import { Container, Card, Modal, Spacer, H3, P } from "@wetradeup/ui-kit"
import styled from "@emotion/styled"
import { ContentContainer, InnerModal } from "../../ModalComponents"
import { ThreeBlueDollars } from "../icons/ThreeBlueDollars"
import { toTop } from "../../../utils"
import { ApplicationButton } from "../../atoms/ApplicationButton"
import { minimumInSchoolPaymentAmount } from "../../slider/hooks/usePaymentSliderOffset"

interface NoInSchoolPaymentModalProps {
    open: boolean
    closeModal: () => void
    handleConfirm: (inSchoolPaymentOption: number) => Promise<void>
    maximumLoanSaving: number
}

const ButtonContainer = styled(Container)`
    margin-left: auto;
    margin-right: auto;
`

export function NoInSchoolPaymentModal({
    open,
    closeModal,
    handleConfirm,
    maximumLoanSaving,
}: NoInSchoolPaymentModalProps) {
    const onOutsideModalClick = () => {
        closeModal()
    }

    const onHandleBack = () => {
        closeModal()
        toTop()
    }

    return (
        <Modal visible={open} width="100%" onClick={onOutsideModalClick}>
            <InnerModal
                width={[
                    [700, "md"],
                    [860, "lg"],
                ]}
                maxHeight="100vh"
                overflowX="auto"
                onClick={(e) => e.stopPropagation()}
            >
                <Card paddingLg>
                    <ContentContainer>
                        <Spacer lg />
                        <Container
                            fullWidth
                            alignItems="center"
                            justifyContent="center"
                        >
                            <ThreeBlueDollars />
                        </Container>
                        <Spacer />
                        <H3 bold="light">
                            Save money with in school payments!
                        </H3>
                        <Spacer />
                        <P textAlign="center">
                            {" "}
                            You could save up to $
                            {maximumLoanSaving
                                ? maximumLoanSaving.toLocaleString()
                                : "---"}{" "}
                            over the life of your loan just by choosing to make
                            payments while you are in school and during your
                            grace period. These voluntary payments can start as
                            low as ${minimumInSchoolPaymentAmount} a month. Are
                            you sure that you don't want to make any payments
                            while you are in school?{" "}
                        </P>
                        <Spacer lg />
                        <ButtonContainer maxWidth={450} alignItems="center">
                            <ApplicationButton
                                fullWidth
                                primary
                                title="Continue without an in school payment"
                                onPress={() => handleConfirm(0.0)}
                            />
                        </ButtonContainer>
                        <Spacer lg />
                        <ButtonContainer maxWidth={300} alignItems="center">
                            <ApplicationButton
                                fullWidth
                                outline="lightBlue"
                                textColor="lightBlue"
                                title="Select an in school payment"
                                onPress={onHandleBack}
                            />
                        </ButtonContainer>
                        <Spacer lg />
                    </ContentContainer>
                </Card>
            </InnerModal>
        </Modal>
    )
}
