import { Container, P, Spacer } from "@wetradeup/ui-kit"

interface InSchoolPaymentDisclosureProps {
    isDesktop?: boolean
    inSchoolPaymentAmount?: number
}

export function InSchoolPaymentDisclosure({
    isDesktop,
    inSchoolPaymentAmount,
}: InSchoolPaymentDisclosureProps) {
    return (
        <Container
            paddingLg
            width={[
                [650, "md"],
                [650, "lg"],
            ]}
        >
            <P
                textColor="black"
                textAlign="left"
                textSize="12px"
                lineHeight="14px"
            >
                Optional in-school payments do not reduce your standard monthly
                payment, but they can reduce the total amount you pay over the
                life of your loan, and they may even reduce the number of
                payments required to pay your loan off in full.
            </P>
            <Spacer />
            <P
                textColor="black"
                textAlign="left"
                textSize="12px"
                lineHeight="14px"
            >
                Before your loan is finalized – and before you accept the final
                terms and sign your Credit Agreement - your loan amount and
                payback terms may change based on information provided by your
                school.
            </P>
            <Spacer />
            <P
                textColor="black"
                textAlign="left"
                textSize="12px"
                lineHeight="14px"
            >
                *To proceed, you and your school will provide additional
                information to verify your enrollment, identity, and loan
                amount. The loan cannot be finalized and will not be funded if
                the school does not confirm your enrollment dates and tuition
                amounts or if Fynn is unable to verify your information or
                identity.
            </P>

            {inSchoolPaymentAmount > 0 && (
                <>
                    <Spacer />
                    <P
                        textColor="black"
                        textAlign="left"
                        textSize="12px"
                        lineHeight="14px"
                    >
                        **The amount saved over the life of your loan and the
                        number of payments to pay off your loan are based on
                        making all optional in-school payments outlined above.
                    </P>
                </>
            )}
        </Container>
    )
}
