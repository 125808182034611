import { Container, Spacer, H2, H3 } from "@wetradeup/ui-kit"
import { SpinningLoader } from "../atoms/SpinningLoader"

export default function LoadingPage() {
    return (
        <Container
            paddingMd
            width={[
                [550, "md"],
                [750, "lg"],
            ]}
            justifyContent="center"
            alignItems="center"
        >
            <Spacer />
            <H2 bold="light"> Loading your information </H2>
            <Spacer lg />
            <SpinningLoader />
            <Spacer lg />
            <H3 bold="light"> Please wait a couple of moments... </H3>
            <Spacer lg />
        </Container>
    )
}
