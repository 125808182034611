import React, { useEffect } from "react"
import * as Yup from "yup"
import { format } from "date-fns"
import {
    useStudentApplicationDetails,
    useVerifyIdentity,
} from "@wetradeup/student-apps-shared"
import {
    Container,
    Spacer,
    H2,
    Picker,
    CancelIcon,
    H3,
    P,
    SecureLabel,
} from "@wetradeup/ui-kit"
import { useHistory } from "react-router-dom"
import { ContentContainer, FullMobileContainer } from "../ModalComponents"
import { HiddenBr } from "../application/atoms/HiddenBr"
import {
    CancelIconContainer,
    DecisionModal,
    handleSubmitResponse,
} from "../application/molecules/modals/DecisionModal"
import { useState } from "react"
import { DatePicker } from "../application/molecules/DatePicker"
import { useFormik } from "formik"
import { SpinningLoader } from "../application/atoms/SpinningLoader"
import { SubmitButtonContainer } from "../application/molecules/SubmitButtonContainer"
import { IdentityVerificationResponseConfirmationModal } from "../application/molecules/modals/IdentityVerificationResponseConfirmationModal"

const NameVerification = ({
    studentApplicationDetails,
    values,
    setFieldValue,
}) => {
    const handlePress = (value) => {
        setFieldValue("name_answer", value)
    }

    return (
        <>
            <Spacer lg />
            <H2 bold="light">Name</H2>
            <P textAlign="center">
                Please select the name that you’ve previously been associated
                with from the options below
            </P>
            <Spacer />
            <Picker
                options={
                    studentApplicationDetails.data?.name_verification_options
                }
                onSelect={handlePress}
                value={values?.name_answer || null}
            />
        </>
    )
}

const AddressVerification = ({
    studentApplicationDetails,
    values,
    setFieldValue,
}) => {
    const handlePress = (value) => {
        setFieldValue("zip", value)
    }

    return (
        <>
            <Spacer lg />
            <H2 bold="light">Zip Code</H2>
            <P textAlign="center">
                Please select the zip code that you’ve previously been
                associated with from the options below
            </P>
            <Spacer />
            <Picker
                options={
                    studentApplicationDetails.data?.address_verification_options
                }
                onSelect={handlePress}
                value={values?.zip || null}
            />
        </>
    )
}

const DobVerification = ({ values, errors, setFieldValue }) => {
    const handlePress = (value) => {
        setFieldValue("dob", value)
    }

    return (
        <>
            <Spacer lg />
            <H2 bold="light">Date of Birth</H2>
            <P textAlign="center">Please re-enter your date of birth</P>
            <Spacer />
            <DatePicker
                name="dob"
                format="MM/dd/yyyy"
                monthPlaceholder="MM"
                dayPlaceholder="DD"
                yearPlaceholder="YYYY"
                value={values?.dob}
                onChange={handlePress}
                errors={errors.dob}
            />
            <Spacer />
        </>
    )
}

const minDate = new Date(1900, 1, 1)
const maxDate = new Date(new Date().getFullYear() - 10, 1, 1)

const identityVerificationFormSchema = Yup.object().shape({
    name_answer: Yup.string().when("name_answer_required", {
        is: true,
        then: Yup.string().required("You must choose an associated name"),
    }),
    zip: Yup.string().when("zip_required", {
        is: true,
        then: Yup.string().required("You must choose a zip code"),
    }),
    dob: Yup.date().when("dob_required", {
        is: true,
        then: Yup.date()
            .required("Please enter your date of birth.")
            .min(minDate, "Please enter a valid date of birth.")
            .max(maxDate, "Please enter a valid date of birth."),
    }),
    zip_required: Yup.bool(),
    name_answer_required: Yup.bool(),
    dob_required: Yup.bool(),
})

export interface IVerifyIdentity {
    name_answer?: string
    dob?: Date
    zip?: string
    zip_required?: boolean
    name_answer_required?: boolean
    dob_required?: boolean
}

interface PendingIdentityVerificationProps {
    studentApplicationDetails: ReturnType<typeof useStudentApplicationDetails>
}

export default function PendingIdentityVerification({
    studentApplicationDetails,
}: PendingIdentityVerificationProps) {
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [serverError, setServerError] = useState("")
    const [decision, setDecision] = useState(null)
    const verifyIdentityMutation = useVerifyIdentity()
    const { handleSubmit, setFieldValue, setValues, values, errors } =
        useFormik<IVerifyIdentity>({
            initialValues: {},
            validationSchema: identityVerificationFormSchema,
            onSubmit: async (values) => {
                setLoading(true)
                try {
                    const response = await verifyIdentityMutation.mutateAsync({
                        ...(values.dob
                            ? { dob: format(values.dob, "yyyy-MM-dd") }
                            : {}),
                        ...(values.name_answer
                            ? { name_answer: values.name_answer }
                            : {}),
                        ...(values.zip ? { zip: values.zip } : {}),
                    })
                    if (response) {
                        const newRoute = handleSubmitResponse(
                            response,
                            setServerError,
                            setDecision
                        )
                        if (newRoute) {
                            history.push(newRoute)
                        }
                    }
                } catch (e) {
                    setServerError(e?.error)
                } finally {
                    setLoading(false)
                }
            },
        })

    useEffect(() => {
        if (
            studentApplicationDetails.data
                ?.should_show_identity_verification_modal
        ) {
            setValues({
                ...values,
                name_answer_required:
                    !studentApplicationDetails.data
                        ?.is_name_verified_against_experian,
                zip_required:
                    !studentApplicationDetails.data
                        ?.is_address_verified_against_experian,
                dob_required:
                    !studentApplicationDetails.data
                        ?.is_dob_verified_against_experian,
            })
        }
    }, [studentApplicationDetails.data])

    return (
        <FullMobileContainer
            paddingMd
            width={[
                [550, "sm"],
                [700, "md"],
                [860, "lg"],
            ]}
        >
            <Spacer />
            <ContentContainer>
                <Container
                    fullWidth
                    alignItems="center"
                    justifyContent="center"
                >
                    <CancelIconContainer width={28}>
                        <CancelIcon />
                    </CancelIconContainer>
                </Container>
                <Spacer />
                <H3 bold="light">
                    {" "}
                    Oops, we had an error verifying <HiddenBr /> some of your
                    information{" "}
                </H3>
                <Spacer />
                <ContentContainer>
                    <P textAlign="center">
                        {" "}
                        When verifying your information we had an error in one
                        or more field(s). Please re-enter your information
                        below.{" "}
                    </P>
                    {studentApplicationDetails.data &&
                        !studentApplicationDetails.data
                            .is_name_verified_against_experian && (
                            <NameVerification
                                studentApplicationDetails={
                                    studentApplicationDetails
                                }
                                values={values}
                                setFieldValue={setFieldValue}
                            />
                        )}
                    {studentApplicationDetails.data &&
                        !studentApplicationDetails.data
                            .is_dob_verified_against_experian && (
                            <DobVerification
                                values={values}
                                errors={errors}
                                setFieldValue={setFieldValue}
                            />
                        )}
                    {studentApplicationDetails.data &&
                        !studentApplicationDetails.data
                            .is_address_verified_against_experian && (
                            <AddressVerification
                                studentApplicationDetails={
                                    studentApplicationDetails
                                }
                                values={values}
                                setFieldValue={setFieldValue}
                            />
                        )}
                    <Spacer />
                    <SubmitButtonContainer
                        disabled={Object.keys(errors).length > 0}
                        handleSubmit={() => {
                            setShowConfirmation(true)
                        }}
                        errors={errors}
                        title="Confirm"
                    />
                    {loading && <SpinningLoader />}
                    <Spacer />
                    <SecureLabel />
                </ContentContainer>
            </ContentContainer>
            <Spacer lg />
            <IdentityVerificationResponseConfirmationModal
                loading={loading}
                open={showConfirmation}
                closeModal={() => {
                    setShowConfirmation(false)
                }}
                handleSubmit={handleSubmit}
                serverError={serverError}
                values={values}
            />
            {decision === "rejected" && (
                <DecisionModal
                    decision={decision}
                    appFormId={null}
                    setDecision={setDecision}
                    setError={() => {}}
                />
            )}
        </FullMobileContainer>
    )
}
