export function BlueCircle() {
    return (
        <svg
            width="25"
            height="25"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="10.1056" cy="10.7766" r="10" fill="#0070F9" />
        </svg>
    )
}
