import styled from "@emotion/styled"
import { useLogout } from "@wetradeup/student-apps-shared"
import { Container, P, Spacer } from "@wetradeup/ui-kit"
import { SignOutIcon } from "../../exit-counseling/icons/SignOutIcon"
import { ApplicationButton } from "../../atoms/ApplicationButton"
import { useWindowSize } from "../../hooks/useWindowSize"

const FixedSignOutContainer = styled(Container)`
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 200;

    @media (max-width: 1440px) {
        display: none;
        bottom: 0px;
    }
`

const SignOutButtonWrap = styled(ApplicationButton)`
    min-height: 40px;
    width: 160px;
`

export function SignOutButton() {
    const logout = useLogout()
    const { windowSize } = useWindowSize()
    const isSmallDesktop = windowSize.width < 1440
    return (
        <SignOutButtonWrap
            buttonColor="newBlue"
            onPress={logout}
            {...(isSmallDesktop ? { outline: "black" } : { primary: true })}
            fullWidth
        >
            <>
                <SignOutIcon stroke={isSmallDesktop ? "black" : "white"} />
                <Spacer />
                <P textColor={windowSize.width < 1440 ? "black" : "white"} bold>
                    Sign Out
                </P>
            </>
        </SignOutButtonWrap>
    )
}

export function NavBarExitCounselingSignOut() {
    return (
        <FixedSignOutContainer>
            <SignOutButton />
        </FixedSignOutContainer>
    )
}
