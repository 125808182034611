import {
    Card,
    Container,
    H2,
    P,
    Spacer,
    TouchableContainer,
} from "@wetradeup/ui-kit"
import { useStudentApplicationDetails } from "@wetradeup/student-apps-shared"
import { GreenCheck } from "./icons/GreeCheck"
import { useState } from "react"
import { CancelCosignerModal } from "../application/molecules/modals/CancelCosignerModal"
import { UnderlineText } from "../atoms/UnderlineText"

interface AwaitingProps {
    studentApplicationDetails: ReturnType<typeof useStudentApplicationDetails>
    isAwaitingContractSigning?: boolean
}
export function CosignerAwaiting({
    studentApplicationDetails,
    isAwaitingContractSigning,
}: AwaitingProps) {
    const [open, setOpen] = useState(false)
    return (
        <>
            <Container
                paddingMd
                width={[
                    [550, "sm"],
                    [550, "md"],
                    [750, "lg"],
                ]}
                justifyContent="center"
                alignItems="center"
            >
                <Container width="100%" maxWidth={600}>
                    <Spacer lg />
                    <H2 bold="light" textColor="black">
                        You're done! We're waiting on{" "}
                        {studentApplicationDetails.data?.cosigner_name} to
                        complete their portion.
                    </H2>
                    <Spacer lg />
                    {!isAwaitingContractSigning && (
                        <P textAlign="center" textColor="black">
                            We will contact you when it’s time to come back. You
                            can close this window now.{" "}
                        </P>
                    )}
                    <Spacer lg />
                </Container>
                <Container width="100%" maxWidth={600}>
                    <Card
                        disableShadow
                        flex
                        row
                        width="100%"
                        paddingLg
                        border="medGrey"
                        alignItems="center"
                    >
                        <P textColor="black">Your application:</P>
                        <Spacer />
                        <P textColor="medGreen" bold>
                            Complete
                        </P>
                        <Spacer xs />
                        <GreenCheck />
                    </Card>
                    <Spacer lg />
                    <Card
                        disableShadow
                        flex
                        row
                        width="100%"
                        paddingLg
                        border="medGrey"
                        justifyContent="space-between"
                    >
                        <Container flex row>
                            <P textColor="black">
                                {studentApplicationDetails.data?.cosigner_name}
                                's application:
                            </P>
                            <Spacer />
                            <P textColor="orange" bold>
                                Incomplete
                            </P>
                        </Container>
                        {!isAwaitingContractSigning && (
                            <TouchableContainer onPress={() => setOpen(true)}>
                                <UnderlineText textColor="black">
                                    Cancel Application{" "}
                                </UnderlineText>
                            </TouchableContainer>
                        )}
                    </Card>
                </Container>
                <Spacer lg />
            </Container>
            <CancelCosignerModal
                open={open}
                closeModal={() => setOpen(false)}
            />
        </>
    )
}
