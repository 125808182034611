import { Card, Container, H5, Modal, Spacer } from "@wetradeup/ui-kit"
import { ContentContainer } from "../ModalComponents"
import { Falling } from "../isl-finalize/icons/Falling"

interface CookieModalProps {
    showCookieModal: boolean
    setShowCookieModal: React.Dispatch<React.SetStateAction<boolean>>
}

export function CookieModal({
    showCookieModal,
    setShowCookieModal,
}: CookieModalProps) {
    return (
        <Modal
            visible={showCookieModal}
            width="100%"
            onClick={() => {
                setShowCookieModal(false)
            }}
        >
            <Container
                width={[
                    [700, "md"],
                    [860, "lg"],
                ]}
            >
                <Card paddingMd>
                    <ContentContainer
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Spacer lg />
                        <Falling />
                        <Spacer lg />
                        <H5 bold="light" textAlign="center">
                            Please enable cookies in your browser to continue.
                        </H5>
                        <Spacer lg />
                    </ContentContainer>
                </Card>
            </Container>
        </Modal>
    )
}
