import styled from "@emotion/styled"
import { CoinStackIcon } from "../../../atoms/CoinStackIcon"
import { P } from "@wetradeup/ui-kit"
import { maximumInSchoolPaymentMatch } from "../../../slider/hooks/usePaymentSliderOffset"

interface PaymentMatchProgressBarInputProps {
    inSchoolPaymentAmount: number
    paymentOption: string
    isMaxSuggestedPayment: boolean
}

const PaymentMatchProgressBarContainer = styled.div<{ percentage?: number }>`
    ${(props) => `
        height: 30px;
        width: 100%;
        background-color: #e1f9e9;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
        border-radius: 6px;
        position: relative;
        z-index: 5;
        &:before {
            border-radius: ${
                props.percentage < 100 ? "6px 0px 0px 6px" : "6px"
            };
            content: '';
            height: 30px;
            position: absolute;
            top: 0;
            left: 0;
            width: ${Math.min(props.percentage, 100)}%;
            background-color: #52EA6A;
        }
        &:after {
            content: '';
            height: 0px;
            width: 0px;
            position: absolute;
            left: calc(${Math.min(props.percentage, 100)}% - 10px);
            ${
                props.percentage > 0 &&
                props.percentage < 100 &&
                `
                border-right: solid 10px transparent;
                border-left: solid 10px transparent;
                border-top: solid 30px #52EA6A;
            `
            }
        }
    `}
`

const StyledCoinStackIconWrapper = styled.div`
    position: absolute;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    left: 3%;
    z-index: 100;
`

const ChosenPaymentOption = styled(P)`
    position: absolute;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    left: 28%;
    z-index: 100;
`

const MaximumPaymentOption = styled(P)<{ isMax?: boolean }>`
    ${(props) => `
        position: absolute;
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        color: ${props.isMax ? "black" : "#8cbda6"};
        left: 80%;
        z-index: 100;
    `}
`

export function PaymentMatchProgressBar({
    inSchoolPaymentAmount,
    isMaxSuggestedPayment,
    paymentOption,
}: PaymentMatchProgressBarInputProps) {
    return (
        <PaymentMatchProgressBarContainer
            percentage={
                (inSchoolPaymentAmount / maximumInSchoolPaymentMatch) * 100
            }
        >
            <StyledCoinStackIconWrapper>
                <CoinStackIcon />
            </StyledCoinStackIconWrapper>
            {!isMaxSuggestedPayment && (
                <ChosenPaymentOption bold textColor="black">
                    +$
                    {paymentOption === "custom"
                        ? inSchoolPaymentAmount
                        : paymentOption}
                    /mo
                </ChosenPaymentOption>
            )}
            <MaximumPaymentOption bold isMax={isMaxSuggestedPayment}>
                +${maximumInSchoolPaymentMatch}/mo
            </MaximumPaymentOption>
        </PaymentMatchProgressBarContainer>
    )
}
