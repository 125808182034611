export function CosignerAppIcon() {
    return (
        <svg
            width="157"
            height="121"
            viewBox="0 0 157 121"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M127.695 57C122.606 60.8215 116.258 63.0952 109.381 63.0952H106.619C99.7404 63.0952 93.3907 60.8207 88.3031 57C71.6879 59.6475 59 73.8431 59 90.9652C59 100.372 80.9376 108 108 108C135.062 108 157 100.372 157 90.9652C157 73.8421 144.31 59.646 127.695 57H127.695Z"
                fill="#787878"
            />
            <path
                d="M108.001 57C122.338 57 134 41.3878 134 26.6783C134 11.9697 122.338 0 108.001 0C93.6637 0 82 11.9695 82 26.6783C82.0025 41.3861 93.6641 57 108.001 57Z"
                fill="#787878"
            />
            <path
                d="M49.0011 70C63.338 70 75 54.3878 75 39.6783C75 24.9697 63.3384 13 49.0011 13C34.6637 13 23 24.9695 23 39.6783C23.0025 54.3861 34.6641 70 49.0011 70Z"
                fill="#2E54E8"
            />
            <path
                d="M68.695 70C63.6059 73.8215 57.2577 76.0952 50.3811 76.0952H47.6187C40.7404 76.0952 34.3907 73.8207 29.3031 70C12.6879 72.6475 0 86.8431 0 103.965C0 113.372 21.9376 121 49 121C76.0624 121 98 113.372 98 103.965C98 86.8421 85.3104 72.646 68.6948 70H68.695Z"
                fill="#2E54E8"
            />
        </svg>
    )
}
