import {
    useGetStudentProfile,
    useStudentApplicationDetails,
} from "@wetradeup/student-apps-shared"
import { Container, H2, Spacer } from "@wetradeup/ui-kit"
import { HiddenBr } from "../../application/atoms/HiddenBr"
import { PendingIdentityVerification } from "./PendingVerifyIdentity"
import { PendingIdentityVerificationReview } from "./PendingIdentityVerificationReview"
import VerifyBank, { ResponsiveApplicationCard } from "./VerifyBank"
import { useStripe } from "./useStripe"
import { useVerifyBank } from "./useVerifyBank"

interface VerifyIdentityProps {
    studentProfile: ReturnType<typeof useGetStudentProfile>
    studentApplicationDetails: ReturnType<typeof useStudentApplicationDetails>
}

function VerifyIdentityTitle() {
    return (
        <>
            <H2 bold="light">
                {" "}
                Loan submitted! <HiddenBr /> Time to verify your identity.{" "}
            </H2>
            <Spacer />
        </>
    )
}

function VerifyBothIDAndBank() {
    const { isPendingIDReview, isLoading, handleCreateVerificationSession } =
        useStripe()

    return (
        <>
            {isPendingIDReview && <PendingIdentityVerificationReview />}
            {!isPendingIDReview && (
                <>
                    <VerifyIdentityTitle />
                    <ResponsiveApplicationCard
                        alignItems="center"
                        justifyContent="center"
                        disableCard
                        border
                        paddingLg
                        width="100%"
                    >
                        <PendingIdentityVerification
                            handleCreateVerificationSession={
                                handleCreateVerificationSession
                            }
                            isLoading={isLoading}
                        />
                    </ResponsiveApplicationCard>
                </>
            )}
        </>
    )
}

export default function VerifyIdentity({
    studentProfile,
    studentApplicationDetails,
}: VerifyIdentityProps) {
    const requiresIDUpload =
        studentApplicationDetails.data?.underwriting_recommendation ===
        "approved_after_id_verification"
    const hasUploadedID = studentProfile.data?.has_uploaded_id_to_stripe
    const hasCompletedBank =
        studentProfile.data?.bank_identity_verification === "approved"

    const { linkTokenQuery, onSuccess, onExit, onEvent } = useVerifyBank({
        studentProfileLoaded: studentProfile.isSuccess,
        studentApplicationDetailsLoaded: studentApplicationDetails.isSuccess,
        bankVerificationIdentity:
            studentProfile.data?.bank_identity_verification,
        bankVerificationIdentityAttempts:
            studentApplicationDetails.data?.bank_identity_verification_attempts,
        plaidStatus: studentProfile.data?.plaid_status,
        bankIdentityFrozen: studentProfile.data?.bank_identity_frozen,
    })

    return (
        <>
            <Spacer lg />
            <Container
                paddingMd
                width={[
                    [550, "sm"],
                    [550, "md"],
                    [750, "lg"],
                ]}
            >
                {requiresIDUpload && !hasUploadedID && !hasCompletedBank && (
                    <VerifyBothIDAndBank />
                )}
                {(!requiresIDUpload || hasUploadedID || hasCompletedBank) && (
                    <VerifyBank
                        studentProfile={studentProfile}
                        studentApplicationDetails={studentApplicationDetails}
                        linkTokenQuery={linkTokenQuery}
                        onSuccess={onSuccess}
                        onExit={onExit}
                        onEvent={onEvent}
                    />
                )}
            </Container>
        </>
    )
}
