import {
    Questionnaire,
    QuestionnaireAnswer,
} from "@wetradeup/student-apps-shared"
import { useMemo } from "react"

export function useCreateQuestionnaireInitValues(
    questionnaire: Questionnaire[]
): QuestionnaireAnswer {
    return useMemo(() => {
        return Object.fromEntries(
            questionnaire.map((i) => {
                if (i["style"] === "prioritize") {
                    const randomOptionOrder = i["options"].sort(
                        () => Math.random() - 0.5
                    )
                    const initPrioritizeVal = {
                        initial_order: randomOptionOrder,
                        final_order: randomOptionOrder,
                        time_spent: 0,
                    }
                    return [i["id"], initPrioritizeVal]
                } else {
                    return [i["id"], ""]
                }
            })
        )
    }, [questionnaire])
}
