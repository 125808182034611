import { useGetStudentProfile } from "@wetradeup/student-apps-shared"
import { Container, P, Spacer } from "@wetradeup/ui-kit"
import { GreenCheck } from "../icons/GreeCheck"

interface VerifyBankStatusProps {
    studentProfile: ReturnType<typeof useGetStudentProfile>
}

export function VerifyBankStatus({ studentProfile }: VerifyBankStatusProps) {
    const hasCompletedBank =
        studentProfile.data?.bank_identity_verification === "approved"
    return (
        <Container>
            {hasCompletedBank && (
                <Container row alignItems="center" wrap>
                    <Container row alignItems="center">
                        <P bold>Step Two: Bank Account Verification -</P>
                        <Spacer sm />
                    </Container>
                    <Container row alignItems="center">
                        <P textColor="medGreen" bold>
                            Complete
                        </P>
                        <Spacer sm />
                        <GreenCheck />
                    </Container>
                </Container>
            )}
            {!hasCompletedBank && (
                <>
                    <P bold textColor="black">
                        Step Two: Bank Account Verification{" "}
                    </P>
                    <Spacer lg />
                </>
            )}
        </Container>
    )
}
