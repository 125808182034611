import React from "react"
import { H3, Spacer } from "@wetradeup/ui-kit"
import { Falling } from "../isl-finalize/icons/Falling"

export default function ServerError() {
    return (
        <div className="notFound-container">
            <div>
                <Falling />
                <Spacer />
                <H3 black>
                    Whoops! There was an internal server error. We're sorry for
                    the inconvenience.
                </H3>
            </div>
        </div>
    )
}
