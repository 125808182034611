export function PaymentPause() {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.0887 30.267C23.9649 30.267 30.3498 23.8821 30.3498 16.006C30.3498 8.12978 23.9649 1.74487 16.0887 1.74487C8.21254 1.74487 1.82764 8.12978 1.82764 16.006C1.82764 23.8821 8.21254 30.267 16.0887 30.267Z"
                fill="white"
                stroke="#2EC63C"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M12.6411 20.7966V11.0133"
                stroke="#2EC63C"
                stroke-width="2"
                stroke-linecap="square"
                stroke-linejoin="round"
            />
            <path
                d="M19.335 20.7966V11.0133"
                stroke="#2EC63C"
                stroke-width="2"
                stroke-linecap="square"
                stroke-linejoin="round"
            />
        </svg>
    )
}
