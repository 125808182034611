import { useTheme } from "@emotion/react"
import { useHistory } from "react-router-dom"
import { Container, P, Spacer } from "@wetradeup/ui-kit"
import { ApplicationButton } from "../../atoms/ApplicationButton"
import { DefaultPage } from "../molecules/DefaultPage"
import { useContext, useEffect } from "react"
import {
    AuthContext,
    QuestionnaireAnswer,
    useGetExitCounseling,
    useGetExitCounselingQuestionnaire,
    useUpdateExitCounselingQuestionnaireAnswer,
} from "@wetradeup/student-apps-shared"
import { RadioQuestion } from "../../application/Questionnaire/RadioQuestion"
import { useFormik } from "formik"
import { useCreateQuestionnaireValidation } from "../../application/Questionnaire/hooks/useCreateQuestionnaireValidation"
import { useCreateQuestionnaireInitValues } from "../../application/Questionnaire/hooks/useCreateQuestionnaireInitValues"
import { SpinningLoader } from "../../application/atoms/SpinningLoader"
import { formatQuestionnaireAnswers } from "../../application/Questionnaire/hooks/useGetQuestionnaireSubmitCallback"
import styled from "@emotion/styled"

const AnswerTheQuestionsPrompt = styled.div`
    ${(props) => `
        background-color: ${props.theme.colors.palette.newLightOrange};
        border: 1.5px solid ${props.theme.colors.palette.newDarkOrange};
        padding: 20px 40px;
        width: 100%;
    `}
`

export function ExitCounselingQuiz() {
    const theme = useTheme()
    const history = useHistory()
    const { authProfile } = useContext(AuthContext)
    const exitCounseling = useGetExitCounseling(authProfile?.accounts_id)
    const questionnaire = useGetExitCounselingQuestionnaire(
        authProfile.accounts_id
    )
    const valSchema = useCreateQuestionnaireValidation(
        questionnaire.data?.questionnaire || []
    )
    const initVals = useCreateQuestionnaireInitValues(
        questionnaire.data?.questionnaire || []
    )
    const updateAnswer = useUpdateExitCounselingQuestionnaireAnswer()

    const onFormikSubmit = async () => {
        const processedValues = formatQuestionnaireAnswers(
            questionnaire.data?.questionnaire,
            values
        )
        await updateAnswer.mutateAsync({
            accountId: authProfile.accounts_id,
            answers: processedValues,
        })
        history.push("/loan-orientation/result")
    }

    const { setFieldValue, handleSubmit, errors, values, dirty, isValid } =
        useFormik<QuestionnaireAnswer>({
            initialValues: initVals,
            validationSchema: valSchema,
            onSubmit: onFormikSubmit,
        })

    const allQuestionsAnswered = dirty && isValid

    useEffect(() => {
        if (exitCounseling.data?.exit_counseling_questionnaire_answered_at) {
            history.push("/loan-orientation/result")
        }
    }, [])

    return (
        <DefaultPage>
            <Container width="100%">
                <P
                    textColor="black"
                    bold
                    textSize={theme.sizings.fontSizeMd}
                    textAlign="left"
                >
                    Time to test your knowledge!
                </P>
            </Container>
            <Spacer lg />
            {questionnaire.isFetching && <SpinningLoader />}
            {(questionnaire.data?.questionnaire || []).map(
                ({ id, title, subtitle, options }) => (
                    <RadioQuestion
                        key={id}
                        id={id}
                        title={title}
                        subtitle={subtitle}
                        options={options}
                        questionnaire={questionnaire.data?.questionnaire}
                        setQuestionnaire={() => {}}
                        setFieldValue={setFieldValue}
                        values={values}
                        setTimeOfLastTouch={() => {}}
                    />
                )
            )}
            <Spacer lg />
            {!allQuestionsAnswered && (
                <AnswerTheQuestionsPrompt>
                    <P textColor="newGreenBackground" textSize="18px" bold>
                        Answer all the questions above to complete your Exit
                        Counseling session.
                    </P>
                </AnswerTheQuestionsPrompt>
            )}
            <Spacer lg />
            {allQuestionsAnswered && (
                <Container width={300}>
                    {updateAnswer.isLoading && <SpinningLoader />}
                    {!updateAnswer.isLoading && (
                        <ApplicationButton
                            fullWidth
                            alignCenter
                            title="Continue"
                            disabled={Object.keys(errors).length > 0}
                            buttonColor="newBlue"
                            onPress={() => handleSubmit()}
                        />
                    )}
                </Container>
            )}
        </DefaultPage>
    )
}
