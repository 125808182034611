export function YellowWarning() {
    return (
        <svg
            width="25"
            height="23"
            viewBox="0 0 23 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.37766 1.63257C10.1475 0.299233 12.072 0.299235 12.8418 1.63257L21.7666 17.0908C22.5364 18.4242 21.5741 20.0908 20.0345 20.0908H2.18487C0.645273 20.0908 -0.316975 18.4242 0.452825 17.0908L9.37766 1.63257Z"
                fill="#FFC83B"
            />
            <path
                d="M12.0033 7.71084L11.8371 13.8216H10.2775L10.107 7.71084H12.0033ZM11.0573 16.5489C10.7761 16.5489 10.5346 16.4495 10.3329 16.2506C10.1312 16.0489 10.0317 15.8074 10.0346 15.5262C10.0317 15.2478 10.1312 15.0091 10.3329 14.8103C10.5346 14.6114 10.7761 14.512 11.0573 14.512C11.3272 14.512 11.5644 14.6114 11.769 14.8103C11.9735 15.0091 12.0772 15.2478 12.08 15.5262C12.0772 15.7137 12.0275 15.8856 11.9309 16.0418C11.8371 16.1952 11.7136 16.3188 11.5601 16.4125C11.4067 16.5035 11.2391 16.5489 11.0573 16.5489Z"
                fill="black"
            />
        </svg>
    )
}
