import { useTheme } from "@emotion/react"
import styled from "@emotion/styled"
import { Container, ContainerProps, P, Spacer } from "@wetradeup/ui-kit"
import { breakpoints } from "../../../constants"
import { isNumber } from "../../../utils"
import { FormattedTerms } from "../hooks/useGetLoanPaymentTerms"
import { TermContainer } from "./TermContainer"

const SectionContainer = styled(Container)`
    ${(props) => `
        ${props.showTopRadius ? `border-radius: 5px 5px 0px 0px;` : ""}
        ${props.showBottomRadius ? `border-radius: 0px 0px 5px 5px;` : ""}
        ${props.showBottomLeftRadius ? `border-radius: 0px 0px 0px 5px;` : ""}
        ${props.showBottomRightRadius ? `border-radius: 0px 0px 5px 0px;` : ""}

        @media (max-width: ${breakpoints.mobileLg}) {
            padding: ${props.theme.sizings.paddingLg} ${
        props.theme.sizings.paddingSm
    };
            justify-content: start;
        }

        ${
            props.showRightBorder
                ? `border-right: 1px solid ${props.theme.colors.palette.medGrey};`
                : ""
        }
    `}
`

const MobileStandardPaymentSectionContainer = styled(Container)`
    ${(props) => `
        ${props.showTopRadius ? `border-radius: 5px 5px 0px 0px;` : ""}
        ${props.showBottomRadius ? `border-radius: 0px 0px 5px 5px;` : ""}

        padding: ${props.theme.sizings.paddingMd} ${
        props.theme.sizings.paddingSm
    };
    `}
`

export const StraightHR = styled.hr`
    border: 1px solid ${(props) => props.theme.colors.palette.medGrey};
    width: 100%;
`

const ResponsiveMainHeadline = styled(P)`
    ${(props) => `
        font-size: ${props.fontSize || props.theme.sizings.fontSizeMdLg};
        color: ${props.theme.colors.palette.black};
    `}
`

const ResponsiveMainP = styled(P)`
    ${(props) => `
        font-size: ${props.fontSize};
    `}
`

const ResponsiveSubHeadline = styled(P)`
    ${(props) => `
        font-size: 16px;

        @media (max-width: ${breakpoints.mobileLg}) {
            font-size: 14px;
            line-height: 13px;
        }
    `}
`
interface SectionProps {
    terms: FormattedTerms
    isDeskTop?: boolean
    stickyFooter?: boolean
}

export function InSchoolPaymentSection({
    terms,
    isDeskTop,
    stickyFooter,
    ...rest
}: SectionProps & ContainerProps) {
    const theme = useTheme()
    return (
        <>
            {terms?.has_in_school_payment && (
                <>
                    <SectionContainer
                        disableShadow
                        backgroundColor="lightestBlue"
                        paddingLg
                        justifyContent="center"
                        alignItems="center"
                        {...(isDeskTop ? { showTopRadius: true } : {})}
                        {...(isDeskTop ? { width: "100%" } : {})}
                        {...(!isDeskTop ? { showRightBorder: true } : {})}
                        {...(!isDeskTop && !stickyFooter
                            ? { showBottomLeftRadius: true }
                            : {})}
                        {...rest}
                    >
                        <ResponsiveSubHeadline textColor="black">
                            {" "}
                            During school you'll pay{" "}
                        </ResponsiveSubHeadline>
                        <Spacer />
                        <ResponsiveMainP
                            textColor="black"
                            weight="bold"
                            fontSize={
                                isDeskTop
                                    ? theme.sizings.fontSizeLg
                                    : theme.sizings.fontSizeMdLg
                            }
                        >
                            {" "}
                            ${terms?.in_school_payment}/mo{" "}
                        </ResponsiveMainP>
                        <Spacer />
                        <ResponsiveSubHeadline
                            textAlign="center"
                            textColor="black"
                        >
                            For {terms?.in_school_payment_period}
                        </ResponsiveSubHeadline>
                    </SectionContainer>
                    {isDeskTop && <StraightHR />}
                </>
            )}
        </>
    )
}

export function StandardPaymentSection({
    terms,
    isDeskTop,
    stickyFooter,
    ...rest
}: SectionProps & ContainerProps) {
    const theme = useTheme()
    return (
        <>
            {!isDeskTop &&
            !terms?.has_in_school_payment &&
            isNumber(terms.borrowing_request) ? (
                <>
                    <MobileStandardPaymentSectionContainer
                        disableShadow
                        backgroundColor="lightestBlue"
                        justifyContent="center"
                        alignItems="center"
                        showTopRadius={!terms?.has_in_school_payment}
                        showBottomRadius
                        {...(isDeskTop ? { width: "100%" } : {})}
                        {...rest}
                    >
                        <ResponsiveMainHeadline
                            fontSize={theme.sizings.fontSizeMd}
                        >
                            Your monthly payment
                        </ResponsiveMainHeadline>
                        <Spacer />
                        <Container column>
                            <ResponsiveMainP
                                textColor="black"
                                weight="bold"
                                textAlign="center"
                                fontSize={theme.sizings.fontSizeXl}
                            >
                                ${terms?.payment}
                            </ResponsiveMainP>
                        </Container>
                    </MobileStandardPaymentSectionContainer>
                </>
            ) : (
                <SectionContainer
                    disableShadow
                    backgroundColor="lightestBlue"
                    paddingLg
                    justifyContent="center"
                    alignItems="center"
                    showTopRadius={!terms?.has_in_school_payment}
                    {...(isDeskTop ? { width: "100%" } : {})}
                    {...(!isDeskTop && !stickyFooter
                        ? { showBottomRightRadius: true }
                        : {})}
                    {...rest}
                >
                    <ResponsiveSubHeadline textColor="black">
                        {" "}
                        {terms?.has_in_school_payment
                            ? "After school you'll pay"
                            : "Your monthly payment"}{" "}
                    </ResponsiveSubHeadline>
                    <Spacer />
                    {!isNumber(terms?.borrowing_request) && (
                        <P>First, select a loan amount</P>
                    )}
                    {isNumber(terms?.borrowing_request) && (
                        <>
                            <ResponsiveMainP
                                textColor="black"
                                weight="bold"
                                fontSize={
                                    isDeskTop
                                        ? theme.sizings.fontSizeLg
                                        : theme.sizings.fontSizeMdLg
                                }
                            >
                                {terms?.has_in_school_payment
                                    ? `$${terms?.payment}/mo`
                                    : `$${terms?.payment}`}
                            </ResponsiveMainP>
                            <Spacer />
                            {terms?.has_in_school_payment && (
                                <ResponsiveSubHeadline
                                    textAlign="center"
                                    textColor="black"
                                >
                                    starting on{" "}
                                    {terms?.first_standard_payment_due_date}{" "}
                                    <br />
                                    for {terms?.effective_loan_terms}
                                </ResponsiveSubHeadline>
                            )}
                            {!terms?.has_in_school_payment && (
                                <Container paddingSm>
                                    <ResponsiveSubHeadline
                                        textColor="black"
                                        textSize="16px"
                                        textAlign="center"
                                    >
                                        {" "}
                                        First payment due{" "}
                                        {
                                            terms?.first_standard_payment_due_date
                                        }{" "}
                                    </ResponsiveSubHeadline>
                                </Container>
                            )}
                        </>
                    )}
                </SectionContainer>
            )}
        </>
    )
}

export function LoanTermSection({
    terms,
    isDeskTop,
}: {
    terms: FormattedTerms
    isDeskTop?: boolean
}) {
    return (
        <SectionContainer
            disableShadow
            paddingMd
            width="100%"
            backgroundColor="lightestBlue"
            {...(isDeskTop
                ? { showBottomRadius: true }
                : { showTopRadius: true })}
        >
            <Container padding={["none", "sm"]}>
                <P textColor="black" bold>
                    Loan Terms
                </P>
            </Container>
            <Spacer />
            {terms?.note_size && (
                <TermContainer
                    term="Loan Amount"
                    termValue={terms?.note_size}
                    addHR
                />
            )}
            <TermContainer
                term="Loan Term"
                termValue={terms?.loan_term}
                addHR
            />
            <TermContainer
                term="Initial Grace Period"
                termValue={terms?.grace_period}
                addHR
            />
            <TermContainer
                term="Interest Rate"
                termValue={terms?.interest}
                addHR={!isDeskTop && terms?.in_school_payment === 0}
            />
            {!isDeskTop && terms?.in_school_payment === 0 && (
                <TermContainer
                    term="First Payment"
                    termValue={terms?.first_standard_payment_due_date}
                />
            )}
        </SectionContainer>
    )
}
