import React from "react"
import ReactDOM from "react-dom"
import * as Sentry from "@sentry/react"
import smoothscroll from "smoothscroll-polyfill"
import "whatwg-fetch"
import { initLogRocket, setBuildVersion } from "@wetradeup/student-apps-shared"

import "./index.css"
import App from "./components/App"
import * as serviceWorker from "./serviceWorker"
import { CLIENT_ENV, BUILD_VERSION } from "./config"

smoothscroll.polyfill()

if (CLIENT_ENV === "production") {
    Sentry.init({
        dsn: "https://a1bb1567c8504701a7bd8da25f8b863e@o504509.ingest.sentry.io/5733763",
        integrations: [new Sentry.BrowserTracing()],
        tracesSampleRate: 0.05,
    })
    initLogRocket("isl")
}

setBuildVersion(BUILD_VERSION)

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
