import {
    Card,
    Container,
    FynnFullLetterLogo,
    Input,
    Modal,
    P,
    Spacer,
} from "@wetradeup/ui-kit"
import {
    AuthContext,
    AuthStyledButton,
    useUpdateAccountMutation,
} from "@wetradeup/student-apps-shared"
import * as Yup from "yup"
import { useFormik } from "formik"
import { ContentContainer, InnerModal } from "../../../ModalComponents"
import { useTheme } from "@emotion/react"
import { useContext, useState } from "react"
import { formatPhoneNumber } from "../../IntroForm/CreateAccount"

const valSchema = Yup.object().shape({
    phone: Yup.string().required("Please enter a new phone number."),
})

export function UpdatePhoneModal({
    visible,
    closeModal,
    sendPhoneVerification,
}: {
    visible: boolean
    closeModal?: () => void
    sendPhoneVerification: () => Promise<void>
}) {
    const { authProfile, setAuthProfile } = useContext(AuthContext)
    const [serverError, setServerError] = useState("")
    const updateAccount = useUpdateAccountMutation()
    const theme = useTheme()
    const onSubmit = async () => {
        try {
            await updateAccount.mutateAsync({ phone: values.phone })
            await sendPhoneVerification()
            setAuthProfile({
                ...authProfile,
                phone: values.phone,
            })
            closeModal()
        } catch (err) {
            setServerError(err.error || "")
        }
    }

    const { handleSubmit, handleChange, values, errors } = useFormik<{
        phone: string
    }>({
        initialValues: { phone: "" },
        validationSchema: valSchema,
        onSubmit,
    })

    const disabled = !!errors.phone || !values.phone
    return (
        <Modal
            visible={visible}
            width="100%"
            onClick={() => closeModal && closeModal()}
        >
            <InnerModal
                width={[
                    [700, "md"],
                    [860, "lg"],
                ]}
                onClick={(e) => e.stopPropagation()}
            >
                <Card paddingLg>
                    <Spacer lg />
                    <ContentContainer>
                        <Container justifyContent="center" alignItems="center">
                            <FynnFullLetterLogo />
                        </Container>
                        <Spacer />
                        <P
                            bold
                            textSize={theme.sizings.fontSizeMd}
                            textColor={theme.colors.palette.black}
                            textAlign="center"
                        >
                            Please type your correct phone number
                        </P>
                        <Spacer />
                        <Input
                            name="phone"
                            label="New Phone Number"
                            showRequired
                            onChange={handleChange}
                            fullWidth
                            value={formatPhoneNumber(values.phone)}
                        />
                        <Spacer lg />
                        <AuthStyledButton
                            disabled={disabled}
                            title={"Update your phone number"}
                            primary
                            fullWidth
                            onPress={(e) => {
                                e.preventDefault()
                                handleSubmit()
                            }}
                            isRoundButton
                        />
                        {updateAccount.isLoading && (
                            <>
                                <Spacer />
                                <P bold="extra" textAlign="center">
                                    Loading...
                                </P>
                            </>
                        )}
                        {serverError && (
                            <>
                                <Spacer />
                                <P bold="extra" error textAlign="center">
                                    {serverError}
                                </P>
                            </>
                        )}
                    </ContentContainer>
                    <Spacer lg />
                </Card>
            </InnerModal>
        </Modal>
    )
}
