export function GreenCheckMark(
    props: React.SVGProps<SVGSVGElement> & {
        width?: number
    }
) {
    return (
        <svg
            width={props?.width || 133}
            height={props?.width * (82 / 133) || 82}
            viewBox="0 0 133 82"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M114.458 15.1863C114.153 15.1863 113.923 14.957 113.847 14.6513L113.771 9.1483C113.771 8.84258 114 8.53687 114.306 8.53687C114.611 8.53687 114.917 8.76615 114.917 9.07188L114.993 14.5749C115.07 14.8806 114.841 15.1863 114.458 15.1863C114.535 15.1863 114.458 15.1863 114.458 15.1863Z"
                fill="#0070F9"
            />
            <path
                d="M62.5113 81.7148C62.4349 81.7148 62.3585 81.7148 62.282 81.6383C61.9763 81.4855 61.8999 81.1797 61.9763 80.874L63.8871 76.7468C64.0399 76.4411 64.3456 76.3646 64.6514 76.441C64.9571 76.5939 65.0335 76.8996 64.9571 77.2054L63.0463 81.3326C62.9699 81.5619 62.7406 81.7148 62.5113 81.7148Z"
                fill="#0070F9"
            />
            <path
                d="M42.6634 0.66449L40.8291 8.53683L48.2429 5.78533L42.6634 0.66449Z"
                fill="#0070F9"
            />
            <path
                d="M2.40806 53.1968L0.57373 61.0692L7.98748 58.3177L2.40806 53.1968Z"
                fill="#0070F9"
            />
            <path
                d="M131.441 49.867C131.288 49.867 131.211 49.7905 131.059 49.7141L128.613 47.5741C128.384 47.3448 128.307 47.039 128.536 46.7333C128.766 46.504 129.071 46.4276 129.377 46.6569L131.823 48.7969C132.052 49.0262 132.129 49.332 131.899 49.6377C131.746 49.7905 131.594 49.867 131.441 49.867Z"
                fill="#0070F9"
            />
            <path
                d="M85.0091 14.1929C84.9327 14.1929 84.8563 14.1929 84.7798 14.1165C84.4741 13.9636 84.3977 13.6579 84.5506 13.3522L87.0728 8.23131C87.2256 7.92559 87.5313 7.84917 87.8371 8.00203C88.1428 8.15489 88.2192 8.46061 88.0664 8.76634L85.5441 13.8872C85.4677 14.1165 85.2384 14.1929 85.0091 14.1929Z"
                fill="#0070F9"
            />
            <path
                d="M120.378 77.3648C120.225 77.3648 120.072 77.2884 119.996 77.212C119.766 76.9827 119.766 76.6005 119.996 76.3712L124.811 71.5561C125.04 71.3268 125.422 71.3268 125.652 71.5561C125.881 71.7854 125.881 72.1676 125.652 72.3969L120.836 77.212C120.607 77.2884 120.531 77.3648 120.378 77.3648Z"
                fill="#0070F9"
            />
            <path
                d="M126.664 23.8449C126.358 23.8449 126.129 23.6157 126.129 23.3099L125.823 20.4056C125.823 20.0999 126.053 19.7941 126.358 19.7941C126.664 19.7941 126.97 20.0234 126.97 20.3291L127.275 23.2335C127.275 23.5392 127.046 23.8449 126.664 23.8449Z"
                fill="#0070F9"
            />
            <path
                d="M32.3133 18.493C32.5907 18.544 32.8403 18.3568 32.965 18.0689L34.0304 12.6696C34.0858 12.3689 33.9331 12.0299 33.6557 11.9789C33.3783 11.9278 33.0594 12.1023 33.004 12.403L31.9386 17.8023C31.8139 18.0902 31.9666 18.4291 32.3133 18.493C32.244 18.4802 32.3133 18.493 32.3133 18.493Z"
                fill="#0070F9"
            />
            <path
                d="M34.7318 75.9121C34.7501 75.9802 34.7683 76.0483 34.8604 76.0967C35.0811 76.3295 35.3946 76.3185 35.6717 76.1713L39.202 73.4003C39.4608 73.185 39.4617 72.8927 39.3148 72.6401C39.0942 72.4072 38.7806 72.4182 38.5036 72.5654L34.9732 75.3364C34.77 75.4639 34.6771 75.7078 34.7318 75.9121Z"
                fill="#0070F9"
            />
            <path
                d="M14.9989 43.1055C15.1399 43.1055 15.2104 43.0291 15.3515 42.9526L17.6079 40.8126C17.8194 40.5833 17.89 40.2776 17.6784 39.9718C17.4669 39.7425 17.1848 39.6661 16.9028 39.8954L14.6463 42.0355C14.4348 42.2647 14.3643 42.5705 14.5758 42.8762C14.7168 43.029 14.8579 43.1055 14.9989 43.1055Z"
                fill="#0070F9"
            />
            <path
                d="M15.0895 65.4837C15.1078 65.5518 15.126 65.6199 15.2181 65.6682C15.4387 65.9011 15.7523 65.8901 16.0111 65.6748L20.3552 62.1017C20.614 61.8864 20.6148 61.5941 20.3942 61.3613C20.1735 61.1284 19.8599 61.1394 19.6011 61.3547L15.257 64.9278C15.0538 65.0552 15.0348 65.2793 15.0895 65.4837Z"
                fill="#0070F9"
            />
            <path
                d="M11.6919 25.622C11.8329 25.622 11.974 25.5456 12.0445 25.4691C12.256 25.2399 12.256 24.8577 12.0445 24.6284L7.60209 19.8133C7.39055 19.584 7.03798 19.584 6.82644 19.8133C6.6149 20.0426 6.6149 20.4247 6.82644 20.654L11.2688 25.4691C11.4804 25.5456 11.5509 25.622 11.6919 25.622Z"
                fill="#0070F9"
            />
            <path
                d="M4.17504 37.4494C4.45709 37.4494 4.66863 37.2201 4.66863 36.9144L4.95068 34.01C4.95068 33.7043 4.73914 33.3986 4.45709 33.3986C4.17503 33.3986 3.89298 33.6278 3.89298 33.9336L3.61092 36.8379C3.61092 37.1437 3.82247 37.4494 4.17504 37.4494Z"
                fill="#0070F9"
            />
            <path
                d="M92.2773 79.3943L96.0989 76.4135L96.8632 81.6107L92.2773 79.3943Z"
                fill="#0070F9"
            />
            <path
                d="M103.164 33.171L109.405 28.3025L110.654 36.7912L103.164 33.171Z"
                fill="#0070F9"
            />
            <circle cx="64.1265" cy="42.0228" r="24.0161" fill="#2EC63C" />
            <path
                d="M52.6567 44.9795L62.9121 52.6052L76.849 30.2539"
                stroke="white"
                stroke-width="5"
            />
        </svg>
    )
}
