export function ThumbsUp() {
    return (
        <svg
            width="20"
            height="19"
            viewBox="0 0 20 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.00846 8.67851L9.3621 1.13281C10.0292 1.13281 10.6689 1.39781 11.1406 1.86951C11.6123 2.3412 11.8773 2.98096 11.8773 3.64804V7.00169H16.6227C16.8658 6.99893 17.1066 7.04906 17.3283 7.14859C17.5501 7.24812 17.7476 7.39468 17.9071 7.5781C18.0666 7.76153 18.1843 7.97745 18.2521 8.21088C18.3198 8.44432 18.336 8.68971 18.2996 8.93003L17.1426 16.4757C17.0819 16.8756 16.8788 17.24 16.5707 17.5019C16.2625 17.7639 15.8701 17.9056 15.4657 17.901H6.00846M6.00846 8.67851V17.901M6.00846 8.67851H3.49323C3.04851 8.67851 2.622 8.85517 2.30754 9.16964C1.99307 9.4841 1.81641 9.91061 1.81641 10.3553V16.2242C1.81641 16.6689 1.99307 17.0954 2.30754 17.4099C2.622 17.7244 3.04851 17.901 3.49323 17.901H6.00846"
                stroke="#2E54E8"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M6.00846 17.901V8.67847H3.49323C3.04851 8.67847 2.622 8.85513 2.30754 9.1696C1.99307 9.48406 1.81641 9.91057 1.81641 10.3553V16.2242C1.81641 16.6689 1.99307 17.0954 2.30754 17.4099C2.622 17.7243 3.04851 17.901 3.49323 17.901H6.00846Z"
                fill="#2E54E8"
                stroke="#2E54E8"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
