import React, { useState } from "react"
import styled from "@emotion/styled"
import { Container, TouchableContainer, P, CancelIcon } from "@wetradeup/ui-kit"
import { useHistory } from "react-router-dom"
import { breakpoints } from "../../../constants"
import { useLogout } from "@wetradeup/student-apps-shared"

const MenuContainer = styled(TouchableContainer)`
    ${(props) => `
        position: fixed;
        top: 32px;
        left: 40px;
        width: 46px;
        height: 46px;
        border-radius: 23px;
        background: ${props.theme.colors.palette.white};
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        z-index: 300;

        @media (max-width: ${breakpoints.desktopSm}) {
            display: none;
        }
    `}
`

const MenuItemContainer = styled(Container)`
    ${(props) => `
        position: absolute;
        overflow: hidden;
        width: 132px;
        height: 72px;
        left: 2px;
        top: 54px;
        background: ${props.theme.colors.palette.white};
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 6px;

        @media (max-width: ${breakpoints.desktopSm}) {
            display: none;
        }
    `}
`

const MenuItem = styled(TouchableContainer)`
    ${(props) => `
        text-align: left;
        font: ${props.theme.fonts.typefaces.secondary};
        &:hover {
            background-color: ${props.theme.colors.palette.medGrey};
        }

        @media (min-width: ${breakpoints.desktopSm}) {
            padding-left: 12px;
        }
    `}
`

export const MenuIcon = () => {
    return (
        <svg
            width="15"
            height="16"
            viewBox="0 0 15 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M0 1H14.5" stroke="black" stroke-width="2" />
            <path d="M0 8H14.5" stroke="black" stroke-width="2" />
            <path d="M0 15H14.5" stroke="black" stroke-width="2" />
        </svg>
    )
}

interface MenuItemsProps {
    setShowMenu: React.Dispatch<React.SetStateAction<boolean>>
}

const MenuItems = ({ setShowMenu }: MenuItemsProps) => {
    const logout = useLogout()
    const history = useHistory()

    const handleLogout = async () => {
        await logout()
        setShowMenu(false)
        history.push("/")
    }

    const handleProfile = () => {
        setShowMenu(false)
        return history.push("/application/intro/select")
    }

    return (
        <>
            <MenuItemContainer flex={1} justifyContent="space-around">
                <MenuItem
                    flex={1}
                    height="50%"
                    justifyContent="center"
                    onPress={handleProfile}
                >
                    <P>View Profile</P>
                </MenuItem>
                <MenuItem
                    flex={1}
                    height="50%"
                    justifyContent="center"
                    onPress={handleLogout}
                >
                    <P>Log out</P>
                </MenuItem>
            </MenuItemContainer>
        </>
    )
}

export const NavBarAuthenticatedMenu = (props: any) => {
    const [showMenu, setShowMenu] = useState(false)
    return (
        <>
            <MenuContainer
                {...props}
                flex={1}
                alignItems="center"
                justifyContent="center"
                onPress={() => {
                    setShowMenu(!showMenu)
                }}
            >
                {showMenu ? <CancelIcon /> : <MenuIcon />}
                {showMenu && <MenuItems setShowMenu={setShowMenu} />}
            </MenuContainer>
        </>
    )
}
