import { Route, Switch } from "react-router-dom"
import { Container, H4, Spacer } from "@wetradeup/ui-kit"
import PendingDecision from "./PendingDecision"
import PendingIdentityVerification from "../finalize/PendingIdentityVerification"
import FailedIdentityVerification from "../finalize/FailedIdentityVerification"
import RejectedDecision from "./RejectedDecision"
import AwaitingSchoolConfirmation from "./AwaitingSchoolConfirmation"
import FinancingChanged from "./FinancingChanged"
import SchoolConfirmed from "./SchoolConfirmed"
import AutopayConsent from "./AutopayConsent"
import Complete from "./Complete"
import { SpinningLoader } from "../application/atoms/SpinningLoader"
import {
    useGetStudentProfile,
    useStudentApplicationDetails,
} from "@wetradeup/student-apps-shared"
import LoanTerms from "./LoanTerms"
import { FraudAlert } from "./FraudAlert"
import { LockedCreditProfile } from "./LockedCreditProfile"
import PendingAutomatic from "./VerifyBank/PendingAutomatic"
import PendingManual from "./VerifyBank/PendingManual"
import VerifyIdentity from "./VerifyBank/VerifyIdentity"
import { CosignerAwaiting } from "./CosignerAwaiting"
import { CosignerCanceled } from "./CosignerCanceled"

interface IslFinalizeRoutesProps {
    studentApplicationDetails: ReturnType<typeof useStudentApplicationDetails>
    studentProfile: ReturnType<typeof useGetStudentProfile>
}
export function IslFinalizeRoutes({
    studentApplicationDetails,
    studentProfile,
}: IslFinalizeRoutesProps) {
    return (
        <Switch>
            <Route path="/isl-finalize" exact>
                <Container paddingLg paddingXl="md">
                    <SpinningLoader />
                    <Spacer />
                    <H4>Loading Your Information</H4>
                </Container>
            </Route>
            <Route path="/isl-finalize/verification">
                <PendingIdentityVerification
                    studentApplicationDetails={studentApplicationDetails}
                />
            </Route>
            <Route path="/isl-finalize/verification-failed">
                <FailedIdentityVerification
                    studentApplicationDetails={studentApplicationDetails}
                />
            </Route>
            <Route path="/isl-finalize/alerts">
                <FraudAlert />
            </Route>
            <Route path="/isl-finalize/locked">
                <LockedCreditProfile
                    studentApplicationDetails={studentApplicationDetails}
                />
            </Route>
            <Route path="/isl-finalize/pending">
                <PendingDecision />
            </Route>
            <Route path="/isl-finalize/decisioned">
                <RejectedDecision />
            </Route>
            <Route path="/isl-finalize/cosigner-awaiting">
                <CosignerAwaiting
                    studentApplicationDetails={studentApplicationDetails}
                />
            </Route>
            <Route path="/isl-finalize/cosigner-canceled">
                <CosignerCanceled
                    studentApplicationDetails={studentApplicationDetails}
                />
            </Route>
            <Route path="/isl-finalize/loan-terms">
                <LoanTerms
                    studentApplicationDetails={studentApplicationDetails}
                />
            </Route>
            <Route path="/isl-finalize/identity">
                <VerifyIdentity
                    studentProfile={studentProfile}
                    studentApplicationDetails={studentApplicationDetails}
                />
            </Route>
            <Route path="/isl-finalize/pending-automatic">
                <PendingAutomatic />
            </Route>
            <Route path="/isl-finalize/pending-manual">
                <PendingManual
                    studentProfile={studentProfile}
                    studentApplicationDetails={studentApplicationDetails}
                />
            </Route>
            <Route path="/isl-finalize/awaiting">
                <AwaitingSchoolConfirmation
                    studentApplicationDetails={studentApplicationDetails}
                />
            </Route>
            <Route path="/isl-finalize/school-confirmed">
                <SchoolConfirmed />
            </Route>
            <Route path="/isl-finalize/financing-changed">
                <FinancingChanged
                    studentApplicationDetails={studentApplicationDetails}
                />
            </Route>
            <Route path="/isl-finalize/final-review">
                <LoanTerms
                    studentApplicationDetails={studentApplicationDetails}
                    isFinancingChanged
                />
            </Route>
            <Route path="/isl-finalize/autopay">
                <AutopayConsent
                    studentApplicationDetails={studentApplicationDetails}
                />
            </Route>
            <Route path="/isl-finalize/complete">
                <Complete
                    studentApplicationDetails={studentApplicationDetails}
                />
            </Route>
        </Switch>
    )
}
