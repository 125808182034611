import {
    ErrorMessage,
    NonStickyErrorContainer,
    H2,
    H3,
    Input,
    Container,
    P,
} from "@wetradeup/ui-kit"
import styled from "@emotion/styled"
import { SyntheticEvent, useState } from "react"
import { InputProps } from "@material-ui/core"
import { LockIcon } from "./icons/Lock"

const BorrowingInputChild = styled(Input)`
    font-size: 30px;
    text-align: right;
    margin-left: 20px;
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`

export const CentsInput = styled(Input)`
    padding-top: 3px;
    font-size: 24px;
    height: 40px;
`

export const DollarIcon = styled(H3)`
    margin: 0px;
    padding: 0px;
`

export const DotStyle = styled(H2)`
    padding-top: 34px;
`

interface BorrowingInputProps {
    handleFinancingAmountChange: (value: number) => void
    max: number
    value?: number
    serverError?: string
    disabled?: boolean
}

export const BorrowingInput = ({
    handleFinancingAmountChange,
    max,
    value,
    serverError,
    disabled,
}: BorrowingInputProps & InputProps) => {
    const [error, setError] = useState<string | null>(null)
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (
            e.target.value.indexOf(".") > -1 &&
            e.target.value.length - e.target.value.indexOf(".") > 2
        ) {
            e.target.value = parseFloat(e.target.value).toFixed(2)
        }

        const newValue = e.target.valueAsNumber
        if (Number.isNaN(newValue)) {
            setError("Please type a valid dollar amount")
        } else {
            setError(null)
        }

        handleFinancingAmountChange(newValue)
    }

    const isValid = !!value && !error && !serverError
    return (
        <Container>
            <BorrowingInputChild
                focusedBorder="blue"
                focusedBackground="white"
                id="financingAmount"
                onChange={handleChange}
                max={max}
                placeholder={"Enter amount"}
                icon={
                    <DollarIcon textColor={isValid ? "blue" : "grey"}>
                        $
                    </DollarIcon>
                }
                iconPosition="left"
                flex={2}
                showRequired
                autoFocus
                type="number"
                value={value}
                grid
                disabled={disabled}
                onWheel={(e: SyntheticEvent) =>
                    (e.target as HTMLInputElement).blur()
                }
                {...(disabled
                    ? {
                          secondIcon: <LockIcon />,
                          secondIconPosition: "right",
                      }
                    : {})}
            />
            {(error || serverError) && (
                <NonStickyErrorContainer>
                    <ErrorMessage>{error || serverError}</ErrorMessage>
                </NonStickyErrorContainer>
            )}
        </Container>
    )
}

interface InSchoolPaymentAmountInputProps {
    handleInSchoolPaymentChange: (value: number) => void
    max: number
    value?: number
    serverError?: string
}

export const InSchoolPaymentAmountInput = ({
    handleInSchoolPaymentChange,
    max,
    value,
    serverError,
}: InSchoolPaymentAmountInputProps) => {
    const [error, setError] = useState<string | null>(null)
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (
            e.target.value.indexOf(".") > -1 &&
            e.target.value.length - e.target.value.indexOf(".") > 2
        ) {
            e.target.value = parseFloat(e.target.value).toFixed(2)
        }

        const newValue = e.target.valueAsNumber
        if (e.target.value !== "" && Number.isNaN(newValue)) {
            setError("Please type a valid dollar amount")
        } else {
            setError(null)
        }

        handleInSchoolPaymentChange(newValue)
    }

    const isValid = !!value && !error && !serverError

    return (
        <Container>
            <BorrowingInputChild
                backgroundColor="white"
                focusedBorder="blue"
                focusedBackground="white"
                id="inSchoolPayment"
                onChange={handleChange}
                max={max}
                placeholder="00.00"
                icon={
                    <DollarIcon textColor={isValid ? "black" : "grey"}>
                        $
                    </DollarIcon>
                }
                iconPosition="left"
                flex={2}
                showRequired
                autoFocus
                type="number"
                value={value}
                grid
                onWheel={(e: SyntheticEvent) =>
                    (e.target as HTMLInputElement).blur()
                }
                secondIcon={
                    <P textColor="black" textSize="32px">
                        /mo
                    </P>
                }
                secondIconPosition="right"
            />
            {(error || serverError) && (
                <>
                    <NonStickyErrorContainer>
                        <ErrorMessage>{error || serverError}</ErrorMessage>
                    </NonStickyErrorContainer>
                </>
            )}
        </Container>
    )
}
