import { useState, useRef } from "react"
import {
    Card,
    Container,
    H2,
    HR,
    P,
    Spacer,
    TouchableContainer,
} from "@wetradeup/ui-kit"
import { RouteComponentProps } from "react-router-dom"
import { useGetQuestionnaire } from "./Questionnaire/hooks/useGetQuestionnaire"
import { Review } from "./Questionnaire/Review"
import { useGetApplicationForm } from "@wetradeup/student-apps-shared"
import { ContentContainer } from "../ModalComponents"
import { SpinningLoader } from "./atoms/SpinningLoader"
import { DecisionModal } from "./molecules/modals/DecisionModal"
import { SubmitButtonContainer } from "./molecules/SubmitButtonContainer"
import { Terms } from "./IntroForm/ConditionsAgreement"
import { ClickableCaption } from "./atoms/ClickableCaption"
import styled from "@emotion/styled"

const CardLoader = () => (
    <Container
        width="100%"
        paddingMd
        justifyContent="center"
        alignItems="center"
    >
        <SpinningLoader />
    </Container>
)

const InvisibleContainer = styled(Container)`
    ${(props) => `
        ${props.isCollapsed && `display: none;`}
    `}
`

interface ReviewQuestionnaireProps {
    appFormId?: number
    employerId?: number
    programId?: number
}

export default function ReviewQuestionnaire({
    match,
    employerId,
    appFormId,
    programId,
}: ReviewQuestionnaireProps & RouteComponentProps<{ schoolId?: string }>) {
    const schoolId = parseInt(match.params.schoolId)
    const productType = employerId ? "employer" : "isl"
    const loadedInfoProps = !!programId

    const { questionnaire, setQuestionnaire } = useGetQuestionnaire(
        schoolId,
        productType,
        loadedInfoProps
    )
    const applicationFormQuery = useGetApplicationForm(appFormId)
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const [ready, setReady] = useState(false)
    const [unanswered, setUnanswered] = useState(null)
    const [decision, setDecision] = useState("")
    const [isCollapsed, setIsCollapsed] = useState(true)
    const handleSubmitRef = useRef()

    const disabled =
        !unanswered || Object.keys(unanswered).length > 0 || loading || !ready

    return (
        <Container paddingMd maxWidth={[550, [750, "lg"]]} alignItems="center">
            <Spacer />
            <H2 bold="light"> You're ready to submit! </H2>
            <Spacer size={20} />
            <ContentContainer>
                <Container display="flex">
                    <P textAlign="left">
                        You can double check your answers by clicking “Review”
                        in the section below, otherwise you’re ready to submit
                        your loan application!
                    </P>
                </Container>
            </ContentContainer>
            <Spacer lg />
            <Card
                disableShadow
                backgroundColor="questionnaireGrey"
                maxWidth="600px"
                width="100%"
            >
                {questionnaire?.length === 0 && <SpinningLoader />}
                {questionnaire?.length > 0 && (
                    <Container paddingMd row justifyContent="space-between">
                        <P textColor="black" textSize="20px">
                            Your Answers
                        </P>
                        <TouchableContainer
                            onPress={() => {
                                setIsCollapsed(!isCollapsed)
                            }}
                        >
                            <ClickableCaption>
                                {isCollapsed ? "Review" : "Collapse"}
                            </ClickableCaption>
                        </TouchableContainer>
                    </Container>
                )}
                <InvisibleContainer isCollapsed={isCollapsed}>
                    <Spacer size={16} />
                    <HR />
                    {questionnaire?.length > 0 &&
                    !!applicationFormQuery.data?.questionnaire ? (
                        <Review
                            schoolId={schoolId}
                            appFormId={appFormId}
                            questionnaire={questionnaire}
                            setQuestionnaire={setQuestionnaire}
                            setLoading={setLoading}
                            handleSubmitRef={handleSubmitRef}
                            setError={setError}
                            answers={applicationFormQuery.data?.questionnaire}
                            employerId={employerId}
                            setDecision={setDecision}
                            setUnanswered={setUnanswered}
                            setReady={setReady}
                            ready={ready}
                        />
                    ) : (
                        <CardLoader />
                    )}
                </InvisibleContainer>
            </Card>
            <Container maxWidth="600px">
                <Spacer lg />
                <HR />
                <Spacer />
                <Terms textAlign="left">
                    By clicking "Submit Application" below, you are officially
                    submitting your application for a loan with Fynn, and you
                    are providing Fynn with written instruction under the Fair
                    Credit Reporting Act, authorizing Fynn to obtain information
                    from your personal credit report or other information from
                    one or more consumer reporting agency.
                </Terms>
                <Spacer lg />
            </Container>
            <ContentContainer justifyContent="center" alignItems="center">
                {!loading && ready && (
                    <Container width={300} maxWidth="95vw">
                        <SubmitButtonContainer
                            fullWidth
                            handleSubmit={handleSubmitRef.current}
                            disabled={disabled}
                            errors={unanswered}
                            title="Submit Application"
                        />
                    </Container>
                )}
                {(loading || !ready) && <SpinningLoader />}
                {!!error && (
                    <>
                        <Spacer />
                        <Container row justifyContent="center">
                            <P bold error>
                                {error}
                            </P>
                            <Spacer />
                        </Container>
                    </>
                )}
            </ContentContainer>
            <DecisionModal
                decision={decision}
                appFormId={appFormId}
                setDecision={setDecision}
                setError={setError}
            />
            <Spacer lg />
        </Container>
    )
}
