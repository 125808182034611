import { Container } from "@wetradeup/ui-kit"
import { TailSpin } from "react-loader-spinner"

interface SpinningLoaderProps {
    size?: number
}

export const SpinningLoader = ({ size }: SpinningLoaderProps) => (
    <Container flex alignItems="center">
        <TailSpin color="#00308b" height={size || 40} width={size || 40} />
    </Container>
)
