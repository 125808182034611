export function Home() {
    return (
        <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.30981 9.50478L12.509 2.34985L21.7082 9.50478V20.7482C21.7082 21.2904 21.4928 21.8104 21.1095 22.1938C20.7261 22.5771 20.2061 22.7925 19.6639 22.7925H5.35408C4.81191 22.7925 4.29194 22.5771 3.90857 22.1938C3.52519 21.8104 3.30981 21.2904 3.30981 20.7482V9.50478Z"
                stroke="#0070F9"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M9.44263 22.7926V12.5713H15.5754V22.7926"
                stroke="#0070F9"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
