import * as Yup from "yup"

export const createAccountValSchema = Yup.object().shape({
    program: Yup.string().required("Please choose your program."),
    campus: Yup.string(),
    anticipated_start_date: Yup.date().nullable(true),
    first: Yup.string().when("is_creating_new_account", {
        is: true,
        then: Yup.string()
            .required("Please enter your first name.")
            .min(2, "Please enter your full name."),
    }),
    last: Yup.string().when("is_creating_new_account", {
        is: true,
        then: Yup.string()
            .required("Please enter your last name.")
            .min(2, "Please enter your full name."),
    }),
    email: Yup.string().when("is_creating_new_account", {
        is: true,
        then: Yup.string().email().required("Please enter your email."),
    }),
    phone: Yup.string().when("is_creating_new_account", {
        is: true,
        then: Yup.string()
            .matches(/^[2-9]\d{9}/, "Please enter a valid US phone number")
            .required(),
    }),
    passwordOne: Yup.string().when("is_creating_new_account", {
        is: true,
        then: Yup.string()
            .required("Please enter your password.")
            .min(8, "Password is too short.")
            .max(32, "Password is too long.")
            .matches(
                /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                "Please include at least one uppercase/lowercase, one number, and one special character."
            ),
    }),
    passwordTwo: Yup.string().when("is_creating_new_account", {
        is: true,
        then: Yup.string()
            .required("Please confirm your password.")
            .oneOf([Yup.ref("passwordOne"), null], "Passwords must match"),
    }),
    passwordRequired: Yup.bool(),
    is_creating_new_account: Yup.bool(),
})

const minDate = new Date(1900, 1, 1)
const maxDate = new Date(new Date().getFullYear() - 10, 1, 1)

export const basicInfoSchema = Yup.object().shape({
    street: Yup.string()
        .required("Please enter your street address.")
        .min(5, "Please enter your full address."),
    street_line_two: Yup.string(),
    city: Yup.string()
        .required("Please enter your city.")
        .min(3, "Please enter your full city"),
    state: Yup.string().required("Please choose your state."),
    zip: Yup.string()
        .typeError("Please enter your zip.")
        .required("Please enter your zip.")
        .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/),
    citizen: Yup.string()
        .oneOf(["us_citizen", "us_permanent_resident", "others"])
        .typeError("Please specify your residency status."),
    dob: Yup.date()
        .typeError("Please enter a valid date of birth.")
        .min(minDate, "Please enter a valid date of birth.")
        .max(maxDate, "Please enter a valid date of birth.")
        .required("Please enter your date of birth."),
    education_level: Yup.string().required(
        "Please specify your education level"
    ),
    ssn: Yup.string()
        .typeError("Please enter your real SSN.")
        .required("Please enter your SSN.")
        .matches(
            /^(?!666|000|9\d{2})\d{3}[- ]{0,1}(?!00)\d{2}[- ]{0,1}(?!0{4})\d{4}$/,
            "Please enter a valid SSN."
        )
        .notOneOf(
            ["111111111", "333333333", "123456789"],
            "Please enter a valid SSN."
        ),
    confirmSSN: Yup.string()
        .typeError("SSNs must match.")
        .required("Please confirm your SSN.")
        .oneOf([Yup.ref("ssn"), null], "SSNs must match."),
    program: Yup.string().required("Please choose your program."),
    campus: Yup.string(),
    anticipated_start_date: Yup.date().nullable(true),
})

export const personalDetailsSchema = Yup.object().shape({
    consent: Yup.bool().oneOf(
        [true],
        "Please accept the terms and conditions."
    ),
    ssn: Yup.string()
        .typeError("Please enter your real SSN.")
        .required("Please enter your SSN.")
        .matches(
            /^(?!666|000|9\d{2})\d{3}[- ]{0,1}(?!00)\d{2}[- ]{0,1}(?!0{4})\d{4}$/,
            "Please enter a valid SSN."
        )
        .notOneOf(
            ["111111111", "333333333", "123456789"],
            "Please enter a valid SSN."
        ),
    confirmSSN: Yup.string()
        .typeError("SSNs must match.")
        .required("Please confirm your SSN.")
        .oneOf([Yup.ref("ssn"), null], "SSNs must match."),
    dob: Yup.date()
        .min(minDate, "Please enter a valid date of birth.")
        .max(maxDate, "Please enter a valid date of birth.")
        .required("Please enter your date of birth."),
    gender: Yup.object().required("Please specify your gender."),
    photo_id: Yup.string().required("Upload photo identification"),
})

export const infoSchema = Yup.object().shape({
    street: Yup.string()
        .required("Please enter your street address.")
        .min(5, "Please enter your full address."),
    city: Yup.string()
        .required("Please enter your city.")
        .min(3, "Please enter your full city"),
    state: Yup.string().required("Please choose your state."),
    zip: Yup.string()
        .typeError("Please enter your zip.")
        .required("Please enter your zip.")
        .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/),
    consent: Yup.bool().oneOf(
        [true],
        "Please accept the terms and conditions."
    ),
    ssn: Yup.string()
        .typeError("Please enter your real SSN.")
        .required("Please enter your SSN.")
        .matches(
            /^(?!666|000|9\d{2})\d{3}[- ]{0,1}(?!00)\d{2}[- ]{0,1}(?!0{4})\d{4}$/,
            "Please enter a valid SSN."
        )
        .notOneOf(
            ["111111111", "333333333", "123456789"],
            "Please enter a valid SSN."
        ),
    confirmSSN: Yup.string()
        .typeError("SSNs must match.")
        .required("Please confirm your SSN.")
        .oneOf([Yup.ref("ssn"), null], "SSNs must match."),
    dob: Yup.date()
        .min(minDate, "Please enter a valid date of birth.")
        .max(maxDate, "Please enter a valid date of birth.")
        .required("Please enter your date of birth."),
    gender: Yup.object().required("Please specify your gender."),
    photo_id: Yup.string().required("Upload photo identification"),
})
