import React from "react"
import { BackgroundImage, Container, H2, Spacer } from "@wetradeup/ui-kit"

import notFoundImage from "../../assets/notfound.png"

export default function NotFound() {
    return (
        <Container paddingLg>
            <BackgroundImage
                contain
                noRepeat
                height="50vh"
                source={notFoundImage}
                alt="404 error!"
            />
            <Spacer />
            <H2 black>Whoops! This isn't a valid page. Try going back!</H2>
        </Container>
    )
}
