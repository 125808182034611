import { useGetStudentProfile } from "@wetradeup/student-apps-shared"
import { Container, P, Spacer } from "@wetradeup/ui-kit"
import { GreenCheck } from "../icons/GreeCheck"
import { OrangeTimerCheck } from "../icons/OrangeTimerCheck"

interface VerifyBankIDUploadStatusProps {
    studentProfile: ReturnType<typeof useGetStudentProfile>
    hasCompletedBank: boolean
    statusOverride?: string
}

export function VerifyBankIDUploadStatus({
    studentProfile,
    hasCompletedBank,
    statusOverride,
}: VerifyBankIDUploadStatusProps) {
    const stripeStatus =
        statusOverride ||
        (studentProfile.data?.document_identity_verification === "approved"
            ? "completed"
            : studentProfile.data?.document_identity_verification ===
                  "rejected" && hasCompletedBank
            ? "retry"
            : "pending")

    const stepLabel =
        stripeStatus === "retry" &&
        studentProfile.data?.stripe_identity_verification_result?.code ===
            "document_expired"
            ? "Your ID is expired. Please upload a different ID"
            : stripeStatus === "retry"
            ? "The document type you uploaded is not supported"
            : "Step One: Photo ID Verification - "

    const stepBody =
        stripeStatus === "retry" &&
        studentProfile.data?.stripe_identity_verification_result?.code ===
            "document_expired"
            ? "The ID you previously uploaded was not valid. Please try again with a different form of ID."
            : stripeStatus === "retry"
            ? "The ID you previously uploaded was a document we support. Please try again with a different form of ID."
            : ""
    return (
        <>
            <Container row alignItems="center" wrap>
                <Container row alignItems="center">
                    <P
                        bold
                        textColor={
                            stripeStatus === "retry" ? "black" : "bodyGrey"
                        }
                    >
                        {stepLabel}
                    </P>
                    <Spacer sm />
                </Container>
                <Container row alignItems="center">
                    {stripeStatus === "completed" && (
                        <>
                            <P textColor="medGreen" bold>
                                Complete
                            </P>
                            <Spacer sm />
                            <GreenCheck />
                        </>
                    )}
                    {stripeStatus === "pending" && (
                        <>
                            <P textColor="orange" bold>
                                Pending Manual Review
                            </P>
                            <Spacer sm />
                            <OrangeTimerCheck />
                        </>
                    )}
                </Container>
            </Container>
            {stripeStatus === "retry" && (
                <Container>
                    <Spacer sm />
                    <P textColor="black">{stepBody}</P>
                </Container>
            )}
        </>
    )
}
