import { BrowserRouter } from "react-router-dom"
import {
    GlobalReset,
    externalTheme,
    UIKitThemeProvider,
} from "@wetradeup/ui-kit"

import {
    setStorageProvider,
    localStorageProvider,
    AuthProvider,
    setConfigProvider,
    QueryClientProvider,
} from "@wetradeup/student-apps-shared"
import { Routes } from "./routes"
import "./App.css"
import { config } from "../config"
import { ErrorBoundary } from "./ErrorBoundary"

setConfigProvider(config)
setStorageProvider(localStorageProvider)

export default function App() {
    const ua = window.navigator.userAgent
    const isIE = /MSIE|Trident/.test(ua)
    if (isIE)
        alert(
            "Hey! You are trying to access the Fynn student application, but we currently do not support Internet Explorer. \n \nTry accessing this site on Chrome, Firefox, Safari, or Edge."
        )

    return (
        <div className="App">
            <GlobalReset />
            <UIKitThemeProvider theme={externalTheme}>
                <ErrorBoundary>
                    <QueryClientProvider>
                        <AuthProvider>
                            <BrowserRouter>
                                <Routes />
                            </BrowserRouter>
                        </AuthProvider>
                    </QueryClientProvider>
                </ErrorBoundary>
            </UIKitThemeProvider>
        </div>
    )
}
