import { AuthContext, useLogout } from "@wetradeup/student-apps-shared"
import { useCallback, useContext, useMemo } from "react"
import { QueryCache } from "react-query"
import { useHistory } from "react-router-dom"
import { useFynnLocation } from "./useFynnLocation"

const desktopNavBarPages = [
    "/application/intro",
    "/loan-orientation",
    "/loan-orientation/admin",
    "/loan-orientation/login",
    "/loan-orientation/admin/login",
    "/loan-orientation/overview",
    "/loan-orientation/repayment",
    "/loan-orientation/repayment-how-to",
    "/loan-orientation/missed-payment",
    "/loan-orientation/member-benefits",
    "/loan-orientation/quiz",
    "/loan-orientation/result",
    "/login",
    "/reset-password",
    "/not-found",
    "/server-error",
    "/coming-soon",
]

const nonAuthExitCounselingPages = [
    "/loan-orientation",
    "/loan-orientation/admin",
    "/loan-orientation/login",
    "/loan-orientation/admin/login",
]

export function useNavBar() {
    const history = useHistory()
    const location = useFynnLocation()
    const { authProfile } = useContext(AuthContext)
    const logout = useLogout()

    const applicationCTAClick = useCallback(() => {
        if (authProfile) {
            return history.push("/application/intro/select")
        }
    }, [authProfile])

    const signInCTAText = useMemo(() => {
        if (authProfile) return "Log out"
        if (!authProfile && location.pathname === "/login") return null

        return "Sign in"
    }, [authProfile, location.pathname])

    const applicationCTAText = useMemo(() => {
        if (authProfile) return "View Profile"
        return null
    }, [authProfile])

    const handleSignIn = useCallback(async () => {
        if (authProfile) {
            await logout()
            const queryCache = new QueryCache()
            queryCache.clear()
        } else {
            return history.push("/login")
        }
    }, [authProfile])

    const navDesktopHidden = !desktopNavBarPages.includes(location.pathname)
    const navMobileHidden = location.pathname === "/application/intro/account"

    const showDefaultNavBar = desktopNavBarPages.includes(location.pathname)
    const showAuthenticatedNavBar =
        !showDefaultNavBar &&
        location.pathname !== "/application/intro" &&
        location.pathname !== "/application/intro/account"
    const isExitCounseling = location.pathname.indexOf("/loan-orientation") >= 0
    const showExitCounselingSignOut =
        isExitCounseling &&
        !nonAuthExitCounselingPages.includes(location.pathname)

    return {
        applicationCTAText,
        applicationCTAClick,
        signInCTAText,
        handleSignIn,
        navDesktopHidden,
        navMobileHidden,
        showDefaultNavBar,
        showAuthenticatedNavBar,
        isExitCounseling,
        showExitCounselingSignOut,
    }
}
