import styled from "@emotion/styled"
import { P } from "@wetradeup/ui-kit"

export const UnderlineText = styled(P)`
    text-decoration: underline;
`

export const UnderlineItalicText = styled(UnderlineText)`
    font-style: italic;
`
