import { useState, useEffect, useCallback } from "react"
import { useSendEmailVerificationApp } from "@wetradeup/student-apps-shared"
import { TouchableContainer, Caption } from "@wetradeup/ui-kit"
import { ClickableCaption } from "../atoms/ClickableCaption"

export const ResendEmail = ({ schoolId }: { schoolId: number }) => {
    const [emailSent, setEmailSent] = useState(false)
    const sendEmailVerificationMutation = useSendEmailVerificationApp()

    useEffect(() => {
        if (emailSent) {
            setTimeout(() => {
                setEmailSent(false)
            }, 5000)
        }
    }, [emailSent])

    const sendEmailVerification = useCallback(async () => {
        await sendEmailVerificationMutation.mutate(schoolId)
        setEmailSent(true)
    }, [schoolId])

    return (
        <>
            {emailSent ? (
                <Caption weight={600} textAlign="center">
                    {" "}
                    Email sent!{" "}
                </Caption>
            ) : (
                <TouchableContainer onPress={sendEmailVerification}>
                    <ClickableCaption textAlign="center">
                        {" "}
                        Resend verification email{" "}
                    </ClickableCaption>
                </TouchableContainer>
            )}
        </>
    )
}
