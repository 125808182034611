import { Container, H2, P, SecureLabel, Spacer } from "@wetradeup/ui-kit"
import { SpinningLoader } from "../../application/atoms/SpinningLoader"
import { ApplicationCard } from "../../ModalComponents"

export function BankVerificationLoadingView() {
    return (
        <>
            <ApplicationCard
                alignItems="center"
                justifyContent="center"
                disableCard
                border
                paddingLg
                width="100%"
            >
                <SpinningLoader />
                <Spacer lg />
                <H2 bold="light" textColor="black">
                    Please wait. We are verifying your information.
                </H2>
                <Spacer lg />
                <P textAlign="center">
                    This should only take a moment. Please do not refresh the
                    page.
                </P>
                <Spacer />
                <Container alignItems="center" justifyContent="center">
                    <Container
                        maxWidth={600}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <SecureLabel />
                    </Container>
                </Container>
            </ApplicationCard>
        </>
    )
}
