import {
    Card,
    Container,
    H2,
    Modal,
    P,
    Spacer,
    TouchableContainer,
} from "@wetradeup/ui-kit"
import { ContentContainer } from "../../../ModalComponents"
import { UnderlineText } from "../../../atoms/UnderlineText"
import { useAcknowledgeRejection } from "@wetradeup/student-apps-shared"
import { SpinningLoader } from "../../atoms/SpinningLoader"
import { ApplicationButton } from "../../../atoms/ApplicationButton"

interface RejectionConfirmationModalProps {
    open: boolean
    closeModal: () => void
}

export function RejectionConfirmationModal({
    open,
    closeModal,
}: RejectionConfirmationModalProps) {
    const acknowledgeRejection = useAcknowledgeRejection()
    const handleAcknowledgeRejection = async () => {
        await acknowledgeRejection.mutateAsync()
        closeModal()
    }
    return (
        <Modal visible={open} width="100%">
            <Container
                width={[
                    [550, "md"],
                    [750, "lg"],
                ]}
            >
                <Card paddingMd>
                    <ContentContainer
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Spacer lg />
                        <H2 bold="light" textColor="black">
                            Are you sure?
                        </H2>
                        <Spacer lg />
                        <P textColor="black">
                            If you leave now, you won't be able to add any new
                            co-signers to this application, and you won't be
                            able to reapply for 90 days.
                        </P>
                        <Spacer lg />
                        <Container alignItems="center" width="100%">
                            {acknowledgeRejection.isLoading && (
                                <SpinningLoader />
                            )}
                            {!acknowledgeRejection.isLoading && (
                                <ApplicationButton
                                    fullWidth
                                    primary
                                    onPress={handleAcknowledgeRejection}
                                    title={"Yes"}
                                    id="acknowledgeRejection"
                                />
                            )}
                            <Spacer lg />
                            <TouchableContainer
                                onPress={() => {
                                    closeModal()
                                }}
                            >
                                <UnderlineText textColor="black">
                                    Never mind, go back
                                </UnderlineText>
                            </TouchableContainer>
                        </Container>
                    </ContentContainer>
                </Card>
            </Container>
        </Modal>
    )
}
