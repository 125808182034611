import styled from "@emotion/styled"
import { Card, Container, Spacer } from "@wetradeup/ui-kit"
import { useEffect } from "react"
import { toTop } from "../../../utils"
import { useNavBar } from "../../hooks/useNavBar"
import { useWindowSize } from "../../hooks/useWindowSize"
import { SignOutButton } from "../../molecules/NavBar/NavBarExitCounselingSignOut"
import { ExitCounselingHeaderRoutes } from "./ExitCounselingHeaderRoutes"

const CustomContainer = styled(Container)`
    @media (min-width: ${(props) => props.theme.breakpoints.md}px) {
        padding: 0px 66px 60px 66px;
    }

    padding: 0px 12px 18px 12px;
`

const LoginContainer = styled(Container)`
    padding: 30px 30px;
`

export function DefaultPage({ children }) {
    const { windowSize } = useWindowSize()
    const { showExitCounselingSignOut } = useNavBar()
    useEffect(() => {
        toTop()
    }, [])

    return (
        <>
            <Card
                background="white"
                maxWidth={750}
                width="100%"
                alignItems="center"
                disablePadding
            >
                <CustomContainer width="100%" alignItems="center">
                    <ExitCounselingHeaderRoutes />
                    {children}
                </CustomContainer>
            </Card>
            {windowSize.width < 1440 && showExitCounselingSignOut && (
                <>
                    <Spacer lg />
                    <SignOutButton />
                </>
            )}
        </>
    )
}

export function LoginPage({ children }) {
    return (
        <Card
            background="white"
            maxWidth={400}
            width="100%"
            alignItems="center"
            disablePadding
        >
            <LoginContainer width="100%" alignItems="center">
                {children}
            </LoginContainer>
        </Card>
    )
}
