import { useLocation } from "react-router-dom"
import { getNonTrailingSlashPathname } from "../../utils"

export function useFynnLocation() {
    const location = useLocation<{
        disableAutoRouting?: boolean
        redirectToCurrentApplicationForm?: boolean
        appliedToTheWrongProgram?: boolean
        createNewApplication?: boolean
        emailDuplication?: boolean
    }>()
    const noTrailingSlashPath = getNonTrailingSlashPathname(location.pathname)

    return {
        ...location,
        pathname: noTrailingSlashPath,
        isEmailRoute: noTrailingSlashPath.includes("/email"),
    }
}
