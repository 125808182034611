import { useTheme } from "@emotion/react"
import { useHistory, useRouteMatch } from "react-router-dom"
import { Card, Container, HR, P, Spacer } from "@wetradeup/ui-kit"
import styled from "@emotion/styled"
import { ApplicationButton } from "../../atoms/ApplicationButton"
import { DefaultPage } from "../molecules/DefaultPage"
import { UnderlineText } from "../../atoms/UnderlineText"
import { FirstTier } from "../icons/FirstTier"
import {
    ExitCounseling,
    MEMBER_BENEFITS_URL,
    OnboardedExitCounseling,
} from "@wetradeup/student-apps-shared"
import { formatPercent } from "../../../utils"
import { SecondTier } from "../icons/SecondTier"
import { PaymentPause } from "../icons/PaymentPause"
import { useWindowSize } from "../../hooks/useWindowSize"
import { SpinningLoader } from "../../application/atoms/SpinningLoader"
import { useProperExitCounseling } from "../hooks/useProperExitCounseling"
import { useProperUpdateExitCounselingStatus } from "../hooks/useProperUpdateExitCounselingStatus"
import { MemberBenefitGuard } from "../icons/MemberBenefitGuard"
import { PaymentPauseTimer } from "../icons/PaymentPauseTimer"
import { ExitCounselingMemberBenefitOverlay } from "../molecules/ExitCounselingMemberBenefitOverlay"
import { ExitCounselingMemberBenefitMobileLockedFooter } from "../molecules/ExitCounselingMemberBenefitMobileLockedFooter"

export const StyledList = styled.li`
    ${(props) => `
        list-style-type: disc;
        color: ${props.theme.colors.palette.black};
    `}
`
export const StyledUl = styled.ul`
    margin-left: 12px;
    text-align: left;
    list-style-position: outside;
`

const BenefitCard = styled(Card)`
    border: 2px solid
        ${(props) =>
            props.showRedBorder
                ? props.theme.colors.palette.errorRed
                : props.theme.colors.palette.medLightGrey};
    border-radius: ${(props) =>
        props.showRedBorder ? "5px 5px 0px 0px" : "5px"};
`

const Banner = styled(Container)`
    ${(props) => `
        border-radius: 6px;
        border-left: 13px solid ${props.theme.colors.palette.newBlue};
    `}
`

const ImgContainer = styled(Container)`
    mix-blend-mode: darken;
`

export const determineAchOverlay = (
    exitCounselingData: OnboardedExitCounseling | ExitCounseling
) => {
    if (exitCounselingData.onboarded_to_servicer) {
        const data = exitCounselingData as OnboardedExitCounseling
        return data.is_ach_enabled
    } else {
        return true
    }
}

export function ExitCounselingMemberBenefits() {
    const theme = useTheme()
    const history = useHistory()
    const { windowSize } = useWindowSize()

    const match = useRouteMatch<{ accountId?: string }>({
        path: "/loan-orientation/admin/:accountId/member-benefits",
    })
    const exitCounseling = useProperExitCounseling(match)
    const updateExitCounselingStatus =
        useProperUpdateExitCounselingStatus(match)

    const handleContinue = async () => {
        await updateExitCounselingStatus.mutateAsync({
            exit_counseling_stage: "member_benefits",
        })
        if (match?.params?.accountId) {
            history.push(
                `/loan-orientation/admin/${match?.params?.accountId}/credit-scores`
            )
        } else {
            history.push("/loan-orientation/credit-scores")
        }
    }

    const isMobile = windowSize.width <= theme.breakpoints.sm

    if (!exitCounseling.data) {
        return <SpinningLoader />
    }

    return (
        <DefaultPage>
            <Card border="medGrey" disableShadow width="100%" paddingLg>
                <P
                    textAlign="left"
                    textSize={theme.sizings.fontSizeMd}
                    bold
                    textColor="black"
                >
                    How can I stay on track if I'm having trouble making my
                    payments?
                </P>
                <Spacer lg />
                <P textColor="black">
                    Your loan comes with a few great tools that can help to
                    reduce your burden, we call them your{" "}
                    <P textColor="newBlue" bold>
                        member benefits
                    </P>
                    .
                </P>
                <Spacer lg />
                <BenefitCard
                    disableShadow
                    backgroundColor="medLightGrey"
                    showRedBorder={
                        !determineAchOverlay(exitCounseling.data) && isMobile
                    }
                >
                    {isMobile && (
                        <Container col>
                            <Card
                                flex={1}
                                paddingLg
                                col
                                disableShadow
                                backgroundColor="medLightBlue"
                            >
                                <P
                                    bold
                                    textColor="newBlue"
                                    textSize={theme.sizings.fontSizeXl}
                                    lineHeight={theme.sizings.fontSizeJumboSm}
                                    textAlign={"left"}
                                >
                                    Get up to 20% off your payment!
                                </P>
                            </Card>
                            <Spacer lg />
                        </Container>
                    )}
                    {!isMobile && (
                        <Container
                            {...(windowSize.width > theme.breakpoints.sm
                                ? { row: true }
                                : { col: true })}
                        >
                            <ImgContainer
                                flex={1}
                                alignItems="center"
                                justifyContent="center"
                                wrap
                            >
                                <MemberBenefitGuard />
                            </ImgContainer>
                            <Container flex={1} paddingMd col>
                                <P
                                    bold
                                    textColor="newBlue"
                                    textSize={theme.sizings.fontSizeXl}
                                    lineHeight={theme.sizings.fontSizeXl}
                                    textAlign={
                                        windowSize.width > theme.breakpoints.sm
                                            ? "left"
                                            : "center"
                                    }
                                >
                                    Payment <br />
                                    Reduction
                                </P>
                                <Spacer />
                                <StyledUl>
                                    <StyledList>
                                        <P textColor="black">
                                            Lasts up to 6 months
                                        </P>
                                    </StyledList>
                                    <StyledList>
                                        <P textColor="black">
                                            No limit to how many times you can
                                            qualify
                                        </P>
                                    </StyledList>
                                </StyledUl>
                            </Container>
                        </Container>
                    )}
                    {windowSize.width > theme.breakpoints.sm && <Spacer lg />}
                    <Card
                        width="100%"
                        backgroundColor="white"
                        border="medGrey"
                        disableShadow
                        disablePadding
                    >
                        <Container
                            paddingMd
                            {...(windowSize.width > theme.breakpoints.sm
                                ? { row: true }
                                : { col: true, gap: "16px" })}
                            width="100%"
                            alignItems="center"
                        >
                            <Container width={100} alignItems="center">
                                <FirstTier />
                            </Container>
                            <P textColor="black">
                                Fynn will pay{" "}
                                <P bold textColor="black">
                                    {formatPercent(
                                        exitCounseling.data.discount_one * 100
                                    )}
                                    %
                                </P>{" "}
                                of your payment if you're making less than{" "}
                                <P textColor="black" bold>
                                    $
                                    {(
                                        exitCounseling.data
                                            .discount_one_threshold / 12
                                    ).toLocaleString(undefined, {
                                        maximumFractionDigits: 2,
                                    })}{" "}
                                    a month
                                </P>
                                .
                            </P>
                        </Container>
                        <HR />
                        <Container
                            paddingMd
                            {...(windowSize.width > theme.breakpoints.sm
                                ? { row: true }
                                : { col: true, gap: "16px" })}
                            width="100%"
                            alignItems="center"
                        >
                            <Container width={100} alignItems="center">
                                <SecondTier />
                            </Container>
                            <P textColor="black">
                                Fynn will pay{" "}
                                <P bold textColor="black">
                                    {formatPercent(
                                        exitCounseling.data.discount_two * 100
                                    )}
                                    %
                                </P>{" "}
                                of your payment if you're making less than{" "}
                                <P textColor="black" bold>
                                    $
                                    {(
                                        exitCounseling.data
                                            ?.discount_two_threshold / 12
                                    ).toLocaleString(undefined, {
                                        maximumFractionDigits: 2,
                                    })}{" "}
                                    a month
                                </P>
                                .
                            </P>
                        </Container>
                        <HR />
                        <Container
                            paddingMd
                            {...(windowSize.width > theme.breakpoints.sm
                                ? { row: true }
                                : { col: true, gap: "16px" })}
                            width="100%"
                            alignItems="center"
                        >
                            <P textColor="black">
                                There is{" "}
                                <P bold textColor="black">
                                    no limit
                                </P>{" "}
                                to how many times you can use this benefit!
                            </P>
                        </Container>
                    </Card>
                    {!determineAchOverlay(exitCounseling.data) && !isMobile && (
                        <ExitCounselingMemberBenefitOverlay />
                    )}
                </BenefitCard>
                {!determineAchOverlay(exitCounseling.data) && isMobile && (
                    <ExitCounselingMemberBenefitMobileLockedFooter />
                )}
                <Spacer lg />
                <BenefitCard
                    disableShadow
                    backgroundColor="medLightGrey"
                    showRedBorder={
                        !determineAchOverlay(exitCounseling.data) && isMobile
                    }
                >
                    {isMobile && (
                        <Container col>
                            <Card
                                flex={1}
                                paddingLg
                                col
                                disableShadow
                                backgroundColor="medLightBlue"
                            >
                                <P
                                    bold
                                    textColor="newBlue"
                                    textSize={theme.sizings.fontSizeXl}
                                    lineHeight={theme.sizings.fontSizeJumboSm}
                                    textAlign={"left"}
                                >
                                    Pause your payments!
                                </P>
                            </Card>
                            <Spacer lg />
                        </Container>
                    )}
                    {!isMobile && (
                        <Container
                            {...(windowSize.width > theme.breakpoints.sm
                                ? { row: true }
                                : { col: true })}
                        >
                            <ImgContainer
                                flex={1}
                                alignItems="center"
                                justifyContent="center"
                            >
                                <PaymentPauseTimer />
                            </ImgContainer>
                            <Container flex={1} paddingMd col>
                                <P
                                    bold
                                    textColor="newBlue"
                                    textSize={theme.sizings.fontSizeXl}
                                    lineHeight={theme.sizings.fontSizeXl}
                                    textAlign={
                                        windowSize.width > theme.breakpoints.sm
                                            ? "left"
                                            : "center"
                                    }
                                >
                                    Payment <br />
                                    Pause
                                </P>
                                <Spacer />
                                <StyledUl>
                                    <StyledList>
                                        <P textColor="black">
                                            Lasts up to 3 months
                                        </P>
                                    </StyledList>
                                    <StyledList>
                                        <P textColor="black">
                                            Limit of 12 months of pauses over
                                            your entire loan
                                        </P>
                                    </StyledList>
                                </StyledUl>
                            </Container>
                        </Container>
                    )}
                    {windowSize.width > theme.breakpoints.sm && <Spacer lg />}
                    <Card
                        width="100%"
                        backgroundColor="white"
                        border="medGrey"
                        disableShadow
                        disablePadding
                    >
                        <Container
                            paddingMd
                            {...(windowSize.width > theme.breakpoints.sm
                                ? { row: true }
                                : { col: true, gap: "16px" })}
                            width="100%"
                            alignItems="center"
                        >
                            <Container width={100} alignItems="center">
                                <PaymentPause />
                            </Container>
                            <P textColor="black">
                                If you're making less than{" "}
                                <P textColor="black" bold>
                                    $
                                    {(
                                        exitCounseling.data
                                            ?.no_payment_threshold / 12
                                    ).toLocaleString(undefined, {
                                        maximumFractionDigits: 2,
                                    })}{" "}
                                    a month
                                </P>{" "}
                                you can qualify to pause your payments for 3
                                months at a time.
                            </P>
                        </Container>
                        <HR />
                        <Container
                            paddingMd
                            {...(windowSize.width > theme.breakpoints.sm
                                ? { row: true }
                                : { col: true, gap: "16px" })}
                            width="100%"
                            alignItems="center"
                        >
                            <P textColor="black">
                                You can pause your payments for up to{" "}
                                <P bold textColor="black">
                                    12 months
                                </P>{" "}
                                total!
                            </P>
                        </Container>
                    </Card>
                    {!determineAchOverlay(exitCounseling.data) && !isMobile && (
                        <ExitCounselingMemberBenefitOverlay />
                    )}
                </BenefitCard>
                {!determineAchOverlay(exitCounseling.data) && isMobile && (
                    <ExitCounselingMemberBenefitMobileLockedFooter />
                )}
                <Spacer lg />
                <Banner width="100%" backgroundColor="medLightBlue" paddingMd>
                    <P textColor="newBlue">
                        <a
                            href={MEMBER_BENEFITS_URL}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <UnderlineText textColor="newBlue" bold>
                                Click here
                            </UnderlineText>{" "}
                        </a>
                        to learn more about your Member Benefits
                    </P>
                </Banner>
            </Card>
            <Spacer lg />
            <Card border="medGrey" disableShadow width="100%" paddingLg>
                <P
                    textAlign="left"
                    textSize={theme.sizings.fontSizeMd}
                    bold
                    textColor="black"
                >
                    How do I qualify for a Member Benefit?
                </P>
                <Spacer lg />
                <P textColor="black">
                    Just give us a call at (800) 419-2118 and we'll walk you
                    through the income validation process.
                </P>
                <Spacer />
                <Card
                    disableShadow
                    backgroundColor={
                        determineAchOverlay(exitCounseling.data)
                            ? "newLightOrange"
                            : "newLightRed"
                    }
                    border={
                        determineAchOverlay(exitCounseling.data)
                            ? "newDarkOrange"
                            : "newRed"
                    }
                >
                    <P textColor="black" bold>
                        {determineAchOverlay(exitCounseling.data)
                            ? "Remember, you are only eligible for these member benefits while you continue to enroll in autopay."
                            : "You are not currently eligible for these member benefits because you are not enrolled in autopay."}
                    </P>
                </Card>
                <Spacer />
                {!exitCounseling.data.onboarded_to_servicer && (
                    <Card
                        disableShadow
                        backgroundColor="newLightOrange"
                        border="newDarkOrange"
                    >
                        <P textColor="black" bold>
                            Again, this only applies once you've started classes
                            and have been onboarded to the payment portal.
                        </P>
                    </Card>
                )}
            </Card>
            <Spacer lg />
            <Card border="medGrey" disableShadow width="100%" paddingLg>
                <P
                    textAlign="left"
                    textSize={theme.sizings.fontSizeMd}
                    bold
                    textColor="black"
                >
                    What if I can't make my payments, but I don't qualify for a
                    Member Benefit?
                </P>
                <Spacer lg />
                <P textColor="black">
                    When in doubt, reach out. We may be able to help you even if
                    you do not qualify for a member benefit.{" "}
                    {!exitCounseling.data.onboarded_to_servicer
                        ? "Once you've started classes, call"
                        : "Call"}{" "}
                    (800) 419-2118 or email{" "}
                    <UnderlineText textColor="black">
                        fynn@acct-admin.com
                    </UnderlineText>
                    . The Fynn team is here to help, and we are committed to
                    working with you to stay on track.
                </P>
                <Spacer />
                <P textColor="black">
                    Make sure to call us at least two business days before your
                    next payment is due so we can work with you on repayment
                    options.
                </P>
            </Card>
            <Spacer lg />
            <Container width={300}>
                {updateExitCounselingStatus.isLoading && <SpinningLoader />}
                {!updateExitCounselingStatus.isLoading && (
                    <ApplicationButton
                        fullWidth
                        alignCenter
                        title="Continue"
                        buttonColor="newBlue"
                        onPress={handleContinue}
                    />
                )}
            </Container>
        </DefaultPage>
    )
}
