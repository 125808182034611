import { useTheme } from "@emotion/react"
import { Card, Container, P, Spacer } from "@wetradeup/ui-kit"
import { DefaultPage } from "../molecules/DefaultPage"
import { GreenCheckMark } from "../icons/GreenCheckMark"
import {
    AuthContext,
    useGetExitCounseling,
} from "@wetradeup/student-apps-shared"
import { useHistory } from "react-router-dom"
import { useContext, useEffect } from "react"
import { StyledList, StyledUl } from "./ExitCounselingMemberBenefits"

export function ExitCounselingResult() {
    const theme = useTheme()
    const history = useHistory()
    const { authProfile } = useContext(AuthContext)
    const exitCounseling = useGetExitCounseling(authProfile?.accounts_id)
    const wrongAnswerKeys = Object.keys(
        exitCounseling.data?.exit_counseling_questionnaire_wrong_answers || {}
    )

    useEffect(() => {
        if (
            exitCounseling.isSuccess &&
            !exitCounseling.data?.exit_counseling_questionnaire_answered_at
        ) {
            history.push("/loan-orientation/quiz")
        }
    }, [
        exitCounseling.isSuccess,
        exitCounseling.data?.exit_counseling_questionnaire_answered_at,
    ])

    return (
        <DefaultPage>
            <Container width="100%" alignItems="center">
                {exitCounseling.isSuccess &&
                    Object.keys(
                        exitCounseling.data
                            ?.exit_counseling_questionnaire_wrong_answers || {}
                    ).length === 0 && (
                        <>
                            <GreenCheckMark width={240} />
                            <Spacer lg />
                            <P
                                textColor="black"
                                textSize={theme.sizings.fontSizeMdLg}
                                textAlign="center"
                                lineHeight={"120%"}
                            >
                                You're done! And you aced the quiz!
                            </P>
                            <Spacer lg />
                            <P textAlign="center">
                                We'll send you an email with a summary of <br />{" "}
                                everything you've learned. Nice work!
                            </P>
                        </>
                    )}
                {exitCounseling.isSuccess &&
                    Object.keys(
                        exitCounseling.data
                            ?.exit_counseling_questionnaire_wrong_answers || {}
                    ).length > 0 && (
                        <>
                            <GreenCheckMark width={240} />
                            <Spacer lg />
                            <P
                                textAlign="center"
                                textSize={theme.sizings.fontSizeMdLg}
                                textColor="black"
                                lineHeight={"120%"}
                            >
                                You're done!
                            </P>
                            <Spacer lg />
                            <P textAlign="center">
                                We'll send you an email with a summary of <br />{" "}
                                everything you've learned!
                            </P>
                            <Spacer lg />
                            <Card
                                border="medGrey"
                                disableShadow
                                width="100%"
                                paddingLg
                            >
                                <P
                                    textAlign="left"
                                    textSize={theme.sizings.fontSizeInc2}
                                    bold
                                    textColor="black"
                                >
                                    Here is what you missed:
                                </P>
                                <Spacer lg />

                                {wrongAnswerKeys.map((key, ind) => (
                                    <>
                                        <Card
                                            backgroundColor="medLightGrey"
                                            border="medGrey"
                                            disableShadow
                                            width="100%"
                                        >
                                            <P
                                                textAlign="left"
                                                bold
                                                textColor="black"
                                            >
                                                {
                                                    exitCounseling.data
                                                        ?.exit_counseling_questionnaire_wrong_answers[
                                                        key
                                                    ].question
                                                }
                                            </P>
                                            <Spacer />
                                            <StyledUl>
                                                <StyledList>
                                                    <P textColor="black">
                                                        <P
                                                            textColor="newBlue"
                                                            bold
                                                        >
                                                            Correct answer:{" "}
                                                        </P>
                                                        {
                                                            exitCounseling.data
                                                                ?.exit_counseling_questionnaire_wrong_answers[
                                                                key
                                                            ]
                                                                .correct_answer_label
                                                        }
                                                    </P>
                                                </StyledList>
                                                <StyledList>
                                                    <P textColor="black">
                                                        Your answer:{" "}
                                                        {
                                                            exitCounseling.data
                                                                ?.exit_counseling_questionnaire_wrong_answers[
                                                                key
                                                            ].label
                                                        }
                                                    </P>
                                                </StyledList>
                                            </StyledUl>
                                        </Card>
                                        {ind < wrongAnswerKeys.length - 1 && (
                                            <Spacer />
                                        )}
                                    </>
                                ))}
                            </Card>
                        </>
                    )}
            </Container>
            <Spacer lg />
        </DefaultPage>
    )
}
