import {
    useGetApplicationForm,
    useGetAllApplicationForms,
    useStudentApplicationDetails,
    useGetPublicSchool,
    useGetAccount,
    useGetStudentProfile,
    AuthContext,
    StudentAccount,
} from "@wetradeup/student-apps-shared"
import { useHistory, useRouteMatch } from "react-router-dom"
import { useEffect, useState, useMemo, useContext } from "react"
import queryString from "query-string"
import { useFynnLocation } from "../../hooks/useFynnLocation"

export function useApplicationProgress() {
    const history = useHistory()
    const location = useFynnLocation()
    const match = useRouteMatch<{ schoolId?: string }>("/application/:schoolId")
    const employerMatch = useRouteMatch<{ employerId?: string; page?: string }>(
        "/application/employer/:employerId/:page"
    )

    const query = queryString.parse(location.search)
    const { authProfile, authLoaded } = useContext(AuthContext)

    const [schoolId, setSchoolId] = useState<number>()
    const [schoolName, setSchoolName] = useState<string>("")
    const [appFormId, setAppFormId] = useState<number>(null)
    const [programId, setProgramId] = useState<number>()
    const [employerId, setEmployerId] = useState<number>()

    const accountQuery = useGetAccount()

    const isIntroRoute =
        match?.params?.schoolId && isNaN(parseInt(match?.params?.schoolId))

    const schoolQuery = useGetPublicSchool(schoolId, null)
    const school = schoolQuery.data

    const program = useMemo(
        () => school?.programs?.find((p) => p.id === programId),
        [school, programId]
    )

    const allAppFormsQuery = useGetAllApplicationForms(authProfile?.email)
    const applicationFormQuery = useGetApplicationForm(appFormId)
    const memberApplicationQuery = useStudentApplicationDetails({
        enabled: applicationFormQuery?.data?.is_archived,
    })

    const enableStudentProfileQuery =
        applicationFormQuery?.data?.status === "valid"

    const studentProfileQuery = useGetStudentProfile({
        enabled: enableStudentProfileQuery,
    })

    // TODO: Re-enable once Telnyx issues are resolved
    // const shouldVerifyPhone = (createdAt) => {
    //     // this is the date we started verifying phone numbers. Any app before this
    //     // date does not need to go through the phone verification flow.
    //     const phoneVerificationCutoffDate = new Date(2023, 7, 8)

    //     if (createdAt) {
    //         return createdAt.getTime() > phoneVerificationCutoffDate.getTime()
    //     }

    //     return false
    // }

    useEffect(() => {
        const query = queryString.parse(location.search)
        if (query.schoolId && schoolId !== parseInt(query.schoolId as string)) {
            setSchoolId(parseInt(query.schoolId as string))
        }

        if (
            !isIntroRoute &&
            schoolId !== parseInt(match?.params?.schoolId as string)
        ) {
            setSchoolId(parseInt(match?.params?.schoolId as string))
        }
    }, [location.search, match?.params?.schoolId, isIntroRoute])

    useEffect(() => {
        if (
            allAppFormsQuery.isSuccess &&
            // no background query is running
            !(allAppFormsQuery.isFetching && !allAppFormsQuery.isLoading) &&
            allAppFormsQuery.data?.length === 0
        ) {
            setAppFormId(null)
        }

        if (
            allAppFormsQuery.isSuccess &&
            // no background query is running
            !(allAppFormsQuery.isFetching && !allAppFormsQuery.isLoading) &&
            allAppFormsQuery.data?.length === 1
        ) {
            setAppFormId(allAppFormsQuery.data[0].id)
        }

        if (applicationFormQuery.isSuccess && applicationFormQuery.data) {
            setEmployerId(applicationFormQuery.data?.employer_id)
            setProgramId(applicationFormQuery.data?.program_id)
        }
    }, [
        allAppFormsQuery.isSuccess,
        allAppFormsQuery.isFetching,
        allAppFormsQuery.data,
        applicationFormQuery.data?.employer_id,
        applicationFormQuery.data?.program_id,
    ])

    useEffect(() => {
        if (schoolQuery.isError) {
            return history.push(
                `/coming-soon?school=${encodeURIComponent(schoolName.trim())}`
            )
        }
    }, [schoolQuery.isError])

    useEffect(() => {
        if (location.state?.disableAutoRouting) return

        if (employerMatch?.params?.page === "intro") return

        if (
            authLoaded &&
            !authProfile &&
            !isIntroRoute &&
            !location.isEmailRoute
        ) {
            return history.push(`/application/intro`)
        }

        if (authLoaded && authProfile?.role === "exit_counseling") {
            return history.push(`/loan-orientation`)
        }

        if (
            (applicationFormQuery.data || memberApplicationQuery.data) &&
            accountQuery.isSuccess &&
            !(accountQuery.data as StudentAccount)?.verified_email
        ) {
            let emailLink = `/application/${
                applicationFormQuery.data?.school_id ||
                memberApplicationQuery.data?.school_id
            }/email`
            if (query.code) {
                emailLink += `?code=${query.code}`
            }
            return history.push(emailLink)
        }

        // TODO: Re-enable once Telnyx issues are resolved
        // if (
        //     applicationFormQuery.isSuccess &&
        //     shouldVerifyPhone(applicationFormQuery.data.created_at) &&
        //     !(accountQuery.data as StudentAccount)?.verified_phone
        // ) {
        //     let phoneLink = `/application/${
        //         applicationFormQuery.data?.school_id ||
        //         memberApplicationQuery.data?.school_id
        //     }/phone`
        //     return history.push(phoneLink)
        // }

        if (school && !school.is_live && school?.school_name) {
            return history.push(
                `/coming-soon?school=${encodeURIComponent(
                    school?.school_name.trim()
                )}`
            )
        }

        if (
            location.pathname === "/application/intro/select" &&
            !location.state?.redirectToCurrentApplicationForm
        ) {
            return
        }

        if (
            (memberApplicationQuery.data?.is_cancelled ||
                memberApplicationQuery.data?.decision === "rejected" ||
                memberApplicationQuery.data
                    ?.dropped_out_within_cancellation_period) &&
            location.state?.createNewApplication
        ) {
            return
        }

        if (
            memberApplicationQuery.data?.is_cancelled &&
            memberApplicationQuery.data?.underwriting_recommendation ===
                "locked" &&
            !allAppFormsQuery.data?.length &&
            !location.state?.createNewApplication
        ) {
            return history.push("/isl-finalize/locked")
        }

        if (
            memberApplicationQuery.data &&
            !memberApplicationQuery.data?.is_cancelled &&
            !memberApplicationQuery.data?.dropped_out_within_cancellation_period
        ) {
            return history.push("/isl-finalize/financing")
        }

        if (
            (allAppFormsQuery.isLoading || accountQuery.isLoading) &&
            !location.isEmailRoute
        ) {
            return history.push("/application")
        }

        if (
            allAppFormsQuery.isSuccess &&
            accountQuery.isSuccess &&
            authLoaded &&
            !!authProfile
        ) {
            if (!appFormId && allAppFormsQuery.data?.length === 0) {
                return history.push("/application/intro")
            }

            if (appFormId === null && allAppFormsQuery.data?.length > 1) {
                return history.push("/application/intro/select")
            }

            if (applicationFormQuery.isSuccess && applicationFormQuery.data) {
                const { school_id: schoolId, status } =
                    applicationFormQuery.data
                if (status === "valid" && !studentProfileQuery) return

                const { questionnaire, invalid_reasons } =
                    applicationFormQuery.data

                if (status === "pending") {
                    history.push(`/application/${schoolId}/basic`)
                } else if (status === "invalid" && invalid_reasons.length > 0) {
                    history.push("/application/intro/select")
                } else if (
                    !questionnaire &&
                    schoolQuery.data?.is_cosigner_enabled &&
                    !applicationFormQuery.data?.has_cosigner
                ) {
                    history.push(`/application/${schoolId}/cosigner`)
                } else if (!questionnaire) {
                    history.push(`/application/${schoolId}/questionnaire`)
                } else {
                    history.push(`/application/${schoolId}/review`)
                }
            }
        }
    }, [
        applicationFormQuery.isSuccess,
        allAppFormsQuery.isSuccess,
        memberApplicationQuery.isSuccess,
        accountQuery.isSuccess,
        studentProfileQuery.isSuccess,
        memberApplicationQuery.data?.id,
        school,
        authProfile?.email,
        authLoaded,
        schoolId,
        appFormId,
        employerMatch,
        history,
        location.state,
    ])

    return {
        schoolId,
        school,
        program,
        appFormId,
        programId,
        employerId,
        setAppFormId,
        setSchoolId,
        setSchoolName,
    }
}
