import { UIKitTheme } from "@wetradeup/ui-kit"
import { css, Global } from "@emotion/react"
import ReactDatePicker from "react-date-picker"
import { Container } from "@wetradeup/ui-kit"
import React from "react"
import styled from "@emotion/styled"

const datePickerStyles = (theme: UIKitTheme) => css`
    .react-date-picker {
        width: 100%;
    }

    .react-calendar__tile abbr {
        font-family: ${theme.fonts.typefaces.secondary};
    }

    .react-calendar__navigation__label__labelText {
        font-family: ${theme.fonts.typefaces.primary};
    }

    .react-calendar__month-view__weekdays__weekday abbr {
        font-family: ${theme.fonts.typefaces.primary};
        font-weight: 800;
    }

    .react-calendar {
        box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.16);
    }

    .react-date-picker__inputGroup {
        font-family: ${theme.fonts.typefaces.secondary};
    }

    .react-date-picker__inputGroup input {
        font-family: ${theme.fonts.typefaces.secondary};
    }
`

const DatePickerBaseContainer = styled(Container)`
    ${(props) => `
        ${props?.shouldHide && "display: none;"}
    `}
`

export function DatePickerBase(props: any) {
    return (
        <DatePickerBaseContainer
            flex={1}
            justifyContent="center"
            shouldHide={props?.shouldHide}
        >
            <Global styles={datePickerStyles} />
            <ReactDatePicker
                {...props}
                disableCalendar
                data-private="redact"
                clearIcon={null}
                showLeadingZeros={false}
            />
        </DatePickerBaseContainer>
    )
}
