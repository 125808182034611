export function ThreeDollars() {
    return (
        <svg
            width="110"
            height="59"
            viewBox="0 0 110 59"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M27.9206 51.5493L26.8729 48.3793C25.5941 48.7792 24.3821 49.0545 23.2371 49.2053C22.099 49.3425 21.0841 49.3706 20.1926 49.2898L19.1093 46.012C20.0213 46.1203 21.1061 46.1316 22.3637 46.046C23.6213 45.9604 24.8006 45.7584 25.9016 45.4401L23.9945 39.6694C22.3408 39.6584 20.9573 39.5352 19.8441 39.3C18.7411 39.0615 17.8555 38.6543 17.1873 38.0786C16.5157 37.4926 16.0121 36.6918 15.6764 35.6761C15.3306 34.6297 15.2955 33.6456 15.5712 32.7238C15.8538 31.7883 16.3941 30.9441 17.1922 30.191C18.0004 29.4346 19.0168 28.8085 20.2413 28.3128L19.4123 25.8045L21.4128 25.1434L22.2265 27.6055C23.3754 27.26 24.4694 27.0407 25.5083 26.9477C26.5541 26.841 27.5825 26.8368 28.5936 26.9351L28.4162 30.1686C27.5451 30.1151 26.6618 30.1282 25.7664 30.2079C24.871 30.2876 24.0216 30.4204 23.2182 30.6063L25.0796 36.2384C26.4668 36.2351 27.7256 36.3084 28.8562 36.4582C29.997 36.6047 30.9631 36.9454 31.7544 37.4804C32.5526 38.0018 33.1415 38.837 33.5212 39.986C34.0196 41.4941 33.8776 42.9236 33.0951 44.2746C32.3092 45.6154 30.8918 46.7325 28.8429 47.6258L29.9211 50.8881L27.9206 51.5493ZM27.8614 44.6558C28.8056 44.2073 29.4543 43.7084 29.8076 43.1592C30.1576 42.5997 30.2275 42.002 30.0173 41.3659C29.8749 40.9351 29.6519 40.6048 29.3484 40.3751C29.0552 40.142 28.6541 39.9787 28.1453 39.8851C27.6467 39.7882 27.0113 39.7421 26.2391 39.747L27.8614 44.6558ZM22.8299 36.1455L21.2381 31.3289C20.6634 31.5871 20.2014 31.8764 19.8521 32.1967C19.5027 32.5169 19.2746 32.8597 19.1678 33.225C19.0609 33.5904 19.0753 33.9782 19.2109 34.3886C19.3601 34.8399 19.5796 35.1941 19.8693 35.4511C20.1591 35.7081 20.543 35.8885 21.0211 35.9922C21.5094 36.0926 22.1124 36.1437 22.8299 36.1455Z"
                fill="#2EC63C"
            />
            <path
                d="M54.0856 46.9491L54.3621 43.6219C53.0287 43.4894 51.8057 43.2685 50.693 42.9592C49.5919 42.6401 48.647 42.2688 47.8582 41.8455L48.1441 38.4052C48.941 38.8618 49.9348 39.2967 51.1256 39.7101C52.3164 40.1235 53.4807 40.3991 54.6185 40.537L55.1218 34.4803C53.6043 33.8229 52.3794 33.1682 51.447 32.5161C50.5253 31.8649 49.8697 31.1436 49.4801 30.3523C49.0914 29.5502 48.9413 28.6162 49.0299 27.5502C49.1212 26.4519 49.474 25.5326 50.0885 24.7922C50.7146 24.0419 51.5422 23.4765 52.5713 23.0958C53.6112 22.716 54.7915 22.5376 56.1123 22.5606L56.3311 19.928L58.4307 20.1024L58.216 22.6867C59.4085 22.8183 60.501 23.0446 61.4935 23.3656C62.4976 23.6767 63.4456 24.0753 64.3377 24.5614L62.909 27.4676C62.1282 27.0775 61.3103 26.7439 60.4551 26.4668C59.6 26.1898 58.7663 25.9795 57.9543 25.8362L57.463 31.7475C58.7409 32.2874 59.8707 32.8475 60.8524 33.4278C61.845 34.009 62.6006 34.7006 63.1194 35.5027C63.6499 36.2948 63.865 37.2939 63.7648 38.4999C63.6333 40.0827 62.9431 41.3426 61.6943 42.2797C60.4464 43.206 58.7049 43.6792 56.4698 43.6995L56.1853 47.1236L54.0856 46.9491ZM56.7289 40.5823C57.7733 40.539 58.5655 40.3338 59.1056 39.9667C59.6466 39.5888 59.9448 39.066 60.0003 38.3985C60.0379 37.9462 59.9619 37.555 59.7725 37.2249C59.5939 36.8956 59.2888 36.5884 58.8571 36.3031C58.4362 36.0188 57.8695 35.7278 57.157 35.43L56.7289 40.5823ZM55.4291 30.7816L55.8492 25.7263C55.2193 25.739 54.681 25.8244 54.2342 25.9824C53.7874 26.1404 53.4433 26.3666 53.202 26.661C52.9607 26.9553 52.8222 27.3179 52.7864 27.7486C52.747 28.2223 52.8104 28.6342 52.9765 28.9841C53.1425 29.334 53.4253 29.6502 53.8246 29.9327C54.2347 30.2162 54.7696 30.4991 55.4291 30.7816Z"
                fill="#2EC63C"
            />
            <path
                d="M79.9293 52.0184L80.9483 48.839C79.679 48.4095 78.5372 47.9188 77.5228 47.3667C76.522 46.8077 75.685 46.2331 75.0119 45.643L76.0655 42.3556C76.739 42.9799 77.6093 43.6276 78.6763 44.2986C79.7434 44.9696 80.8156 45.5005 81.8931 45.8912L83.748 40.1036C82.4177 39.1213 81.3718 38.2074 80.6102 37.362C79.859 36.5199 79.3827 35.6694 79.1814 34.8107C78.9834 33.9417 79.0476 32.9978 79.3741 31.9792C79.7105 30.9297 80.2614 30.1135 81.0269 29.5306C81.8059 28.9407 82.7397 28.5762 83.8281 28.4372C84.9267 28.3014 86.1169 28.3936 87.3986 28.7136L88.2049 26.1979L90.2112 26.8409L89.4198 29.3103C90.552 29.7072 91.5654 30.1738 92.46 30.7102C93.3682 31.2395 94.2021 31.8415 94.9617 32.5161L92.915 35.0257C92.2422 34.4697 91.5204 33.9604 90.7497 33.4978C89.9789 33.0351 89.2141 32.6425 88.4552 32.3199L86.6448 37.9686C87.7681 38.7825 88.7427 39.5827 89.5685 40.3693C90.4045 41.1592 90.985 42.0032 91.3097 42.9016C91.6481 43.7929 91.6326 44.8147 91.2633 45.9671C90.7785 47.4796 89.8222 48.5517 88.3944 49.1833C86.9699 49.8046 85.1666 49.8733 82.9843 49.3895L81.9357 52.6614L79.9293 52.0184ZM83.939 46.4108C84.9664 46.6039 85.7845 46.5825 86.3934 46.3465C87.0056 46.1002 87.4139 45.6581 87.6183 45.0202C87.7568 44.588 87.771 44.1898 87.6609 43.8254C87.561 43.4644 87.3329 43.0963 86.9766 42.7211C86.6306 42.3493 86.1441 41.9381 85.517 41.4875L83.939 46.4108ZM84.8808 36.5693L86.429 31.7386C85.8125 31.609 85.2688 31.5709 84.7978 31.6242C84.3269 31.6775 83.9407 31.8204 83.6393 32.0528C83.3379 32.2852 83.1213 32.6072 82.9893 33.0188C82.8442 33.4715 82.8132 33.887 82.8962 34.2653C82.9791 34.6437 83.1833 35.0155 83.5088 35.3807C83.8445 35.7493 84.3018 36.1455 84.8808 36.5693Z"
                fill="#2EC63C"
            />
            <path
                d="M89.3301 11.6811C89.0896 11.6811 88.9093 11.5008 88.8492 11.2603L88.7891 6.93208C88.7891 6.69162 88.9694 6.45117 89.2099 6.45117C89.4503 6.45117 89.6908 6.63151 89.6908 6.87197L89.7509 11.2002C89.811 11.4407 89.6307 11.6811 89.3301 11.6811C89.3301 11.6811 89.3902 11.6811 89.3301 11.6811Z"
                fill="#2E54E8"
            />
            <path
                d="M91.9752 13.4845C91.9151 13.4845 91.855 13.4845 91.7949 13.4244C91.5544 13.3042 91.4943 13.0637 91.5544 12.8233L93.0573 9.57709C93.1775 9.33663 93.4179 9.27651 93.6584 9.33662C93.8989 9.45685 93.959 9.69731 93.8989 9.93777L92.396 13.184C92.3359 13.3643 92.1555 13.4845 91.9752 13.4845Z"
                fill="#2E54E8"
            />
            <path
                d="M38.0375 17L36.5947 23.1918L42.4258 21.0277L38.0375 17Z"
                fill="#2E54E8"
            />
            <path
                d="M101.744 41.5861C101.624 41.5861 101.563 41.526 101.443 41.4659L99.5196 39.7827C99.3392 39.6023 99.2791 39.3619 99.4595 39.1214C99.6398 38.9411 99.8803 38.8809 100.121 39.0613L102.044 40.7445C102.225 40.9248 102.285 41.1653 102.105 41.4057C101.984 41.526 101.864 41.5861 101.744 41.5861Z"
                fill="#2E54E8"
            />
            <path
                d="M61.6942 7.55536C61.6341 7.55536 61.574 7.55537 61.5139 7.49525C61.2734 7.37502 61.2133 7.13456 61.3336 6.89411L63.3173 2.86643C63.4376 2.62597 63.678 2.56586 63.9185 2.68609C64.1589 2.80632 64.2191 3.04678 64.0988 3.28724L62.115 7.31491C62.0549 7.49525 61.8746 7.55536 61.6942 7.55536Z"
                fill="#2E54E8"
            />
            <path
                d="M105.2 10.4188C105.08 10.4188 104.96 10.3587 104.9 10.2986C104.72 10.1182 104.72 9.81765 104.9 9.63731L108.687 5.8501C108.867 5.66976 109.168 5.66976 109.348 5.8501C109.529 6.03044 109.529 6.33101 109.348 6.51136L105.561 10.2986C105.381 10.3587 105.321 10.4188 105.2 10.4188Z"
                fill="#2E54E8"
            />
            <path
                d="M97.9869 21.1192C97.7465 21.1192 97.5661 20.9388 97.5661 20.6984L97.3257 18.414C97.3257 18.1736 97.506 17.9331 97.7465 17.9331C97.9869 17.9331 98.2274 18.1134 98.2274 18.3539L98.4679 20.6383C98.4679 20.8787 98.2875 21.1192 97.9869 21.1192Z"
                fill="#2E54E8"
            />
            <path
                d="M19.0277 9.20238C19.2459 9.24254 19.4422 9.09529 19.5403 8.86884L20.3783 4.62213C20.4218 4.38565 20.3017 4.11905 20.0835 4.0789C19.8653 4.03874 19.6145 4.17594 19.571 4.41243L18.733 8.65913C18.6349 8.88558 18.755 9.15218 19.0277 9.20238C19.0277 9.20238 18.9732 9.19234 19.0277 9.20238Z"
                fill="#2E54E8"
            />
            <path
                d="M5.96693 10.3252C5.98128 10.3788 5.99564 10.4324 6.06806 10.4704C6.24161 10.6535 6.48823 10.6449 6.70614 10.5291L9.48285 8.34963C9.68641 8.18025 9.68706 7.95041 9.57158 7.75168C9.39803 7.56852 9.15141 7.57717 8.9335 7.69298L6.15678 9.87243C5.99693 9.97268 5.92387 10.1645 5.96693 10.3252Z"
                fill="#2E54E8"
            />
            <path
                d="M10.1592 36.268C10.2701 36.268 10.3256 36.2079 10.4365 36.1477L12.2113 34.4645C12.3776 34.2842 12.4331 34.0437 12.2667 33.8033C12.1003 33.6229 11.8785 33.5628 11.6567 33.7432L9.88191 35.4264C9.71552 35.6067 9.66006 35.8472 9.82644 36.0876C9.93736 36.2079 10.0483 36.268 10.1592 36.268Z"
                fill="#2E54E8"
            />
            <path
                d="M36.915 8.72406C36.9293 8.77763 36.9437 8.8312 37.0161 8.86922C37.1896 9.05238 37.4362 9.04372 37.6398 8.87434L41.0565 6.06406C41.2601 5.89468 41.2607 5.66484 41.0872 5.48167C40.9136 5.2985 40.667 5.30717 40.4635 5.47654L37.0467 8.28683C36.8869 8.38708 36.8719 8.56335 36.915 8.72406Z"
                fill="#2E54E8"
            />
            <path
                d="M7.55836 22.5167C7.66928 22.5167 7.7802 22.4566 7.83566 22.3965C8.00204 22.2161 8.00204 21.9155 7.83566 21.7352L4.34162 17.948C4.17524 17.7677 3.89793 17.7677 3.73155 17.948C3.56517 18.1283 3.56517 18.4289 3.73155 18.6093L7.22559 22.3965C7.39197 22.4566 7.44744 22.5167 7.55836 22.5167Z"
                fill="#2E54E8"
            />
            <path
                d="M1.64579 31.8196C1.86764 31.8196 2.03402 31.6393 2.03402 31.3988L2.25586 29.1145C2.25586 28.874 2.08948 28.6335 1.86763 28.6335C1.64579 28.6335 1.42395 28.8139 1.42395 29.0543L1.20211 31.3387C1.20211 31.5792 1.36849 31.8196 1.64579 31.8196Z"
                fill="#2E54E8"
            />
            <path
                d="M67.689 56.4665L70.6947 54.1221L71.2958 58.2098L67.689 56.4665Z"
                fill="#2E54E8"
            />
        </svg>
    )
}
