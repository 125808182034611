import { useState, useEffect, useCallback } from "react"
import { useSendEmailVerificationApp } from "@wetradeup/student-apps-shared"
import { TouchableContainer, Caption } from "@wetradeup/ui-kit"
import { ClickableCaption } from "../atoms/ClickableCaption"
import { UpdateEmailModal } from "./modals/UpdateEmailModal"

export const ChangeEmail = ({ schoolId }: { schoolId: number }) => {
    const [emailSent, setEmailSent] = useState(false)
    const sendEmailVerificationMutation = useSendEmailVerificationApp()
    const [showUpdateEmailModal, setShowUpdateEmailModal] = useState(false)

    const handleUpdateEmailPress = (e) => {
        e.preventDefault()
        setShowUpdateEmailModal(true)
    }

    useEffect(() => {
        if (emailSent) {
            setTimeout(() => {
                setEmailSent(false)
            }, 5000)
        }
    }, [emailSent])

    const sendEmailVerification = useCallback(async () => {
        await sendEmailVerificationMutation.mutate(schoolId)
        setEmailSent(true)
    }, [schoolId])

    return (
        <>
            {emailSent ? (
                <Caption weight={600} textAlign="center">
                    {" "}
                    We sent a verification link to your updated email address!{" "}
                </Caption>
            ) : (
                <TouchableContainer onPress={handleUpdateEmailPress}>
                    <ClickableCaption textAlign="center">
                        {" "}
                        Change email address{" "}
                    </ClickableCaption>
                </TouchableContainer>
            )}
            <UpdateEmailModal
                visible={showUpdateEmailModal}
                closeModal={() => setShowUpdateEmailModal(false)}
                sendEmailVerification={sendEmailVerification}
            />
        </>
    )
}
