import { useCallback, useState, useContext } from "react"
import {
    useUpdateApplicationForm,
    useSubmitApplication,
    AuthContext,
    Questionnaire,
    QuestionnaireAnswer,
    PrioritizeQuestionnaireAnswer,
} from "@wetradeup/student-apps-shared"
import { useHistory } from "react-router-dom"
import { handleSubmitResponse } from "../../molecules/modals/DecisionModal"

interface useGetQuestionnaireSubmitCallbackProps {
    questionnaire: Questionnaire[]
    schoolId: number
    appFormId?: number
    employerId?: number
    questionnaireType: string
    setError?: (e: string) => void
    setDecision?: (v: string) => void
}

export function formatQuestionnaireAnswers(
    questionnaire: Questionnaire[],
    values: QuestionnaireAnswer
) {
    /* INPUTS from Formik (by question style):

           radio_question_id: {
           "label": "answer choice label",
              "value": "answer_choice_value"
           }

           multiple_radio_question_id: [
              {"label": "answer choice label", "value": "answer_choice_value"}
           ]

           prioritize_id: {
              "initial_order": "ABCDEF",
              "final_order": "FBCDEA",
              "time_spent": 54.65, (seconds)
           }
        */

    const titleDictionary = questionnaire.reduce(function (accum, question) {
        accum[question["id"]] = question["title"]
        return accum
    }, {})

    const hiddenDictionary = questionnaire.reduce(function (accum, question) {
        accum[question["id"]] = question["hidden"]
        return accum
    }, {})

    return Object.entries(values)
        .filter(([key, val]) => !hiddenDictionary[key])
        .reduce(function (accum, [key, val]) {
            var payload = {
                question: titleDictionary[key],
            }

            if (Array.isArray(val)) {
                // multiple radio option
                payload["answers"] = val.map((o) => {
                    return {
                        answer: o.value,
                        label: o.label,
                    }
                })
            } else if ("value" in val) {
                // radio option
                payload["answer"] = val.value
                payload["label"] = val.label

                if (val?.text) {
                    payload["text"] = val.text
                }
            } else if ("final_order" in val) {
                // prioritize option
                payload["initial_order"] = (
                    val as PrioritizeQuestionnaireAnswer
                ).initial_order
                payload["final_order"] = (
                    val as PrioritizeQuestionnaireAnswer
                ).final_order
                payload["time_spent"] = (
                    val as PrioritizeQuestionnaireAnswer
                ).time_spent
            } else {
                // (essay option)
                payload["answer"] = val
            }

            return {
                ...accum,
                [key]: payload,
            }
        }, {})
}

export function useGetQuestionnaireSubmitCallback({
    questionnaire,
    schoolId,
    appFormId,
    employerId,
    questionnaireType,
    setError,
    setDecision,
}: useGetQuestionnaireSubmitCallbackProps) {
    const [loading, setLoading] = useState(false)
    const updateMutation = useUpdateApplicationForm()
    const submitMutation = useSubmitApplication()
    const history = useHistory()
    const { refreshAuth, authProfile } = useContext(AuthContext)

    const onSubmit = useCallback(
        async (questionnaire: Questionnaire[], values: QuestionnaireAnswer) => {
            setLoading(true)
            const processedValues = formatQuestionnaireAnswers(
                questionnaire,
                values
            )
            const data = {
                questionnaire: processedValues as QuestionnaireAnswer,
                app_form_id: appFormId,
            }

            await updateMutation.mutateAsync(data)
            if (questionnaireType === "questionnaire") {
                history.push(`/application/${schoolId}/review`, {
                    disableAutoRouting: true,
                })
            } else if (questionnaireType === "confirm") {
                try {
                    setLoading(true)
                    setDecision("verifying")
                    const response = await submitMutation.mutateAsync({
                        app_form_id: appFormId,
                    })
                    await refreshAuth.mutateAsync(authProfile.refresh_token)
                    setTimeout(() => {
                        if (response) {
                            const newRoute = handleSubmitResponse(
                                response,
                                setError,
                                setDecision
                            )
                            if (newRoute) {
                                history.push(newRoute)
                            }
                        }
                        setLoading(false)
                    }, 2000)
                } catch (error) {
                    setTimeout(() => {
                        if (error.status === 424) {
                            setDecision("resubmit")
                        } else {
                            setLoading(false)
                            setError(error.error)
                            setDecision(null)
                        }
                    }, 2000)
                } finally {
                    setLoading(false)
                }
            }

            setLoading(false)
        },
        [schoolId, appFormId, questionnaire, questionnaireType]
    )

    return {
        onSubmit,
        loading,
    }
}
