import { useTheme } from "@emotion/react"
import { Card, Container, Modal, P, Spacer } from "@wetradeup/ui-kit"
import { ContentContainer } from "../../ModalComponents"
import { Falling } from "../icons/Falling"

interface BankInternalErrorModalProps {
    showInternalError: boolean
    setShowInternalError: React.Dispatch<React.SetStateAction<boolean>>
}

export function BankInternalErrorModal({
    showInternalError,
    setShowInternalError,
}: BankInternalErrorModalProps) {
    const theme = useTheme()
    return (
        <Modal
            visible={showInternalError}
            width="100%"
            onClick={() => {
                setShowInternalError(false)
            }}
        >
            <Container
                width={[
                    [700, "md"],
                    [860, "lg"],
                ]}
            >
                <Card paddingMd>
                    <ContentContainer
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Spacer lg />
                        <Falling />
                        <Spacer lg />
                        <P
                            bold="light"
                            textAlign="center"
                            textColor="black"
                            textSize={theme.sizings.fontSizeMdLg}
                            lineHeight="120%"
                        >
                            Our identity verification system is experiencing an
                            error.
                        </P>
                        <Spacer />
                        <P textAlign="center">
                            We will contact you when the error is resolved. You
                            may close this window.
                        </P>
                        <Spacer lg />
                    </ContentContainer>
                </Card>
            </Container>
        </Modal>
    )
}
