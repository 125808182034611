import {
    Questionnaire,
    QuestionOption,
    QuestionnaireAnswer,
} from "@wetradeup/student-apps-shared"
import { Container, H5, P, Prioritize, Spacer } from "@wetradeup/ui-kit"
import React, { useCallback } from "react"
import { WrappedFormik } from "../../../utils"

interface PrioritizeQuestionProps {
    id: string
    title: string
    subtitle?: string
    options: QuestionOption[]
    setFieldValue:
        | ReturnType<WrappedFormik<Questionnaire[]>["formik"]>["setFieldValue"]
        | ((fieldName: string, value: any) => void)
    values: QuestionnaireAnswer
    timeOfLastTouch: number
    setTimeOfLastTouch: React.Dispatch<React.SetStateAction<number>>
    spacerSize: number
}

export function PrioritizeQuestion({
    id,
    title,
    subtitle,
    options,
    values,
    setFieldValue,
    timeOfLastTouch,
    setTimeOfLastTouch,
    spacerSize,
}: PrioritizeQuestionProps) {
    const onPriorityChanged = useCallback(
        (newOptions) => {
            const timeOfUpdate = Math.round(Date.now() / 1000)

            const value = {
                initial_order: values[id]["initial_order"],
                final_order: newOptions,
                time_spent:
                    values[id]["time_spent"] + (timeOfUpdate - timeOfLastTouch),
            }

            setTimeOfLastTouch(timeOfUpdate)

            setFieldValue(id, value)
        },
        [values[id], timeOfLastTouch]
    )

    return (
        <Container alignStretch>
            <H5 textAlign="left">{title}</H5>
            {subtitle && (
                <>
                    <Spacer size={8} />
                    <P textAlign="left">{subtitle}</P>
                </>
            )}
            <Spacer />
            <Container>
                <P>Most true</P>
                <Spacer size={8} />
                <Prioritize
                    options={options}
                    onPriorityChanged={onPriorityChanged}
                />
                <Spacer size={8} />
                <P>Least true</P>
            </Container>
            <Spacer size={spacerSize} />
        </Container>
    )
}
