import { Card, Modal, Spacer } from "@wetradeup/ui-kit"
import { PasswordLoginForm } from "@wetradeup/student-apps-shared"
import { ContentContainer, InnerModal } from "../../../ModalComponents"

export function EmailLoginModal({
    visible,
    setLoginSubmitted,
    closeModal,
    isInitialResetPassword,
}: {
    visible: boolean
    setLoginSubmitted: Function
    closeModal?: () => void
    isInitialResetPassword?: boolean
}) {
    return (
        <Modal
            visible={visible}
            width="100%"
            onClick={() => closeModal && closeModal()}
        >
            <InnerModal
                width={[
                    [700, "md"],
                    [860, "lg"],
                ]}
                onClick={(e) => e.stopPropagation()}
            >
                <Card paddingLg>
                    <Spacer lg />
                    <ContentContainer>
                        <PasswordLoginForm
                            onLoginSubmitted={() => setLoginSubmitted(true)}
                            isRoundButton
                            isInitialResetPassword={isInitialResetPassword}
                        />
                    </ContentContainer>
                    <Spacer lg />
                </Card>
            </InnerModal>
        </Modal>
    )
}
