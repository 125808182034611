import { Route, Switch } from "react-router-dom"
import { AuthRoute } from "../AuthRoute"
import { ExitCounselingAboutPage } from "./pages/ExitCounselingAboutPage"
import { ExitCounselingAdminLoginPage } from "./pages/ExitCounselingAdminLoginPage"
import { ExitCounselingAdminStudentsPage } from "./pages/ExitCounselingAdminStudentsPage"
import { ExitCounselingLoginPage } from "./pages/ExitCounselingLoginPage"
import { ExitCounselingMemberBenefits } from "./pages/ExitCounselingMemberBenefits"
import { ExitCounselingMissedPayment } from "./pages/ExitCounselingMissedPayment"
import { ExitCounselingOverview } from "./pages/ExitCounselingOverview"
import { ExitCounselingQuiz } from "./pages/ExitCounselingQuiz"
import { ExitCounselingRepayment } from "./pages/ExitCounselingRepayment"
import { ExitCounselingRepaymentHowTo } from "./pages/ExitCounselingRepaymentHowTo"
import { ExitCounselingResult } from "./pages/ExitCounselingResult"
import { ExitCounselingCreditScoresPage } from "./pages/ExitCounselingCreditScoresPage"

export function ExitCounselingRoute() {
    return (
        <Switch>
            <Route
                path={"/loan-orientation"}
                exact
                component={ExitCounselingAboutPage}
            />
            <Route
                path={"/loan-orientation/admin"}
                exact
                component={ExitCounselingAboutPage}
            />
            <Route
                path={"/loan-orientation/login"}
                exact
                component={ExitCounselingLoginPage}
            />
            <Route
                path={"/loan-orientation/admin/login"}
                exact
                component={ExitCounselingAdminLoginPage}
            />
            <AuthRoute
                path={"/loan-orientation/admin/students"}
                exact
                component={ExitCounselingAdminStudentsPage}
            />
            <AuthRoute
                path={"/loan-orientation/overview"}
                exact
                component={ExitCounselingOverview}
            />
            <AuthRoute
                path={"/loan-orientation/admin/:accountId/overview"}
                exact
                component={ExitCounselingOverview}
            />
            <AuthRoute
                path={"/loan-orientation/repayment"}
                exact
                component={ExitCounselingRepayment}
            />
            <AuthRoute
                path={"/loan-orientation/admin/:accountId/repayment"}
                exact
                component={ExitCounselingRepayment}
            />
            <AuthRoute
                path={"/loan-orientation/repayment-how-to"}
                exact
                component={ExitCounselingRepaymentHowTo}
            />
            <AuthRoute
                path={"/loan-orientation/admin/:accountId/repayment-how-to"}
                exact
                component={ExitCounselingRepaymentHowTo}
            />
            <AuthRoute
                path={"/loan-orientation/missed-payment"}
                exact
                component={ExitCounselingMissedPayment}
            />
            <AuthRoute
                path={"/loan-orientation/admin/:accountId/missed-payment"}
                exact
                component={ExitCounselingMissedPayment}
            />
            <AuthRoute
                path={"/loan-orientation/member-benefits"}
                exact
                component={ExitCounselingMemberBenefits}
            />
            <AuthRoute
                path={"/loan-orientation/admin/:accountId/member-benefits"}
                exact
                component={ExitCounselingMemberBenefits}
            />
            <AuthRoute
                path={"/loan-orientation/credit-scores"}
                exact
                component={ExitCounselingCreditScoresPage}
            />
            <AuthRoute
                path={"/loan-orientation/admin/:accountId/credit-scores"}
                exact
                component={ExitCounselingCreditScoresPage}
            />
            <AuthRoute
                path={"/loan-orientation/quiz"}
                exact
                component={ExitCounselingQuiz}
            />
            <AuthRoute
                path={"/loan-orientation/result"}
                exact
                component={ExitCounselingResult}
            />
        </Switch>
    )
}
