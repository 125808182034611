import { Container, P, Spacer } from "@wetradeup/ui-kit"
import { getIsSignupPage } from "../../../../utils"
import { SignupPageIcon } from "./SignupPageIcon"
import { WelcomePageBackground } from "./WelcomePageBackground"
import { useTheme } from "@emotion/react"
import { HiddenBr } from "../../atoms/HiddenBr"
import { WelcomePageIcon } from "./WelcomePageIcon"
import { WelcomePageMessage } from "./WelcomePageMessage"
import styled from "@emotion/styled"
import { hideOnSmDesktop } from "../../../../styles"
import { breakpoints } from "../../../../constants"
import { useFynnLocation } from "../../../hooks/useFynnLocation"
import { useWindowSize } from "../../../hooks/useWindowSize"

const LeftContainer = styled(Container)`
    ${(props) => hideOnSmDesktop(props.theme)}
    flex: 1;
    @media (min-width: ${breakpoints.tablet}) {
        padding-top: 60px;
    }
`

export function LeftColumnWelcomeScreen() {
    const theme = useTheme()
    const location = useFynnLocation()
    const isSignupPage = getIsSignupPage(location)
    const { isMdDesktop } = useWindowSize()

    if (isSignupPage) {
        return (
            <LeftContainer
                alignItems="center"
                justifyContent="center"
                backgroundColor={theme.colors.palette.greenBackground}
            >
                <WelcomePageBackground />
                <SignupPageIcon />
                <Spacer xl />
                <Container>
                    <P
                        textColor="white"
                        fontFamily="primary"
                        textAlign="center"
                        bold
                        textSize={
                            isMdDesktop
                                ? theme.sizings.fontSizeJumboSm
                                : theme.sizings.fontSizeXl
                        }
                        lineHeight={
                            isMdDesktop
                                ? theme.sizings.fontSizeJumboSm
                                : theme.sizings.fontSizeXl
                        }
                    >
                        Funding is just <HiddenBr /> a few steps away!
                    </P>
                </Container>
            </LeftContainer>
        )
    }

    return (
        <LeftContainer
            backgroundColor={theme.colors.palette.greenBackground}
            paddingMd
        >
            <WelcomePageBackground />
            <WelcomePageIcon />
            <WelcomePageMessage />
        </LeftContainer>
    )
}
