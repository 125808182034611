export function Truck() {
    return (
        <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_1029_4167)">
                <path
                    d="M16.5976 3.33203H1.26562V16.6198H16.5976V3.33203Z"
                    stroke="#0070F9"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M16.5974 8.44263H20.6859L23.7523 11.509V16.6197H16.5974V8.44263Z"
                    stroke="#0070F9"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M5.86515 21.7303C7.27642 21.7303 8.42048 20.5862 8.42048 19.175C8.42048 17.7637 7.27642 16.6196 5.86515 16.6196C4.45388 16.6196 3.30981 17.7637 3.30981 19.175C3.30981 20.5862 4.45388 21.7303 5.86515 21.7303Z"
                    stroke="#0070F9"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M19.1527 21.7303C20.564 21.7303 21.7081 20.5862 21.7081 19.175C21.7081 17.7637 20.564 16.6196 19.1527 16.6196C17.7415 16.6196 16.5974 17.7637 16.5974 19.175C16.5974 20.5862 17.7415 21.7303 19.1527 21.7303Z"
                    stroke="#0070F9"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_1029_4167">
                    <rect
                        width="24.5312"
                        height="24.5312"
                        fill="white"
                        transform="translate(0.243408 0.265625)"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}
