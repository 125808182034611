import React from "react"
import { Spacer, Container, H4 } from "@wetradeup/ui-kit"
import { captureError } from "@wetradeup/student-apps-shared"
import { ErrorLaptopIcon } from "./atoms/ErrorLaptopIcon"
import * as Sentry from "@sentry/react"
import { useHistory } from "react-router-dom"
import { ApplicationButton } from "./atoms/ApplicationButton"

const Fallback = ({ resetError }) => {
    const history = useHistory()
    return (
        <Container
            width="100%"
            height="100%"
            justifyContent="center"
            alignItems="center"
            paddingLg
        >
            <ErrorLaptopIcon />
            <Spacer size={32} />
            <H4 bold>Looks like there's been an error in our system.</H4>
            <H4 bold>Please check back in later</H4>
            <Spacer size={64} />
            <ApplicationButton
                alignCenter
                title="Take Me Home"
                primary
                onPress={() => {
                    resetError()
                    history.push("/application/intro")
                }}
            />
        </Container>
    )
}

export const ErrorBoundary = ({ children }) => (
    // @ts-ignore
    <Sentry.ErrorBoundary
        fallback={({ resetError }) => <Fallback resetError={resetError} />}
        onError={(values) => {
            captureError(values)
        }}
        showDialog
    >
        {children}
    </Sentry.ErrorBoundary>
)
