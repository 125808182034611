import {
    Questionnaire,
    QuestionOption,
    QuestionnaireAnswer,
    EditingQuestionnaireAnswer,
} from "@wetradeup/student-apps-shared"
import { Container, H5, MultiSelect, P, Spacer } from "@wetradeup/ui-kit"
import React, { useCallback, useState } from "react"
import { WrappedFormik } from "../../../utils"

interface MultipleRadioQuestionProps {
    id: string
    title: string
    subtitle: string
    options: QuestionOption[]
    questionnaire: Questionnaire[]
    setQuestionnaire: React.Dispatch<React.SetStateAction<Questionnaire[]>>
    setFieldValue:
        | ReturnType<WrappedFormik<Questionnaire[]>["formik"]>["setFieldValue"]
        | ((fieldName: string, value: any) => void)
    values: QuestionnaireAnswer | EditingQuestionnaireAnswer
    timeOfLastTouch: number
    setTimeOfLastTouch: React.Dispatch<React.SetStateAction<number>>
    newAnswer?: EditingQuestionnaireAnswer
    isEditAnswer?: boolean
    dependentQuestions?: Questionnaire[]
    setDependentQuestions?: React.Dispatch<
        React.SetStateAction<Questionnaire[]>
    >
}

export function MultipleRadioQuestion({
    title,
    subtitle,
    options,
    id,
    questionnaire,
    dependentQuestions,
    setDependentQuestions,
    setQuestionnaire,
    setFieldValue,
    values,
    timeOfLastTouch,
    setTimeOfLastTouch,
    newAnswer,
    isEditAnswer,
}: MultipleRadioQuestionProps) {
    const [error, setError] = useState<string | null>()
    const onOptionSelected = useCallback(
        (fieldName, option) => {
            setTimeOfLastTouch(Math.round(Date.now() / 1000))

            const newQuestionnaire = JSON.parse(
                JSON.stringify(questionnaire)
            ).map((q) => {
                if (option["show"].includes(q["id"])) {
                    q["hidden"] = false
                } else if (option["hide"].includes(q["id"])) {
                    q["hidden"] = true
                }

                return q
            })

            if (isEditAnswer) {
                const newDependentQuestions = option.show.map((showId) =>
                    newQuestionnaire.find((nQ) => nQ.id === showId)
                )
                const filteredQuestions = dependentQuestions.filter(
                    (dQ) => !option.hide.includes(dQ.id)
                )
                setDependentQuestions(
                    filteredQuestions.concat(newDependentQuestions)
                )
            }

            setQuestionnaire(newQuestionnaire)
            let oldValues = null
            if (isEditAnswer) {
                oldValues = newAnswer[id] || []
            } else {
                oldValues = values[id] || []
            }
            const newValues = [...oldValues, option]
            if (newValues.length) {
                setError(null)
            }
            setFieldValue(fieldName, newValues)
        },
        [newAnswer, values[id], timeOfLastTouch]
    )

    const onOptionDeselected = useCallback(
        (fieldName, option) => {
            setTimeOfLastTouch(Math.round(Date.now() / 1000))
            let selectedOptions = isEditAnswer ? newAnswer[id] : values[id]
            const newValues = selectedOptions.filter((x) => {
                return x !== option
            })
            if (!newValues.length) {
                setError("Please select a minimum of one option")
            }
            setFieldValue(fieldName, newValues)
        },
        [newAnswer, values[id], timeOfLastTouch]
    )

    return (
        <Container alignStretch>
            <H5 textAlign="left">{title}</H5>
            {subtitle && (
                <>
                    <Spacer size={8} />
                    <P textAlign="left">{subtitle}</P>
                </>
            )}
            <Spacer />
            <Container>
                <MultiSelect
                    options={options}
                    name={id}
                    onOptionSelected={onOptionSelected}
                    onOptionDeselected={onOptionDeselected}
                    placeholder={"Click here to see options"}
                    alignStretch
                    error={error}
                />
            </Container>
            <Spacer size="lg" />
        </Container>
    )
}
