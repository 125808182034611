import { useTheme } from "@emotion/react"
import { Container, FynnFullLetterLogo, P, Spacer } from "@wetradeup/ui-kit"

export function WelcomePageMessageShort() {
    const theme = useTheme()
    return (
        <Container flex col alignItems="center" justifyCenter="center">
            <FynnFullLetterLogo white />
            <Spacer lg />
            <P
                textColor="white"
                fontFamily="primary"
                textAlign="center"
                bold
                textSize={theme.sizings.fontSizeXl}
                lineHeight={theme.sizings.fontSizeXl}
            >
                Get approved in minutes!
            </P>
        </Container>
    )
}
