export function BlueLock() {
    return (
        <svg
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.75 6.875H3C2.30964 6.875 1.75 7.43464 1.75 8.125V12.5C1.75 13.1904 2.30964 13.75 3 13.75H11.75C12.4404 13.75 13 13.1904 13 12.5V8.125C13 7.43464 12.4404 6.875 11.75 6.875Z"
                fill="#2E54E8"
                stroke="#2E54E8"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M4.25 6.875V4.375C4.25 3.5462 4.57924 2.75134 5.16529 2.16529C5.75134 1.57924 6.5462 1.25 7.375 1.25C8.2038 1.25 8.99866 1.57924 9.58471 2.16529C10.1708 2.75134 10.5 3.5462 10.5 4.375V6.875"
                stroke="#2E54E8"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
