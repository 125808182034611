import { Container, H2, H6, H4, P, HR, Spacer } from "@wetradeup/ui-kit"
import styled from "@emotion/styled"
import {
    useStudentApplicationDetails,
    useUpdateNoteSizeChangeStatus,
} from "@wetradeup/student-apps-shared"
import { ContentContainer } from "../ModalComponents"
import OldBorrowingBg from "../../assets/application/old_borrowing.png"
import NewBorrowingBg from "../../assets/application/new_borrowing.png"
import { useEffect, useMemo } from "react"
import { useHistory } from "react-router-dom"
import { HiddenBr } from "../application/atoms/HiddenBr"
import { SpinningLoader } from "../application/atoms/SpinningLoader"
import { ApplicationButton } from "../atoms/ApplicationButton"
import { toTop } from "../../utils"

const ImageContainer = styled(Container)`
    background: ${(props) => `url(${props.bgImg}) center no-repeat;`}
    background-size: 100% 100%;
    border-radius: 8px;
    width: 100%;
    height: 100%;
`

interface FinancingChangedProps {
    studentApplicationDetails: ReturnType<typeof useStudentApplicationDetails>
}

export default function FinancingChanged({
    studentApplicationDetails,
}: FinancingChangedProps) {
    const updateNoteSizeChangeStatusMutation = useUpdateNoteSizeChangeStatus()
    const history = useHistory()

    const { request_amount, updated_amount } = useMemo(() => {
        if (studentApplicationDetails.data) {
            const borrowing_request =
                studentApplicationDetails.data?.borrowing_request
            const note_size =
                studentApplicationDetails.data?.note?.cash_outflow?.note_size
            return {
                request_amount:
                    borrowing_request && borrowing_request.toLocaleString(),
                updated_amount: note_size && note_size.toLocaleString(),
            }
        } else {
            return {
                borrowing_request: null,
                note_size: null,
            }
        }
    }, [studentApplicationDetails.data])

    const handlePress = async () => {
        history.push("/isl-finalize/final-review", {
            disableAutoRouting: true,
        })
    }

    const { isLoading: loading } = updateNoteSizeChangeStatusMutation

    useEffect(() => {
        toTop()
    }, [])

    return (
        <>
            <Container
                paddingMd
                width={[
                    [550, "sm"],
                    [550, "md"],
                    [750, "lg"],
                ]}
            >
                <ContentContainer>
                    <Spacer />
                    <H2 bold="light">
                        {" "}
                        Your financing amount was <HiddenBr /> changed by your
                        school
                    </H2>
                    <Spacer />
                    <P textAlign="center">
                        {" "}
                        Your school has indicated you require a different amount
                        of <HiddenBr />
                        funding than you previously requested.{" "}
                    </P>
                    <Spacer />
                    <ImageContainer bgImg={OldBorrowingBg} paddingMd>
                        <H6> Requested financing amount </H6>
                        <Spacer />
                        <H2> ${request_amount} </H2>
                    </ImageContainer>
                    <Spacer />
                    <ImageContainer bgImg={NewBorrowingBg} paddingMd>
                        <H6> Updated amount </H6>
                        <Spacer />
                        <H2> ${updated_amount} </H2>
                    </ImageContainer>
                    <Spacer lg />
                    {loading ? (
                        <SpinningLoader />
                    ) : (
                        <>
                            <P textAlign="center">Looks good?</P>
                            <Spacer />
                            <ContentContainer alignItems="center">
                                <ApplicationButton
                                    fullWidth
                                    type="button"
                                    primary
                                    onPress={handlePress}
                                    title="Accept and continue"
                                />
                            </ContentContainer>
                            <Spacer lg />
                            <HR />
                            <Spacer lg />
                            <Container
                                alignItems="center"
                                justifyContent="center"
                                fullWidth
                            >
                                <P textAlign="center">
                                    Amount not looking right?
                                </P>
                                <Spacer />
                                <H4 bold>
                                    {" "}
                                    Contact your school’s admission /{" "}
                                    <HiddenBr /> tuition assistance department{" "}
                                </H4>
                                <Spacer />
                                <P textAlign="center">
                                    Please note that if your school re-adjusts
                                    your tuition it may take up to 24 hours for
                                    that change to be reflected here. Thank you
                                    for your patience!
                                </P>
                            </Container>
                        </>
                    )}
                </ContentContainer>
                <Spacer lg />
            </Container>
        </>
    )
}
