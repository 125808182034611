import { useHistory } from "react-router-dom"
import {
    Container,
    Spacer,
    P,
    TouchableContainer,
    Card,
} from "@wetradeup/ui-kit"
import ExclamationTriangle from "../../assets/ExclamationTriangle.png"
import { BenefitCard } from "./molecules/BenefitCard"
import { ClickableCaption } from "../application/atoms/ClickableCaption"
import { useContract } from "./hooks/useContract"
import { useState } from "react"
import { SpinningLoader } from "../application/atoms/SpinningLoader"
import { ContentContainer } from "../ModalComponents"
import { Stop } from "./icons/Stop"
import { useStudentApplicationDetails } from "@wetradeup/student-apps-shared"
import styled from "@emotion/styled"
import { useWindowSize } from "../hooks/useWindowSize"
import { useTheme } from "@emotion/react"
import { breakpoints } from "../../constants"
import { IncomeProtection } from "./icons/IncomeProtection"
import { ApplicationButton } from "../atoms/ApplicationButton"

const TopContainer = styled(Container)`
    padding-left: 48px;
    padding-right: 48px;
    padding-bottom: 32px;

    @media (max-width: ${breakpoints.desktopSm}) {
        padding-left: 16px;
        padding-right: 16px;
    }
`

const StickyCard = styled(Card)`
    ${(props) => `
        ${
            props.stickyFooter &&
            `
            position: fixed;
            bottom: 0px;
            left: 0px;
            z-index: 800;
            border-radius: 0px;
        `
        }
    `}
`

const MobileCard = styled(Container)`
    ${(props) => `
        ${
            props.stickyFooter
                ? `
            margin-bottom: 212px;
        `
                : `
            margin-bottom: 0px;
        `
        }
    `}
`

interface AutopayConsentProps {
    studentApplicationDetails: ReturnType<typeof useStudentApplicationDetails>
}

export default function AutopayConsent({
    studentApplicationDetails,
}: AutopayConsentProps) {
    const history = useHistory()

    const [loading, setLoading] = useState(false)

    const getSignURL = useContract({ setLoading, type: "autopay_consent" })

    const goToComplete = () => {
        history.push("/isl-finalize/complete")
    }

    const { windowSize } = useWindowSize()
    const theme = useTheme()
    const stickyFooter = windowSize.width <= theme.breakpoints.sm

    return (
        <MobileCard
            width={[
                [550, "sm"],
                [550, "md"],
                [750, "lg"],
            ]}
            justifyContent="center"
            alignItems="center"
            disablePadding
            stickyFooter={stickyFooter}
        >
            {!!studentApplicationDetails.data && (
                <>
                    <TopContainer backgroundColor="orange">
                        <Spacer />
                        <Container justifyContent="center" alignItems="center">
                            <img
                                src={ExclamationTriangle}
                                alt="exclamation_triangle"
                                width={78}
                            />
                        </Container>
                        <Container>
                            <Container
                                padding={
                                    windowSize.width > theme.breakpoints.lg
                                        ? ["md", "xl"]
                                        : ["md", "md"]
                                }
                            >
                                <P
                                    textAlign="center"
                                    lineHeight="32px"
                                    textSize="24px"
                                    textColor="black"
                                >
                                    If you{" "}
                                    <P textSize="24px" textColor="black" bold>
                                        do not{" "}
                                    </P>
                                    consent to autopay, you will{" "}
                                    <P textSize="24px" textColor="black" bold>
                                        lose access{" "}
                                    </P>
                                    to two major benefits:
                                </P>
                            </Container>
                            <Container
                                {...(windowSize.width > theme.breakpoints.md
                                    ? { row: true }
                                    : { column: true })}
                            >
                                <BenefitCard
                                    image={<IncomeProtection />}
                                    title="Payment Reduction"
                                    description={
                                        <P>
                                            Qualified borrowers get up to 20%
                                            off their monthly payments, and Fynn
                                            covers the rest. There is no limit
                                            to the number of reduced payments
                                            you can qualify for, and you never
                                            have to pay back the amount Fynn
                                            covers.
                                        </P>
                                    }
                                />
                                <Spacer />
                                <BenefitCard
                                    image={
                                        <Stop
                                            width={48}
                                            color={theme.colors.palette.black}
                                        />
                                    }
                                    title="Payment Pause"
                                    description={
                                        <P>
                                            {`If your income drops below $${studentApplicationDetails.data?.note.isl_terms.formatted_monthly_no_payment_threshold} a month, you can apply to pause your payments for up to 12 months over the life of your loan! While your payments are paused, so is your interest.`}
                                        </P>
                                    }
                                />
                            </Container>
                        </Container>
                    </TopContainer>
                    <StickyCard
                        backgroundColor="lightYellow"
                        justifyContent="center"
                        alignItems="center"
                        disableShadow
                        stickyFooter={stickyFooter}
                    >
                        <ContentContainer
                            justifyContent="center"
                            alignItems="center"
                        >
                            <P textAlign="center" textColor="black">
                                <P textColor="black" bold>
                                    To qualify for these benefits, you must
                                    enroll in autopay.
                                </P>{" "}
                                Enrolling only takes a few minutes, and it will
                                make paying your bill in the future easier!
                            </P>
                            <Spacer lg />
                            {loading ? (
                                <SpinningLoader />
                            ) : (
                                <ApplicationButton
                                    title="Enable autopay"
                                    onPress={getSignURL}
                                    primary
                                />
                            )}
                            <Spacer />
                            <TouchableContainer onPress={goToComplete}>
                                <ClickableCaption>
                                    Continue without autopay
                                </ClickableCaption>
                            </TouchableContainer>
                        </ContentContainer>
                    </StickyCard>
                </>
            )}
        </MobileCard>
    )
}
