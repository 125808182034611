/** @jsxImportSource @emotion/react */
import { Route, Switch } from "react-router-dom"
import React from "react"
import { ISLHeader, finalizeTitleOrders, finalizeTitles } from "./ISLHeader"

export function FinalizeHeaderRoutes() {
    return (
        <div>
            <Switch>
                <Route path="/isl-finalize/loan-terms">
                    <ISLHeader
                        currentPosition={finalizeTitleOrders.indexOf(
                            finalizeTitles.loanTerms
                        )}
                        isFinalize
                    />
                </Route>
                <Route path="/isl-finalize/identity">
                    <ISLHeader
                        currentPosition={finalizeTitleOrders.indexOf(
                            finalizeTitles.verification
                        )}
                        isFinalize
                    />
                </Route>
                <Route path="/isl-finalize/pending-manual">
                    <ISLHeader
                        currentPosition={finalizeTitleOrders.indexOf(
                            finalizeTitles.verification
                        )}
                        isFinalize
                    />
                </Route>
                <Route path="/isl-finalize/pending-automatic">
                    <ISLHeader
                        currentPosition={finalizeTitleOrders.indexOf(
                            finalizeTitles.verification
                        )}
                        isFinalize
                    />
                </Route>
                <Route path="/isl-finalize/awaiting">
                    <ISLHeader
                        currentPosition={finalizeTitleOrders.indexOf(
                            finalizeTitles.verification
                        )}
                        isFinalize
                    />
                </Route>
                <Route path="/isl-finalize/school-confirmed">
                    <ISLHeader
                        currentPosition={finalizeTitleOrders.indexOf(
                            finalizeTitles.verification
                        )}
                        isFinalize
                    />
                </Route>
                <Route path="/isl-finalize/final-review">
                    <ISLHeader
                        currentPosition={finalizeTitleOrders.indexOf(
                            finalizeTitles.signature
                        )}
                        isFinalize
                    />
                </Route>
                <Route>
                    <ISLHeader hide={true} />
                </Route>
            </Switch>
        </div>
    )
}
