import { useTheme } from "@emotion/react"
import { useHistory, useRouteMatch } from "react-router-dom"
import { Card, Container, P, Spacer } from "@wetradeup/ui-kit"
import { ApplicationButton } from "../../atoms/ApplicationButton"
import { DefaultPage } from "../molecules/DefaultPage"
import { UnderlineText } from "../../atoms/UnderlineText"
import { PAYMENT_PAGE_URL } from "@wetradeup/student-apps-shared"
import { SpinningLoader } from "../../application/atoms/SpinningLoader"
import { useProperUpdateExitCounselingStatus } from "../hooks/useProperUpdateExitCounselingStatus"
import { useProperExitCounseling } from "../hooks/useProperExitCounseling"
import { determineAchOverlay } from "./ExitCounselingMemberBenefits"

const terms = [
    {
        title: "Payment via check or money order",
        content:
            "Mail payment to: P.O. Box 206536, Dallas, TX 75320-6536. Check must be payable to Fynn.",
    },
    {
        title: "One time or debit by phone",
        content: "You can contact loan servicing at 800-419-2118",
    },
]

export function ExitCounselingRepaymentHowTo() {
    const theme = useTheme()
    const history = useHistory()

    const match = useRouteMatch<{ accountId?: string }>({
        path: "/loan-orientation/admin/:accountId/repayment-how-to",
    })
    const updateExitCounselingStatus =
        useProperUpdateExitCounselingStatus(match)
    const exitCounseling = useProperExitCounseling(match)
    const handleContinue = async () => {
        await updateExitCounselingStatus.mutateAsync({
            exit_counseling_stage: "repayment_how_to",
        })
        if (match?.params?.accountId) {
            history.push(
                `/loan-orientation/admin/${match?.params?.accountId}/missed-payment`
            )
        } else {
            history.push("/loan-orientation/missed-payment")
        }
    }

    if (!exitCounseling.data) {
        return <SpinningLoader />
    }

    return (
        <DefaultPage>
            <Card border="medGrey" disableShadow width="100%" paddingLg>
                <P
                    textAlign="left"
                    textSize={theme.sizings.fontSizeInc2}
                    bold
                    textColor="black"
                >
                    {exitCounseling.data.onboarded_to_servicer
                        ? "Here's how to make a payment:"
                        : "Here are some helpful tips about how payments work once you're onboarded to our payment portal:"}
                </P>
                <Spacer lg />
                <Card
                    backgroundColor={
                        determineAchOverlay(exitCounseling.data)
                            ? "medLightBlue"
                            : "newLightOrange"
                    }
                    border={
                        determineAchOverlay(exitCounseling.data)
                            ? "newBlue"
                            : "newDarkOrange"
                    }
                    disableShadow
                    width="100%"
                >
                    {exitCounseling.data.onboarded_to_servicer ? (
                        <P
                            textAlign="left"
                            textSize={theme.sizings.fontSizeInc2}
                            bold
                            textColor="black"
                        >
                            Automatic Payments -{" "}
                            <P
                                textSize={theme.sizings.fontSizeInc2}
                                bold
                                textColor={
                                    determineAchOverlay(exitCounseling.data)
                                        ? "newBlue"
                                        : "black"
                                }
                            >
                                {determineAchOverlay(exitCounseling.data)
                                    ? "You're Signed Up!"
                                    : "You're Not Enrolled!"}
                            </P>
                        </P>
                    ) : (
                        <P
                            textAlign="left"
                            textSize={theme.sizings.fontSizeInc2}
                            bold
                            textColor="black"
                        >
                            Automatic Payments
                        </P>
                    )}
                    <Spacer lg />
                    <P textColor="black">
                        If you have not already signed up for auto-pay, we
                        strongly encourage you to do so. Once you get your
                        welcome packet, you'll be able to log into your payment
                        portal and adjust autopay.
                    </P>
                </Card>
                <Spacer />
                <Card
                    backgroundColor="medLightGrey"
                    border="medGrey"
                    disableShadow
                    width="100%"
                >
                    <P
                        textAlign="left"
                        textSize={theme.sizings.fontSizeInc2}
                        bold
                        textColor="black"
                    >
                        Online payments through My Payment Center
                    </P>
                    <Spacer lg />
                    <P textColor="black">
                        Go to{" "}
                        <a
                            href={PAYMENT_PAGE_URL}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <UnderlineText textColor="black">
                                studentloans.acct-admin.com
                            </UnderlineText>
                        </a>{" "}
                        to register. You'll get access to this payment portal
                        once you receive your welcome packet!
                    </P>
                </Card>
                <Spacer />
                {terms.map((term, ind) => (
                    <>
                        <Card
                            backgroundColor="medLightGrey"
                            border="medGrey"
                            disableShadow
                            width="100%"
                        >
                            <P
                                textAlign="left"
                                textSize={theme.sizings.fontSizeInc2}
                                bold
                                textColor="black"
                            >
                                {term.title}
                            </P>
                            <Spacer lg />
                            <P textColor="black">{term.content}</P>
                        </Card>
                        {ind < terms.length - 1 && <Spacer />}
                    </>
                ))}
            </Card>
            <Spacer lg />
            <Container width={300}>
                {updateExitCounselingStatus.isLoading && <SpinningLoader />}
                {!updateExitCounselingStatus.isLoading && (
                    <ApplicationButton
                        fullWidth
                        alignCenter
                        title="Continue"
                        buttonColor="newBlue"
                        onPress={handleContinue}
                    />
                )}
            </Container>
        </DefaultPage>
    )
}
