/** @jsxImportSource @emotion/react */
import { ApplicationHeaderRoutes } from "./ApplicationHeaderRoutes"
import { ApplicationFormRoutes } from "./ApplicationFormRoutes"
import { useApplicationProgress } from "./hooks/useApplicationProgress"
import { ApplicationCard, CardContainer, InnerModal } from "../ModalComponents"
import { Container, Spacer } from "@wetradeup/ui-kit"
import { getIsNonAuthPage } from "../../utils"
import { useFynnLocation } from "../hooks/useFynnLocation"
import { useWindowSize } from "../hooks/useWindowSize"

export default function ApplicationFormWrapper() {
    const {
        schoolId,
        appFormId,
        employerId,
        programId,
        setAppFormId,
        setSchoolId,
        setSchoolName,
        school,
    } = useApplicationProgress()

    const location = useFynnLocation()
    const isNonAuthPage = getIsNonAuthPage(location)
    const { isDesktop } = useWindowSize()

    return (
        <InnerModal flex={1} alignStretch disableMaxWidth={isNonAuthPage}>
            <Container alignStretch justifyContent="space-between">
                <CardContainer disableMaxWidth={isNonAuthPage}>
                    {!isNonAuthPage && <Spacer size={60} />}
                    {!isNonAuthPage && <ApplicationHeaderRoutes />}
                    <ApplicationCard
                        alignStretch
                        minWidth="100%"
                        alignItems="center"
                        disablePadding
                        disableCard={isNonAuthPage && !isDesktop}
                    >
                        <ApplicationFormRoutes
                            schoolId={schoolId}
                            appFormId={appFormId}
                            programId={programId}
                            employerId={employerId}
                            setAppFormId={setAppFormId}
                            setSchoolId={setSchoolId}
                            setSchoolName={setSchoolName}
                            school={school}
                        />
                    </ApplicationCard>
                </CardContainer>
            </Container>
        </InnerModal>
    )
}
