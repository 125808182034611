import {
    Container,
    P,
    Spacer,
    H2,
    Card,
    TouchableContainer,
    SecureLabel,
} from "@wetradeup/ui-kit"
import { ApplicationCard, ContentContainer } from "../../ModalComponents"
import { SpinningLoader } from "../../application/atoms/SpinningLoader"
import { HiddenBr } from "../../application/atoms/HiddenBr"
import { ExclamationCard } from "./ExclamationCard"
import { useVerifyBank } from "./useVerifyBank"
import { useEffect } from "react"
import { toTop } from "../../../utils"
import { BankVerificationLoadingView } from "./BankVerificationLoadingView"
import styled from "@emotion/styled"
import { breakpoints } from "../../../constants"
import { BankFAQ } from "../../application/molecules/BankFAQ"
import {
    useGetPlaidLinkToken,
    useGetStudentProfile,
    useStudentApplicationDetails,
} from "@wetradeup/student-apps-shared"
import { BankVerificationFailedModal } from "./BankVerificationFailedModal"
import { BankInternalErrorModal } from "./BankInternalErrorModal"
import { BankRejectedModal } from "./BankRejectedModal"
import { BankFrozenModal } from "./BankFrozenModal"
import { useTheme } from "@emotion/react"
import { VerifyBankIcon } from "../icons/VerifyBankIcon"
import { VerifyBankIDUploadStatus } from "./VerifyBankIDUploadStatus"
import { VerifyBankStatus } from "./VerifyBankStatus"
import { useStripe } from "./useStripe"
import { PendingIdentityVerificationReview } from "./PendingIdentityVerificationReview"
import { RightArrow } from "../icons/RightArrow"
import { useWindowSize } from "../../hooks/useWindowSize"
import { BankVerifyButton } from "./BankVerifyButton"

const ResponsiveSpacer = styled(Container)`
    @media (max-width: ${breakpoints.mobileLg}) {
        height: 2px;
    }
    @media (min-width: ${breakpoints.mobileLg}) {
        height: 8px;
    }
`

export const ResponsiveApplicationCard = styled(ApplicationCard)`
    @media (max-width: ${breakpoints.mobileLg}) {
        border-radius: 0px;
        width: 100vw;
    }
`

interface BankVerificationModalProps {
    linkToken?: string
    onSuccess: Function
    onExit: Function
    onEvent: Function
    error: string
    studentProfile: ReturnType<typeof useGetStudentProfile>
    studentApplicationDetails: ReturnType<typeof useStudentApplicationDetails>
    handleCreateVerificationSession: (e: any) => void
    isStripeLoading: boolean
}

function BankVerificationButton({
    linkToken,
    requiresIDUpload,
    onExit,
    onSuccess,
    onEvent,
}) {
    const theme = useTheme()

    return (
        <>
            {!requiresIDUpload && (
                <>
                    <Container alignItems="center">
                        <P
                            textColor="black"
                            textSize={theme.sizings.fontSizeInc2}
                        >
                            Verify Your Identity With a Bank Account
                        </P>
                    </Container>
                    <Spacer lg />
                </>
            )}
            {requiresIDUpload ? (
                <>
                    <Card
                        paddingLg
                        disableShadow
                        row
                        border="blue"
                        justifyContent={!linkToken ? "center" : "space-between"}
                        alignItems="center"
                    >
                        {linkToken && (
                            <BankVerifyButton
                                linkToken={linkToken}
                                buttonType="card"
                                onSuccess={onSuccess}
                                onExit={onExit}
                                onEvent={onEvent}
                            />
                        )}
                        {!linkToken && (
                            <Container justifyContent="center">
                                <SpinningLoader size={24} />
                            </Container>
                        )}
                    </Card>
                    <Spacer />
                    <ExclamationCard />
                </>
            ) : (
                <ContentContainer alignItems="center">
                    {linkToken && (
                        <BankVerifyButton
                            linkToken={linkToken}
                            buttonType="small"
                            onSuccess={onSuccess}
                            onExit={onExit}
                            onEvent={onEvent}
                        />
                    )}
                    {!linkToken && <SpinningLoader size={24} />}
                </ContentContainer>
            )}
        </>
    )
}

function IDUploadRetryButton({ handleCreateVerificationSession, isLoading }) {
    const theme = useTheme()
    return (
        <>
            <TouchableContainer
                onPress={handleCreateVerificationSession}
                width="100%"
            >
                <Card
                    paddingLg
                    disableShadow
                    row
                    border="blue"
                    justifyContent={isLoading ? "center" : "space-between"}
                    alignItems="center"
                >
                    {isLoading && <SpinningLoader size={24} />}
                    {!isLoading && (
                        <>
                            <P
                                textSize={theme.sizings.fontSizeInc2}
                                textColor="black"
                            >
                                Upload a Different Government Issued Photo ID
                            </P>
                            <RightArrow />
                        </>
                    )}
                </Card>
            </TouchableContainer>
            <Spacer lg />
        </>
    )
}

function BankVerificationModal({
    linkToken,
    error,
    studentProfile,
    studentApplicationDetails,
    handleCreateVerificationSession,
    isStripeLoading,
    onSuccess,
    onEvent,
    onExit,
}: BankVerificationModalProps) {
    const theme = useTheme()
    const requiresIDUpload =
        studentApplicationDetails.data?.underwriting_recommendation ===
        "approved_after_id_verification"
    const hasCompletedBank =
        studentProfile.data?.bank_identity_verification === "approved"
    const pendingIDReview =
        studentProfile.data?.document_identity_verification === "pending" &&
        studentProfile.data?.has_uploaded_id_to_stripe
    const retryIDUpload =
        studentProfile.data?.document_identity_verification === "rejected" &&
        hasCompletedBank

    const { windowSize } = useWindowSize()

    return (
        <>
            {requiresIDUpload ? (
                <H2 bold="light">Time to verify your identity.</H2>
            ) : (
                <H2 bold="light">
                    {" "}
                    Loan submitted! <HiddenBr /> Time to verify your identity.{" "}
                </H2>
            )}
            <Spacer />
            <ResponsiveApplicationCard
                alignItems="center"
                justifyContent="center"
                disableCard
                border
                width="100%"
                disablePadding
            >
                <Container
                    width="100%"
                    padding={
                        windowSize.width < theme.breakpoints.sm
                            ? ["lg", "md"]
                            : ["lg", "xl"]
                    }
                    alignItems="center"
                    justifyContent="center"
                >
                    <Container width="100%">
                        {requiresIDUpload &&
                            hasCompletedBank &&
                            pendingIDReview && (
                                <>
                                    <Card
                                        disableShadow
                                        border="newDarkOrange"
                                        backgroundColor="newLightOrange"
                                    >
                                        <P
                                            textColor="black"
                                            textSize={
                                                theme.sizings.fontSizeInc1
                                            }
                                        >
                                            We're still working on verifying
                                            your ID. We may reach out to you by
                                            phone or email to confirm your
                                            information.
                                        </P>
                                    </Card>
                                    <Spacer lg />
                                </>
                            )}
                        {requiresIDUpload &&
                        (!hasCompletedBank ||
                            (hasCompletedBank && pendingIDReview)) ? (
                            <VerifyBankIDUploadStatus
                                studentProfile={studentProfile}
                                hasCompletedBank={hasCompletedBank}
                            />
                        ) : requiresIDUpload &&
                          hasCompletedBank &&
                          !pendingIDReview ? (
                            <Card
                                disableShadow
                                backgroundColor="newLightOrange"
                                border="newDarkOrange"
                            >
                                <VerifyBankIDUploadStatus
                                    studentProfile={studentProfile}
                                    hasCompletedBank={hasCompletedBank}
                                    statusOverride="retry"
                                />
                            </Card>
                        ) : (
                            <Container
                                justifyContent="center"
                                alignItems="center"
                            >
                                <VerifyBankIcon />
                            </Container>
                        )}
                        <Spacer lg />
                        {requiresIDUpload &&
                            hasCompletedBank &&
                            (retryIDUpload || !pendingIDReview) && (
                                <IDUploadRetryButton
                                    isLoading={isStripeLoading}
                                    handleCreateVerificationSession={
                                        handleCreateVerificationSession
                                    }
                                />
                            )}
                        {requiresIDUpload && (
                            <VerifyBankStatus studentProfile={studentProfile} />
                        )}
                        {!hasCompletedBank && (
                            <BankVerificationButton
                                linkToken={linkToken}
                                requiresIDUpload={requiresIDUpload}
                                onSuccess={onSuccess}
                                onExit={onExit}
                                onEvent={onEvent}
                            />
                        )}
                    </Container>
                    <Spacer lg />
                    <Container
                        maxWidth={500}
                        justifyContent="center"
                        alignItems="center"
                    >
                        <SecureLabel />
                    </Container>
                    {error && (
                        <>
                            <Spacer size={12} />
                            <P error textAlign="center">
                                {error}
                            </P>
                        </>
                    )}
                </Container>
            </ResponsiveApplicationCard>
        </>
    )
}

interface VerifyBankProps {
    studentProfile: ReturnType<typeof useGetStudentProfile>
    studentApplicationDetails: ReturnType<typeof useStudentApplicationDetails>
    linkTokenQuery: ReturnType<typeof useGetPlaidLinkToken>
    onSuccess: Function
    onExit: Function
    onEvent: Function
}

export default function VerifyBank({
    studentProfile,
    studentApplicationDetails,
    linkTokenQuery,
    onSuccess,
    onExit,
    onEvent,
}: VerifyBankProps) {
    const {
        isPendingIDReview,
        handleCreateVerificationSession,
        isLoading: isStripeLoading,
    } = useStripe()
    const {
        isUpdatingPlaid,
        error,
        showBankVerificationFailedModal,
        setShowBankVerificationModal,
        showInternalErrorModal,
        setShowInternalErrorModal,
        remainingAttempts,
        showRejectedModal,
        showFrozenModal,
        setShowFrozenModal,
    } = useVerifyBank({
        studentProfileLoaded: studentProfile.isSuccess,
        studentApplicationDetailsLoaded: studentApplicationDetails.isSuccess,
        bankVerificationIdentity:
            studentProfile.data?.bank_identity_verification,
        bankVerificationIdentityAttempts:
            studentApplicationDetails.data?.bank_identity_verification_attempts,
        plaidStatus: studentProfile.data?.plaid_status,
        bankIdentityFrozen: studentProfile.data?.bank_identity_frozen,
    })

    const isLoading = linkTokenQuery.isLoading || !linkTokenQuery.isSuccess
    const hasCompletedBank =
        studentProfile.data?.bank_identity_verification === "approved"

    useEffect(() => {
        toTop()
    }, [])

    return (
        <>
            {isPendingIDReview ? (
                <PendingIdentityVerificationReview />
            ) : (
                <>
                    {isUpdatingPlaid ? (
                        <BankVerificationLoadingView />
                    ) : (
                        <BankVerificationModal
                            linkToken={
                                isLoading
                                    ? undefined
                                    : linkTokenQuery.data?.link_token
                            }
                            onSuccess={onSuccess}
                            onExit={onExit}
                            onEvent={onEvent}
                            error={error}
                            studentProfile={studentProfile}
                            studentApplicationDetails={
                                studentApplicationDetails
                            }
                            handleCreateVerificationSession={
                                handleCreateVerificationSession
                            }
                            isStripeLoading={isStripeLoading}
                        />
                    )}
                    {!isUpdatingPlaid && !hasCompletedBank && (
                        <>
                            <ResponsiveSpacer />
                            <ResponsiveApplicationCard
                                disableCard
                                border
                                alignItems="center"
                                justifyContent="center"
                                width="100%"
                            >
                                <BankFAQ />
                            </ResponsiveApplicationCard>
                        </>
                    )}
                </>
            )}
            <BankVerificationFailedModal
                showBankVerificationFailed={showBankVerificationFailedModal}
                setShowBankVerificationFailed={setShowBankVerificationModal}
                remainingAttempts={remainingAttempts}
            />
            <BankInternalErrorModal
                showInternalError={showInternalErrorModal}
                setShowInternalError={setShowInternalErrorModal}
            />
            <BankRejectedModal showRejectedModal={showRejectedModal} />
            <BankFrozenModal
                showFrozenModal={showFrozenModal}
                setShowFrozenModal={setShowFrozenModal}
            />
        </>
    )
}
