export function MobileLicensePending() {
    return (
        <svg
            width="380"
            height="226"
            viewBox="0 0 380 226"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M340.913 189.241H39.0526C33.6136 189.241 29.0278 186.01 29.0278 181.943V8.29775C29.0278 4.33331 33.4679 1 39.0526 1H340.913C346.359 1 350.938 4.23046 350.938 8.29775V181.943C350.938 186.01 346.498 189.241 340.913 189.241Z"
                fill="#EAEEEF"
                stroke="#344F5B"
                stroke-width="2"
            />
            <path
                d="M136.205 129.175C135.843 130.15 135.224 131.131 134.599 132.112C134.243 132.823 133.71 133.541 133.084 134.16C132.103 135.319 131.036 136.214 129.699 136.925C125.334 139.151 118.914 138.71 110.091 139.243C95.8345 140.132 87.8143 140.757 82.9152 134.252C82.8232 134.16 82.7373 134.074 82.7373 133.982C77.1208 126.14 76.4095 115.268 76.4095 106.095C76.4095 104.667 76.4095 103.238 76.4095 101.908V101.724C76.4953 98.6945 76.4953 95.8433 76.4095 93.7954V92.3667C75.876 86.4006 74.4474 62.8736 81.4865 55.9265C83.8042 53.7866 93.4309 50.6656 103.769 51.199C112.322 51.5546 120.962 55.0313 127.29 58.6857C128.718 60.1144 128.804 62.1624 128.804 62.1624L122.391 79.3616C122.213 80.2506 122.66 82.0349 123.372 84.0829C125.15 89.3377 128.626 96.6466 128.896 97.18C130.233 99.4978 131.57 101.724 132.728 103.6C133.887 105.47 134.954 107.07 135.665 108.143C138.161 111.884 139.676 121.063 136.205 129.175Z"
                fill="#EAEEEF"
                stroke="#344F5B"
                stroke-width="2"
            />
            <path
                d="M116.774 57.6187C109.827 65.5468 96.6378 79.0916 89.9543 83.2795C84.6995 86.4863 81.045 92.9061 78.7273 100.387"
                stroke="#344F5B"
                stroke-width="2"
                stroke-linecap="round"
            />
            <path
                opacity="0.3"
                d="M136.205 129.175C135.843 130.15 135.224 131.131 134.599 132.112C134.243 132.823 133.71 133.54 133.084 134.16C132.103 135.319 131.036 136.214 129.699 136.925C116.51 136.833 85.9442 134.699 82.9152 134.252C82.8232 134.16 82.7374 134.074 82.7374 133.982C77.1208 126.14 76.4095 115.268 76.4095 106.095C76.4095 104.667 76.4095 103.238 76.4095 101.907C80.1498 92.7283 85.4108 87.2896 85.4108 87.2896C91.8245 83.1017 106.976 76.9516 112.5 72.9416C113.837 72.0525 118.828 77.9327 123.372 84.0827C125.15 89.3375 128.626 96.6464 128.896 97.1799C130.233 99.4976 131.57 101.723 132.729 103.6C133.887 105.47 134.954 107.07 135.666 108.143C138.161 111.883 139.676 121.063 136.205 129.175Z"
                fill="#5E767C"
            />
            <path
                d="M78.7273 100.387C77.4764 104.667 76.6793 109.302 76.3176 113.931C75.5204 123.466 76.0539 129.438 78.7273 135.674"
                stroke="#344F5B"
                stroke-width="2"
                stroke-linecap="round"
            />
            <path
                d="M116.774 99.3203C117.669 119.996 129.607 118.212 130.944 135.767"
                stroke="#344F5B"
                stroke-width="2"
                stroke-linecap="round"
            />
            <path
                d="M93.6086 96.7388C93.6086 96.7388 86.3917 117.145 93.253 131.756"
                stroke="#344F5B"
                stroke-width="2"
                stroke-linecap="round"
            />
            <path
                d="M123.905 98.6089C129.252 105.826 137.542 112.509 135.31 126.943"
                stroke="#344F5B"
                stroke-width="2"
                stroke-linecap="round"
            />
            <path
                d="M72.1296 134.074C81.045 140.31 91.8244 144.143 103.499 144.59C104.302 144.59 109.116 144.235 109.913 144.143C113.837 143.695 115.259 143.879 116.952 143.609C124.261 142.18 131.122 139.421 137.18 135.675C130.589 132.204 107.779 121.774 107.779 121.774C107.779 121.774 83.001 129.795 72.1296 134.074Z"
                fill="#EAEEEF"
                stroke="#344F5B"
                stroke-width="2"
            />
            <path
                d="M131.655 78.3803C131.655 80.7839 131.57 83.1017 131.478 85.3275C131.3 87.823 131.122 90.2328 130.858 92.6364C130.681 94.3287 130.497 96.113 130.319 97.7133C130.141 99.05 129.963 100.301 129.785 101.546C129.16 105.378 125.953 111.975 121.857 115.268L122.391 125.52L122.66 130.597C122.746 133.007 122.121 135.233 120.962 137.195C119.895 139.065 118.289 141.646 117.044 143.609C112.678 144.59 105.283 144.767 103.499 144.59C99.2192 141.561 95.6567 137.373 93.4309 134.521C91.8244 132.559 91.0212 129.972 91.291 127.39L92.2721 114.471L92.8975 106.984V106.892C91.6466 103.686 90.1321 98.2529 89.6845 96.8242C86.8333 95.8432 83.4486 93.084 82.0261 88.6263C80.8672 84.794 82.9152 80.9617 85.7664 80.4283C88.7096 79.8029 91.1132 81.2315 92.0023 83.813C92.1801 85.0638 92.272 86.5783 92.3579 88.3565L92.8975 88.1787C110.63 78.1105 116.418 65.5469 128.896 62.248C130.233 63.3211 131.655 71.6049 131.655 78.3803Z"
                fill="#EAEEEF"
                stroke="#344F5B"
                stroke-width="2"
            />
            <path
                opacity="0.3"
                d="M122.391 125.52C120.52 126.587 118.289 127.299 116.51 127.746C114.37 128.28 111.967 128.28 109.735 127.568C101.893 125.251 96.0124 119.818 92.2721 114.379L92.8975 106.893L94.2342 109.302C98.1523 113.846 109.735 117.053 117.222 117.053C118.289 117.053 119.362 116.789 120.428 116.164L121.857 115.183L122.391 125.52Z"
                fill="#5E767C"
            />
            <path
                d="M120.343 116.427C119.362 117.053 118.289 117.322 117.136 117.322C109.735 117.322 98.1523 114.116 94.1422 109.566"
                stroke="#344F5B"
                stroke-width="2"
                stroke-linecap="round"
            />
            <path
                d="M109.379 86.8422C108.398 86.8422 107.595 86.0451 107.595 85.064V84.4386C107.595 83.4575 108.398 82.6543 109.379 82.6543C110.36 82.6543 111.164 83.4575 111.164 84.4386V85.064C111.164 86.0451 110.36 86.8422 109.379 86.8422Z"
                fill="#EAEEEF"
                stroke="#344F5B"
                stroke-width="2"
            />
            <path
                d="M125.597 86.8422C124.616 86.8422 123.813 86.0451 123.813 85.064V84.4386C123.813 83.4575 124.616 82.6543 125.597 82.6543C126.578 82.6543 127.382 83.4575 127.382 84.4386V85.064C127.382 86.0451 126.578 86.8422 125.597 86.8422Z"
                fill="#EAEEEF"
                stroke="#344F5B"
                stroke-width="2"
            />
            <path
                d="M78.1938 72.0527C87.2809 70.1826 102.788 63.4991 111.341 54.0503"
                stroke="#344F5B"
                stroke-width="2"
                stroke-linecap="round"
            />
            <path
                d="M78.7273 64.3878C81.5785 61.8922 85.4966 58.2378 89.0652 54.6753"
                stroke="#344F5B"
                stroke-width="2"
                stroke-linecap="round"
            />
            <path
                d="M93.0753 86.6641C93.7866 86.6641 94.5898 86.6641 95.6567 86.4863C102.788 84.9718 108.668 81.1395 114.101 75.0814C116.952 71.8746 122.299 65.5468 125.597 62.4258"
                stroke="#344F5B"
                stroke-width="2"
                stroke-linecap="round"
            />
            <path
                d="M89.8623 87.2042C89.8623 84.7087 86.9253 82.3909 84.8773 84.6167"
                stroke="#344F5B"
                stroke-width="2"
                stroke-linecap="round"
            />
            <path
                d="M118.288 87.3818C118.38 86.8422 118.472 86.5786 118.558 86.3088C118.736 85.7753 119.447 85.7753 119.717 86.3088C120.606 87.8233 122.035 89.6934 122.299 90.141C123.102 91.2999 125.867 93.5257 125.334 94.7765C124.794 96.1991 121.943 97.7136 120.876 97.7136C119.987 97.7136 116.332 97.0023 116.241 95.8434C116.155 94.7765 116.596 93.8875 117.044 92.9922C117.044 93.0842 117.933 89.7854 118.288 87.3818Z"
                fill="#EAEEEF"
                stroke="#344F5B"
                stroke-width="2"
            />
            <path
                d="M113.126 99.3203C114.996 101.908 118.288 102.527 120.962 101.19"
                stroke="#344F5B"
                stroke-width="2"
                stroke-linecap="round"
            />
            <rect
                x="46.7407"
                y="31.9072"
                width="117.63"
                height="124.667"
                rx="4.27778"
                stroke="#344F5B"
                stroke-width="2"
            />
            <path d="M190 36.2407H319" stroke="#344F5B" stroke-width="2" />
            <path d="M277 137.241H319" stroke="#344F5B" stroke-width="2" />
            <path d="M190 45.2407H319" stroke="#344F5B" stroke-width="2" />
            <path d="M277 146.241H319" stroke="#344F5B" stroke-width="2" />
            <path d="M190 54.2407H319" stroke="#344F5B" stroke-width="2" />
            <path d="M277 155.241H319" stroke="#344F5B" stroke-width="2" />
            <circle
                cx="114"
                cy="91"
                r="73"
                stroke="#344F5B"
                stroke-width="10"
            />
            <circle opacity="0.5" cx="114" cy="91" r="68" fill="#D9D9D9" />
            <path
                opacity="0.5"
                d="M119 32C190.117 59.2 170.076 110.667 151.166 133C155.845 112 155.962 62.4 119 32Z"
                fill="white"
            />
            <path
                d="M163.5 148.5L236.5 221.5"
                stroke="#344F5B"
                stroke-width="10"
            />
        </svg>
    )
}
