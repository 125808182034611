import { useMemo, useEffect, useContext, useState, useCallback } from "react"
import {
    Container,
    H2,
    H3,
    P,
    Spacer,
    Caption,
    Button,
} from "@wetradeup/ui-kit"
import {
    useAttemptVerificationMutation,
    useGetAccount,
    AuthContext,
    StudentAccount,
    SCHOOL_PORTAL_URL,
    useSendPhoneVerificationApp,
} from "@wetradeup/student-apps-shared"
import { RouteComponentProps, useHistory } from "react-router-dom"
import { ContentContainer, FullMobileContainer } from "../ModalComponents"
import { ResendPhone } from "./molecules/ResendPhone"
import { EmailLoginModal } from "./molecules/modals/EmailLoginModal"
import { Pointer } from "./atoms/Pointer"
import { ChangePhone } from "./molecules/ChangePhone"
import VerificationCodeInput from "./VerificationCodeInput"
import { formatPhoneNumber } from "./IntroForm/CreateAccount"
import * as Sentry from "@sentry/react"

export default function VerifyPhone({
    match,
}: RouteComponentProps<{ schoolId?: string }>) {
    const { authProfile, authLoaded } = useContext(AuthContext)
    const [textButtonDisabled, sendTextButtonDisabled] = useState(false)
    const [loginSubmitted, setLoginSubmitted] = useState()
    const [validationCode, setValidationCode] = useState("")
    const [showValidationError, setShowValidationError] = useState(false)
    const sendPhoneVerification = useSendPhoneVerificationApp()
    const schoolId = parseInt(match.params.schoolId)
    const history = useHistory()
    const accountQuery = useGetAccount()
    const mutation = useAttemptVerificationMutation()

    useEffect(() => {
        if (authLoaded && !authProfile) {
            history.push("/login")
        }
    }, [authLoaded, authProfile, history])

    const sendPhoneCode = useCallback(
        async (schoolId) => {
            if (!sendPhoneVerification.isLoading) {
                try {
                    await sendPhoneVerification.mutateAsync(schoolId)
                    sendTextButtonDisabled(true)
                } catch (err) {
                    if (err?.error === "Phone number already verified") {
                        return history.push(`/application/${schoolId}/basic`)
                    }

                    const data = accountQuery?.data as StudentAccount
                    Sentry.addBreadcrumb({
                        category: "error",
                        message:
                            "Could not send phone number verification text",
                        data: {
                            account_id: data.account_id,
                            phone: data.phone,
                        },
                    })

                    Sentry.captureException(
                        new Error(
                            "Could not send phone number verification text"
                        )
                    )
                }
            }
        },
        [history, sendPhoneVerification, accountQuery]
    )

    const message = useMemo(() => {
        if ((accountQuery?.data as StudentAccount)?.verified_phone) {
            return <H3 white>Thanks! Your phone number has been verified</H3>
        }
        if (mutation.isLoading) return <H3>Please Wait, verifying you...</H3>
        if (mutation.isSuccess)
            return <H3>Thanks! Your phone number has been verified.</H3>
        if (mutation.isError) {
            return (
                <H3>Oops! Something has gone wrong, please try again later.</H3>
            )
        }
        return (
            <P>
                Click this button to send a text to{" "}
                {formatPhoneNumber(
                    (accountQuery?.data as StudentAccount)?.phone
                )}
            </P>
        )
    }, [
        authProfile?.email,
        accountQuery.data,
        mutation.isSuccess,
        mutation.isError,
    ])

    useEffect(() => {
        const profileData = accountQuery.data as StudentAccount

        if (loginSubmitted && !!authProfile) {
            if (authProfile.role === "school") {
                window.location.href = SCHOOL_PORTAL_URL
            }
        }

        if (mutation.isSuccess || profileData?.verified_phone) {
            history.push(`/application/${schoolId}/basic`)
        }
    }, [
        mutation.isSuccess,
        schoolId,
        accountQuery.data,
        loginSubmitted,
        authProfile,
    ])

    const handleCodeFullyInputted = async (phoneCode) => {
        try {
            await mutation.mutateAsync({ phone_code: phoneCode })
        } catch (err) {
            const data = accountQuery?.data as StudentAccount
            const sentryData = {
                account_id: data.account_id,
                phone: data.phone,
            }

            if (err?.error === "Invalid credentials") {
                return handleShowInvalidCodeError()
            }

            const additionalError = !!err?.error

            Sentry.addBreadcrumb({
                category: "error",
                message: "Error verifying student's phone number",
                data: additionalError
                    ? { ...sentryData, errorMessage: err.error }
                    : sentryData,
            })

            Sentry.captureException(
                new Error("Error verifying student's phone number")
            )
        }
    }

    const handleShowInvalidCodeError = () => {
        setValidationCode("")
        setShowValidationError(true)
        setTimeout(() => setShowValidationError(false), 3000)
    }

    const showLoginModal = authLoaded && !authProfile

    return (
        <FullMobileContainer
            paddingMd
            width={[
                [550, "md"],
                [750, "lg"],
            ]}
            justifyContent="center"
            alignItems="center"
        >
            <Container row alignItems="center" justifyContent="center">
                <Pointer />
            </Container>
            <Spacer />
            <H2 bold="light">Verify your phone number with a code</H2>
            <Spacer size={20} />
            <ContentContainer>
                <>
                    <Container maxWidth={350}>
                        <Spacer />
                        {!sendPhoneVerification.isSuccess && (
                            <>
                                <Button
                                    disabled={textButtonDisabled}
                                    fullWidth
                                    primary
                                    title={
                                        sendPhoneVerification.isSuccess
                                            ? "Sent!"
                                            : "Send me a code"
                                    }
                                    onPress={() => sendPhoneCode(schoolId)}
                                />
                                <Container
                                    flex
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Spacer />
                                    <P>
                                        {" "}
                                        {mutation.isSuccess ||
                                        (accountQuery?.data as StudentAccount)
                                            ?.verified_phone ? (
                                            <P widthsuccess>
                                                Redirecting you to complete your
                                                application...
                                            </P>
                                        ) : (
                                            <P textAlign="center">{message}</P>
                                        )}
                                    </P>
                                </Container>
                            </>
                        )}
                        <Spacer />
                        {sendPhoneVerification.isSuccess && (
                            <Container
                                flex
                                justifyContent="center"
                                alignItems="center"
                            >
                                <P>
                                    Enter the code we sent to{" "}
                                    {formatPhoneNumber(
                                        (accountQuery?.data as StudentAccount)
                                            ?.phone
                                    )}
                                </P>
                                <Spacer />
                                <VerificationCodeInput
                                    value={validationCode}
                                    onChange={setValidationCode}
                                    onComplete={handleCodeFullyInputted}
                                />
                                <Spacer />
                                {showValidationError && (
                                    <P textColor="errorRed" textAlign="center">
                                        The verification code you gave was not
                                        correct. Please try again.
                                    </P>
                                )}
                            </Container>
                        )}
                    </Container>
                    <Spacer />
                    <Caption textAlign="center"> Wrong phone number? </Caption>
                    <ChangePhone schoolId={schoolId} />
                    <Spacer />
                    {sendPhoneVerification.isSuccess && (
                        <>
                            <Caption textAlign="center">
                                {" "}
                                Didn't get a verification text?{" "}
                            </Caption>
                            <ResendPhone schoolId={schoolId} />
                        </>
                    )}
                </>
            </ContentContainer>
            <Spacer lg />
            <EmailLoginModal
                visible={showLoginModal}
                setLoginSubmitted={setLoginSubmitted}
            />
        </FullMobileContainer>
    )
}
