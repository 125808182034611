import { useTheme } from "@emotion/react"
import { Card, Container, Modal, P, Spacer } from "@wetradeup/ui-kit"
import { SubmissionButton } from "../../application/atoms/SubmissionButton"
import { ContentContainer } from "../../ModalComponents"
import { Warning } from "../icons/Warning"

interface BankFrozenModalProps {
    showFrozenModal: boolean
    setShowFrozenModal: React.Dispatch<React.SetStateAction<boolean>>
}

export function BankFrozenModal({
    showFrozenModal,
    setShowFrozenModal,
}: BankFrozenModalProps) {
    const theme = useTheme()
    return (
        <Modal visible={showFrozenModal} width="100%">
            <Container
                width={[
                    [700, "md"],
                    [860, "lg"],
                ]}
            >
                <Card paddingMd>
                    <ContentContainer
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Spacer lg />
                        <Warning />
                        <Spacer lg />
                        <P
                            bold="light"
                            textAlign="center"
                            textColor="black"
                            textSize={theme.sizings.fontSizeMdLg}
                            lineHeight="120%"
                        >
                            Your bank account has triggered a fraud warning. In
                            order to proceed please contact MicroBilt customer
                            service at 888-222-7621
                        </P>
                        <Spacer />
                        <P>
                            Once you remove the fraud flag on your account you
                            may re-link it using the button below.
                        </P>
                        <Spacer size={32} />
                        <SubmissionButton
                            onPress={() => {
                                setShowFrozenModal(false)
                            }}
                            primary
                            title="Re-link account"
                        />
                        <Spacer size={48} />
                    </ContentContainer>
                </Card>
            </Container>
        </Modal>
    )
}
