import { Container, Spacer, Card, P } from "@wetradeup/ui-kit"
import { ApplicationCard, ContentContainer } from "../../ModalComponents"
import { useVerifyBank } from "./useVerifyBank"
import { SpinningLoader } from "../../application/atoms/SpinningLoader"
import { BankVerificationLoadingView } from "./BankVerificationLoadingView"
import styled from "@emotion/styled"
import { SubmissionButton } from "../../application/atoms/SubmissionButton"
import { usePlaidLink } from "react-plaid-link"
import {
    useGetStudentProfile,
    useStudentApplicationDetails,
} from "@wetradeup/student-apps-shared"
import { BankVerificationFailedModal } from "./BankVerificationFailedModal"
import { BankInternalErrorModal } from "./BankInternalErrorModal"
import { BankRejectedModal } from "./BankRejectedModal"
import { BankFrozenModal } from "./BankFrozenModal"
import { UnderlineItalicText } from "../../atoms/UnderlineText"
import { useTheme } from "@emotion/react"
import { PendingManualMicroDeposit } from "../icons/PendingManualMicroDeposit"

const CustomContentContainer = styled(ContentContainer)`
    @media (min-width: 1050px) {
        padding-left: 80px;
        padding-right: 80px;
    }
`

interface PendingManualBankVerificationProps {
    isLoading: boolean
    open: Function
    ready: boolean
    error: string
    is_ready_for_micro_deposit_verification?: boolean
}

function PendingManualBankVerification({
    isLoading,
    open,
    ready,
    error,
    is_ready_for_micro_deposit_verification,
}: PendingManualBankVerificationProps) {
    const theme = useTheme()
    return (
        <>
            <ApplicationCard
                alignItems="center"
                justifyContent="center"
                disableCard
                border
                paddingLg
                width="100%"
            >
                <Card backgroundColor="lighterBlue" paddingMd disableShadow>
                    <Container padding={["sm", "lg"]}>
                        <P
                            textColor="black"
                            textSize={theme.sizings.fontSizeLg}
                            lineHeight={"120%"}
                        >
                            You’re{" "}
                            <UnderlineItalicText
                                bold
                                textColor="black"
                                textSize={theme.sizings.fontSizeLg}
                            >
                                Almost
                            </UnderlineItalicText>{" "}
                            Done, Just One More Step:
                        </P>
                    </Container>
                </Card>
                <CustomContentContainer
                    justifyContent="center"
                    alignItems="center"
                >
                    <Spacer lg />
                    {isLoading && <SpinningLoader />}
                    {!isLoading && (
                        <>
                            {!is_ready_for_micro_deposit_verification && (
                                <>
                                    <PendingManualMicroDeposit />
                                    <Spacer lg />
                                    <P textAlign="center">
                                        In the next{" "}
                                        <P bold>1 - 2 business days</P>, you
                                        will receive <P bold>a small deposit</P>{" "}
                                        in your bank account. We'll contact you
                                        when it arrives. When you see the
                                        deposit, come back here and we'll walk
                                        you through completing your
                                        verification.
                                    </P>
                                    <Spacer />
                                </>
                            )}
                            {is_ready_for_micro_deposit_verification && (
                                <>
                                    <P textAlign="center">
                                        a small deposit, less than a dollar,
                                        have arrived in your account. When
                                        you've located the deposit, click the
                                        button below!
                                    </P>
                                </>
                            )}

                            {is_ready_for_micro_deposit_verification && (
                                <>
                                    <Spacer lg />
                                    <Container maxWidth="320px" width="100%">
                                        <SubmissionButton
                                            fullWidth
                                            disabled={!ready}
                                            width="100%"
                                            onPress={() => open()}
                                            title="Enter micro deposit amounts"
                                        />
                                    </Container>
                                </>
                            )}
                            {error && (
                                <>
                                    <Spacer size={12} />
                                    <P error textAlign="center">
                                        {error}
                                    </P>
                                </>
                            )}
                        </>
                    )}
                </CustomContentContainer>
            </ApplicationCard>
        </>
    )
}

interface PendingManualProps {
    studentProfile: ReturnType<typeof useGetStudentProfile>
    studentApplicationDetails: ReturnType<typeof useStudentApplicationDetails>
}

export default function PendingManual({
    studentProfile,
    studentApplicationDetails,
}: PendingManualProps) {
    const {
        isUpdatingPlaid,
        error,
        onSuccess,
        onExit,
        onEvent,
        linkTokenQuery,
        showBankVerificationFailedModal,
        setShowBankVerificationModal,
        showInternalErrorModal,
        setShowInternalErrorModal,
        remainingAttempts,
        showRejectedModal,
        showFrozenModal,
        setShowFrozenModal,
    } = useVerifyBank({
        studentProfileLoaded: studentProfile.isSuccess,
        studentApplicationDetailsLoaded: studentApplicationDetails.isSuccess,
        bankVerificationIdentity:
            studentProfile.data?.bank_identity_verification,
        bankVerificationIdentityAttempts:
            studentApplicationDetails.data?.bank_identity_verification_attempts,
        plaidStatus: studentProfile.data?.plaid_status,
        bankIdentityFrozen: studentProfile.data?.bank_identity_frozen,
    })

    const { open, ready } = usePlaidLink({
        token: linkTokenQuery?.data?.link_token || null,
        onSuccess,
        onExit,
        onEvent,
    })

    const isLoading = linkTokenQuery.isLoading || !linkTokenQuery.isSuccess
    return (
        <>
            <Container
                paddingMd
                width={[
                    [550, "sm"],
                    [550, "md"],
                    [750, "lg"],
                ]}
            >
                {isUpdatingPlaid ? (
                    <BankVerificationLoadingView />
                ) : (
                    <PendingManualBankVerification
                        isLoading={isLoading}
                        ready={ready}
                        open={open}
                        error={error}
                        is_ready_for_micro_deposit_verification={
                            studentProfile.data
                                ?.is_ready_for_micro_deposit_verification
                        }
                    />
                )}
            </Container>
            <BankVerificationFailedModal
                showBankVerificationFailed={showBankVerificationFailedModal}
                setShowBankVerificationFailed={setShowBankVerificationModal}
                remainingAttempts={remainingAttempts}
            />
            <BankInternalErrorModal
                showInternalError={showInternalErrorModal}
                setShowInternalError={setShowInternalErrorModal}
            />
            <BankRejectedModal showRejectedModal={showRejectedModal} />
            <BankFrozenModal
                showFrozenModal={showFrozenModal}
                setShowFrozenModal={setShowFrozenModal}
            />
        </>
    )
}
