import {
    Questionnaire,
    QuestionnaireAnswer,
} from "@wetradeup/student-apps-shared"
import { useState, useEffect, useCallback } from "react"
import { WrappedFormik } from "../../../../utils"

export function useEnterInitialValues(
    questionnaire: Questionnaire[],
    answers: QuestionnaireAnswer,
    setQuestionnaire: React.Dispatch<React.SetStateAction<Questionnaire[]>>,
    setFieldValue: ReturnType<
        WrappedFormik<QuestionnaireAnswer>["formik"]
    >["setFieldValue"],
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setReady: React.Dispatch<React.SetStateAction<boolean>>
) {
    const [enteredInitial, setEnteredInitial] = useState(false)

    const enterInitialValues = useCallback(
        async (answerEntries, questionnaire) => {
            setEnteredInitial(true)
            setLoading(true)
            let show = []
            let hide = []
            for (const answer of answerEntries) {
                const [key, val] = answer
                const question = questionnaire.find((q) => q.id === key)
                if (question) {
                    // prioritize question
                    if (val.final_order) {
                        const processed_values = val.final_order.map((v) => {
                            const option = question.options.find(
                                (o) => o.value === v.value
                            )
                            return {
                                show: option.show,
                                hide: option.hide,
                                ...v,
                            }
                        })
                        const value = {
                            initial_order: processed_values,
                            final_order: processed_values,
                            time_spent: val.time_spent,
                        }
                        await setFieldValue(key, value)
                    }
                    // multiple radio question
                    else if (val.answers) {
                        const options = []
                        for (const answer of val.answers) {
                            const option = question.options.find(
                                (o) => o.value === answer.answer
                            )
                            options.push(option)
                            if (option.show) show = [...show, ...option.show]
                            if (option.hide) hide = [...hide, ...option.hide]
                        }
                        await setFieldValue(key, options)
                    }
                    // radio question
                    else if (val.label) {
                        const option = question.options.find(
                            (o) => o.value === val.answer
                        )
                        if (val.answer === "freeform") {
                            option.text = val.text
                        }
                        if (option.show) show = [...show, ...option.show]
                        if (option.hide) hide = [...hide, ...option.hide]
                        await setFieldValue(key, option)
                    }
                }
            }
            const newQuestionnaire = JSON.parse(
                JSON.stringify(questionnaire)
            ).map((q) => {
                if (show.includes(q["id"])) {
                    q["hidden"] = false
                } else if (hide.includes(q["id"])) {
                    q["hidden"] = true
                }
                return q
            })
            setQuestionnaire(newQuestionnaire)
            setLoading(false)
            setReady(true)
        },
        [setFieldValue, setQuestionnaire, setLoading]
    )

    useEffect(() => {
        ;(async () => {
            const answerEntries = Object.entries(answers)
            if (answerEntries.length > 0 && questionnaire && !enteredInitial) {
                await enterInitialValues(answerEntries, questionnaire)
            }
        })()
    }, [questionnaire, answers, enterInitialValues])
}
