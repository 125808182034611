import { Spacer, HR, H2, H4, Container, P } from "@wetradeup/ui-kit"
import { ContentContainer } from "../../../ModalComponents"
import { LogoutLabel } from "../../molecules/LogoutLabel"
import { ApplicationStatusContainer } from "./ApplicationSelection"
import { ApplicationButton } from "../../../atoms/ApplicationButton"

interface SignedAndOnboardedProps {
    firstName: String
}

const VERVENT_URL = "http://studentloans.acct-admin.com/"

export function SignedAndOnboarded({ firstName }: SignedAndOnboardedProps) {
    return (
        <>
            <Spacer lg />
            <ContentContainer>
                <>
                    <H2 bold="light">
                        Hi{", "}
                        {firstName}
                        {"!"}
                    </H2>
                    <Spacer lg />
                    <H4 textAlign="left" bold="light">
                        You’ve already been approved for a Fynn Loan.
                    </H4>
                    <Spacer lg />
                    <H4 textAlign="left" bold="light">
                        View your loan details
                    </H4>
                    <Spacer lg />
                    <ApplicationStatusContainer paddingMd>
                        <Container row>
                            <P>
                                To view your loan details please either signup
                                or sign into your payment portal{" "}
                            </P>
                        </Container>
                        <Spacer lg />
                        <Container
                            flex
                            row
                            alignItems="center"
                            justifyContent="center"
                        >
                            <ApplicationButton
                                primary
                                onPress={() => window.open(VERVENT_URL)}
                                title="Go To Payment Portal"
                            />
                        </Container>
                    </ApplicationStatusContainer>
                    <HR />
                    <Spacer lg />
                    <LogoutLabel />
                    <Spacer />
                </>
            </ContentContainer>
            <Spacer lg />
        </>
    )
}
