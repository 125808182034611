export function CheckMarkCircle() {
    return (
        <svg
            width="25"
            height="25"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="10.1056" cy="10.3307" r="10" fill="#2EC63C" />
            <path
                d="M14.0745 7.56421L8.67637 13.0971L6.13665 10.5574"
                stroke="white"
                stroke-width="2"
                stroke-linecap="square"
                stroke-linejoin="round"
            />
        </svg>
    )
}
