export function GreenCheck() {
    return (
        <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="10" height="10" rx="5" fill="#2EC63C" />
            <path
                d="M2.92578 4.95088L4.30828 6.70181L7.07328 3.19995"
                stroke="white"
                stroke-linecap="square"
                stroke-linejoin="round"
            />
        </svg>
    )
}
