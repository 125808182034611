import React, { useContext, useEffect } from "react"
import styled from "@emotion/styled"
import queryString from "querystring"
import { Container } from "@wetradeup/ui-kit"
import { Redirect, Route, Switch } from "react-router-dom"
import ApplicationFormWrapper from "./application/ApplicationFormWrapper"
import Paused from "./deadends/Paused"
import Soon from "./deadends/Soon"
import NotFound from "./deadends/NotFound"
import ServerError from "./deadends/ServerError"
import { NavBar } from "./molecules/NavBar/NavBar"
import { breakpoints } from "../constants"
import { AuthRoute } from "./AuthRoute"
import { Login } from "./Login"
import { ResetPassword } from "./ResetPassword"
import { IslFinalizeWrapper } from "./isl-finalize/IslFinalizeWrapper"
import { useFynnLocation } from "./hooks/useFynnLocation"
import { useBackgroundColor } from "./hooks/useBackgroundColor"
import {
    AuthContext,
    useLogout,
    useRecordMessageClick,
} from "@wetradeup/student-apps-shared"
import { ApprovedModalProvider } from "./contexts/ApprovedModalProvider"
import { CookieModal } from "./atoms/CookieModal"
import ExitCounselingWrapper from "./exit-counseling/ExitCounselingWrapper"
import { StripeProvider } from "./contexts/StripeProvider"
import { BankVerificationProvider } from "./contexts/BankVerificationProvider"

const LandingContainer = styled(Container)`
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-color: ${(props) =>
        props.theme.colors.palette[props.backgroundColor]};

    min-height: 100vh;
`

export const CardContainer = styled(Container)`
    @media (max-width: ${breakpoints.desktopSm}) {
        width: 100%;
    }

    @media (min-width: ${breakpoints.desktopSm}) {
        width: 600px;
    }

    @media (min-width: ${breakpoints.desktopMd}) {
        flex: 1;
        width: 1200px;
        max-height: 600px;
        flex-direction: row;
    }
`

export function Routes() {
    const location = useFynnLocation()
    useEffect(() => {
        // @ts-ignore
        window.gtag("event", "page_view", {
            page_title: location.pathname,
            page_location: location.pathname,
            // @ts-ignore
            send_to: window.googleMeasurementId,
        })
    }, [location.pathname])

    const backgroundColor = useBackgroundColor()
    const { authProfile, showCookieModal, setShowCookieModal } =
        useContext(AuthContext)
    const logout = useLogout()
    const recordMessageClick = useRecordMessageClick()

    useEffect(() => {
        ;(async () => {
            const searchParams = queryString.parse(
                location.search.replace("?", "")
            )
            if (searchParams && searchParams.t) {
                try {
                    await recordMessageClick.mutateAsync(
                        searchParams.t as string
                    )
                } catch (err) {
                    console.log(err)
                }
            }
        })()
    }, [])

    useEffect(() => {
        if (authProfile?.role === "school") {
            logout()
        }
    }, [authProfile])

    return (
        <>
            <NavBar />
            <LandingContainer
                data-component-type="landing-container"
                backgroundColor={backgroundColor}
            >
                <StripeProvider>
                    <BankVerificationProvider>
                        <ApprovedModalProvider>
                            <Switch>
                                <Route path="/login" component={Login} />
                                <Route
                                    path="/application"
                                    component={ApplicationFormWrapper}
                                />
                                <AuthRoute
                                    path="/isl-finalize"
                                    component={IslFinalizeWrapper}
                                />
                                <Route
                                    path={"/loan-orientation"}
                                    component={ExitCounselingWrapper}
                                />
                                <Route
                                    path="/reset-password"
                                    component={ResetPassword}
                                />
                                <Route
                                    path="/enrollment-paused"
                                    component={Paused}
                                />
                                <Route path="/coming-soon" component={Soon} />
                                <Route path="/not-found" component={NotFound} />
                                <Route
                                    path="/server-error"
                                    component={ServerError}
                                />

                                <Redirect from="/" to="/application" exact />
                                <Redirect to="/not-found" />
                            </Switch>
                            <CookieModal
                                showCookieModal={showCookieModal}
                                setShowCookieModal={setShowCookieModal}
                            />
                        </ApprovedModalProvider>
                    </BankVerificationProvider>
                </StripeProvider>
            </LandingContainer>
        </>
    )
}
