import styled from "@emotion/styled"
import {
    Container,
    P,
    ErrorContainer,
    ErrorMessage,
    Label,
    Spacer,
    TouchableContainer,
} from "@wetradeup/ui-kit"
import React, { useEffect, useRef, useState } from "react"
import { DatePickerBase } from "./DatePickerBase"

const PlaceholderP = styled(P)`
    opacity: 0.7;
`

const StyledTouchableContainer = styled(TouchableContainer)`
    &:hover {
        cursor: text;
    }
`
export const DatePickerContainer = styled(Container)<{
    focused: boolean
    touched: boolean
    showError: boolean
    alignItems?: string
    flexDirection?: string
}>`
    background: ${(props) => props.theme.components.input.backgroundColor};
    padding: ${(props) => props.theme.sizings.paddingSm}
        ${(props) => props.theme.sizings.paddingMd};
    border: ${(props) => props.theme.components.input.border};
    border-radius: ${(props) => props.theme.sizings.borderRadiusSm};
    align-self: stretch;
    display: flex;
    flex-direction: ${(props) => props.flexDirection || "row"};
    justify-content: space-between;
    align-items: ${(props) => props?.alignItems || "center"};
    & div {
        border: none;
    }

    & > p {
        color: ${(props) => props.theme.colors.palette.darkGrey};
    }

    flex: 1;

    ${(props) =>
        props.focused &&
        `
        border: 1px solid ${props.theme.colors.palette.green};
        background: ${props.theme.colors.palette.lightGreen};
    `}

    ${(props) =>
        props.touched &&
        !props.showError &&
        `
        border: 1px solid ${props.theme.colors.palette.green} !important;
    `}

    ${(props) =>
        props.showError &&
        `
        border: 1px solid ${props.theme.colors.palette.errorRed};
    `};
`

export const DatePicker = (props: any) => {
    const [focused, setFocused] = useState(false)
    const [touched, setTouched] = useState(false)
    const [show, setShow] = useState(false)
    const shouldHideRef = useRef(false)
    const showError =
        ((touched && !focused) || props.alwaysShowError) && !!props.error

    const shouldHide = props?.placeholder && !props.value && !focused

    useEffect(() => {
        if (!show && !shouldHide) {
            setShow(true)
        }
        shouldHideRef.current = shouldHide
    }, [shouldHide])

    return (
        <Container
            flex={1}
            minHeight={58}
            {...(props?.tabIndex
                ? { onFocus: () => setFocused(true), tabIndex: props?.tabIndex }
                : {})}
        >
            <DatePickerContainer
                focused={focused}
                touched={touched}
                error={props.error}
                showError={showError}
                alignItems={props?.alignItems}
                flexDirection={props?.flexDirection}
            >
                {props?.label && (
                    <>
                        <Container row>
                            <Label textColor="darkGrey">{props?.label}</Label>
                            {props?.showRequired && (
                                <P bold textColor="errorRed">
                                    *
                                </P>
                            )}
                        </Container>
                        <Spacer size={8} />
                    </>
                )}
                {!props.noLabel && (
                    <Container flex={1}>
                        <P>Date of birth</P>
                    </Container>
                )}
                {shouldHide && (
                    <StyledTouchableContainer
                        flex={1}
                        width="100%"
                        justifyContent="center"
                        alignItems="flex-start"
                        onPress={() => setFocused(true)}
                    >
                        <PlaceholderP textColor="bodyGrey">
                            {props?.placeholder}
                        </PlaceholderP>
                    </StyledTouchableContainer>
                )}
                {show && (
                    <DatePickerBase
                        {...props}
                        onChange={(evt) => {
                            setTouched(true)
                            props.onChange(evt)
                        }}
                        onFocus={() => setFocused(true)}
                        onBlur={() => {
                            if (!props?.disableBlur) {
                                setFocused(false)
                            }
                        }}
                        shouldHide={shouldHide}
                    />
                )}
            </DatePickerContainer>
            {showError && (
                <ErrorContainer>
                    <ErrorMessage>{props.error}</ErrorMessage>
                </ErrorContainer>
            )}
        </Container>
    )
}
