import { ApplicationCard, CardContainer, InnerModal } from "../ModalComponents"
import { IslFinalizeRoutes } from "./IslFinalizeRoutes"
import { useIslFinalizeWrapperRouting } from "./hooks/useIslFinalizeWrapperRouting"
import { FinanceDisclaimer } from "./molecules/FinanceDisclaimer"
import { useFynnLocation } from "../hooks/useFynnLocation"
import { useContext } from "react"
import { ApprovedModalContext } from "../contexts/ApprovedModalProvider"
import { FinalizeHeaderRoutes } from "./FinalizeHeaderRoutes"

export const modalViewPages = [
    "/isl-finalize/loan-term",
    "/isl-finalize/verification-failed",
    "/isl-finalize/verification",
    "/isl-finalize/financing-changed",
    "/isl-finalize/complete",
]

export function IslFinalizeWrapper() {
    const { studentApplicationDetails, studentProfile } =
        useIslFinalizeWrapperRouting()

    const location = useFynnLocation()
    const parts = location.pathname.split("/")
    const page = parts.slice(-1)[0]
    const showFinanceDisclaimer = page === "finance"
    const showCard = modalViewPages.includes(location.pathname)

    const { isApprovedModalOpen } = useContext(ApprovedModalContext)

    return (
        <InnerModal
            flex={1}
            alignStretch
            noScroll={page === "loan-terms" && isApprovedModalOpen}
        >
            <CardContainer>
                <FinalizeHeaderRoutes />
                <ApplicationCard
                    alignStretch
                    minWidth="100%"
                    alignItems="center"
                    disablePadding
                    disableCard={!showCard}
                >
                    <IslFinalizeRoutes
                        studentApplicationDetails={studentApplicationDetails}
                        studentProfile={studentProfile}
                    />
                </ApplicationCard>
                {showFinanceDisclaimer && <FinanceDisclaimer />}
            </CardContainer>
        </InnerModal>
    )
}
