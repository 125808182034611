import {
    Card,
    Container,
    ErrorContainer,
    Spacer,
    ErrorMessage,
} from "@wetradeup/ui-kit"
import { useStudentApplicationDetails } from "@wetradeup/student-apps-shared"
import { SubmissionButton } from "../../application/atoms/SubmissionButton"
import { SpinningLoader } from "../../application/atoms/SpinningLoader"
import { useGetLoanPaymentTerms } from "../hooks/useGetLoanPaymentTerms"
import {
    InSchoolPaymentSection,
    LoanTermSection,
    StandardPaymentSection,
    StraightHR,
} from "./LoanPaymentTermComponents"
import { useWindowSize } from "../../hooks/useWindowSize"
import { useTheme } from "@emotion/react"
import styled from "@emotion/styled"
import { useContext, useEffect } from "react"
import { useState } from "react"
import { ApprovedModalContext } from "../../contexts/ApprovedModalProvider"

interface LoanPaymentContainerProps {
    studentApplicationDetails: ReturnType<typeof useStudentApplicationDetails>
    handleConfirmPress: () => Promise<void>
    isLoading?: boolean
    disabled?: boolean
    confirmLoanTermError: string
    noSticky: boolean
    inSchoolPaymentAmount?: number
}

const MobilePaymentTermContainer = styled(Container)`
    ${(props) => `
        height: 140px;
        border-top: 1px solid ${props.theme.colors.palette.medGrey};
        ${
            props.stickyFooter &&
            `
            position: fixed;
            bottom: 0px;
            left: 0px;
            z-index: 1100;
            box-shadow: 0px -3px 15px rgb(0 0 0 / 25%);
        `
        }
    `}
`

const MobileCard = styled(Container)`
    ${(props) => `
        ${
            props.stickyFooter
                ? `
            margin-bottom: 140px;
        `
                : `
            margin-bottom: 0px;
        `
        }

        border-radius: 5px;
    `}
`

export const LoanPaymentContainer = ({
    studentApplicationDetails,
    isLoading,
    disabled,
    handleConfirmPress,
    confirmLoanTermError,
    noSticky,
    inSchoolPaymentAmount,
}: LoanPaymentContainerProps) => {
    const terms = useGetLoanPaymentTerms(studentApplicationDetails)
    const [stickyFooter, setStickyFooter] = useState(true)

    const { windowSize } = useWindowSize()
    const theme = useTheme()
    const { isApprovedModalOpen } = useContext(ApprovedModalContext)

    const getPosition = () => {
        if (
            document.body.scrollHeight -
                window.pageYOffset -
                window.innerHeight <
            200
        ) {
            setStickyFooter(false)
        } else {
            setStickyFooter(true)
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", getPosition)
        return () => window.removeEventListener("scroll", getPosition)
    }, [])

    const isDeskTop = windowSize?.width > theme.breakpoints.md

    return (
        <Container flex={2} column>
            {isDeskTop ? (
                <Card
                    disableShadow
                    border="medGrey"
                    disablePadding
                    alignItems="center"
                    justifyContent="center"
                    column
                    height="fit-content"
                >
                    <InSchoolPaymentSection terms={terms} isDeskTop />
                    <StandardPaymentSection terms={terms} isDeskTop />
                    <StraightHR />
                    <LoanTermSection terms={terms} isDeskTop />
                </Card>
            ) : (
                <MobileCard
                    disableShadow
                    border="medGrey"
                    disablePadding
                    alignItems="center"
                    justifyContent="center"
                    column
                    height="fit-content"
                    stickyFooter={!noSticky && stickyFooter}
                >
                    <LoanTermSection terms={terms} />
                    <MobilePaymentTermContainer
                        row
                        width="100%"
                        stickyFooter={
                            !noSticky && stickyFooter && !isApprovedModalOpen
                        }
                    >
                        <InSchoolPaymentSection
                            terms={terms}
                            flex={1}
                            stickyFooter={!noSticky && stickyFooter}
                        />
                        <StandardPaymentSection
                            terms={terms}
                            flex={1}
                            stickyFooter={!noSticky && stickyFooter}
                        />
                    </MobilePaymentTermContainer>
                </MobileCard>
            )}
            <Spacer />
            <Container alignItems="center">
                {isLoading ? (
                    <SpinningLoader />
                ) : (
                    <SubmissionButton
                        width="100%"
                        type="submit"
                        primary
                        disabled={disabled}
                        onPress={handleConfirmPress}
                        title="Submit"
                    />
                )}
            </Container>
            {confirmLoanTermError && (
                <ErrorContainer>
                    <ErrorMessage>{confirmLoanTermError}</ErrorMessage>
                </ErrorContainer>
            )}
        </Container>
    )
}
