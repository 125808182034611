import { useTheme } from "@emotion/react"
import { useHistory, useRouteMatch } from "react-router-dom"
import { Card, Container, P, Spacer } from "@wetradeup/ui-kit"
import styled from "@emotion/styled"
import { ApplicationButton } from "../../atoms/ApplicationButton"
import { DefaultPage } from "../molecules/DefaultPage"
import { BlueCircle } from "../icons/BlueCircle"
import { DottedLine } from "./ExitCounselingRepayment"
import { YellowWarning } from "../icons/YellowWarning"
import { RedWarning } from "../icons/RedWarning"

const SolidLine = styled.div<{ extraHeight: number; color: string }>`
    ${(props) => `
        border-left: 3px solid ${props.color};
        height: 100%;
        width: 0px;
    `}
`
export function ExitCounselingMissedPayment() {
    const theme = useTheme()
    const history = useHistory()
    const match = useRouteMatch<{ accountId?: string }>({
        path: "/loan-orientation/admin/:accountId/missed-payment",
    })
    return (
        <DefaultPage>
            <Card border="medGrey" disableShadow width="100%" paddingLg>
                <P
                    textAlign="left"
                    textSize={theme.sizings.fontSizeInc2}
                    bold
                    textColor="black"
                >
                    What happens if I miss a payment, or if my payment is late?
                </P>
                <Spacer lg />
                <P textColor="black">
                    Missed and late payments can result in late fees and
                    additional finance charges, which make your loan more
                    expensive over time. They can also result in negative
                    impacts to your credit report. Here is how it works:
                </P>
                <Spacer lg />
                <Container width="100%">
                    <Container row width="100%">
                        <Container alignItems="center" width="25px">
                            <Spacer />
                            <Container>
                                <BlueCircle />
                            </Container>
                            <Container height="100%">
                                <DottedLine />
                            </Container>
                        </Container>
                        <Spacer />
                        <Container flex={1}>
                            <Card
                                id="repayment_first_section"
                                backgroundColor="medLightGrey"
                                border="medGrey"
                                disableShadow
                                paddingMd
                            >
                                <P textColor="black">
                                    <P bold textColor="black">
                                        Day 1 - 15:
                                    </P>{" "}
                                    You can make up your missed payment without
                                    penalty.
                                </P>
                            </Card>
                        </Container>
                    </Container>
                    <Spacer />
                    <Container row width="100%">
                        <Container alignItems="center" width="25px">
                            <Spacer />
                            <Container zIndex={200}>
                                <YellowWarning />
                            </Container>
                            <Container height="100%">
                                <SolidLine extraHeight={32} color="#EEAF0C" />
                            </Container>
                        </Container>
                        <Spacer />
                        <Container flex={1}>
                            <Card
                                id="repayment_first_section"
                                backgroundColor="medLightGrey"
                                border="medGrey"
                                disableShadow
                                paddingMd
                            >
                                <P textColor="black">
                                    <P bold textColor="black">
                                        Day 16 - 30:
                                    </P>{" "}
                                    15 days after a missed payment, Fynn will
                                    start to charge fees and penalties.
                                </P>
                                <Spacer />
                                <P
                                    textColor="black"
                                    textSize={theme.sizings.fontSizeXs}
                                    lineHeight={"120%"}
                                >
                                    We may charge a late fee of up to $25
                                    dollars, and may charge additional fees for
                                    bounced or returned payments.
                                </P>
                            </Card>
                        </Container>
                    </Container>
                    <Spacer />
                    <Container row width="100%">
                        <Container alignItems="center" width="25px">
                            <Spacer />
                            <Container zIndex={200}>
                                <YellowWarning />
                            </Container>
                            <Container height="100%">
                                <SolidLine extraHeight={36} color="#FF0B04" />
                            </Container>
                        </Container>
                        <Spacer />
                        <Container flex={1}>
                            <Card
                                id="repayment_first_section"
                                backgroundColor="medLightGrey"
                                border="medGrey"
                                disableShadow
                                paddingMd
                            >
                                <P textColor="black">
                                    <P bold textColor="black">
                                        Day 30 - 120:
                                    </P>{" "}
                                    After 30 days, your missed payment may begin
                                    to impact your credit.
                                </P>
                                <Spacer />
                                <P
                                    textColor="black"
                                    textSize={theme.sizings.fontSizeXs}
                                    lineHeight={"120%"}
                                >
                                    We may report any payment more than 30 days
                                    past due. Missed or late payments on your
                                    credit report can negatively impact your
                                    credit, making it more difficult to get
                                    other loans, open new credit cards, get a
                                    mortgage, and even rent an apartment.
                                </P>
                            </Card>
                        </Container>
                    </Container>
                    <Spacer />
                    <Container row width="100%">
                        <Container alignItems="center" width="25px">
                            <Spacer />
                            <RedWarning />
                        </Container>
                        <Spacer />
                        <Container flex={1}>
                            <Card
                                id="repayment_first_section"
                                backgroundColor="medLightGrey"
                                border="medGrey"
                                disableShadow
                                paddingMd
                            >
                                <P textColor="black">
                                    <P bold textColor="black">
                                        Day 120+:
                                    </P>{" "}
                                    After 120 days, your loan is considered in
                                    default, and your account may be sent to a
                                    collections agency.
                                </P>
                                <Spacer />
                                <P
                                    textColor="black"
                                    textSize={theme.sizings.fontSizeXs}
                                    lineHeight={"120%"}
                                >
                                    Collections agencies will charge additional
                                    fees while collecting payments. If you're in
                                    default, you will not qualify for the many
                                    benefits available on Fynn loans, which are
                                    specifically designed to help you avoid
                                    these consequences.
                                </P>
                            </Card>
                        </Container>
                    </Container>
                </Container>
            </Card>

            <Spacer lg />
            <Container width={300}>
                <ApplicationButton
                    fullWidth
                    alignCenter
                    title="Continue"
                    buttonColor="newBlue"
                    onPress={() => {
                        if (match?.params?.accountId) {
                            history.push(
                                `/loan-orientation/admin/${match?.params?.accountId}/member-benefits`
                            )
                        } else {
                            history.push("/loan-orientation/member-benefits")
                        }
                    }}
                />
            </Container>
        </DefaultPage>
    )
}
