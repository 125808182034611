export function ThreeBlueDollars() {
    return (
        <svg
            width="251"
            height="133"
            viewBox="0 0 251 133"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M64.2543 119.174L61.8607 111.931C58.9386 112.845 56.1695 113.474 53.5534 113.819C50.953 114.132 48.6342 114.197 46.5971 114.012L44.1221 106.523C46.2059 106.77 48.6844 106.796 51.5578 106.6C54.4312 106.405 57.1256 105.943 59.6413 105.216L55.2838 92.0309C51.5055 92.0056 48.3445 91.7243 45.8009 91.1869C43.2807 90.6418 41.2573 89.7115 39.7305 88.396C38.1961 87.0572 37.0454 85.2274 36.2785 82.9069C35.4883 80.516 35.4082 78.2675 36.0381 76.1613C36.6838 74.0239 37.9183 72.0949 39.7417 70.3743C41.5885 68.646 43.9107 67.2155 46.7084 66.0829L44.8144 60.3518L49.3852 58.8413L51.2444 64.4669C53.8694 63.6773 56.3689 63.1763 58.7426 62.9638C61.1321 62.7201 63.4819 62.7105 65.7921 62.935L65.3868 70.323C63.3964 70.2008 61.3783 70.2308 59.3324 70.4129C57.2866 70.595 55.3459 70.8984 53.5102 71.323L57.7631 84.1916C60.9326 84.1841 63.8089 84.3515 66.3921 84.6938C68.9986 85.0284 71.2059 85.8069 73.014 87.0294C74.8377 88.2207 76.1833 90.129 77.0509 92.7543C78.1897 96.1999 77.8651 99.4662 76.0773 102.553C74.2817 105.616 71.0432 108.169 66.3617 110.21L68.8251 117.664L64.2543 119.174ZM64.1191 103.424C66.2764 102.399 67.7587 101.259 68.566 100.004C69.3655 98.7262 69.5252 97.3604 69.0449 95.9071C68.7195 94.9227 68.2101 94.168 67.5167 93.6432C66.8467 93.1106 65.9303 92.7374 64.7677 92.5237C63.6285 92.3022 62.1767 92.1969 60.4124 92.208L64.1191 103.424ZM52.6229 83.9793L48.9859 72.9743C47.6729 73.5642 46.6173 74.2251 45.8191 74.9568C45.021 75.6886 44.4998 76.4718 44.2556 77.3065C44.0115 78.1412 44.0444 79.0274 44.3542 79.965C44.6951 80.9963 45.1965 81.8056 45.8586 82.3928C46.5206 82.98 47.3978 83.3921 48.4902 83.6291C49.6059 83.8584 50.9835 83.9751 52.6229 83.9793Z"
                fill="#2E54E8"
            />
            <path
                d="M123.455 108.733L124.087 101.131C121.04 100.828 118.246 100.324 115.704 99.617C113.188 98.8877 111.029 98.0394 109.227 97.0722L109.88 89.2119C111.701 90.2549 113.971 91.2487 116.692 92.1932C119.413 93.1377 122.073 93.7675 124.673 94.0826L125.823 80.244C122.356 78.742 119.557 77.2461 117.426 75.7561C115.321 74.2682 113.823 72.6203 112.932 70.8123C112.044 68.9796 111.701 66.8455 111.904 64.4099C112.112 61.9005 112.919 59.8 114.322 58.1083C115.753 56.3941 117.644 55.102 119.995 54.2322C122.371 53.3645 125.068 52.9569 128.086 53.0095L128.586 46.9943L133.383 47.393L132.892 53.2975C135.617 53.5982 138.113 54.1153 140.381 54.8487C142.675 55.5596 144.841 56.4704 146.879 57.5811L143.615 64.2212C141.831 63.3298 139.962 62.5675 138.009 61.9345C136.055 61.3015 134.15 60.8212 132.294 60.4936L131.172 74.0001C134.092 75.2336 136.673 76.5133 138.916 77.8392C141.184 79.1672 142.911 80.7474 144.096 82.58C145.308 84.3899 145.8 86.6727 145.571 89.4281C145.27 93.0446 143.693 95.9233 140.84 98.0643C137.989 100.181 134.01 101.262 128.903 101.308L128.253 109.132L123.455 108.733ZM129.495 94.1861C131.881 94.0871 133.691 93.6182 134.925 92.7794C136.161 91.916 136.843 90.7217 136.969 89.1964C137.055 88.1631 136.882 87.2693 136.449 86.5149C136.041 85.7626 135.344 85.0606 134.357 84.4089C133.396 83.7592 132.101 83.0942 130.473 82.414L129.495 94.1861ZM126.525 71.7932L127.485 60.2425C126.046 60.2715 124.816 60.4666 123.795 60.8277C122.774 61.1887 121.988 61.7055 121.436 62.3781C120.885 63.0507 120.569 63.879 120.487 64.8631C120.397 65.9456 120.542 66.8865 120.921 67.686C121.3 68.4855 121.946 69.208 122.859 69.8536C123.796 70.5012 125.018 71.1477 126.525 71.7932Z"
                fill="#2E54E8"
            />
            <path
                d="M182.178 120.251L184.506 112.987C181.606 112.005 178.997 110.884 176.679 109.623C174.393 108.345 172.48 107.032 170.942 105.684L173.349 98.1729C174.888 99.5994 176.877 101.079 179.315 102.612C181.753 104.146 184.203 105.359 186.665 106.251L190.903 93.0275C187.863 90.783 185.473 88.6949 183.733 86.7633C182.017 84.8392 180.929 82.8961 180.469 80.9341C180.016 78.9485 180.163 76.792 180.909 74.4646C181.678 72.0667 182.936 70.2018 184.685 68.8699C186.465 67.5221 188.599 66.6893 191.086 66.3716C193.596 66.0614 196.315 66.2719 199.244 67.0031L201.086 61.2552L205.67 62.7244L203.862 68.3666C206.449 69.2735 208.764 70.3396 210.808 71.5651C212.883 72.7746 214.789 74.15 216.524 75.6913L211.848 81.4252C210.311 80.1548 208.662 78.9912 206.9 77.9342C205.139 76.8772 203.392 75.9801 201.658 75.2429L197.521 88.1493C200.088 90.0089 202.315 91.8373 204.202 93.6345C206.112 95.4392 207.438 97.3679 208.18 99.4204C208.953 101.457 208.918 103.792 208.074 106.425C206.966 109.88 204.781 112.33 201.519 113.773C198.264 115.193 194.144 115.35 189.158 114.244L186.762 121.72L182.178 120.251ZM191.339 107.438C193.686 107.88 195.556 107.831 196.947 107.291C198.346 106.729 199.279 105.719 199.746 104.261C200.062 103.274 200.095 102.364 199.843 101.531C199.615 100.706 199.094 99.8653 198.28 99.0081C197.489 98.1585 196.377 97.2189 194.945 96.1894L191.339 107.438ZM193.491 84.9522L197.029 73.9147C195.62 73.6188 194.377 73.5317 193.301 73.6534C192.225 73.7752 191.343 74.1016 190.654 74.6327C189.966 75.1638 189.471 75.8995 189.169 76.8398C188.838 77.8742 188.767 78.8236 188.956 79.688C189.146 80.5524 189.613 81.4019 190.356 82.2365C191.123 83.0785 192.168 83.9838 193.491 84.9522Z"
                fill="#2E54E8"
            />
            <path
                d="M204.105 26.6898C203.556 26.6898 203.144 26.2778 203.006 25.7284L202.869 15.839C202.869 15.2896 203.281 14.7402 203.83 14.7402C204.38 14.7402 204.929 15.1523 204.929 15.7017L205.067 25.591C205.204 26.1404 204.792 26.6898 204.105 26.6898C204.242 26.6898 204.105 26.6898 204.105 26.6898Z"
                fill="#FFCC3E"
            />
            <path
                d="M210.148 30.8103C210.011 30.8103 209.874 30.8103 209.736 30.6729C209.187 30.3982 209.049 29.8488 209.187 29.2994L212.621 21.8824C212.895 21.333 213.445 21.1956 213.994 21.333C214.543 21.6077 214.681 22.1571 214.543 22.7065L211.11 30.1235C210.972 30.5356 210.56 30.8103 210.148 30.8103Z"
                fill="#FFCC3E"
            />
            <path
                d="M21.9764 68.9941L18.6799 83.1414L32.003 78.1967L21.9764 68.9941Z"
                fill="white"
            />
            <path
                d="M232.468 95.0176C232.193 95.0176 232.056 94.8802 231.781 94.7429L227.386 90.897C226.974 90.485 226.837 89.9356 227.249 89.3862C227.661 88.9741 228.21 88.8367 228.76 89.2488L233.155 93.0946C233.567 93.5067 233.704 94.0561 233.292 94.6055C233.018 94.8802 232.743 95.0176 232.468 95.0176Z"
                fill="#FFCC3E"
            />
            <path
                d="M140.961 17.2628C140.824 17.2628 140.687 17.2628 140.549 17.1255C140 16.8508 139.863 16.3014 140.137 15.752L144.67 6.54939C144.945 5.99999 145.494 5.86265 146.043 6.13736C146.593 6.41206 146.73 6.96147 146.456 7.51087L141.923 16.7134C141.786 17.1255 141.374 17.2628 140.961 17.2628Z"
                fill="#FFCC3E"
            />
            <path
                d="M240.366 23.8054C240.091 23.8054 239.816 23.668 239.679 23.5307C239.267 23.1186 239.267 22.4319 239.679 22.0198L248.332 13.3667C248.744 12.9546 249.431 12.9546 249.843 13.3667C250.255 13.7787 250.255 14.4655 249.843 14.8775L241.19 23.5307C240.778 23.668 240.64 23.8054 240.366 23.8054Z"
                fill="#FFCC3E"
            />
            <path
                d="M223.884 48.254C223.334 48.254 222.922 47.842 222.922 47.2926L222.373 42.0732C222.373 41.5238 222.785 40.9744 223.334 40.9744C223.884 40.9744 224.433 41.3864 224.433 41.9358L224.982 47.1552C224.982 47.7046 224.57 48.254 223.884 48.254Z"
                fill="#FFCC3E"
            />
            <path
                d="M43.4751 21.0257C43.9736 21.1175 44.4221 20.7811 44.6461 20.2637L46.5609 10.5606C46.6603 10.0203 46.3859 9.41117 45.8874 9.31942C45.3889 9.22766 44.8158 9.54116 44.7163 10.0815L42.8016 19.7845C42.5775 20.3019 42.852 20.911 43.4751 21.0257C43.3505 21.0028 43.4751 21.0257 43.4751 21.0257Z"
                fill="#FFCC3E"
            />
            <path
                d="M13.6331 23.5919C13.6659 23.7143 13.6987 23.8367 13.8641 23.9235C14.2607 24.342 14.8241 24.3222 15.322 24.0576L21.6664 19.0779C22.1314 18.6909 22.1329 18.1658 21.8691 17.7117C21.4725 17.2932 20.9091 17.313 20.4112 17.5776L14.0668 22.5573C13.7016 22.7864 13.5347 23.2247 13.6331 23.5919Z"
                fill="#FFCC3E"
            />
            <path
                d="M23.2127 82.8664C23.4661 82.8664 23.5929 82.7291 23.8463 82.5917L27.9013 78.7459C28.2815 78.3338 28.4082 77.7844 28.028 77.235C27.6479 76.823 27.141 76.6856 26.6341 77.0977L22.5791 80.9435C22.199 81.3556 22.0722 81.905 22.4524 82.4544C22.7058 82.7291 22.9593 82.8664 23.2127 82.8664Z"
                fill="#FFCC3E"
            />
            <path
                d="M84.3447 19.9335C84.3775 20.0559 84.4103 20.1783 84.5758 20.2651C84.9723 20.6836 85.5358 20.6638 86.0009 20.2768L93.8076 13.8558C94.2727 13.4688 94.2741 12.9436 93.8776 12.5251C93.4811 12.1066 92.9176 12.1264 92.4525 12.5134L84.6458 18.9345C84.2806 19.1635 84.2463 19.5663 84.3447 19.9335Z"
                fill="#FFCC3E"
            />
            <path
                d="M17.2695 51.4472C17.523 51.4472 17.7764 51.3099 17.9031 51.1725C18.2833 50.7605 18.2833 50.0737 17.9031 49.6616L9.91981 41.0085C9.53966 40.5964 8.90605 40.5964 8.5259 41.0085C8.14574 41.4206 8.14574 42.1073 8.5259 42.5194L16.5092 51.1725C16.8894 51.3099 17.0161 51.4472 17.2695 51.4472Z"
                fill="#FFCC3E"
            />
            <path
                d="M3.76064 72.7025C4.26752 72.7025 4.64767 72.2904 4.64767 71.741L5.15454 66.5217C5.15454 65.9723 4.77439 65.4229 4.26751 65.4229C3.76064 65.4229 3.25376 65.8349 3.25376 66.3843L2.74689 71.6037C2.74689 72.1531 3.12705 72.7025 3.76064 72.7025Z"
                fill="#FFCC3E"
            />
            <path
                d="M154.658 129.017L161.526 123.66L162.899 133L154.658 129.017Z"
                fill="white"
            />
        </svg>
    )
}
