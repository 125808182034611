import { useTheme } from "@emotion/react"
import { useHistory } from "react-router-dom"
import { DefaultPage } from "../molecules/DefaultPage"
import {
    Card,
    Container,
    P,
    Spacer,
    TouchableContainer,
} from "@wetradeup/ui-kit"
import { ApplicationButton } from "../../atoms/ApplicationButton"
import { Percent } from "../icons/Percent"
import { UnderlineText } from "../../atoms/UnderlineText"
import { Arrow } from "../icons/Arrow"
import { Phone } from "../icons/Phone"
import { Truck } from "../icons/Truck"
import { Home } from "../icons/Home"
import styled from "@emotion/styled"
import { useWindowSize } from "../../hooks/useWindowSize"

const benefits = [
    {
        icon: Percent,
        title: "Borrow money at better interest rates",
        url: "https://www.nerdwallet.com/article/mortgages/credit-score-affects-mortgage-rate",
        reference: "Nerdwallet",
    },
    {
        icon: Phone,
        title: "Get better terms on your next cell phone",
        url: "https://www.capitalone.com/learn-grow/money-management/benefits-of-high-credit-score/",
        reference: "CapitalOne",
    },
    {
        icon: Truck,
        title: "Get better rates on car insurance",
        url: "https://www.allstate.com/resources/car-insurance/does-credit-score-affect-car-insurance#:~:text=If%20you've%20ever%20applied,status%20and%20payment%20history%20can",
        reference: "Allstate",
    },
    {
        icon: Home,
        title: "Get approved for that new apartment",
        url: "https://www.capitalone.com/learn-grow/money-management/what-credit-score-is-needed-to-rent-apartment/",
        reference: "CapitalOne",
    },
]

const WrappingTouchableContainer = styled(TouchableContainer)`
    display: contents;
`
export function ExitCounselingCreditScoresPage() {
    const theme = useTheme()
    const history = useHistory()
    const { windowSize } = useWindowSize()

    const isMobile = windowSize.width <= theme.breakpoints.sm
    return (
        <DefaultPage>
            <Card border="medGrey" disableShadow width="100%" paddingLg>
                <P
                    textAlign="left"
                    textSize={theme.sizings.fontSizeInc2}
                    bold
                    textColor="black"
                >
                    Here's how improving your credit could help you in the
                    future!
                </P>
                <Spacer lg />
                <P textColor="black">
                    Good credit is important for a healthy financial future, but
                    don’t take our word for it! Check out some benefits of
                    improving your credit according to the experts:
                </P>
                {benefits.map((benefitItem) => (
                    <>
                        <Spacer lg />
                        <Card
                            disableShadow
                            width="100%"
                            backgroundColor={theme.colors.palette.newLightGrey}
                            row
                            alignItems="center"
                        >
                            <benefitItem.icon />
                            <Spacer />
                            <Container
                                {...(isMobile
                                    ? { column: true }
                                    : { row: true })}
                                flex={1}
                                justifyContent="space-between"
                            >
                                <WrappingTouchableContainer
                                    onPress={() => {
                                        window.open(benefitItem.url, "_blank")
                                    }}
                                    row
                                    alignItems="center"
                                >
                                    {isMobile ? (
                                        <P textColor="black" bold>
                                            {benefitItem.title}
                                        </P>
                                    ) : (
                                        <UnderlineText textColor="black" bold>
                                            {benefitItem.title} <Arrow />
                                        </UnderlineText>
                                    )}
                                </WrappingTouchableContainer>
                                {isMobile ? (
                                    <UnderlineText
                                        textSize={theme.sizings.fontSizeXs}
                                        textColor="black"
                                    >
                                        According to {benefitItem.reference}
                                    </UnderlineText>
                                ) : (
                                    <P
                                        textSize={theme.sizings.fontSizeXs}
                                        textColor="black"
                                    >
                                        According to {benefitItem.reference}
                                    </P>
                                )}
                            </Container>
                        </Card>
                    </>
                ))}
            </Card>

            <Spacer lg />
            <Container width={300}>
                <ApplicationButton
                    fullWidth
                    alignCenter
                    title="Continue"
                    buttonColor="newBlue"
                    onPress={() => {
                        history.push("/loan-orientation/quiz")
                    }}
                />
            </Container>
        </DefaultPage>
    )
}
