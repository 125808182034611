import { useHistory } from "react-router-dom"
import { Container, Spacer, H2, H4 } from "@wetradeup/ui-kit"
import { SubmissionButton } from "../application/atoms/SubmissionButton"
import { ContentContainer } from "../ModalComponents"

export default function PendingDecision() {
    const history = useHistory()

    return (
        <Container
            paddingMd
            width={[
                [550, "sm"],
                [550, "md"],
                [750, "lg"],
            ]}
        >
            <ContentContainer justifyContent="center" alignItems="center">
                <H2 bold="light">
                    {" "}
                    We're reviewing your application! We'll reach out in the
                    next 24-48 hours to inform you of our decision.{" "}
                </H2>
                <Spacer lg />
                <H4 bold="light">
                    We'll contact you about our decision via email and text. You
                    can leave this page if you'd like.
                </H4>
                <Spacer size={32} />
                <SubmissionButton
                    onPress={() => history.push("/application/intro/select")}
                    primary
                    title="Exit Application"
                />
            </ContentContainer>
            <Spacer lg />
        </Container>
    )
}
