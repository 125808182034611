import styled from "@emotion/styled"
import { BackgroundImage } from "@wetradeup/ui-kit"
import { breakpoints } from "../../../constants"

export const Logo = styled(BackgroundImage)`
    @media (max-width: ${breakpoints.mobileLg}) {
        height: 68px;
        width: 68px;
    }
    @media (min-width: ${breakpoints.mobileLg}) {
        height: 92px;
        width: 92px;
    }
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 50%;
    elevation: 1;
    box-shadow: 0px 4px 4px 0px #00000040;
`
