export const initialCreateAccountFormValues = {
    first: "",
    last: "",
    program: "",
    anticipated_start_date: null,
    phone: "",
    email: "",
    passwordOne: "",
    passwordTwo: "",
    passwordRequired: true,
    is_creating_new_account: true,
}

export const initialBasicValues = {
    street: "",
    street_line_two: "",
    city: "",
    state: "",
    zip: "",
    citizen: null,
    dob: null,
    ssn: "",
    confirmSSN: "",
    residency: "",
    education_level: "",
    school: "",
    program: "",
    anticipated_start_date: null,
}

export const initialPersonalValues = {
    ssn: "",
    confirmSSN: "",
    dob: null,
    gender: "",
    photo_id: "",
    consent: false,
}

export const initialInformationValues = {
    street: "",
    city: "",
    state: "",
    zip: "",
    ssn: "",
    confirmSSN: "",
    dob: "",
    gender: "",
    photo_id: "",
    consent: false,
}
