import {
    Questionnaire,
    QuestionnaireAnswer,
} from "@wetradeup/student-apps-shared"
import { useMemo } from "react"

export function useCreateReviewInitValues(
    questionnaire: Questionnaire[]
): QuestionnaireAnswer {
    return useMemo(() => {
        return Object.fromEntries(
            questionnaire.map((i) => {
                return [i["id"], null]
            })
        )
    }, [questionnaire])
}
