import {
    useGetStripeVerificationSession,
    useGetStudentProfile,
    useStudentApplicationDetails,
} from "@wetradeup/student-apps-shared"
import { useContext, useEffect, useState } from "react"
import * as Sentry from "@sentry/react"
import { StripeContext } from "../../contexts/StripeProvider"

export function useStripe() {
    useStudentApplicationDetails({ enabled: true, refetchInterval: 10 * 1000 })
    const studentProfile = useGetStudentProfile({
        enabled: true,
        refetchInterval: 10 * 1000,
    })
    const [isLoading, setIsLoading] = useState(false)
    const [isPending, setIsPending] = useState(false)
    const { stripe } = useContext(StripeContext)

    const getStripeVerificationSession = useGetStripeVerificationSession()

    const handleCreateVerificationSession = async (e: any) => {
        e.preventDefault()
        try {
            setIsLoading(true)
            const result = await getStripeVerificationSession.mutateAsync()

            const data = await stripe.verifyIdentity(result?.client_secret)
            if (data.error) {
                Sentry.addBreadcrumb({
                    category: "error",
                    message: "Could not create a verification session",
                    data: {
                        stripeErrorObject: JSON.stringify(data),
                    },
                })

                Sentry.captureException(
                    new Error("Could not create a Stripe verification session.")
                )
            } else {
                setIsPending(true)
            }
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (
            isPending &&
            studentProfile.data
                ?.stripe_identity_verification_processing_status !==
                "processing"
        ) {
            setIsPending(true)
        }

        if (
            isPending &&
            studentProfile.data
                ?.stripe_identity_verification_processing_status ===
                "processing"
        ) {
            setIsPending(false)
        }
    }, [
        isPending,
        studentProfile.data?.stripe_identity_verification_processing_status,
    ])

    const isPendingIDReview =
        studentProfile.data?.stripe_identity_verification_processing_status ===
            "processing" || isPending

    return {
        isPendingIDReview,
        isLoading,
        handleCreateVerificationSession,
    }
}
