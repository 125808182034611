import { useMemo, useEffect, useContext, useState } from "react"
import { Container, H2, H3, P, Spacer, Caption } from "@wetradeup/ui-kit"
import {
    useAttemptVerificationMutation,
    useGetAccount,
    AuthContext,
    StudentAccount,
    SCHOOL_PORTAL_URL,
} from "@wetradeup/student-apps-shared"
import { RouteComponentProps, useHistory } from "react-router-dom"
import queryString from "query-string"
import { ContentContainer, FullMobileContainer } from "../ModalComponents"
import { ResendEmail } from "./molecules/ResendEmail"
import { EmailLoginModal } from "./molecules/modals/EmailLoginModal"
import { Pointer } from "./atoms/Pointer"
import { SpinningLoader } from "./atoms/SpinningLoader"
import { useFynnLocation } from "../hooks/useFynnLocation"
import { ChangeEmail } from "./molecules/ChangeEmail"

export default function VerifyEmail({
    match,
}: RouteComponentProps<{ schoolId?: string }>) {
    const { authProfile, authLoaded } = useContext(AuthContext)
    const [loginSubmitted, setLoginSubmitted] = useState()
    const schoolId = parseInt(match.params.schoolId)
    const history = useHistory()
    const location = useFynnLocation()
    const accountQuery = useGetAccount(1000)
    const mutation = useAttemptVerificationMutation()

    const query = useMemo(
        () => queryString.parse(location.search),
        [location.search]
    )

    useEffect(() => {
        ;(async () => {
            if (authLoaded && !!authProfile && query.code) {
                await mutation.mutateAsync({ email_code: query.code as string })
            } else if (authLoaded && !authProfile) {
                history.push(`/login?code=${query.code}&schoolId=${schoolId}`)
            }
        })()
    }, [query, authLoaded, authProfile])

    const message = useMemo(() => {
        const email = authProfile?.email || "your email"

        if ((accountQuery?.data as StudentAccount)?.verified_email) {
            return <H3 white>Thanks! Your email has been verified</H3>
        }
        if (!query.code) {
            return (
                <>
                    <P textAlign="center"> We take your security seriously. </P>
                    <P textAlign="center">
                        {" "}
                        Check <P weight={600}> {email} </P> to continue.{" "}
                    </P>
                    <P bold textAlign="center">
                        Make sure to check your spam folder!
                    </P>
                </>
            )
        }

        if (mutation.isLoading) return <H3>Please Wait, verifying you...</H3>
        if (mutation.isSuccess)
            return <H3>Thanks! Your email has been verified.</H3>
        if (mutation.isError) {
            return (
                <H3>Oops! Something has gone wrong, please try again later.</H3>
            )
        }
    }, [
        authProfile?.email,
        accountQuery.data,
        query,
        mutation.isLoading,
        mutation.isSuccess,
        mutation.isError,
    ])

    useEffect(() => {
        if (loginSubmitted && !!authProfile) {
            if (authProfile.role === "school") {
                window.location.href = SCHOOL_PORTAL_URL
            }
        }
    }, [
        mutation.isSuccess,
        schoolId,
        accountQuery.data,
        loginSubmitted,
        authProfile,
    ])

    const showLoginModal = authLoaded && !authProfile

    const title = query.code
        ? "Verifying your email"
        : "Check your email for verification"

    return (
        <FullMobileContainer
            paddingMd
            width={[
                [550, "md"],
                [750, "lg"],
            ]}
            justifyContent="center"
            alignItems="center"
        >
            <Container row alignItems="center" justifyContent="center">
                <Pointer />
            </Container>
            <Spacer />
            <H2 bold="light">{title}</H2>
            <Spacer size={20} />
            <ContentContainer>
                {query.code && <SpinningLoader />}
                {!query.code && (
                    <>
                        {message}
                        {(mutation.isSuccess ||
                            (accountQuery?.data as StudentAccount)
                                ?.verified_email) && (
                            <P success>
                                Redirecting you to complete your application...
                            </P>
                        )}
                        <Spacer lg />
                        <Caption textAlign="center"> Wrong email? </Caption>
                        <ChangeEmail schoolId={schoolId} />
                        <Spacer lg />
                        <Caption textAlign="center">
                            {" "}
                            Didn't get a verification email?{" "}
                        </Caption>
                        <ResendEmail schoolId={schoolId} />
                    </>
                )}
            </ContentContainer>
            <Spacer lg />
            <EmailLoginModal
                visible={showLoginModal}
                setLoginSubmitted={setLoginSubmitted}
            />
        </FullMobileContainer>
    )
}
