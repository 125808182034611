import {
    CancelIcon,
    SomethingWentWrong,
    Container,
    H3,
    Spacer,
    P,
} from "@wetradeup/ui-kit"
import { CancelIconContainer } from "../application/molecules/modals/DecisionModal"
import { HiddenBr } from "../application/atoms/HiddenBr"
import { ContentContainer } from "../ModalComponents"
import { useStudentApplicationDetails } from "@wetradeup/student-apps-shared"

interface FailedIdentityVerificationProps {
    studentApplicationDetails: ReturnType<typeof useStudentApplicationDetails>
}

export default function FailedIdentityVerification({
    studentApplicationDetails,
}: FailedIdentityVerificationProps) {
    return (
        <Container
            width={[
                [550, "sm"],
                [700, "md"],
                [860, "lg"],
            ]}
        >
            <Spacer />
            <ContentContainer>
                <Container
                    fullWidth
                    alignItems="center"
                    justifyContent="center"
                >
                    <CancelIconContainer width={28}>
                        <CancelIcon />
                    </CancelIconContainer>
                </Container>
                <Spacer />
                <H3 bold="light">
                    {" "}
                    We're unable to verify your identity <HiddenBr />
                    with the information you provided{" "}
                </H3>
                <Spacer />
                <ContentContainer>
                    <P textAlign="center">
                        {" "}
                        Please contact customer service to help resolve this
                        issue.{" "}
                    </P>
                    <Spacer />
                    <Container alignItems="center">
                        <SomethingWentWrong />
                    </Container>
                    <Spacer />
                </ContentContainer>
                <Spacer lg />
            </ContentContainer>
            <Spacer lg />
        </Container>
    )
}
