import { useTheme } from "@emotion/react"
import { useHistory, useRouteMatch } from "react-router-dom"
import { Card, Container, P, Spacer } from "@wetradeup/ui-kit"
import { ApplicationButton } from "../../atoms/ApplicationButton"
import { DefaultPage } from "../molecules/DefaultPage"
import { BlueCircle } from "../icons/BlueCircle"
import styled from "@emotion/styled"
import { MoneyCircle } from "../icons/MoneyCircle"
import { CheckMarkCircle } from "../icons/CheckMarkCircle"
import { format, addMonths, differenceInDays } from "date-fns"
import { SpinningLoader } from "../../application/atoms/SpinningLoader"
import { useProperExitCounseling } from "../hooks/useProperExitCounseling"
import { useProperUpdateExitCounselingStatus } from "../hooks/useProperUpdateExitCounselingStatus"

export const DottedLine = styled.div`
    border-left: 3px dashed #0070f9;
    height: 100%;
    width: 0px;
`

export const GreenLine = styled.div`
    border-left: 3px solid #00ca80;
    height: 100%;
    width: 0px;
`

export function ExitCounselingRepayment() {
    const theme = useTheme()
    const history = useHistory()

    const match = useRouteMatch<{ accountId?: string }>({
        path: "/loan-orientation/admin/:accountId/repayment",
    })
    const exitCounseling = useProperExitCounseling(match)
    const updateExitCounselingStatus =
        useProperUpdateExitCounselingStatus(match)

    const handleContinue = async () => {
        await updateExitCounselingStatus.mutateAsync({
            exit_counseling_stage: "repayment",
        })
        if (match?.params?.accountId) {
            history.push(
                `/loan-orientation/admin/${match?.params?.accountId}/repayment-how-to`
            )
        } else {
            history.push("/loan-orientation/repayment-how-to")
        }
    }

    const gracePeriodDays =
        exitCounseling.data?.grad_date &&
        exitCounseling.data?.first_standard_payment_due_date
            ? differenceInDays(
                  exitCounseling.data?.first_standard_payment_due_date,
                  exitCounseling.data?.grad_date
              )
            : "N/A"

    const gracePeriodExplanation = exitCounseling.data?.has_grace_period_reduced
        ? `Your grace period will last from ${
              exitCounseling.data?.grad_date
                  ? format(exitCounseling.data?.grad_date, "PPP")
                  : "N/A"
          } to ${
              exitCounseling.data?.first_standard_payment_due_date
                  ? format(
                        exitCounseling.data?.first_standard_payment_due_date,
                        "PPP"
                    )
                  : "N/A"
          }. Your grace period length was affected because your graduation date was extended.`
        : `From ${
              exitCounseling.data?.grad_date
                  ? format(exitCounseling.data?.grad_date, "PPP")
                  : "N/A"
          } to ${
              exitCounseling.data?.first_standard_payment_due_date
                  ? format(
                        exitCounseling.data?.first_standard_payment_due_date,
                        "PPP"
                    )
                  : "N/A"
          }, you won't have to make a payment. It's good to use this time to find a job and make sure you know your loan obligations.`

    const graduationTitle = "Graduation:"
    const firstPaymentTitle = "First full payment due:"
    const graduationDate = exitCounseling.data?.grad_date
        ? format(exitCounseling.data?.grad_date, "PPP")
        : "N/A"
    const firstPaymentDate = exitCounseling.data
        ?.first_standard_payment_due_date
        ? format(exitCounseling.data?.first_standard_payment_due_date, "PPP")
        : "N/A"

    return (
        <DefaultPage>
            <Card border="black" disableShadow width="100%" paddingLg>
                <P textColor="black">Your Fynn Number</P>
                <Spacer />
                <P textSize={theme.sizings.fontSizeMdLg} bold textColor="black">
                    {exitCounseling.data?.vervent_id}
                </P>
                <Spacer sm />
                <P textColor="black" textSize={theme.sizings.fontSizeXxs}>
                    (You'll need this number to verify your identity whenever
                    you speak with our billing department)
                </P>
            </Card>
            <Spacer lg />
            <Card border="black" disableShadow width="100%" paddingLg>
                <P
                    textAlign="left"
                    textSize={theme.sizings.fontSizeInc2}
                    bold
                    textColor="black"
                >
                    Let's talk about repayment
                </P>
                <Spacer lg />
                <Container row justifyContent="flex-start" wrap gap="16px">
                    <Container>
                        <P textColor="black">Monthly Payment</P>
                        <Spacer sm />
                        <P
                            textSize={theme.sizings.fontSizeMdLg}
                            bold
                            textColor="black"
                        >
                            $
                            {exitCounseling.data?.standard_payment_amount.toLocaleString()}
                        </P>
                    </Container>
                    <Spacer xl />
                    <Container>
                        <P textColor="black">Your first full payment date</P>
                        <Spacer sm />
                        <P
                            textSize={theme.sizings.fontSizeMdLg}
                            bold
                            textColor="black"
                        >
                            {firstPaymentDate}
                        </P>
                    </Container>
                </Container>
            </Card>
            <Spacer lg />
            <Card border="medGrey" disableShadow width="100%" paddingLg>
                <P
                    textAlign="left"
                    textSize={theme.sizings.fontSizeInc2}
                    bold
                    textColor="black"
                >
                    Your loan timeline
                </P>
                <Spacer lg />
                <Container width="100%">
                    <Container row width="100%">
                        <Container alignItems="center" width="25px">
                            <Spacer />
                            <Container>
                                {gracePeriodDays > 0 ? (
                                    <BlueCircle />
                                ) : (
                                    <MoneyCircle />
                                )}
                            </Container>
                            <Container height="calc(100% + 32px)">
                                {gracePeriodDays > 0 ? (
                                    <DottedLine />
                                ) : (
                                    <GreenLine />
                                )}
                            </Container>
                        </Container>
                        <Spacer />
                        <Container flex={1}>
                            <Card
                                id="repayment_graduation_section"
                                backgroundColor="medLightGrey"
                                border="medGrey"
                                disableShadow
                                paddingMd
                            >
                                <P textColor="black">
                                    <P bold textColor="black">
                                        {gracePeriodDays > 0
                                            ? graduationTitle
                                            : firstPaymentTitle}
                                    </P>{" "}
                                    {gracePeriodDays > 0
                                        ? graduationDate
                                        : firstPaymentDate}
                                </P>
                            </Card>
                            {gracePeriodDays > 0 && (
                                <>
                                    <Spacer />
                                    <Card
                                        backgroundColor="medLightGrey"
                                        border="medGrey"
                                        disableShadow
                                        paddingMd
                                    >
                                        <P bold textColor="black">
                                            {gracePeriodDays} day grace period
                                        </P>
                                        <Spacer />
                                        <P textColor="black">
                                            {gracePeriodExplanation}
                                        </P>
                                    </Card>
                                </>
                            )}
                        </Container>
                    </Container>
                    <Spacer />
                    <Container row width="100%">
                        <Container alignItems="center" width="25px">
                            <Spacer />
                            <Container>
                                {gracePeriodDays > 0 ? (
                                    <MoneyCircle />
                                ) : (
                                    <BlueCircle />
                                )}
                            </Container>
                            <Container height="100%">
                                <GreenLine />
                            </Container>
                        </Container>
                        <Spacer />
                        <Container flex={1}>
                            <Card
                                id="repayment_payment_section"
                                backgroundColor="medLightGrey"
                                border="medGrey"
                                disableShadow
                                paddingMd
                            >
                                <P textColor="black">
                                    <P bold textColor="black">
                                        {gracePeriodDays > 0
                                            ? firstPaymentTitle
                                            : graduationTitle}
                                    </P>{" "}
                                    {gracePeriodDays > 0
                                        ? firstPaymentDate
                                        : graduationDate}
                                </P>
                            </Card>

                            {gracePeriodDays > 0 && (
                                <>
                                    <Spacer />
                                    <Card
                                        backgroundColor="medLightGrey"
                                        border="medGrey"
                                        disableShadow
                                        paddingMd
                                    >
                                        <P bold textColor="black">
                                            {exitCounseling.data?.loan_term}{" "}
                                            months of repayment
                                        </P>
                                    </Card>
                                </>
                            )}
                        </Container>
                    </Container>
                    <Spacer />
                    <Container row width="100%">
                        <Container alignItems="center" width="25px">
                            <Spacer />
                            <CheckMarkCircle />
                        </Container>
                        <Spacer />
                        <Container flex={1}>
                            <Card
                                id="repayment_completed_section"
                                backgroundColor="medLightGrey"
                                border="medGrey"
                                disableShadow
                                paddingMd
                            >
                                <P textColor="black">
                                    <P bold textColor="black">
                                        Loan completion:
                                    </P>{" "}
                                    {exitCounseling.data
                                        ?.first_standard_payment_due_date
                                        ? format(
                                              addMonths(
                                                  exitCounseling.data
                                                      ?.first_standard_payment_due_date,
                                                  exitCounseling.data?.loan_term
                                              ),
                                              "PPP"
                                          )
                                        : "N/A"}
                                </P>
                            </Card>
                        </Container>
                    </Container>
                </Container>
            </Card>
            <Spacer lg />
            <Container width={300}>
                {updateExitCounselingStatus.isLoading && <SpinningLoader />}
                {!updateExitCounselingStatus.isLoading && (
                    <ApplicationButton
                        fullWidth
                        alignCenter
                        title="Continue"
                        buttonColor="newBlue"
                        onPress={handleContinue}
                    />
                )}
            </Container>
        </DefaultPage>
    )
}
