import { useState, useRef } from "react"
import { Container, Spacer, H2, H4, P } from "@wetradeup/ui-kit"
import { RouteComponentProps } from "react-router-dom"
import { QuestionnaireWrapper } from "./Questionnaire/QuestionnaireWrapper"
import { useGetQuestionnaire } from "./Questionnaire/hooks/useGetQuestionnaire"
import { ContentContainer } from "../ModalComponents"
import { SpinningLoader } from "./atoms/SpinningLoader"
import { SubmitButtonContainer } from "./molecules/SubmitButtonContainer"
import { breakpoints } from "../../constants"
import styled from "@emotion/styled"
import { toTopMobile, toTop } from "../../utils"
import { useEffect } from "react"

const CardLoader = () => (
    <Container
        width="100%"
        paddingMd
        justifyContent="center"
        alignItems="center"
    >
        <SpinningLoader />
    </Container>
)

const ContainerWrapper = styled(Container)`
    @media (max-width: ${breakpoints.mobileLg}) {
        max-width: calc(100vw - 32px);
    }
`

interface QuestionnairePageProps {
    appFormId?: number
    employerId?: number
    programId?: number
}

export default function QuestionnairePage({
    match,
    appFormId,
    employerId,
    programId,
}: QuestionnairePageProps & RouteComponentProps<{ schoolId?: string }>) {
    const schoolId = parseInt(match.params.schoolId)
    const productType = employerId ? "employer" : "isl"
    const loadedInfoProps = !!programId

    const { questionnaire, setQuestionnaire } = useGetQuestionnaire(
        schoolId,
        productType,
        loadedInfoProps
    )
    const [page, setPage] = useState<"one" | "two">("one")
    const [loading, setLoading] = useState(false)
    const [unanswered, setUnanswered] =
        useState<{ [key: string]: string }>(null)
    const handleSubmitRef =
        useRef<(e?: React.FormEvent<HTMLFormElement>) => void | undefined>()
    const prevPageRef = useRef<string>()

    const onPress = () => {
        if (
            page === "one" &&
            questionnaire.length > 0 &&
            questionnaire.some((q) => q.style === "prioritize")
        ) {
            setPage("two")
        } else {
            handleSubmitRef.current()
        }
    }

    const title =
        page === "one" ? "Tell us about yourself" : "Just a few more questions!"

    const disabled = !unanswered || Object.keys(unanswered).length > 0

    useEffect(() => {
        toTop()
    }, [])

    useEffect(() => {
        if (prevPageRef.current === "one" && page === "two") {
            toTopMobile()
        }

        prevPageRef.current = page
    }, [page])

    return (
        <ContainerWrapper
            paddingMd
            width={[
                [550, "sm"],
                [550, "md"],
                [750, "lg"],
            ]}
        >
            <Spacer />
            <H2 bold="light"> {title} </H2>
            <Spacer size={20} />
            <ContentContainer>
                {page === "two" && (
                    <>
                        <H4 bold="light"> Reordering Questions </H4>
                        <Spacer />
                        <P textAlign="center">
                            {" "}
                            Reorder the following in order of those what you
                            feel are most to least true.{" "}
                        </P>
                        <Spacer lg />
                    </>
                )}
                {questionnaire?.length > 0 ? (
                    <QuestionnaireWrapper
                        schoolId={schoolId}
                        appFormId={appFormId}
                        questionnaire={questionnaire}
                        setQuestionnaire={setQuestionnaire}
                        setLoading={setLoading}
                        handleSubmitRef={handleSubmitRef}
                        page={page}
                        setUnanswered={setUnanswered}
                        employerId={employerId}
                    />
                ) : (
                    <CardLoader />
                )}
                <Spacer />
                {!loading && (
                    <SubmitButtonContainer
                        handleSubmit={onPress}
                        errors={unanswered}
                        disabled={disabled}
                        title="Next Page"
                    />
                )}
                {loading && <CardLoader />}
            </ContentContainer>
            <Spacer lg />
        </ContainerWrapper>
    )
}
