import { Card, Container, P } from "@wetradeup/ui-kit"
import { ThreeDollars } from "../isl-finalize/icons/ThreeDollars"
import { HiddenBr } from "./atoms/HiddenBr"

export function ProgramFinanceDetailSection() {
    return (
        <Card disableShadow backgroundColor="lightGreen" borderRadiusSm>
            <Container row justifyContent="center">
                <ThreeDollars />
            </Container>
            <P textAlign="center" textColor="black">
                Good news! A Fynn loan can typically <HiddenBr />
                cover the full cost of tuition for this program.
            </P>
        </Card>
    )
}
