import { Questionnaire, baseFetch } from "@wetradeup/student-apps-shared"
import { useEffect, useState } from "react"
import { API_URL } from "../../../../config"

export function useGetQuestionnaire(
    schoolId: number,
    productType: "employer" | "isl",
    loadedInfoProps: boolean
) {
    const [questionnaire, setQuestionnaire] = useState<Questionnaire[]>([])

    const getQuestionnaire = async (schoolId, productType) => {
        const url = `${API_URL}/application-forms/${schoolId}/questionnaire/${productType}`
        const response = await baseFetch(url)
        const data = await response.json()
        if ("questionnaire" in data) {
            setQuestionnaire(data["questionnaire"])
        } else {
            window.location.href = "/server-error"
        }
    }

    useEffect(() => {
        if (loadedInfoProps && schoolId && productType) {
            getQuestionnaire(schoolId, productType)
        }
    }, [schoolId, productType, loadedInfoProps])

    return { questionnaire, setQuestionnaire }
}
