import styled from "@emotion/styled"
import { Container, P, Spacer } from "@wetradeup/ui-kit"
import { Fragment } from "react"
import { breakpoints } from "../../constants"
import { hideOnMobile } from "../../styles"

const FinalizeProgressBarContainer = styled.div<{ hide?: Boolean }>`
    ${(props) => `
        height: 50px;
        position: relative;
        margin-bottom: 0px;
        display: flex;
        flex-direction: row;
        margin-top: 40px;

        @media (max-width: ${breakpoints.mobileLg}) {
            margin-left: ${props.theme.sizings.paddingLg};
            margin-right: ${props.theme.sizings.paddingLg};
            margin-top: 0px;

            ${props.hide && "display: none;"}
        }
    `}
`

const Bar = styled.div<{ completed: boolean }>`
    height: 4px;
    background-color: ${(props) =>
        props.completed
            ? props.theme.colors.palette.medGreen
            : props.theme.colors.palette.medGrey};
    transform: translateY(-8px);
    border-radius: 5px;

    @media (max-width: ${breakpoints.mobileLg}) {
        margin-top: 20px;
    }
`

const HidableP = styled(P)`
    ${(props) => hideOnMobile(props.theme)}
    margin-bottom: 8px;
`

interface ISLHeaderProps {
    hide?: boolean
    currentPosition?: number
    isFinalize?: boolean
}

export const finalizeTitles = {
    loanTerms: "Create Your Loan",
    verification: "Verification",
    signature: "Document Signature",
}

export const applicationTitles = {
    basic: "Basic Info",
    questionnaire: "Questionnaire",
    review: "Review & Submit",
}

export const finalizeTitleOrders = [
    finalizeTitles.loanTerms,
    finalizeTitles.verification,
    finalizeTitles.signature,
]

export const applicationTitleOrders = [
    applicationTitles.basic,
    applicationTitles.questionnaire,
    applicationTitles.review,
]

export function ISLHeader({
    hide,
    currentPosition,
    isFinalize,
}: ISLHeaderProps) {
    const titleOrders = isFinalize
        ? finalizeTitleOrders
        : applicationTitleOrders
    return (
        <FinalizeProgressBarContainer hide={hide}>
            {hide && <></>}
            {!hide && (
                <>
                    {titleOrders.map((title, index) => {
                        const isFinalSection = titleOrders.length - 1 === index
                        return (
                            <Fragment key={title}>
                                <Container
                                    width={
                                        !isFinalSection
                                            ? "calc((100% - 32px) / 3 + 16px)"
                                            : "calc((100% - 32px) / 3)"
                                    }
                                    row
                                >
                                    <Container
                                        width={
                                            !isFinalSection
                                                ? "calc(100% - 16px)"
                                                : "100%"
                                        }
                                    >
                                        <HidableP
                                            textAlign="center"
                                            textSize="13px"
                                            textColor="black"
                                        >
                                            {title}
                                        </HidableP>
                                        <Spacer sm />
                                        <Bar
                                            completed={currentPosition >= index}
                                        />
                                    </Container>
                                    {!isFinalSection && <Spacer />}
                                </Container>
                            </Fragment>
                        )
                    })}
                </>
            )}
        </FinalizeProgressBarContainer>
    )
}
