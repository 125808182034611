import { Container, H2, P, Spacer } from "@wetradeup/ui-kit"
import { ContentContainer } from "../ModalComponents"
import { Falling } from "./icons/Falling"

export function FraudAlert() {
    return (
        <Container
            paddingMd
            width={[
                [550, "sm"],
                [550, "md"],
                [750, "lg"],
            ]}
        >
            <ContentContainer justifyContent="center" alignItems="center">
                <Spacer lg />
                <Falling />
                <Spacer lg />
                <H2 bold="light">
                    {" "}
                    There is a fraud alert on your credit report. Fynn will
                    reach out shortly to verify your information.
                </H2>
                <Spacer lg />
                <P textAlign="center">
                    We will attempt to contact you at the phone number you
                    provided within the next 48 hours.
                </P>
                <Spacer lg />
            </ContentContainer>
        </Container>
    )
}
