export function CallUs() {
    return (
        <svg
            width="33"
            height="53"
            viewBox="0 0 33 53"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M31.3626 47.4081V47.414C31.239 47.5067 27.414 50.2382 18.9834 51.2331C17.5808 51.4005 16.0476 51.5172 14.3789 51.567C14.3479 51.567 14.317 51.5481 14.3111 51.5172L12.3024 43.1434C12.2964 43.1315 9.87397 38.9346 8.42152 34.7567C8.14938 33.9841 7.91511 33.2056 7.73567 32.4639C7.67985 31.3763 7.73567 29.6208 7.73567 29.5959L7.4386 29.3118H7.43262C7.51337 30.7144 7.03088 31.1719 6.33307 31.1101C5.41793 31.0234 4.14492 30.0475 3.27365 29.1144C2.70444 28.5083 2.31566 27.9211 2.31566 27.6121C2.31566 26.7717 2.79117 26.0799 3.40923 25.3631C3.41521 25.3562 3.41521 25.3562 3.40923 25.3502C2.87789 24.1888 2.18606 22.52 2.34058 21.9817C2.43927 21.6488 2.75428 20.8453 3.74318 20.604C3.81097 20.5851 3.84786 20.5233 3.82991 20.4555C3.50792 19.1636 4.21271 16.7471 4.36125 16.3334C4.441 16.0922 4.54068 15.839 4.65732 15.5489C4.67626 15.511 4.65732 15.4681 4.62043 15.4492C3.64449 15.0355 2.53796 14.4733 1.91392 13.7874C1.53012 13.3667 1.32675 12.8972 1.44438 12.3838C1.7594 11.0121 3.34144 10.9503 10.177 12.3659C12.2166 12.8294 12.9762 12.9341 13.9901 13.3976C15.0039 13.8612 19.8118 16.3025 21.3819 17.761C21.6969 18.051 22.0797 18.4907 22.4944 19.016H22.5004C22.4874 14.226 22.5931 13.4405 22.8832 12.5762C23.1554 11.7478 23.6867 10.9005 24.4842 10.6104C24.4652 10.6163 24.4533 10.6223 24.4473 10.6223C24.4782 10.5984 25.5409 10.2395 25.7761 11.1238C25.807 11.2474 25.8997 12.6809 26.147 13.9419C26.4371 15.4183 26.7959 15.9875 27.105 16.8588C27.1907 17.1 27.7599 20.1275 27.8646 20.8573C27.8646 20.8573 28.0929 21.3088 28.378 22.1552C29.5523 25.5914 29.7926 30.0106 29.9291 37.538C29.9471 38.502 29.96 39.5098 29.978 40.5785C30.0029 40.727 30.0278 40.8566 30.0468 40.9742C30.3249 42.6799 30.1574 41.7897 31.3626 47.4081Z"
                fill="#EAEEEF"
                stroke="#344F5B"
                stroke-width="2"
            />
            <path
                opacity="0.3"
                d="M6.33899 14.2509V18.0021C6.33899 18.0081 6.33301 18.0081 6.32603 18.0081L4.36119 16.3334V16.3274C4.44094 16.0862 4.54062 15.8329 4.65726 15.5419C4.67022 15.505 4.65726 15.4621 4.62037 15.4491C3.65041 15.0414 2.54986 14.4792 1.92582 13.7993C1.91984 13.7933 1.92582 13.7874 1.9318 13.7874C2.98251 13.9728 4.59545 14.2569 6.05488 14.2509C6.14061 14.2509 6.23332 14.2509 6.33301 14.2569C6.33301 14.2439 6.33899 14.2439 6.33899 14.2509Z"
                fill="#5E767C"
            />
            <path
                opacity="0.3"
                d="M3.60791 25.7518C3.66972 25.8814 3.72455 25.999 3.78037 26.1107C4.10236 26.7656 7.30931 26.3828 7.1548 23.453C7.1548 23.453 7.01922 22.483 6.8029 22.0933L3.60791 25.7518Z"
                fill="#5E767C"
            />
            <path
                d="M14.7067 20.1345C14.6698 20.1894 14.608 20.2272 14.5462 20.2272C14.5153 20.2272 14.4784 20.2203 14.4535 20.2023C13.02 19.349 10.1271 17.2974 9.79311 17.0562C9.71934 17.0382 9.53392 17.0004 9.27474 16.9455C9.16309 16.9206 9.03349 16.8897 8.89692 16.8588C7.81531 16.6116 5.91227 16.1361 4.79378 15.7214C4.69509 15.6845 4.65122 15.5798 4.68213 15.4801C4.71901 15.3814 4.82468 15.3326 4.92337 15.3695C6.06081 15.7891 8.03163 16.2776 9.08932 16.5189C9.23087 16.5498 9.35448 16.5807 9.46614 16.6056C9.73828 16.6674 9.89878 16.6983 9.90476 16.7043C9.92968 16.7103 9.95461 16.7162 9.97255 16.7352C10.0035 16.7601 13.1366 18.9911 14.6389 19.8873C14.7316 19.9302 14.7625 20.0478 14.7067 20.1345Z"
                fill="#EAEEEF"
                stroke="#344F5B"
                stroke-width="2"
            />
            <path
                opacity="0.3"
                d="M6.33908 18.719V31.0852C6.33908 31.0922 6.3331 31.0922 6.3331 31.0922C5.42394 30.9995 4.15093 30.0286 3.28564 29.1015C3.28564 29.1015 3.28564 29.0955 3.28564 29.0895C4.15691 28.6818 5.01622 28.1006 5.01622 27.668C5.01622 27.3649 4.99728 26.8764 4.96638 26.3391C4.96638 26.3331 4.9604 26.3331 4.9604 26.3331C4.3862 26.4378 3.89773 26.3391 3.7801 26.0979C3.67543 25.8885 3.55181 25.6283 3.41524 25.3442C3.41524 25.3383 3.41524 25.3383 3.42222 25.3383C3.90371 25.3253 5.10295 22.5071 5.18968 21.605C5.20164 21.4634 5.20164 21.284 5.1827 21.0926C5.1827 21.0856 5.1827 21.0856 5.17672 21.0856C4.67031 21.0856 4.20676 20.9131 3.90371 20.616C3.89773 20.6101 3.90371 20.6101 3.90371 20.6041C4.52177 20.4057 5.54158 19.9302 6.32014 18.707C6.3331 18.713 6.33908 18.713 6.33908 18.719Z"
                fill="#5E767C"
            />
            <path
                d="M6.17896 13.0455H6.33946C6.78407 13.0455 6.78407 9.5166 6.33946 9.5166H6.17896C6.1042 9.5166 6.04239 9.57841 6.04239 9.65218V12.909C6.03641 12.9837 6.09822 13.0455 6.17896 13.0455Z"
                fill="#EAEEEF"
                stroke="#344F5B"
                stroke-width="2"
            />
            <path
                d="M6.17896 8.99718H6.33946C6.90768 8.99718 6.90768 7.44604 6.33946 7.44604H6.17896C6.1042 7.44604 6.04239 7.50785 6.04239 7.58162V8.85463C6.03641 8.93538 6.09822 8.99718 6.17896 8.99718Z"
                fill="#EAEEEF"
                stroke="#344F5B"
                stroke-width="2"
            />
            <path
                d="M6.17896 17.087H6.33946C6.78407 17.087 6.78407 13.5581 6.33946 13.5581H6.17896C6.1042 13.5581 6.04239 13.6199 6.04239 13.6947V16.9515C6.03641 17.0252 6.09822 17.087 6.17896 17.087Z"
                fill="#EAEEEF"
                stroke="#344F5B"
                stroke-width="2"
            />
            <path
                d="M9.46601 16.6055C9.42912 16.6793 9.36034 16.7909 9.27461 16.9455C8.87885 17.6373 8.05045 19.0588 7.2041 20.2451C6.77743 20.8383 6.15339 21.2031 5.44262 21.2779C5.36187 21.2839 5.28212 21.2898 5.20735 21.2898C4.67602 21.2898 4.17559 21.1104 3.83565 20.7954C3.76188 20.7276 3.75491 20.61 3.82967 20.5362C3.89746 20.4614 4.01509 20.4555 4.08886 20.5292C4.39789 20.8203 4.89234 20.9689 5.40573 20.913C6.01084 20.8512 6.53619 20.5422 6.90105 20.0348C7.70453 18.9103 8.48907 17.5755 8.89679 16.8647C8.97754 16.7291 9.03934 16.6115 9.08919 16.5308C9.23074 16.5497 9.35436 16.5806 9.46601 16.6055Z"
                fill="#EAEEEF"
                stroke="#344F5B"
                stroke-width="2"
            />
            <path
                d="M6.67166 25.7758H6.58693C4.91716 26.7417 3.47966 26.6121 3.22845 25.5564C3.19456 25.389 3.42683 25.2893 3.50758 25.4896H3.51256C4.19542 26.8783 6.85708 25.4857 6.75839 25.0032C6.85708 25.696 6.77035 25.7758 6.67166 25.7758Z"
                fill="#EAEEEF"
                stroke="#344F5B"
                stroke-width="2"
            />
            <path
                d="M22.5371 23.138C22.5371 23.138 22.5062 21.7594 22.4942 20.0288C22.4753 17.5874 22.4873 14.4423 22.6488 13.4783C22.7036 13.1444 22.7784 12.8413 22.8711 12.5692V3.74381C22.8711 2.6313 21.9619 1.72314 20.8504 1.72314H8.35954C7.24702 1.72314 6.33887 2.6313 6.33887 3.74381V14.2439V18.0141V18.6999V31.0981V32.723C6.33887 33.8355 7.24702 34.7447 8.35954 34.7447H8.42134H19.1996C19.5584 32.5625 19.9602 30.2697 20.1825 29.4293C20.652 27.7107 22.3457 23.6255 22.5371 23.138Z"
                fill="#EAEEEF"
                stroke="#344F5B"
                stroke-width="2"
            />
            <path
                d="M22.5371 23.1381C22.5371 23.1381 22.5062 21.7594 22.4943 20.0288C22.4813 18.379 22.4813 16.4141 22.5371 15.0105V3.74384C22.5371 2.81675 21.7765 2.05713 20.8504 2.05713H8.35957C7.43247 2.05713 6.67285 2.81675 6.67285 3.74384V32.73C6.67285 33.6561 7.43247 34.4167 8.35957 34.4167H19.2554C19.6013 32.3402 19.9722 30.2388 20.1825 29.4353C20.6521 27.7107 22.3457 23.6255 22.5371 23.1381Z"
                fill="#EAEEEF"
                stroke="#344F5B"
                stroke-width="2"
            />
            <path
                opacity="0.3"
                d="M7.00586 32.7298V3.74363C7.00586 2.99597 7.61196 2.39087 8.35962 2.39087"
                stroke="#344F5B"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M21.8762 24.7758V5.25793C21.8762 5.25195 21.8762 5.25195 21.8692 5.25195H7.35268C7.3457 5.25195 7.3457 5.25195 7.3457 5.25793V30.9494C7.3457 30.9554 7.3457 30.9554 7.35268 30.9554H19.8545C19.9781 30.2945 20.0958 29.7631 20.1825 29.4352C20.4855 28.3286 21.289 26.2521 21.8762 24.7758Z"
                fill="#EAEEEF"
                stroke="#344F5B"
            />
            <path
                d="M18.4577 39.6334C18.4517 39.6334 18.4397 39.6334 18.4267 39.6334C18.3281 39.6205 18.2543 39.5218 18.2722 39.4231C18.2782 39.3981 18.6181 37.1612 19.0019 34.7936C19.0199 34.6949 19.1126 34.6271 19.2123 34.6391C19.311 34.658 19.3788 34.7507 19.3668 34.8494C18.977 37.21 18.6371 39.454 18.6371 39.4719C18.6251 39.5646 18.5504 39.6334 18.4577 39.6334Z"
                fill="#EAEEEF"
                stroke="#344F5B"
                stroke-width="2"
            />
            <path
                d="M16.2827 4.02786H12.9273C12.8096 4.02786 12.7109 3.92917 12.7109 3.81154C12.7109 3.6949 12.8096 3.59521 12.9273 3.59521H16.2827C16.4004 3.59521 16.4991 3.6949 16.4991 3.81154C16.4991 3.93515 16.4004 4.02786 16.2827 4.02786Z"
                fill="#EAEEEF"
                stroke="#344F5B"
                stroke-width="2"
            />
            <path
                opacity="0.3"
                d="M21.4866 25.7708C22.0309 24.3742 22.5254 23.1749 22.5433 23.138C22.5433 23.0942 22.5184 21.7414 22.5064 20.0477C22.5064 20.0417 22.5124 20.0417 22.5184 20.0417C23.1185 20.7087 23.7984 22.0375 24.0765 23.2118C24.3297 24.2874 22.4506 25.2325 21.5055 25.7828C21.4866 25.7828 21.4806 25.7768 21.4866 25.7708Z"
                fill="#5E767C"
            />
            <path
                opacity="0.3"
                d="M29.9662 40.5665C29.9543 39.5028 29.9353 38.49 29.9164 37.532C29.9164 37.525 29.9104 37.525 29.9104 37.525C27.2468 38.1371 23.5822 36.4992 21.8766 33.3042C21.3941 32.408 20.8069 30.5289 20.3564 28.898C20.3564 28.8921 20.3434 28.8921 20.3434 28.898C20.2816 29.0954 20.2258 29.2749 20.1829 29.4354C19.9546 30.2757 19.5589 32.5685 19.2 34.7447C19.2 34.7447 19.2 34.7507 19.194 34.7507H8.43372C8.42773 34.7507 8.42773 34.7567 8.42773 34.7567C9.8742 38.9226 12.2846 43.0936 12.3096 43.1374L14.3173 51.5052C14.3243 51.5361 14.3552 51.561 14.392 51.561C16.0548 51.5112 17.587 51.3936 18.9896 51.2271C18.9896 51.2271 18.9966 51.2271 18.9966 51.2211C20.2756 48.8725 20.6345 47.1977 21.4928 45.6216C22.5066 43.7485 28.2606 42.4576 30.047 40.9553V40.9493C30.0161 40.8446 29.9912 40.714 29.9662 40.5665Z"
                fill="#5E767C"
            />
            <path
                d="M18.4577 39.6333C18.4517 39.6333 18.4397 39.6333 18.4267 39.6333C18.3281 39.6204 18.2543 39.5217 18.2722 39.423C18.3221 39.089 19.5273 31.1599 20.0028 29.3924C20.4604 27.6868 22.1601 23.5637 22.3515 23.1071C22.3455 22.841 22.3205 21.2778 22.3086 19.4855C22.3086 19.3798 22.3883 19.3 22.494 19.3C22.5987 19.3 22.6794 19.3798 22.6794 19.4855C22.6914 21.4383 22.7223 23.1251 22.7223 23.138C22.7223 23.1629 22.7163 23.1869 22.7103 23.2118C22.6914 23.2557 20.8372 27.7486 20.3617 29.4911C19.8922 31.2337 18.6491 39.3981 18.6371 39.4788C18.6251 39.5645 18.5504 39.6333 18.4577 39.6333Z"
                fill="#EAEEEF"
                stroke="#344F5B"
                stroke-width="2"
            />
        </svg>
    )
}
