import {
    AuthContext,
    useGetExitCounseling,
    useGetAdminExitCounseling,
} from "@wetradeup/student-apps-shared"
import { useContext } from "react"

export function useProperExitCounseling(match) {
    const { authProfile } = useContext(AuthContext)
    const studentExitCounseling = useGetExitCounseling(
        !match?.params?.accountId && authProfile?.accounts_id
    )
    const adminExitCounseling = useGetAdminExitCounseling(
        match?.params?.accountId
    )

    return match?.params?.accountId
        ? adminExitCounseling
        : studentExitCounseling
}
