import { useTheme } from "@emotion/react"
import { Card, Container, P, Spacer } from "@wetradeup/ui-kit"
import { UnderlineText } from "../../atoms/UnderlineText"
import { ThumbsUp } from "../icons/ThumbsUp"

export const ExclamationCard = () => {
    const theme = useTheme()

    return (
        <Card
            backgroundColor="#1C30BA16"
            disableShadow
            width="100%"
            justifyContent="center"
            alignItems="center"
            row
        >
            <Container alignItems="center" justifyContent="center" fullWidth>
                <ThumbsUp />
            </Container>
            <Spacer />
            <P textColor="medBlue" textSize={theme.sizings.fontSizeInc1}>
                We will{" "}
                <UnderlineText
                    textSize={theme.sizings.fontSizeInc1}
                    textColor="medBlue"
                    bold
                >
                    not
                </UnderlineText>{" "}
                charge your account during verification
            </P>
        </Card>
    )
}
