import { useTheme } from "@emotion/react"
import styled from "@emotion/styled"
import { Anchor, Card, Container, Modal, P, Spacer } from "@wetradeup/ui-kit"
import { SubmissionButton } from "../../application/atoms/SubmissionButton"
import { ContentContainer } from "../../ModalComponents"

interface BankVerificationFailedModalProps {
    showBankVerificationFailed: boolean
    setShowBankVerificationFailed: React.Dispatch<React.SetStateAction<boolean>>
    remainingAttempts: number
}

const StyledList = styled.li`
    ${(props) => `
        list-style: inside;
        list-style: inside;
        list-style-type: disc;
        color: ${props.theme.colors.palette.black};
    `}
`

const StyledUl = styled.ul`
    text-align: left;
`

export function BankVerificationFailedModal({
    showBankVerificationFailed,
    setShowBankVerificationFailed,
    remainingAttempts,
}: BankVerificationFailedModalProps) {
    const theme = useTheme()
    return (
        <Modal visible={showBankVerificationFailed} width="100%">
            <Container
                width={[
                    [700, "md"],
                    [860, "lg"],
                ]}
            >
                <Card paddingMd>
                    <ContentContainer
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Spacer lg />
                        <P
                            bold="light"
                            textAlign="center"
                            textColor="black"
                            textSize={theme.sizings.fontSizeMdLg}
                            lineHeight="120%"
                        >
                            {`We were not able to verify your identity. Please try again with a different bank account. You have ${remainingAttempts} attempts remaining.`}
                        </P>
                        <Spacer lg />
                        <Container justifyContent="center" alignItems="center">
                            <P textColor="black">
                                Here are some common reasons you might be seeing
                                this error:
                            </P>
                            <StyledUl>
                                <StyledList>
                                    <P textColor="black">
                                        your account is not active
                                    </P>
                                </StyledList>
                                <StyledList>
                                    <P textColor="black">
                                        your account is very new
                                    </P>
                                </StyledList>
                                <StyledList>
                                    <P textColor="black">
                                        your account is closed
                                    </P>
                                </StyledList>
                                <StyledList>
                                    <P textColor="black">
                                        you are not the owner of the account you
                                        linked
                                    </P>
                                </StyledList>
                            </StyledUl>
                        </Container>
                        <Spacer lg />
                        <Container maxWidth={500} width="100%">
                            <SubmissionButton
                                width="100%"
                                type="submit"
                                primary
                                onPress={() => {
                                    setShowBankVerificationFailed(false)
                                }}
                                title="Link a different account"
                            />
                        </Container>
                        <Spacer />
                        <Container justifyContent="center" alignItems="center">
                            <P textColor="black">Don't have another account?</P>
                            <P>
                                <Anchor
                                    to="https://www.kqzyfj.com/click-100634244-14415782"
                                    textColor="lightBlue"
                                >
                                    Sign up for a Chime account here!
                                </Anchor>{" "}
                            </P>
                        </Container>
                        <Spacer lg />
                    </ContentContainer>
                </Card>
            </Container>
        </Modal>
    )
}
