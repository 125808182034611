import {
    useUpdateAdminExitCounselingStatus,
    useUpdateExitCounselingStatus,
} from "@wetradeup/student-apps-shared"

export function useProperUpdateExitCounselingStatus(match) {
    const studentUpdateExitCounselingStatus = useUpdateExitCounselingStatus()
    const adminUpdateExitCounselingStatus = useUpdateAdminExitCounselingStatus(
        match?.params?.accountId
    )

    return match?.params?.accountId
        ? adminUpdateExitCounselingStatus
        : studentUpdateExitCounselingStatus
}
