export function RedWarning() {
    return (
        <svg
            width="25"
            height="25"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18.7321 5.54534L18.7455 13.199L13.343 18.6204L5.68929 18.6337L0.267899 13.2312L0.254545 5.57757L5.65706 0.15618L13.3107 0.142826L18.7321 5.54534Z"
                fill="#FF0B04"
            />
            <path
                d="M13.3874 5.51605L5.6123 13.2912"
                stroke="white"
                stroke-width="2"
                stroke-linecap="square"
                stroke-linejoin="round"
            />
            <path
                d="M5.6123 5.51605L13.3874 13.2912"
                stroke="white"
                stroke-width="2"
                stroke-linecap="square"
                stroke-linejoin="round"
            />
        </svg>
    )
}
