import {
    Card,
    Container,
    P,
    Spacer,
    TouchableContainer,
} from "@wetradeup/ui-kit"
import {
    ExitCounseling,
    OnboardedExitCounseling,
} from "@wetradeup/student-apps-shared"
import { useTheme } from "@emotion/react"
import { useState } from "react"
import { UnderlineText } from "../../atoms/UnderlineText"
import { formatPercent } from "../../../utils"

interface TermsProps {
    loanOrientationData: ExitCounseling | OnboardedExitCounseling
    isOnboarded: boolean
}

const keyTerms = [
    {
        title: "Initial Loan Amount",
        content:
            "Your initial loan amount is the initial size of your loan. It won't ever change, and it should be the exact amount you requested to fund your tuition.",
    },
    {
        title: "Current Balance",
        content:
            "Your current balance is the outstanding portion of your loan, including all accrued interest, payments you've made, and any fees. When your current balance is $0, you've paid off your loan!",
    },
    {
        title: "Interest Rate",
        content:
            "The interest rate is the amount Fynn charges you as a borrower and is a percentage of the principal balance.",
    },
    {
        title: "Loan Term",
        content: "This is the expected length of your loan in months.",
    },
    {
        title: "Accrued Interest and Fees",
        content:
            "The finance charge, or the total amount of interest that has already been charged on your loan, along with any fees that have been charged.",
    },
]

const termsForDropouts = keyTerms.concat([
    {
        title: "Refund Amount",
        content:
            "If you withdrew from your program before graduation and were owed a partial tuition refund by the school, that amount should be reflected here. (Tuition refunds are sent to Fynn and applied directly to your outstanding loan balance.)",
    },
])

export function LoanOrientationTerms({
    loanOrientationData,
    isOnboarded,
}: TermsProps) {
    const theme = useTheme()
    const [keyTermSectionOpen, setKeyTermSectionOpen] = useState(false)
    const isDropout = loanOrientationData.refund_amount_to_student > 0
    const currentLoanBalance = isOnboarded
        ? (loanOrientationData as OnboardedExitCounseling).vervent_balance
        : loanOrientationData.note_size
    const currentAccruedInterest = isOnboarded
        ? (loanOrientationData as OnboardedExitCounseling)
              .accrued_interest_and_fees
        : 0
    const currentPreviousPayments = isOnboarded
        ? (loanOrientationData as OnboardedExitCounseling).previous_payments
        : 0
    const keyTermsToShow = isDropout ? termsForDropouts : keyTerms

    return (
        <>
            <Card border="black" disableShadow width="100%" paddingLg>
                <P
                    textAlign="left"
                    textSize={theme.sizings.fontSizeInc2}
                    bold
                    textColor="black"
                >
                    Your loan details
                </P>
                <Spacer lg />
                <Container
                    row
                    justifyContent="space-between"
                    alignItems="end"
                    wrap
                    gap="16px"
                    padding={[null, "md"]}
                >
                    <Container>
                        <P textColor="black">Current Balance</P>
                        <Spacer sm />
                        <P
                            textSize={theme.sizings.fontSizeXl}
                            bold
                            textColor="newBlue"
                        >
                            ${currentLoanBalance.toLocaleString()}
                        </P>
                    </Container>
                    <Container
                        row
                        justifyContent="space-between"
                        alignItems="end"
                        gap="32px"
                    >
                        <Container>
                            <P
                                textColor="black"
                                textSize={theme.sizings.fontSizeXxs}
                                lineHeight="100%"
                            >
                                Interest Rate
                            </P>
                            <Spacer size={4} />
                            <P
                                textSize={theme.sizings.fontSizeXxs}
                                bold
                                textColor="black"
                                lineHeight="100%"
                            >
                                {formatPercent(
                                    loanOrientationData.interest_rate * 100
                                )}
                                %
                            </P>
                        </Container>
                        <Container>
                            <P
                                textColor="black"
                                textSize={theme.sizings.fontSizeXxs}
                                lineHeight="100%"
                            >
                                Loan Term
                            </P>
                            <Spacer size={4} />
                            <P
                                textSize={theme.sizings.fontSizeXxs}
                                bold
                                textColor="black"
                                lineHeight="100%"
                            >
                                {loanOrientationData?.loan_term} months
                            </P>
                        </Container>
                    </Container>
                </Container>
                <Spacer lg />
                <Card
                    row
                    disableShadow
                    justifyContent="flex-start"
                    backgroundColor="medLightGrey"
                    border="medGrey"
                    wrap
                    gap="32px"
                >
                    <Container>
                        <P textColor="black">Initial Loan Amount</P>
                        <Spacer sm />
                        <P
                            textSize={theme.sizings.fontSizeMdLg}
                            bold
                            textColor="black"
                        >
                            ${loanOrientationData.note_size.toLocaleString()}
                        </P>
                    </Container>
                    <Container>
                        <P textColor="black">Accrued Interest</P>
                        <Spacer sm />
                        <P
                            textSize={theme.sizings.fontSizeMdLg}
                            bold
                            textColor="black"
                        >
                            ${currentAccruedInterest.toLocaleString()}
                        </P>
                    </Container>
                    <Container>
                        <P textColor="black">Previous Payments</P>
                        <Spacer sm />
                        <P
                            textSize={theme.sizings.fontSizeMdLg}
                            bold
                            textColor="black"
                        >
                            ${currentPreviousPayments.toLocaleString()}
                        </P>
                    </Container>
                    {isDropout && (
                        <Container>
                            <P textColor="black">Refund Amount</P>
                            <Spacer sm />
                            <P
                                textSize={theme.sizings.fontSizeMdLg}
                                bold
                                textColor="black"
                            >
                                $
                                {loanOrientationData.refund_amount_to_student.toLocaleString()}
                            </P>
                        </Container>
                    )}
                </Card>
                <Spacer lg />
                <Container padding={[null, "sm"]}>
                    <P textColor="black">
                        Loan origination may be subject to final confirmation of
                        items like enrollment, tuition amount, and ID
                        verification. Some of these amounts may be estimates
                        until your loan is onboarded to our servicing arm.
                    </P>
                </Container>
                <Spacer lg />
                <Card border="medGrey" disableShadow width="100%" paddingLg>
                    <Container flex row justifyContent="space-between">
                        <P
                            textAlign="left"
                            textSize={theme.sizings.fontSizeInc2}
                            bold
                            textColor="black"
                        >
                            Key terms explained
                        </P>
                        <TouchableContainer
                            onPress={() => {
                                setKeyTermSectionOpen(!keyTermSectionOpen)
                            }}
                        >
                            <UnderlineText>
                                {keyTermSectionOpen ? "Collapse" : "Expand"}
                            </UnderlineText>
                        </TouchableContainer>
                    </Container>
                    {keyTermSectionOpen && (
                        <>
                            <Spacer lg />
                            {keyTermsToShow.map((term, ind) => (
                                <>
                                    <Card
                                        backgroundColor="medLightGrey"
                                        border="medGrey"
                                        disableShadow
                                        width="100%"
                                    >
                                        <P
                                            textAlign="left"
                                            textSize={
                                                theme.sizings.fontSizeInc2
                                            }
                                            bold
                                            textColor="black"
                                        >
                                            {term.title}
                                        </P>
                                        <Spacer lg />
                                        <P textColor="black">{term.content}</P>
                                    </Card>
                                    {ind < keyTermsToShow.length - 1 && (
                                        <Spacer />
                                    )}
                                </>
                            ))}
                        </>
                    )}
                </Card>
            </Card>
        </>
    )
}
