import { AuthContext, SCHOOL_PORTAL_URL } from "@wetradeup/student-apps-shared"
import { useContext } from "react"
import { Route, Redirect } from "react-router-dom"
import { SpinningLoader } from "./application/atoms/SpinningLoader"
import { useFynnLocation } from "./hooks/useFynnLocation"

const exitCounselingRoles = ["exit_counseling", "fynn_admin"]
export function AuthRoute(authProps) {
    const { authProfile, authLoaded } = useContext(AuthContext)
    const location = useFynnLocation()

    const elt = authProps.component ? (
        <authProps.component />
    ) : (
        authProps.children
    )

    if (authLoaded) {
        const component = (() => {
            if (
                location.pathname.includes("loan-orientation") &&
                !exitCounselingRoles.includes(authProfile?.role)
            ) {
                return <Redirect to="/loan-orientation" />
            }

            if (
                location.pathname.includes("loan-orientation") &&
                location.pathname.includes("admin") &&
                authProfile?.role !== "fynn_admin"
            ) {
                return <Redirect to="/loan-orientation" />
            }

            if (!authProfile) return <Redirect to="/login" />

            if (authProfile.role === "school") {
                window.location.href = SCHOOL_PORTAL_URL
            } else {
                return elt
            }
        })()

        return (
            <Route {...authProps} path={authProps.to}>
                {component}
            </Route>
        )
    } else {
        return <SpinningLoader />
    }
}
