import styled from "@emotion/styled"
import { ApplicationButton } from "../../atoms/ApplicationButton"

export const SubmissionButton = styled(ApplicationButton)<{ width?: string }>`
    ${(props) =>
        props.disabled &&
        `background-color: ${props.theme.colors.palette.grey};`}
    width: ${(props) => props.width || "200px"};
    max-width: 100%;
`
