import React, { useEffect, useMemo, useState } from "react"
import {
    BackgroundImage,
    Container,
    H3,
    H4,
    Input,
    P,
    Spacer,
} from "@wetradeup/ui-kit"
import { baseFetch } from "@wetradeup/student-apps-shared"
import { useFynnLocation } from "../hooks/useFynnLocation"
import { ApplicationButton } from "../atoms/ApplicationButton"

export default function Soon() {
    const location = useFynnLocation()
    const [schoolName, programName] = useMemo(() => {
        const params = new URLSearchParams(location.search)
        return [params.get("school"), params.get("program")]
    }, [location.search])

    const [emailAddress, setEmailAddress] = useState("")
    const [contactSubmitted, setContactSubmitted] = useState(false)
    const [emailInvalid, setEmailInvalid] = useState(false)
    const [showError, setShowError] = useState(false)

    useEffect(() => {
        setEmailInvalid(false)
    }, [emailAddress])

    const onContactSubmitted = async (evt) => {
        evt.preventDefault()
        const body = {
            email: emailAddress,
            ...(schoolName && { school_interested_in: schoolName }),
            ...(programName && { program_interested_in: programName }),
        }
        if (!/\S+@\S+\.\S+/.test(emailAddress)) {
            setEmailInvalid(true)
            return
        }

        try {
            const res = await baseFetch("https://api.wetradeup.com/contacts", {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    "Content-Type": "application/json",
                },
            })

            if (res.ok) {
                setContactSubmitted(true)
            } else {
                throw res.status
            }
        } catch {
            setShowError(true)
        }
    }

    const buttonDisabled =
        contactSubmitted ||
        emailInvalid ||
        !emailAddress.length ||
        !/\S+@\S+\.\S+/.test(emailAddress)
    return (
        <Container paddingLg maxWidth={["100%", ["md", 900]]}>
            <BackgroundImage
                source={require("../../assets/comingSoon.png")}
                width="700"
                height="100"
                alt="Coming soon!"
            />
            {!contactSubmitted && (
                <>
                    <H3 white>
                        Fynn applications to {schoolName} haven't opened yet.
                    </H3>

                    <Container paddingLg>
                        <H4 white>
                            If you would like to receive an update when we begin
                            accepting applications to this school, please enter
                            your email below.
                        </H4>
                        <Spacer xl />
                        <Input
                            placeholder="yourname@email.com"
                            flex={0}
                            onChange={(evt) =>
                                setEmailAddress(
                                    (evt.target as HTMLInputElement).value
                                )
                            }
                        />
                        <Spacer />
                        <ApplicationButton
                            primary
                            disabled={buttonDisabled}
                            title="Submit"
                            onPress={onContactSubmitted}
                        />

                        {emailInvalid && (
                            <P bold error>
                                Please enter a valid email address!
                            </P>
                        )}

                        {showError && (
                            <P error white>
                                Unfortunately, there seems to be a problem:
                                please try again later
                            </P>
                        )}
                    </Container>
                </>
            )}
            {contactSubmitted && (
                <H4 primary white>
                    Thanks for your interest! We'll be in touch with any
                    updates.
                </H4>
            )}
        </Container>
    )
}
