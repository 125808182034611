import { Container, H2, Spacer, P } from "@wetradeup/ui-kit"
import { useState } from "react"
import { ApplicationCard, ContentContainer } from "../ModalComponents"
import CheckCircle from "../../assets/CheckCircle.png"
import { SubmissionButton } from "../application/atoms/SubmissionButton"
import { HiddenBr } from "../application/atoms/HiddenBr"
import { breakpoints } from "../../constants"
import styled from "@emotion/styled"
import { useContract } from "./hooks/useContract"
import { SpinningLoader } from "../application/atoms/SpinningLoader"

export const ResponsiveImg = styled.img`
    @media (max-width: ${breakpoints.mobileLg}) {
        width: 68px;
    }
    @media (min-width: ${breakpoints.mobileLg}) {
        width: 92px;
    }
`

export default function SchoolConfirmed() {
    const [loading, setLoading] = useState(false)

    const getSignURL = useContract({ setLoading, type: "acceptance_packet" })

    return (
        <Container
            paddingMd
            width={[
                [550, "sm"],
                [550, "md"],
                [750, "lg"],
            ]}
            disableShadow
        >
            <ApplicationCard
                alignItems="center"
                justifyContent="center"
                disableCard
                border
                paddingLg
                width="100%"
            >
                <Spacer />
                <Container justifyContent="center" alignItems="center">
                    <ResponsiveImg src={CheckCircle} alt="Checkmark" />
                    <Spacer />
                </Container>
                <H2 bold="light">
                    Your school has confirmed <HiddenBr />
                    your loan information
                </H2>
                <Spacer lg />
                <ContentContainer justifyContent="center" alignItems="center">
                    <P textAlign="center">
                        {" "}
                        You're ready to review your loan terms and sign your
                        contract.{" "}
                    </P>
                    <Spacer lg />
                    {loading ? (
                        <SpinningLoader />
                    ) : (
                        <SubmissionButton
                            title="Next"
                            onPress={getSignURL}
                            primary
                            type="submit"
                        />
                    )}
                </ContentContainer>
                <Spacer lg />
            </ApplicationCard>
        </Container>
    )
}
