import { useTheme } from "@emotion/react"
import styled from "@emotion/styled"
import { Container, H2, Spacer } from "@wetradeup/ui-kit"
import { RotatingLines } from "react-loader-spinner"
import { UnderlineText } from "../../atoms/UnderlineText"
import { useWindowSize } from "../../hooks/useWindowSize"
import { LicensePending } from "../icons/LicensePending"
import { MobileLicensePending } from "../icons/MobileLicensePending"

const LoadingContainer = styled(Container)`
    position: relative;
    left: -60px;
    top: 220px;
    border: 2px solid #344f5b;
    border-radius: 6px;
    width: 78px;
    height: 78px;
`

const MobileLoadingContainer = styled(Container)`
    position: relative;
    left: 30px;
    top: -10px;
    border: 2px solid #344f5b;
    border-radius: 6px;
    width: 78px;
    height: 78px;
`

function LicenseLoadingSpinner() {
    const { windowSize } = useWindowSize()
    const theme = useTheme()
    const isMobile = windowSize.width <= theme.breakpoints.sm
    return (
        <Container>
            {isMobile ? (
                <>
                    <MobileLoadingContainer
                        textAlign="center"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <RotatingLines
                            strokeColor="#0000004D"
                            strokeWidth="3"
                            animationDuration="0.75"
                            width="60"
                        />
                    </MobileLoadingContainer>
                    <MobileLicensePending />
                </>
            ) : (
                <>
                    <LoadingContainer
                        textAlign="center"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <RotatingLines
                            strokeColor="#0000004D"
                            strokeWidth="3"
                            animationDuration="0.75"
                            width="60"
                        />
                    </LoadingContainer>
                    <LicensePending />
                </>
            )}
        </Container>
    )
}

export function PendingIdentityVerificationReview() {
    return (
        <Container justifyContent="center" alignItems="center">
            <LicenseLoadingSpinner />
            <Spacer lg />
            <Container
                padding={[null, "sm"]}
                justifyContent="center"
                alignItems="center"
            >
                <H2 bold="light">
                    Hang on while we verify your ID, this typically takes
                    between 1 and 3 minutes.
                </H2>
                <Spacer />
                <UnderlineText>
                    Do not close or refresh this window
                </UnderlineText>
            </Container>
        </Container>
    )
}
