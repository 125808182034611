import { useTheme } from "@emotion/react"
import {
    Card,
    Container,
    P,
    SecureLabel,
    Spacer,
    TouchableContainer,
} from "@wetradeup/ui-kit"
import { SpinningLoader } from "../../application/atoms/SpinningLoader"
import { RightArrow } from "../icons/RightArrow"

interface PendingIdentityVerificationProps {
    handleCreateVerificationSession: (e: any) => void
    isLoading: boolean
}

export function PendingIdentityVerification({
    handleCreateVerificationSession,
    isLoading,
}: PendingIdentityVerificationProps) {
    const theme = useTheme()
    return (
        <>
            <Container width="100%">
                <P bold textAlign="left" textColor="black">
                    Step One: Photo ID Verification
                </P>
            </Container>
            <Spacer />
            <TouchableContainer
                onPress={handleCreateVerificationSession}
                width="100%"
            >
                <Card
                    paddingLg
                    disableShadow
                    row
                    border="blue"
                    justifyContent={isLoading ? "center" : "space-between"}
                    alignItems="center"
                >
                    {isLoading && <SpinningLoader size={24} />}
                    {!isLoading && (
                        <>
                            <P
                                textSize={theme.sizings.fontSizeInc2}
                                textColor="black"
                            >
                                Upload Government Issued Photo ID
                            </P>
                            <RightArrow />
                        </>
                    )}
                </Card>
            </TouchableContainer>
            <Spacer lg />
            <Container width="100%">
                <P bold textAlign="left" textColor="medDarkGrey">
                    Step Two: Bank Account Verification
                </P>
            </Container>
            <Spacer />
            <TouchableContainer onPress={() => {}} width="100%">
                <Card
                    paddingLg
                    disableShadow
                    row
                    border="medGrey"
                    justifyContent="space-between"
                >
                    <P
                        textSize={theme.sizings.fontSizeInc2}
                        textColor="medDarkGrey"
                    >
                        Bank Account Verification
                    </P>
                </Card>
            </TouchableContainer>
            <Spacer lg />
            <Container padding={[null, "md"]}>
                <SecureLabel />
            </Container>
        </>
    )
}
