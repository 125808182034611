import { css } from "@emotion/react"
import { breakpoints } from "./constants"

export const hideOnMobile = (theme) => css`
    @media (max-width: ${breakpoints.mobileLg}) {
        display: none;
    }
    margin-bottom: ${theme.sizings.paddingMd};
`

export const hideOnSmDesktop = (theme) => css`
    @media (max-width: ${breakpoints.desktopSm}) {
        display: none;
    }
    margin-bottom: ${theme.sizings.paddingMd};
`
