export const IncomeProtection = (props) => (
    <svg
        width="48"
        height="50"
        viewBox="0 0 48 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M5.70133 31.3616H2.76758C1.69202 31.3616 0.821289 32.2342 0.821289 33.3079V47.4115C0.821289 48.487 1.69204 49.3562 2.76758 49.3562H5.70133C6.77537 49.3562 7.64762 48.487 7.64762 47.4115V33.3079C7.64762 32.2342 6.77537 31.3616 5.70133 31.3616Z"
            fill="black"
        />
        <path
            d="M45.2209 35.208C31.2314 42.597 25.4577 38.7393 24.6727 37.4389C31.1131 37.4389 33.5458 37.3141 33.8703 36.5032C34.1932 35.6941 34.8425 33.423 31.1131 33.0967C27.387 32.7704 21.7389 32.0576 18.6573 31.8979C16.7693 31.7979 14.9726 32.2923 13.6455 33.8305C12.3184 35.3687 9.79884 35.9343 9.79884 35.9343L9.79883 44.5946C9.79883 44.5946 11.2914 44.8282 11.994 45.4145C12.6805 45.9875 13.7923 47.1725 17.2122 47.5821C20.632 47.9916 25.5025 48.7906 29.2491 48.6609C33.9311 48.4977 44.4088 41.6881 46.8396 38.771C49.2709 35.854 46.3156 34.6302 45.2218 35.2081L45.2209 35.208Z"
            fill="black"
        />
        <path
            d="M43.4962 20.5726V3.19042C43.4962 1.73363 42.3159 0.553223 40.8605 0.553223H11.2681C9.81127 0.553223 8.63086 1.73354 8.63086 3.19042V20.5726C8.63086 22.0294 9.81117 23.2098 11.2681 23.2098H40.8615C42.3165 23.2116 43.4968 22.0298 43.4968 20.573L43.4962 20.5726ZM30.501 15.2328C30.3196 16.548 29.5637 17.5671 28.3681 18.1015C28.0585 18.2397 27.7289 18.3412 27.3892 18.4096V20.3027H24.9335V18.4296C23.6947 18.2229 22.4943 17.7368 21.5835 17.1259L22.9556 15.0862C24.286 15.9804 26.3056 16.335 27.3629 15.8604C27.7825 15.6723 27.9988 15.3757 28.0638 14.8996C28.1136 14.5332 28.2485 13.5577 25.2683 12.6769C23.3436 12.1075 22.5642 11.0502 22.2498 10.266C21.8268 9.21205 21.9599 7.99492 22.6244 6.83801C23.1655 5.89218 24.0095 5.4012 24.9337 5.1779L24.933 3.45976H27.3887V5.09161C28.5475 5.23135 29.5 5.55096 29.723 5.63124L28.8972 7.94544C27.8332 7.56934 25.3209 7.07617 24.7549 8.05879C24.4784 8.54341 24.397 9.01276 24.5319 9.3506C24.6902 9.74863 25.1994 10.0931 25.9672 10.3195C29.2871 11.3017 30.8137 12.9552 30.5008 15.2327L30.501 15.2328Z"
            fill="black"
        />
    </svg>
)
