import { Container } from "@wetradeup/ui-kit"
import { WelcomePageMessageLong } from "./WelcomePageMessageLong"
import { WelcomePageMessageShort } from "./WelcomePageMessageShort"
import { useWindowSize } from "../../../hooks/useWindowSize"

export const welcomeMessageBreakpoint = 1280
export const welcomeMessagePeopleBreakpoint = 1200

export function WelcomePageMessage() {
    const { windowSize } = useWindowSize()

    if (windowSize.width > welcomeMessageBreakpoint) {
        return (
            <Container width="100%" height="100%" padding={[null, 70]}>
                <WelcomePageMessageLong />
            </Container>
        )
    }

    return (
        <Container width="100%" height="100%">
            <WelcomePageMessageShort />
        </Container>
    )
}
