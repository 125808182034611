import { Container, H2, P, Spacer, TouchableContainer } from "@wetradeup/ui-kit"
import { SubmissionButton } from "../application/atoms/SubmissionButton"
import { UnderlineText } from "../atoms/UnderlineText"
import { ContentContainer } from "../ModalComponents"
import { Falling } from "./icons/Falling"
import {
    AuthContext,
    useStudentApplicationDetails,
    useUnfreezeStudentCreditProfile,
} from "@wetradeup/student-apps-shared"
import { SpinningLoader } from "../application/atoms/SpinningLoader"
import { addHours, differenceInMinutes, isAfter, subHours } from "date-fns"
import styled from "@emotion/styled"
import { BlueLock } from "./icons/BlueLock"
import { useContext, useEffect, useState } from "react"

interface LockedCreditProfileProps {
    studentApplicationDetails: ReturnType<typeof useStudentApplicationDetails>
}

const UnfreezeButtonContainer = styled(Container)`
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
`
export function LockedCreditProfile({
    studentApplicationDetails,
}: LockedCreditProfileProps) {
    const [remainingTime, setRemainingTime] = useState<number>()
    const [intervalId, setIntervalId] = useState<NodeJS.Timer>()
    const unfreezeStudentCreditProfile = useUnfreezeStudentCreditProfile()
    const { refreshAuth, authProfile } = useContext(AuthContext)

    const handleUnfreezeCreditProfile = async (e) => {
        setIntervalId(undefined)
        await unfreezeStudentCreditProfile.mutateAsync()
        await refreshAuth.mutateAsync(authProfile.refresh_token)
        window.location.reload()
    }

    const isDisabled =
        studentApplicationDetails.data?.unfreeze_credit_last_attempted_at &&
        isAfter(
            new Date(
                studentApplicationDetails.data.unfreeze_credit_last_attempted_at
            ),
            subHours(new Date(), 1)
        )
    const canRetry =
        studentApplicationDetails.data?.unfreeze_credit_attempts < 3

    const message = !canRetry
        ? "Your credit is still frozen, and you’ve maxed out your reattempts. Please contact customer service to move your application forward."
        : studentApplicationDetails.data?.unfreeze_credit_attempts > 0 &&
          canRetry
        ? `Your credit report is still frozen, please make sure you’ve unfrozen your credit score with Experian before clicking the button again. You have ${
              3 - studentApplicationDetails.data?.unfreeze_credit_attempts
          } attempts remaining.`
        : "We are unable to evaluate your application because your credit score has been frozen. Please unfreeze your credit score to continue."

    useEffect(() => {
        if (isDisabled && canRetry && !intervalId) {
            let remainingTime = differenceInMinutes(
                addHours(
                    new Date(
                        studentApplicationDetails.data.unfreeze_credit_last_attempted_at
                    ),
                    1
                ),
                new Date()
            )
            setRemainingTime(remainingTime)
            const interval = setInterval(() => {
                remainingTime -= 1
                if (remainingTime >= 0) {
                    setRemainingTime(remainingTime)
                }
            }, 60000)
            setIntervalId(interval)
        }

        return () => {
            return intervalId ? clearInterval(intervalId) : null
        }
    }, [isDisabled, canRetry, intervalId])
    return (
        <Container
            paddingMd
            width={[
                [550, "sm"],
                [550, "md"],
                [750, "lg"],
            ]}
        >
            <ContentContainer justifyContent="center" alignItems="center">
                <Spacer lg />
                <Falling />
                <Spacer lg />
                <H2 bold="light">{message}</H2>
                <Spacer lg />
                {canRetry && (
                    <>
                        <TouchableContainer
                            onPress={() => {
                                window.open(
                                    "https://www.experian.com/freeze/center.html",
                                    "_blank"
                                )
                            }}
                        >
                            <UnderlineText>
                                How to unfreeze your credit
                            </UnderlineText>
                        </TouchableContainer>
                        <Spacer lg />
                        <UnfreezeButtonContainer
                            backgroundColor={"questionnaireGrey"}
                        >
                            {!unfreezeStudentCreditProfile.isLoading && (
                                <SubmissionButton
                                    width={"320px"}
                                    onPress={handleUnfreezeCreditProfile}
                                    primary
                                    title="I've unfrozen my credit report"
                                    disabled={isDisabled}
                                />
                            )}
                            {isDisabled && (
                                <Container paddingMd display={["inline"]}>
                                    <BlueLock />
                                    <P textColor="blue" bold>
                                        {" "}
                                        This button will unlock in{" "}
                                        {remainingTime} minutes
                                    </P>
                                </Container>
                            )}
                        </UnfreezeButtonContainer>
                        {unfreezeStudentCreditProfile.isLoading && (
                            <SpinningLoader />
                        )}
                        <Spacer lg />
                    </>
                )}
            </ContentContainer>
        </Container>
    )
}
