interface ExitCounselingLockProps {
    color?: string
}

export function ExitCounselingLock({ color }: ExitCounselingLockProps) {
    return (
        <svg
            width="22"
            height="24"
            viewBox="0 0 22 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.6599 10.9073H4.1489C3.08291 10.9073 2.21875 11.7957 2.21875 12.8915V19.8359C2.21875 20.9317 3.08291 21.82 4.1489 21.82H17.6599C18.7259 21.82 19.5901 20.9317 19.5901 19.8359V12.8915C19.5901 11.7957 18.7259 10.9073 17.6599 10.9073Z"
                fill={color || "white"}
                stroke={color || "white"}
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M6.0791 10.9073V6.93907C6.0791 5.62351 6.58749 4.36184 7.49242 3.4316C8.39735 2.50136 9.6247 1.97876 10.9045 1.97876C12.1842 1.97876 13.4116 2.50136 14.3165 3.4316C15.2214 4.36184 15.7298 5.62351 15.7298 6.93907V10.9073"
                stroke={color || "white"}
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
