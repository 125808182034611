import { useEffect, useRef, useState } from "react"
import { useHistory } from "react-router-dom"
import ConfettiGenerator from "confetti-js"
import { Container, H3, Spacer } from "@wetradeup/ui-kit"
import {
    NoteSizeUpdateStatus,
    useInSchoolPaymentOption,
    useStudentApplicationDetails,
    useUpdateBorrowing,
    useUpdateNoteSizeChangeStatus,
} from "@wetradeup/student-apps-shared"
import { LoanPaymentContainer } from "./molecules/LoanPaymentContainer"
import { LoanPaymentInputsContainer } from "./molecules/LoanPaymentInputsContainer"
import styled from "@emotion/styled"
import { breakpoints } from "../../constants"
import { NoInSchoolPaymentModal } from "./molecules/NoInSchoolPaymentModal"
import { useContract } from "./hooks/useContract"
import { useGetFinancing } from "./hooks/useGetFinancing"
import { InSchoolPaymentDisclosure } from "./molecules/InSchoolPaymentDisclosure"
import { minimumInSchoolPaymentAmount } from "../slider/hooks/usePaymentSliderOffset"

const Canvas = styled.canvas`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
`

interface LoanTermsProps {
    studentApplicationDetails: ReturnType<typeof useStudentApplicationDetails>
    isFinancingChanged?: boolean
}

const PaymentTermContainer = styled(Container)`
    flex-direction: row;

    @media (max-width: ${breakpoints.tablet}) {
        flex-direction: column;
    }
`

const LoanTermsFooter = styled(Container)`
    flex-shrink: 0;
`

export default function LoanTerms({
    studentApplicationDetails,
    isFinancingChanged,
}: LoanTermsProps) {
    const [hideCanvas, setHideCanvas] = useState(false)
    const canvasRef = useRef<HTMLCanvasElement>()
    const [showNoInSchoolPaymentModal, setShowNoInSchoolPaymentModal] =
        useState(false)
    const [financingAmount, setFinancingAmount] = useState<number | null>(null)
    const [isBorrowingRequestUpdated, setIsBorrowingRequestUpdated] =
        useState<boolean>(false)
    const [inSchoolPaymentAmount, setInSchoolPaymentAmount] =
        useState<number>(0)
    const [inSchoolPaymentAmountUpdated, setIsInSchoolPaymentAmountUpdated] =
        useState<boolean>(false)
    const updateBorrowingMutation = useUpdateBorrowing()
    const updateInSchoolPaymentMutation = useInSchoolPaymentOption()
    const [inSchoolPaymentAmountError, setInSchoolPaymentAmountError] =
        useState<string>()
    const [financingAmountUpdateError, setFinancingAmountUpdateError] =
        useState<string>()
    const [confirmLoanTermError, setConfirmLoanTermError] = useState<string>("")
    const [isContractLoading, setIsContractLoading] = useState(false)
    const { maxNoteSize, minNoteSize } = useGetFinancing(
        studentApplicationDetails
    )
    const history = useHistory()

    const updateNoteSizeChangeStatusMutation = useUpdateNoteSizeChangeStatus()

    const getSignURL = useContract({
        setLoading: setIsContractLoading,
        type: "acceptance_packet",
    })

    const handlePress = async () => {
        if (!isFinancingChanged && inSchoolPaymentAmount === 0) {
            setShowNoInSchoolPaymentModal(true)
            return
        }

        if (isFinancingChanged) {
            await handleConfirmNewLoanTerms()
        } else {
            await handleConfirm()
        }
    }

    const handleConfirm = async () => {
        try {
            await updateNoteSizeChangeStatusMutation.mutateAsync({
                status: "pending_school_approval" as NoteSizeUpdateStatus,
            })
            history.push("/isl-finalize/identity")
        } catch (error) {
            setConfirmLoanTermError(error?.error)
        }
    }

    const handleConfirmNewLoanTerms = async () => {
        try {
            await updateNoteSizeChangeStatusMutation.mutateAsync({
                status: "approved" as NoteSizeUpdateStatus,
            })

            if (studentApplicationDetails.data?.is_cosigner_ready_for_signing) {
                getSignURL()
            } else {
                history.push("/isl-finalize/awaiting")
            }
        } catch (error) {
            setConfirmLoanTermError(error?.error)
        }
    }

    const { isLoading: loading } = updateNoteSizeChangeStatusMutation

    const handleFinancingAmountChange = async (newAmount: number) => {
        if (isFinancingChanged) return
        setFinancingAmountUpdateError(undefined)
        setIsBorrowingRequestUpdated(true)
        setFinancingAmount(newAmount)

        if (newAmount > maxNoteSize) {
            setFinancingAmountUpdateError(
                `Your financing amount cannot be bigger than $${maxNoteSize}`
            )
        } else if (newAmount < minNoteSize) {
            setFinancingAmountUpdateError(
                `Your financing amount cannot be smaller than $${minNoteSize}`
            )
        }
    }

    const handleFinancingAmountSubmit = async (financingAmount: number) => {
        try {
            const newApplication = await updateBorrowingMutation.mutateAsync({
                borrowing: financingAmount,
            })

            if (
                newApplication.note?.isl_terms?.in_school_payment !==
                inSchoolPaymentAmount
            ) {
                setInSchoolPaymentAmount(
                    newApplication.note?.isl_terms?.in_school_payment
                )
            }
        } catch (error) {
            setFinancingAmount(
                studentApplicationDetails.data?.borrowing_request
            )
            setFinancingAmountUpdateError(error?.error)
        }
    }

    const handleInSchoolPaymentChange = (inSchoolPayment: number | null) => {
        setInSchoolPaymentAmountError(undefined)
        setIsInSchoolPaymentAmountUpdated(true)
        setInSchoolPaymentAmount(inSchoolPayment)

        if (
            inSchoolPayment > 0 &&
            inSchoolPayment < minimumInSchoolPaymentAmount
        ) {
            setInSchoolPaymentAmountError(
                `Your in school payment amount has to be at least $${minimumInSchoolPaymentAmount}.`
            )
            return
        }

        if (
            inSchoolPayment >
            studentApplicationDetails.data?.note?.isl_terms.maximum_payment
        ) {
            setInSchoolPaymentAmountError(
                `Your in school payment cannot be bigger than $${studentApplicationDetails.data?.note?.isl_terms.maximum_payment.toLocaleString()}`
            )
            return
        }
    }

    const disabled =
        updateBorrowingMutation.isLoading ||
        updateInSchoolPaymentMutation.isLoading ||
        !studentApplicationDetails.data?.borrowing_request ||
        !!financingAmountUpdateError ||
        !!inSchoolPaymentAmountError

    useEffect(() => {
        const shouldUpdateBorrowingRequest =
            !isBorrowingRequestUpdated &&
            studentApplicationDetails.data?.borrowing_request
        const shouldUpdateBorrowingRequestFromNoteSize =
            !isBorrowingRequestUpdated &&
            studentApplicationDetails.data?.note?.cash_outflow?.note_size
        if (!isFinancingChanged && shouldUpdateBorrowingRequest) {
            setFinancingAmount(
                studentApplicationDetails.data?.borrowing_request
            )
        }

        if (isFinancingChanged && shouldUpdateBorrowingRequestFromNoteSize) {
            setFinancingAmount(
                studentApplicationDetails.data?.note?.cash_outflow?.note_size
            )
        }
    }, [
        isBorrowingRequestUpdated,
        studentApplicationDetails.data,
        isFinancingChanged,
    ])

    useEffect(() => {
        if (
            !inSchoolPaymentAmountUpdated &&
            studentApplicationDetails.data?.note?.isl_terms?.in_school_payment
        ) {
            setInSchoolPaymentAmount(
                studentApplicationDetails.data?.note?.isl_terms
                    ?.in_school_payment
            )
        }
    }, [inSchoolPaymentAmountUpdated, studentApplicationDetails.data])

    useEffect(() => {
        const confettiSettings = {
            target: "confetti-holder",
            max: "200",
            size: "1",
            animate: true,
            props: ["circle", "square"],
            colors: [
                [46, 56, 232],
                [46, 198, 60],
                [211, 217, 240],
            ],
            clock: "40",
            rotate: true,
            start_from_edge: false,
            respawn: false,
        }
        const confetti = new ConfettiGenerator(confettiSettings)
        confetti.render()

        return () => confetti.clear()
    }, [])

    useEffect(() => {
        setTimeout(function () {
            setHideCanvas(true)
            if (canvasRef && canvasRef.current) {
                canvasRef.current.remove()
            }
        }, 2000)
    }, [hideCanvas])

    return (
        <>
            <Container
                paddingSm
                width={[
                    [550, "sm"],
                    [750, "md"],
                    [750, "lg"],
                ]}
            >
                <Canvas
                    ref={canvasRef}
                    id="confetti-holder"
                    style={{ zIndex: hideCanvas ? 0 : 210 }}
                />
                <Spacer />
                <H3 bold="light">
                    Congrats, you're approved! Customize your loan below*
                </H3>
                <Spacer lg />
                <Container alignItems="center" justifyContent="center">
                    <PaymentTermContainer>
                        <LoanPaymentInputsContainer
                            handleFinancingAmountChange={
                                handleFinancingAmountChange
                            }
                            handleInSchoolPaymentChange={
                                handleInSchoolPaymentChange
                            }
                            handleFinancingAmountSubmit={
                                handleFinancingAmountSubmit
                            }
                            studentApplicationDetails={
                                studentApplicationDetails
                            }
                            financingAmount={financingAmount}
                            inSchoolPaymentAmount={inSchoolPaymentAmount}
                            inSchoolPaymentAmountError={
                                inSchoolPaymentAmountError
                            }
                            financingAmountUpdateError={
                                financingAmountUpdateError
                            }
                            isFinancingChanged={isFinancingChanged}
                        />
                        <Spacer />
                        <LoanPaymentContainer
                            studentApplicationDetails={
                                studentApplicationDetails
                            }
                            isLoading={loading || isContractLoading}
                            disabled={disabled}
                            inSchoolPaymentAmount={inSchoolPaymentAmount}
                            handleConfirmPress={handlePress}
                            confirmLoanTermError={confirmLoanTermError}
                            noSticky={
                                !isFinancingChanged &&
                                showNoInSchoolPaymentModal
                            }
                        />
                    </PaymentTermContainer>
                    <Spacer lg />
                </Container>
                <Spacer lg />
            </Container>
            {!isFinancingChanged && (
                <NoInSchoolPaymentModal
                    open={showNoInSchoolPaymentModal}
                    closeModal={() => setShowNoInSchoolPaymentModal(false)}
                    handleConfirm={handleConfirm}
                    maximumLoanSaving={
                        studentApplicationDetails.data?.note?.isl_terms
                            .maximum_net_loan_amount_saved
                    }
                />
            )}
            <LoanTermsFooter>
                <Spacer />
                <InSchoolPaymentDisclosure
                    inSchoolPaymentAmount={inSchoolPaymentAmount}
                />
            </LoanTermsFooter>
        </>
    )
}
