import { P, Container } from "@wetradeup/ui-kit"
import styled from "@emotion/styled"
import { breakpoints } from "../../../constants"

const DisclaimerContainer = styled(Container)`
    @media (max-width: ${breakpoints.mobileLg}) {
        background-color: ${(props) => props.theme.colors.palette.white};
    }
`

const TextContainer = styled(Container)`
    @media (max-width: ${breakpoints.mobileLg}) {
        & > p {
            color: ${(props) => props.theme.colors.palette.darkGrey};
        }
    }
`

export const FinanceDisclaimer = () => {
    return (
        <DisclaimerContainer
            width="100%"
            justifyContent="center"
            alignItems="center"
            paddingMd
        >
            <TextContainer maxWidth={550}>
                <P textAlign="center">
                    This approval is for a private loan that may only be used to
                    directly fund educational expenses incurred by you for the
                    education program and at the school listed in your loan
                    application. Before your loan is finalized -- and before you
                    accept the final terms and sign your Credit Agreement, your
                    loan amount and payback terms may change based on
                    information provided by the school. The loan cannot be
                    finalized and will not be funded if the school does not
                    confirm your enrollment dates and tuition amounts.
                </P>
            </TextContainer>
        </DisclaimerContainer>
    )
}
