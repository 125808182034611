import styled from "@emotion/styled"
import { Container } from "@wetradeup/ui-kit"
import { welcomeMessagePeopleBreakpoint } from "./WelcomePageMessage"

const FixedToBottomLeftContainer = styled(Container)`
    @media (max-width: ${welcomeMessagePeopleBreakpoint}px) {
        left: -100px;
    }
    position: fixed;
    bottom: 0;
    left: 0;
    width: 480px;
`

export function WelcomePageIcon() {
    return (
        <FixedToBottomLeftContainer>
            <svg viewBox="0 0 380 264" fill="none">
                <path
                    d="M166.859 83.1541C166.859 83.1541 187.761 125.416 191.553 169.584C191.839 172.928 173.031 171.962 173.031 171.962L155.787 83.5922L166.859 83.1541Z"
                    fill="#91B0D7"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M147.853 54.6638C154.498 54.6638 170.65 81.2589 170.65 81.2589L153.076 86.0074C153.076 86.0074 143.577 70.814 144.526 67.4874C145.474 64.1608 147.853 54.6638 147.853 54.6638Z"
                    fill="#91B0D7"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M150.54 74.8469C151.488 75.8127 155.316 59.4747 155.316 59.4747C155.316 59.4747 150.236 54.6636 147.865 54.6636C145.495 54.6636 147.052 71.2878 150.549 74.8469H150.54Z"
                    fill="#181818"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M161.154 95.0307C163.533 100.253 174.453 144.894 174.453 144.894L129.332 147.273L135.029 82.2161C135.029 82.2161 155.528 82.6543 161.154 95.0397V95.0307Z"
                    fill="white"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M150.706 53.2412C150.706 53.2412 148.81 70.8133 149.758 73.1831C150.706 75.5618 158.782 86.4806 161.161 95.0297C163.54 103.579 147.862 98.3563 145.009 92.177C142.156 86.0067 129.34 80.7753 129.814 78.8795C130.288 76.9837 134.563 46.114 134.563 46.114L150.715 53.2412H150.706Z"
                    fill="#EBA17A"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M129.57 43.2718C132.588 43.2718 135.035 40.8255 135.035 37.8079C135.035 34.7903 132.588 32.344 129.57 32.344C126.552 32.344 124.105 34.7903 124.105 37.8079C124.105 40.8255 126.552 43.2718 129.57 43.2718Z"
                    fill="#EBA17A"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M158.064 42.7967C161.082 42.7967 163.529 40.3504 163.529 37.3328C163.529 34.3152 161.082 31.8689 158.064 31.8689C155.046 31.8689 152.6 34.3152 152.6 37.3328C152.6 40.3504 155.046 42.7967 158.064 42.7967Z"
                    fill="#EBA17A"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M132.658 22.846C132.658 22.846 126.961 44.6927 137.407 52.7678C147.853 60.8429 154.034 55.1465 155.93 50.872C157.826 46.5974 161.627 29.0253 160.679 22.855C159.731 16.6846 141.682 14.7799 132.658 22.855V22.846Z"
                    fill="#EBA17A"
                />
                <path
                    d="M141.128 55.1735C149.339 59.4301 154.276 54.6011 155.93 50.8632C157.826 46.5886 161.627 29.0165 160.679 22.8462C159.731 16.6758 141.683 14.7711 132.659 22.8462C132.659 22.8462 128.339 39.3988 134.117 49.0121"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M169.583 7.93917C167.714 3.84348 163.582 0.999756 158.779 0.999756C155.694 0.999756 152.885 2.18017 150.775 4.10282C147.913 5.90027 147.054 8.77082 147.045 11.0333C146.956 11.6324 146.902 12.2405 146.902 12.8665C146.902 12.9738 146.902 13.0722 146.902 13.1706C137.144 9.67402 130.973 15.1469 129.793 21.4156C128.371 29.0167 131.215 42.7882 131.215 42.7882C131.215 42.7882 133.594 42.7882 133.594 42.3143C133.594 37.5658 135.016 26.1641 135.016 26.1641C135.016 26.1641 144.514 30.4386 158.287 29.9646C166.077 29.6964 175.155 16.3809 169.566 7.93917H169.583Z"
                    fill="#91B0D7"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M158.782 24.7419C152.226 24.7419 146.904 19.43 146.904 12.8662L158.782 24.7419Z"
                    fill="#91B0D7"
                />
                <path
                    d="M158.782 24.7419C152.226 24.7419 146.904 19.43 146.904 12.8662"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M140.732 145.368L174.459 143.946C174.459 143.946 194.404 238.451 130.286 320.606C127.907 318.227 103.688 308.256 103.688 308.256C103.688 308.256 144.534 216.604 141.681 196.179"
                    fill="#EBFFFF"
                />
                <path
                    d="M140.732 145.368L174.459 143.946C174.459 143.946 194.404 238.451 130.286 320.606C127.907 318.227 103.688 308.256 103.688 308.256C103.688 308.256 144.534 216.604 141.681 196.179"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M137.152 223.945C137.152 223.945 144.343 233.594 146.436 239.872C151.659 255.539 143.538 284.164 149.763 290.21C157.884 274.882 158.313 251.739 158.313 251.739L142.644 204.245L137.161 223.936L137.152 223.945Z"
                    fill="#181818"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M122.211 181.934C122.211 181.934 148.81 217.543 148.81 241.294C148.81 265.046 154.033 328.672 154.033 328.672C154.033 328.672 184.433 324.871 184.433 323.45C178.261 273.586 186.329 237.494 170.185 184.778"
                    fill="#EBFFFF"
                />
                <path
                    d="M122.211 181.934C122.211 181.934 148.81 217.543 148.81 241.294C148.81 265.046 154.033 328.672 154.033 328.672C154.033 328.672 184.433 324.871 184.433 323.45C178.261 273.586 186.329 237.494 170.185 184.778"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M130.654 193.595C130.654 193.595 131.236 188.588 136.459 186.209C141.682 183.83 151.18 180.986 151.18 180.986L121.729 180.512L130.645 193.595H130.654Z"
                    fill="#181818"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M143.59 35.9123C143.59 35.2554 143.057 34.7229 142.4 34.7229C141.743 34.7229 141.211 35.2554 141.211 35.9123V39.7039C141.211 40.3608 141.743 40.8933 142.4 40.8933C143.057 40.8933 143.59 40.3608 143.59 39.7039V35.9123Z"
                    fill="#181818"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M153.561 35.9123C153.561 35.2554 153.028 34.7229 152.371 34.7229C151.714 34.7229 151.182 35.2554 151.182 35.9123V39.7039C151.182 40.3608 151.714 40.8933 152.371 40.8933C153.028 40.8933 153.561 40.3608 153.561 39.7039V35.9123Z"
                    fill="#181818"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M149.286 32.344C149.286 32.344 155.458 43.7457 148.812 46.1155L149.286 32.344Z"
                    fill="#EBA17A"
                />
                <path
                    d="M149.286 32.344C149.286 32.344 155.458 43.7457 148.812 46.1155"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M144.061 47.0625C144.061 47.0625 146.914 50.8631 151.189 48.4844"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M130.277 55.1374C131.708 56.2105 138.353 67.487 143.576 74.1313L146.903 83.1543L156.876 183.829L109.858 184.777C109.858 184.777 109.331 75.6784 113.633 70.8673C117.935 66.0562 130.286 55.1284 130.286 55.1284L130.277 55.1374Z"
                    fill="#91B0D7"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M109.894 191.736L97.9807 190.484C94.6536 194.284 97.5425 204.559 93.7414 210.256C89.9403 215.952 87.5702 218.805 90.8883 219.753C91.3981 219.896 91.9884 219.762 92.6234 219.431C92.5787 220.262 93.0437 220.826 94.2064 221.157C94.5373 221.255 94.8951 221.228 95.2797 221.112C95.4138 221.756 95.9236 222.176 96.9432 222.391C100.324 223.106 107.515 211.686 107.515 211.686C107.515 211.686 110.842 205.516 110.842 198.389C110.842 192.218 109.894 191.745 109.894 191.745V191.736Z"
                    fill="#EBA17A"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M92.6348 219.431C92.6348 219.431 97.0709 215.005 99.4499 210.73"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M95.4844 221.335C95.4844 221.335 99.9205 216.909 102.3 212.634"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M124.582 59.4126C124.582 59.4126 102.732 79.3545 99.8878 91.2302C97.0348 103.106 81.8394 191.905 83.7354 191.905H115.557L122.202 125.891"
                    fill="#91B0D7"
                />
                <path
                    d="M124.582 59.4126C124.582 59.4126 102.732 79.3545 99.8878 91.2302C97.0348 103.106 81.8394 191.905 83.7354 191.905H115.557L122.202 125.891"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M134.076 48.9683C134.076 48.9683 123.281 50.8999 121.725 61.7919C120.168 72.6839 127.422 86.9651 127.422 86.9651L143.574 74.1415L133.164 58.9571L134.076 48.9772V48.9683Z"
                    fill="#91B0D7"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M138.828 77.4585L146.904 84.5857"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M122.688 100.254C123.162 101.676 125.541 131.123 125.541 131.123L146.442 129.228L143.061 99.0376L122.696 100.254H122.688Z"
                    fill="#91B0D7"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M146.904 139.197L148.568 169.423L125.511 170.773L125.055 140.503L146.904 139.197Z"
                    fill="#91B0D7"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M159.734 145.368L166.389 164.362"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M311.933 62.9867C311.933 62.9867 298.017 54.8311 282.651 70.6683C267.286 86.5055 283.134 122.982 283.134 122.982L332.092 120.586C332.092 120.586 334.015 66.832 311.933 62.9956V62.9867Z"
                    fill="#463E36"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M320.103 76.4267C320.103 76.4267 333.063 99.4627 326.346 106.179C322.956 109.568 320.103 110.015 320.103 110.015L320.586 131.128L302.824 125.369L302.341 107.609C302.341 107.609 292.744 107.609 294.184 99.4537C295.32 93.024 302.341 95.1345 302.341 95.1345C302.341 95.1345 312.904 91.2981 317.223 85.0562C321.543 78.8143 320.103 76.4177 320.103 76.4177V76.4267Z"
                    fill="#E9B080"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M320.58 131.137L332.1 164.251L349.379 152.25L357.536 153.207C357.536 153.207 355.21 190.077 324.417 186.804C293.624 183.523 320.58 131.128 320.58 131.128V131.137Z"
                    fill="#EEEEED"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M320.579 124.896C322.234 124.941 337.858 141.69 334.978 168.089C332.099 194.487 324.416 199.763 324.416 199.763L294.079 201.99C294.079 201.99 291.288 145.527 293.211 140.251C295.134 134.975 302.334 125.37 302.334 125.37L320.57 124.887L320.579 124.896Z"
                    fill="#EEEEED"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M317.221 124.896C317.221 124.896 328.257 157.054 320.584 192.09"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M313.862 160.416C313.862 160.416 311.939 148.898 319.139 146.502C323.781 144.955 328.735 145.062 330.175 149.381C331.955 154.711 332.572 158.02 330.658 158.503C327.269 159.352 327.779 155.623 327.779 155.623C327.779 155.623 326.822 160.899 322.976 165.701C319.13 170.503 313.853 160.425 313.853 160.425L313.862 160.416Z"
                    fill="#E9B080"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M349.381 151.295C349.381 151.295 348.424 141.217 351.304 136.898C354.184 132.578 360.901 131.139 360.901 131.139C360.901 131.139 360.543 150.964 357.538 153.218C355.615 154.658 349.381 151.295 349.381 151.295Z"
                    fill="#E9B080"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M360.908 111.465C360.908 112.905 357.545 153.218 357.545 153.218L373.867 159.459L379.144 115.784L360.908 111.465Z"
                    fill="#EEEEED"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M352.736 144.095C352.736 144.095 352.253 139.776 356.099 138.819C359.936 137.862 365.696 135.939 364.739 139.776C363.853 143.326 358.496 142.655 358.022 146.491"
                    fill="#E9B080"
                />
                <path
                    d="M352.736 144.095C352.736 144.095 352.253 139.776 356.099 138.819C359.936 137.862 365.696 135.939 364.739 139.776C363.853 143.326 358.496 142.655 358.022 146.491"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M329.215 192.091L298.976 191.134C278.817 205.048 296.579 218.972 269.694 290.959C256.797 325.513 251.932 344.23 251.932 344.23L269.211 343.273C269.211 343.273 305.335 274.979 305.21 275.605C298.967 307.762 304.727 338.48 304.727 338.48L324.886 338.963C324.886 338.963 326.505 283.528 328.249 245.853C329.689 214.662 329.206 192.1 329.206 192.1L329.215 192.091Z"
                    fill="#EEEEED"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M314.814 242.964C315.119 244.484 310.978 261.198 308.572 271.276C306.568 279.691 303.295 291.433 303.295 291.433L305.218 276.552L314.814 242.955V242.964Z"
                    fill="#181818"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M302.337 125.378C285.541 137.853 270.658 177.692 279.298 193.047C285.55 204.162 300.522 195.676 304.734 191.607C318.651 178.166 322.97 165.691 322.97 165.691L313.848 160.415L294.171 166.174"
                    fill="#EEEEED"
                />
                <path
                    d="M302.337 125.378C285.541 137.853 270.658 177.692 279.298 193.047C285.55 204.162 300.522 195.676 304.734 191.607C318.651 178.166 322.97 165.691 322.97 165.691L313.848 160.415L294.171 166.174"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M305.225 275.596C305.225 275.596 314.347 244.404 320.581 222.799"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M316.256 91.0659C316.256 90.6659 315.934 90.3416 315.536 90.3416C315.139 90.3416 314.816 90.6659 314.816 91.0659V93.9365C314.816 94.3365 315.139 94.6608 315.536 94.6608C315.934 94.6608 316.256 94.3365 316.256 93.9365V91.0659Z"
                    fill="#181818"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M323.456 89.6267C323.456 89.2266 323.133 88.9023 322.736 88.9023C322.338 88.9023 322.016 89.2266 322.016 89.6267V92.4972C322.016 92.8973 322.338 93.2216 322.736 93.2216C323.133 93.2216 323.456 92.8973 323.456 92.4972V89.6267Z"
                    fill="#181818"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M319.137 86.9792C319.137 87.4621 320.094 91.7814 322.974 93.2211C325.854 94.6609 324.897 97.0575 324.414 98.9801"
                    fill="#E9B080"
                />
                <path
                    d="M319.137 86.9792C319.137 87.4621 320.094 91.7814 322.974 93.2211C325.854 94.6609 324.897 97.0575 324.414 98.9801"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M327.779 151.778C327.779 151.778 328.354 153.718 327.779 156.097V151.778Z"
                    fill="#E9B080"
                />
                <path
                    d="M327.779 151.778C327.779 151.778 328.354 153.718 327.779 156.097"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M302.821 118.019L314.34 117.697V124.896L302.338 125.378L302.821 118.019Z"
                    fill="#EEEEED"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M317.221 117.697V124.896H320.575L320.101 117.616L317.221 117.697Z"
                    fill="#EEEEED"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M313.637 156.418C314.343 156.579 325.38 161.856 325.38 161.856L322.983 165.692L313.86 160.416L313.637 156.418Z"
                    fill="#EEEEED"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M349.385 152.251C349.385 152.251 357.068 153.691 357.542 154.174C357.775 154.406 358.284 149.765 358.284 149.765L348.902 147.941L349.385 152.26V152.251Z"
                    fill="#EEEEED"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M285.01 196.374L317.968 164.226"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M333.236 185.929L357.545 154.174"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M289.865 203.125C289.865 203.125 294.185 204.082 297.548 201.203C300.911 198.323 308.102 191.768 308.102 191.768L304.748 191.607C304.748 191.607 297.02 198.162 291.493 198.735C290.831 198.806 289.865 203.125 289.865 203.125Z"
                    fill="#181818"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M275.458 254.643C275.458 254.643 272.39 248.938 271.076 250.261C269.761 251.585 272.829 256.405 272.829 256.405C272.829 256.405 275.458 291.218 283.776 294.893C292.102 298.569 296.503 290.735 296.503 290.735"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M291.521 220.957C291.521 220.957 291.521 233.709 269.064 239.835C246.606 245.96 277.739 214.322 281.308 214.322C284.876 214.322 289.983 218.4 291.512 220.957H291.521Z"
                    fill="#181818"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M255.801 145.445C255.801 145.445 270.872 222.369 275.192 243.411C279.27 263.308 282.338 275.049 282.338 275.049C282.338 275.049 247.636 274.54 219.061 266.375C190.485 258.21 162.42 263.826 162.42 263.826L180.281 168.919L255.81 145.445H255.801Z"
                    fill="#E7F9F8"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M170.621 222.531C170.621 222.531 185.378 234.219 193.034 232.689C200.69 231.16 174.038 205.012 174.038 205.012L170.621 222.531Z"
                    fill="#181818"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M293.563 225.044L289.484 220.966L286.935 214.84L276.73 221.476C276.73 221.476 278.77 233.208 278.77 234.746C278.77 236.285 279.011 244.834 285.916 241.382C286.935 240.872 293.062 238.833 294.591 235.766C295.423 234.103 295.548 232.985 295.307 232.162C296.925 232.323 298.401 231.76 298.678 230.66C299.188 228.621 296.639 228.111 293.572 225.044H293.563Z"
                    fill="#945833"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M282.34 238.305C282.34 238.305 278.262 232.689 280.811 231.16C283.744 229.398 286.427 236.776 286.427 236.776"
                    fill="#945833"
                />
                <path
                    d="M282.34 238.305C282.34 238.305 278.262 232.689 280.811 231.16C283.744 229.398 286.427 236.776 286.427 236.776"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M285.903 237.285C285.903 237.285 281.824 231.159 284.373 229.63C287.307 227.868 289.99 235.246 289.99 235.246"
                    fill="#945833"
                />
                <path
                    d="M285.903 237.285C285.903 237.285 281.824 231.159 284.373 229.63C287.307 227.868 289.99 235.246 289.99 235.246"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M288.975 235.757C288.975 235.757 285.406 230.65 287.955 229.121C290.889 227.36 293.062 234.227 293.062 234.227"
                    fill="#945833"
                />
                <path
                    d="M288.975 235.757C288.975 235.757 285.406 230.65 287.955 229.121C290.889 227.36 293.062 234.227 293.062 234.227"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M188.947 230.65C188.947 230.65 162.411 208.196 161.901 186.769C161.391 165.343 190.476 134.214 199.152 125.03C207.827 115.846 219.562 110.23 219.562 110.23L229.766 115.336L232.315 111.259C232.315 111.259 251.196 112.278 262.93 135.243C274.664 158.207 291.505 220.966 291.505 220.966C291.505 220.966 285.378 217.398 277.723 222.495C274.664 224.534 272.106 228.111 272.106 228.111"
                    fill="#E7F9F8"
                />
                <path
                    d="M188.947 230.65C188.947 230.65 162.411 208.196 161.901 186.769C161.391 165.343 190.476 134.214 199.152 125.03C207.827 115.846 219.562 110.23 219.562 110.23L229.766 115.336L232.315 111.259C232.315 111.259 251.196 112.278 262.93 135.243C274.664 158.207 291.505 220.966 291.505 220.966C291.505 220.966 285.378 217.398 277.723 222.495C274.664 224.534 272.106 228.111 272.106 228.111"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M195.077 179.625C183.343 185.75 178.513 229.551 193.037 232.69C211.918 236.768 206.82 173.499 195.077 179.625Z"
                    fill="#E7F9F8"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M229.777 115.845C230.287 118.394 238.98 271.231 238.98 271.231"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M199.958 179.248C200.683 178.613 221.102 148.503 221.102 148.503L238.452 169.42L197.624 231.159L182.312 209.223L199.958 179.248Z"
                    fill="white"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M201.71 179.624C199.161 178.605 194.671 177.755 190.485 183.192C185.378 189.827 186.917 201.05 186.917 201.05L192.023 192.886C192.023 192.886 195.082 196.964 195.592 198.502C196.102 200.04 197.631 201.56 199.67 200.031C199.813 199.924 199.894 199.673 199.921 199.324C200.672 200.174 201.844 200.576 203.248 199.521C203.579 199.271 203.57 198.332 203.293 197.035C204.035 198.046 205.278 198.636 206.816 197.482C207.192 197.196 207.129 196.034 206.745 194.487C207.353 194.639 208.069 194.504 208.865 193.914C210.152 192.948 206.387 181.493 201.718 179.624H201.71Z"
                    fill="#945833"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M203.238 185.749C203.238 185.749 206.154 190.471 206.735 194.486"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M200.17 188.816C200.17 188.816 202.719 193.922 203.282 197.043"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M196.6 190.856C197.109 190.856 200.159 198.118 199.909 199.334"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M209.36 64.3097C209.36 64.3097 198.135 57.6743 190.479 68.9061C182.824 80.129 186.911 87.2741 184.863 96.4581C183.074 104.506 179.756 108.7 179.756 108.7H206.292C206.292 108.7 221.094 66.3486 209.351 64.3097H209.36Z"
                    fill="#E79C79"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M242.533 76.5612C242.533 76.5612 236.407 61.7613 230.799 58.703C225.182 55.6446 210.899 54.1065 207.322 65.3383C203.753 76.5612 214.468 93.4 214.468 93.4L242.533 76.5612Z"
                    fill="#E79C79"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M237.43 70.436C237.43 70.436 228.755 79.1103 220.079 70.436C217.53 79.62 211.914 81.1492 211.914 81.1492L213.953 87.7846C213.953 87.7846 213.05 87.8382 209.356 88.2943C205.278 88.804 204.562 95.2158 207.317 96.9685C212.933 100.537 217.012 94.9297 217.012 94.9297L217.101 95.0012L217.423 111.849L229.256 115.337L235.382 111.768L235.141 101.377C236.733 100.841 238.36 99.9285 239.979 98.4977C248.655 90.8429 237.43 70.436 237.43 70.436Z"
                    fill="#945833"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M237.431 70.436C237.431 70.436 248.655 90.8429 239.98 98.4977C231.304 106.153 222.119 99.0075 222.119 99.0075"
                    fill="#945833"
                />
                <path
                    d="M237.431 70.436C237.431 70.436 248.655 90.8429 239.98 98.4977C231.304 106.153 222.119 99.0075 222.119 99.0075"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M226.707 80.8984C226.707 80.1922 226.137 79.6196 225.433 79.6196C224.729 79.6196 224.158 80.1922 224.158 80.8984V84.9762C224.158 85.6825 224.729 86.255 225.433 86.255C226.137 86.255 226.707 85.6825 226.707 84.9762V80.8984Z"
                    fill="#181818"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M237.418 79.8781C237.418 79.1719 236.847 78.5994 236.144 78.5994C235.44 78.5994 234.869 79.1719 234.869 79.8781V83.9559C234.869 84.6622 235.44 85.2347 236.144 85.2347C236.847 85.2347 237.418 84.6622 237.418 83.9559V79.8781Z"
                    fill="#181818"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M232.836 77.0713C232.836 84.2164 239.472 87.7845 232.326 91.8712L232.836 77.0713Z"
                    fill="#945833"
                />
                <path
                    d="M232.836 77.0713C232.836 84.2164 239.472 87.7845 232.326 91.8712"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M227.221 92.8901C227.221 92.8901 230.28 96.9679 234.877 94.4193"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M164.962 260.76C164.962 260.76 160.365 263.309 163.943 268.415C167.923 274.103 199.664 277.089 210.889 277.089C222.113 277.089 282.331 275.051 282.331 275.051C282.331 275.051 188.431 251.067 164.962 260.76Z"
                    fill="#E7F9F8"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <mask
                    id="mask0_5748_88"
                    maskUnits="userSpaceOnUse"
                    x="162"
                    y="258"
                    width="121"
                    height="20"
                >
                    <path
                        d="M164.962 260.761C164.962 260.761 160.365 263.309 163.943 268.415C167.923 274.103 199.664 277.09 210.889 277.09C222.113 277.09 282.331 275.051 282.331 275.051C282.331 275.051 188.431 251.067 164.962 260.761Z"
                        fill="white"
                    />
                </mask>
                <g mask="url(#mask0_5748_88)">
                    <path
                        d="M171.756 271.518C171.756 271.518 171.085 269.435 186.397 266.877C201.708 264.32 280.565 274.362 280.565 274.362C280.565 274.362 195.582 236.777 185.887 242.893C176.192 249.01 162.92 256.164 162.41 263.81C161.9 271.465 171.756 271.509 171.756 271.509V271.518Z"
                        fill="#181818"
                        stroke="#181818"
                        stroke-width="0.996091"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </g>
                <path
                    d="M193.544 259.23C193.034 260.759 180.271 315.863 180.271 320.46C180.271 325.056 187.418 324.028 187.418 324.028C187.418 324.028 206.298 264.327 206.298 260.759C206.298 259.74 193.544 259.23 193.544 259.23Z"
                    fill="#945833"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M221.607 262.288V324.028L228.753 325.047L233.342 264.327L221.607 262.288Z"
                    fill="#945833"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M253.764 142.378C253.764 142.378 268.557 208.419 273.154 233.708"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M164.961 248.509C180.782 242.383 217.013 248.509 217.013 248.509C217.013 248.509 244.864 253.213 255.516 255.064C257.69 255.439 259.854 255.922 261.983 256.495L278.251 260.76"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M195.074 179.624C190.477 179.115 187.419 179.624 187.928 178.605C191.354 172.9 208.848 140.85 208.848 140.85"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M224.131 108.906L217.012 105.642L217.423 111.848L223.058 115.076C226.912 117.285 231.796 116.328 234.542 112.841L235.382 111.777V106.161L233.593 107.654C230.946 109.863 227.27 110.355 224.131 108.915V108.906Z"
                    fill="#E7F9F8"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M226.709 114.826C227.272 114.826 227.729 114.37 227.729 113.807C227.729 113.244 227.272 112.787 226.709 112.787C226.146 112.787 225.689 113.244 225.689 113.807C225.689 114.37 226.146 114.826 226.709 114.826Z"
                    fill="#181818"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M229.266 109.729L229.776 116.355"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M221.885 270.659L233.342 266.876V264.327L221.607 262.288L221.885 270.659Z"
                    fill="#181818"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M192.738 263.639L205.653 262.592L206.297 260.759L193.543 259.23L192.738 263.639Z"
                    fill="#181818"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M58.7422 84.5402C58.7422 84.5402 119.971 143.194 104.615 156.787C89.2672 170.38 60.3163 133.983 59.8781 126.087C59.4398 118.191 58.7422 84.5312 58.7422 84.5312V84.5402Z"
                    fill="#FBF1DE"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M49.7861 81.2062L58.2469 82.0915C58.2469 82.0915 78.9606 92.3039 90.8021 156.502C101.821 216.23 17.3203 192.156 17.3203 192.156L32.6678 109.268L49.7772 81.1973L49.7861 81.2062Z"
                    fill="#FBF1DE"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M99.7826 335.567C99.7826 335.567 104.925 308.793 103.53 258.822C102.063 205.953 91.286 165.81 91.286 165.81L25.4332 164.344C25.4332 164.344 -14.9567 252.946 7.56365 335.683C9.62965 338.017 41.6125 341.272 59.6073 339.421C76.2337 344.402 99.7736 335.576 99.7736 335.576L99.7826 335.567Z"
                    fill="#350F32"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M19.9766 178.205C19.9766 178.205 31.3172 180.861 40.8691 177.552C50.4121 174.243 48.5786 169.844 48.5786 169.844L21.9352 167.76L19.9766 178.205Z"
                    fill="#181818"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-miterlimit="10"
                />
                <path
                    d="M17.127 222.238L53.1076 176.381"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M21.2831 217.203L20.8359 244.344L47.5957 245.22L49.6795 180.485"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M47.721 81.5989C47.721 81.5989 -9.77834 115.67 1.455 152.093C11.4899 184.644 52.7384 172.16 52.7384 172.16L52.2465 147.434C52.2465 147.434 36.9795 148.426 33.0264 140.825C29.8424 134.709 41.4425 118.031 41.4425 118.031"
                    fill="#FBF1DE"
                />
                <path
                    d="M47.721 81.5989C47.721 81.5989 -9.77834 115.67 1.455 152.093C11.4899 184.644 52.7384 172.16 52.7384 172.16L52.2465 147.434C52.2465 147.434 36.9795 148.426 33.0264 140.825C29.8424 134.709 41.4425 118.031 41.4425 118.031"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M74.6497 198.603L59.6152 340.879"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M95.7042 187.344L89.8281 244.13L103.047 245.596"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <mask
                    id="mask1_5748_88"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="81"
                    width="108"
                    height="94"
                >
                    <path
                        d="M68.7119 93.1265C62.7286 84.3091 58.2478 82.0914 58.2478 82.0914L49.787 81.2061L47.0055 85.7667L47.721 81.5995C47.721 81.5995 -9.77834 115.671 1.455 152.094C5.05933 163.781 12.6884 169.666 20.9882 172.402L21.7752 173.144C32.5435 176.569 52.6222 173.636 52.6222 173.636L53.597 165.802H91.2949C91.2949 165.802 91.4648 166.446 91.7778 167.68C91.8583 164.542 91.599 161.081 90.9461 157.271C95.9188 158.997 100.713 158.774 104.612 155.313C115.98 145.253 85.3741 110.493 68.7119 93.1175V93.1265Z"
                        fill="white"
                    />
                </mask>
                <g mask="url(#mask1_5748_88)">
                    <path
                        d="M8.46518 100.932L6.64062 182.783"
                        stroke="#181818"
                        stroke-width="0.996091"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M33.2837 91.0413L31.3965 174.287"
                        stroke="#181818"
                        stroke-width="0.996091"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M56.3004 85.4348L54.5742 165.149"
                        stroke="#181818"
                        stroke-width="0.996091"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M78.8694 92.0703L77.0449 173.921"
                        stroke="#181818"
                        stroke-width="0.996091"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M84.687 84.2908L-20.4824 88.9767"
                        stroke="#181818"
                        stroke-width="0.996091"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M99.8628 108.238L-5.30664 112.924"
                        stroke="#181818"
                        stroke-width="0.996091"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M109.369 130.729L-6.04102 136.649"
                        stroke="#181818"
                        stroke-width="0.996091"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M103.14 153.756L-2.0293 158.442"
                        stroke="#181818"
                        stroke-width="0.996091"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </g>
                <path
                    d="M110.068 130.721L100.435 153.238C100.435 153.238 93.7631 154.794 89.9799 151.092C89.3359 150.457 87.0195 139.994 87.4041 138.886C90.7312 129.201 105.864 122.109 105.864 122.109L110.068 130.721Z"
                    fill="#E9B080"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M90.6261 108.105C90.6261 108.105 90.7245 143.938 91.127 143.777L102.128 139.404C102.128 139.404 101.851 112.612 101.377 104.305C101.457 103.33 90.6172 108.114 90.6172 108.114L90.6261 108.105Z"
                    fill="white"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M108.21 134.414C109.676 132.08 110.911 129.317 111.107 126.428C111.537 120.106 107.915 117.951 105.518 117.101C101.144 115.545 91.5385 121.161 91.8426 122.619C92.0304 123.513 92.4418 124.041 92.9516 124.309C91.7352 125.15 90.984 125.91 91.0645 126.339C91.4222 128.074 92.6475 128.431 93.7745 128.181C92.7906 128.905 92.2093 129.549 92.2898 129.925C92.6207 131.517 93.6671 131.946 94.7135 131.821C94.5168 132.071 94.4184 132.295 94.4541 132.456C94.9282 134.763 96.9226 134.638 98.2105 133.886C99.4984 133.135 104.113 132.187 104.113 132.187"
                    fill="#E9B080"
                />
                <path
                    d="M108.21 134.414C109.676 132.08 110.911 129.317 111.107 126.428C111.537 120.106 107.915 117.951 105.518 117.101C101.144 115.545 91.5385 121.161 91.8426 122.619C92.0304 123.513 92.4418 124.041 92.9516 124.309C91.7352 125.15 90.984 125.91 91.0645 126.339C91.4222 128.074 92.6475 128.431 93.7745 128.181C92.7906 128.905 92.2093 129.549 92.2898 129.925C92.6207 131.517 93.6671 131.946 94.7135 131.821C94.5168 132.071 94.4184 132.295 94.4541 132.456C94.9282 134.763 96.9226 134.638 98.2105 133.886C99.4984 133.135 104.113 132.187 104.113 132.187"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M100.722 120.401C100.722 120.401 95.821 121.886 92.959 124.309"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M100.965 124.389C100.965 124.389 96.0817 126.365 93.7832 128.18"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M102.187 127.984C101.838 127.671 95.2911 130.836 94.7188 131.829"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M78.2079 158.191C76.5533 155.16 73.7717 150.876 68.5307 149.615C64.0678 148.605 62.0107 148.059 55.4371 147.702C54.1134 147.63 53.067 147.621 52.2441 147.648L52.5125 161.321C57.199 162.358 63.3881 162.43 66.0622 163.145C69.0047 165.739 71.831 167.17 72.7879 165.944C73.092 165.56 73.2619 165.292 72.9847 164.692C73.7449 164.773 74.5588 165.596 75.5247 164.397C75.7215 164.147 75.7931 163.288 75.7036 162.922C76.3028 162.832 76.7411 162.671 76.9915 161.741C77.1167 161.276 76.9021 160.668 76.6696 160.007C76.4728 159.461 78.0826 159.801 78.1989 158.2L78.2079 158.191Z"
                    fill="#E9B080"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M73.106 164.799C73.106 164.799 70.5213 161.785 66.6934 159.38"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M75.5892 163.092C75.5892 163.092 72.7272 159.202 68.9082 156.797"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M76.6265 159.819C76.6265 159.819 73.9881 156.367 70.1602 153.962"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M84.2127 57.0873C84.2127 52.8753 80.8499 49.3878 76.4316 48.6724C78.0147 46.553 78.9448 43.9239 78.9448 41.0801C78.9448 34.0602 73.2476 28.3638 66.2268 28.3638C65.2788 28.3638 64.3486 28.4712 63.4632 28.6679C61.1557 26.316 57.9449 24.8584 54.3853 24.8584C54.3495 24.8584 54.3138 24.8584 54.278 24.8584C48.9028 12.7949 31.3552 18.5002 32.2317 28.5874C22.5814 26.3965 15.5605 36.4837 18.4136 42.4036C18.6193 42.8239 18.4404 43.3426 18.4941 43.7987C18.0379 43.745 17.5729 43.7182 17.0988 43.7182C10.078 43.7182 4.38086 49.4146 4.38086 56.4345C4.38086 60.87 6.65258 64.7779 10.0959 67.0493C10.0959 67.2371 10.078 67.4249 10.078 67.6127C10.078 78.6299 19.0128 87.5635 30.0315 87.5635C41.0502 87.5635 53.4999 78.6299 53.4999 67.6127C53.4999 67.094 53.473 66.5753 53.4373 66.0656C56.5765 64.9388 56.1294 63.0341 58.7767 60.3871C60.8606 58.3035 62.4794 55.9695 63.6242 53.5371C64.4112 53.6981 65.2341 53.7875 66.0748 53.7964C65.6097 54.8069 65.3503 55.9248 65.3503 57.0873C65.3503 60.1546 67.1391 62.8373 69.8222 64.3486C64.8853 64.6169 60.9679 68.3281 60.9679 72.8709C60.9679 77.4137 65.1894 81.4199 70.3946 81.4199C75.5999 81.4199 79.8213 77.5925 79.8213 72.8709C79.8213 69.8036 78.0326 67.1208 75.3494 65.6095C80.2864 65.3413 84.2038 61.6301 84.2038 57.0873H84.2127Z"
                    fill="#DF8737"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-miterlimit="10"
                />
                <path
                    d="M71.7194 65.8512C71.4958 65.1895 71.1739 64.8675 70.7356 64.7513C69.7965 64.5188 69.09 63.7587 69.09 62.7929V54.0023L60.7544 53.126C60.7544 53.126 59.9673 58.9833 53.6173 62.5872C53.2864 61.4247 52.2847 60.5751 51.1041 60.5751C49.6463 60.5751 48.4746 61.8539 48.4746 63.4278C48.4746 64.4115 48.9307 65.2699 49.6284 65.7797C53.4831 66.4056 52.5083 69.9558 52.3473 70.707L45.6484 85.7394L57.5257 85.8646L59.547 76.1888C60.9243 76.5286 62.5073 76.6359 64.2693 76.3676C69.9754 75.4913 67.5695 67.0316 68.6517 68.4714C70.4047 69.3477 72.417 67.9169 71.7194 65.8423V65.8512Z"
                    fill="#E9B080"
                />
                <path
                    d="M55.1241 73.7292C56.6892 75.473 59.9805 77.0379 64.2556 76.3851C69.9618 75.5088 67.5559 67.0491 68.638 68.4889C70.391 69.3652 72.4034 67.9344 71.7057 65.8598C71.4821 65.198 71.1602 64.8761 70.722 64.7598C69.7829 64.5273 69.0763 63.7672 69.0763 62.8014V54.0109L60.7407 53.1345C60.7407 53.1345 59.9537 58.9919 53.6036 62.5957C53.2727 61.4332 52.271 60.5837 51.0904 60.5837C49.6326 60.5837 48.461 61.8624 48.461 63.4363C48.461 64.42 48.9171 65.2785 49.6147 65.7882C49.6147 65.7882 52.2889 66.906 53.1028 67.7198C53.2638 67.8808 52.4409 70.492 52.4409 70.492L45.6348 85.7569L57.512 85.9984L59.5602 76.0632"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-miterlimit="10"
                />
                <path
                    d="M62.5034 66.4413C62.9875 66.4413 63.3799 65.2642 63.3799 63.8122C63.3799 62.3602 62.9875 61.1831 62.5034 61.1831C62.0194 61.1831 61.627 62.3602 61.627 63.8122C61.627 65.2642 62.0194 66.4413 62.5034 66.4413Z"
                    fill="#181818"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-miterlimit="10"
                />
                <path
                    d="M63.3828 69.9563C63.3828 69.9563 64.2593 73.0236 68.2035 72.5854"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-miterlimit="10"
                />
                <path
                    d="M32.2383 28.5776C32.2383 28.5776 42.2553 32.0742 39.1518 41.7053"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-miterlimit="10"
                />
                <path
                    d="M55.5566 36.0726C55.5566 36.0726 57.8373 29.7413 63.4719 28.6682"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-miterlimit="10"
                />
                <path
                    d="M67.3086 49.942C67.3086 49.942 71.3691 51.4801 76.4402 48.6721"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-miterlimit="10"
                />
                <path
                    d="M10.1035 67.041C10.1035 67.041 16.6324 70.3408 21.2832 67.3987"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-miterlimit="10"
                />
                <path
                    d="M25.4414 41.2142C28.8669 39.9891 37.435 47.8228 33.2761 55.415"
                    stroke="#181818"
                    stroke-width="0.996091"
                    stroke-miterlimit="10"
                />
            </svg>
        </FixedToBottomLeftContainer>
    )
}
