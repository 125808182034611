import { Container, P } from "@wetradeup/ui-kit"
import { useEffect, useState } from "react"
import { TailSpin } from "react-loader-spinner"
import { questionMap } from "../../../constants"
import { WrappedFormik } from "../../../utils"
import { ApplicationButton } from "../../atoms/ApplicationButton"

const generateErrorMessage = (invalidQuestions) => {
    let errorMessage =
        "The following questions do not have valid answers or are unanswered: "
    invalidQuestions.forEach((q, i) => {
        errorMessage += questionMap[q] || q
        if (invalidQuestions.length > 1 && i < invalidQuestions.length - 1) {
            errorMessage += ", "
        }
    })
    return errorMessage
}

interface SubmitButtonContainerProps {
    disabled: boolean
    errors: ReturnType<WrappedFormik["formik"]>["errors"]
    handleSubmit: (e: any) => void
    title: string
    fullWidth?: boolean
    isLoading?: boolean
}

export const SubmitButtonContainer = ({
    disabled,
    errors,
    handleSubmit,
    title,
    fullWidth,
    isLoading,
}: SubmitButtonContainerProps) => {
    const [disabledClicks, setDisabledClicks] = useState(0)
    const [disabledError, setDisabledError] = useState("")

    const handleDisabledClick = () => {
        if (disabled) {
            setDisabledClicks(disabledClicks + 1)
        }
    }

    useEffect(() => {
        if (disabled && disabledClicks > 0 && errors) {
            const invalidQuestions = Object.keys(errors)
            if (!invalidQuestions.length || invalidQuestions.length === 0) {
                setDisabledError("")
                return
            }
            const errorMessage = generateErrorMessage(invalidQuestions)
            setDisabledError(errorMessage)
        } else {
            setDisabledError("")
        }
    }, [disabled, disabledClicks, errors])

    return (
        <Container alignItems="center">
            <Container
                minWidth={200}
                onClick={handleDisabledClick}
                {...(fullWidth ? { width: "100%" } : {})}
            >
                <ApplicationButton
                    fullWidth
                    disabled={disabled}
                    type="submit"
                    primary
                    onPress={handleSubmit}
                    title={isLoading ? "" : title}
                >
                    {isLoading && (
                        <TailSpin color="#ffffff" height={24} width={24} />
                    )}
                </ApplicationButton>
            </Container>
            {disabledError && (
                <Container paddingSm>
                    <P error textAlign="center">
                        {" "}
                        {disabledError}{" "}
                    </P>
                </Container>
            )}
        </Container>
    )
}
