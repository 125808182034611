import styled from "@emotion/styled"
import { Container, P, Spacer } from "@wetradeup/ui-kit"
import { Fragment } from "react"
import { breakpoints } from "../../../constants"
import { hideOnSmDesktop } from "../../../styles"

const FinalizeProgressBarContainer = styled.div<{
    hide?: Boolean
    keepHeight?: boolean
}>`
    ${(props) => `
        height: 60px;
        position: relative;
        margin-bottom: 0px;
        display: flex;
        flex-direction: row;
        margin-top: 40px;
        width: 100%;
        max-width: 750px;

        ${!props?.keepHeight && props.hide ? "display: none;" : ""}

        @media (max-width: ${breakpoints.mobileLg}) {
            margin-left: ${props.theme.sizings.paddingLg};
            margin-right: ${props.theme.sizings.paddingLg};
            margin-top: 20px;
        }
    `}
`

const BarContainer = styled(Container)`
    margin-bottom: 20px;
`

const Bar = styled.div<{ completed: boolean; partialComplete: number }>`
    height: 4px;
    background: ${(props) =>
        props.partialComplete
            ? `linear-gradient(to left, ${props.theme.colors.palette.medGrey} ${
                  100 - props.partialComplete
              }%, ${props.theme.colors.palette.newBlue} ${
                  100 - props.partialComplete
              }%)`
            : props.completed
            ? props.theme.colors.palette.newBlue
            : props.theme.colors.palette.white};
    transform: translateY(-8px);
    border-radius: 5px;

    @media (max-width: ${breakpoints.mobileLg}) {
        margin-top: 20px;
    }
`

const HidableP = styled(P)`
    ${(props) => hideOnSmDesktop(props.theme)}
    margin-bottom: 8px;
    line-height: 16px;
`

interface ExitCounselingHeaderProps {
    hide?: boolean
    currentPosition?: number
    partialComplete?: number
    keepHeight?: boolean
}

export const titles = {
    overview: "Your Loan Overview",
    repayment: "Repayment",
    latePayment: "Missed & Late Payments",
    benefits: "Benefits",
    test: "Test Your Knowledge",
}

export const titleOrders = [
    titles.overview,
    titles.repayment,
    titles.latePayment,
    titles.benefits,
    titles.test,
]

export function ExitCounselingHeader({
    hide,
    currentPosition,
    partialComplete,
    keepHeight,
}: ExitCounselingHeaderProps) {
    return (
        <FinalizeProgressBarContainer hide={hide} keepHeight={keepHeight}>
            {hide && <></>}
            {!hide && (
                <>
                    {titleOrders.map((title, index) => {
                        const isFinalSection = titleOrders.length - 1 === index
                        return (
                            <Fragment key={title}>
                                <BarContainer
                                    width={
                                        !isFinalSection
                                            ? "calc((100% - 64px) / 5 + 16px)"
                                            : "calc((100% - 64px) / 5)"
                                    }
                                    row
                                    alignItems="end"
                                    margin
                                >
                                    <Container
                                        width={
                                            !isFinalSection
                                                ? "calc(100% - 16px)"
                                                : "100%"
                                        }
                                    >
                                        <HidableP
                                            textAlign="center"
                                            textSize="13px"
                                            textColor="black"
                                            bold
                                        >
                                            {title}
                                        </HidableP>
                                        <Spacer sm />
                                        <Bar
                                            completed={currentPosition >= index}
                                            partialComplete={
                                                currentPosition === index
                                                    ? partialComplete
                                                    : 0
                                            }
                                        />
                                    </Container>
                                    {!isFinalSection && <Spacer />}
                                </BarContainer>
                            </Fragment>
                        )
                    })}
                </>
            )}
        </FinalizeProgressBarContainer>
    )
}
