import {
    Questionnaire,
    QuestionnaireAnswer,
    RadioQuestionnaireAnswer,
} from "@wetradeup/student-apps-shared"
import { format } from "date-fns"
import HelloSign from "hellosign-embedded"
import { HELLOSIGN_CLIENT_ID } from "./config"
import { FormikConfig, FormikValues, useFormik } from "formik"
import { englishDateFormat } from "./constants"

export class WrappedFormik<Values extends FormikValues = FormikValues> {
    formik(config: FormikConfig<Values>) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return useFormik<Values>(config)
    }
}

export interface StartDateOption {
    label: string
    value: Date
}
// gets previous start date and next 3 upcoming start dates
export const getStartDates = (allStartDates: Date[]): StartDateOption[] => {
    if (!allStartDates || allStartDates.length === 0) return null

    const currentDate = new Date()
    let hasFoundUpcomingStartDate = false
    return allStartDates.map((d) => {
        if (d > currentDate && !hasFoundUpcomingStartDate) {
            hasFoundUpcomingStartDate = true
            return {
                label: `${format(d, englishDateFormat)} (upcoming class)`,
                value: d,
            }
        }

        return {
            label: format(d, englishDateFormat),
            value: d,
        }
    })
}

export const toTop = () =>
    window.scrollTo({ left: 0, top: 0, behavior: "smooth" })

export const toTopMobile = () =>
    window.scrollTo({ left: 0, top: 1, behavior: "smooth" })

export const HellosignClient = new HelloSign({
    clientId: HELLOSIGN_CLIENT_ID,
})

export function getEmpty(
    questionnaire: Questionnaire[],
    answers: QuestionnaireAnswer
): { [value: string]: string } {
    if (!questionnaire || !answers) return {}
    let unanswered = {}
    for (let q of questionnaire) {
        const noAnswer = q.hidden === false && !answers[q.id]
        const noFreeformAnswer =
            q.hidden === false &&
            answers[q.id] &&
            q.style === "radio" &&
            (answers[q.id] as RadioQuestionnaireAnswer)?.value === "freeform" &&
            !(answers[q.id] as RadioQuestionnaireAnswer)?.text
        const emptyArray =
            q.hidden === false &&
            q.style === "multiple_radio" &&
            answers[q.id] &&
            (answers[q.id] as RadioQuestionnaireAnswer[]).length === 0
        if (noAnswer || noFreeformAnswer || emptyArray) {
            unanswered[q.title] = q.id
        }
    }
    return unanswered
}

export function getNonTrailingSlashPathname(pathname: string) {
    return pathname.slice(-1) === "/" ? pathname.slice(0, -1) : pathname
}

export function getIsSignupPage(location) {
    return location.pathname === "/application/intro/account"
}

export function getIsWelcomePage(location) {
    return location.pathname === "/application/intro"
}

export function getIsNonAuthPage(location) {
    return getIsSignupPage(location) || getIsWelcomePage(location)
}

export function getIsMenuVisible(location) {
    return (
        (location.pathname.includes("/application") ||
            location.pathname.includes("/isl-finalize")) &&
        location.pathname !== "/application/intro" &&
        location.pathname !== "/application/intro/account"
    )
}

export const isNumber = (val: any) => typeof val === "number" && val === val

export const formatPercent = (val: number) =>
    Math.round((val + Number.EPSILON) * 100) / 100

export function getErrorMessage(error: unknown) {
    if (error instanceof Error) return error.message
    return String(error)
}
