import { Container, Modal, Spacer, Card, H2, P } from "@wetradeup/ui-kit"
import {
    AuthContext,
    useCancelApplicationForm,
    useCancelMemberApplication,
} from "@wetradeup/student-apps-shared"
import { useContext, useEffect, useState } from "react"
import queryString from "query-string"
import { useHistory } from "react-router-dom"
import { parseISO } from "date-fns"
import { ContentContainer } from "../../ModalComponents"
import { SpinningLoader } from "../atoms/SpinningLoader"
import { HiddenBr } from "../atoms/HiddenBr"
import { CreateAccountForm } from "./IntroForm"
import { WrappedFormik } from "../../../utils"
import { useFynnLocation } from "../../hooks/useFynnLocation"
import { ApplicationButton } from "../../atoms/ApplicationButton"

interface ShowCancelApplicationModalProps {
    appFormId: number
    setAppFormId: React.Dispatch<React.SetStateAction<number>>
    open: Boolean
    toggleOpen: () => void
    isCreateNewAppFormValid: boolean
    isCancellingMemberApp: boolean
    isOnlyCancelling: boolean
    values: CreateAccountForm
    setValues: ReturnType<
        WrappedFormik<CreateAccountForm>["formik"]
    >["setValues"]
    handleSubmit: ReturnType<
        WrappedFormik<CreateAccountForm>["formik"]
    >["handleSubmit"]
}

export function CancelApplicationModal({
    appFormId,
    setAppFormId,
    open,
    toggleOpen,
    setValues,
    isCreateNewAppFormValid,
    isCancellingMemberApp,
    isOnlyCancelling,
    values,
    handleSubmit,
}: ShowCancelApplicationModalProps) {
    const history = useHistory()
    const location = useFynnLocation()
    const [isMutating, setIsMutating] = useState(false)
    const { authProfile } = useContext(AuthContext)
    const cancelAppForm = useCancelApplicationForm(authProfile?.email)
    const cancelMemberAppForm = useCancelMemberApplication()
    const handleCancelPress = (e: any) => {
        e.preventDefault()
        toggleOpen()
    }

    const handleCreateNewApplication = async (e: any) => {
        e.preventDefault()
        setIsMutating(true)

        try {
            if (isCancellingMemberApp) {
                await cancelMemberAppForm.mutateAsync()
            } else {
                await cancelAppForm.mutateAsync({
                    app_form_id: appFormId,
                })
            }

            if (isOnlyCancelling) {
                setIsMutating(false)
                toggleOpen()
                return
            }

            if (isCreateNewAppFormValid) {
                await handleSubmit()
            } else {
                setAppFormId(undefined)
                return history.push("/application/intro")
            }
        } finally {
            setIsMutating(false)
        }
    }

    useEffect(() => {
        const query = queryString.parse(location.search)
        if (
            query.schoolId &&
            query.program &&
            query.campusId &&
            query.startDate
        ) {
            setValues({
                ...values,
                is_creating_new_account: false,
                program: values.program || (query.program as string),
                anticipated_start_date:
                    values.anticipated_start_date ||
                    parseISO(query.startDate as string),
                campus: values.campus || parseInt(query.campusId as string),
            })
        }
    }, [location.search])

    const appliedToTheWrongProgram = location.state?.appliedToTheWrongProgram
    const title = appliedToTheWrongProgram
        ? "Applied to the wrong program"
        : "Are you sure"

    return (
        <Modal visible={open} width="100%">
            <Container
                width={[
                    [550, "md"],
                    [750, "lg"],
                ]}
            >
                <Card paddingMd>
                    <Spacer lg />
                    <H2>{title}</H2>
                    <Spacer lg />
                    <ContentContainer>
                        {!isOnlyCancelling && appliedToTheWrongProgram && (
                            <P>
                                If you have accidentally applied to the wrong
                                program you'll need to create a new application
                                and apply to the correct one. You will only be
                                permitted to create 1 more application in the
                                next 90 days.
                                <HiddenBr /> <HiddenBr />
                                Please make sure you really want to do this.
                            </P>
                        )}
                        {!isOnlyCancelling &&
                            !appliedToTheWrongProgram &&
                            !isCancellingMemberApp && (
                                <P>
                                    Creating a new application will delete any
                                    other applications you currently have.
                                </P>
                            )}
                        {!isOnlyCancelling &&
                            !appliedToTheWrongProgram &&
                            isCancellingMemberApp && (
                                <>
                                    <P>
                                        Because you have already been offered a
                                        loan, or your current application is
                                        under review for acceptance, you will
                                        only be permitted to create 1 more
                                        application in the next 90 days.
                                        <HiddenBr /> <HiddenBr />
                                        Please make sure you really want to do
                                        this.
                                    </P>
                                </>
                            )}
                        <Spacer lg />
                        <Container alignItems="center">
                            {isMutating && <SpinningLoader />}
                            {!isMutating && (
                                <ApplicationButton
                                    fullWidth
                                    primary
                                    onPress={handleCreateNewApplication}
                                    title={
                                        isOnlyCancelling
                                            ? "Yes, cancel my application"
                                            : "Create new application"
                                    }
                                    id="createNewAppConfirmation"
                                />
                            )}
                            <Spacer lg />
                            <ApplicationButton
                                fullWidth
                                onPress={handleCancelPress}
                                outline="blue"
                                title="Cancel"
                                textColor="blue"
                            />
                        </Container>
                    </ContentContainer>
                    <Spacer lg />
                </Card>
            </Container>
        </Modal>
    )
}
