import React, { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Container, H2, P, Spacer, TouchableContainer } from "@wetradeup/ui-kit"
import { AuthContext } from "@wetradeup/student-apps-shared"
import { ContentContainer, FullMobileContainer } from "../../ModalComponents"
import { HiddenBr } from "../atoms/HiddenBr"
import { EmailLoginModal } from "../molecules/modals/EmailLoginModal"
import { useFynnLocation } from "../../hooks/useFynnLocation"
import { ApplicationButton } from "../../atoms/ApplicationButton"
import { UnderlineText } from "../../atoms/UnderlineText"
import styled from "@emotion/styled"

interface ExistingAccountPageProps {
    setFieldValue: (key: string, value: string) => void
}

const InlineP = styled(P)`
    display: inline-block;
`

const InlineTouchable = styled(TouchableContainer)`
    display: inline-block;
`

export default function ExistingAccountPage({
    setFieldValue,
}: ExistingAccountPageProps) {
    const location = useFynnLocation()
    const { authProfile } = useContext(AuthContext)
    const [showLoginModal, setShowLoginModal] = useState(false)
    const [showPasswordResetModal, setShowPasswordResetModal] = useState(false)
    const [loginSubmitted, setLoginSubmitted] = useState(false)
    const history = useHistory()

    const handlePasswordResetPress = (e) => {
        e.preventDefault()
        setShowPasswordResetModal(true)
    }

    const handleSignInPress = (e) => {
        e.preventDefault()
        setShowLoginModal(true)
    }

    useEffect(() => {
        if (loginSubmitted && authProfile) {
            return history.push(`/application/intro/select${location.search}`, {
                disableAutoRouting: true,
            })
        }
    }, [loginSubmitted, authProfile])

    return (
        <>
            <FullMobileContainer
                paddingMd
                justifyContent="center"
                alignItems="center"
            >
                <Spacer lg />
                {location.state?.emailDuplication ? (
                    <H2 bold="light">
                        There is already an account <HiddenBr /> with that email
                        address.{" "}
                    </H2>
                ) : (
                    <H2 bold="light">
                        It looks like you already have an account. You’ll need
                        to log into that account to continue or create a new
                        application.
                    </H2>
                )}
                <Spacer lg />
                <ContentContainer width={400} maxWidth="80vw">
                    <ApplicationButton
                        type="submit"
                        primary
                        onPress={handleSignInPress}
                        title="Log in"
                    />
                    <Spacer lg />
                    <Spacer lg />
                    <Container
                        width="100%"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <P textColor="black">Forgot your password?</P>
                        <Spacer />
                        <ApplicationButton
                            fullWidth
                            type="submit"
                            outline="blue"
                            textColor="blue"
                            onPress={handlePasswordResetPress}
                            title="Reset password"
                        />
                    </Container>
                </ContentContainer>
                <Spacer lg />
                <Container>
                    <InlineP textColor="black" textAlign="center">
                        If you believe this is an error, please email Fynn
                        customer support at{" "}
                        <InlineTouchable
                            onPress={() =>
                                window.open("mailto:help@wetradeup.com")
                            }
                        >
                            <UnderlineText bold textColor="black">
                                help@fynncredit.com
                            </UnderlineText>{" "}
                            or by calling or texting (505)-806-2054.
                        </InlineTouchable>
                    </InlineP>
                </Container>
                <Spacer lg />
            </FullMobileContainer>
            <EmailLoginModal
                visible={showLoginModal || showPasswordResetModal}
                setLoginSubmitted={setLoginSubmitted}
                closeModal={() => {
                    setShowLoginModal(false)
                    setShowPasswordResetModal(false)
                }}
                isInitialResetPassword={showPasswordResetModal}
            />
        </>
    )
}
