import styled from "@emotion/styled"
import {
    Card,
    Container,
    H2,
    P,
    Spacer,
    TouchableContainer,
} from "@wetradeup/ui-kit"
import { RouteComponentProps, useHistory } from "react-router-dom"
import { breakpoints } from "../../constants"
import { CosignerAppIcon } from "./atoms/CosignerAppIcon"
import { SoloAppIcon } from "./atoms/SoloAppIcon"
import { SubmissionButton } from "./atoms/SubmissionButton"
import { useState } from "react"
import { CosignerInviteModal } from "./molecules/modals/CosignerInviteModal"

const ContainerWrapper = styled(Container)`
    @media (max-width: ${breakpoints.mobileLg}) {
        max-width: calc(100vw - 32px);
    }
`

const FlexibleContainer = styled(Container)`
    flex-direction: row;

    @media (max-width: ${breakpoints.mobileLg}) {
        flex-direction: column;
    }
`

interface CosignerSelectionPage {
    appFormId?: number
}
export function CosignerSelectionPage({
    match,
    appFormId,
}: CosignerSelectionPage & RouteComponentProps<{ schoolId?: string }>) {
    const history = useHistory()
    const schoolId = parseInt(match.params.schoolId)
    const [applicationType, setApplicationType] = useState<string>()
    const [open, setOpen] = useState(false)
    const handleNext = () => {
        if (applicationType === "withCosigner") {
            setOpen(true)
        } else {
            history.push(`/application/${schoolId}/questionnaire`, {
                disableAutoRouting: true,
            })
        }
    }
    return (
        <>
            <ContainerWrapper
                paddingMd
                width={[
                    [550, "sm"],
                    [550, "md"],
                    [750, "lg"],
                ]}
                alignItems="center"
            >
                <Spacer />
                <H2 bold="light">Would you like to apply with a co-signer?</H2>
                <Spacer lg />
                <FlexibleContainer flex row>
                    <TouchableContainer
                        flex={1}
                        onPress={() => setApplicationType("withCosigner")}
                        minWidth={200}
                    >
                        <Card
                            disableShadow
                            border={
                                applicationType === "withCosigner"
                                    ? "blue"
                                    : "medBlack"
                            }
                            backgroundColor={
                                applicationType === "withCosigner"
                                    ? "infoBlue"
                                    : "white"
                            }
                            justifyContent="center"
                            alignItems="center"
                            paddingMd
                            height={200}
                        >
                            <CosignerAppIcon />
                            <Spacer />
                            <P textColor="black">Invite a co-signer</P>
                        </Card>
                    </TouchableContainer>
                    <Spacer />
                    <TouchableContainer
                        flex={1}
                        onPress={() => setApplicationType("withoutCosigner")}
                        minWidth={200}
                    >
                        <Card
                            disableShadow
                            border={
                                applicationType === "withoutCosigner"
                                    ? "blue"
                                    : "medBlack"
                            }
                            backgroundColor={
                                applicationType === "withoutCosigner"
                                    ? "infoBlue"
                                    : "white"
                            }
                            justifyContent="center"
                            alignItems="center"
                            paddingMd
                            height={200}
                        >
                            <SoloAppIcon />
                            <Spacer />
                            <P textColor="black">Apply alone</P>
                        </Card>
                    </TouchableContainer>
                </FlexibleContainer>
                <Spacer lg />
                <SubmissionButton
                    onPress={() => handleNext()}
                    disabled={!applicationType}
                    title="Next"
                />
                <Spacer lg />
            </ContainerWrapper>
            <CosignerInviteModal
                open={open}
                closeModal={() => setOpen(false)}
                appFormId={appFormId}
                postSuccessAction={() => {
                    history.push(`/application/${schoolId}/questionnaire`, {
                        disableAutoRouting: true,
                    })
                }}
            />
        </>
    )
}
