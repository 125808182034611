import {
    Card,
    Container,
    Modal,
    Spacer,
    H3,
    CancelIcon,
    P,
    TouchableContainer,
} from "@wetradeup/ui-kit"
import { useHistory } from "react-router-dom"
import { ContentContainer } from "../../../ModalComponents"
import RejectedIcon from "../../../../assets/application/rejected_icon.png"
import styled from "@emotion/styled"
import { useLogout } from "@wetradeup/student-apps-shared"
import { ApplicationButton } from "../../../atoms/ApplicationButton"
import { ClickableCaption } from "../../atoms/ClickableCaption"

const declineReasonMap = {
    non_citizen: " we do not currently lend to non US citizens",
    not_over_18: " we do not lend to individuals under the age of 18",
    not_over_23: " you must be 23 or older to qualify for this program",
    ineligible_state:
        " Fynn does not currently offer funding for the selected program to residents of your state",
    too_far_in_advance:
        " your start date cannot be more than 90 days in the future",
    too_far_in_the_past:
        " it looks like you've entered a start date too far in the past. Please double check that you've entered the correct date",
}

function getDeclineMessage(invalidReasons) {
    if (!invalidReasons || (invalidReasons && invalidReasons.length === 0)) {
        return "Sorry, based on your responses we cannot lend to you."
    }
    let reasons = "Sorry, "
    invalidReasons.forEach((reason, idx) => {
        reasons += declineReasonMap[reason]
        if (idx < invalidReasons.length - 1 && invalidReasons.length > 2) {
            reasons += ","
        } else if (idx === invalidReasons.length - 1) {
            reasons += "."
        }
        if (idx === invalidReasons.length - 2) {
            reasons += " and"
        }
    })
    return reasons
}

function getSubMessage(invalidReasons) {
    return invalidReasons.length === 1 &&
        invalidReasons[0] === "too_far_in_advance"
        ? "Please feel free to re-apply when your start date is 90 days or less in the future! Thank you."
        : null
}

function getChangeMessage(invalidReasons) {
    return invalidReasons.includes("ineligible_state")
        ? "If you'd like to use a different address, please contact Fynn customer support using the chat bubble below, or by calling or texting (505)-806-2054."
        : "If you've misentered your personal information, please contact Fynn customer support using the chat bubble below, or by calling or texting (505)-806-2054."
}

const CancelIconContainer = styled(Container)`
    border-radius: 50%;
    border: ${(props) => `2px solid ${props.theme.colors.palette.errorRed};`}
    padding: 8px;
    svg {
        fill: ${(props) => `${props.theme.colors.palette.errorRed};`}
        stroke-width: 5px;
        width: 16px;
        height:  16px;
    }
`

export function AutomaticRejectionModal({
    open,
    invalidReasons,
}: {
    open: boolean
    invalidReasons: string[]
}) {
    const history = useHistory()
    const logout = useLogout()

    const handlePress = async () => {
        await logout()
        history.push("/application/intro")
    }

    const declineMessage = getDeclineMessage(invalidReasons)
    const subMessage = getSubMessage(invalidReasons)
    const changeMessage = getChangeMessage(invalidReasons)
    const allowedToCancelApp =
        invalidReasons.length === 1 &&
        (invalidReasons.includes("too_far_in_advance") ||
            invalidReasons.includes("too_far_in_the_past"))

    return (
        <Modal visible={open} width="100%">
            <Container
                width={[
                    [700, "md"],
                    [860, "lg"],
                ]}
                overflow
                maxHeight="100vh"
            >
                <Card paddingMd>
                    <ContentContainer
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Spacer lg />
                        <CancelIconContainer>
                            <CancelIcon />
                        </CancelIconContainer>
                        <Spacer />
                        <Container padding={["sm", "lg"]}>
                            <H3 bold="light">{declineMessage}</H3>
                        </Container>
                        <Spacer lg />
                        <Container
                            alignItems="center"
                            justifyContent="center"
                            fullWidth
                        >
                            <img
                                src={RejectedIcon}
                                alt="Application Declined"
                                width={148}
                            />
                        </Container>
                        <Spacer size={48} />
                        {subMessage && (
                            <Container>
                                <P>{subMessage}</P>
                                <Spacer size={48} />
                            </Container>
                        )}
                        <Container width={200}>
                            <ApplicationButton
                                primary
                                onPress={handlePress}
                                title="Exit Application"
                            />
                        </Container>
                        <Spacer size={48} />
                        {allowedToCancelApp ? (
                            <TouchableContainer
                                onPress={() => {
                                    history.push("/application/intro/select")
                                }}
                            >
                                <ClickableCaption>
                                    Return to profile
                                </ClickableCaption>
                            </TouchableContainer>
                        ) : (
                            <P textColor="black" textAlign="center">
                                {changeMessage}
                            </P>
                        )}
                    </ContentContainer>
                </Card>
            </Container>
        </Modal>
    )
}
