import { useCallback } from "react"
import { useHistory } from "react-router-dom"
import {
    authFetch,
    useRecordStudentSignature,
} from "@wetradeup/student-apps-shared"
import { API_URL, CLIENT_ENV } from "../../../config"
import { HellosignClient } from "../../../utils"

interface UseContractProps {
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
    type: "autopay_consent" | "acceptance_packet"
}

export function useContract({ setLoading, type }: UseContractProps) {
    const history = useHistory()
    const recordSignatureMutation = useRecordStudentSignature()

    const getSignURL = useCallback(async () => {
        setLoading(true)
        const response = await authFetch(
            API_URL + `/member-applications/signature?contract_type=${type}`
        )
        const data = await response.json()

        if ("success" in data) {
            history.push("/isl-finalize/complete")
        }

        if ("error" in data) {
            history.push({ pathname: `/server-error` })
        }

        const hellosign_test_mode = CLIENT_ENV !== "production"

        HellosignClient.open(data.sign_url, {
            skipDomainVerification: hellosign_test_mode,
        })

        const onClientClose = async (data) => {
            HellosignClient.close()
            setLoading(true)
            try {
                const data = await recordSignatureMutation.mutateAsync(type)
                if (data && data.status === "success") {
                    if (
                        type === "acceptance_packet" &&
                        !data.autopay_consent_received
                    ) {
                        history.push("/isl-finalize/autopay", {
                            disableAutoRouting: true,
                        })
                    } else {
                        history.push("/isl-finalize/complete", {
                            disableAutoRouting: true,
                        })
                    }
                }
            } catch ({ error }) {
                console.log(error)
            } finally {
                setLoading(false)
            }
        }

        HellosignClient.on("sign", onClientClose)

        setLoading(false)
    }, [])

    return getSignURL
}
