import { Anchor, Container, H5, P, Spacer } from "@wetradeup/ui-kit"
import { ContentContainer } from "../../ModalComponents"
import { CollapsibleContainer } from "./CollapsibleContainer"

export function BankFAQ() {
    return (
        <Container
            width={[
                [550, "sm"],
                [550, "md"],
                [750, "lg"],
            ]}
        >
            <Spacer />
            <H5 weight={600}>
                {" "}
                Common questions about verifying your bank account
            </H5>
            <Spacer />
            <ContentContainer>
                <CollapsibleContainer header="Q: Why does Fynn need my bank account?">
                    <Spacer />
                    <P>
                        We use your bank account to verify your identity and
                        prevent fraud. We will not charge your account any
                        amount at this time, and we will not charge your account
                        in the future without your prior approval.
                    </P>
                </CollapsibleContainer>
                <Spacer lg />
                <CollapsibleContainer
                    header="Q: How does Fynn keep my bank information safe?"
                    collapsedInit
                >
                    <Spacer />
                    <P>
                        We use Plaid to connect to your account. Plaid is the
                        nation's leading provider of bank connection services
                        and uses bank grade security.
                    </P>
                </CollapsibleContainer>
                <Spacer lg />
                <CollapsibleContainer
                    header="Q: What if I don't have a bank account?"
                    collapsedInit
                >
                    <Spacer />
                    <P>
                        Unfortunately this step is mandatory. If you do not have
                        a bank account consider creating a{" "}
                        <Anchor
                            to="https://www.kqzyfj.com/click-100634244-14415782"
                            textColor="blue"
                        >
                            Chime
                        </Anchor>{" "}
                        account. It only takes 5 minutes!
                    </P>
                </CollapsibleContainer>
                <Spacer lg />
                <CollapsibleContainer
                    header="Q: Will I be charged any money?"
                    collapsedInit
                >
                    <Spacer />
                    <P>
                        No. Fynn will not charge your account any money during
                        this step. We use your bank account information for the
                        express purpose of fraud prevention.
                    </P>
                </CollapsibleContainer>
            </ContentContainer>
            <Spacer size={48} />
        </Container>
    )
}
