export function LicensePending() {
    return (
        <svg
            width="450"
            height="224"
            viewBox="0 0 450 224"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M340.913 222.241H39.0526C33.6136 222.241 29.0278 219.01 29.0278 214.943V41.2977C29.0278 37.3333 33.4679 34 39.0526 34H340.913C346.359 34 350.938 37.2305 350.938 41.2977V214.943C350.938 219.01 346.498 222.241 340.913 222.241Z"
                fill="#EAEEEF"
                stroke="#344F5B"
                stroke-width="2"
            />
            <path
                d="M136.205 162.175C135.843 163.15 135.224 164.131 134.599 165.112C134.243 165.823 133.71 166.541 133.084 167.16C132.103 168.319 131.036 169.214 129.699 169.925C125.334 172.151 118.914 171.71 110.091 172.243C95.8345 173.132 87.8143 173.757 82.9152 167.252C82.8232 167.16 82.7373 167.074 82.7373 166.982C77.1208 159.14 76.4095 148.268 76.4095 139.095C76.4095 137.667 76.4095 136.238 76.4095 134.908V134.724C76.4953 131.695 76.4953 128.843 76.4095 126.795V125.367C75.876 119.401 74.4474 95.8736 81.4865 88.9265C83.8042 86.7866 93.4309 83.6656 103.769 84.199C112.322 84.5546 120.962 88.0313 127.29 91.6857C128.718 93.1144 128.804 95.1624 128.804 95.1624L122.391 112.362C122.213 113.251 122.66 115.035 123.372 117.083C125.15 122.338 128.626 129.647 128.896 130.18C130.233 132.498 131.57 134.724 132.728 136.6C133.887 138.47 134.954 140.07 135.665 141.143C138.161 144.884 139.676 154.063 136.205 162.175Z"
                fill="#EAEEEF"
                stroke="#344F5B"
                stroke-width="2"
            />
            <path
                d="M116.774 90.6187C109.827 98.5468 96.6378 112.092 89.9543 116.279C84.6995 119.486 81.045 125.906 78.7273 133.387"
                stroke="#344F5B"
                stroke-width="2"
                stroke-linecap="round"
            />
            <path
                opacity="0.3"
                d="M136.205 162.175C135.843 163.15 135.224 164.131 134.599 165.112C134.243 165.823 133.71 166.54 133.084 167.16C132.103 168.319 131.036 169.214 129.699 169.925C116.51 169.833 85.9442 167.699 82.9152 167.252C82.8232 167.16 82.7374 167.074 82.7374 166.982C77.1208 159.14 76.4095 148.268 76.4095 139.095C76.4095 137.667 76.4095 136.238 76.4095 134.907C80.1498 125.728 85.4108 120.29 85.4108 120.29C91.8245 116.102 106.976 109.952 112.5 105.942C113.837 105.052 118.828 110.933 123.372 117.083C125.15 122.338 128.626 129.646 128.896 130.18C130.233 132.498 131.57 134.723 132.729 136.6C133.887 138.47 134.954 140.07 135.666 141.143C138.161 144.883 139.676 154.063 136.205 162.175Z"
                fill="#5E767C"
            />
            <path
                d="M78.7273 133.387C77.4764 137.667 76.6793 142.302 76.3176 146.931C75.5204 156.466 76.0539 162.438 78.7273 168.674"
                stroke="#344F5B"
                stroke-width="2"
                stroke-linecap="round"
            />
            <path
                d="M116.774 132.32C117.669 152.996 129.607 151.212 130.944 168.767"
                stroke="#344F5B"
                stroke-width="2"
                stroke-linecap="round"
            />
            <path
                d="M93.6086 129.739C93.6086 129.739 86.3917 150.145 93.253 164.756"
                stroke="#344F5B"
                stroke-width="2"
                stroke-linecap="round"
            />
            <path
                d="M123.905 131.609C129.252 138.826 137.542 145.509 135.31 159.943"
                stroke="#344F5B"
                stroke-width="2"
                stroke-linecap="round"
            />
            <path
                d="M72.1296 167.074C81.045 173.31 91.8244 177.143 103.499 177.59C104.302 177.59 109.116 177.235 109.913 177.143C113.837 176.695 115.259 176.879 116.952 176.609C124.261 175.18 131.122 172.421 137.18 168.675C130.589 165.204 107.779 154.774 107.779 154.774C107.779 154.774 83.001 162.795 72.1296 167.074Z"
                fill="#EAEEEF"
                stroke="#344F5B"
                stroke-width="2"
            />
            <path
                d="M131.655 111.38C131.655 113.784 131.57 116.102 131.478 118.327C131.3 120.823 131.122 123.233 130.858 125.636C130.681 127.329 130.497 129.113 130.319 130.713C130.141 132.05 129.963 133.301 129.785 134.546C129.16 138.378 125.953 144.975 121.857 148.268L122.391 158.52L122.66 163.597C122.746 166.007 122.121 168.233 120.962 170.195C119.895 172.065 118.289 174.646 117.044 176.609C112.678 177.59 105.283 177.767 103.499 177.59C99.2192 174.561 95.6567 170.373 93.4309 167.521C91.8244 165.559 91.0212 162.972 91.291 160.39L92.2721 147.471L92.8975 139.984V139.892C91.6466 136.686 90.1321 131.253 89.6845 129.824C86.8333 128.843 83.4486 126.084 82.0261 121.626C80.8672 117.794 82.9152 113.962 85.7664 113.428C88.7096 112.803 91.1132 114.232 92.0023 116.813C92.1801 118.064 92.272 119.578 92.3579 121.356L92.8975 121.179C110.63 111.111 116.418 98.5469 128.896 95.248C130.233 96.3211 131.655 104.605 131.655 111.38Z"
                fill="#EAEEEF"
                stroke="#344F5B"
                stroke-width="2"
            />
            <path
                opacity="0.3"
                d="M122.391 158.52C120.52 159.587 118.289 160.299 116.51 160.746C114.37 161.28 111.967 161.28 109.735 160.568C101.893 158.251 96.0124 152.818 92.2721 147.379L92.8975 139.893L94.2342 142.302C98.1523 146.846 109.735 150.053 117.222 150.053C118.289 150.053 119.362 149.789 120.428 149.164L121.857 148.183L122.391 158.52Z"
                fill="#5E767C"
            />
            <path
                d="M120.343 149.427C119.362 150.053 118.289 150.322 117.136 150.322C109.735 150.322 98.1523 147.116 94.1422 142.566"
                stroke="#344F5B"
                stroke-width="2"
                stroke-linecap="round"
            />
            <path
                d="M109.379 119.842C108.398 119.842 107.595 119.045 107.595 118.064V117.439C107.595 116.458 108.398 115.654 109.379 115.654C110.36 115.654 111.164 116.458 111.164 117.439V118.064C111.164 119.045 110.36 119.842 109.379 119.842Z"
                fill="#EAEEEF"
                stroke="#344F5B"
                stroke-width="2"
            />
            <path
                d="M125.597 119.842C124.616 119.842 123.813 119.045 123.813 118.064V117.439C123.813 116.458 124.616 115.654 125.597 115.654C126.578 115.654 127.382 116.458 127.382 117.439V118.064C127.382 119.045 126.578 119.842 125.597 119.842Z"
                fill="#EAEEEF"
                stroke="#344F5B"
                stroke-width="2"
            />
            <path
                d="M78.1938 105.053C87.2809 103.183 102.788 96.4991 111.341 87.0503"
                stroke="#344F5B"
                stroke-width="2"
                stroke-linecap="round"
            />
            <path
                d="M78.7273 97.3878C81.5785 94.8922 85.4966 91.2378 89.0652 87.6753"
                stroke="#344F5B"
                stroke-width="2"
                stroke-linecap="round"
            />
            <path
                d="M93.0753 119.664C93.7866 119.664 94.5898 119.664 95.6567 119.486C102.788 117.972 108.668 114.139 114.101 108.081C116.952 104.875 122.299 98.5468 125.597 95.4258"
                stroke="#344F5B"
                stroke-width="2"
                stroke-linecap="round"
            />
            <path
                d="M89.8623 120.204C89.8623 117.709 86.9253 115.391 84.8773 117.617"
                stroke="#344F5B"
                stroke-width="2"
                stroke-linecap="round"
            />
            <path
                d="M118.288 120.382C118.38 119.842 118.472 119.579 118.558 119.309C118.736 118.775 119.447 118.775 119.717 119.309C120.606 120.823 122.035 122.693 122.299 123.141C123.102 124.3 125.867 126.526 125.334 127.777C124.794 129.199 121.943 130.714 120.876 130.714C119.987 130.714 116.332 130.002 116.241 128.843C116.155 127.777 116.596 126.887 117.044 125.992C117.044 126.084 117.933 122.785 118.288 120.382Z"
                fill="#EAEEEF"
                stroke="#344F5B"
                stroke-width="2"
            />
            <path
                d="M113.126 132.32C114.996 134.908 118.288 135.527 120.962 134.19"
                stroke="#344F5B"
                stroke-width="2"
                stroke-linecap="round"
            />
            <rect
                x="46.7407"
                y="64.9072"
                width="117.63"
                height="124.667"
                rx="4.27778"
                stroke="#344F5B"
                stroke-width="2"
            />
            <path d="M190 69.2407H319" stroke="#344F5B" stroke-width="2" />
            <path d="M277 170.241H319" stroke="#344F5B" stroke-width="2" />
            <path d="M190 78.2407H319" stroke="#344F5B" stroke-width="2" />
            <path d="M277 179.241H319" stroke="#344F5B" stroke-width="2" />
            <path d="M190 87.2407H319" stroke="#344F5B" stroke-width="2" />
            <path d="M277 188.241H319" stroke="#344F5B" stroke-width="2" />
            <circle
                cx="323"
                cy="78"
                r="73"
                stroke="#344F5B"
                stroke-width="10"
            />
            <circle opacity="0.5" cx="323" cy="78" r="68" fill="#D9D9D9" />
            <path
                opacity="0.5"
                d="M328 19C399.117 46.2 379.076 97.6667 360.166 120C364.845 99 364.962 49.4 328 19Z"
                fill="white"
            />
            <path
                d="M372.5 135.5L445.5 208.5"
                stroke="#344F5B"
                stroke-width="10"
            />
        </svg>
    )
}
