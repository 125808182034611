import { Campus, Program } from "@wetradeup/student-apps-shared"
import { Select } from "@wetradeup/ui-kit"
import { useMemo } from "react"
import { WrappedFormik } from "../../../../utils"
import { UpdateApplicationBasicInfoForm } from "../../BasicInfoPage"
import { CreateAccountForm } from "../IntroForm"

interface CampusSelectProps {
    program?: Program
    campus?: Campus
    setFieldValue:
        | ReturnType<
              WrappedFormik<CreateAccountForm>["formik"]
          >["setFieldValue"]
        | ReturnType<
              WrappedFormik<UpdateApplicationBasicInfoForm>["formik"]
          >["setFieldValue"]
}

export function CampusSelect({
    program,
    campus,
    setFieldValue,
}: CampusSelectProps) {
    const campusOptions = useMemo(() => {
        return program?.campuses?.map((c) => ({
            label: c.campus_name,
            value: c.id,
        }))
    }, [program])

    return (
        <Select
            name="campus"
            placeholder="Select Your Campus"
            options={campusOptions}
            value={campus?.campus_name}
            onOptionSelected={(fieldName, { value }) =>
                setFieldValue(fieldName, value)
            }
            onClear={() => setFieldValue("campus", "")}
        />
    )
}
