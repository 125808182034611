import { useTheme } from "@emotion/react"
import { Route, Switch } from "react-router-dom"
import { useWindowSize } from "../../hooks/useWindowSize"
import {
    ExitCounselingHeader,
    titleOrders,
    titles,
} from "../atoms/ExitCounselingHeader"

export function ExitCounselingHeaderRoutes() {
    const { windowSize } = useWindowSize()
    const theme = useTheme()
    return (
        <Switch>
            <Route path={"/loan-orientation"} exact>
                <ExitCounselingHeader
                    hide={true}
                    keepHeight={windowSize.width > theme.breakpoints.sm}
                />
            </Route>
            <Route path={"/loan-orientation/admin"} exact>
                <ExitCounselingHeader
                    hide={true}
                    keepHeight={windowSize.width > theme.breakpoints.sm}
                />
            </Route>
            <Route path={"/loan-orientation/login"} exact>
                <ExitCounselingHeader hide={true} keepHeight={true} />
            </Route>
            <Route path={"/loan-orientation/admin/login"} exact>
                <ExitCounselingHeader hide={true} keepHeight={true} />
            </Route>
            <Route path={"/loan-orientation/admin/students"} exact>
                <ExitCounselingHeader hide={true} keepHeight={true} />
            </Route>
            <Route path={"/loan-orientation/overview"}>
                <ExitCounselingHeader
                    currentPosition={titleOrders.indexOf(titles.overview)}
                />
            </Route>
            <Route path={"/loan-orientation/admin/:accountId/overview"}>
                <ExitCounselingHeader
                    currentPosition={titleOrders.indexOf(titles.overview)}
                />
            </Route>
            <Route path={"/loan-orientation/repayment"}>
                <ExitCounselingHeader
                    currentPosition={titleOrders.indexOf(titles.repayment)}
                    partialComplete={50}
                />
            </Route>
            <Route path={"/loan-orientation/admin/:accountId/repayment"}>
                <ExitCounselingHeader
                    currentPosition={titleOrders.indexOf(titles.repayment)}
                    partialComplete={50}
                />
            </Route>
            <Route path={"/loan-orientation/repayment-how-to"}>
                <ExitCounselingHeader
                    currentPosition={titleOrders.indexOf(titles.repayment)}
                />
            </Route>
            <Route path={"/loan-orientation/admin/:accountId/repayment-how-to"}>
                <ExitCounselingHeader
                    currentPosition={titleOrders.indexOf(titles.repayment)}
                />
            </Route>
            <Route path={"/loan-orientation/missed-payment"}>
                <ExitCounselingHeader
                    currentPosition={titleOrders.indexOf(titles.latePayment)}
                />
            </Route>
            <Route path={"/loan-orientation/admin/:accountId/missed-payment"}>
                <ExitCounselingHeader
                    currentPosition={titleOrders.indexOf(titles.latePayment)}
                />
            </Route>
            <Route path={"/loan-orientation/member-benefits"}>
                <ExitCounselingHeader
                    currentPosition={titleOrders.indexOf(titles.benefits)}
                    partialComplete={33}
                />
            </Route>
            <Route path={"/loan-orientation/admin/:accountId/member-benefits"}>
                <ExitCounselingHeader
                    currentPosition={titleOrders.indexOf(titles.benefits)}
                    partialComplete={33}
                />
            </Route>
            <Route path={"/loan-orientation/credit-scores"}>
                <ExitCounselingHeader
                    currentPosition={titleOrders.indexOf(titles.benefits)}
                    partialComplete={66}
                />
            </Route>
            <Route path={"/loan-orientation/admin/:accountId/credit-scores"}>
                <ExitCounselingHeader
                    currentPosition={titleOrders.indexOf(titles.benefits)}
                    partialComplete={66}
                />
            </Route>
            <Route path={"/loan-orientation/quiz"}>
                <ExitCounselingHeader
                    currentPosition={titleOrders.indexOf(titles.test)}
                />
            </Route>
            <Route path={"/loan-orientation/result"}>
                <ExitCounselingHeader
                    currentPosition={titleOrders.indexOf(titles.test)}
                />
            </Route>
            <Route>
                <ExitCounselingHeader hide={true} />
            </Route>
        </Switch>
    )
}
