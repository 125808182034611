import { Container, H5, P, Select, Spacer, Input } from "@wetradeup/ui-kit"
import {
    Questionnaire,
    QuestionnaireAnswer,
    QuestionOption,
    EditingQuestionnaireAnswer,
    RadioQuestionnaireAnswer,
} from "@wetradeup/student-apps-shared"
import { WrappedFormik } from "../../../utils"

interface RadioQuestionProps {
    id: string
    title: string
    subtitle: string
    options: QuestionOption[]
    questionnaire: Questionnaire[]
    setQuestionnaire: React.Dispatch<React.SetStateAction<Questionnaire[]>>
    setFieldValue:
        | ReturnType<WrappedFormik<Questionnaire[]>["formik"]>["setFieldValue"]
        | ((fieldName: string, value: any) => void)
    values: QuestionnaireAnswer
    setTimeOfLastTouch: React.Dispatch<React.SetStateAction<number>>
    newAnswer?: EditingQuestionnaireAnswer
    isEditAnswer?: boolean
    dependentQuestions?: Questionnaire[]
    setDependentQuestions?: React.Dispatch<
        React.SetStateAction<Questionnaire[]>
    >
}

export function RadioQuestion({
    id,
    title,
    subtitle,
    options,
    questionnaire,
    dependentQuestions,
    setDependentQuestions,
    setQuestionnaire,
    setFieldValue,
    values,
    setTimeOfLastTouch,
    newAnswer,
    isEditAnswer,
}: RadioQuestionProps) {
    const onOptionSelected = (fieldName, option) => {
        setTimeOfLastTouch(Math.round(Date.now() / 1000))

        let newQuestionnaire = JSON.parse(JSON.stringify(questionnaire)).map(
            (q) => {
                if (option["show"].includes(q["id"])) {
                    q["hidden"] = false
                } else if (option["hide"].includes(q["id"])) {
                    q["hidden"] = true
                }

                return q
            }
        )

        if (isEditAnswer) {
            const newDependentQuestions = option.show.map((showId) =>
                newQuestionnaire.find((nQ) => nQ.id === showId)
            )
            const filteredQuestions = dependentQuestions.filter(
                (dQ) => !option.hide.includes(dQ.id)
            )
            setDependentQuestions(
                filteredQuestions.concat(newDependentQuestions)
            )
        }
        setQuestionnaire(newQuestionnaire)
        setFieldValue(fieldName, option)
    }

    const onFreeFormChange = (evt) => {
        if (isEditAnswer) {
            setFieldValue(id, { ...newAnswer[id], text: evt.target.value })
        } else {
            setFieldValue(id, { ...values[id], text: evt.target.value })
        }
    }

    const showFreeForm =
        (isEditAnswer && newAnswer[id]?.value === "freeform") ||
        (!isEditAnswer &&
            (values[id] as RadioQuestionnaireAnswer)?.value === "freeform")

    return (
        <Container alignStretch>
            <H5 textAlign="left">{title}</H5>
            {subtitle && (
                <>
                    <Spacer size={8} />
                    <P textAlign="left">{subtitle}</P>
                </>
            )}
            <Spacer />
            <Container>
                <Select
                    options={options}
                    name={id}
                    onOptionSelected={onOptionSelected}
                    placeholder={"Select an option"}
                    alignStretch
                />
            </Container>
            {showFreeForm && (
                <>
                    <Spacer md />
                    <H5 textAlign="left">Please describe:</H5>
                    <Spacer />
                    <Input
                        fullWidth
                        onChange={onFreeFormChange}
                        placeholder={"Type here..."}
                    />
                </>
            )}
            <Spacer lg />
        </Container>
    )
}
