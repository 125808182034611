import {
    Card,
    Container,
    FynnFullLetterLogo,
    Input,
    Modal,
    P,
    Spacer,
} from "@wetradeup/ui-kit"
import {
    AuthContext,
    AuthStyledButton,
    useUpdateAccountMutation,
} from "@wetradeup/student-apps-shared"
import * as Yup from "yup"
import { useFormik } from "formik"
import { ContentContainer, InnerModal } from "../../../ModalComponents"
import { useTheme } from "@emotion/react"
import { useContext, useState } from "react"

const valSchema = Yup.object().shape({
    email: Yup.string().email().required("Please enter a new email address."),
})

export function UpdateEmailModal({
    visible,
    closeModal,
    sendEmailVerification,
}: {
    visible: boolean
    closeModal?: () => void
    sendEmailVerification: () => Promise<void>
}) {
    const { authProfile, setAuthProfile } = useContext(AuthContext)
    const [serverError, setServerError] = useState("")
    const updateAccount = useUpdateAccountMutation()
    const theme = useTheme()
    const onSubmit = async () => {
        try {
            await updateAccount.mutateAsync({ email: values.email })
            await sendEmailVerification()
            setAuthProfile({
                ...authProfile,
                email: values.email,
            })
            closeModal()
        } catch (err) {
            setServerError(err.error || "")
        }
    }

    const { handleSubmit, handleChange, values, errors } = useFormik<{
        email: string
    }>({
        initialValues: { email: "" },
        validationSchema: valSchema,
        onSubmit,
    })

    const disabled = !!errors.email || !values.email
    return (
        <Modal
            visible={visible}
            width="100%"
            onClick={() => closeModal && closeModal()}
        >
            <InnerModal
                width={[
                    [700, "md"],
                    [860, "lg"],
                ]}
                onClick={(e) => e.stopPropagation()}
            >
                <Card paddingLg>
                    <Spacer lg />
                    <ContentContainer>
                        <Container justifyContent="center" alignItems="center">
                            <FynnFullLetterLogo />
                        </Container>
                        <Spacer />
                        <P
                            bold
                            textSize={theme.sizings.fontSizeMd}
                            textColor={theme.colors.palette.black}
                            textAlign="center"
                        >
                            Please type your correct email address
                        </P>
                        <Spacer />
                        <Input
                            name="email"
                            label="New Email Address"
                            showRequired
                            onChange={handleChange}
                            fullWidth
                            value={values.email}
                        />
                        <Spacer lg />
                        <AuthStyledButton
                            disabled={disabled}
                            title={"Update your email address"}
                            primary
                            fullWidth
                            onPress={(e) => {
                                e.preventDefault()
                                handleSubmit()
                            }}
                            isRoundButton
                        />
                        {updateAccount.isLoading && (
                            <>
                                <Spacer />
                                <P bold="extra" textAlign="center">
                                    Loading...
                                </P>
                            </>
                        )}
                        {serverError && (
                            <>
                                <Spacer />
                                <P bold="extra" error textAlign="center">
                                    {serverError}
                                </P>
                            </>
                        )}
                    </ContentContainer>
                    <Spacer lg />
                </Card>
            </InnerModal>
        </Modal>
    )
}
