import { useTheme } from "@emotion/react"
import { useHistory } from "react-router-dom"
import { format } from "date-fns"
import { useExitCounselingLogin } from "@wetradeup/student-apps-shared"
import {
    Container,
    FynnFullLetterLogoLoanOrientation,
    Input,
    P,
    SecureLabel,
    Spacer,
} from "@wetradeup/ui-kit"
import * as Yup from "yup"
import { useFormik } from "formik"
import { LoginPage } from "../molecules/DefaultPage"
import { DatePicker } from "../../application/molecules/DatePicker"
import { ApplicationButton } from "../../atoms/ApplicationButton"
import { SpinningLoader } from "../../application/atoms/SpinningLoader"

const valSchema = Yup.object().shape({
    first_name: Yup.string().required("Please enter your first name."),
    last_name: Yup.string().required("Please enter your last name."),
    dob: Yup.date().required("Please enter your dob."),
    last_four_digits_of_ssn: Yup.string().required(
        "Please enter last 4 digits of your SSN."
    ),
})

interface LoginForm {
    first_name: string
    last_name: string
    dob: Date | null
    last_four_digits_of_ssn: string
}

export function ExitCounselingLoginPage() {
    const theme = useTheme()
    const history = useHistory()
    const exitCounselingMutation = useExitCounselingLogin()
    const onSubmit = async () => {
        await exitCounselingMutation.mutateAsync({
            ...values,
            dob: format(values.dob, "yyyy-MM-dd"),
        })
        history.push("/loan-orientation/overview")
    }
    const { handleSubmit, handleChange, values, errors, setFieldValue } =
        useFormik<LoginForm>({
            initialValues: {
                first_name: "",
                last_name: "",
                dob: null,
                last_four_digits_of_ssn: "",
            },
            validationSchema: valSchema,
            onSubmit,
        })

    return (
        <LoginPage>
            <Container width="100%">
                <Container justifyContent="center" alignItems="center">
                    <FynnFullLetterLogoLoanOrientation />
                </Container>
                <Spacer lg />
                <P
                    bold
                    textSize={theme.sizings.fontSizeMd}
                    textColor={theme.colors.palette.black}
                    textAlign="center"
                >
                    Let's pull up your info
                </P>
                <Spacer lg />
                <Input
                    name="first_name"
                    placeholder="First name"
                    onChange={handleChange}
                    fullWidth
                    value={values.first_name}
                    error={errors.first_name}
                    tabIndex={1}
                />
                <Spacer lg />
                <Input
                    name="last_name"
                    placeholder="Last name"
                    onChange={handleChange}
                    fullWidth
                    value={values.last_name}
                    error={errors.last_name}
                    tabIndex={2}
                />
                <Spacer lg />
                <DatePicker
                    name="dob"
                    format="MM/dd/yyyy"
                    placeholder="Date of birth"
                    monthPlaceholder="MM"
                    dayPlaceholder="DD"
                    yearPlaceholder="YYYY"
                    value={values.dob}
                    onChange={(date) => {
                        setFieldValue("dob", date)
                    }}
                    error={errors.dob}
                    data-private="redact"
                    alignItems="flex-start"
                    flexDirection="column"
                    tabIndex={3}
                    autoFocus
                    noLabel
                    disableBlur
                />
                <Spacer lg />
                <Input
                    name="last_four_digits_of_ssn"
                    placeholder="Last 4 digits of your SSN"
                    onChange={handleChange}
                    fullWidth
                    value={values.last_four_digits_of_ssn}
                    error={errors.last_four_digits_of_ssn}
                    tabIndex={4}
                />
                <Spacer lg />
                {exitCounselingMutation.isLoading ? (
                    <SpinningLoader />
                ) : (
                    <ApplicationButton
                        fullWidth
                        alignCenter
                        title="Submit"
                        buttonColor="newBlue"
                        disabled={Object.keys(errors).length > 0}
                        onPress={() => handleSubmit()}
                    />
                )}
            </Container>
            <Spacer lg />
            <SecureLabel />
        </LoginPage>
    )
}
