export function Phone() {
    return (
        <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.6196 2.2876H7.39827C6.26925 2.2876 5.354 3.20285 5.354 4.33186V20.686C5.354 21.815 6.26925 22.7303 7.39827 22.7303H17.6196C18.7486 22.7303 19.6639 21.815 19.6639 20.686V4.33186C19.6639 3.20285 18.7486 2.2876 17.6196 2.2876Z"
                stroke="#0070F9"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M12.509 18.6418H12.519"
                stroke="#0070F9"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
