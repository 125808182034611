import React, { useMemo } from "react"
import { Card, Spacer } from "@wetradeup/ui-kit"
import { ResetPasswordForm } from "@wetradeup/student-apps-shared"
import queryString from "query-string"
import { useFynnLocation } from "./hooks/useFynnLocation"

export function ResetPassword() {
    const location = useFynnLocation()
    const query = useMemo(
        () => queryString.parse(location.search),
        [location.search]
    )
    return (
        <>
            <Card
                paddingLg
                width={[["100%"], ["90%", "xs"], ["75%", "md"], [500, "lg"]]}
            >
                <Spacer />
                <ResetPasswordForm token={(query && query.code) as string} />
            </Card>
        </>
    )
}
