import React, { useContext, useEffect, useState, useCallback } from "react"
import {
    Container,
    P,
    Card,
    Spacer,
    TouchableContainer,
} from "@wetradeup/ui-kit"
import { useHistory } from "react-router-dom"
import {
    ApplicantAccount,
    AuthContext,
    PasswordLoginForm,
    useGetAccount,
    useLogout,
    SCHOOL_PORTAL_URL,
} from "@wetradeup/student-apps-shared"
import { QueryCache } from "react-query"
import queryString from "query-string"
import { useFynnLocation } from "./hooks/useFynnLocation"
import { FullMobileContainer } from "./ModalComponents"
import { ApplicationButton } from "./atoms/ApplicationButton"
import { ClickableCaption } from "./application/atoms/ClickableCaption"
import { useTheme } from "@emotion/react"

export function Login() {
    const { authProfile } = useContext(AuthContext)
    const accountQuery = useGetAccount()
    const history = useHistory()
    const location = useFynnLocation()
    const logout = useLogout()
    const theme = useTheme()

    const [loginSubmitted, setLoginSubmitted] = useState(false)

    const logoutIfAuth = useCallback(async () => {
        if (loginSubmitted) return
        if (authProfile) {
            await logout()
            const queryCache = new QueryCache()
            queryCache.clear()
        }
    }, [authProfile, loginSubmitted, logout])

    useEffect(() => {
        logoutIfAuth()
    }, [logoutIfAuth])

    useEffect(() => {
        if (loginSubmitted && accountQuery.isSuccess && !!authProfile) {
            const query = queryString.parse(location.search)
            if (query.code && query.schoolId) {
                return history.push(
                    `/application/${query.schoolId}/email?code=${query.code}`
                )
            }

            // Not yet submitted
            if (authProfile.role === "application_in_progress") {
                return history.push("/application")
            }

            // Not a student
            if (authProfile.role === "school") {
                window.location.href = SCHOOL_PORTAL_URL
                return
            }

            // Submitted and approved or rejected
            if (
                "underwriting_decision" in accountQuery.data &&
                ((accountQuery.data as ApplicantAccount)
                    ?.underwriting_decision === "approved" ||
                    (accountQuery.data as ApplicantAccount)
                        ?.underwriting_decision === "rejected")
            ) {
                return history.push("/isl-finalize")
            } else if (
                "underwriting_decision" in accountQuery.data &&
                (accountQuery.data as ApplicantAccount)
                    ?.underwriting_decision === "pending" &&
                authProfile.role === "applicant"
            ) {
                return history.push("/isl-finalize")
            }

            return history.push("/browse/landing")
        }
    }, [authProfile, accountQuery, loginSubmitted, location.search])
    return (
        <>
            <Card
                paddingLg
                width={["100%", [400, "xs"], [400, "md"], [400, "ld"]]}
            >
                <FullMobileContainer>
                    <Spacer />
                    <PasswordLoginForm
                        onLoginSubmitted={() => setLoginSubmitted(true)}
                        isRoundButton
                    />
                    <Spacer />
                    <Container alignItems="center">
                        <P>Or</P>
                    </Container>
                    <Spacer />
                    <ApplicationButton
                        outline="lightBlue"
                        textColor="lightBlue"
                        title="Create application"
                        onPress={() => history.push("/application/intro")}
                    />
                    <Spacer />
                    <Container row alignItems="center" justifyContent="center">
                        <P>Are you a school?</P>
                        <Spacer xs />
                        <TouchableContainer
                            onPress={() => {
                                window.location.href = SCHOOL_PORTAL_URL
                            }}
                        >
                            <ClickableCaption
                                color={theme.colors.palette.blue}
                                fontWeight={400}
                                textSize="15px"
                            >
                                Sign in here
                            </ClickableCaption>
                        </TouchableContainer>
                    </Container>
                </FullMobileContainer>
            </Card>
        </>
    )
}
