import { Container, H2, P, Spacer } from "@wetradeup/ui-kit"
import {
    useProceedWithoutCosigner,
    useStudentApplicationDetails,
} from "@wetradeup/student-apps-shared"
import { useState } from "react"
import { ApplicationButton } from "../atoms/ApplicationButton"
import { CosignerInviteModal } from "../application/molecules/modals/CosignerInviteModal"
import { SpinningLoader } from "../application/atoms/SpinningLoader"

interface CosignerCanceledProps {
    studentApplicationDetails: ReturnType<typeof useStudentApplicationDetails>
}

export function CosignerCanceled({
    studentApplicationDetails,
}: CosignerCanceledProps) {
    const [open, setOpen] = useState(false)
    const proceedWithoutCosigner = useProceedWithoutCosigner()
    const handleProceedWithoutCosigner = async () => {
        await proceedWithoutCosigner.mutateAsync()
    }

    const title = studentApplicationDetails.data
        ?.cosigner_application_was_canceled_by_student
        ? "Would you like to submit your application without a co-signer?"
        : "Your co-signer has removed themselves from your application. Continue without a co-signer?"
    const content = studentApplicationDetails.data
        ?.cosigner_application_was_canceled_by_student
        ? "You removed your co-signer. Would you like to submit your application without any co-signer, or would you like to add a new one?"
        : "Because your co-signer has canceled their application, you can submit your application on your own or you can invite a new co-signer."
    const buttonTitle = studentApplicationDetails.data
        ?.cosigner_application_was_canceled_by_student
        ? "Submit without a co-signer"
        : "Continue without a co-signer"

    return (
        <>
            <Container
                paddingMd
                width={[
                    [550, "sm"],
                    [550, "md"],
                    [750, "lg"],
                ]}
                justifyContent="center"
                alignItems="center"
            >
                <Container
                    width="100%"
                    maxWidth={400}
                    justifyContent="center"
                    alignItems="center"
                >
                    <Spacer lg />
                    <H2 bold="light" textColor="black">
                        {title}
                    </H2>
                    <Spacer lg />
                    <P textAlign="center" textColor="black">
                        {content}
                    </P>
                    <Spacer lg />
                    {!studentApplicationDetails.data
                        .has_rejected_application_and_is_reapplying_with_cosigner && (
                        <Container
                            maxWidth={450}
                            alignItems="center"
                            width="100%"
                        >
                            {proceedWithoutCosigner.isLoading && (
                                <SpinningLoader />
                            )}
                            {!proceedWithoutCosigner.isLoading && (
                                <ApplicationButton
                                    fullWidth
                                    primary
                                    title={buttonTitle}
                                    onPress={() =>
                                        handleProceedWithoutCosigner()
                                    }
                                    id="proceedWithoutCosigner"
                                />
                            )}
                            <Spacer lg />
                        </Container>
                    )}
                    <Container maxWidth={450} alignItems="center" width="100%">
                        <ApplicationButton
                            fullWidth
                            outline="lightBlue"
                            textColor="lightBlue"
                            title="Invite a new cosigner"
                            onPress={() => setOpen(true)}
                        />
                    </Container>
                </Container>
                <Spacer lg />
            </Container>
            <CosignerInviteModal
                open={open}
                closeModal={() => setOpen(false)}
            />
        </>
    )
}
