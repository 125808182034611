export function SignOutIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="22"
            height="21"
            viewBox="0 0 22 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.85106 19.4405H3.76546C3.22367 19.4405 2.70408 19.2253 2.32098 18.8422C1.93788 18.4591 1.72266 17.9395 1.72266 17.3977V3.0981C1.72266 2.55632 1.93788 2.03672 2.32098 1.65362C2.70408 1.27052 3.22367 1.0553 3.76546 1.0553H7.85106"
                stroke={props.stroke}
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M15.0008 15.3549L20.1078 10.2479L15.0008 5.14087"
                stroke={props.stroke}
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M20.1078 10.2479H7.85101"
                stroke={props.stroke}
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
