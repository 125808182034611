export function ErrorLaptopIcon() {
    return (
        <svg
            width="600"
            height="241"
            viewBox="0 0 375 151"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M75.7909 138.433C75.9445 139.16 76.3464 139.816 76.9197 140.283C77.4989 140.75 78.22 141.004 78.9588 141.004L178.826 143.694L266.636 141.004C267.097 141.004 267.552 140.904 267.966 140.709C268.379 140.508 268.74 140.218 269.029 139.864C269.319 139.509 269.526 139.089 269.632 138.646C269.739 138.197 269.745 137.736 269.65 137.281L247.557 33.3356C246.416 27.9749 241.15 23.6309 235.79 23.6309H61.0918C55.7312 23.6309 52.3091 27.9749 53.4498 33.3356L75.7909 138.433Z"
                fill="#EAEEEF"
                stroke="#344F5B"
                stroke-width="0.865741"
            />
            <path
                d="M86.2698 128.363C86.2698 128.369 86.2758 128.368 86.2817 128.374C86.2876 128.38 86.2935 128.38 86.2994 128.38H255.105C255.111 128.38 255.111 128.38 255.116 128.38C255.122 128.374 255.122 128.374 255.122 128.368C255.128 128.368 255.128 128.369 255.128 128.363C255.128 128.357 255.128 128.351 255.128 128.351L235.559 36.2736C235.559 36.2677 235.553 36.2618 235.547 36.2618C235.547 36.2559 235.541 36.2559 235.53 36.2559H66.7303C66.7244 36.2559 66.7184 36.2559 66.7125 36.2559L66.7066 36.2618C66.7007 36.2677 66.7007 36.2736 66.7007 36.2736C66.6948 36.2795 66.6948 36.2795 66.7007 36.2854L86.2698 128.363Z"
                fill="#EAEEEF"
                stroke="#344F5B"
                stroke-width="0.865741"
            />
            <path
                opacity="0.3"
                d="M94.5562 114.059C94.7276 114.857 95.1649 115.572 95.8033 116.086C96.4357 116.601 97.2335 116.884 98.0492 116.884H263.988C263.994 116.884 263.994 116.884 263.994 116.878C264 116.878 264.006 116.878 264.006 116.872C264.012 116.867 264.012 116.867 264.012 116.861C264.012 116.861 264.012 116.855 264.012 116.849L244.442 24.7776C244.442 24.7716 244.436 24.7657 244.431 24.7598C244.431 24.7598 244.419 24.7539 244.413 24.7539H75.6076C75.6017 24.7539 75.6017 24.7539 75.5958 24.7598L75.5899 24.7657C75.584 24.7657 75.584 24.7716 75.584 24.7776C75.584 24.7776 75.584 24.7835 75.584 24.7894L94.5562 114.059Z"
                fill="#5E767C"
            />
            <path
                d="M103.097 105.838C103.268 106.636 103.706 107.352 104.338 107.866C104.977 108.38 105.769 108.664 106.584 108.664H269.645C269.999 108.664 270.342 108.587 270.661 108.433C270.974 108.285 271.258 108.061 271.477 107.789C271.701 107.517 271.855 107.198 271.938 106.855C272.02 106.512 272.026 106.158 271.955 105.815L255.265 27.3078L176.74 21.9648L86.4062 27.3078L103.097 105.838Z"
                fill="#EAEEEF"
                stroke="#344F5B"
                stroke-width="0.865741"
            />
            <path
                d="M86.4065 27.3078H255.271L253.575 19.3643C253.409 18.5605 252.966 17.8453 252.333 17.3311C251.695 16.8169 250.903 16.5332 250.087 16.5332H87.0271C86.6725 16.5332 86.3297 16.6159 86.0105 16.7637C85.6914 16.9174 85.4136 17.1361 85.1949 17.4079C84.9703 17.6798 84.8166 17.999 84.7339 18.3418C84.6512 18.6846 84.6453 19.0392 84.7221 19.382L86.4065 27.3078Z"
                fill="#EAEEEF"
                stroke="#344F5B"
                stroke-width="0.865741"
            />
            <path
                d="M77.3043 141.006C77.0501 141.006 76.8019 141.106 76.6187 141.29C76.4354 141.473 76.335 141.721 76.335 141.975V143.388C76.335 145.031 76.991 146.609 78.1494 147.767C79.3079 148.926 80.88 149.582 82.5231 149.582H342.56C344.203 149.582 345.776 148.926 346.934 147.767C348.098 146.609 348.748 145.031 348.748 143.388V141.975C348.748 141.721 348.648 141.473 348.465 141.29C348.282 141.106 348.033 141.006 347.779 141.006H77.3043Z"
                fill="#EAEEEF"
                stroke="#344F5B"
                stroke-width="0.865741"
            />
            <path
                opacity="0.3"
                d="M77.4697 146.957C78.0371 147.767 78.7995 148.429 79.6802 148.89C80.5549 149.345 81.5301 149.581 82.5231 149.581H342.56C343.553 149.581 344.528 149.345 345.409 148.89C346.284 148.429 347.04 147.767 347.614 146.957H77.4697Z"
                fill="#5E767C"
            />
            <path
                d="M105.16 145.39H96.9682C96.5959 145.39 96.2354 145.242 95.9694 144.976C95.7093 144.716 95.5616 144.355 95.5557 143.983C95.5616 143.605 95.7093 143.25 95.9694 142.984C96.2354 142.718 96.5959 142.57 96.9682 142.57H105.16C105.532 142.57 105.893 142.718 106.159 142.984C106.425 143.25 106.573 143.605 106.573 143.983C106.573 144.355 106.425 144.716 106.159 144.976C105.893 145.242 105.532 145.39 105.16 145.39Z"
                fill="#EAEEEF"
                stroke="#344F5B"
                stroke-width="0.865741"
            />
            <path
                d="M133.305 145.39H113.594C113.216 145.39 112.855 145.242 112.595 144.982C112.329 144.716 112.182 144.355 112.182 143.983C112.182 143.605 112.329 143.25 112.595 142.984C112.855 142.718 113.216 142.57 113.594 142.57H133.305C133.678 142.57 134.038 142.718 134.304 142.984C134.57 143.25 134.718 143.605 134.718 143.983C134.718 144.355 134.57 144.716 134.304 144.982C134.038 145.242 133.678 145.39 133.305 145.39Z"
                fill="#EAEEEF"
                stroke="#344F5B"
                stroke-width="0.865741"
            />
            <path
                d="M263.87 141.277V142.371C263.87 142.956 263.64 143.517 263.226 143.931C262.812 144.339 262.257 144.575 261.671 144.575H241.724C241.139 144.575 240.577 144.339 240.17 143.931C239.75 143.517 239.525 142.956 239.525 142.371V141.277"
                stroke="#344F5B"
                stroke-width="0.865741"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M211.972 58.3652C216.912 74.6054 209.028 91.2223 194.596 95.6125C180.164 100.003 164.362 90.5908 159.422 74.3505C154.481 58.1102 162.366 41.4933 176.798 37.1032C191.23 32.713 207.032 42.1249 211.972 58.3652Z"
                stroke="#344F5B"
                stroke-width="1.73148"
            />
            <path
                d="M171.513 57.9859L174.9 53.9573L185.737 63.068L194.419 52.741L198.674 56.3185L189.993 66.6455L200.831 75.7561L197.444 79.7848L186.606 70.6741L177.877 81.0578L173.622 77.4803L182.351 67.0966L171.513 57.9859Z"
                fill="#344F5B"
            />
        </svg>
    )
}
