import { useEffect, useState } from "react"
import { breakpoints } from "../../constants"

export function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    })

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            })
        }
        window.addEventListener("resize", handleResize)
        handleResize()
        return () => window.removeEventListener("resize", handleResize)
    }, [])

    const desktopSmSize = parseInt(breakpoints.desktopSm.replace("px", ""))
    const isMdDesktop = parseInt(breakpoints.desktopMd.replace("px", ""))

    return {
        windowSize,
        isDesktop: windowSize.width >= desktopSmSize,
        isMdDesktop: windowSize.width >= isMdDesktop,
    }
}
