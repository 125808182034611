export const titles = [
    "Funding for school",
    "Getting to know you",
    "Questionnaire",
    "Identity Verification",
    "Review and Send",
]

export const genderOptions = ["Male", "Female", "Other"]

export const educationOptions = [
    {
        label: "None of the below",
        value: "none",
    },
    {
        label: "High school",
        value: "high_school",
    },
    {
        label: "High school equivalent (GED)",
        value: "ged",
    },
    {
        label: "Technical school",
        value: "technical_school",
    },
    {
        label: "Some college coursework",
        value: "some_college_coursework",
    },
    {
        label: "Associate's degree",
        value: "associate_degree",
    },
    {
        label: "Bachelor's degree",
        value: "bachelors_degree",
    },
    {
        label: "Master's degree",
        value: "masters_degree",
    },
    {
        label: "Doctorate/Professional",
        value: "doctorate_professional",
    },
]

export const citizenOptions = [
    {
        label: "US Citizen",
        value: "us_citizen",
    },
    {
        label: "Permanent Resident",
        value: "us_permanent_resident",
    },
    {
        label: "Other",
        value: "others",
    },
]
