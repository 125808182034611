export function Arrow() {
    return (
        <svg
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1 7L7 1"
                stroke="black"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M1 1H7V7"
                stroke="black"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
