import { modalViewPages } from "../isl-finalize/IslFinalizeWrapper"
import { useFynnLocation } from "./useFynnLocation"
import { useWindowSize } from "./useWindowSize"

const greenBackgroundPages = [
    "/application/intro/select",
    "/not-found",
    "/server-error",
    "/coming-soon",
]

const getBackgroundColor = (pathname: string, isDesktop: boolean) => {
    if (pathname.includes("loan-orientation")) {
        return "newISLGreenBackground"
    }

    if (greenBackgroundPages.includes(pathname)) {
        return "newISLGreenBackground"
    }

    if (
        pathname.includes("/application/intro") ||
        pathname.includes("/application/intro/account")
    ) {
        return "whiteBackground"
    }

    if (
        pathname.includes("/isl-finalize") &&
        !modalViewPages.includes(pathname)
    ) {
        return "whiteBackground"
    }

    return "creamBackground"
}

export function useBackgroundColor() {
    const location = useFynnLocation()
    const { isDesktop } = useWindowSize()
    return getBackgroundColor(location.pathname, isDesktop)
}
