export function SandTimer() {
    return (
        <svg
            width="53"
            height="53"
            viewBox="0 0 53 53"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="26.5"
                cy="26.5"
                r="25.0804"
                stroke="#1C30BA"
                stroke-width="2.83929"
            />
            <rect
                width="15.1416"
                height="10.0954"
                transform="matrix(-1 0 0 1 34.0718 11.3567)"
                fill="#1C30BA"
            />
            <rect
                width="15.1416"
                height="10.0954"
                transform="matrix(-1 0 0 1 34.0718 31.5474)"
                fill="#1C30BA"
            />
            <path
                d="M26.878 27.2209C30.5455 27.0501 34.0698 24.9495 34.0698 21.4521C34.0698 16.0438 18.9287 16.0438 18.9287 21.4521C18.9287 25.1694 22.9799 27.4024 26.878 27.2209Z"
                fill="#1C30BA"
            />
            <path
                d="M26.1225 25.7888C22.455 25.9596 18.9307 28.0603 18.9307 31.5577C18.9307 36.9659 34.0718 36.9659 34.0718 31.5577C34.0718 27.8403 30.0206 25.6073 26.1225 25.7888Z"
                fill="#1C30BA"
            />
            <rect
                width="10.5991"
                height="7.21101"
                transform="matrix(1 -8.32679e-08 -9.17849e-08 -1 21.1997 39.4797)"
                fill="#5060CA"
            />
            <path
                d="M26.2326 27.9494C23.6653 28.0746 21.1982 29.6148 21.1982 32.179C21.1982 36.1442 31.7974 36.1442 31.7974 32.179C31.7974 29.4535 28.9614 27.8164 26.2326 27.9494Z"
                fill="#5060CA"
            />
            <rect
                width="12.1133"
                height="7.93211"
                transform="matrix(-1 0 0 1 32.5503 12.7988)"
                fill="#D3D9F0"
            />
            <path
                d="M26.7957 25.7703C29.7298 25.6325 32.5493 23.9384 32.5493 21.1178C32.5493 13.5199 20.436 14.241 20.436 21.1178C20.436 24.1157 23.6771 25.9167 26.7957 25.7703Z"
                fill="#D3D9F0"
            />
            <rect
                width="12.1133"
                height="7.93211"
                transform="matrix(1 -8.32679e-08 -9.17849e-08 -1 20.436 40.2004)"
                fill="#D3D9F0"
            />
            <path
                d="M26.1906 27.229C23.2565 27.3667 20.437 29.0609 20.437 31.8815C20.437 36.2432 32.5503 36.2432 32.5503 31.8815C32.5503 28.8835 29.3092 27.0826 26.1906 27.229Z"
                fill="#D3D9F0"
            />
            <rect
                width="4.54249"
                height="3.6055"
                transform="matrix(-1 0 0 1 28.7646 24.3367)"
                fill="#D3D9F0"
            />
            <rect
                width="10.5991"
                height="4.3266"
                transform="matrix(1 -6.76552e-08 -1.12966e-07 -1 21.1904 39.4795)"
                fill="#1C30BA"
            />
            <path
                d="M26.2287 31.5536C23.6614 31.6538 21.1943 32.8859 21.1943 34.9373C21.1943 38.1095 31.7935 38.1095 31.7935 34.9373C31.7935 32.7569 28.9575 31.4472 26.2287 31.5536Z"
                fill="#1C30BA"
            />
            <path
                d="M26.7644 25.0505C29.3318 24.9324 31.7989 23.4798 31.7989 21.0614C26.7644 21.0614 26.4993 21.0614 21.1997 21.0614C21.1997 23.6319 24.0356 25.176 26.7644 25.0505Z"
                fill="#1C30BA"
            />
        </svg>
    )
}
