import { Button, Container } from "@wetradeup/ui-kit"
import styled from "@emotion/styled"

export function PaymentOptions({
    selected,
    options,
    setSelected,
}: {
    selected: string
    options: Array<{ label: string; value: string }>
    setSelected: (option: string) => void
}) {
    const InSchoolPaymentButton = styled(Button)`
        ${(props) => `
            > p {
                border-width: 0px;
            }
            border: 1.5px solid ${props.theme.colors.palette.blue};
            color: ${
                props.type === selected
                    ? props.theme.colors.palette.white
                    : props.theme.colors.palette.blue
            };
            background-color: ${
                props.type === selected
                    ? props.theme.colors.palette.blue
                    : props.theme.colors.palette.white
            };
      `}
    `

    return (
        <Container flex row width="100%" justifyContent="space-between">
            {options.map(({ label, value }) => (
                <InSchoolPaymentButton
                    key={value}
                    small
                    disableShadow
                    type={value}
                    outlineWidth={1.5}
                    title={label}
                    onPress={() => {
                        setSelected(value)
                    }}
                />
            ))}
        </Container>
    )
}
