export function EditQuestion() {
    return (
        <svg
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.12945 2.44971H1.75099C1.41921 2.44971 1.10101 2.58151 0.866406 2.81611C0.6318 3.05072 0.5 3.36891 0.5 3.7007V12.4576C0.5 12.7894 0.6318 13.1076 0.866406 13.3422C1.10101 13.5768 1.41921 13.7086 1.75099 13.7086H10.5079C10.8397 13.7086 11.1579 13.5768 11.3925 13.3422C11.6271 13.1076 11.7589 12.7894 11.7589 12.4576V8.07916"
                stroke="#212121"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M10.8207 1.51144C11.0695 1.2626 11.407 1.1228 11.7589 1.1228C12.1108 1.1228 12.4483 1.2626 12.6972 1.51144C12.946 1.76027 13.0858 2.09777 13.0858 2.44968C13.0858 2.80159 12.946 3.13908 12.6972 3.38792L6.75497 9.33012L4.25299 9.95561L4.87849 7.45363L10.8207 1.51144Z"
                stroke="#212121"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
