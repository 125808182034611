import { useStudentApplicationDetails } from "@wetradeup/student-apps-shared"
import { Container, Spacer } from "@wetradeup/ui-kit"
import { InSchoolPaymentInput } from "./InSchoolPayment/InSchoolPaymentInput"
import { LoanAmountInput } from "./LoanAmountInput"

interface LoanPaymentInputsContainerProps {
    studentApplicationDetails: ReturnType<typeof useStudentApplicationDetails>
    handleFinancingAmountChange: (value: number) => void
    handleInSchoolPaymentChange: (value: number) => void
    handleFinancingAmountSubmit: (financingAmount: number) => Promise<void>
    financingAmount?: number
    inSchoolPaymentAmount?: number
    financingAmountUpdateError?: string
    inSchoolPaymentAmountError?: string
    isFinancingChanged?: boolean
}

export function LoanPaymentInputsContainer({
    studentApplicationDetails,
    handleFinancingAmountChange,
    handleInSchoolPaymentChange,
    handleFinancingAmountSubmit,
    financingAmount,
    inSchoolPaymentAmount,
    financingAmountUpdateError,
    inSchoolPaymentAmountError,
    isFinancingChanged,
}: LoanPaymentInputsContainerProps) {
    return (
        <Container flex={3}>
            <LoanAmountInput
                studentApplicationDetails={studentApplicationDetails}
                handleFinancingAmountChange={handleFinancingAmountChange}
                handleFinancingAmountSubmit={handleFinancingAmountSubmit}
                financingAmount={financingAmount}
                financingAmountUpdateError={financingAmountUpdateError}
                isFinancingChanged={isFinancingChanged}
            />
            <Spacer />
            <InSchoolPaymentInput
                studentApplicationDetails={studentApplicationDetails}
                handleInSchoolPaymentChange={handleInSchoolPaymentChange}
                inSchoolPaymentAmount={inSchoolPaymentAmount}
                inSchoolPaymentAmountError={inSchoolPaymentAmountError}
            />
        </Container>
    )
}
