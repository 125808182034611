import { usePlaidLink } from "react-plaid-link"
import { SubmissionButton } from "../../application/atoms/SubmissionButton"
import { P, TouchableContainer } from "@wetradeup/ui-kit"
import { SpinningLoader } from "../../application/atoms/SpinningLoader"
import { RightArrow } from "../icons/RightArrow"
import { useTheme } from "@emotion/react"

export function BankVerifyButton({
    linkToken,
    buttonType,
    onExit,
    onSuccess,
    onEvent,
}) {
    const buttonTitle = "Connect & Verify"
    const theme = useTheme()
    const { ready, open } = usePlaidLink({
        token: linkToken,
        onSuccess,
        onExit,
        onEvent,
    })

    return (
        <>
            {buttonType === "card" ? (
                <TouchableContainer
                    onPress={() => open()}
                    width="100%"
                    disabled={!ready}
                    row
                    alignItems="center"
                    justifyContent={!ready ? "center" : "space-between"}
                >
                    {!ready ? (
                        <SpinningLoader size={24} />
                    ) : (
                        <>
                            <P
                                textSize={theme.sizings.fontSizeInc2}
                                textColor="black"
                            >
                                {buttonTitle}
                            </P>
                            <RightArrow />
                        </>
                    )}
                </TouchableContainer>
            ) : (
                <SubmissionButton
                    fullWidth
                    disabled={!ready}
                    onPress={() => open()}
                    title={buttonTitle}
                />
            )}
        </>
    )
}
