import { useCallback, useState, useContext } from "react"
import { useHistory } from "react-router-dom"
import {
    useCreateApplicationForm,
    identifyLogRocketSession,
    usePasswordLogin,
    AuthContext,
} from "@wetradeup/student-apps-shared"
import { format } from "date-fns"
import { useFynnLocation } from "../../../hooks/useFynnLocation"

export function useSubmitIntro({
    setAppFormId,
    setValues,
    school,
    employer,
    setSubmitted,
}) {
    const [, setSubmitValues] = useState(null)
    const [invalidPersonalInfo, setInvalidPersonalInfo] = useState("")
    const submitFormMutation = useCreateApplicationForm()
    const loginMutation = usePasswordLogin()
    const history = useHistory()
    const location = useFynnLocation()
    const { authProfile } = useContext(AuthContext)

    const onSubmit = useCallback(
        async (values) => {
            setSubmitValues(values)
            setValues && setValues(values)
            setSubmitted && setSubmitted(true)

            const start_date =
                values.anticipated_start_date &&
                format(new Date(values.anticipated_start_date), "yyyy-MM-dd")

            const body = {
                email: values.email,
                phone: values.phone,
                school: school.school_name,
                employer: employer?.employer_name,
                program: values.program,
                campus_id: values.campus,
                first_name: values.first,
                last_name: values.last,
                password: values.passwordOne,
                tos_consent_received: true,
                is_creating_new_account: values.is_creating_new_account,
                ...(!employer?.employer_name && {
                    anticipated_start_date: start_date,
                }),
            }

            try {
                const data = await submitFormMutation.mutateAsync(body)

                setAppFormId(data?.id)

                identifyLogRocketSession(data.email)

                return history.push(`/application/${school.id}/email`)
            } catch (error) {
                if (
                    error.error ===
                    "There already exists an account with this email. Please log in."
                ) {
                    return history.push(
                        `/application/intro/existing-account${location.search}`,
                        { emailDuplication: true }
                    )
                }

                if (
                    error.error.includes(
                        "Whoops, you already have an account under"
                    )
                ) {
                    return history.push(
                        `/application/intro/existing-account${location.search}`
                    )
                }

                if (
                    error.error.includes(
                        "if you need to correct your personal information"
                    )
                ) {
                    if (error.error.includes("residents of your state")) {
                        setInvalidPersonalInfo("state of residence")
                    } else if (error.error.includes("non US citizens")) {
                        setInvalidPersonalInfo("citizenship status")
                    } else {
                        setInvalidPersonalInfo("age")
                    }
                }
            }
        },
        [school, setValues, setAppFormId]
    )

    return {
        onSubmit,
        loading: submitFormMutation.isLoading || loginMutation.isLoading,
        error: submitFormMutation.error,
        authProfile,
        invalidPersonalInfo,
    }
}
