import React, { useState } from "react"

export interface IApprovedModalContext {
    isApprovedModalOpen: boolean
    setIsApprovedModalOpen: (isApprovedModalOpen: boolean) => void
}

export const ApprovedModalContext = React.createContext<IApprovedModalContext>({
    isApprovedModalOpen: true,
    setIsApprovedModalOpen: () => {},
})

export const ApprovedModalProvider: React.FC = ({ children }) => {
    const [isApprovedModalOpen, setIsApprovedModalOpen] =
        useState<boolean>(false)

    return (
        <ApprovedModalContext.Provider
            value={{ isApprovedModalOpen, setIsApprovedModalOpen }}
        >
            {children}
        </ApprovedModalContext.Provider>
    )
}
