import { useMemo, useState } from "react"
import {
    Select,
    Container,
    H6,
    Spacer,
    ErrorContainer,
    ErrorMessage,
} from "@wetradeup/ui-kit"
import { parseISO, addMonths, format } from "date-fns"
import { DayPicker } from "react-day-picker"
import "react-day-picker/dist/style.css"
import { StartDateOption, WrappedFormik } from "../../../utils"
import { CreateAccountForm } from "./IntroForm"
import { Program } from "@wetradeup/student-apps-shared"
import { UpdateApplicationBasicInfoForm } from "../BasicInfoPage"
import {
    englishDateFormat,
    englishMonthAndYearFormat,
} from "../../../constants"
import styled from "@emotion/styled"

interface StartDateSelectProps {
    startDates?: StartDateOption[]
    error?: string
    program?: Program
    values:
        | ReturnType<WrappedFormik<CreateAccountForm>["formik"]>["values"]
        | ReturnType<
              WrappedFormik<UpdateApplicationBasicInfoForm>["formik"]
          >["values"]
    setFieldValue:
        | ReturnType<
              WrappedFormik<CreateAccountForm>["formik"]
          >["setFieldValue"]
        | ReturnType<
              WrappedFormik<UpdateApplicationBasicInfoForm>["formik"]
          >["setFieldValue"]
}

const DayPickerContainer = styled(Container)`
    font-family: ${(props) => props.theme.fonts.typefaces.primary};

    & > .rdp {
        margin: 0px;
        margin-top: 12px;
    }
`

export function StartDateSelect({
    startDates,
    error,
    program,
    values,
    setFieldValue,
}: StartDateSelectProps) {
    const [showCalendar, setShowCalendar] = useState(false)
    const endDate = useMemo(() => {
        if (program && values.anticipated_start_date) {
            const endDate = addMonths(
                values.anticipated_start_date,
                program.program_length
            )
            return format(endDate, englishMonthAndYearFormat)
        }
    }, [program, values.anticipated_start_date])

    const selectValue =
        values.anticipated_start_date &&
        format(new Date(values.anticipated_start_date), englishDateFormat)

    const setStartDateForPicker = (date) => {
        setShowCalendar(false)
        setFieldValue("anticipated_start_date", date)
    }

    return (
        <>
            <Container
                row
                paddinigSm
                alignItems="center"
                justifyContent="center"
                wrap
            >
                <Select
                    value={selectValue}
                    name="anticipated_start_date"
                    placeholder="Select Your Start Date"
                    options={[
                        ...(startDates || []),
                        {
                            label: "Select Date Manually",
                            value: "custom",
                            style: {
                                textDecoration: "underline",
                            },
                        },
                    ]}
                    onOptionSelected={(fieldName, { value }) => {
                        if (value === "custom") {
                            setShowCalendar(true)
                        } else {
                            setShowCalendar(false)
                            setFieldValue(fieldName, value)
                        }
                    }}
                    onClear={() => setFieldValue("anticipated_start_date", "")}
                    onFocus={() => setShowCalendar(false)}
                    error={error}
                    maxDropDownSize={150}
                />
                {showCalendar && (
                    <DayPickerContainer>
                        <DayPicker
                            mode="single"
                            selected={parseISO(selectValue)}
                            onSelect={setStartDateForPicker}
                        />
                        {error && (
                            <ErrorContainer>
                                <ErrorMessage>{error}</ErrorMessage>
                            </ErrorContainer>
                        )}
                    </DayPickerContainer>
                )}
                <Spacer />
                {!showCalendar && endDate && (
                    <Container>
                        <Spacer size={4} />
                        <H6> to {endDate} </H6>
                    </Container>
                )}
            </Container>
        </>
    )
}
