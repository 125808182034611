import { setConfigProvider } from "@wetradeup/student-apps-shared"
import { getConfigKey } from "@wetradeup/student-apps-shared"

export const config = {
    API_URL: process.env.REACT_APP_API_URL,
    DASHBOARD_URL: process.env.REACT_APP_DASHBOARD_URL,
    HELLOSIGN_CLIENT_ID: process.env.REACT_APP_HELLOSIGN_CLIENT_ID,
    CLIENT_ENV: process.env.REACT_APP_CLIENT_ENV,
    STRIPE_PUBLIC_KEY: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
    BUILD_VERSION: process.env.REACT_APP_BUILD_VERSION || "local",
}

setConfigProvider(config)

export const API_URL = getConfigKey("API_URL")
export const DASHBOARD_URL = getConfigKey("DASHBOARD_URL")
export const HELLOSIGN_CLIENT_ID = getConfigKey("HELLOSIGN_CLIENT_ID")
export const CLIENT_ENV = getConfigKey("CLIENT_ENV")
export const STRIPE_PUBLIC_KEY = getConfigKey("STRIPE_PUBLIC_KEY")
export const BUILD_VERSION = getConfigKey("BUILD_VERSION")
