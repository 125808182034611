import styled from "@emotion/styled"
import { Container } from "@wetradeup/ui-kit"
import { useWindowSize } from "../../../hooks/useWindowSize"

const FixedFullContainer = styled(Container)`
    position: fixed;
    top: 0;
    left: 0;
    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => `${height}px`};
    background: ${({ theme }) => theme.colors.palette.greenBackground};
`

const viewBoxWidth = 615
const viewBoxHeight = 731
export function WelcomePageBackground() {
    const { windowSize } = useWindowSize()
    const halfWidth = windowSize.width / 2
    const shouldBasedOnWidth =
        halfWidth * (viewBoxHeight / viewBoxWidth) > windowSize.height
    const height = shouldBasedOnWidth
        ? halfWidth * (viewBoxHeight / viewBoxWidth)
        : windowSize.height
    const width = shouldBasedOnWidth
        ? halfWidth
        : windowSize.height * (viewBoxWidth / viewBoxHeight)

    return (
        <FixedFullContainer height={height} width={width}>
            <svg
                viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <mask
                    id="mask0_5748_66"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="615"
                    height="731"
                >
                    <rect
                        x="0.0429688"
                        width="614.958"
                        height="731"
                        fill="#18C27A"
                    />
                </mask>
                <g mask="url(#mask0_5748_66)">
                    <path
                        d="M-5.04594 232.494C19.7649 246.043 61.3426 263.845 65.3856 294.429C70.4377 332.341 41.9571 385.236 32.7348 422.487C11.706 507.313 12.4549 585.183 21.0617 671.187C27.6937 737.692 17.6139 801.138 -55.3837 828.052C-69.9448 833.371 -86.2508 836.249 -101.862 835.594C-125.255 834.571 -127.795 833.808 -137.284 815.888C-159.95 773.468 -173.909 724.199 -191.612 679.643C-219.987 608.474 -341.093 638.646 -312.41 710.409C-289.105 768.841 -271.69 837.366 -238.184 891.254C-208.081 939.566 -144.323 957.089 -88.4101 954.528C20.8248 949.461 122.323 873.472 143.5 769.68C155.082 712.565 142.509 656.969 138.429 600.017C133.911 536.262 147.108 475.379 164.258 414.026C179.859 358.169 202.73 301.87 180.471 244.647C158.211 187.425 108.245 156.858 57.5749 129.289C-12.3201 91.2466 -74.9762 194.326 -4.91444 232.461L-5.04594 232.494Z"
                        fill="#19B472"
                    />
                    <path
                        d="M847.385 286.584C762.198 172.696 600.714 131.672 475.264 214.639C414.739 254.687 377.663 313.886 360.41 381.559C344.606 443.36 346.223 510.092 322.491 569.966C296.597 635.424 221.172 671.506 181.089 729.261C141.005 787.017 120.11 860.519 124.504 929.625C132.387 1051.57 227.328 1203 364.151 1224.32C443.981 1236.81 522.165 1158.64 575.17 1111.59C645.597 1049.15 705.828 976.446 755.945 898.278C797.94 832.789 689.753 773.355 647.806 838.765C605.859 904.175 553.928 966.842 493.925 1021.04C468.216 1044.26 441.19 1066.54 412.625 1086.6C379.599 1109.82 375.957 1108.87 341.463 1084.7C235.796 1010.67 217.928 860.895 306.798 767.64C356.421 715.559 410.888 680.751 438.513 612.435C461.092 556.479 463.979 496.777 475.475 438.427C486.971 380.076 508.12 326.297 568.261 300.434C628.402 274.57 700.934 294.904 738.947 345.676C785.293 407.596 894.134 348.938 847.517 286.551L847.385 286.584Z"
                        fill="#19B472"
                    />
                    <path
                        d="M177.585 -331.581C217.608 -309.66 271.033 -284.383 287.861 -240.118C304.69 -195.853 293.41 -140.197 300.143 -94.2968C307.523 -43.599 322.248 4.68991 343.775 51.6466C361.391 90.2115 395.109 133.725 363.348 173.88C333.506 211.6 284.82 199.4 247.061 180.524C200.985 157.598 152.977 134.561 141.007 83.5584C112.689 -37.9879 96.2903 -163.444 80.5864 -286.932C71.0806 -362.171 -54.0727 -362.521 -44.4516 -286.373C-33.7921 -202.04 -21.5696 -117.904 -7.30683 -34.0196C4.97885 38.0956 7.77573 131.744 55.4951 191.74C96.5836 243.434 170.592 282.577 233.833 304.48C301.968 328.019 377.581 320.88 433.431 275.053C484.422 233.215 511.537 166.815 498.845 103.253C485.416 36.3253 441.169 -21.3372 426.933 -89.1324C412.428 -158.133 429.892 -232.066 397.543 -297.456C366.034 -361.116 301.962 -400.713 239.904 -434.654C170.185 -472.812 107.568 -369.797 177.454 -331.547L177.585 -331.581Z"
                        fill="#19B472"
                    />
                </g>
            </svg>
        </FixedFullContainer>
    )
}
