import { Container, Spacer, Card, H6 } from "@wetradeup/ui-kit"

export const BenefitCard = ({ image, title, description }) => {
    return (
        <Card
            disableShadow
            border="medGrey"
            justifyContent="center"
            alignItems="center"
            disableSm
            column
            flex={1}
        >
            <Card
                disableShadow
                alignItems="center"
                justifyContent="center"
                paddingSm
            >
                {image}
            </Card>
            <Container flex={2} paddingSm>
                <H6 textAlign="center" weight={800}>
                    {" "}
                    {title}{" "}
                </H6>
                <Spacer size={6} />
                {description}
            </Container>
            <Spacer />
        </Card>
    )
}
