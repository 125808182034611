import styled from "@emotion/styled"
import { Container, H6, Caption, HR } from "@wetradeup/ui-kit"

interface TermContainerProps {
    term: string
    termValue: string
    termDescription?: string
    addHR?: boolean
}

const SolidHR = styled(HR)`
    border: 1px solid ${(props) => props.theme.colors.palette.medGrey};
`

export const TermContainer = ({
    term,
    termValue,
    termDescription,
    addHR,
}: TermContainerProps) => (
    <Container column padding={["none", "sm"]}>
        <Container
            row
            justifyContent="space-between"
            alignItems="center"
            height={48}
        >
            <Container>
                <H6 bold="light" textColor="black">
                    {" "}
                    {term}{" "}
                </H6>
            </Container>
            <Container>
                <H6 bold="light" textColor="black">
                    {" "}
                    {termValue || "--"}{" "}
                </H6>
                {termDescription && (
                    <Caption textAlign="right" textColor="black">
                        {" "}
                        {termDescription}
                    </Caption>
                )}
            </Container>
        </Container>
        {addHR && <SolidHR />}
    </Container>
)
