import { useTheme } from "@emotion/react"
import { useHistory } from "react-router-dom"
import { useGetExitCounselingSearch } from "@wetradeup/student-apps-shared"
import {
    Container,
    FynnFullLetterLogoLoanOrientation,
    Input,
    P,
    SecureLabel,
    Spacer,
} from "@wetradeup/ui-kit"
import * as Yup from "yup"
import { useFormik } from "formik"
import { LoginPage } from "../molecules/DefaultPage"
import { ApplicationButton } from "../../atoms/ApplicationButton"
import { SpinningLoader } from "../../application/atoms/SpinningLoader"
import { useState } from "react"

const valSchema = Yup.object().shape({
    first_name: Yup.string(),
    last_name: Yup.string(),
    email: Yup.string(),
})

interface LoginForm {
    first_name: string
    last_name: string
    email: string
}

export function ExitCounselingAdminStudentsPage() {
    const theme = useTheme()
    const history = useHistory()
    const exitCounselingSearchMutation = useGetExitCounselingSearch()
    const [serverError, setServerError] = useState("")
    const onSubmit = async () => {
        try {
            setServerError("")
            const resp = await exitCounselingSearchMutation.mutateAsync(values)
            history.push(`/loan-orientation/admin/${resp.account_id}/overview`)
        } catch (err) {
            setServerError(err?.error || err)
        }
    }
    const { handleSubmit, handleChange, values, errors } = useFormik<LoginForm>(
        {
            initialValues: {
                first_name: "",
                last_name: "",
                email: "",
            },
            validationSchema: valSchema,
            onSubmit,
        }
    )

    return (
        <LoginPage>
            <Container width="100%">
                <Container justifyContent="center" alignItems="center">
                    <FynnFullLetterLogoLoanOrientation />
                </Container>
                <Spacer lg />
                <P
                    bold
                    textSize={theme.sizings.fontSizeMd}
                    textColor={theme.colors.palette.black}
                    textAlign="center"
                >
                    Please type student's info
                </P>
                <Spacer lg />
                <Input
                    name="first_name"
                    placeholder="First name"
                    onChange={handleChange}
                    fullWidth
                    value={values.first_name}
                    error={errors.first_name}
                    tabIndex={1}
                />
                <Spacer lg />
                <Input
                    name="last_name"
                    placeholder="Last name"
                    onChange={handleChange}
                    fullWidth
                    value={values.last_name}
                    error={errors.last_name}
                    tabIndex={2}
                />
                <Spacer lg />
                <Container alignItems="center">
                    <P>Or</P>
                </Container>
                <Spacer lg />
                <Input
                    name="email"
                    placeholder="email"
                    onChange={handleChange}
                    value={values.email}
                    error={errors.email}
                />
                <Spacer lg />
                {exitCounselingSearchMutation.isLoading ? (
                    <SpinningLoader />
                ) : (
                    <>
                        <ApplicationButton
                            fullWidth
                            alignCenter
                            title="Submit"
                            buttonColor="newBlue"
                            disabled={Object.keys(errors).length > 0}
                            onPress={() => handleSubmit()}
                        />
                        <Spacer sm />
                        {serverError && <P error> {serverError} </P>}
                    </>
                )}
            </Container>
            <Spacer lg />
            <SecureLabel />
        </LoginPage>
    )
}
