import { useMemo } from "react"
import {
    useGetPublicSchool,
    useStudentApplicationDetails,
} from "@wetradeup/student-apps-shared"

export const useGetFinancing = (
    studentApplicationDetails: ReturnType<typeof useStudentApplicationDetails>
) => {
    const { school_id, program_id, max_request, min_request } =
        studentApplicationDetails.data || {}

    const schoolQuery = useGetPublicSchool(school_id, null)

    const { maxNoteSize, minNoteSize, programTuition } = useMemo(() => {
        if (studentApplicationDetails.data && schoolQuery.data) {
            let programTuition = 0

            const school = schoolQuery.data

            if (school) {
                const program = school.programs.find(
                    (program) => program.id === program_id
                )
                if (program) {
                    programTuition = program.note_size
                }
            }

            return {
                maxNoteSize: max_request,
                minNoteSize: min_request,
                programTuition: programTuition,
            }
        } else {
            return {}
        }
    }, [studentApplicationDetails.data, schoolQuery.data, program_id])

    return { maxNoteSize, minNoteSize, programTuition }
}
