import { useCallback, useContext, useEffect } from "react"
import {
    useGetPlaidLinkToken,
    useCreateAccessToken,
    useUpdatePlaidMetadata,
} from "@wetradeup/student-apps-shared"
import * as Sentry from "@sentry/react"
import {
    BankVerificationContext,
    MAX_BANK_VERIFICATION_ATTEMPTS,
} from "../../contexts/BankVerificationProvider"

interface useVerifyBankProps {
    studentProfileLoaded: boolean
    studentApplicationDetailsLoaded: boolean
    bankVerificationIdentity: string
    bankVerificationIdentityAttempts: number
    plaidStatus: string
    bankIdentityFrozen: boolean
}

export const useVerifyBank = ({
    studentProfileLoaded,
    studentApplicationDetailsLoaded,
    bankVerificationIdentity,
    bankVerificationIdentityAttempts,
    plaidStatus,
    bankIdentityFrozen,
}: useVerifyBankProps) => {
    const {
        isUpdatingPlaid,
        setIsUpdatingPlaid,
        showBankVerificationFailedModal,
        setShowBankVerificationModal,
        showFrozenModal,
        setShowFrozenModal,
        remainingAttempts,
        setRemainingAttempts,
        showInternalErrorModal,
        setShowInternalErrorModal,
        showRejectedModal,
        setShowRejectedModal,
        error,
        setError,
    } = useContext(BankVerificationContext)

    const linkTokenQuery = useGetPlaidLinkToken()
    const createAccessTokenMutation = useCreateAccessToken()
    const updatePlaidMetadataMutation = useUpdatePlaidMetadata()

    useEffect(() => {
        if (
            studentProfileLoaded &&
            studentApplicationDetailsLoaded &&
            bankVerificationIdentity === "rejected" &&
            bankVerificationIdentityAttempts > 0 &&
            bankVerificationIdentityAttempts < MAX_BANK_VERIFICATION_ATTEMPTS &&
            !plaidStatus &&
            !bankIdentityFrozen
        ) {
            setShowBankVerificationModal(true)
            setRemainingAttempts(
                MAX_BANK_VERIFICATION_ATTEMPTS -
                    bankVerificationIdentityAttempts
            )
        }
    }, [
        studentProfileLoaded,
        studentApplicationDetailsLoaded,
        bankVerificationIdentity,
        bankVerificationIdentityAttempts,
        plaidStatus,
        bankIdentityFrozen,
        bankVerificationIdentityAttempts,
    ])

    useEffect(() => {
        if (
            bankVerificationIdentity === "rejected" &&
            bankVerificationIdentityAttempts >= MAX_BANK_VERIFICATION_ATTEMPTS
        ) {
            setShowRejectedModal(true)
        }
    }, [bankVerificationIdentityAttempts, bankVerificationIdentity])

    useEffect(() => {
        if (
            bankVerificationIdentity === "rejected" &&
            bankVerificationIdentityAttempts < MAX_BANK_VERIFICATION_ATTEMPTS &&
            bankIdentityFrozen
        ) {
            setShowFrozenModal(true)
        }
    }, [
        bankVerificationIdentity,
        bankVerificationIdentityAttempts,
        bankIdentityFrozen,
    ])

    const onSuccess = useCallback(
        async (token, metadata) => {
            setIsUpdatingPlaid(true)

            let verification_status = null
            let account_id = null

            if (metadata.account) {
                verification_status = metadata.account.verification_status
                account_id = metadata.account_id
            } else if (metadata.accounts && metadata.accounts.length > 0) {
                verification_status = metadata.accounts[0].verification_status
                account_id = metadata.accounts[0].id
            }

            if (!account_id) {
                Sentry.addBreadcrumb({
                    category: "error",
                    message:
                        "Could not extract account_id from Plaid onSuccess response",
                    data: {
                        plaidMetadataObject: JSON.stringify(metadata),
                    },
                })

                Sentry.captureException(
                    new Error("Could not parse Plaid onSuccess response.")
                )

                setIsUpdatingPlaid(false)
                setError(
                    "There seems to have been a problem linking your bank account. Please try again or contact Fynn Support if the problem persists."
                )
                return
            }

            try {
                await createAccessTokenMutation.mutateAsync({
                    token: token,
                    verification_status: verification_status,
                    plaid_account_id: account_id,
                    metadata: JSON.stringify(metadata),
                })
            } catch (e) {
                // The error thrown is a Plaid error with the following schema:
                // https://plaid.com/docs/errors/#error-schema
                Sentry.captureException(new Error(e?.error_message))
                if (e?.error_type === "FrozenBankIdentityException") {
                    setShowFrozenModal(true)
                } else if (
                    e?.error_type === "FailedBankIdentityCheckException"
                ) {
                    setShowBankVerificationModal(true)
                    setRemainingAttempts(
                        MAX_BANK_VERIFICATION_ATTEMPTS -
                            (e?.bank_identity_verification_attempts || 0)
                    )
                } else if (
                    e?.error_type === "ExceededMaxBankIdentityChecksException"
                ) {
                    setShowRejectedModal(true)
                } else if (
                    e?.error_type === "MicrobiltIntegrationException" ||
                    e?.error_type === "FraudCheckException"
                ) {
                    setShowInternalErrorModal(true)
                }
            }
            setIsUpdatingPlaid(false)
        },
        [createAccessTokenMutation]
    )

    const onExit = useCallback(
        async (err, metadata) => {
            try {
                await updatePlaidMetadataMutation.mutateAsync({
                    metadata: JSON.stringify(metadata),
                    error: err ? JSON.stringify(err) : null,
                })
            } catch (e) {
                Sentry.addBreadcrumb({
                    category: "error",
                    message: "Could not send plaid metadata inside onExit",
                    data: {
                        plaidErrorObject: JSON.stringify(err),
                        plaidMetadataObject: JSON.stringify(metadata),
                        serverError: JSON.stringify(e),
                    },
                })

                Sentry.captureException(
                    new Error(
                        "Could not save Plaid metadata from onExit to the database."
                    )
                )
            }
        },
        [updatePlaidMetadataMutation]
    )

    const onEvent = useCallback(
        async (event, metadata) => {
            try {
                await updatePlaidMetadataMutation.mutateAsync({
                    metadata: JSON.stringify({ event, ...metadata }),
                })
            } catch (e) {
                Sentry.addBreadcrumb({
                    category: "error",
                    message: "Could not send plaid metadata inside onEvent",
                    data: {
                        plaidErrorObject: JSON.stringify(event),
                        plaidMetadataObject: JSON.stringify(metadata),
                        serverError: JSON.stringify(e),
                    },
                })

                Sentry.captureException(
                    new Error(
                        "Could not save Plaid metadata from onEvent to the database."
                    )
                )
            }
        },
        [updatePlaidMetadataMutation]
    )

    return {
        isUpdatingPlaid,
        error,
        onSuccess,
        onExit,
        onEvent,
        linkTokenQuery,
        showBankVerificationFailedModal,
        setShowBankVerificationModal,
        showInternalErrorModal,
        setShowInternalErrorModal,
        remainingAttempts,
        showRejectedModal,
        showFrozenModal,
        setShowFrozenModal,
    }
}
