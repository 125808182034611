import VerificationInput from "react-verification-input"
import styled from "@emotion/styled"
import noop from "lodash/fp/noop"

const CodeInputStyleWrapper = styled.div`
    @keyframes blink {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0;
        }
    }

    .codeinput-container {
        height: 76px;
        font-family: ${(props) => props.theme.fonts.typefaces.primary};
    }

    .codeinput-character {
        background: #f1eded;
        border-radius: 5px;
        color: black;
        font-size: 40px;
        padding-top: 20px;
    }

    .codeinput-character--selected {
        outline: 2px solid #d9d7d7;
        position: relative;
        &:empty::after {
            content: "";
            width: 1px;
            height: 30px;
            background-color: #333;
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            animation: blink 1s infinite step-end;
        }
    }
`

interface VerificationCodeInputProps {
    onChange?: (value: string) => void
    onComplete?: (value: string) => void
    value?: string
}

export default function VerificationCodeInput({
    value,
    onChange,
    onComplete,
}: VerificationCodeInputProps) {
    return (
        <CodeInputStyleWrapper>
            <VerificationInput
                value={value}
                onChange={onChange || noop}
                onComplete={onComplete || noop}
                classNames={{
                    container: "codeinput-container",
                    character: "codeinput-character",
                    characterInactive: "codeinput-character--inactive",
                    characterSelected: "codeinput-character--selected",
                }}
                validChars="0-9"
                inputProps={{ inputMode: "numeric" }}
                placeholder=""
                autoFocus={true}
            />
        </CodeInputStyleWrapper>
    )
}
