/** @jsxImportSource @emotion/react */
import { Route, Switch } from "react-router-dom"
import {
    applicationTitleOrders,
    applicationTitles,
    ISLHeader,
} from "../isl-finalize/ISLHeader"

export function ApplicationHeaderRoutes() {
    return (
        <div>
            <Switch>
                <Route path="/application/:schoolId/basic">
                    <ISLHeader
                        currentPosition={applicationTitleOrders.indexOf(
                            applicationTitles.basic
                        )}
                    />
                </Route>
                <Route path="/application/:schoolId/questionnaire">
                    <ISLHeader
                        currentPosition={applicationTitleOrders.indexOf(
                            applicationTitles.questionnaire
                        )}
                    />
                </Route>
                <Route path="/application/:schoolId/review">
                    <ISLHeader
                        currentPosition={applicationTitleOrders.indexOf(
                            applicationTitles.review
                        )}
                    />
                </Route>
            </Switch>
        </div>
    )
}
