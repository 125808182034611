import { Container, Spacer, H2, P } from "@wetradeup/ui-kit"
import { HELP_CENTER_URL } from "@wetradeup/student-apps-shared"
import { ContentContainer } from "../ModalComponents"
import { HiddenBr } from "../application/atoms/HiddenBr"
import { FundingCompleted } from "./icons/FundingCompleted"
import { ApplicationButton } from "../atoms/ApplicationButton"
import { useStudentApplicationDetails } from "@wetradeup/student-apps-shared"
import { SpinningLoader } from "../application/atoms/SpinningLoader"
import { CosignerAwaiting } from "./CosignerAwaiting"

interface CompleteProps {
    studentApplicationDetails: ReturnType<typeof useStudentApplicationDetails>
}

export default function Complete({ studentApplicationDetails }: CompleteProps) {
    const isCompleted = (studentApplicationDetails) => {
        if (!studentApplicationDetails.data) {
            return ""
        }

        const { note, application_type } = studentApplicationDetails.data

        const acceptance_contract = note?.contracts.find(
            (c) => c.contract_type === "acceptance_packet"
        )

        if (
            application_type === "with_cosigner" &&
            !acceptance_contract?.cosigner_signed_on
        ) {
            return false
        }

        return true
    }

    const handlePress = () => {
        window.location.href = HELP_CENTER_URL
    }

    if (!studentApplicationDetails.data) {
        return (
            <Container>
                <SpinningLoader />
            </Container>
        )
    }

    return (
        <>
            {!isCompleted(studentApplicationDetails) ? (
                <CosignerAwaiting
                    studentApplicationDetails={studentApplicationDetails}
                    isAwaitingContractSigning={true}
                />
            ) : (
                <Container
                    paddingMd
                    width={[
                        [550, "sm"],
                        [550, "md"],
                        [750, "lg"],
                    ]}
                >
                    <Spacer />
                    <Container
                        alignItems="center"
                        justifyContent="center"
                        fullWidth
                    >
                        <FundingCompleted />
                    </Container>
                    <Spacer />

                    <H2 bold="light">
                        You're funded and ready to attend school!
                    </H2>
                    <Spacer size={32} />
                    <ContentContainer
                        justifyContent="center"
                        alignItems="center"
                    >
                        <P textAlign="center">
                            Want to know what to expect next? Take a look at our
                            student <HiddenBr />
                            lifecycle page for helpful info about every stage of
                            your loan.
                        </P>
                        <Spacer lg />
                        <ApplicationButton
                            type="submit"
                            primary
                            onPress={handlePress}
                            title="Learn About Your Loan"
                        />
                    </ContentContainer>
                    <Spacer lg />
                </Container>
            )}
        </>
    )
}
