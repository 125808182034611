import styled from "@emotion/styled"
import { Container, P, Spacer } from "@wetradeup/ui-kit"
import { ExitCounselingLock } from "./ExitCounselingLock"

const MemberBenefitOverlay = styled(Container)`
    background: linear-gradient(
        180deg,
        rgba(80, 80, 80, 0.11) 0%,
        rgba(0, 0, 0, 0.256135) 53.76%,
        rgba(54, 54, 54, 0.611167) 80.48%,
        #222222 81.92%
    );
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
`

const MemberBenefitOverlayItem = styled(Container)`
    display: flex;
    flex-direction: row;
    position: absolute;
    top: calc(90.96% - 16px);
    justify-content: center;
    width: 100%;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
}
`

export function ExitCounselingMemberBenefitOverlay() {
    return (
        <MemberBenefitOverlay>
            <MemberBenefitOverlayItem>
                <ExitCounselingLock />
                <Spacer />
                <P bold textColor="white">
                    Enroll in autopay to unlock this benefit.
                </P>
            </MemberBenefitOverlayItem>
        </MemberBenefitOverlay>
    )
}
