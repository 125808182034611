import styled from "@emotion/styled"
import { Card, Container } from "@wetradeup/ui-kit"
import { breakpoints } from "../constants"
import { useNavBar } from "./hooks/useNavBar"

export const CardContainer = styled(Container)`
    ${(props) =>
        !props.disableMaxWidth &&
        `
    @media (min-width: 1250px) {
        max-width: 1250px;
    }
    max-width: 1250px;
    align-self: center;

    @media (max-width: ${breakpoints.mobileLg}) {
        width: 100%;
        flex: 1;
    }
    `}
`

export const ApplicationCard = styled(Card)`
    ${(props) => `
        box-shadow: ${
            props.disableCard ? "none" : "0 2px 7px 1px rgb(0 0 0 / 16%)"
        };
        @media (max-width: ${breakpoints.mobileLg}) {
            border-radius: ${props.border ? "6px" : "0px"};
            flex: 1;
            align-items: center;
            display: flex;
            justify-content: center;
        }
        align-self: center;
        ${
            props.border
                ? `
            border: solid 1px ${props.theme.colors.palette.medGrey};
            border-radius: 6px;
        `
                : ""
        }
    `}
`

export const InnerModal = styled(Container)`
    align-self: center;

    ${(props) =>
        !props.disableMaxWidth &&
        `
    @media (min-width: ${breakpoints.mobileLg}) {
        min-width: 50vw;
        max-width: 90vw;
    }`}

    width: 100%;

    justify-content: flex-start;

    ${(props) =>
        props.noScroll &&
        `
        position: fixed;
        top: 60px;
        z-index: 200;
    `}
`

export const ContentContainer = styled(Container)`
    @media (max-width: ${breakpoints.mobileLg}) {
        padding-left: 10px;
        padding-right: 10px;
    }
    @media (max-width: 1050px) and (min-width: ${breakpoints.mobileLg}) {
        padding-left: 70px;
        padding-right: 70px;
    }
    @media (min-width: 1050px) {
        padding-left: 98px;
        padding-right: 98px;
    }
`

const FullMobileContainerWrapper = styled(Container)`
    @media (max-width: ${breakpoints.mobileLg}) {
        ${(props) =>
            props.showAuthenticatedNavBar
                ? "min-height: calc(100vh - 60px);"
                : "min-height: 100vh;"}
    }
`

export const FullMobileContainer = ({ children, ...props }) => {
    const { showAuthenticatedNavBar } = useNavBar()

    return (
        <FullMobileContainerWrapper
            {...props}
            showAuthenticatedNavBar={showAuthenticatedNavBar}
        >
            {children}
        </FullMobileContainerWrapper>
    )
}
