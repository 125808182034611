import { Container, P, Spacer } from "@wetradeup/ui-kit"
import styled from "@emotion/styled"
import { CoinIcon } from "../../../atoms/CoinIcon"
import { CoinBagIcon } from "../../../atoms/CoinBagIcon"
import {
    maximumInSchoolPaymentMatch,
    minimumInSchoolPaymentAmount,
} from "../../../slider/hooks/usePaymentSliderOffset"

interface PaymentBlurbProps {
    index: number
    setIndex: (index: number) => void
    loanAmount: string
}

const InSchoolPaymentBlurbs = styled.div`
    width: 100%;
    height: 80px;
    overflow: hidden;
`

const CoinContainer = styled(Container)`
    background: #52ea6a;
    border-radius: 4px;
`

const CoinBagContainer = styled(Container)`
    background: #e1f9e9;
    border-radius: 4px;
`

const CoinDescriptionContainer = styled(Container)`
    border-radius: 4px;
    padding: 10px 20px;
`

const BlurbIndexCircle = styled.div<{ isSelected: boolean }>`
    ${(props) => `
        &:hover {
            cursor: pointer;
        }
        width: 8px;
        height: 8px;
        border-radius: 8px;
        background-color: ${props.isSelected ? "#5C5555" : "#D9D9D9"};
    `}
`

export function PaymentBlurbs({
    index,
    setIndex,
    loanAmount,
}: PaymentBlurbProps) {
    return (
        <>
            <InSchoolPaymentBlurbs>
                {index === 0 && (
                    <Container flex row height={80}>
                        <CoinContainer
                            width="20%"
                            flex
                            row
                            alignItems="center"
                            justifyContent="center"
                        >
                            <CoinIcon />
                        </CoinContainer>
                        <CoinDescriptionContainer
                            width="80%"
                            backgroundColor="#E1F9E9"
                        >
                            <P textColor="blacK" bold textSize="12px">
                                Double your money on in-school payments!
                            </P>
                            <P
                                textColor="blacK"
                                textSize="11px"
                                lineHeight={1.4}
                            >
                                When you make an optional in-school payment,
                                we'll match it up to $
                                {maximumInSchoolPaymentMatch} per month!{" "}
                            </P>
                        </CoinDescriptionContainer>
                    </Container>
                )}
                {index === 1 && (
                    <Container flex row height={80}>
                        <CoinBagContainer
                            width="20%"
                            flex
                            row
                            alignItems="center"
                            justifyContent="center"
                        >
                            <CoinBagIcon />
                        </CoinBagContainer>
                        <CoinDescriptionContainer
                            width="80%"
                            backgroundColor="#E1F9E9"
                        >
                            <P textColor="blacK" bold textSize="12px">
                                Save up to {loanAmount} over the life of your
                                loan!**
                            </P>
                            <P
                                textColor="blacK"
                                textSize="11px"
                                lineHeight={1.4}
                            >
                                Making in school payments saves you money and
                                time. Payments can be as low as $
                                {minimumInSchoolPaymentAmount} a month!
                            </P>
                        </CoinDescriptionContainer>
                    </Container>
                )}
            </InSchoolPaymentBlurbs>
            <Spacer sm />
            <Container flex row alignItems="center" justifyContent="center">
                <BlurbIndexCircle
                    isSelected={index === 0}
                    onClick={() => setIndex(0)}
                />
                <Spacer sm />
                <BlurbIndexCircle
                    isSelected={index === 1}
                    onClick={() => setIndex(1)}
                />
            </Container>
        </>
    )
}
