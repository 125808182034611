import {
    ApplicantAccount,
    useGetAccount,
    useGetApplicationForm,
    useGetStudentProfile,
    useStudentApplicationDetails,
} from "@wetradeup/student-apps-shared"
import {
    Spacer,
    HR,
    H2,
    H4,
    Container,
    P,
    TouchableContainer,
} from "@wetradeup/ui-kit"
import { format, isBefore } from "date-fns"
import { useHistory } from "react-router-dom"
import { Terms } from "../ConditionsAgreement"
import { ContentContainer } from "../../../ModalComponents"
import { SpinningLoader } from "../../atoms/SpinningLoader"
import { CancelApplicationModal } from "../CancelApplicationModal"
import styled from "@emotion/styled"
import { useEffect, useState } from "react"
import { CreateAccountForm } from "../IntroForm"
import { WrappedFormik } from "../../../../utils"
import { LogoutLabel } from "../../molecules/LogoutLabel"
import { useFynnLocation } from "../../../hooks/useFynnLocation"
import { ApplicationButton } from "../../../atoms/ApplicationButton"
import { UnderlineText } from "../../../atoms/UnderlineText"
import { SignedAndOnboarded } from "./SignedAndOnboarded"

export const ApplicationStatusContainer = styled(Container)`
    border: 1px solid ${(props) => props.theme.colors.palette.grey};
    border-radius: 6px;
`

interface OpenApplicationsProps {
    appFormId?: number
    setAppFormId: React.Dispatch<React.SetStateAction<number>>
    values: CreateAccountForm
    setValues: ReturnType<
        WrappedFormik<CreateAccountForm>["formik"]
    >["setValues"]
    handleSubmit: ReturnType<
        WrappedFormik<CreateAccountForm>["formik"]
    >["handleSubmit"]
    isCreateNewAppFormValid: boolean
}

const shouldShowMemberApplication = (
    memberAppQuery: ReturnType<typeof useStudentApplicationDetails>,
    appFormQuery: ReturnType<typeof useGetApplicationForm>
) => {
    if (!appFormQuery.data?.created_at) {
        return true
    }

    return (
        memberAppQuery.data?.created_at &&
        appFormQuery.data?.created_at &&
        isBefore(appFormQuery.data?.created_at, memberAppQuery.data?.created_at)
    )
}

const getSchoolName = (
    memberAppQuery: ReturnType<typeof useStudentApplicationDetails>,
    appFormQuery: ReturnType<typeof useGetApplicationForm>
) => {
    if (shouldShowMemberApplication(memberAppQuery, appFormQuery)) {
        return memberAppQuery.data?.school_name || ""
    }
    return appFormQuery.data?.school_name || ""
}

const getProgramName = (
    memberAppQuery: ReturnType<typeof useStudentApplicationDetails>,
    appFormQuery: ReturnType<typeof useGetApplicationForm>
) => {
    if (shouldShowMemberApplication(memberAppQuery, appFormQuery)) {
        return memberAppQuery.data?.program_name || ""
    }
    return appFormQuery.data?.program_name || ""
}

const getProgramStartDate = (
    memberAppQuery: ReturnType<typeof useStudentApplicationDetails>,
    appFormQuery: ReturnType<typeof useGetApplicationForm>
) => {
    if (
        shouldShowMemberApplication(memberAppQuery, appFormQuery) &&
        memberAppQuery.data?.start_date
    ) {
        return format(memberAppQuery.data?.start_date, "MM/dd/yyyy")
    }

    if (appFormQuery.data?.anticipated_start_date) {
        return format(appFormQuery.data?.anticipated_start_date, "MM/dd/yyyy")
    }

    return ""
}

const getMemberApplicationStatus = (
    memberAppQuery: ReturnType<typeof useStudentApplicationDetails>,
    studentProfileQuery: ReturnType<typeof useGetStudentProfile>
) => {
    if (
        memberAppQuery.data?.is_cosigner_reapplication_allowed &&
        memberAppQuery.data?.decision === "rejected"
    ) {
        return "submitted - pending decision"
    }

    if (
        !memberAppQuery.data?.is_cosigner_reapplication_allowed &&
        memberAppQuery.data?.decision === "rejected"
    ) {
        return "rejected"
    }

    if (memberAppQuery.data?.is_cancelled) {
        return "cancelled"
    }

    if (
        memberAppQuery.data?.underwriting_decision === "pending" &&
        memberAppQuery.data?.underwriting_recommendation === "locked"
    ) {
        return "credit profile locked"
    }

    if (memberAppQuery.data?.underwriting_decision === "pending") {
        return "submitted - pending decision"
    }

    if (memberAppQuery.data?.dropped_out_within_cancellation_period) {
        return "cancelled"
    }

    if (
        memberAppQuery.data?.underwriting_decision === "approved" &&
        memberAppQuery.data?.note_size_update_status ===
            "pending_student_request"
    ) {
        return "accepted - pending financing"
    }

    if (
        memberAppQuery.data?.underwriting_decision === "approved" &&
        studentProfileQuery.data?.bank_identity_verification &&
        studentProfileQuery.data?.bank_identity_verification !== "approved"
    ) {
        return "accepted - awaiting identity verification"
    }

    if (
        memberAppQuery.data?.underwriting_decision === "approved" &&
        memberAppQuery.data?.note_size_update_status ===
            "pending_school_approval"
    ) {
        return "accepted - awaiting school verification"
    }

    if (memberAppQuery.data?.graduation) {
        return "signed and completed"
    }

    if (memberAppQuery.data?.decision === "approved") {
        return "accepted - ready for signature"
    }

    return ""
}

const getApplicationStatus = (
    memberAppQuery: ReturnType<typeof useStudentApplicationDetails>,
    appFormQuery: ReturnType<typeof useGetApplicationForm>,
    studentProfileQuery: ReturnType<typeof useGetStudentProfile>
) => {
    if (shouldShowMemberApplication(memberAppQuery, appFormQuery)) {
        return getMemberApplicationStatus(memberAppQuery, studentProfileQuery)
    }
    if (appFormQuery.data?.status === "invalid") {
        return "invalid"
    }

    if (!!appFormQuery.data) {
        return "incomplete"
    }

    return ""
}

const isContractSigned = (
    memberApp: ReturnType<typeof useStudentApplicationDetails>
) => {
    const acceptance_contract = memberApp.data?.note?.contracts.find(
        (c) => c.contract_type === "acceptance_packet"
    )

    return !!acceptance_contract?.executed_on
}

const isOnboardedWithServicer = (
    memberApp: ReturnType<typeof useStudentApplicationDetails>
) => {
    return !!memberApp.data?.note?.onboarded_to_servicer_on
}

const noMemberAppOrAppForm = (
    memberAppQuery: ReturnType<typeof useStudentApplicationDetails>,
    appFormQuery: ReturnType<typeof useGetApplicationForm>
) => {
    return !memberAppQuery.data && !appFormQuery.data
}

export function ApplicationSelection({
    appFormId,
    setAppFormId,
    values,
    setValues,
    handleSubmit,
    isCreateNewAppFormValid,
}: OpenApplicationsProps) {
    const [showCancelApplicationModal, setShowCancelApplicationModal] =
        useState(false)
    const [isOnlyCancelling, setIsOnlyCancelling] = useState(false)
    const history = useHistory()
    const location = useFynnLocation()
    const accountQuery = useGetAccount()
    const applicationFormQuery = useGetApplicationForm(appFormId, 1)
    const memberAppQuery = useStudentApplicationDetails({
        enabled: !appFormId,
        retry: 1,
    })
    const studentProfileQuery = useGetStudentProfile({
        enabled: !appFormId,
        retry: 1,
    })

    const schoolName = getSchoolName(memberAppQuery, applicationFormQuery)
    const programName = getProgramName(memberAppQuery, applicationFormQuery)
    const programStartDate = getProgramStartDate(
        memberAppQuery,
        applicationFormQuery
    )
    const applicationStatus = getApplicationStatus(
        memberAppQuery,
        applicationFormQuery,
        studentProfileQuery
    )

    const isLoading =
        accountQuery.isLoading ||
        applicationFormQuery.isLoading ||
        memberAppQuery.isLoading

    const rejectedApp =
        shouldShowMemberApplication(memberAppQuery, applicationFormQuery) &&
        memberAppQuery.data?.decision === "rejected"
    const cancelledApp =
        shouldShowMemberApplication(memberAppQuery, applicationFormQuery) &&
        memberAppQuery.data?.is_cancelled
    const rejectedOrCancelledApp = rejectedApp || cancelledApp
    const droppedOutBeforeDisbursementApp =
        shouldShowMemberApplication(memberAppQuery, applicationFormQuery) &&
        memberAppQuery.data?.dropped_out_within_cancellation_period
    const signedApp =
        shouldShowMemberApplication(memberAppQuery, applicationFormQuery) &&
        isContractSigned(memberAppQuery)
    const signedAndOnboarded =
        shouldShowMemberApplication(memberAppQuery, applicationFormQuery) &&
        isContractSigned(memberAppQuery) &&
        isOnboardedWithServicer(memberAppQuery)
    const invalidAppForm =
        !shouldShowMemberApplication(memberAppQuery, applicationFormQuery) &&
        applicationFormQuery.data?.status === "invalid"

    const cancellationDisabled =
        signedApp ||
        rejectedOrCancelledApp ||
        noMemberAppOrAppForm(memberAppQuery, applicationFormQuery)

    const allowCreation =
        noMemberAppOrAppForm(memberAppQuery, applicationFormQuery) ||
        rejectedOrCancelledApp ||
        droppedOutBeforeDisbursementApp

    const cancellationAndCreationDisabled =
        cancellationDisabled && !allowCreation

    const continueApplicationDisabled =
        noMemberAppOrAppForm(memberAppQuery, applicationFormQuery) ||
        (rejectedApp &&
            !memberAppQuery.data?.is_cosigner_reapplication_allowed) ||
        cancelledApp ||
        droppedOutBeforeDisbursementApp ||
        invalidAppForm

    const handleContinueApplicationPress = (e: any) => {
        e.preventDefault()
        if (applicationFormQuery.data) {
            return history.push(
                `/application/${applicationFormQuery.data.school_id}`,
                { redirectToCurrentApplicationForm: true }
            )
        } else if (memberAppQuery.data) {
            return history.push("/isl-finalize/")
        } else {
            return history.push("/application/intro")
        }
    }

    const handleCreateNewApplication = async (e: any) => {
        e.preventDefault()
        setIsOnlyCancelling(false)
        if (!cancellationDisabled) {
            setShowCancelApplicationModal(true)
        } else if (rejectedOrCancelledApp || droppedOutBeforeDisbursementApp) {
            setAppFormId(undefined)
            return history.push("/application/intro", {
                createNewApplication: true,
            })
        } else if (isCreateNewAppFormValid) {
            await handleSubmit()
        } else {
            setAppFormId(undefined)
            return history.push("/application/intro")
        }
    }

    const handleCancelApplication = async (e: any) => {
        e.preventDefault()
        if (applicationFormQuery.data || !memberAppQuery.data.is_cancelled) {
            setIsOnlyCancelling(true)
            setShowCancelApplicationModal(true)
        }
    }

    const toggleModal = () => {
        setShowCancelApplicationModal(false)
    }

    const getApplicationSelectionComponent = () => {
        if (isLoading) {
            return (
                <Container paddingMd>
                    <SpinningLoader />
                </Container>
            )
        }
        if (signedAndOnboarded) {
            return (
                <SignedAndOnboarded
                    firstName={
                        (accountQuery?.data as ApplicantAccount)?.first_name
                    }
                />
            )
        } else {
            return (
                <>
                    <Spacer lg />
                    <ContentContainer>
                        {isLoading && <SpinningLoader />}
                        {!isLoading && (
                            <>
                                <H2 bold="light">
                                    Hi{", "}
                                    {
                                        (accountQuery?.data as ApplicantAccount)
                                            ?.first_name
                                    }
                                    {"!"}
                                </H2>
                                <Spacer lg />
                                <H4 textAlign="left" bold="light">
                                    {signedApp || rejectedOrCancelledApp
                                        ? "Previous Application"
                                        : "Current Application"}
                                </H4>
                                <Spacer lg />
                                <ApplicationStatusContainer
                                    paddingMd
                                    backgroundColor="medGrey"
                                >
                                    <Container row>
                                        <P>School: </P>
                                        <Spacer />
                                        <P>{schoolName}</P>
                                    </Container>

                                    <Spacer md />

                                    <Container row>
                                        <P>Program: </P>
                                        <Spacer />
                                        <P>{programName}</P>
                                    </Container>

                                    <Spacer md />

                                    <Container row>
                                        <P>Program start date: </P>
                                        <Spacer />
                                        <P>{programStartDate}</P>
                                    </Container>

                                    <Spacer md />

                                    <Container row>
                                        <P>Application status: </P>
                                        <Spacer />
                                        <P>{applicationStatus}</P>
                                    </Container>

                                    {!continueApplicationDisabled && (
                                        <Container minWidth={200}>
                                            <Spacer lg />
                                            <ApplicationButton
                                                fullWidth
                                                primary
                                                onPress={
                                                    handleContinueApplicationPress
                                                }
                                                title="Continue application"
                                            />
                                        </Container>
                                    )}

                                    <Spacer />

                                    {!cancellationDisabled && (
                                        <Container
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Spacer />
                                            <TouchableContainer
                                                onPress={
                                                    handleCancelApplication
                                                }
                                            >
                                                <UnderlineText>
                                                    Cancel application
                                                </UnderlineText>
                                            </TouchableContainer>
                                        </Container>
                                    )}
                                </ApplicationStatusContainer>
                                <Spacer lg />
                                <HR />
                                <Spacer lg />
                                <Container minWidth={200}>
                                    <ApplicationButton
                                        fullWidth
                                        disabled={
                                            cancellationAndCreationDisabled
                                        }
                                        onPress={handleCreateNewApplication}
                                        outline={
                                            !cancellationAndCreationDisabled &&
                                            "lightBlue"
                                        }
                                        title="Create new application"
                                        textColor={
                                            !cancellationAndCreationDisabled
                                                ? "lightBlue"
                                                : "white"
                                        }
                                    />
                                </Container>
                                {allowCreation && !cancellationDisabled ? (
                                    <>
                                        <Spacer />
                                        <Terms textAlign="left">
                                            Creating a new application will
                                            cancel your current application and
                                            delete it. Before creating a new
                                            application make sure that you no
                                            longer wish to complete your current
                                            application.
                                        </Terms>
                                    </>
                                ) : (
                                    <></>
                                )}
                                <Spacer lg />
                                <LogoutLabel />
                                <Spacer />
                            </>
                        )}
                    </ContentContainer>
                    <Spacer lg />
                    <CancelApplicationModal
                        appFormId={appFormId}
                        setAppFormId={setAppFormId}
                        open={showCancelApplicationModal}
                        toggleOpen={toggleModal}
                        isCreateNewAppFormValid={isCreateNewAppFormValid}
                        isCancellingMemberApp={shouldShowMemberApplication(
                            memberAppQuery,
                            applicationFormQuery
                        )}
                        isOnlyCancelling={isOnlyCancelling}
                        values={values}
                        setValues={setValues}
                        handleSubmit={handleSubmit}
                    />
                </>
            )
        }
    }

    useEffect(() => {
        if (location.state?.appliedToTheWrongProgram) {
            setIsOnlyCancelling(false)
            setShowCancelApplicationModal(true)
        }
    }, [location.state?.appliedToTheWrongProgram])

    return getApplicationSelectionComponent()
}
