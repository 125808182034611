import { useState, useEffect, useMemo } from "react"
import { Select, P, Spacer, Card } from "@wetradeup/ui-kit"
import { PublicSchool, useGetSchools } from "@wetradeup/student-apps-shared"
import { ProgramSelect } from "./ProgramSelect"
import { CampusSelect } from "./CampusSelect"
import { StartDateSelect } from "../StartDateSelect"
import {
    getStartDates,
    StartDateOption,
    WrappedFormik,
} from "../../../../utils"
import { CreateAccountForm } from "../IntroForm"
import { addFakeSchools } from "../../../../obfuscate"
import { UpdateApplicationBasicInfoForm } from "../../BasicInfoPage"
import { SpinningLoader } from "../../atoms/SpinningLoader"

interface ProgramSelectionFormProps {
    school?: PublicSchool
    setSchoolId: React.Dispatch<React.SetStateAction<number>>
    setSchoolName: React.Dispatch<React.SetStateAction<string>>
    setFieldValue:
        | ReturnType<
              WrappedFormik<CreateAccountForm>["formik"]
          >["setFieldValue"]
        | ReturnType<
              WrappedFormik<UpdateApplicationBasicInfoForm>["formik"]
          >["setFieldValue"]
    values:
        | ReturnType<WrappedFormik<CreateAccountForm>["formik"]>["values"]
        | ReturnType<
              WrappedFormik<UpdateApplicationBasicInfoForm>["formik"]
          >["values"]
    showOnlyLiveSchool?: boolean
}

interface SelectedSchoolOption {
    value: number
    label: string
    trade: string
    is_live: boolean
}

export function ProgramSelectionForm({
    school,
    setSchoolId,
    setSchoolName,
    setFieldValue,
    values,
    showOnlyLiveSchool = false,
}: ProgramSelectionFormProps) {
    const [selectedSchoolOption, setSelectedSchoolOption] =
        useState<SelectedSchoolOption>()
    const [schools, setSchools] = useState([])
    const schoolsQuery = useGetSchools()

    const program = useMemo(() => {
        if (school && values.program) {
            return school.programs.find(
                (program) => program.program_name === values.program
            )
        }
    }, [school, values.program])

    const hasCampuses =
        school &&
        school.programs &&
        !!school.programs.find((p) => p.campuses.length > 0)

    const campus = useMemo(() => {
        if (program && values.campus) {
            return program.campuses.find(
                (campus) => campus.id === values.campus
            )
        }
    }, [program, values.campus])

    const startDates: StartDateOption[] | undefined = useMemo(() => {
        if (program) {
            return getStartDates(program.valid_start_dates)
        }
    }, [program])

    useEffect(() => {
        if (schoolsQuery.isSuccess) {
            let schoolsProcessed = (schoolsQuery.data || [])
                .filter((school) => !showOnlyLiveSchool || school.is_live)
                .map((school) => ({
                    value: school.id,
                    label: school.school_name,
                    trade: school.trade,
                    is_live: school.is_live,
                }))

            if (!showOnlyLiveSchool) {
                schoolsProcessed = addFakeSchools(schoolsProcessed)
            }
            setSchools(schoolsProcessed)
        }
    }, [schoolsQuery.data, schoolsQuery.isSuccess, showOnlyLiveSchool])

    useEffect(() => {
        if (school && schools.length > 0) {
            setSelectedSchoolOption(schools.find((s) => s.value === school.id))
        }
    }, [school, schools])

    useEffect(() => {
        if (program?.program_name) {
            setFieldValue("program", program.program_name)
        }
    }, [program])

    useEffect(() => {
        if (campus?.campus_name) {
            setFieldValue("campus", campus.id)
        }
    }, [campus])

    const programCardBdColor = values.anticipated_start_date
        ? "green"
        : "medGrey"

    return (
        <>
            <Select
                label="School name"
                name="school"
                placeholder="Choose your school"
                searchable
                options={schools}
                disabled={!!school}
                value={selectedSchoolOption?.label}
                onOptionSelected={(_fieldName, val: SelectedSchoolOption) => {
                    setSelectedSchoolOption(val)
                    setSchoolId(val.value)
                    setSchoolName(val.label)
                    setFieldValue("school", val.label)
                    setFieldValue("program", "")
                    setFieldValue("campus", null)
                    setFieldValue("anticipated_start_date", null)
                }}
                flex={0}
                onClear={() => {
                    setSelectedSchoolOption(undefined)
                    setSchoolId(undefined)
                    setSchoolName("")
                    setFieldValue("school", "")
                    setFieldValue("program", "")
                    setFieldValue("campus", null)
                    setFieldValue("anticipated_start_date", null)
                }}
            />
            <Spacer />
            {!school && selectedSchoolOption && <SpinningLoader />}
            {school && selectedSchoolOption && (
                <>
                    <ProgramSelect
                        school={school}
                        program={program}
                        setFieldValue={setFieldValue}
                    />
                    <Spacer />
                </>
            )}
            {school && program && (
                <>
                    {hasCampuses && (
                        <CampusSelect
                            program={program}
                            campus={campus}
                            setFieldValue={setFieldValue}
                        />
                    )}
                    <Spacer />
                    <Card disableShadow border={programCardBdColor}>
                        <Spacer size={6} />
                        <P textAlign="center">
                            {" "}
                            Your program:{" "}
                            <P weight={800}> {program.program_name} </P>{" "}
                        </P>
                        <Spacer size={4} />
                        <P textAlign="center">
                            Please confirm the dates of the program you're
                            applying to:
                        </P>
                        <Spacer />
                        <StartDateSelect
                            startDates={startDates}
                            program={program}
                            values={values}
                            setFieldValue={setFieldValue}
                        />
                    </Card>
                </>
            )}
        </>
    )
}
