export function WelcomePageCheckMark() {
    return (
        <svg
            width="26"
            height="25"
            viewBox="0 0 26 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.98242 15.1343L8.3177 21.4696L23.5969 2.09106"
                stroke="white"
                stroke-width="3.72664"
            />
        </svg>
    )
}
