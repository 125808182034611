export function Stop(props) {
    return (
        <svg
            viewBox="0 0 36 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M18 0.998218C21.896 1.00048 25.6866 2.26611 28.8025 4.60492C31.9183 6.94406 34.1916 10.2304 35.2811 13.9712C36.3709 17.7116 36.2183 21.7047 34.8462 25.351C34.6601 25.8487 34.2834 26.2517 33.7996 26.4712C33.3157 26.6909 32.7643 26.7087 32.2673 26.5212C31.7701 26.3336 31.368 25.9559 31.1498 25.4715C30.9316 24.9869 30.915 24.4357 31.1041 23.939C32.3469 20.6544 32.3117 17.0232 31.0057 13.7633C29.6997 10.5033 27.2176 7.85236 24.0513 6.33453C20.8845 4.81683 17.2635 4.54259 13.9039 5.56613C10.5448 6.58994 7.6919 8.83714 5.91012 11.863C4.12795 14.8889 3.54636 18.4735 4.28037 21.9076C5.01437 25.3417 7.01048 28.3753 9.87367 30.4086C12.7369 32.4419 16.259 33.3266 19.7433 32.8876C23.2275 32.4489 26.42 30.7184 28.6899 28.0385C29.1517 27.4934 29.8691 27.2357 30.5722 27.363C31.2753 27.4901 31.8574 27.983 32.0987 28.6555C32.34 29.3279 32.2042 30.0783 31.7424 30.6234C29.128 33.7139 25.5482 35.8328 21.5809 36.6381C17.614 37.4431 13.4912 36.8877 9.87843 35.0613C6.26594 33.2348 3.37472 30.244 1.67117 26.5719C-0.0321236 22.8999 -0.447983 18.7606 0.490707 14.8232C1.42959 10.8857 3.66831 7.37961 6.84514 4.87121C10.0222 2.36262 13.9518 0.998047 17.9999 0.998047L18 0.998218Z"
                fill={props.color || "#2D2AB1"}
            />
            <path
                d="M20 24.9978V12.9984C20 12.2838 20.3812 11.6236 21.0002 11.2663C21.619 10.9092 22.3813 10.9092 23 11.2663C23.6188 11.6236 24 12.2838 24 12.9984V24.9978C24 25.7125 23.6188 26.3727 23 26.73C22.3813 27.0871 21.619 27.0871 21.0002 26.73C20.3812 26.3727 20 25.7125 20 24.9978Z"
                fill={props.color || "#2D2AB1"}
            />
            <path
                d="M12 24.9978V12.9984C12 12.2838 12.3812 11.6236 13 11.2663C13.6187 10.9092 14.381 10.9092 14.9998 11.2663C15.6188 11.6236 16 12.2838 16 12.9984V24.9978C16 25.7125 15.6188 26.3727 14.9998 26.73C14.381 27.0871 13.6187 27.0871 13 26.73C12.3812 26.3727 12 25.7125 12 24.9978Z"
                fill={props.color || "#2D2AB1"}
            />
        </svg>
    )
}
