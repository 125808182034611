import { useTheme } from "@emotion/react"
import { Container, FynnFullLetterLogo, P, Spacer } from "@wetradeup/ui-kit"
import { WelcomePageCheckMark } from "./WelcomePageCheckMark"
import { HiddenBr } from "../../atoms/HiddenBr"

const BENEFITS = [
    "Payment Pauses",
    "Career Support",
    "Optional Co-signers",
    "Holistic Underwriting",
]

export function WelcomePageMessageLong() {
    const theme = useTheme()
    return (
        <>
            <Spacer />
            <FynnFullLetterLogo white />
            <Spacer lg />
            <P
                textColor="white"
                fontFamily="primary"
                bold
                textSize={theme.sizings.fontSizeXl}
                lineHeight={theme.sizings.fontSizeXl}
            >
                Here's why more
                <HiddenBr />
                students choose Fynn
            </P>
            <Spacer xl />
            {BENEFITS.map((benefit) => (
                <>
                    <Container flex row>
                        <WelcomePageCheckMark />
                        <Spacer />
                        <P
                            textColor="white"
                            fontFamily="primary"
                            textSize={theme.sizings.fontSizeMdLg}
                            lineHeight={theme.sizings.fontSizeMdLg}
                        >
                            {benefit}
                        </P>
                    </Container>
                    <Spacer />
                </>
            ))}
        </>
    )
}
