import { P, TouchableContainer, Spacer, Container } from "@wetradeup/ui-kit"
import { useLogout } from "@wetradeup/student-apps-shared"
import styled from "@emotion/styled"
import { useHistory } from "react-router-dom"

const LogoutText = styled(P)`
    text-decoration: underline;
`

export function LogoutLabel() {
    const logout = useLogout()
    const history = useHistory()

    const handleLogout = async () => {
        await logout()
        history.push("/")
    }

    return (
        <Container width="100%" alignItems="center">
            <Spacer />
            <TouchableContainer onPress={handleLogout}>
                <LogoutText textAlign="center" textColor="black">
                    {" "}
                    Log out{" "}
                </LogoutText>
            </TouchableContainer>
            <Spacer />
        </Container>
    )
}
