export function Falling() {
    return (
        <svg
            width="297"
            height="135"
            viewBox="0 0 297 135"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M23.37 123.009C23.5236 123.736 23.9255 124.392 24.4988 124.859C25.078 125.326 25.7991 125.58 26.5379 125.58L126.405 128.269L214.215 125.58C214.676 125.58 215.131 125.479 215.545 125.284C215.958 125.083 216.319 124.794 216.609 124.439C216.898 124.085 217.105 123.665 217.211 123.222C217.318 122.773 217.324 122.312 217.229 121.856L195.136 17.9113C193.996 12.5506 188.729 8.20654 183.369 8.20654H8.67094C3.31026 8.20654 -0.111821 12.5506 1.02887 17.9113L23.37 123.009Z"
                fill="#EAEEEF"
                stroke="#344F5B"
                stroke-width="0.865741"
            />
            <path
                d="M33.849 112.938C33.849 112.943 33.8549 112.943 33.8608 112.949C33.8667 112.955 33.8726 112.955 33.8785 112.955H202.684C202.69 112.955 202.69 112.955 202.696 112.955C202.701 112.949 202.701 112.949 202.701 112.943C202.707 112.943 202.707 112.943 202.707 112.938C202.707 112.932 202.707 112.926 202.707 112.926L183.138 20.8485C183.138 20.8426 183.132 20.8367 183.126 20.8367C183.126 20.8308 183.121 20.8308 183.109 20.8308H14.3094C14.3035 20.8308 14.2975 20.8308 14.2916 20.8308L14.2857 20.8367C14.2798 20.8426 14.2798 20.8485 14.2798 20.8485C14.2739 20.8545 14.2739 20.8544 14.2798 20.8604L33.849 112.938Z"
                fill="#EAEEEF"
                stroke="#344F5B"
                stroke-width="0.865741"
            />
            <path
                opacity="0.3"
                d="M42.1353 98.6346C42.3067 99.4325 42.744 100.148 43.3824 100.662C44.0148 101.176 44.8126 101.46 45.6283 101.46H211.567C211.573 101.46 211.573 101.46 211.573 101.454C211.579 101.454 211.585 101.454 211.585 101.448C211.591 101.442 211.591 101.442 211.591 101.436C211.591 101.436 211.591 101.43 211.591 101.424L192.022 9.35299C192.022 9.34708 192.016 9.34115 192.01 9.33524C192.01 9.33524 191.998 9.32935 191.992 9.32935H23.1867C23.1808 9.32935 23.1808 9.32933 23.1749 9.33524L23.169 9.34116C23.1631 9.34116 23.1631 9.34708 23.1631 9.35299C23.1631 9.35299 23.1631 9.3589 23.1631 9.36481L42.1353 98.6346Z"
                fill="#5E767C"
            />
            <path
                d="M50.6761 90.4134C50.8475 91.2113 51.2849 91.9265 51.9173 92.4407C52.5556 92.9549 53.3476 93.2386 54.1632 93.2386H217.224C217.578 93.2386 217.921 93.1617 218.24 93.0081C218.553 92.8603 218.837 92.6357 219.056 92.3639C219.28 92.092 219.434 91.7728 219.517 91.43C219.6 91.0872 219.606 90.7326 219.535 90.3898L202.844 11.8827L124.319 6.53979L33.9854 11.8827L50.6761 90.4134Z"
                fill="#EAEEEF"
                stroke="#344F5B"
                stroke-width="0.865741"
            />
            <path
                d="M33.9856 11.8827H202.85L201.154 3.93921C200.988 3.13541 200.545 2.42025 199.913 1.90605C199.274 1.39185 198.482 1.10815 197.667 1.10815H34.6062C34.2516 1.10815 33.9088 1.19089 33.5896 1.33865C33.2705 1.49232 32.9927 1.71102 32.774 1.98289C32.5494 2.25477 32.3957 2.57392 32.313 2.91672C32.2303 3.25952 32.2244 3.61414 32.3012 3.95694L33.9856 11.8827Z"
                fill="#EAEEEF"
                stroke="#344F5B"
                stroke-width="0.865741"
            />
            <path
                d="M24.8834 125.58C24.6292 125.58 24.381 125.68 24.1978 125.864C24.0145 126.047 23.9141 126.295 23.9141 126.549V127.962C23.9141 129.605 24.5701 131.183 25.7285 132.341C26.887 133.5 28.4591 134.156 30.1022 134.156H290.139C291.783 134.156 293.355 133.5 294.513 132.341C295.677 131.183 296.328 129.605 296.328 127.962V126.549C296.328 126.295 296.227 126.047 296.044 125.864C295.861 125.68 295.612 125.58 295.358 125.58H24.8834Z"
                fill="#EAEEEF"
                stroke="#344F5B"
                stroke-width="0.865741"
            />
            <path
                opacity="0.3"
                d="M25.0488 131.532C25.6162 132.341 26.3786 133.003 27.2593 133.464C28.134 133.92 29.1092 134.156 30.1022 134.156H290.139C291.132 134.156 292.108 133.92 292.988 133.464C293.863 133.003 294.619 132.341 295.193 131.532H25.0488Z"
                fill="#5E767C"
            />
            <path
                d="M52.7391 129.965H44.5473C44.175 129.965 43.8145 129.818 43.5485 129.552C43.2884 129.292 43.1407 128.931 43.1348 128.559C43.1407 128.181 43.2884 127.826 43.5485 127.56C43.8145 127.294 44.175 127.146 44.5473 127.146H52.7391C53.1114 127.146 53.472 127.294 53.7379 127.56C54.0039 127.826 54.1516 128.181 54.1516 128.559C54.1516 128.931 54.0039 129.292 53.7379 129.552C53.472 129.818 53.1114 129.965 52.7391 129.965Z"
                fill="#EAEEEF"
                stroke="#344F5B"
                stroke-width="0.865741"
            />
            <path
                d="M80.8843 129.965H61.1733C60.7951 129.965 60.4345 129.818 60.1745 129.558C59.9085 129.292 59.7607 128.931 59.7607 128.559C59.7607 128.181 59.9085 127.826 60.1745 127.56C60.4345 127.294 60.7951 127.146 61.1733 127.146H80.8843C81.2567 127.146 81.6172 127.294 81.8832 127.56C82.1491 127.826 82.2969 128.181 82.2969 128.559C82.2969 128.931 82.1491 129.292 81.8832 129.558C81.6172 129.818 81.2567 129.965 80.8843 129.965Z"
                fill="#EAEEEF"
                stroke="#344F5B"
                stroke-width="0.865741"
            />
            <path
                d="M211.449 125.852V126.945C211.449 127.53 211.219 128.092 210.805 128.506C210.391 128.913 209.836 129.15 209.251 129.15H189.303C188.718 129.15 188.157 128.913 187.749 128.506C187.329 128.092 187.104 127.53 187.104 126.945V125.852"
                stroke="#344F5B"
                stroke-width="0.865741"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M159.551 42.9408C164.491 59.1811 156.607 75.798 142.175 80.1882C127.743 84.5783 111.941 75.1665 107.001 58.9262C102.061 42.6859 109.945 26.069 124.377 21.6789C138.809 17.2887 154.611 26.7006 159.551 42.9408Z"
                stroke="#344F5B"
                stroke-width="1.73148"
            />
            <path
                d="M119.092 42.5607L122.479 38.532L133.316 47.6427L141.998 37.3157L146.253 40.8932L137.572 51.2202L148.41 60.3308L145.023 64.3595L134.185 55.2488L125.456 65.6325L121.201 62.055L129.93 51.6713L119.092 42.5607Z"
                fill="#344F5B"
            />
        </svg>
    )
}
