import { useState, useEffect, useCallback } from "react"
import { useSendPhoneVerificationApp } from "@wetradeup/student-apps-shared"
import { TouchableContainer, Caption } from "@wetradeup/ui-kit"
import { ClickableCaption } from "../atoms/ClickableCaption"

export const ResendPhone = ({ schoolId }: { schoolId: number }) => {
    const [textSent, setTextSent] = useState(false)
    const sendPhoneVerification = useSendPhoneVerificationApp()

    useEffect(() => {
        if (textSent) {
            setTimeout(() => {
                setTextSent(false)
            }, 5000)
        }
    }, [textSent])

    const handleSendPhoneVerification = useCallback(async () => {
        await sendPhoneVerification.mutateAsync(schoolId)
        setTextSent(true)
    }, [schoolId, sendPhoneVerification])

    return (
        <>
            {textSent ? (
                <Caption weight={600} textAlign="center">
                    {" "}
                    Sent!{" "}
                </Caption>
            ) : (
                <TouchableContainer onPress={handleSendPhoneVerification}>
                    <ClickableCaption textAlign="center">
                        {" "}
                        Text me again{" "}
                    </ClickableCaption>
                </TouchableContainer>
            )}
        </>
    )
}
