import { Anchor } from "@wetradeup/ui-kit"
import { Terms } from "./ConditionsAgreement"

export function CreateAccountConditions() {
    return (
        <Terms textAlign="center">
            By clicking "Create account" or "Login with existing account", you
            agree to Fynn's{" "}
            <Anchor to="https://fynncredit.com/websiteTOS.htm" textColor="blue">
                Terms of Use
            </Anchor>{" "}
            and{" "}
            <Anchor to="https://fynncredit.com/privacy" textColor="blue">
                Privacy Policy
            </Anchor>
            , and{" "}
            <Anchor
                to="https://legal.fynncredit.com/communicationconsent.html"
                textColor="blue"
            >
                Consent to Electronic Communications.
            </Anchor>
            You also authorize Fynn to send you text messages with account
            notifications. Message & data rates may apply. Text STOP to
            unsubscribe.
        </Terms>
    )
}
