import { Container, Modal, Spacer, Card, P } from "@wetradeup/ui-kit"
import { ContentContainer } from "../../../ModalComponents"
import styled from "@emotion/styled"
import { breakpoints } from "../../../../constants"
import { WarningIcon } from "../../atoms/WarningIcon"
import { ApplicationButton } from "../../../atoms/ApplicationButton"

interface NotFoundAddressConfirmationModalProps {
    open: boolean
    addressToConfirm: {
        street: String
        street_line_two: String
        city: String
        state: String
        zip: String
    }
    toggleModal: () => void
    handleConfirm: () => void
}

const ResponsiveContainer = styled(Container)`
    @media (max-width: ${breakpoints.mobileLg}) {
        padding-left: 0px;
        padding-right: 0px;
    }
`

export function NotFoundAddressConfirmationModal({
    open,
    toggleModal,
    addressToConfirm,
    handleConfirm,
}: NotFoundAddressConfirmationModalProps) {
    return (
        <Modal visible={open} width="100%">
            <Container
                width={[
                    [700, "md"],
                    [860, "lg"],
                ]}
            >
                <Card paddingMd>
                    <ContentContainer
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Spacer />
                        <WarningIcon />
                        <Spacer lg />
                        <ResponsiveContainer padding={["sm", "lg"]}>
                            <P
                                textAlign="center"
                                textSize="24px"
                                lineHeight="32px"
                                textColor="black"
                            >
                                We could not verify your address. Please confirm
                                the address you supplied is correct:
                            </P>
                        </ResponsiveContainer>
                        <ResponsiveContainer
                            padding={["sm", "xl"]}
                            width="100%"
                        >
                            <P
                                textAlign="center"
                                textSize="20px"
                                lineHeight="28px"
                                textColor="black"
                            >
                                {addressToConfirm.street}
                            </P>
                            {addressToConfirm.street_line_two && (
                                <P
                                    textAlign="center"
                                    textSize="20px"
                                    lineHeight="28px"
                                    textColor="black"
                                >
                                    {addressToConfirm.street_line_two}
                                </P>
                            )}
                            <P
                                textAlign="center"
                                textSize="20px"
                                lineHeight="28px"
                                textColor="black"
                            >
                                {addressToConfirm.city},{" "}
                                {addressToConfirm.state} {addressToConfirm.zip}
                            </P>
                            <Spacer />
                        </ResponsiveContainer>
                        <Spacer />
                        <Container minWidth={200} width={300} maxWidth="95vw">
                            <ApplicationButton
                                onPress={handleConfirm}
                                title="This is correct"
                            />
                            <Spacer />
                            <ApplicationButton
                                onPress={toggleModal}
                                outline="blue"
                                title="Go back"
                                textColor="blue"
                            />
                            <Spacer />
                        </Container>
                    </ContentContainer>
                </Card>
            </Container>
        </Modal>
    )
}
