import { useTheme } from "@emotion/react"
import { useHistory } from "react-router-dom"
import { usePasswordLogin } from "@wetradeup/student-apps-shared"
import {
    Container,
    FynnFullLetterLogoLoanOrientation,
    Input,
    P,
    PasswordInput,
    SecureLabel,
    Spacer,
} from "@wetradeup/ui-kit"
import * as Yup from "yup"
import { useFormik } from "formik"
import { LoginPage } from "../molecules/DefaultPage"
import { ApplicationButton } from "../../atoms/ApplicationButton"
import { SpinningLoader } from "../../application/atoms/SpinningLoader"

const valSchema = Yup.object().shape({
    email: Yup.string().required("Please enter your email."),
    password: Yup.string().required("Please enter password."),
})

interface LoginForm {
    email: string
    password: string
}

export function ExitCounselingAdminLoginPage() {
    const theme = useTheme()
    const history = useHistory()
    const exitCounselingMutation = usePasswordLogin()
    const onSubmit = async () => {
        await exitCounselingMutation.mutateAsync({ ...values, source: "web" })
        history.push("/loan-orientation/admin/students")
    }
    const { handleSubmit, handleChange, values, errors } = useFormik<LoginForm>(
        {
            initialValues: {
                email: "",
                password: "",
            },
            validationSchema: valSchema,
            onSubmit,
        }
    )

    return (
        <LoginPage>
            <Container width="100%">
                <Container justifyContent="center" alignItems="center">
                    <FynnFullLetterLogoLoanOrientation />
                </Container>
                <Spacer lg />
                <P
                    bold
                    textSize={theme.sizings.fontSizeMd}
                    textColor={theme.colors.palette.black}
                    textAlign="center"
                >
                    Please type your password and email
                </P>
                <Spacer lg />
                <Input
                    name="email"
                    placeholder="email"
                    onChange={handleChange}
                    value={values.email}
                    error={errors.email}
                />
                <Spacer lg />
                <PasswordInput
                    name="password"
                    placeholder="Password"
                    onChange={handleChange}
                    value={values.password}
                    error={errors.password}
                />
                <Spacer lg />
                {exitCounselingMutation.isLoading ? (
                    <SpinningLoader />
                ) : (
                    <ApplicationButton
                        fullWidth
                        alignCenter
                        title="Submit"
                        buttonColor="newBlue"
                        disabled={Object.keys(errors).length > 0}
                        onPress={() => handleSubmit()}
                    />
                )}
            </Container>
            <Spacer lg />
            <SecureLabel />
        </LoginPage>
    )
}
