import React, { Dispatch, SetStateAction, useState } from "react"

export interface IBankVerificationContext {
    isUpdatingPlaid: boolean
    setIsUpdatingPlaid: Dispatch<SetStateAction<boolean>>
    showBankVerificationFailedModal: boolean
    setShowBankVerificationModal: Dispatch<SetStateAction<boolean>>
    showFrozenModal: boolean
    setShowFrozenModal: Dispatch<SetStateAction<boolean>>
    remainingAttempts: number
    setRemainingAttempts: Dispatch<SetStateAction<number>>
    showInternalErrorModal: boolean
    setShowInternalErrorModal: Dispatch<SetStateAction<boolean>>
    showRejectedModal: boolean
    setShowRejectedModal: Dispatch<SetStateAction<boolean>>
    error: string
    setError: Dispatch<SetStateAction<string>>
}

export const MAX_BANK_VERIFICATION_ATTEMPTS = 3

export const BankVerificationContext =
    React.createContext<IBankVerificationContext>({
        isUpdatingPlaid: false,
        setIsUpdatingPlaid: () => {},
        showBankVerificationFailedModal: false,
        setShowBankVerificationModal: () => {},
        showFrozenModal: false,
        setShowFrozenModal: () => {},
        remainingAttempts: MAX_BANK_VERIFICATION_ATTEMPTS,
        setRemainingAttempts: () => {},
        showInternalErrorModal: false,
        setShowInternalErrorModal: () => {},
        showRejectedModal: false,
        setShowRejectedModal: () => {},
        error: "",
        setError: () => {},
    })

export const BankVerificationProvider: React.FC = ({ children }) => {
    const [isUpdatingPlaid, setIsUpdatingPlaid] = useState(false)
    const [showBankVerificationFailedModal, setShowBankVerificationModal] =
        useState(false)
    const [showFrozenModal, setShowFrozenModal] = useState(false)
    const [remainingAttempts, setRemainingAttempts] = useState<number>(
        MAX_BANK_VERIFICATION_ATTEMPTS
    )
    const [showInternalErrorModal, setShowInternalErrorModal] = useState(false)
    const [showRejectedModal, setShowRejectedModal] = useState(false)

    const [error, setError] = useState("")
    return (
        <BankVerificationContext.Provider
            value={{
                isUpdatingPlaid,
                setIsUpdatingPlaid,
                showBankVerificationFailedModal,
                setShowBankVerificationModal,
                showFrozenModal,
                setShowFrozenModal,
                remainingAttempts,
                setRemainingAttempts,
                showInternalErrorModal,
                setShowInternalErrorModal,
                showRejectedModal,
                setShowRejectedModal,
                error,
                setError,
            }}
        >
            {children}
        </BankVerificationContext.Provider>
    )
}
