import { useEffect } from "react"
import { Container, H2, Card, Spacer, P } from "@wetradeup/ui-kit"
import { ApplicationCard, ContentContainer } from "../ModalComponents"
import { useStudentApplicationDetails } from "@wetradeup/student-apps-shared"
import { toTop } from "../../utils"
import { SandTimer } from "./icons/SandTimer"
import styled from "@emotion/styled"
import { breakpoints } from "../../constants"
import { useTheme } from "@emotion/react"

interface AwaitingSchoolConfirmationProps {
    studentApplicationDetails: ReturnType<typeof useStudentApplicationDetails>
}
const ResponsiveContainer = styled(Container)`
    @media (max-width: ${breakpoints.mobileLg}) {
        width: 68px;
    }
    @media (min-width: ${breakpoints.mobileLg}) {
        width: 92px;
    }
`
export default function AwaitingSchoolConfirmation({
    studentApplicationDetails,
}: AwaitingSchoolConfirmationProps) {
    const theme = useTheme()
    const hasCosigner =
        studentApplicationDetails.data?.application_type === "with_cosigner"

    useEffect(() => {
        toTop()
    }, [])

    return (
        <Card
            disableShadow
            paddingMd
            width={[
                [550, "sm"],
                [550, "md"],
                [750, "lg"],
            ]}
        >
            <ApplicationCard
                alignItems="center"
                justifyContent="center"
                disableCard
                border
                paddingLg
                width="100%"
            >
                <Container
                    maxWidth={"600px"}
                    alignItems="center"
                    justifyContent="center"
                >
                    <Spacer />
                    <ResponsiveContainer
                        justifyContent="center"
                        alignItems="center"
                    >
                        <SandTimer />
                    </ResponsiveContainer>
                    <Spacer />
                    <H2 bold="light">
                        {hasCosigner
                            ? "Two things still need to happen before you can sign your loan agreement:"
                            : "We've verified your identity! Now, we're just waiting on your school."}
                    </H2>

                    {hasCosigner && (
                        <>
                            <Spacer lg />
                            <Card
                                disableShadow
                                flex
                                row
                                width="100%"
                                paddingLg
                                border="orange"
                                alignItems="center"
                            >
                                <P
                                    textColor="black"
                                    textSize={theme.sizings.fontSizeMd}
                                >
                                    Your school must confirm your loan amount
                                </P>
                            </Card>
                            <Spacer lg />
                            <Card
                                disableShadow
                                flex
                                row
                                width="100%"
                                paddingLg
                                border="orange"
                                alignItems="center"
                            >
                                <P
                                    textColor="black"
                                    textSize={theme.sizings.fontSizeMd}
                                >
                                    Your co-signer must verify their identity{" "}
                                </P>
                            </Card>
                        </>
                    )}
                    <ContentContainer>
                        <Spacer lg />

                        {!hasCosigner && (
                            <>
                                <P textColor="black" textAlign="center">
                                    Before you can sign your loan contract, your
                                    school needs to confirm your start date and
                                    tuition amount. On average, this takes 24-48
                                    hours for your school to complete.
                                </P>
                                <Spacer />
                            </>
                        )}

                        {hasCosigner ? (
                            <P textAlign="center">
                                This usually takes around 24 hours. In the
                                meantime, just sit back, relax, and we'll let
                                you know when this step is complete! You can
                                close this window.
                            </P>
                        ) : (
                            <P textColor="black" textAlign="center" bold>
                                We will contact you when it's time to come back.
                                <br /> You can close this window now.
                            </P>
                        )}
                        <Spacer lg />
                    </ContentContainer>
                </Container>
            </ApplicationCard>
        </Card>
    )
}
