import { useEffect, useContext, useMemo } from "react"
import { Container, H2, P, Spacer, TouchableContainer } from "@wetradeup/ui-kit"
import { HiddenBr } from "../../atoms/HiddenBr"
import {
    useSearchEmployers,
    AuthContext,
    PublicSchool,
} from "@wetradeup/student-apps-shared"
import { format } from "date-fns"
import { SubmissionButton } from "../../atoms/SubmissionButton"
import { ContentContainer } from "../../../ModalComponents"
import { useHistory } from "react-router-dom"
import { CreateAccountForm } from "../IntroForm"
import { toTop, WrappedFormik } from "../../../../utils"
import { ProgramSelectionForm } from "./ProgramSelectionForm"
import { UnderlineText } from "../../../atoms/UnderlineText"
import { Logo } from "../Logo"
import { API_URL } from "../../../../config"

interface ProgramSelectionPageProps {
    school?: PublicSchool
    setSchoolId: React.Dispatch<React.SetStateAction<number>>
    setSchoolName: React.Dispatch<React.SetStateAction<string>>
    setFieldValue: ReturnType<
        WrappedFormik<CreateAccountForm>["formik"]
    >["setFieldValue"]
    isCreateNewAppFormValid: boolean
    values: ReturnType<WrappedFormik<CreateAccountForm>["formik"]>["values"]
    errors: ReturnType<WrappedFormik<CreateAccountForm>["formik"]>["errors"]
    handleSubmit: ReturnType<
        WrappedFormik<CreateAccountForm>["formik"]
    >["handleSubmit"]
}

export function ProgramSelectionPage({
    school,
    setSchoolId,
    setSchoolName,
    setFieldValue,
    isCreateNewAppFormValid,
    values,
    errors,
    handleSubmit,
}: ProgramSelectionPageProps) {
    const history = useHistory()
    const { authProfile } = useContext(AuthContext)

    const hasCampuses =
        school &&
        school.programs &&
        !!school.programs.find((p) => p.campuses.length > 0)

    const program = useMemo(() => {
        if (school && values.program) {
            return school.programs.find(
                (program) => program.program_name === values.program
            )
        }
    }, [school, values.program])

    const employersQuery = useSearchEmployers(values.campus, program?.id)

    const nextPage = async () => {
        if (authProfile && isCreateNewAppFormValid) {
            await handleSubmit()
            toTop()
        } else {
            history.push(
                `/application/intro/account?schoolId=${school.id}&program=${
                    values?.program
                }&startDate=${format(
                    values?.anticipated_start_date,
                    "yyyy-MM-dd"
                )}${values.campus ? `&campusId=${values.campus}` : ""}`
            )
            toTop()
        }
    }

    const handleClickSignIn = (e) => {
        history.push("/login")
    }

    useEffect(() => {
        if (authProfile) {
            setFieldValue("is_creating_new_account", false)
        }
    }, [authProfile])

    const disabled =
        !school ||
        (hasCampuses && !employersQuery.isSuccess) ||
        !values.program ||
        errors.program ||
        !values.anticipated_start_date ||
        (hasCampuses && !values.campus)

    return (
        <>
            {school && (
                <Container row alignItems="center" justifyContent="center">
                    <Logo
                        source={`${API_URL}/schools/${
                            school ? school.id : ""
                        }/logo`}
                        alt="School"
                    />
                </Container>
            )}
            <Spacer />
            <H2 bold="light">
                {" "}
                What program would you <HiddenBr /> like to get a loan for?{" "}
            </H2>
            <Spacer size={20} />
            <ContentContainer>
                <ProgramSelectionForm
                    school={school}
                    setSchoolId={setSchoolId}
                    setSchoolName={setSchoolName}
                    setFieldValue={setFieldValue}
                    values={values}
                />
                <Spacer lg />
                <Container alignItems="center">
                    <SubmissionButton
                        disabled={!!disabled}
                        type="button"
                        primary
                        onPress={nextPage}
                        title="Next"
                    />
                </Container>
                {!!authProfile && (
                    <>
                        <Spacer lg />
                        <Container
                            flex
                            row
                            alignItems="center"
                            justifyContent="center"
                        >
                            <P textAlign="center" textColor="black">
                                Already have an account?
                            </P>
                            <Spacer sm />
                            <TouchableContainer onPress={handleClickSignIn}>
                                <UnderlineText textColor="blue">
                                    Sign in
                                </UnderlineText>
                            </TouchableContainer>
                        </Container>
                    </>
                )}
            </ContentContainer>
        </>
    )
}
