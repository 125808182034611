export function FirstTier() {
    return (
        <svg
            width="29"
            height="29"
            viewBox="0 0 29 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="1.09599"
                y="1.61796"
                width="26.2814"
                height="26.2814"
                rx="6.08144"
                fill="white"
                stroke="#2EC63C"
                stroke-width="1.95564"
            />
            <path
                d="M13.2873 22.6487V21.2355C12.0881 21.1009 11.1398 20.6726 10.4424 19.9507C9.74493 19.2288 9.3962 18.3111 9.3962 17.1976H11.0114C11.0114 17.8461 11.201 18.4273 11.5803 18.9412C11.9719 19.4429 12.5409 19.7672 13.2873 19.914V14.94C13.2016 14.9156 13.1098 14.8911 13.012 14.8666C12.9263 14.8299 12.8407 14.7993 12.755 14.7748C11.7639 14.4322 11.0297 14.0101 10.5525 13.5084C10.0753 12.9945 9.8367 12.3276 9.8367 11.5078C9.82446 10.5534 10.1242 9.75806 10.7361 9.12179C11.3601 8.48551 12.2105 8.11843 13.2873 8.02054V6.53386H14.5537V8.02054C15.5815 8.1429 16.4013 8.52834 17.0131 9.17685C17.6249 9.82536 17.937 10.6146 17.9492 11.5445H16.334C16.334 11.0918 16.1872 10.6574 15.8935 10.2414C15.5999 9.81312 15.1533 9.52558 14.5537 9.37874V13.8204C14.6516 13.8449 14.7495 13.8755 14.8474 13.9122C14.9453 13.9489 15.0493 13.9795 15.1594 14.004C15.7712 14.2242 16.3218 14.4812 16.8113 14.7748C17.3007 15.0685 17.68 15.4478 17.9492 15.9128C18.2306 16.3778 18.3713 16.9712 18.3713 17.6931C18.3713 18.2682 18.2306 18.8127 17.9492 19.3267C17.6678 19.8406 17.2395 20.275 16.6644 20.6298C16.1016 20.9846 15.398 21.1927 14.5537 21.2538V22.6487H13.2873ZM11.4335 11.361C11.4335 11.9238 11.5987 12.3521 11.9291 12.6458C12.2594 12.9272 12.7122 13.1719 13.2873 13.3799V9.34204C12.7366 9.42769 12.29 9.64794 11.9474 10.0028C11.6048 10.3454 11.4335 10.7981 11.4335 11.361ZM16.7745 17.7849C16.7745 17.0752 16.5665 16.5491 16.1505 16.2065C15.7345 15.8639 15.2022 15.5824 14.5537 15.3622V19.9507C15.2634 19.865 15.8079 19.6203 16.1872 19.2165C16.5788 18.8127 16.7745 18.3355 16.7745 17.7849Z"
                fill="#2EC63C"
            />
        </svg>
    )
}
