import { useStudentApplicationDetails } from "@wetradeup/student-apps-shared"
import { format } from "date-fns"
import { isNumber } from "../../../utils"

export interface FormattedTerms {
    note_size: string
    loan_term: string
    effective_loan_terms: string
    interest: string
    payment?: string
    grace_period: string
    in_school_payment_period: string
    first_in_school_payment_due_date: string
    first_standard_payment_due_date: string
    in_school_payment?: number
    has_in_school_payment: boolean
    borrowing_request?: number
}

export function useGetLoanPaymentTerms(
    studentApplicationDetails: ReturnType<typeof useStudentApplicationDetails>
) {
    const note = studentApplicationDetails.data?.note
    const note_size = note?.cash_outflow?.note_size
    const loan_term = note?.isl_terms?.loan_term
    const grace_period = note?.grace_period
    const in_school_payment_period = note?.isl_terms?.in_school_payment_period
    const first_in_school_payment_due_date =
        note?.isl_terms?.first_in_school_payment_due_date
    const first_standard_payment_due_date =
        note?.isl_terms?.first_standard_payment_due_date
    const interest_rate = note?.isl_terms?.interest_rate
    const maximum_payment = note?.isl_terms?.maximum_payment
    const in_school_payment = note?.isl_terms.in_school_payment
    const has_in_school_payment = note?.isl_terms.in_school_payment !== 0.0
    const net_loan_amount_saved = note?.isl_terms.net_loan_amount_saved
    const effective_loan_terms =
        loan_term && maximum_payment && net_loan_amount_saved
            ? loan_term - Math.floor(net_loan_amount_saved / maximum_payment)
            : loan_term
            ? loan_term
            : null

    return {
        note_size: isNumber(studentApplicationDetails.data?.borrowing_request)
            ? note_size &&
              "$" +
                  note_size.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                  })
            : "",
        loan_term: loan_term && `${loan_term} Month${loan_term > 1 ? "s" : ""}`,
        effective_loan_terms: effective_loan_terms
            ? `${effective_loan_terms}** month${
                  effective_loan_terms > 1 ? "s" : ""
              }`
            : "N/A",
        interest: interest_rate && (interest_rate * 100).toFixed(2) + "%",
        payment: maximum_payment && maximum_payment.toLocaleString(),
        grace_period:
            grace_period &&
            `${grace_period} Month${grace_period > 1 ? "s" : ""}`,
        in_school_payment_period:
            in_school_payment_period &&
            `${in_school_payment_period} month${
                in_school_payment_period > 1 ? "s" : ""
            }`,
        first_in_school_payment_due_date:
            first_in_school_payment_due_date &&
            format(first_in_school_payment_due_date, "MM/dd/yyyy"),
        first_standard_payment_due_date:
            first_standard_payment_due_date &&
            format(first_standard_payment_due_date, "MM/dd/yyyy"),
        in_school_payment,
        has_in_school_payment,
        borrowing_request: studentApplicationDetails.data?.borrowing_request,
    }
}
