import {
    Card,
    Container,
    H2,
    Modal,
    Spacer,
    TouchableContainer,
} from "@wetradeup/ui-kit"
import { UnderlineText } from "../../../atoms/UnderlineText"
import { ApplicationButton } from "../../../atoms/ApplicationButton"
import { SpinningLoader } from "../../atoms/SpinningLoader"
import { useCancelCosigner } from "@wetradeup/student-apps-shared"
import { ContentContainer } from "../../../ModalComponents"

export function CancelCosignerModal({ open, closeModal }) {
    const cancelCosigner = useCancelCosigner()

    const handleCancelCosigner = async () => {
        await cancelCosigner.mutateAsync()
    }

    return (
        <Modal visible={open} width="100%">
            <Container
                width={[
                    [550, "md"],
                    [750, "lg"],
                ]}
            >
                <Card paddingMd>
                    <ContentContainer
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Spacer lg />
                        <H2 bold="light" textColor="black">
                            Are you sure you want to remove this co-signer?
                        </H2>
                        <Spacer lg />
                        <Container alignItems="center" width="100%">
                            {cancelCosigner.isLoading && <SpinningLoader />}
                            {!cancelCosigner.isLoading && (
                                <ApplicationButton
                                    fullWidth
                                    primary
                                    onPress={handleCancelCosigner}
                                    title={"Yes"}
                                    id="removeCosigner"
                                />
                            )}
                            <Spacer lg />
                            <TouchableContainer
                                onPress={() => {
                                    closeModal()
                                }}
                            >
                                <UnderlineText textColor="black">
                                    Never mind, I'll wait for my co-signer
                                </UnderlineText>
                            </TouchableContainer>
                        </Container>
                    </ContentContainer>
                </Card>
            </Container>
        </Modal>
    )
}
