import { Container, P, Spacer } from "@wetradeup/ui-kit"
import { ExitCounselingLock } from "./ExitCounselingLock"
import { useTheme } from "@emotion/react"
import styled from "@emotion/styled"

const LockedFooterContainer = styled(Container)`
    border-radius: 0px 0px 5px 5px;
    border: 2px solid ${(props) => props.theme.colors.palette.errorRed};
    border-top: 0px;
`
export function ExitCounselingMemberBenefitMobileLockedFooter() {
    const theme = useTheme()
    return (
        <LockedFooterContainer
            paddingMd
            flex
            col
            alignItems="center"
            backgroundColor={theme.colors.palette.ecBackgroundRed}
        >
            <ExitCounselingLock color="black" />
            <Spacer sm />
            <P
                bold
                textAlign="center"
                textColor="black"
                textSize={theme.sizings.fontSizeMd}
            >
                This benefit is locked
            </P>
            <P textAlign="center" textColor="black">
                Enroll in autopay to unlock this benefit.
            </P>
        </LockedFooterContainer>
    )
}
