import { Questionnaire } from "@wetradeup/student-apps-shared"
import { useMemo } from "react"
import * as Yup from "yup"

export function useCreateQuestionnaireValidation(
    questionnaire: Questionnaire[]
) {
    return useMemo(() => {
        return Yup.object().shape(
            Object.fromEntries(
                questionnaire
                    .filter((i) => {
                        return !i["hidden"]
                    })
                    .map((i) => {
                        if (i["style"] === "essay") {
                            return [
                                i["id"],
                                Yup.string().min(
                                    i["char"],
                                    `Please use at least ${i["char"]} characters!`
                                ),
                            ]
                        } else if (i["style"] === "radio") {
                            return [
                                i["id"],
                                Yup.object()
                                    .shape({
                                        value: Yup.string().required(),
                                        text: Yup.string().when("value", {
                                            is: (value) => value === "freeform",
                                            then: Yup.string()
                                                .min(1)
                                                .required(),
                                        }),
                                    })
                                    .required("Please answer this question."),
                            ]
                        } else if (i["style"] === "multiple_radio") {
                            return [
                                i["id"],
                                Yup.array()
                                    .min(
                                        1,
                                        "Please select at least one option."
                                    )
                                    .required("You can't leave this blank.")
                                    .of(Yup.object().required()),
                            ]
                        }
                        return [i["id"], Yup.object()]
                    })
            )
        )
    }, [questionnaire])
}
