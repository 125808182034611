import { AuthContext, PublicSchool } from "@wetradeup/student-apps-shared"
import { useContext, useEffect } from "react"
import { useSubmitIntro } from "./hooks/useSubmitIntro"
import { useFormik } from "formik"
import { initialCreateAccountFormValues } from "./constants/initialFormValues"
import { createAccountValSchema } from "./constants/formSchema"
import { Container } from "@wetradeup/ui-kit"
import { AuthRoute } from "../../AuthRoute"
import { Route, Switch } from "react-router-dom"
import { CreateAccount } from "./CreateAccount"
import ExistingAccountPage from "./ExistingAccountPage"
import { ProgramSelectionPage } from "./ProgramSelection/ProgramSelectionPage"
import { ApplicationSelection } from "./ApplicationSelection/ApplicationSelection"
import styled from "@emotion/styled"
import { getIsNonAuthPage } from "../../../utils"
import { breakpoints } from "../../../constants"
import { useFynnLocation } from "../../hooks/useFynnLocation"
import { FullMobileContainer } from "../../ModalComponents"
import { ContactSupportModal } from "../../atoms/ContactSupportModal"
import { LeftColumnWelcomeScreen } from "./WelcomeScreen/LeftColumnWelcomeScreen"

export interface CreateAccountForm {
    program?: string
    campus?: number
    anticipated_start_date?: Date
    first?: string
    middle?: string
    last?: string
    email?: string
    phone?: string
    passwordOne?: string
    passwordTwo?: string
    passwordRequired: boolean
    is_creating_new_account: boolean
}

interface IntroFormProps {
    schoolId?: number
    appFormId?: number
    setAppFormId: React.Dispatch<React.SetStateAction<number>>
    setSchoolId: React.Dispatch<React.SetStateAction<number>>
    setSchoolName: React.Dispatch<React.SetStateAction<string>>
    school?: PublicSchool
}

const StyledForm = styled.form`
    width: 100%;
`

const OneColumnContainer = styled(FullMobileContainer)`
    @media (max-width: ${breakpoints.tablet}) {
        min-width: 100vw;
        height: 100vh;
        overflow-y: scroll;
    }

    min-width: 720px;
    flex: 1;
`

const RightColumnContainer = styled(FullMobileContainer)`
    ${(props) => `
        flex: 1;
        ${props.extraPadding ? "padding-top: 60px;" : ""}
        @media (min-width: ${breakpoints.tablet}) {
            min-width: 720px;
        }
        @media (min-width: ${breakpoints.desktopSm}) {
            height: 100vh;
            background: ${props.theme.colors.palette.white};
            overflow-y: scroll;
        }
    `}
`

export function IntroForm({
    appFormId,
    setAppFormId,
    school,
    schoolId,
    setSchoolId,
    setSchoolName,
}: IntroFormProps) {
    const location = useFynnLocation()
    const { authProfile } = useContext(AuthContext)

    useEffect(() => {
        if (authProfile?.email) {
            setFieldValue("email", authProfile.email)
        }
    }, [authProfile?.email])

    const { onSubmit, loading, invalidPersonalInfo } = useSubmitIntro({
        setAppFormId,
        setValues: () => {},
        school,
        employer: null,
        setSubmitted: () => {},
    })

    const {
        handleChange,
        handleSubmit,
        setFieldValue,
        setValues,
        isValid,
        dirty,
        values,
        errors,
    } = useFormik<CreateAccountForm>({
        initialValues: initialCreateAccountFormValues,
        validationSchema: createAccountValSchema,
        onSubmit,
    })

    const isNonAuthPage = getIsNonAuthPage(location)

    return (
        <StyledForm autoComplete="off">
            <Container flex row width="100%">
                {isNonAuthPage && <LeftColumnWelcomeScreen />}

                <Switch>
                    <Route path="/application/intro" exact>
                        <RightColumnContainer
                            paddingMd
                            extraPadding={isNonAuthPage}
                        >
                            <ProgramSelectionPage
                                setSchoolId={setSchoolId}
                                setSchoolName={setSchoolName}
                                setFieldValue={setFieldValue}
                                isCreateNewAppFormValid={isValid}
                                handleSubmit={handleSubmit}
                                values={values}
                                errors={errors}
                                school={school}
                            />
                        </RightColumnContainer>
                    </Route>
                    <Route path="/application/intro/existing-account" exact>
                        <OneColumnContainer>
                            <ExistingAccountPage
                                setFieldValue={setFieldValue}
                            />
                        </OneColumnContainer>
                    </Route>
                    <Route path="/application/intro/account">
                        <RightColumnContainer
                            paddingMd
                            extraPadding={isNonAuthPage}
                        >
                            <CreateAccount
                                handleChange={handleChange}
                                values={values}
                                errors={errors}
                                setFieldValue={setFieldValue}
                                setValues={setValues}
                                loading={loading}
                                handleSubmit={handleSubmit}
                                dirty={dirty}
                                isValid={isValid}
                                schoolId={schoolId}
                                type="isl"
                            />
                        </RightColumnContainer>
                    </Route>
                    <AuthRoute path="/application/intro/select">
                        <OneColumnContainer>
                            <ApplicationSelection
                                appFormId={appFormId}
                                setAppFormId={setAppFormId}
                                isCreateNewAppFormValid={isValid}
                                values={values}
                                setValues={setValues}
                                handleSubmit={handleSubmit}
                            />
                        </OneColumnContainer>
                    </AuthRoute>
                    <ContactSupportModal
                        invalidPersonalInfo={invalidPersonalInfo}
                    />
                </Switch>
            </Container>
        </StyledForm>
    )
}
