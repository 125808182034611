import { useNavBar } from "../../hooks/useNavBar"
import { useWindowSize } from "../../hooks/useWindowSize"
import { NavBarAuthenticatedMenu } from "./NavBarAuthenticatedMenu"
import { NavBarExitCounseling } from "./NavBarExitCounseling"
import { NavBarExitCounselingSignOut } from "./NavBarExitCounselingSignOut"
import { NavBarMobileAuthenticatedMen } from "./NavBarMobileAuthenticatedMen"

export function NavBar() {
    const {
        isExitCounseling,
        showAuthenticatedNavBar,
        showExitCounselingSignOut,
    } = useNavBar()

    const { isDesktop } = useWindowSize()

    return (
        <>
            {isExitCounseling && <NavBarExitCounseling />}
            {isExitCounseling && showExitCounselingSignOut && (
                <NavBarExitCounselingSignOut />
            )}
            {!isExitCounseling && showAuthenticatedNavBar && !isDesktop && (
                <NavBarMobileAuthenticatedMen />
            )}
            {!isExitCounseling && showAuthenticatedNavBar && isDesktop && (
                <NavBarAuthenticatedMenu />
            )}
        </>
    )
}
