import {
    Spacer,
    Input,
    PasswordInput,
    H2,
    Container,
    Caption,
    P,
    FynnFullLetterLogo,
} from "@wetradeup/ui-kit"
import { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import queryString from "query-string"
import { parseISO } from "date-fns"
import { CreateAccountConditions } from "./CreateAccountConditions"
import { ContentContainer } from "../../ModalComponents"
import { SpinningLoader } from "../atoms/SpinningLoader"
import { SubmitButtonContainer } from "../molecules/SubmitButtonContainer"
import { AuthContext } from "@wetradeup/student-apps-shared"
import { CreateAccountForm } from "./IntroForm"
import { WrappedFormik } from "../../../utils"
import styled from "@emotion/styled"
import { EmailLoginModal } from "../molecules/modals/EmailLoginModal"
import { useFynnLocation } from "../../hooks/useFynnLocation"
import { ResponsiveContainer } from "../../atoms/ResponsiveContainer"
import { useWindowSize } from "../../hooks/useWindowSize"
import { ApplicationButton } from "../../atoms/ApplicationButton"

export const formatPhoneNumber = (phoneNumber: string): string => {
    if (phoneNumber) {
        const currentValue = phoneNumber.replace(/[^\d]/g, "")
        const cvLength = currentValue.length
        if (cvLength < 4) return currentValue
        if (cvLength < 7)
            return `${currentValue.slice(0, 3)}-${currentValue.slice(3)}`
        return `${currentValue.slice(0, 3)}-${currentValue.slice(
            3,
            6
        )}-${currentValue.slice(6, 10)}`
    }
    return ""
}

const OR = styled.div`
    position: relative;
    &:before,
    &:after {
        content: "";
        display: inline-block;
        height: 1px;
        width: 40%;
        background: ${(props) => props.theme.colors.palette.medGrey};
        position: absolute;
        top: 50%;
    }
    &:before {
        transform: translate(-120%, -50%);
    }
    &:after {
        transform: translate(20%, -50%);
    }
`

interface CreateAccountProps {
    handleChange
    values: CreateAccountForm
    errors: ReturnType<WrappedFormik<CreateAccountForm>["formik"]>["errors"]
    setValues: ReturnType<
        WrappedFormik<CreateAccountForm>["formik"]
    >["setValues"]
    setFieldValue: ReturnType<
        WrappedFormik<CreateAccountForm>["formik"]
    >["setFieldValue"]
    loading: boolean
    handleSubmit: ReturnType<
        WrappedFormik<CreateAccountForm>["formik"]
    >["handleSubmit"]
    dirty: ReturnType<WrappedFormik<CreateAccountForm>["formik"]>["dirty"]
    isValid: ReturnType<WrappedFormik<CreateAccountForm>["formik"]>["isValid"]
    schoolId: number
    type: string
}

function ResponsivePageTitle() {
    const { windowSize } = useWindowSize()

    if (windowSize?.width > 1370 && windowSize?.width < 1440) {
        return (
            <H2 bold="light">
                Great! Create a Fynn account to continue your application
            </H2>
        )
    }

    if (windowSize?.width >= 510 && windowSize?.width < 545) {
        return (
            <H2 bold="light">
                Great! Create a Fynn account to continue your application
            </H2>
        )
    }
    return (
        <H2 bold="light">
            Great! Create a Fynn account to <br />
            continue your application
        </H2>
    )
}

export function CreateAccount({
    handleChange,
    values,
    errors,
    setValues,
    setFieldValue,
    loading,
    handleSubmit,
    dirty,
    isValid,
}: CreateAccountProps) {
    const history = useHistory()
    const location = useFynnLocation()
    const { authProfile } = useContext(AuthContext)
    const [showLoginModal, setShowLoginModal] = useState(false)
    const [loginSubmitted, setLoginSubmitted] = useState(false)

    useEffect(() => {
        const query = queryString.parse(location.search)

        if (query.schoolId && query.program && query.startDate) {
            setValues({
                ...values,
                program: values.program || (query.program as string),
                anticipated_start_date:
                    values.anticipated_start_date ||
                    parseISO(query.startDate as string),
                ...(query.campusId
                    ? {
                          campus:
                              values.campus ||
                              parseInt(query.campusId as string),
                      }
                    : {}),
            })
        } else {
            return history.push(`/application/intro`)
        }
    }, [location.search])

    useEffect(() => {
        if (loginSubmitted && authProfile) {
            return history.push(`/application/intro/select${location.search}`)
        }
    }, [loginSubmitted, authProfile, location])

    const handleLoginPress = (e: any) => {
        e.preventDefault()
        setShowLoginModal(true)
    }

    const disabled = !(isValid && dirty)
    const buttonTitle = authProfile?.email ? "Next" : "Create Account"

    return (
        <>
            <Spacer size={20} />
            <ContentContainer>
                <Container alignItems="center">
                    <FynnFullLetterLogo />
                </Container>
                <Spacer size={20} />
                <ResponsivePageTitle />
                <Spacer size={20} />
                <ResponsiveContainer row>
                    <Input
                        name="first"
                        placeholder="First Name"
                        onChange={handleChange}
                        value={values.first}
                        error={errors.first}
                        autoComplete="first-name"
                        flex={1}
                    />
                    <Spacer />
                    <Input
                        name="last"
                        placeholder="Last Name"
                        onChange={handleChange}
                        value={values.last}
                        error={errors.last}
                        autoComplete="family-name"
                        flex={1}
                    />
                </ResponsiveContainer>
                <Spacer sm />
                <P>
                    Note: Please enter your legal first and last name that
                    appears on your birth certificate or other official
                    documentation.
                </P>
                <Spacer />
                <Input
                    name="phone"
                    placeholder="Mobile Phone Number"
                    onChange={(e) => {
                        const numberForm = (e.target as HTMLInputElement).value
                            .replace(/[^0-9]/g, "")
                            .substr(0, 10)
                        setFieldValue("phone", numberForm)
                    }}
                    value={formatPhoneNumber(values.phone)}
                    error={errors.phone}
                    flex={1}
                    autoComplete="phone"
                />
                <Spacer />
                <Input
                    id="email"
                    name="email"
                    placeholder="Email Address"
                    onChange={handleChange}
                    value={values.email}
                    error={errors.email}
                    autoComplete="email"
                    type="email"
                />
                <Spacer />
                {!authProfile && (
                    <>
                        <PasswordInput
                            showRequired
                            id="new-password"
                            name="passwordOne"
                            placeholder="Password"
                            onChange={handleChange}
                            error={errors.passwordOne}
                            value={values.passwordOne}
                            autoComplete="new-password"
                        />
                        <Spacer />
                        <PasswordInput
                            showRequired
                            name="passwordTwo"
                            placeholder="Confirm Password"
                            onChange={handleChange}
                            error={errors.passwordTwo}
                            value={values.passwordTwo}
                            autoComplete="new-password"
                            alwaysShowError={!!values.passwordTwo}
                        />
                        <Spacer />
                    </>
                )}
                <Spacer lg />
                {!loading && (
                    <SubmitButtonContainer
                        disabled={disabled}
                        handleSubmit={handleSubmit}
                        errors={errors}
                        title={buttonTitle}
                        fullWidth
                    />
                )}
                {loading && <SpinningLoader />}

                <Spacer lg />
                <OR>
                    <Caption>OR</Caption>
                </OR>
                <Spacer lg />
                <Container minWidth={200}>
                    <ApplicationButton
                        fullWidth
                        type="submit"
                        onPress={handleLoginPress}
                        outline="blue"
                        title="Login with existing account"
                        textColor="blue"
                    />
                </Container>
                <Spacer lg />
                <CreateAccountConditions />
                <Spacer lg />
            </ContentContainer>
            <EmailLoginModal
                visible={showLoginModal}
                setLoginSubmitted={setLoginSubmitted}
                closeModal={() => setShowLoginModal(false)}
            />
        </>
    )
}
