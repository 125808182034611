import { useCallback, useContext, useState } from "react"
import {
    Card,
    Container,
    Modal,
    Spacer,
    H2,
    H3,
    H5,
    P,
    BackgroundImage,
    CancelIcon,
    SocialSecurityInput,
    SecureLabel,
    TouchableContainer,
} from "@wetradeup/ui-kit"
import styled from "@emotion/styled"
import ApprovedBackground from "../../../../assets/application/approved_background.png"
import { ContentContainer } from "../../../ModalComponents"
import {
    useResubmitApplication,
    AuthContext,
    useStudentApplicationDetails,
} from "@wetradeup/student-apps-shared"
import { useHistory } from "react-router-dom"
import * as Yup from "yup"
import { SubmissionButton } from "../../atoms/SubmissionButton"
import { SpinningLoader } from "../../atoms/SpinningLoader"
import { HiddenBr } from "../../atoms/HiddenBr"
import { useFormik } from "formik"
import { VerifyingIcon } from "../VerifyingIcon"
import { RotatingLines } from "react-loader-spinner"
import { UnderlineText } from "../../../atoms/UnderlineText"
import { CosignerInviteModal } from "./CosignerInviteModal"
import { RejectionConfirmationModal } from "./RejectionConfirmationModal"

export const ApprovedContainer = styled(Container)`
    background: url(${ApprovedBackground}) center no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
`

export const VerifyingImg = styled(BackgroundImage)`
    height: 180px;
    width: 180px;
    border-radius: 50%;
    elevation: 1;
`

export const CancelIconContainer = styled(Container)`
    border-radius: 50%;
    border: ${(props) => `2px solid ${props.theme.colors.palette.errorRed};`}
    padding: 8px;
    svg {
        fill: ${(props) => `${props.theme.colors.palette.errorRed};`}
        stroke-width: 5px;
        width: 8px;
        height:  8px;
    }
`

export function handleSubmitResponse(response, setError, setDecision) {
    if (!response.underwriting_decision) {
        setError("Server Error. Please try again.")
    }
    if (response.underwriting_decision === "approved") {
        return `/isl-finalize/loan-terms`
    } else if (
        response.underwriting_decision === "pending" &&
        response.should_show_identity_verification_modal &&
        !response?.attempted_identity_verification_at
    ) {
        return `/isl-finalize/verification`
    } else if (response.underwriting_recommendation === "locked") {
        return `/isl-finalize/locked`
    } else if (
        response.underwriting_recommendation ===
        "approved_after_fraud_alert_clarification"
    ) {
        return `/isl-finalize/alerts`
    } else if (
        response.underwriting_decision === "pending" &&
        response.application_type === "with_cosigner" &&
        response.did_cosigner_cancel
    ) {
        return "/isl-finalize/cosigner-canceled"
    } else if (
        response.underwriting_decision === "pending" &&
        response.application_type === "with_cosigner" &&
        !response.did_cosigner_submit
    ) {
        return "/isl-finalize/cosigner-awaiting"
    } else if (response.underwriting_decision === "pending") {
        return `/isl-finalize/pending`
    } else if (response.underwriting_decision === "rejected") {
        return `/isl-finalize/decisioned`
    } else {
        setError(
            "There was a server error. Please try again. If this error persist, contact Fynn support"
        )
        setDecision && setDecision(null)
    }
}

export function DecisionModal({ decision, appFormId, setDecision, setError }) {
    const history = useHistory()
    const resubmitMutation = useResubmitApplication()
    const { refreshAuth, authProfile } = useContext(AuthContext)

    const onResubmit = useCallback(
        async (values) => {
            setDecision("verifying")
            try {
                const response = await resubmitMutation.mutateAsync({
                    app_form_id: appFormId,
                    ssn: values.ssn,
                })
                await refreshAuth.mutateAsync(authProfile.refresh_token)
                if (response) {
                    const newRoute = handleSubmitResponse(
                        response,
                        setError,
                        setDecision
                    )
                    if (newRoute) {
                        history.push(newRoute)
                    }
                }
            } catch (error) {
                setError(error.error)
                setDecision(null)
            }
        },
        [appFormId]
    )

    return (
        <Modal visible={!!decision} width="100%">
            <Container
                width={[
                    [700, "md"],
                    [860, "lg"],
                ]}
            >
                <Card paddingMd>
                    {decision === "verifying" && <Verifying />}
                    {decision === "rejected" && <Rejected />}
                    {decision === "resubmit" && (
                        <ErrorVerifying onSubmit={onResubmit} />
                    )}
                </Card>
            </Container>
        </Modal>
    )
}

function RejectionModalWithCosignerOption({ studentApplicationDetails }) {
    const [open, setOpen] = useState(false)
    const [rejectionModalOpen, setRejectionModal] = useState(false)

    return (
        <>
            {!studentApplicationDetails.isLoading &&
                studentApplicationDetails.data.application_type ===
                    "with_cosigner" && (
                    <>
                        <H2 bold="light">
                            We were not currently able to accept your
                            application. Would you like to re-apply with a
                            different co-signer?
                        </H2>
                    </>
                )}
            {!studentApplicationDetails.isLoading &&
                studentApplicationDetails.data.application_type ===
                    "without_cosigner" && (
                    <>
                        <H2 bold="light">
                            We were not currently able to accept your
                            application. Would you like to add a co-signer?
                        </H2>
                    </>
                )}
            <Spacer size={32} />
            <Container width="100%" maxWidth="300px">
                <SubmissionButton
                    width="100%"
                    onPress={() => setOpen(true)}
                    primary
                    title="Yes, Add A New Co-Signer"
                />
            </Container>
            <Spacer />
            <TouchableContainer
                onPress={() => setRejectionModal(!rejectionModalOpen)}
            >
                <UnderlineText bold textColor="black">
                    No, Exit Application
                </UnderlineText>
            </TouchableContainer>
            <CosignerInviteModal
                open={open}
                closeModal={() => setOpen(!open)}
            />
            <RejectionConfirmationModal
                open={rejectionModalOpen}
                closeModal={() => setRejectionModal(!rejectionModalOpen)}
            />
        </>
    )
}

function RejectedModalWithoutCosignerOption() {
    const history = useHistory()
    return (
        <>
            <H5 bold="light">
                Check your email for our decision on your loan application.
            </H5>
            <Spacer size={32} />
            <SubmissionButton
                onPress={() => history.push("/application/intro/select")}
                primary
                title="Exit Application"
            />
        </>
    )
}

export function Rejected() {
    const studentApplicationDetails = useStudentApplicationDetails({
        enabled: true,
    })

    return (
        <ContentContainer justifyContent="center" alignItems="center">
            <Spacer size={48} />
            {studentApplicationDetails.isLoading && <SpinningLoader />}
            {!studentApplicationDetails.isLoading &&
                studentApplicationDetails.data
                    .is_cosigner_reapplication_allowed && (
                    <RejectionModalWithCosignerOption
                        studentApplicationDetails={studentApplicationDetails}
                    />
                )}
            {!studentApplicationDetails.isLoading &&
                !studentApplicationDetails.data
                    .is_cosigner_reapplication_allowed && (
                    <RejectedModalWithoutCosignerOption />
                )}
            <Spacer size={48} />
        </ContentContainer>
    )
}

const StickyLoader = styled(Container)`
    position: relative;
    top: -160px;
    left: 10px;
`

function Verifying() {
    return (
        <ContentContainer>
            <Spacer size={48} />
            <Container alignItems="center" justifyContent="center" fullWidth>
                <VerifyingIcon />
                <StickyLoader>
                    <RotatingLines
                        strokeColor="#0000004D"
                        strokeWidth="3"
                        animationDuration="0.75"
                        width="60"
                    />
                </StickyLoader>
            </Container>
            <H2 bold="light"> Thank you! </H2>
            <H2 bold="light"> We're verifying your info. </H2>
            <Spacer />
            <P textAlign="center">
                {" "}
                Please do not refresh or exit this screen.{" "}
            </P>
            <Spacer size={48} />
        </ContentContainer>
    )
}

const initialValues = {
    ssn: "",
    confirmSSN: "",
}

export const personalDetailsSchema = Yup.object().shape({
    ssn: Yup.string()
        .typeError("Please enter your real SSN.")
        .required("Please enter your SSN.")
        .matches(
            /^(?!666|000|9\d{2})\d{3}[- ]{0,1}(?!00)\d{2}[- ]{0,1}(?!0{4})\d{4}$/,
            "Please enter a valid SSN."
        )
        .notOneOf(
            ["111111111", "333333333", "123456789"],
            "Please enter a valid SSN."
        ),
    confirmSSN: Yup.string()
        .typeError("SSNs must match.")
        .required("Please confirm your SSN.")
        .oneOf([Yup.ref("ssn"), null], "SSNs must match."),
})

const ErrorVerifying = ({
    onSubmit,
}: {
    onSubmit: (values: any) => Promise<void>
}) => {
    const [loading, setLoading] = useState(false)

    const handlePress = async (values) => {
        setLoading(true)
        try {
            await onSubmit(values)
        } finally {
            setLoading(false)
        }
    }

    const { handleSubmit, setFieldValue, isValid, dirty, values, errors } =
        useFormik<{ ssn: string; confirmSSN: string }>({
            initialValues: initialValues,
            validationSchema: personalDetailsSchema,
            onSubmit: handlePress,
        })

    const disabled = !(isValid && dirty)

    return (
        <ContentContainer>
            <Container fullWidth alignItems="center" justifyContent="center">
                <CancelIconContainer width={28}>
                    <CancelIcon />
                </CancelIconContainer>
            </Container>
            <Spacer />
            <H3 bold="light">
                {" "}
                Oops, we had an error verifying <HiddenBr /> some of your
                information{" "}
            </H3>
            <Spacer />
            <ContentContainer>
                <P textAlign="center">
                    {" "}
                    When verifying your information we had an error in one or
                    more field(s). Please re-enter your information below.{" "}
                </P>
                <Spacer />
                <SocialSecurityInput
                    name="ssn"
                    placeholder="SSN"
                    onChange={(e) => {
                        const ssnForm = e.target.value
                            .replace(/[^0-9]/g, "")
                            .substr(0, 9)
                        setFieldValue("ssn", ssnForm)
                    }}
                    value={values.ssn}
                    error={errors.ssn}
                />
                <Spacer />
                <SocialSecurityInput
                    name="confirmSSN"
                    placeholder="Confirm SSN"
                    onChange={(e) => {
                        const ssnForm = e.target.value
                            .replace(/[^0-9]/g, "")
                            .substr(0, 9)
                        setFieldValue("confirmSSN", ssnForm)
                    }}
                    value={values.confirmSSN}
                    error={errors.confirmSSN}
                />
                <Spacer />
                {!loading && (
                    <Container alignItems="center">
                        <SubmissionButton
                            fullWidth
                            disabled={disabled}
                            type="submit"
                            primary
                            onPress={() => handleSubmit()}
                            title="Verify your SSN"
                        />
                    </Container>
                )}
                {loading && <SpinningLoader />}
                <Spacer />
                <SecureLabel />
            </ContentContainer>
        </ContentContainer>
    )
}
