import { Container, Modal, Spacer, Card, P } from "@wetradeup/ui-kit"
import { WrappedFormik } from "../../../../utils"
import { ContentContainer } from "../../../ModalComponents"
import { UpdateApplicationBasicInfoForm } from "../../BasicInfoPage"
import { SubmitButtonContainer } from "../SubmitButtonContainer"
import { SpinningLoader } from "../../atoms/SpinningLoader"
import styled from "@emotion/styled"
import { breakpoints } from "../../../../constants"
import { ApplicationButton } from "../../../atoms/ApplicationButton"
import { WarningIcon } from "../../atoms/WarningIcon"

interface ProgramConfirmationModalProps {
    loading: boolean
    open: boolean
    toggleModal: () => void
    values: ReturnType<
        WrappedFormik<UpdateApplicationBasicInfoForm>["formik"]
    >["values"]
    handleSubmit: ReturnType<
        WrappedFormik<UpdateApplicationBasicInfoForm>["formik"]
    >["handleSubmit"]
    errors: ReturnType<
        WrappedFormik<UpdateApplicationBasicInfoForm>["formik"]
    >["errors"]
}

const ResponsiveContainer = styled(Container)`
    @media (max-width: ${breakpoints.mobileLg}) {
        padding-left: 0px;
        padding-right: 0px;
    }
`

export function ProgramConfirmationModal({
    loading,
    open,
    toggleModal,
    values,
    errors,
    handleSubmit,
}: ProgramConfirmationModalProps) {
    return (
        <Modal visible={open} width="100%">
            <Container
                width={[
                    [700, "md"],
                    [860, "lg"],
                ]}
            >
                <Card paddingMd>
                    <ContentContainer
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Spacer />
                        <WarningIcon />
                        <Spacer lg />
                        <ResponsiveContainer padding={["sm", "lg"]}>
                            <P
                                textAlign="center"
                                textSize="32px"
                                lineHeight="30px"
                                textColor="black"
                            >
                                Are you sure this is your program?
                            </P>
                        </ResponsiveContainer>
                        <ResponsiveContainer
                            padding={["sm", "xl"]}
                            width="100%"
                        >
                            <ResponsiveContainer>
                                <Spacer />
                                <P bold textSize="18px">
                                    Program
                                </P>
                                <Spacer sm />
                                <P textSize="18px">{values?.program}</P>
                                <Spacer lg />
                            </ResponsiveContainer>
                        </ResponsiveContainer>
                        <Spacer />
                        <Container minWidth={200} width={300} maxWidth="95vw">
                            {!loading && (
                                <SubmitButtonContainer
                                    fullWidth
                                    title="Yes! This is my program"
                                    handleSubmit={handleSubmit}
                                    disabled={false}
                                    errors={errors}
                                />
                            )}
                            {loading && <SpinningLoader />}
                            <Spacer />
                            <ApplicationButton
                                onPress={toggleModal}
                                outline="blue"
                                title="Something is wrong"
                                textColor="blue"
                            />
                            <Spacer />
                        </Container>
                    </ContentContainer>
                </Card>
            </Container>
        </Modal>
    )
}
