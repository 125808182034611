export function EditIcon() {
    return (
        <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.62945 2.32715H2.25099C1.91921 2.32715 1.60101 2.45895 1.36641 2.69355C1.1318 2.92816 1 3.24635 1 3.57814V12.3351C1 12.6668 1.1318 12.985 1.36641 13.2196C1.60101 13.4542 1.91921 13.586 2.25099 13.586H11.0079C11.3397 13.586 11.6579 13.4542 11.8925 13.2196C12.1271 12.985 12.2589 12.6668 12.2589 12.3351V7.9566"
                stroke="#2E54E8"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M11.3206 1.38863C11.5695 1.1398 11.907 1 12.2589 1C12.6108 1 12.9483 1.1398 13.1971 1.38863C13.4459 1.63747 13.5857 1.97497 13.5857 2.32687C13.5857 2.67878 13.4459 3.01628 13.1971 3.26512L7.25491 9.20731L4.75293 9.83281L5.37842 7.33083L11.3206 1.38863Z"
                stroke="#2E54E8"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
