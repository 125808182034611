export function Warning() {
    return (
        <svg
            width="55"
            height="43"
            viewBox="0 0 55 43"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M24.097 2.65983C25.5889 0.436603 28.8591 0.436605 30.351 2.65984L42.2824 20.44L53.4056 37.0159C55.0844 39.5177 53.2915 42.88 50.2786 42.88H27.224H4.16943C1.15655 42.88 -0.636385 39.5177 1.04244 37.0159L12.1657 20.44L24.097 2.65983Z"
                fill="#FFA127"
            />
            <path
                d="M28.0316 27.5912H25.5191L24.994 17.5309H28.5568L28.0316 27.5912ZM24.9528 31.1128C24.9528 30.4675 25.1279 30.0144 25.478 29.7536C25.8349 29.4927 26.264 29.3623 26.7651 29.3623C27.2525 29.3623 27.6712 29.4927 28.0213 29.7536C28.3783 30.0144 28.5568 30.4675 28.5568 31.1128C28.5568 31.7306 28.3783 32.1768 28.0213 32.4514C27.6712 32.726 27.2525 32.8633 26.7651 32.8633C26.264 32.8633 25.8349 32.726 25.478 32.4514C25.1279 32.1768 24.9528 31.7306 24.9528 31.1128Z"
                fill="black"
            />
        </svg>
    )
}
