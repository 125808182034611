import { useState, useEffect, useCallback } from "react"
import { useFormik } from "formik"
import { format } from "date-fns"
import {
    Container,
    Spacer,
    H2,
    H4,
    P,
    Input,
    Select,
    StateSelect,
    fullStateName,
    SocialSecurityInput,
    SecureLabel,
} from "@wetradeup/ui-kit"
import { startCase, toLower } from "lodash"
import {
    useUpdateApplicationForm,
    useValidateAddress,
    AddressAutocomplete,
    useGetStudentProfile,
    useGetApplicationForm,
    PublicSchool,
    useGetAccount,
    ApplicantAccount,
    useGetPublicSchool,
} from "@wetradeup/student-apps-shared"
import { useHistory } from "react-router-dom"
import { basicInfoSchema } from "./IntroForm/constants/formSchema"
import { initialBasicValues } from "./IntroForm/constants/initialFormValues"
import { ContentContainer } from "../ModalComponents"
import { ProgramFinanceDetailSection } from "./ProgramFinanceDetailSection"
import { DatePicker } from "./molecules/DatePicker"
import { educationOptions, citizenOptions } from "./constants"
import { AutomaticRejectionModal } from "./molecules/modals/AutomaticRejectionModal"
import { SubmitButtonContainer } from "./molecules/SubmitButtonContainer"
import { ProgramSelectionForm } from "./IntroForm/ProgramSelection/ProgramSelectionForm"
import { ProgramConfirmationModal } from "./molecules/modals/ProgramConfirmationModal"
import { ResponsiveContainer } from "../atoms/ResponsiveContainer"
import { NotFoundAddressConfirmationModal } from "./molecules/modals/NotFoundAddressConfirmationModal"
import { getErrorMessage } from "../../utils"

interface BasicInfoPageProps {
    school?: PublicSchool
    schoolId?: number
    setSchoolId: React.Dispatch<React.SetStateAction<number>>
    setSchoolName: React.Dispatch<React.SetStateAction<string>>
    appFormId: number
}

export interface UpdateApplicationBasicInfoForm {
    street: string
    street_line_two: string
    city: string
    state: string
    zip: string
    citizen: string
    dob: any
    ssn: string
    confirmSSN: string
    residency: string
    education_level: string
    school: string
    program: string
    campus?: number
    anticipated_start_date: Date
}

export default function BasicInfoPage({
    school,
    schoolId,
    setSchoolId,
    setSchoolName,
    appFormId,
}: BasicInfoPageProps) {
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const history = useHistory()
    const updateFormMutation = useUpdateApplicationForm()
    const validateAddressMutation = useValidateAddress()
    const getStudentProfileQuery = useGetStudentProfile({
        enabled: true,
    })
    const applicationFormQuery = useGetApplicationForm(appFormId)
    const schoolQuery = useGetPublicSchool(schoolId, null)
    const accountQuery = useGetAccount()

    const [modalLoading, setModalLoading] = useState(false)
    const [addressValidationLoading, setAddressValidationLoading] =
        useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [invalidReasons, setInvalidReasons] = useState([])
    const [, setError] = useState(null)
    const [alwaysShowError, setAlwaysShowError] = useState(false)
    const [showManualAddressModal, setShowManualAddressModal] = useState(false)

    useEffect(() => {
        if (
            applicationFormQuery.data?.status === "invalid" &&
            applicationFormQuery.data?.invalid_reasons &&
            applicationFormQuery.data?.invalid_reasons.length > 0
        ) {
            setInvalidReasons(applicationFormQuery.data?.invalid_reasons)
            setModalOpen(true)
        }

        if (applicationFormQuery.isSuccess) {
            setValuesFormik({
                ...values,
                school: values.school || applicationFormQuery.data?.school_name,
                program:
                    values.program || applicationFormQuery.data?.program_name,
                campus: values.campus || applicationFormQuery.data?.campus_id,
                anticipated_start_date:
                    applicationFormQuery.data?.anticipated_start_date,
            })
        }
    }, [
        applicationFormQuery.data?.status,
        applicationFormQuery.data?.invalid_reasons,
        applicationFormQuery.data?.school_name,
        applicationFormQuery.data?.program_name,
        applicationFormQuery.data?.campus_id,
        applicationFormQuery.data?.anticipated_start_date,
    ])

    useEffect(() => {
        if (getStudentProfileQuery.isSuccess) {
            const citizen =
                getStudentProfileQuery.data?.residency !== "unanswered"
                    ? getStudentProfileQuery.data?.residency
                    : undefined
            setValuesFormik({
                ...values,
                dob: values.dob || getStudentProfileQuery.data?.dob,
                street: values.street || getStudentProfileQuery.data?.street,
                street_line_two:
                    values.street_line_two ||
                    getStudentProfileQuery.data?.street_line_two ||
                    "",
                city: values.city || getStudentProfileQuery.data?.city,
                state: values.state || getStudentProfileQuery.data?.state,
                zip: values.zip || getStudentProfileQuery.data?.zip,
                citizen: values.citizen || citizen,
                education_level:
                    values.education_level ||
                    getStudentProfileQuery.data?.education_level,
            })
        }
    }, [getStudentProfileQuery.data])

    const onSubmit = useCallback(
        async (values) => {
            setModalLoading(true)

            try {
                const response = await updateFormMutation.mutateAsync({
                    app_form_id: appFormId,
                    street: values.street,
                    street_line_two: values.street_line_two,
                    city: values.city,
                    state: values.state,
                    zip: values.zip,
                    residency: values.citizen,
                    dob: format(values.dob, "yyyy-MM-dd"),
                    education_level: values.education_level,
                    ssn: values.ssn,
                    school: school.school_name,
                    program: values.program,
                    campus_id: values.campus,
                    anticipated_start_date: format(
                        values.anticipated_start_date,
                        "yyyy-MM-dd"
                    ),
                })
                if (!response.status) return
                if (
                    response.status === "valid" &&
                    schoolQuery.data?.is_cosigner_enabled &&
                    !applicationFormQuery.data?.has_cosigner
                ) {
                    history.push(`/application/${schoolId}/cosigner`, {
                        disableAutoRouting: true,
                    })
                } else if (
                    response.status === "valid" &&
                    !schoolQuery.data?.is_cosigner_enabled
                ) {
                    history.push(`/application/${schoolId}/questionnaire`, {
                        disableAutoRouting: true,
                    })
                } else {
                    setInvalidReasons(response.invalid_reasons)
                    setModalOpen(true)
                    history.push(`/application/${schoolId}/basic`, {
                        disableAutoRouting: true,
                    })
                }
            } catch (err) {
                setError(err)
            }
            setShowConfirmationModal(false)
            setModalLoading(false)
        },
        [schoolId, appFormId, school]
    )

    const {
        handleChange,
        handleSubmit,
        setFieldValue,
        setFieldError,
        isValid,
        dirty,
        values,
        setValues: setValuesFormik,
        errors,
    } = useFormik<UpdateApplicationBasicInfoForm>({
        initialValues: initialBasicValues,
        validationSchema: basicInfoSchema,
        onSubmit,
    })

    const setSSNInput = (e, field) => {
        const ssnForm = e.target.value.replace(/[^0-9]/g, "").substr(0, 9)
        setFieldValue(field, ssnForm)
    }

    const disabled = !(isValid && dirty) && accountQuery.isFetching

    const validateAddress = async () => {
        try {
            setAddressValidationLoading(true)

            const response = await validateAddressMutation.mutateAsync({
                name: `${
                    (accountQuery?.data as ApplicantAccount)?.first_name
                } ${(accountQuery?.data as ApplicantAccount)?.last_name}`,
                street: values.street,
                street_line_two: values.street_line_two,
                city: values.city,
                state: values.state,
                postal_code: values.zip,
            })

            setValuesFormik({
                ...values,
                street: startCase(toLower(response.street)),
                street_line_two: startCase(toLower(response.street_line_two)),
                city: startCase(toLower(response.city)),
                state: fullStateName(response.state),
                zip: response.postal_code,
            })

            setShowConfirmationModal(true)
        } catch (err) {
            if (getErrorMessage(err) === "Address Not Found.") {
                setShowManualAddressModal(true)
            } else {
                setFieldError("street", "This address is invalid.")
            }
        } finally {
            setAddressValidationLoading(false)
        }
    }

    return (
        <Container
            paddingMd
            width={[
                [550, "sm"],
                [550, "md"],
                [750, "lg"],
            ]}
        >
            <Spacer />
            <H2 bold="light"> Let's get some basic info </H2>
            <Spacer size={20} />
            <ContentContainer>
                <H4 bold="light">Program Information</H4>
                <Spacer />
                <ProgramFinanceDetailSection />
                <Spacer />
                <ProgramSelectionForm
                    school={school}
                    setSchoolId={setSchoolId}
                    setSchoolName={setSchoolName}
                    setFieldValue={setFieldValue}
                    values={values}
                    showOnlyLiveSchool={true}
                />
                <Spacer />
                <H4 bold="light">Basic Information</H4>
                <Spacer />
                <AddressAutocomplete
                    placeholder="Street Address"
                    handleChange={handleChange}
                    setValues={setValuesFormik}
                    errors={errors}
                    values={values}
                    alwaysShowError={alwaysShowError}
                />
                <Spacer />
                <Input
                    name="street_line_two"
                    placeholder="Street Address 2"
                    onChange={handleChange}
                    value={values.street_line_two}
                    error={errors.street_line_two}
                    data-private="redact"
                    alwaysShowError={alwaysShowError}
                />
                <P textSize="12px" textColor="grey">
                    Note: Please add "Apt." or "Unit" to your apartment number
                    or unit number, e.g. "Apt. 14D"
                </P>
                <Spacer />
                <ResponsiveContainer row>
                    <Input
                        name="city"
                        placeholder="City"
                        onChange={handleChange}
                        value={values.city}
                        error={errors.city}
                        flex={1}
                        data-private="redact"
                        alwaysShowError={alwaysShowError}
                    />
                    <Spacer />
                    <Container flex={1}>
                        <StateSelect
                            setFieldValue={setFieldValue}
                            values={values}
                            errors={errors}
                            alwaysShowError={alwaysShowError}
                        />
                    </Container>
                    <Spacer />
                    <Input
                        name="zip"
                        placeholder="Zip"
                        onChange={(e) => {
                            const zip = (e.target as HTMLInputElement).value
                                .replace(/[^0-9]/g, "")
                                .substr(0, 5)
                            setFieldValue("zip", zip)
                        }}
                        value={values.zip}
                        error={errors.zip}
                        flex={1}
                        data-private="redact"
                        alwaysShowError={alwaysShowError}
                    />
                </ResponsiveContainer>
                <Spacer />
                <Select
                    name="citizenship"
                    placeholder="Citizenship Status"
                    options={citizenOptions}
                    onOptionSelected={(_field, val) => {
                        setFieldValue("citizen", val.value)
                    }}
                    value={
                        citizenOptions.find(
                            (option) => option.value === values.citizen
                        )?.label
                    }
                    error={errors.citizen}
                    alwaysShowError={alwaysShowError}
                />
                <Spacer />
                <DatePicker
                    name="dob"
                    format="MM/dd/yyyy"
                    monthPlaceholder="MM"
                    dayPlaceholder="DD"
                    yearPlaceholder="YYYY"
                    value={values.dob}
                    onChange={(date) => {
                        setFieldValue("dob", date)
                    }}
                    error={errors.dob}
                    data-private="redact"
                    alwaysShowError={alwaysShowError}
                />
                <Spacer />
                <Select
                    name="education"
                    placeholder="Level of education"
                    options={educationOptions}
                    onOptionSelected={(_field, val) => {
                        setFieldValue("education_level", val.value)
                    }}
                    value={
                        educationOptions.find(
                            (option) => option.value === values.education_level
                        )?.label
                    }
                    error={errors.education_level}
                    alwaysShowError={alwaysShowError}
                />
                <Spacer />
                <SocialSecurityInput
                    name="ssn"
                    placeholder="SSN"
                    onChange={(e) => setSSNInput(e, "ssn")}
                    value={values.ssn}
                    error={errors.ssn}
                    alwaysShowError={alwaysShowError}
                />
                <Spacer />
                <SocialSecurityInput
                    name="confirmSSN"
                    placeholder="Confirm SSN"
                    onChange={(e) => setSSNInput(e, "confirmSSN")}
                    value={values.confirmSSN}
                    error={errors.confirmSSN}
                    alwaysShowError={alwaysShowError}
                />
                <Spacer />
                <SecureLabel />
                <Spacer lg />
                <SubmitButtonContainer
                    disabled={disabled}
                    isLoading={addressValidationLoading}
                    handleSubmit={() => {
                        if (Object.keys(errors).length > 0) {
                            setAlwaysShowError(true)
                        } else {
                            validateAddress()
                        }
                    }}
                    errors={errors}
                    title="Next"
                />
            </ContentContainer>
            <Spacer lg />
            <AutomaticRejectionModal
                open={modalOpen}
                invalidReasons={invalidReasons}
            />
            <ProgramConfirmationModal
                loading={modalLoading}
                open={showConfirmationModal}
                toggleModal={() => {
                    setShowConfirmationModal(false)
                }}
                handleSubmit={handleSubmit}
                errors={errors}
                values={values}
            />
            <NotFoundAddressConfirmationModal
                open={showManualAddressModal}
                toggleModal={() => {
                    setShowManualAddressModal(false)
                }}
                handleConfirm={handleSubmit}
                addressToConfirm={{
                    street: values.street,
                    street_line_two: values.street_line_two,
                    city: values.city,
                    state: values.state,
                    zip: values.zip,
                }}
            />
        </Container>
    )
}
