import { Spacer } from "@wetradeup/ui-kit"
import { InnerModal } from "../ModalComponents"
import { ExitCounselingRoute } from "./ExitCounselingRoute"
import { breakpoints } from "../../constants"
import styled from "@emotion/styled"

const LoanOrientationWrapper = styled(InnerModal)`
    padding-top: 20px;

    @media (max-width: ${breakpoints.desktopSm}) {
        padding-top: 0px;
    }
`

export default function ExitCounselingWrapper() {
    return (
        <>
            <LoanOrientationWrapper flex={1} alignStretch alignItems="center">
                <ExitCounselingRoute />
            </LoanOrientationWrapper>
            <Spacer lg />
        </>
    )
}
