import React, { useEffect, useMemo, useState } from "react"
import { baseFetch } from "@wetradeup/student-apps-shared"
import { useFynnLocation } from "../hooks/useFynnLocation"

export default function Paused() {
    const location = useFynnLocation()
    const [schoolName, programName] = useMemo(() => {
        const params = new URLSearchParams(location.search)
        return [params.get("school"), params.get("program")]
    }, [location.search])

    const [emailAddress, setEmailAddress] = useState("")
    const [contactSubmitted, setContactSubmitted] = useState(false)
    const [emailInvalid, setEmailInvalid] = useState(false)
    const [showError, setShowError] = useState(false)

    useEffect(() => {
        setEmailInvalid(false)
    }, [emailAddress])

    const onContactSubmitted = async (evt) => {
        evt.preventDefault()
        const body = {
            email: emailAddress,
            ...(schoolName && { school_interested_in: schoolName }),
            ...(programName && { program_interested_in: programName }),
        }
        if (!/\S+@\S+\.\S+/.test(emailAddress)) {
            setEmailInvalid(true)
            return
        }

        try {
            const res = await baseFetch("https://api.wetradeup.com/contacts", {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    "Content-Type": "application/json",
                },
            })

            if (res.ok) {
                setContactSubmitted(true)
            } else {
                throw res.status
            }
        } catch {
            setShowError(true)
        }
    }

    return (
        <div className="notFound-container">
            <div>
                <img
                    className="not-found-img"
                    src={require("../../assets/comingSoon.png")}
                    width="700px"
                    alt="Coming soon!"
                ></img>
                <h3 className="text-white text-center">
                    Due to COVID-19 related delays, we are not currently
                    accepting applications from {schoolName}.
                </h3>

                {!contactSubmitted && (
                    <form onSubmit={onContactSubmitted} className="container">
                        <div className="email-address">
                            <h4 className="text-white">
                                If you would like to receive an update when we
                                begin accepting applications again, please enter
                                your email below.
                            </h4>
                            <input
                                className="form-control"
                                type="text"
                                onChange={(evt) =>
                                    setEmailAddress(evt.target.value)
                                }
                            />
                            <button
                                disabled={contactSubmitted}
                                className="button-large green-button button-center"
                            >
                                Submit
                            </button>

                            {emailInvalid && (
                                <p style={{ color: "#E7CCCD" }}>
                                    Please enter a valid email address!
                                </p>
                            )}

                            {showError && (
                                <p style={{ color: "#E7CCCD" }}>
                                    Unfortunately, there seems to be a problem:
                                    please try again later
                                </p>
                            )}
                        </div>
                    </form>
                )}
                {contactSubmitted && (
                    <h4 style={{ color: "#13d58a" }}>
                        Thanks for your interest! We'll be in touch with any
                        updates.
                    </h4>
                )}
            </div>
        </div>
    )
}
