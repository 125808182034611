import { ArrowIcon, Container, TouchableContainer, H6 } from "@wetradeup/ui-kit"
import { useEffect, useState } from "react"

interface CollapsibleContainerProps {
    header: string
    collapsedInit?: boolean
    children: React.ReactChild | React.ReactChild[]
    disabled?: boolean
}
export function CollapsibleContainer({
    header,
    collapsedInit,
    children,
    disabled,
}: CollapsibleContainerProps) {
    const [minimized, setMinimized] = useState(collapsedInit)

    useEffect(() => {
        setMinimized(!!(collapsedInit || disabled))
    }, [collapsedInit])

    return (
        <Container alignStretch>
            <TouchableContainer
                row
                justifyContent="space-between"
                alignItems="center"
                alignStretch
                onPress={() => !disabled && setMinimized(!minimized)}
            >
                <H6
                    weight={500}
                    textColor={disabled ? "medGrey" : "darkBlue"}
                    textAlign="left"
                >
                    {header}
                </H6>
                <ArrowIcon
                    style={{
                        transition: "0.2s ease-in-out transform",
                        transform: !minimized ? "rotateZ(180deg)" : "",
                    }}
                    fill={disabled ? "lightGrey" : "medGrey"}
                    stroke={disabled ? "lightGrey" : "darkBlue"}
                />
            </TouchableContainer>
            {!(minimized || disabled) && children}
        </Container>
    )
}
