export function Email() {
    return (
        <svg
            width="51"
            height="30"
            viewBox="0 0 51 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M49.0346 28.6221C49.0346 28.6848 49.0346 28.6848 48.9719 28.6848H1.24428C1.18165 28.6848 1.18164 28.6848 1.18164 28.6221V1.86732C1.18164 1.80469 1.18165 1.80469 1.24428 1.80469H48.9719C49.0346 1.80469 49.0346 1.80469 49.0346 1.86732V28.6221Z"
                fill="#EAEEEF"
                stroke="#344F5B"
                stroke-width="2"
            />
            <path
                d="M1.81641 2.5022L25.0753 17.6902L48.3996 2.5022"
                stroke="#344F5B"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M21.4541 15.2761L1.81641 28.05"
                stroke="#344F5B"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M48.2719 27.9845L28.6997 15.2761"
                stroke="#344F5B"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
