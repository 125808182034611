import React from "react"
import { Route, Switch } from "react-router-dom"
import { IntroForm } from "./IntroForm/IntroForm"
import { AuthRoute } from "../AuthRoute"
import VerifyEmail from "./VerifyEmail"
import QuestionnairePage from "./QuestionnairePage"
import BasicInfoPage from "./BasicInfoPage"
import ReviewQuestionnaire from "./ReviewQuestionnaire"
import LoadingPage from "./molecules/LoadingPage"
import { PublicSchool } from "@wetradeup/student-apps-shared"
import { CosignerSelectionPage } from "./CosignerSelectionPage"
import VerifyPhone from "./VerifyPhone"

interface ApplicationFormRoutesProps {
    schoolId?: number
    appFormId?: number
    programId?: number
    employerId?: number
    setAppFormId: React.Dispatch<React.SetStateAction<number>>
    setSchoolId: React.Dispatch<React.SetStateAction<number>>
    setSchoolName: React.Dispatch<React.SetStateAction<string>>
    school?: PublicSchool
}

export function ApplicationFormRoutes({
    schoolId,
    appFormId,
    programId,
    employerId,
    setAppFormId,
    setSchoolId,
    setSchoolName,
    school,
}: ApplicationFormRoutesProps) {
    return (
        <Switch>
            <Route path="/application" exact>
                <LoadingPage />
            </Route>
            <Route
                path="/application/intro"
                render={(props) => (
                    <IntroForm
                        {...props}
                        schoolId={schoolId}
                        appFormId={appFormId}
                        setAppFormId={setAppFormId}
                        setSchoolId={setSchoolId}
                        setSchoolName={setSchoolName}
                        school={school}
                    />
                )}
            />
            <Route
                path="/application/:schoolId/email"
                render={(props) => <VerifyEmail {...props} />}
            />
            <Route
                path="/application/:schoolId/phone"
                render={(props) => <VerifyPhone {...props} />}
            />
            <AuthRoute
                path="/application/:schoolId/basic"
                render={(props) => (
                    <BasicInfoPage
                        {...props}
                        schoolId={schoolId}
                        appFormId={appFormId}
                        setSchoolId={setSchoolId}
                        setSchoolName={setSchoolName}
                        school={school}
                    />
                )}
            />
            <AuthRoute
                path="/application/:schoolId/cosigner"
                render={(props) => (
                    <CosignerSelectionPage {...props} appFormId={appFormId} />
                )}
            />
            <AuthRoute
                path="/application/:schoolId/questionnaire"
                render={(props) => (
                    <QuestionnairePage
                        {...props}
                        appFormId={appFormId}
                        programId={programId}
                        employerId={employerId}
                    />
                )}
            />
            <AuthRoute
                path="/application/:schoolId/review"
                render={(props) => (
                    <ReviewQuestionnaire
                        {...props}
                        appFormId={appFormId}
                        programId={programId}
                        employerId={employerId}
                    />
                )}
            />
        </Switch>
    )
}
