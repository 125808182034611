import { Stripe } from "@stripe/stripe-js"
import { loadStripe } from "@stripe/stripe-js/pure"
import React, { useEffect, useState } from "react"
import { STRIPE_PUBLIC_KEY } from "../../config"

export interface IStripeContext {
    stripe: Stripe
}

export const StripeContext = React.createContext<IStripeContext>({
    stripe: null,
})

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY)

export const StripeProvider: React.FC = ({ children }) => {
    const [stripe, setStripe] = useState<Stripe>()

    useEffect(() => {
        ;(async () => {
            const s = await stripePromise
            setStripe(s)
        })()
    }, [])

    return (
        <StripeContext.Provider value={{ stripe }}>
            {children}
        </StripeContext.Provider>
    )
}
