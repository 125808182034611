import {
    Card,
    Container,
    ErrorMessage,
    H2,
    Input,
    Modal,
    Spacer,
    TouchableContainer,
} from "@wetradeup/ui-kit"
import * as Yup from "yup"
import { ContentContainer, InnerModal } from "../../../ModalComponents"
import { useFormik } from "formik"
import { SubmissionButton } from "../../atoms/SubmissionButton"
import { UnderlineText } from "../../../atoms/UnderlineText"
import {
    useInviteCosigner,
    useReapplyWithCosigner,
} from "@wetradeup/student-apps-shared"
import { SpinningLoader } from "../../atoms/SpinningLoader"
import { useState } from "react"

const valSchema = Yup.object().shape({
    first_name: Yup.string(),
    last_name: Yup.string(),
    email: Yup.string(),
})

interface LoginForm {
    first_name: string
    last_name: string
    email: string
}

interface CosignerInviteModalProps {
    open: boolean
    closeModal: () => void
    appFormId?: number
    postSuccessAction?: () => void
}

export function CosignerInviteModal({
    open,
    closeModal,
    appFormId,
    postSuccessAction,
}: CosignerInviteModalProps) {
    const [serverError, setServerError] = useState("")
    const reapplyWithCosigner = useReapplyWithCosigner()
    const inviteCosigner = useInviteCosigner()
    const onSubmit = async () => {
        try {
            setServerError("")
            if (appFormId) {
                await inviteCosigner.mutateAsync({
                    app_form_id: appFormId.toString(),
                    first_name: values.first_name,
                    last_name: values.last_name,
                    email: values.email,
                })
                postSuccessAction && postSuccessAction()
            } else {
                await reapplyWithCosigner.mutateAsync({
                    first_name: values.first_name,
                    last_name: values.last_name,
                    email: values.email,
                })
            }
            closeModal()
        } catch (e) {
            setServerError(e?.error || "")
        }
    }
    const { handleSubmit, handleChange, values, errors } = useFormik<LoginForm>(
        {
            initialValues: {
                first_name: "",
                last_name: "",
                email: "",
            },
            validationSchema: valSchema,
            onSubmit,
        }
    )
    return (
        <Modal
            visible={open}
            width="100%"
            onClick={() => closeModal && closeModal()}
        >
            <InnerModal
                width={[
                    [700, "md"],
                    [860, "lg"],
                ]}
                onClick={(e) => e.stopPropagation()}
            >
                <Card paddingLg>
                    <Spacer lg />
                    <ContentContainer alignItems="center" width="100%">
                        <H2>Invite your co-signer</H2>
                        <Spacer lg />
                        <Container flex row width="100%">
                            <Input
                                name="first_name"
                                placeholder="First name"
                                onChange={handleChange}
                                fullWidth
                                flex={1}
                                value={values.first_name}
                                error={errors.first_name}
                                tabIndex={1}
                            />
                            <Spacer lg />
                            <Input
                                name="last_name"
                                placeholder="Last name"
                                onChange={handleChange}
                                fullWidth
                                flex={1}
                                value={values.last_name}
                                error={errors.last_name}
                                tabIndex={2}
                            />
                        </Container>
                        <Spacer lg />
                        <Container width="100%">
                            <Input
                                name="email"
                                placeholder="Email"
                                fullWidth
                                onChange={handleChange}
                                value={values.email}
                                error={errors.email}
                                flex={1}
                                tabIndex={3}
                            />
                        </Container>
                        <Spacer lg />
                        {!inviteCosigner.isLoading &&
                            !reapplyWithCosigner.isLoading && (
                                <Container width="100%" maxWidth="300px">
                                    <SubmissionButton
                                        width="100%"
                                        onPress={() => {
                                            handleSubmit()
                                        }}
                                        primary
                                        title="Invite"
                                    />
                                </Container>
                            )}
                        {serverError && (
                            <ErrorMessage>{serverError}</ErrorMessage>
                        )}
                        {(inviteCosigner.isLoading ||
                            reapplyWithCosigner.isLoading) && (
                            <SpinningLoader />
                        )}
                        <Spacer />
                        <TouchableContainer
                            onPress={() => {
                                closeModal && closeModal()
                            }}
                        >
                            <UnderlineText bold textColor="black">
                                Back
                            </UnderlineText>
                        </TouchableContainer>
                    </ContentContainer>
                    <Spacer lg />
                </Card>
            </InnerModal>
        </Modal>
    )
}
