import { useHistory, useRouteMatch } from "react-router-dom"
import { Container, Spacer } from "@wetradeup/ui-kit"
import { ApplicationButton } from "../../atoms/ApplicationButton"
import { DefaultPage } from "../molecules/DefaultPage"
import { SpinningLoader } from "../../application/atoms/SpinningLoader"
import { useProperExitCounseling } from "../hooks/useProperExitCounseling"
import { useProperUpdateExitCounselingStatus } from "../hooks/useProperUpdateExitCounselingStatus"
import {
    ExitCounseling,
    OnboardedExitCounseling,
} from "@wetradeup/student-apps-shared"
import { LoanOrientationTerms } from "./LoanOrientationTerms"

export function ExitCounselingOverview() {
    const history = useHistory()
    const match = useRouteMatch<{ accountId?: string }>({
        path: "/loan-orientation/admin/:accountId/overview",
    })
    const exitCounseling = useProperExitCounseling(match)
    const updateExitCounselingStatus =
        useProperUpdateExitCounselingStatus(match)

    const handleContinue = async () => {
        await updateExitCounselingStatus.mutateAsync({
            exit_counseling_stage: "overview",
        })

        if (match?.params?.accountId) {
            history.push(
                `/loan-orientation/admin/${match?.params?.accountId}/repayment`
            )
        } else {
            history.push("/loan-orientation/repayment")
        }
    }

    if (!exitCounseling.data) {
        return <SpinningLoader />
    }

    return (
        <DefaultPage>
            <LoanOrientationTerms
                loanOrientationData={
                    exitCounseling.data.onboarded_to_servicer
                        ? (exitCounseling.data as OnboardedExitCounseling)
                        : (exitCounseling.data as ExitCounseling)
                }
                isOnboarded={exitCounseling.data.onboarded_to_servicer}
            />
            <Spacer lg />
            <Container width={300}>
                {updateExitCounselingStatus.isLoading && <SpinningLoader />}
                {!updateExitCounselingStatus.isLoading && (
                    <ApplicationButton
                        fullWidth
                        alignCenter
                        title="Continue"
                        buttonColor="newBlue"
                        onPress={handleContinue}
                    />
                )}
            </Container>
        </DefaultPage>
    )
}
