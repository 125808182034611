import { useHistory } from "react-router-dom"
import {
    useGetAccount,
    useStudentApplicationDetails,
    ApplicantAccount,
    useGetStudentProfile,
} from "@wetradeup/student-apps-shared"
import { useEffect } from "react"
import { useFynnLocation } from "../../hooks/useFynnLocation"

export function useIslFinalizeWrapperRouting() {
    const history = useHistory()
    const location = useFynnLocation()

    const studentApplicationDetails = useStudentApplicationDetails({
        enabled: true,
    })
    const studentProfile = useGetStudentProfile({ enabled: true })
    const accountQuery = useGetAccount()

    useEffect(() => {
        if (location.state?.disableAutoRouting) return

        if (accountQuery.isSuccess && studentApplicationDetails.isError) {
            if (
                "underwriting_decision" in accountQuery.data &&
                (accountQuery.data as ApplicantAccount)
                    ?.underwriting_decision !== "approved"
            ) {
                history.push("/")
            }
        }

        if (studentApplicationDetails.isSuccess && studentProfile.isSuccess) {
            const {
                underwriting_decision,
                approved_school,
                underwriting_recommendation,
                decision,
                is_cancelled,
                note_size_update_status,
                autopay_consent_received,
                should_show_identity_verification_modal,
                dropped_out_within_cancellation_period,
                note,
                is_cosigner_reapplication_allowed,
                application_type,
                did_cosigner_submit,
                did_cosigner_cancel,
                is_cosigner_ready_for_signing,
                attempted_identity_verification_at,
            } = studentApplicationDetails.data

            const acceptance_contract = note?.contracts.find(
                (c) => c.contract_type === "acceptance_packet"
            )

            const waitingOnCosigner =
                application_type === "with_cosigner" &&
                !is_cosigner_ready_for_signing

            if (
                (is_cancelled && underwriting_recommendation !== "locked") ||
                dropped_out_within_cancellation_period
            ) {
                return history.push("/application")
            } else if (
                (underwriting_decision === "rejected" ||
                    decision === "rejected") &&
                is_cosigner_reapplication_allowed
            ) {
                return history.push("/isl-finalize/decisioned")
            } else if (
                (underwriting_decision === "rejected" ||
                    decision === "rejected") &&
                !is_cosigner_reapplication_allowed
            ) {
                return history.push("/application/intro/select")
            } else if (
                underwriting_decision === "pending" &&
                underwriting_recommendation === "locked"
            ) {
                return history.push("/isl-finalize/locked")
            } else if (
                underwriting_decision === "pending" &&
                underwriting_recommendation ===
                    "approved_after_fraud_alert_clarification"
            ) {
                return history.push("/isl-finalize/alerts")
            } else if (
                underwriting_decision === "pending" &&
                should_show_identity_verification_modal &&
                !attempted_identity_verification_at
            ) {
                return history.push("/isl-finalize/verification")
            } else if (
                underwriting_decision === "pending" &&
                application_type === "with_cosigner" &&
                did_cosigner_cancel
            ) {
                return history.push("/isl-finalize/cosigner-canceled")
            } else if (
                underwriting_decision === "pending" &&
                application_type === "with_cosigner" &&
                !did_cosigner_submit
            ) {
                return history.push("/isl-finalize/cosigner-awaiting")
            } else if (underwriting_decision === "pending") {
                return history.push("/isl-finalize/pending")
            } else if (note_size_update_status === "pending_student_request") {
                return history.push("/isl-finalize/loan-terms")
            } else if (
                studentProfile.data?.bank_identity_verification !==
                    "approved" &&
                studentProfile.data?.plaid_status ===
                    "pending_automatic_verification"
            ) {
                return history.push(`/isl-finalize/pending-automatic`)
            } else if (
                studentProfile.data?.bank_identity_verification !==
                    "approved" &&
                studentProfile.data?.plaid_status ===
                    "pending_manual_verification"
            ) {
                return history.push(`/isl-finalize/pending-manual`)
            } else if (
                underwriting_decision === "approved" &&
                ((underwriting_recommendation ===
                    "approved_after_id_verification" &&
                    studentProfile.data?.document_identity_verification !==
                        "approved") ||
                    studentProfile.data?.bank_identity_verification !==
                        "approved")
            ) {
                return history.push("/isl-finalize/identity")
            } else if (
                studentProfile.data?.bank_identity_verification ===
                    "approved" &&
                (underwriting_recommendation !==
                    "approved_after_id_verification" ||
                    studentProfile.data?.document_identity_verification ===
                        "approved") &&
                approved_school !== "approved"
            ) {
                return history.push("/isl-finalize/awaiting")
            } else if (note_size_update_status === "pending_student_approval") {
                return history.push("/isl-finalize/financing-changed")
            } else if (
                note_size_update_status === "approved" &&
                !acceptance_contract?.student_signed_on &&
                waitingOnCosigner
            ) {
                return history.push("/isl-finalize/awaiting")
            } else if (
                note_size_update_status === "approved" &&
                !acceptance_contract?.student_signed_on &&
                !waitingOnCosigner
            ) {
                return history.push("/isl-finalize/school-confirmed")
            } else if (
                acceptance_contract?.student_signed_on &&
                !autopay_consent_received
            ) {
                return history.push("/isl-finalize/autopay")
            } else {
                return history.push("/isl-finalize/complete")
            }
        }
    }, [
        accountQuery.isSuccess,
        studentApplicationDetails.isSuccess,
        studentProfile.isSuccess,
        studentApplicationDetails.data?.underwriting_decision,
        studentApplicationDetails.data?.identity_verified,
        studentApplicationDetails.data?.underwriting_recommendation,
        studentApplicationDetails.data?.note_size_update_status,
        studentApplicationDetails.data?.autopay_consent_received,
        studentApplicationDetails.data?.attempted_identity_verification_at,
        studentApplicationDetails.data?.should_show_identity_verification_modal,
        studentApplicationDetails.data?.dropped_out_within_cancellation_period,
        studentApplicationDetails.data?.note,
        studentApplicationDetails.data?.is_cosigner_reapplication_allowed,
        studentProfile.data,
        location.state,
    ])

    return {
        studentApplicationDetails,
        studentProfile,
    }
}
