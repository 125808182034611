import { useTheme } from "@emotion/react"
import styled from "@emotion/styled"
import { WEBSITE_URL } from "@wetradeup/student-apps-shared"
import { FynnFullLetterLogo, P, TouchableContainer } from "@wetradeup/ui-kit"

const FixedTouchableContainer = styled(TouchableContainer)`
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 200;

    @media (max-width: 1440px) {
        display: none;
    }
`

const StyledP = styled(P)`
    padding-left: 24px;
`
export function NavBarExitCounseling() {
    const theme = useTheme()
    return (
        <FixedTouchableContainer
            onPress={() => {
                window.location.href = WEBSITE_URL
            }}
        >
            <FynnFullLetterLogo white />
            <StyledP textSize={theme.sizings.fontSizeSm} textColor="white">
                Loan Orientation
            </StyledP>
        </FixedTouchableContainer>
    )
}
