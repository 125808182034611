export function VerifyingIcon() {
    return (
        <svg
            width="328"
            height="275"
            viewBox="0 0 328 275"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity="0.58"
                d="M280.358 209.183L280.042 207.843C278.855 202.945 279.132 197.734 280.751 192.915L282.806 186.993C283.082 186.241 283.91 185.806 284.701 186.085L311.197 195.246C311.946 195.522 312.381 196.35 312.105 197.141L310.051 203.064C309.658 204.248 309.103 205.472 308.551 206.579C307.406 207.92 298.009 211.987 293.427 213.014C288.885 214.041 282.607 212.026 280.358 209.183Z"
                fill="#EAEEEF"
                stroke="#344F5B"
            />
            <path
                opacity="0.58"
                d="M260.456 251.791C262.112 247.012 265.115 242.749 269.102 239.63L281.186 230.113C283.398 228.414 284.345 225.533 283.713 222.807C288.412 220.795 289.124 224.978 289.124 224.978C287.069 226.717 286.281 229.481 286.873 232.088L290.464 247.012C291.019 249.383 291.295 251.83 291.176 254.278C289.479 256.885 281.423 255.897 277.751 256.728C272.577 257.912 260.573 257.713 259.269 255.149L260.456 251.791Z"
                fill="#EAEEEF"
                stroke="#344F5B"
            />
            <path
                d="M287.029 226.598C286.082 227.349 284.621 226.874 284.345 225.69L283.634 222.847L280.358 209.183C290.268 210.723 298.561 205.116 308.551 206.539C306.854 209.934 304.364 212.857 301.404 215.225C301.404 215.225 292.36 222.374 289.32 224.742C289.084 224.939 287.029 226.598 287.029 226.598Z"
                fill="#EAEEEF"
                stroke="#344F5B"
            />
            <path
                d="M285.253 231.416C284.945 231.416 284.701 231.17 284.701 230.864C284.701 230.558 284.945 230.309 285.253 230.309C285.557 230.309 285.805 230.558 285.805 230.864C285.805 231.17 285.557 231.416 285.253 231.416Z"
                fill="#EAEEEF"
                stroke="#344F5B"
            />
            <path
                d="M285.253 228.692C284.945 228.692 284.701 228.446 284.701 228.138C284.701 227.834 284.945 227.585 285.253 227.585C285.557 227.585 285.805 227.834 285.805 228.138C285.805 228.446 285.557 228.692 285.253 228.692Z"
                fill="#EAEEEF"
                stroke="#344F5B"
            />
            <path
                d="M285.253 249.027C284.945 249.027 284.701 248.784 284.701 248.475C284.701 248.172 284.945 247.923 285.253 247.923C285.557 247.923 285.805 248.172 285.805 248.475C285.805 248.784 285.557 249.027 285.253 249.027Z"
                fill="#EAEEEF"
                stroke="#344F5B"
            />
            <path
                d="M287.701 267.785C287.425 268.534 286.596 268.969 285.805 268.693L259.309 259.532C258.56 259.256 258.125 258.425 258.401 257.636L259.269 255.109C266.458 254.99 274.433 253.45 279.647 252.266C283.082 251.475 286.713 251.91 289.833 253.53C290.308 253.806 290.82 254.002 291.216 254.241C291.136 256.845 290.584 259.412 289.716 261.9L287.701 267.785Z"
                fill="#EAEEEF"
                stroke="#344F5B"
            />
            <path
                d="M179.215 93.2278C192.083 93.2278 202.516 103.661 202.516 116.529C202.516 129.4 192.083 139.833 179.215 139.833C166.344 139.833 155.914 129.4 155.914 116.529C155.914 103.661 166.344 93.2278 179.215 93.2278Z"
                fill="#EAEEEF"
                stroke="#344F5B"
            />
            <path
                d="M192.33 130.854C198.763 130.854 203.979 136.068 203.979 142.506C203.979 148.939 198.763 154.155 192.33 154.155C185.895 154.155 180.678 148.939 180.678 142.506C180.678 136.068 185.895 130.854 192.33 130.854Z"
                fill="#EAEEEF"
                stroke="#344F5B"
            />
            <path
                d="M126.026 179.991C129.243 179.991 131.849 182.601 131.849 185.817C131.849 189.033 129.243 191.643 126.026 191.643C122.807 191.643 120.2 189.033 120.2 185.817C120.2 182.601 122.807 179.991 126.026 179.991Z"
                fill="#EAEEEF"
                stroke="#344F5B"
            />
            <path
                d="M49.4506 243C57.9829 243 64.9012 249.915 64.9012 258.448C64.9012 266.983 57.9829 273.898 49.4506 273.898C40.9182 273.898 34 266.983 34 258.448C34 249.915 40.9182 243 49.4506 243Z"
                fill="#EAEEEF"
                stroke="#344F5B"
            />
            <path
                d="M220.697 214.917C220.697 224.991 228.885 233.179 238.962 233.179C249.037 233.179 257.225 224.991 257.225 214.917H220.697Z"
                fill="#EAEEEF"
                stroke="#344F5B"
            />
            <path
                d="M254.579 214.917C254.579 204.84 246.391 196.651 236.316 196.651C226.239 196.651 218.051 204.84 218.051 214.917H254.579Z"
                fill="#EAEEEF"
                stroke="#344F5B"
            />
            <path
                d="M128.388 18.4209C130.503 18.4209 132.217 20.1346 132.217 22.2496C132.217 24.3616 130.503 26.0753 128.388 26.0753C126.276 26.0753 124.562 24.3616 124.562 22.2496C124.562 20.1346 126.276 18.4209 128.388 18.4209Z"
                fill="#EAEEEF"
                stroke="#344F5B"
            />
            <path
                d="M323.248 127.026C325.36 127.026 327.077 128.742 327.077 130.854C327.077 132.969 325.36 134.683 323.248 134.683C321.133 134.683 319.42 132.969 319.42 130.854C319.42 128.742 321.133 127.026 323.248 127.026Z"
                fill="#EAEEEF"
                stroke="#344F5B"
            />
            <path
                d="M75.5512 48C82.4845 48 88.1053 53.6209 88.1053 60.5512C88.1053 67.4845 82.4845 73.1023 75.5512 73.1023C68.6209 73.1023 63 67.4845 63 60.5512C63 53.6209 68.6209 48 75.5512 48Z"
                fill="#EAEEEF"
                stroke="#344F5B"
            />
            <path
                d="M52.5478 139.251C53.9266 139.821 54.5813 141.402 54.0111 142.781C53.4409 144.16 51.8569 144.814 50.4811 144.241C49.1023 143.674 48.4476 142.093 49.0178 140.714C49.591 139.332 51.169 138.681 52.5478 139.251Z"
                fill="#EAEEEF"
                stroke="#344F5B"
            />
            <path
                d="M115.218 1C118.655 1 121.44 3.78479 121.44 7.22127C121.44 10.6577 118.655 13.4395 115.218 13.4395C111.782 13.4395 109 10.6577 109 7.22127C109 3.78479 111.782 1 115.218 1Z"
                fill="#EAEEEF"
                stroke="#344F5B"
            />
            <path
                d="M131.849 55.2637C135.286 55.2637 138.071 58.0454 138.071 61.4819C138.071 64.9184 135.286 67.7032 131.849 67.7032C128.416 67.7032 125.631 64.9184 125.631 61.4819C125.631 58.0454 128.416 55.2637 131.849 55.2637Z"
                fill="#EAEEEF"
                stroke="#344F5B"
            />
            <path
                d="M259.898 123.173C261.377 123.173 262.574 124.368 262.574 125.846C262.574 127.321 261.377 128.519 259.898 128.519C258.423 128.519 257.225 127.321 257.225 125.846C257.225 124.368 258.423 123.173 259.898 123.173Z"
                fill="#EAEEEF"
                stroke="#344F5B"
            />
            <path
                opacity="0.15"
                d="M97.3487 157.842H88.1164V152.158H97.3487V157.842Z"
                fill="#EAEEEF"
                stroke="#344F5B"
            />
            <path
                opacity="0.15"
                d="M253.399 167.159H206.429V164.317H253.399V167.159Z"
                fill="#EAEEEF"
                stroke="#344F5B"
            />
            <path
                opacity="0.15"
                d="M273.014 145.713V171.353H214.506V157.842H117.666V152.158H214.506V145.713H144.711V82.5322H236.878V145.713H253.595V136.146H312.076V145.713H273.014Z"
                fill="#EAEEEF"
                stroke="#344F5B"
            />
            <path
                opacity="0.15"
                d="M45.5 106.5H34.0169V53.0279H45.5V106.5Z"
                fill="#EAEEEF"
                stroke="#344F5B"
            />
            <path
                d="M268.934 214.917H237.469V212.328H268.934V214.917Z"
                fill="#EAEEEF"
                stroke="#344F5B"
            />
            <path
                d="M292.293 37.3936H273.858V30.0198H292.293V37.3936Z"
                fill="#EAEEEF"
                stroke="#344F5B"
            />
            <path
                d="M251.616 242.647L261.745 49.1604H105.935L95.807 242.647H251.616Z"
                fill="white"
                stroke="#344F5B"
            />
            <path
                d="M108.931 52.4005L99.1288 239.57H248.539L258.341 52.4005H108.931ZM103.018 46.0012H265.067L254.615 245.809H92.4852L103.018 46.0012Z"
                fill="#EAEEEF"
                stroke="#344F5B"
            />
            <path
                d="M16.1005 146.811L17.7783 146.505L34.528 97.3687C35.6864 94.0896 39.3271 92.2234 42.6394 93.3105L94.0088 110.852L63.7911 199.54L6.15607 188.144C2.36771 187.368 0.099285 183.467 1.3417 179.794L11.1054 151.012C11.8692 148.85 13.7888 147.188 16.1005 146.811Z"
                fill="#EAEEEF"
                stroke="#344F5B"
            />
            <path
                d="M90.7042 111.142L49.6 104.049C46.5372 103.586 43.5992 105.411 42.4942 108.37L31.4143 140.792C30.6225 143.027 28.7334 144.619 26.4217 144.995L18.8246 146.268"
                stroke="#344F5B"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
