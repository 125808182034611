import { useState } from "react"
import {
    Card,
    Container,
    Modal,
    Spacer,
    H2,
    CancelIcon,
    TouchableContainer,
} from "@wetradeup/ui-kit"
import {
    EditingQuestionnaireAnswer,
    Questionnaire,
    QuestionnaireAnswer,
} from "@wetradeup/student-apps-shared"
import { RadioQuestion } from "./RadioQuestion"
import { MultipleRadioQuestion } from "./MultipleRadioQuestion"
import { PrioritizeQuestion } from "./PrioritizeQuestion"
import { SubmissionButton } from "../atoms/SubmissionButton"
import { ContentContainer } from "../../ModalComponents"
import styled from "@emotion/styled"
import { breakpoints } from "../../../constants"

const ExitButton = styled(TouchableContainer)`
    position: absolute;
    right: 15px;
    top: 15px;
`

const EditCard = styled(Card)`
    @media (max-width: ${breakpoints.mobileLg}) {
        height: 100%;
        max-width: 100vw;
    }
`

const EditContainer = styled(ContentContainer)`
    @media (max-height: 700px) {
        overflow-y: scroll;
        max-height: 60vh;
    }
    @media (max-height: 400px) {
        max-height: 40vh;
    }
`

interface QuestionnaireItemProps {
    question: Questionnaire
    questionnaire: Questionnaire[]
    setNewQuestionnaire: React.Dispatch<React.SetStateAction<Questionnaire[]>>
    dependentQuestions: Questionnaire[]
    setDependentQuestions: React.Dispatch<React.SetStateAction<Questionnaire[]>>
    handleFieldValueChange: (fieldName: string, value: any) => void
    values: QuestionnaireAnswer
    timeOfLastTouch: number
    setTimeOfLastTouch: React.Dispatch<React.SetStateAction<number>>
    newAnswer: EditingQuestionnaireAnswer
}

export interface DependentQuestions {
    [key: string]: Questionnaire[]
}

function QuestionnaireItem({
    question,
    questionnaire,
    setNewQuestionnaire,
    dependentQuestions,
    setDependentQuestions,
    handleFieldValueChange,
    values,
    timeOfLastTouch,
    setTimeOfLastTouch,
    newAnswer,
}: QuestionnaireItemProps) {
    return (
        <>
            {question.style === "radio" && (
                <ContentContainer>
                    <RadioQuestion
                        id={question.id}
                        title={question.title}
                        subtitle={question.subtitle}
                        options={question.options}
                        questionnaire={questionnaire}
                        dependentQuestions={dependentQuestions}
                        setQuestionnaire={setNewQuestionnaire}
                        setDependentQuestions={setDependentQuestions}
                        setFieldValue={handleFieldValueChange}
                        values={values}
                        setTimeOfLastTouch={setTimeOfLastTouch}
                        newAnswer={newAnswer}
                        isEditAnswer
                    />
                </ContentContainer>
            )}
            {question.style === "multiple_radio" && (
                <ContentContainer>
                    <MultipleRadioQuestion
                        id={question.id}
                        title={question.title}
                        subtitle={question.subtitle}
                        options={question.options}
                        questionnaire={questionnaire}
                        dependentQuestions={dependentQuestions}
                        setQuestionnaire={setNewQuestionnaire}
                        setDependentQuestions={setDependentQuestions}
                        setFieldValue={handleFieldValueChange}
                        values={values}
                        timeOfLastTouch={timeOfLastTouch}
                        setTimeOfLastTouch={setTimeOfLastTouch}
                        newAnswer={newAnswer}
                        isEditAnswer
                    />
                </ContentContainer>
            )}
            {question.style === "prioritize" && (
                <EditContainer>
                    <PrioritizeQuestion
                        id={question.id}
                        title={question.title}
                        subtitle={question.subtitle}
                        options={question.options}
                        values={values}
                        setFieldValue={handleFieldValueChange}
                        timeOfLastTouch={timeOfLastTouch}
                        setTimeOfLastTouch={setTimeOfLastTouch}
                        spacerSize={16}
                    />
                </EditContainer>
            )}
        </>
    )
}

export function EditAnswerModal({
    question,
    setQuestion,
    questionnaire,
    setQuestionnaire,
    setFieldValue,
    values,
    timeOfLastTouch,
    setTimeOfLastTouch,
}) {
    const [newAnswer, setNewAnswer] = useState<EditingQuestionnaireAnswer>({})
    const [dependentQuestions, setDependentQuestions] = useState<
        Questionnaire[]
    >([])
    const handleFieldValueChange = (fieldName: string, value: any) => {
        setNewAnswer({ ...newAnswer, [fieldName]: value })
    }
    const updateAnswer = () => {
        Object.entries(newAnswer).forEach(([fieldName, value]) => {
            setFieldValue(fieldName, value)
        })
        setNewAnswer({})
        setDependentQuestions([])
        setQuestion(null)
    }

    const closeModal = () => {
        setNewAnswer({})
        setQuestion(null)
    }

    const disabledFreeform =
        newAnswer?.value?.value === "freeform" && !newAnswer.value?.text

    const disabled = question && !newAnswer

    return (
        <Modal visible={!!question}>
            <EditCard
                paddingMd
                width={[
                    [550, "md"],
                    [750, "lg"],
                ]}
            >
                <ExitButton
                    alignStretch
                    onPress={closeModal}
                    justifyContent="center"
                    alignItems="center"
                >
                    <CancelIcon />
                </ExitButton>
                <Spacer />
                <H2 bold="light"> Edit your answers </H2>
                <Spacer />
                {question && (
                    <QuestionnaireItem
                        question={question}
                        questionnaire={questionnaire}
                        setNewQuestionnaire={setQuestionnaire}
                        dependentQuestions={dependentQuestions}
                        setDependentQuestions={setDependentQuestions}
                        handleFieldValueChange={handleFieldValueChange}
                        values={values}
                        timeOfLastTouch={timeOfLastTouch}
                        setTimeOfLastTouch={setTimeOfLastTouch}
                        newAnswer={newAnswer}
                    />
                )}
                {dependentQuestions.map((dependentQuestion) => (
                    <QuestionnaireItem
                        question={dependentQuestion}
                        questionnaire={questionnaire}
                        setNewQuestionnaire={setQuestionnaire}
                        dependentQuestions={dependentQuestions}
                        setDependentQuestions={setDependentQuestions}
                        handleFieldValueChange={handleFieldValueChange}
                        values={values}
                        timeOfLastTouch={timeOfLastTouch}
                        setTimeOfLastTouch={setTimeOfLastTouch}
                        newAnswer={newAnswer}
                    />
                ))}
                <Spacer lg />
                <Container alignItems="center">
                    <SubmissionButton
                        onPress={updateAnswer}
                        disabled={disabled || disabledFreeform}
                        title="Update"
                    />
                </Container>
                <Spacer />
            </EditCard>
        </Modal>
    )
}
