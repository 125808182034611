import styled from "@emotion/styled"
import {
    CancelIcon,
    Container,
    P,
    Spacer,
    TouchableContainer,
} from "@wetradeup/ui-kit"
import { useState } from "react"
import { useHistory } from "react-router-dom"
import { useNavBar } from "../../hooks/useNavBar"
import { MenuIcon } from "./NavBarAuthenticatedMenu"
import { breakpoints } from "../../../constants"
import { ApplicationButton } from "../../atoms/ApplicationButton"

const SignInItem = styled(P)`
    color: ${(props) => props.theme.colors.palette.black};
    font-size: ${(props) => props.theme.sizings.fontSizeInc1};
    @media (max-width: ${breakpoints.mobileLg}) {
        color: ${(props) => props.theme.colors.palette.white};
    }
`

const RoundButton = styled(ApplicationButton)`
    width: 180px;
`

const MobileDropdown = styled(Container)`
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    ${(props) => !props.visible && "padding: 0px"};
    padding-top: ${(props) => (props.visible ? "70px" : "0px")};
    height: ${(props) =>
        props.visible && props.showAuthenticatedNavBar
            ? "120px"
            : props.visible
            ? "fit-content"
            : "0px"};
    transition: 0.15s cubic-bezier(0, 0, 0.58, 1) height,
        0.15s cubic-bezier(0, 0, 0.58, 1) padding;
    overflow-y: hidden;
`

const NavContainerOuter = styled(Container)`
    position: absolute;
    top: 0;
    left: 0;
    height: 60x;
    width: 100%;
    z-index: 200;

    @media (min-width: ${breakpoints.desktopSm}) {
        display: none;
    }
`

const MobileNavContainerInner = styled(Container)`
    width: 100%;
    padding-left: ${(props) => props.theme.sizings.paddingLg};
    padding-right: ${(props) => props.theme.sizings.paddingLg};
    position: relative;
    height: 60px;
    position: fixed;
    top: 0;
    z-index: 300;
`

export function NavBarMobileAuthenticatedMen() {
    const [showDropdown, setShowDropdown] = useState(false)
    const history = useHistory()
    const {
        applicationCTAText,
        applicationCTAClick,
        handleSignIn,
        showAuthenticatedNavBar,
        signInCTAText,
    } = useNavBar()

    const handleProfile = () => {
        setShowDropdown(false)
        return history.push("/application/intro/select")
    }
    return (
        <NavContainerOuter justifyContent="center" alignItems="center">
            <MobileNavContainerInner
                row
                justifyContent="space-between"
                alignItems="center"
            >
                <TouchableContainer
                    onPress={() => setShowDropdown(!showDropdown)}
                >
                    {showDropdown ? <CancelIcon /> : <MenuIcon />}
                </TouchableContainer>
                <MobileDropdown
                    paddingMd
                    alignItems="flex-start"
                    backgroundColor="darkerGreenBackground"
                    visible={showDropdown}
                    showAuthenticatedNavBar={showAuthenticatedNavBar}
                >
                    {showDropdown && (
                        <>
                            <Container row alignItems="center">
                                <Spacer size="lg" />
                                {signInCTAText && (
                                    <TouchableContainer
                                        onPress={() => {
                                            handleSignIn()
                                            setShowDropdown(false)
                                        }}
                                    >
                                        <SignInItem>
                                            {" "}
                                            {signInCTAText}{" "}
                                        </SignInItem>
                                    </TouchableContainer>
                                )}
                                <Spacer size="lg" />
                                {(showAuthenticatedNavBar ||
                                    applicationCTAText) && (
                                    <RoundButton
                                        small
                                        title={
                                            showAuthenticatedNavBar
                                                ? "View Profile"
                                                : applicationCTAText
                                        }
                                        primary
                                        onPress={() => {
                                            showAuthenticatedNavBar
                                                ? handleProfile()
                                                : applicationCTAClick()

                                            setShowDropdown(false)
                                        }}
                                    />
                                )}
                            </Container>
                        </>
                    )}
                </MobileDropdown>
            </MobileNavContainerInner>
        </NavContainerOuter>
    )
}
