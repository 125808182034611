import { useTheme } from "@emotion/react"
import { useStudentApplicationDetails } from "@wetradeup/student-apps-shared"
import { Button, Card, Container, P, Spacer } from "@wetradeup/ui-kit"
import { BorrowingInput } from "../FinancingComponents"
import { useGetFinancing } from "../hooks/useGetFinancing"
import styled from "@emotion/styled"
import { useEffect, useState } from "react"
import { EditIcon } from "../icons/Edit"

interface LoanAmountInputProps {
    studentApplicationDetails: ReturnType<typeof useStudentApplicationDetails>
    handleFinancingAmountChange: (value: number) => void
    handleFinancingAmountSubmit: (financingAmount: number) => Promise<void>
    financingAmount?: number
    financingAmountUpdateError?: string
    isFinancingChanged?: boolean
}

const PWithBackground = styled(P)`
    ${(props) => `
        background: ${props.theme.colors.palette.questionnaireGrey};
        border-radius: 6px;
        padding: 5px 10px;
    `}
`

const ClickableEditIcon = styled.div`
    &:hover {
        cursor: pointer;
    }
`

export function LoanAmountInput({
    studentApplicationDetails,
    handleFinancingAmountChange,
    financingAmount,
    financingAmountUpdateError,
    isFinancingChanged,
    handleFinancingAmountSubmit,
}: LoanAmountInputProps) {
    const { maxNoteSize, minNoteSize, programTuition } = useGetFinancing(
        studentApplicationDetails
    )
    const formattedNoteSize = maxNoteSize ? maxNoteSize.toLocaleString() : "--"
    const formattedMinNoteSize = minNoteSize
        ? minNoteSize.toLocaleString()
        : "--"
    const formattedProgramTuition = programTuition
        ? programTuition.toLocaleString()
        : "--"
    const theme = useTheme()
    const [borrowingAmountConfirmed, setBorrowingAmountConfirmed] =
        useState(false)

    useEffect(() => {
        const borrowingRequest =
            studentApplicationDetails.data?.borrowing_request
        if (borrowingRequest && borrowingRequest > 0) {
            setBorrowingAmountConfirmed(true)
        }
    }, [studentApplicationDetails.data?.borrowing_request])

    return (
        <Card disableShadow border="medGrey">
            {borrowingAmountConfirmed ? (
                <Container flex row justifyContent="space-between">
                    <P
                        textColor="black"
                        bold
                        textSize={theme.sizings.fontSizeInc1}
                    >
                        Step 1 - Loan Amount
                    </P>
                    <Container row alignItems="center">
                        <P textSize="30px" bold textColor="black">
                            ${financingAmount.toLocaleString()}
                        </P>
                        <Spacer />
                        <ClickableEditIcon
                            onClick={() => setBorrowingAmountConfirmed(false)}
                        >
                            <EditIcon />
                        </ClickableEditIcon>
                    </Container>
                </Container>
            ) : (
                <>
                    <P
                        textColor="black"
                        bold
                        textSize={theme.sizings.fontSizeInc1}
                    >
                        Step 1 - Select your loan amount
                    </P>
                    <Spacer />
                    {isFinancingChanged && (
                        <P textSize={theme.sizings.fontSizeInc1}>
                            You and your school have agreed on a tuition amount,
                            so you can no longer change your loan amount.
                        </P>
                    )}
                    {!isFinancingChanged && !borrowingAmountConfirmed && (
                        <>
                            <PWithBackground
                                textSize={theme.sizings.fontSizeXs}
                                bold
                                textColor="black"
                            >
                                Your school's full tuition cost is: $
                                {formattedProgramTuition}{" "}
                            </PWithBackground>
                            <Spacer sm />
                            <P
                                textSize={theme.sizings.fontSizeXs}
                                textColor="black"
                            >
                                You can select any amount between: $
                                {formattedMinNoteSize} - ${formattedNoteSize}{" "}
                            </P>
                        </>
                    )}
                    <Spacer />
                    <BorrowingInput
                        handleFinancingAmountChange={
                            handleFinancingAmountChange
                        }
                        max={maxNoteSize}
                        value={financingAmount}
                        serverError={financingAmountUpdateError}
                        disabled={isFinancingChanged}
                    />
                    <Spacer />
                </>
            )}
            {!borrowingAmountConfirmed && (
                <Button
                    round
                    disabled={!financingAmount}
                    onPress={() => {
                        setBorrowingAmountConfirmed(true)
                        handleFinancingAmountSubmit(financingAmount)
                    }}
                    title="Confirm"
                ></Button>
            )}
        </Card>
    )
}
