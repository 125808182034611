export function RightArrow() {
    return (
        <svg
            width="16"
            height="18"
            viewBox="0 0 16 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.5945 9L0.594544 0.339745L0.594543 17.6603L15.5945 9Z"
                fill="#2E54E8"
            />
        </svg>
    )
}
