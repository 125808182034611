export function Percent() {
    return (
        <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.6639 5.37622L5.354 19.6861"
                stroke="#0070F9"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M6.88736 9.46473C8.29863 9.46473 9.4427 8.32067 9.4427 6.9094C9.4427 5.49813 8.29863 4.35406 6.88736 4.35406C5.47609 4.35406 4.33203 5.49813 4.33203 6.9094C4.33203 8.32067 5.47609 9.46473 6.88736 9.46473Z"
                stroke="#0070F9"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M18.1308 20.7082C19.542 20.7082 20.6861 19.5641 20.6861 18.1529C20.6861 16.7416 19.542 15.5975 18.1308 15.5975C16.7195 15.5975 15.5754 16.7416 15.5754 18.1529C15.5754 19.5641 16.7195 20.7082 18.1308 20.7082Z"
                stroke="#0070F9"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
