import { useTheme } from "@emotion/react"
import { useStudentApplicationDetails } from "@wetradeup/student-apps-shared"
import { Card, P, Spacer } from "@wetradeup/ui-kit"
import styled from "@emotion/styled"
import "../../../slider/assets/index.css"
import { isNumber } from "../../../../utils"
import { useEffect, useState } from "react"
import { PaymentOptions } from "./PaymentOptions"
import { PaymentBlurbs } from "./PaymentBlurbs"
import { CustomPaymentSlider } from "./CustomPaymentSlider"
import { RibbonIcon } from "../../../atoms/RibbonIcon"
import { PaymentMatchProgressBar } from "./PaymentMatchProgressBar"
import { maximumInSchoolPaymentMatch } from "../../../slider/hooks/usePaymentSliderOffset"

const SelectMoreTip = styled(P)`
    ${(props) => `
        text-align: center;
        border-radius: 6px;
        background-color: ${props.theme.colors.palette.questionnaireGrey};
        padding: 7px 0px 5px 0px;
        position: relative;
        top: -2px;
        z-index: 0;
    `}
`

const MaxPaymentTip = styled(P)`
    ${(props) => `
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        background-color: ${props.theme.colors.palette.questionnaireGrey};
        padding: 7px 0px 5px 0px;
        position: relative;
        top: -2px;
        z-index: 0;
    `}
`

interface InSchoolPaymentInputProps {
    studentApplicationDetails: ReturnType<typeof useStudentApplicationDetails>
    handleInSchoolPaymentChange: (value: number) => void
    inSchoolPaymentAmount?: number
    inSchoolPaymentAmountError?: string
}

export function InSchoolPaymentInput({
    studentApplicationDetails,
    handleInSchoolPaymentChange,
    inSchoolPaymentAmount,
    inSchoolPaymentAmountError,
}: InSchoolPaymentInputProps) {
    const note = studentApplicationDetails.data?.note
    const theme = useTheme()
    const [paymentOption, setPaymentOption] = useState("")
    const [blurbIndex, setBlurbIndex] = useState(0)

    const isMaxSuggestedPayment =
        inSchoolPaymentAmount >= maximumInSchoolPaymentMatch

    useEffect(() => {
        const interval = setInterval(() => {
            setBlurbIndex(blurbIndex === 0 ? 1 : 0)
        }, 10000)
        return () => clearInterval(interval)
    }, [blurbIndex])

    const inSchoolPaymentPeriod =
        note?.isl_terms.in_school_payment_period + note?.grace_period > 1
            ? `${note?.isl_terms.in_school_payment_period} months`
            : `${note?.isl_terms.in_school_payment_period} month`

    return (
        <Card disableShadow border="medGrey">
            <P textColor="black" bold textSize={theme.sizings.fontSizeInc1}>
                Step 2 - Optional in-school payment
            </P>
            <Spacer />
            {!isNumber(studentApplicationDetails.data?.borrowing_request) && (
                <P textSize={theme.sizings.fontSizeInc1}>
                    First, select a loan amount
                </P>
            )}
            {isNumber(studentApplicationDetails.data?.borrowing_request) && (
                <>
                    <P textSize={theme.sizings.fontSizeInc1}>
                        You can optionally choose to make monthly payments for
                        the combined {inSchoolPaymentPeriod} while you are in
                        school and during the grace period.
                    </P>
                    <Spacer lg />
                    <P
                        textColor="black"
                        bold
                        textSize={theme.sizings.fontSizeInc1}
                    >
                        In school you'll pay
                    </P>
                    <Spacer sm />
                    <PaymentOptions
                        selected={paymentOption}
                        options={[
                            { label: "$5/mo", value: "5" },
                            { label: "$10/mo", value: "10" },
                            { label: "$20/mo", value: "20" },
                            { label: "Custom", value: "custom" },
                        ]}
                        setSelected={(value) => {
                            const paymentValue =
                                value === "custom" ? 0 : parseInt(value)
                            setPaymentOption(value)
                            handleInSchoolPaymentChange(paymentValue)
                        }}
                    />
                    <Spacer />
                    {!paymentOption && (
                        <>
                            <PaymentBlurbs
                                index={blurbIndex}
                                loanAmount={note?.isl_terms.maximum_net_loan_amount_saved.toLocaleString()}
                                setIndex={setBlurbIndex}
                            />
                        </>
                    )}
                    {paymentOption === "custom" && (
                        <>
                            <CustomPaymentSlider
                                handleInSchoolPaymentChange={
                                    handleInSchoolPaymentChange
                                }
                                inSchoolPaymentAmount={inSchoolPaymentAmount}
                                inSchoolPaymentAmountError={
                                    inSchoolPaymentAmountError
                                }
                                note={note}
                            />
                            <Spacer />
                        </>
                    )}
                    {(inSchoolPaymentAmount > 0 ||
                        paymentOption === "custom") && (
                        <>
                            <P
                                textColor="black"
                                bold
                                textSize={theme.sizings.fontSizeInc1}
                            >
                                {inSchoolPaymentAmount >
                                maximumInSchoolPaymentMatch
                                    ? "And Fynn will pay"
                                    : "And Fynn will match it!"}
                            </P>
                            <Spacer sm />
                            <PaymentMatchProgressBar
                                inSchoolPaymentAmount={inSchoolPaymentAmount}
                                paymentOption={paymentOption}
                                isMaxSuggestedPayment={isMaxSuggestedPayment}
                            />

                            {isMaxSuggestedPayment ? (
                                <MaxPaymentTip
                                    bold
                                    textColor="blue"
                                    textSize="13px"
                                >
                                    <RibbonIcon />
                                    <Spacer />
                                    You're getting the most out of our match!
                                </MaxPaymentTip>
                            ) : (
                                <SelectMoreTip
                                    bold
                                    textColor="black"
                                    textSize="11px"
                                >
                                    Select a ${maximumInSchoolPaymentMatch}/mo
                                    or higher in-school payment to max out this
                                    benefit
                                </SelectMoreTip>
                            )}
                        </>
                    )}
                </>
            )}
        </Card>
    )
}
