import styled from "@emotion/styled"
import { BackgroundImage } from "@wetradeup/ui-kit"
import EmailVerificationImg from "../../../assets/application/Email_Verification.png"

export const PointerImg = styled(BackgroundImage)`
    height: 108px;
    width: 108px;
    border-radius: 50%;
    elevation: 1;
    box-shadow: 0px 14px 14px 0px rgba(0, 0, 0, 0.25);
`

export function Pointer() {
    return (
        <PointerImg
            source={EmailVerificationImg}
            alt="email_verification"
            contain
            center
            noRepeat
        />
    )
}
