import { useCreateQuestionnaireValidation } from "./hooks/useCreateQuestionnaireValidation"
import { useCreateReviewInitValues } from "./hooks/useCreateReviewInitValues"
import { useGetQuestionnaireSubmitCallback } from "./hooks/useGetQuestionnaireSubmitCallback"
import { useFormik } from "formik"
import { Container, P, Spacer } from "@wetradeup/ui-kit"
import { useEffect, useState, useMemo } from "react"
import { EditAnswerModal } from "./EditAnswerModal"
import { ClickableQuestion } from "./ClickableQuestion"
import { SpinningLoader } from "../atoms/SpinningLoader"
import { useEnterInitialValues } from "./hooks/useEnterInitialValues"
import { getEmpty } from "../../../utils"
import {
    Questionnaire,
    QuestionnaireAnswer,
    QuestionOption,
} from "@wetradeup/student-apps-shared"

interface ReviewProps {
    schoolId: number
    appFormId?: number
    employerId?: number
    questionnaire: Questionnaire[]
    setQuestionnaire: React.Dispatch<React.SetStateAction<Questionnaire[]>>
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
    handleSubmitRef: React.MutableRefObject<
        (e?: React.FormEvent<HTMLFormElement>) => void | undefined
    >
    answers: QuestionnaireAnswer
    setError: React.Dispatch<React.SetStateAction<string>>
    setDecision: React.Dispatch<React.SetStateAction<string>>
    setUnanswered: React.Dispatch<
        React.SetStateAction<{ [key: string]: string }>
    >
    setReady: React.Dispatch<React.SetStateAction<boolean>>
    ready: boolean
}

export interface SelectedQuestion {
    id: string
    style: string
    title: string
    subtitle: string
    options: QuestionOption[]
}

export function Review({
    schoolId,
    appFormId,
    employerId,
    questionnaire,
    setQuestionnaire,
    setLoading,
    handleSubmitRef,
    answers,
    setError,
    setDecision,
    setUnanswered,
    setReady,
    ready,
}: ReviewProps) {
    const [question, setQuestion] = useState<SelectedQuestion>(null)
    const valSchema = useCreateQuestionnaireValidation(questionnaire)
    const initVals = useCreateReviewInitValues(questionnaire)
    const { onSubmit } = useGetQuestionnaireSubmitCallback({
        questionnaire,
        schoolId,
        appFormId,
        questionnaireType: "confirm",
        setError,
        employerId,
        setDecision,
    })

    const [timeOfLastTouch, setTimeOfLastTouch] = useState(
        Math.round(Date.now() / 1000)
    )

    const onFormikSubmit = async (values) => {
        setLoading(true)
        try {
            await onSubmit(questionnaire, values)
        } finally {
            setLoading(false)
        }
    }

    const { setFieldValue, handleSubmit, values } =
        useFormik<QuestionnaireAnswer>({
            initialValues: initVals,
            validationSchema: valSchema,
            onSubmit: onFormikSubmit,
        })

    useEnterInitialValues(
        questionnaire,
        answers,
        setQuestionnaire,
        setFieldValue,
        setLoading,
        setReady
    )

    useEffect(() => {
        const unanswered = getEmpty(questionnaire, values)
        setUnanswered(unanswered)
    }, [questionnaire, values])

    useEffect(() => {
        handleSubmitRef.current = handleSubmit
    }, [onSubmit])

    const sortedQuestionnaire = useMemo(() => {
        return questionnaire.sort((a, b) => {
            if (a.style === "prioritize" && b.style !== "prioritize") {
                return 1
            } else if (b.style === "prioritize" && a.style !== "prioritize") {
                return -1
            }
            return 0
        })
    }, [questionnaire])

    return (
        <>
            <Container paddingMd>
                <P bold textColor="black" textSize="18px">
                    Questionnaire
                </P>
            </Container>
            {values &&
                ready &&
                sortedQuestionnaire.map(
                    ({ id, style, title, subtitle, options, hidden }, ind) => {
                        if (hidden) {
                            return <></>
                        } else if (
                            style === "radio" ||
                            style === "multiple_radio" ||
                            style === "prioritize"
                        ) {
                            return (
                                <ClickableQuestion
                                    isFirstQuestion={ind === 0}
                                    key={id}
                                    id={id}
                                    title={title}
                                    values={values}
                                    question={{
                                        id,
                                        style,
                                        title,
                                        subtitle,
                                        options,
                                    }}
                                    setQuestion={setQuestion}
                                />
                            )
                        } else {
                            return <div />
                        }
                    }
                )}
            {!ready && <SpinningLoader />}
            <EditAnswerModal
                question={question}
                setQuestion={setQuestion}
                questionnaire={questionnaire}
                setQuestionnaire={setQuestionnaire}
                setFieldValue={setFieldValue}
                values={values}
                timeOfLastTouch={timeOfLastTouch}
                setTimeOfLastTouch={setTimeOfLastTouch}
            />
            <Spacer />
        </>
    )
}
