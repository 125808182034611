import {
    Card,
    Container,
    H6,
    HR,
    P,
    Spacer,
    TouchableContainer,
} from "@wetradeup/ui-kit"
import styled from "@emotion/styled"
// import EditIcon from "../../../assets/application/Edit.png"
import { SelectedQuestion } from "./Review"
import {
    QuestionnaireAnswer,
    RadioQuestionnaireAnswer,
    PrioritizeQuestionnaireAnswer,
} from "@wetradeup/student-apps-shared"
import { breakpoints } from "../../../constants"
import { EditQuestion } from "../../atoms/EditQuestion"

const SolidHR = styled(HR)`
    border: 1px solid ${(props) => props.theme.colors.palette.medGrey};
`

export const QuestionContainer = styled(Container)`
    @media (max-width: ${breakpoints.mobileLg}) {
        padding-left: 10px;
        padding-right: 10px;
    }
    @media (max-width: 1050px) and (min-width: ${breakpoints.mobileLg}) {
        padding-left: 20px;
        padding-right: 20px;
    }
    @media (min-width: 1050px) {
        padding-left: 38px;
        padding-right: 38px;
    }
`

interface InnerContainerProps {
    id: string
    values: QuestionnaireAnswer
    title: string
    question: SelectedQuestion
    handlePress: () => void
}
const InnerContainer = ({
    id,
    values,
    title,
    question,
    handlePress,
}: InnerContainerProps) => {
    return (
        <Container row alignItems="center" justifyContent="space-between">
            <Container flex={1}>
                <H6 textAlign="left">Q: {title}</H6>
                <Spacer size={8} />
                {question.style === "radio" && (
                    <P textAlign="left">
                        A: {(values[id] as RadioQuestionnaireAnswer)?.label}
                    </P>
                )}
                {question.style === "multiple_radio" &&
                    ((values[id] || []) as RadioQuestionnaireAnswer[]).map(
                        (subVal) => {
                            const text = `• ${subVal.label}`
                            return (
                                <>
                                    <P textAlign="left">{text}</P>
                                    <Spacer size={4} />
                                </>
                            )
                        }
                    )}
                {question.style === "prioritize" && values[id] && (
                    <>
                        <P>Most true</P>
                        {(
                            values[id] as PrioritizeQuestionnaireAnswer
                        ).final_order.map((subVal) => {
                            return (
                                <>
                                    <Card
                                        flex={1}
                                        border="medGrey"
                                        disableShadow
                                        paddingSm
                                        width="90%"
                                    >
                                        <P>{subVal.label}</P>
                                    </Card>
                                    <Spacer size={4} />
                                </>
                            )
                        })}
                        <P>Least true</P>
                    </>
                )}
            </Container>
            <Spacer />
            <TouchableContainer onPress={handlePress}>
                <EditQuestion />
            </TouchableContainer>
        </Container>
    )
}

interface ClickableQuestionProps {
    id: string
    title: string
    values: QuestionnaireAnswer
    question: SelectedQuestion
    setQuestion: React.Dispatch<React.SetStateAction<SelectedQuestion>>
    isFirstQuestion: boolean
}

export const ClickableQuestion = ({
    id,
    title,
    values,
    question,
    setQuestion,
    isFirstQuestion,
}: ClickableQuestionProps) => {
    const handlePress = () => {
        setQuestion(question)
    }

    return (
        <>
            <QuestionContainer>
                {isFirstQuestion && <SolidHR />}
                <Container paddingSm>
                    <InnerContainer
                        id={id}
                        title={title}
                        question={question}
                        values={values}
                        handlePress={handlePress}
                    />
                </Container>
                <SolidHR />
            </QuestionContainer>
            <Spacer size={4} />
        </>
    )
}
