import { useTheme } from "@emotion/react"
import { useHistory } from "react-router-dom"
import { Card, Container, HR, P, Spacer } from "@wetradeup/ui-kit"
import { ApplicationButton } from "../../atoms/ApplicationButton"
import { ExitCounselingAboutIcon } from "../icons/ExitCounselingAboutIcon"
import { DefaultPage } from "../molecules/DefaultPage"
import { useContext } from "react"
import { AuthContext } from "@wetradeup/student-apps-shared"
import { useFynnLocation } from "../../hooks/useFynnLocation"

export function ExitCounselingAboutPage() {
    const theme = useTheme()
    const history = useHistory()
    const { authProfile } = useContext(AuthContext)
    const location = useFynnLocation()
    return (
        <DefaultPage>
            <P textSize={theme.sizings.fontSizeLg} textColor="black">
                What is Loan Orientation?
            </P>
            <Spacer lg />
            <Card
                backgroundColor="medGrey"
                disableShadow
                alignItems="center"
                width="100%"
            >
                <ExitCounselingAboutIcon />
            </Card>
            <Spacer lg />
            <P textColor="black" textSize={theme.sizings.fontSizeInc1}>
                Every Fynn borrower goes through loan counseling when they leave
                their program. The goal of loan counseling is to make sure you
                fully understand your obligations as you enter into loan
                repayment, and to make sure you’re aware of all the tools you
                can use to your advantage!
            </P>
            <Spacer lg />
            <Card
                disableShadow
                alignItems="center"
                width="100%"
                border="medGrey"
            >
                <Container width="100%">
                    <P
                        textAlign="left"
                        textSize={theme.sizings.fontSizeMd}
                        bold
                        textColor="black"
                    >
                        What we'll cover today
                    </P>
                </Container>
                <Spacer lg />
                <Container width="100%">
                    <P weight={600} textAlign="left" textColor="black">
                        Your Loan Overview
                    </P>
                    <Spacer />
                    <Container width="100%" padding={[null, "md"]}>
                        <P textAlign="left" textColor="black">
                            1. Your loan details
                        </P>
                        <P textAlign="left" textColor="black">
                            2. Key loan terms
                        </P>
                    </Container>
                </Container>
                <Spacer lg />
                <HR />
                <Spacer lg />
                <Container width="100%">
                    <P weight={600} textAlign="left" textColor="black">
                        Repayment
                    </P>
                    <Spacer />
                    <Container width="100%" padding={[null, "md"]}>
                        <P textAlign="left" textColor="black">
                            1. Your payment
                        </P>
                        <P textAlign="left" textColor="black">
                            2. Your loan timeline
                        </P>
                    </Container>
                </Container>
                <Spacer lg />
                <HR />
                <Spacer lg />
                <Container width="100%">
                    <P weight={600} textAlign="left" textColor="black">
                        Missed and Late Payments
                    </P>
                    <Spacer />
                    <Container width="100%" padding={[null, "md"]}>
                        <P textAlign="left" textColor="black">
                            1. What happens if you miss a payment?
                        </P>
                        <P textAlign="left" textColor="black">
                            2. Your member benefits
                        </P>
                    </Container>
                </Container>
                <Spacer lg />
                <HR />
                <Spacer lg />
                <Container width="100%">
                    <P weight={600} textAlign="left" textColor="black">
                        Test your knowledge
                    </P>
                    <Spacer />
                    <Container width="100%" padding={[null, "md"]}>
                        <P textAlign="left" textColor="black">
                            1. Short quiz
                        </P>
                    </Container>
                </Container>
            </Card>
            <Spacer lg />
            <Container width={300}>
                <ApplicationButton
                    fullWidth
                    alignCenter
                    title="Let's get started"
                    buttonColor="newBlue"
                    onPress={() => {
                        if (authProfile?.role === "fynn_admin") {
                            history.push("/loan-orientation/admin/students")
                        } else if (
                            location.pathname.includes(
                                "/loan-orientation/admin"
                            )
                        ) {
                            history.push("/loan-orientation/admin/login")
                        } else if (authProfile?.role === "exit_counseling") {
                            history.push("/loan-orientation/overview")
                        } else {
                            history.push("/loan-orientation/login")
                        }
                    }}
                />
            </Container>
        </DefaultPage>
    )
}
