export function SecondTier() {
    return (
        <svg
            width="40"
            height="29"
            viewBox="0 0 40 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="1.30058"
                y="1.12626"
                width="37.5762"
                height="26.2814"
                rx="7.49329"
                fill="white"
                stroke="#2EC63C"
                stroke-width="1.95564"
            />
            <path
                d="M12.7868 22.157V20.7438C11.5876 20.6092 10.6394 20.1809 9.9419 19.459C9.24444 18.7371 8.89571 17.8194 8.89571 16.7059H10.5109C10.5109 17.3544 10.7005 17.9356 11.0799 18.4495C11.4714 18.9512 12.0404 19.2755 12.7868 19.4223V14.4483C12.7011 14.4239 12.6094 14.3994 12.5115 14.3749C12.4258 14.3382 12.3402 14.3076 12.2545 14.2831C11.2634 13.9405 10.5292 13.5184 10.052 13.0167C9.57482 12.5028 9.33621 11.8359 9.33621 11.0161C9.32398 10.0617 9.62376 9.26636 10.2356 8.63009C10.8596 7.99381 11.71 7.62673 12.7868 7.52884V6.04216H14.0532V7.52884C15.081 7.6512 15.9009 8.03664 16.5127 8.68515C17.1245 9.33366 17.4365 10.1229 17.4487 11.0528H15.8336C15.8336 10.6001 15.6867 10.1657 15.3931 9.74969C15.0994 9.32142 14.6528 9.03388 14.0532 8.88704V13.3287C14.1511 13.3532 14.249 13.3838 14.3469 13.4205C14.4448 13.4572 14.5488 13.4878 14.6589 13.5123C15.2707 13.7325 15.8213 13.9895 16.3108 14.2831C16.8002 14.5768 17.1795 14.9561 17.4487 15.4211C17.7301 15.8861 17.8709 16.4795 17.8709 17.2014C17.8709 17.7765 17.7301 18.321 17.4487 18.835C17.1673 19.3489 16.739 19.7833 16.1639 20.1381C15.6011 20.4929 14.8975 20.701 14.0532 20.7621V22.157H12.7868ZM10.933 10.8693C10.933 11.4321 11.0982 11.8604 11.4286 12.1541C11.759 12.4355 12.2117 12.6802 12.7868 12.8882V8.85034C12.2362 8.93599 11.7895 9.15624 11.4469 9.51108C11.1043 9.85369 10.933 10.3064 10.933 10.8693ZM16.2741 17.2932C16.2741 16.5835 16.066 16.0574 15.65 15.7148C15.234 15.3722 14.7017 15.0907 14.0532 14.8705V19.459C14.7629 19.3733 15.3074 19.1286 15.6867 18.7248C16.0783 18.321 16.2741 17.8438 16.2741 17.2932Z"
                fill="#2EC63C"
            />
            <path
                d="M25.4938 22.157V20.7438C24.2947 20.6092 23.3464 20.1809 22.6489 19.459C21.9515 18.7371 21.6027 17.8194 21.6027 16.7059H23.2179C23.2179 17.3544 23.4076 17.9356 23.7869 18.4495C24.1784 18.9512 24.7474 19.2755 25.4938 19.4223V14.4483C25.4082 14.4239 25.3164 14.3994 25.2185 14.3749C25.1328 14.3382 25.0472 14.3076 24.9615 14.2831C23.9704 13.9405 23.2363 13.5184 22.7591 13.0167C22.2818 12.5028 22.0432 11.8359 22.0432 11.0161C22.031 10.0617 22.3308 9.26636 22.9426 8.63009C23.5666 7.99381 24.417 7.62673 25.4938 7.52884V6.04216H26.7602V7.52884C27.7881 7.6512 28.6079 8.03664 29.2197 8.68515C29.8315 9.33366 30.1435 10.1229 30.1557 11.0528H28.5406C28.5406 10.6001 28.3938 10.1657 28.1001 9.74969C27.8064 9.32142 27.3598 9.03388 26.7602 8.88704V13.3287C26.8581 13.3532 26.956 13.3838 27.0539 13.4205C27.1518 13.4572 27.2558 13.4878 27.3659 13.5123C27.9777 13.7325 28.5284 13.9895 29.0178 14.2831C29.5072 14.5768 29.8866 14.9561 30.1557 15.4211C30.4372 15.8861 30.5779 16.4795 30.5779 17.2014C30.5779 17.7765 30.4372 18.321 30.1557 18.835C29.8743 19.3489 29.4461 19.7833 28.871 20.1381C28.3081 20.4929 27.6045 20.701 26.7602 20.7621V22.157H25.4938ZM23.64 10.8693C23.64 11.4321 23.8052 11.8604 24.1356 12.1541C24.466 12.4355 24.9187 12.6802 25.4938 12.8882V8.85034C24.9432 8.93599 24.4966 9.15624 24.154 9.51108C23.8114 9.85369 23.64 10.3064 23.64 10.8693ZM28.9811 17.2932C28.9811 16.5835 28.7731 16.0574 28.357 15.7148C27.941 15.3722 27.4088 15.0907 26.7602 14.8705V19.459C27.4699 19.3733 28.0144 19.1286 28.3938 18.7248C28.7853 18.321 28.9811 17.8438 28.9811 17.2932Z"
                fill="#2EC63C"
            />
        </svg>
    )
}
