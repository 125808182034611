import { Container, Spacer } from "@wetradeup/ui-kit"
import { Rejected } from "../application/molecules/modals/DecisionModal"

export default function RejectedDecision() {
    return (
        <Container
            paddingMd
            width={[
                [550, "sm"],
                [550, "md"],
                [750, "lg"],
            ]}
            justifyContent="center"
            alignItems="center"
        >
            <Spacer />
            <Rejected />
            <Spacer lg />
        </Container>
    )
}
