export function LockIcon() {
    return (
        <svg
            width="21"
            height="23"
            viewBox="0 0 21 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.5 10.5H3.5C2.39543 10.5 1.5 11.3954 1.5 12.5V19.5C1.5 20.6046 2.39543 21.5 3.5 21.5H17.5C18.6046 21.5 19.5 20.6046 19.5 19.5V12.5C19.5 11.3954 18.6046 10.5 17.5 10.5Z"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M5.5 10.5V6.5C5.5 5.17392 6.02678 3.90215 6.96447 2.96447C7.90215 2.02678 9.17392 1.5 10.5 1.5C11.8261 1.5 13.0979 2.02678 14.0355 2.96447C14.9732 3.90215 15.5 5.17392 15.5 6.5V10.5"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
