export function RibbonIcon() {
    return (
        <svg
            width="12"
            height="18"
            viewBox="0 0 12 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z"
                fill="#FFBC66"
                stroke="#2E54E8"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M3.29251 10.2073L2.42822 16.7145L5.99965 14.5716L9.57108 16.7145L8.70679 10.2002"
                fill="#2E54E8"
            />
            <path
                d="M3.29251 10.2073L2.42822 16.7145L5.99965 14.5716L9.57108 16.7145L8.70679 10.2002"
                stroke="#2E54E8"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
