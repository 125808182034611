export function MoneyCircle() {
    return (
        <svg
            width="25"
            height="25"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="10.1056"
                cy="10.563"
                r="10"
                transform="rotate(90 10.1056 10.563)"
                fill="#00CA80"
            />
            <path
                d="M9.66413 16.1748V15.1908C8.83772 15.0971 8.16466 14.8074 7.64496 14.3218C7.12526 13.8362 6.85689 13.1844 6.83985 12.3665H8.56509C8.58213 12.7073 8.68436 13.0055 8.8718 13.2611C9.06775 13.5167 9.33186 13.6913 9.66413 13.785V11.0886C9.58745 11.063 9.50651 11.0374 9.42132 11.0119C9.34464 10.9863 9.2637 10.9608 9.17851 10.9352C8.48841 10.6966 7.96019 10.3899 7.59384 10.0151C7.23602 9.6402 7.0571 9.1418 7.0571 8.51986C7.04858 7.78717 7.28713 7.19931 7.77276 6.75629C8.25838 6.30474 8.88884 6.04489 9.66413 5.97673V4.94159H10.5204V5.98951C11.2871 6.06619 11.9048 6.33456 12.3734 6.79463C12.8505 7.24617 13.1018 7.82977 13.1274 8.54542H11.3766C11.3681 8.29835 11.2871 8.07258 11.1338 7.86811C10.9889 7.65511 10.7845 7.50602 10.5204 7.42082V9.79782C10.5885 9.82338 10.6567 9.84894 10.7248 9.87449C10.793 9.89153 10.8611 9.91283 10.9293 9.93839C11.3723 10.0917 11.777 10.2707 12.1434 10.4751C12.5097 10.6796 12.8036 10.9522 13.0251 11.293C13.2467 11.6253 13.3574 12.0555 13.3574 12.5838C13.3574 13.0268 13.2467 13.44 13.0251 13.8234C12.8122 14.2068 12.4927 14.5262 12.0667 14.7818C11.6492 15.0289 11.1338 15.1695 10.5204 15.2036V16.1748H9.66413ZM8.76956 8.40485C8.76956 8.67748 8.8505 8.89899 9.01237 9.06938C9.17425 9.23126 9.3915 9.37183 9.66413 9.49111V7.3697C9.40002 7.42082 9.18277 7.53584 9.01237 7.71475C8.8505 7.89367 8.76956 8.1237 8.76956 8.40485ZM11.6194 12.6988C11.6194 12.358 11.5172 12.0896 11.3127 11.8937C11.1082 11.6977 10.8441 11.5316 10.5204 11.3953V13.8234C10.8611 13.7723 11.1295 13.6487 11.3255 13.4528C11.5214 13.2568 11.6194 13.0055 11.6194 12.6988Z"
                fill="white"
            />
        </svg>
    )
}
