import { Container, Spacer, H2, P } from "@wetradeup/ui-kit"
import { ContentContainer, ApplicationCard } from "../../ModalComponents"

export default function PendingAutomatic() {
    return (
        <Container
            width={[
                [550, "sm"],
                [550, "md"],
                [750, "lg"],
            ]}
        >
            <ApplicationCard
                alignItems="center"
                justifyContent="center"
                disableCard
                border
                paddingLg
                width="100%"
            >
                <Spacer />
                <H2 bold="light">
                    Fynn will automatically verify your identity in the next 1 -
                    2 business days
                </H2>
                <Spacer lg />
                <ContentContainer justifyContent="center" alignItems="center">
                    <P textAlign="center">
                        This usually takes around 24 hours. In the meantime,
                        just sit back, relax, and we'll let you know when this
                        step is complete! You can close this window.
                    </P>
                </ContentContainer>
                <Spacer lg />
            </ApplicationCard>
        </Container>
    )
}
