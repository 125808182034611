import {
    Questionnaire,
    QuestionnaireAnswer,
} from "@wetradeup/student-apps-shared"
import { useCreateQuestionnaireValidation } from "./hooks/useCreateQuestionnaireValidation"
import { useCreateQuestionnaireInitValues } from "./hooks/useCreateQuestionnaireInitValues"
import { useGetQuestionnaireSubmitCallback } from "./hooks/useGetQuestionnaireSubmitCallback"
import { useFormik } from "formik"
import { useEffect, useState } from "react"
import { RadioQuestion } from "./RadioQuestion"
import { MultipleRadioQuestion } from "./MultipleRadioQuestion"
import { PrioritizeQuestion } from "./PrioritizeQuestion"
import { getEmpty } from "../../../utils"

interface QuestionnaireProps {
    schoolId: number
    appFormId?: number
    employerId?: number
    questionnaire: Questionnaire[]
    setQuestionnaire: React.Dispatch<React.SetStateAction<Questionnaire[]>>
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
    handleSubmitRef: React.MutableRefObject<
        (e?: React.FormEvent<HTMLFormElement>) => void | undefined
    >
    page: "one" | "two"
    setUnanswered: React.Dispatch<
        React.SetStateAction<{ [key: string]: string }>
    >
}

export function QuestionnaireWrapper({
    schoolId,
    appFormId,
    employerId,
    questionnaire,
    setQuestionnaire,
    setLoading,
    handleSubmitRef,
    page,
    setUnanswered,
}: QuestionnaireProps) {
    const valSchema = useCreateQuestionnaireValidation(questionnaire)
    const initVals = useCreateQuestionnaireInitValues(questionnaire)
    const { onSubmit, loading } = useGetQuestionnaireSubmitCallback({
        questionnaire,
        schoolId,
        appFormId,
        employerId,
        questionnaireType: "questionnaire",
    })

    const [timeOfLastTouch, setTimeOfLastTouch] = useState(
        Math.round(Date.now() / 1000)
    )

    const onFormikSubmit = (values) => {
        onSubmit(questionnaire, values)
    }

    const { setFieldValue, handleSubmit, values } =
        useFormik<QuestionnaireAnswer>({
            initialValues: initVals,
            validationSchema: valSchema,
            onSubmit: onFormikSubmit,
        })

    useEffect(() => {
        const unanswered = getEmpty(questionnaire, values)
        setUnanswered(unanswered)
    }, [questionnaire, values])

    useEffect(() => {
        setLoading(loading)
    }, [loading])

    useEffect(() => {
        handleSubmitRef.current = handleSubmit
    }, [onSubmit])

    return (
        <>
            {page === "one"
                ? questionnaire.map(
                      ({ id, style, title, subtitle, options, hidden }) => {
                          if (hidden) {
                              return <></>
                          } else if (style === "radio") {
                              return (
                                  <RadioQuestion
                                      id={id}
                                      title={title}
                                      subtitle={subtitle}
                                      options={options}
                                      questionnaire={questionnaire}
                                      setQuestionnaire={setQuestionnaire}
                                      setFieldValue={setFieldValue}
                                      values={values}
                                      setTimeOfLastTouch={setTimeOfLastTouch}
                                  />
                              )
                          } else if (style === "multiple_radio") {
                              return (
                                  <MultipleRadioQuestion
                                      title={title}
                                      subtitle={subtitle}
                                      options={options}
                                      id={id}
                                      questionnaire={questionnaire}
                                      setQuestionnaire={setQuestionnaire}
                                      setFieldValue={setFieldValue}
                                      values={values}
                                      timeOfLastTouch={timeOfLastTouch}
                                      setTimeOfLastTouch={setTimeOfLastTouch}
                                  />
                              )
                          } else {
                              return <div />
                          }
                      }
                  )
                : questionnaire.map(({ id, style, title, options, hidden }) => {
                      if (hidden) {
                          return <></>
                      } else if (style === "prioritize") {
                          return (
                              <PrioritizeQuestion
                                  id={id}
                                  title={title}
                                  options={options}
                                  values={values}
                                  setFieldValue={setFieldValue}
                                  timeOfLastTouch={timeOfLastTouch}
                                  setTimeOfLastTouch={setTimeOfLastTouch}
                                  spacerSize={48}
                              />
                          )
                      } else {
                          return <div />
                      }
                  })}
        </>
    )
}
