import { useTheme } from "@emotion/react"
import { Card, Container, P, Modal, Spacer } from "@wetradeup/ui-kit"
import { useHistory } from "react-router-dom"
import { SubmissionButton } from "../../application/atoms/SubmissionButton"
import { ContentContainer } from "../../ModalComponents"

interface BankRejectedModalProps {
    showRejectedModal: boolean
}

export function BankRejectedModal({
    showRejectedModal,
}: BankRejectedModalProps) {
    const history = useHistory()
    const theme = useTheme()
    return (
        <Modal visible={showRejectedModal} width="100%">
            <Container
                width={[
                    [700, "md"],
                    [860, "lg"],
                ]}
            >
                <Card paddingMd>
                    <ContentContainer
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Spacer size={48} />
                        <P
                            bold="light"
                            textAlign="center"
                            textColor="black"
                            textSize={theme.sizings.fontSizeMdLg}
                            lineHeight="120%"
                        >
                            Please check your email for important information
                            about your loan application.
                        </P>
                        <Spacer size={32} />
                        <SubmissionButton
                            onPress={() =>
                                history.push("/application/intro/select")
                            }
                            primary
                            title="Exit application"
                        />
                        <Spacer size={48} />
                    </ContentContainer>
                </Card>
            </Container>
        </Modal>
    )
}
