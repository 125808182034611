export const breakpoints = {
    mobile: "390px",
    mobileLg: "625px",
    tablet: "750px",
    desktopSm: "1050px",
    desktopMd: "1250px",
}

export const numWords = [
    "zero",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
    "eleven",
    "twelve",
]

export const questionMap = {
    first: "first name",
    last: "last name",
    program: "program",
    campus: "campus",
    anticipated_start_date: "start date",
    phone: "phone number",
    email: "email",
    passwordOne: "password",
    passwordTwo: "confirm password",
    street: "street",
    street_line_two: "address line two",
    city: "city",
    state: "state",
    zip: "zipcode",
    citizen: "citizenship status",
    dob: "date of birth",
    education: "education level",
    ssn: "social security",
    confirmSSN: "confirm social security",
}

export const englishDateFormat = "MMM dd yyyy"
export const englishMonthAndYearFormat = "MMM yyyy"
export const defaultDateFormat = "yyyy-MM-dd"
