import React from "react"
import { Select } from "@wetradeup/ui-kit"
import { Program, PublicSchool } from "@wetradeup/student-apps-shared"
import { WrappedFormik } from "../../../../utils"
import { CreateAccountForm } from "../IntroForm"
import { UpdateApplicationBasicInfoForm } from "../../BasicInfoPage"

interface ProgramSelectProps {
    school: PublicSchool
    program?: Program
    setFieldValue:
        | ReturnType<
              WrappedFormik<CreateAccountForm>["formik"]
          >["setFieldValue"]
        | ReturnType<
              WrappedFormik<UpdateApplicationBasicInfoForm>["formik"]
          >["setFieldValue"]
}

export function ProgramSelect({
    school,
    program,
    setFieldValue,
}: ProgramSelectProps) {
    const programOptions = school
        ? school.programs.map((s, idx) => {
              return {
                  label: s.program_name,
                  value: s.program_name,
              }
          })
        : []

    return (
        <Select
            name="program"
            placeholder="Select Your Program"
            options={programOptions}
            value={program?.program_name}
            onOptionSelected={(fieldName, { value }) => {
                setFieldValue(fieldName, value)
            }}
            onClear={() => {
                setFieldValue("program", "")
            }}
        />
    )
}
