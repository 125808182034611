export function SoloAppIcon() {
    return (
        <svg
            width="114"
            height="126"
            viewBox="0 0 114 126"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M57.5012 66C74.3196 66 88 47.9228 88 30.8907C88 13.8597 74.3201 0 57.5012 0C40.6824 0 27 13.8594 27 30.8907C27.0029 47.9208 40.6829 66 57.5012 66Z"
                fill="#2E54E8"
            />
            <path
                d="M79.9105 66C73.9905 70.4959 66.6058 73.1708 58.6066 73.1708H55.3932C47.3919 73.1708 40.0055 70.4949 34.0873 66C14.7593 69.1147 0 85.8154 0 105.959C0 117.026 25.5193 126 57 126C88.4807 126 114 117.026 114 105.959C114 85.8142 99.2387 69.113 79.9102 66H79.9105Z"
                fill="#2E54E8"
            />
        </svg>
    )
}
