import { useTheme } from "@emotion/react"
import { Spacer } from "@wetradeup/ui-kit"
import styled from "@emotion/styled"
import { InSchoolPaymentAmountInput } from "../../FinancingComponents"
import { NoteDetails } from "@wetradeup/student-apps-shared"
import Slider from "../../../slider"
import { minimumInSchoolPaymentAmount } from "../../../slider/hooks/usePaymentSliderOffset"

interface CustomPaymentSliderProps {
    handleInSchoolPaymentChange: (value: number) => void
    inSchoolPaymentAmount?: number
    inSchoolPaymentAmountError?: string
    note: NoteDetails
}

const handleBorderColor = "#92A7FF"

const StyledSlider = styled(Slider)<{ showsMarkerLabel?: boolean }>`
    ${(props) => `
        font-family: ${props.theme.fonts.typefaces.primary};
        padding: 0px;
        padding-bottom: ${props.showsMarkerLabel ? "32px;" : "16px;"}
        width: calc(100% - 12px);
        margin-left: auto;
        margin-right: auto;
        margin-top: 12px;
    `}
`

export function CustomPaymentSlider({
    handleInSchoolPaymentChange,
    inSchoolPaymentAmount,
    inSchoolPaymentAmountError,
    note,
}: CustomPaymentSliderProps) {
    const theme = useTheme()
    const showsMarkerLabel =
        inSchoolPaymentAmount === 0 ||
        inSchoolPaymentAmount === minimumInSchoolPaymentAmount ||
        inSchoolPaymentAmount ===
            note?.isl_terms?.in_school_interest_only_payment ||
        inSchoolPaymentAmount === note?.isl_terms?.maximum_payment

    const activeLabelColor = theme.colors.palette.blue
    const defaultLabelColor = theme.colors.palette.black

    const handleChange = (value: number) => {
        if (value > note?.isl_terms?.maximum_payment) {
            handleInSchoolPaymentChange(note?.isl_terms?.maximum_payment)
        } else {
            handleInSchoolPaymentChange(value)
        }
    }

    return (
        <>
            <InSchoolPaymentAmountInput
                handleInSchoolPaymentChange={handleInSchoolPaymentChange}
                max={note?.isl_terms?.maximum_payment}
                value={inSchoolPaymentAmount}
                serverError={inSchoolPaymentAmountError}
            />
            <Spacer sm />
            <StyledSlider
                value={inSchoolPaymentAmount || 0}
                showsMarkerLabel={showsMarkerLabel}
                marks={{
                    0: {
                        label: "No Payment",
                        style: {
                            transform: "translateX(-9%)",
                            color:
                                inSchoolPaymentAmount === 0 ||
                                inSchoolPaymentAmount === null
                                    ? activeLabelColor
                                    : defaultLabelColor,
                            display:
                                inSchoolPaymentAmount === 0 ||
                                inSchoolPaymentAmount === null
                                    ? "inline-block"
                                    : "none",
                            fontSize: "14px",
                        },
                    },
                    5: {
                        label: "Minimum Payment",
                        style: {
                            ...(note?.isl_terms?.maximum_payment > 150
                                ? {
                                      transform: "translateX(-16%)",
                                  }
                                : {}),
                            color:
                                inSchoolPaymentAmount ===
                                minimumInSchoolPaymentAmount
                                    ? activeLabelColor
                                    : defaultLabelColor,
                            display:
                                inSchoolPaymentAmount ===
                                minimumInSchoolPaymentAmount
                                    ? "inline-block"
                                    : "none",
                            fontSize: "14px",
                        },
                    },
                    [note?.isl_terms.in_school_interest_only_payment]: {
                        style: {
                            width: "100%",
                            color:
                                inSchoolPaymentAmount ===
                                note?.isl_terms.in_school_interest_only_payment
                                    ? activeLabelColor
                                    : defaultLabelColor,
                            display:
                                inSchoolPaymentAmount ===
                                note?.isl_terms.in_school_interest_only_payment
                                    ? "inline-block"
                                    : "none",
                            fontSize: "14px",
                        },
                        label: "Interest Only Payment",
                    },
                    [note?.isl_terms.maximum_payment]: {
                        style: {
                            width: "100%",
                            transform: "translateX(-59%)",
                            color:
                                inSchoolPaymentAmount ===
                                note?.isl_terms.maximum_payment
                                    ? activeLabelColor
                                    : defaultLabelColor,
                            display:
                                inSchoolPaymentAmount ===
                                note?.isl_terms.maximum_payment
                                    ? "inline-block"
                                    : "none",
                            fontSize: "14px",
                        },
                        label: "Full Payment",
                    },
                }}
                min={0}
                max={note?.isl_terms.maximum_payment}
                step={1}
                onChange={handleChange}
                trackStyle={{
                    backgroundColor: theme.colors.palette.blue,
                    height: "10px",
                }}
                railStyle={{
                    height: "10px",
                }}
                dotStyle={{
                    height: "14px",
                    width: "14px",
                    bottom: "-8px",
                    borderColor: theme.colors.palette.black,
                }}
                activeDotStyle={{
                    background: handleBorderColor,
                    borderColor: handleBorderColor,
                    height: "14px",
                    width: "14px",
                }}
                handleStyle={{
                    marginTop: "-5px",
                    background: theme.colors.palette.blue,
                    borderColor: handleBorderColor,
                    opacity: 1,
                    height: "20px",
                    width: "20px",
                }}
            />
        </>
    )
}
