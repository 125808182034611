interface ProcessedSchool {
    value: number
    label: string
    trade: string
    is_live: boolean
}

export function addFakeSchools(originals: ProcessedSchool[]) {
    const schools = [
        "A T Still University of Health Sciences",
        "Aaniiih Nakoda College",
        "ABC Adult School",
        "ABC Adult School - Cabrillo Lane",
        "ABC Beauty Academy",
        "ABCO Technology",
        "Abilene Christian University",
        "Abraham Baldwin Agricultural College",
        "Abraham Lincoln University",
        "Academia Serrant Inc",
        "Academy College",
        "Academy Di Capelli-School of Cosmetology",
        "Academy di Firenze",
        "Academy for Careers and Technology",
        "Academy for Five Element Acupuncture",
        "Academy for Jewish Religion-California",
        "Academy for Nursing and Health Occupations",
        "Academy for Salon Professionals",
        "Academy of Art University",
        "Academy of Career Training",
        "Academy of Careers and Technology",
        "Academy of Chinese Culture and Health Sciences",
        "Academy of Cosmetology",
        "Academy of Cosmetology and Esthetics",
        "Academy of Esthetics and Cosmetology",
        "Academy of Hair Design - Austin",
        "Academy of Hair Design-Beaumont",
        "Academy of Hair Design-Grenada",
        "Academy of Hair Design-Jackson",
        "Academy of Hair Design-Jasper",
        "Academy of Hair Design-Las Vegas",
        "Academy of Hair Design-Lufkin",
        "Academy of Hair Design-Oklahoma City",
        "Academy of Hair Design-Pearl",
        "Academy of Hair Design-Springfield",
        "Academy of Hair Technology",
        "Academy of Interactive Entertainment",
        "Academy of Massage and Bodywork",
        "Academy of Natural Therapy Inc",
        "Academy of Professional Cosmetology",
        "Academy of Salon and Spa",
        "Academy of Salon Professionals",
        "Academy of Vocal Arts",
        "Acaydia School of Aesthetics",
        "Access Careers",
        "Ace Cosmetology and Barber Training Center",
        "Acupuncture and Integrative Medicine College-Berkeley",
        "Acupuncture and Massage College",
        "Adams State University",
        "Adelphi University",
        "Adirondack Community College",
        "Adler Graduate School",
        "Adler University",
        "Adrian College",
        "Adrian's College of Beauty Turlock",
        "Adult and Community Education-Hudson",
        "Adult and Continuing Education-BCTS",
        "Advance Beauty College",
        "Advance Beauty Techs Academy",
        "Advance Science International College",
        "Advanced Barber College and Hair Design",
        "Advanced Beauty College",
        "Advanced College",
        "Advanced College of Cosmetology",
        "Advanced College-Stockton",
        "Advanced Training Associates",
        "Advantage Technical College",
        "AdventHealth University",
        "Agnes Scott College",
        "AI Miami International University of Art and Design",
        "Aiken School of Cosmetology and Barbering",
        "Aiken Technical College",
        "Aims Community College",
        "Alabama A & M University",
        "Alabama College of Osteopathic Medicine",
        "Alabama School of Nail Technology & Cosmetology",
        "Alabama State College of Barber Styling",
        "Alabama State University",
        "Alamance Community College",
        "Alamo City Barber College",
        "Alaska Bible College",
        "Alaska Career College",
        "Alaska Christian College",
        "Alaska Pacific University",
        "Alaska Vocational Technical Center",
        "Albany BOCES-Adult Practical Nursing Program",
        "Albany College of Pharmacy and Health Sciences",
        "Albany Law School",
        "Albany Medical College",
        "Albany State University",
        "Albany Technical College",
        "Albertus Magnus College",
        "Albion College",
        "Albright College",
        "Alcorn State University",
        "Alderson Broaddus University",
        "Alexander Academy",
        "Alexandria School of Scientific Therapeutics",
        "Alexandria Technical & Community College",
        "Alfred University",
        "Alhambra Beauty College",
        "Alhambra Medical University",
        "Alice Lloyd College",
        "All Beauty College",
        "Allan Hancock College",
        "Allegany College of Maryland",
        "Allegheny College",
        "Allegheny Wesleyan College",
        "Allen College",
        "Allen County Community College",
        "Allen School-Brooklyn",
        "Allen School-Jamaica",
        "Allen School-Phoenix",
        "Allen University",
        "Alliance Career Center",
        "Alliance Computing Solutions",
        "Alliant International University-Fresno",
        "All-State Career School",
        "All-State Career-Baltimore",
        "Allstate Hairstyling & Barber College",
        "Alma College",
        "Alpena Community College",
        "Altierus Career College-Bissonnet",
        "Altierus Career College-Norcross",
        "Altierus Career College-Tampa",
        "Altierus Career College-Thornton",
        "Altoona Beauty School Inc",
        "Alvernia University",
        "Alverno College",
        "Alvin Community College",
        "Amarillo College",
        "Amberton University",
        "Ambiance Beauty & Barber Academy Inc",
        "Ambria College of Nursing",
        "America Evangelical University",
        "American Academy of Acupuncture and Oriental Medicine",
        "American Academy of Art",
        "American Academy of Cosmetology",
        "American Academy of Dramatic Arts",
        "American Academy of Health and Beauty",
        "American Baptist College",
        "American Baptist Seminary of the West",
        "American Barber Academy",
        "American Beauty Academy",
        "American Beauty College",
        "American Beauty School",
        "American Beauty Schools",
        "American Business and Technology University",
        "American Career College",
        "American College for Medical Careers",
        "American College of Acupuncture and Oriental Med",
        "American College of Barbering",
        "American College of Education",
        "American College of Financial Services",
        "American College of Hair Design Inc",
        "American College of Hairstyling-Des Moines",
        "American College of Healthcare and Technology",
        "American College of Healthcare Sciences",
        "American Conservatory Theater",
        "American Hair Academy",
        "American Indian OIC Inc",
        "American InterContinental University",
        "American International College",
        "American Islamic College",
        "American Jewish University",
        "American Medical Academy",
        "American Medical Sciences Center",
        "American Musical and Dramatic Academy",
        "American National University",
        "American Public University System",
        "American River College",
        "American Samoa Community College",
        "American School of Nursing and Medical Careers",
        "American Sentinel University",
        "American Trade School",
        "American University",
        "American University of Health Sciences",
        "American University of Puerto Rico",
        "AmeriTech College-Draper",
        "AmeriTech College-Provo",
        "AMG School of Licensed Practical Nursing",
        "Amherst College",
        "Amridge University",
        "Anabaptist Mennonite Biblical Seminary",
        "Ancilla College",
        "Anderson University",
        "Andover Newton Theological School",
        "Andrew College",
        "Andrews University",
        "Angeles College",
        "Angelina College",
        "Angelo State University",
        "Anna Maria College",
        "Anne Arundel Community College",
        "Annenberg School of Nursing",
        "Anoka Technical College",
        "Anoka-Ramsey Community College",
        "Anoka-Ramsey Community College-Cambridge Campus",
        "Another Level Barbering and Cosmetology School",
        "Anousheh School of Hair",
        "Anson College of Cosmetology",
        "Antelope Valley College",
        "Antilles School of Technical Careers",
        "Antioch College",
        "Antioch University-Los Angeles",
        "Antioch University-Midwest",
        "Antioch University-New England",
        "Antioch University-PhD Program in Leadership and Change",
        "Antioch University-Santa Barbara",
        "Antioch University-Seattle",
        "Antonelli College-Cincinnati",
        "Antonelli College-Hattiesburg",
        "Antonelli College-Jackson",
        "AOMA Graduate School of Integrative Medicine",
        "Aparicio-Levy Technical College",
        "Apex Academy Hair Skin Nails School of Cosmetology",
        "Apex School of Theology",
        "Apex Technical School",
        "Apollo Career Center",
        "Appalachian Beauty School",
        "Appalachian Bible College",
        "Appalachian College of Pharmacy",
        "Appalachian School of Law",
        "Appalachian State University",
        "Applied Technology Services",
        "Aquinas College",
        "Arapahoe Community College",
        "Arcadia University",
        "Arclabs",
        "Aria Health School of Nursing",
        "Arizona Academy of Beauty-East",
        "Arizona Christian University",
        "Arizona College-Glendale",
        "Arizona College-Las Vegas",
        "Arizona College-Mesa",
        "Arizona School of Acupuncture and Oriental Medicine",
        "Arizona School of Integrative Studies",
        "Arizona State University - ASU Colleges at Lake Havasu City",
        "Arizona State University - Cochise",
        "Arizona State University - Pinal",
        "Arizona State University at The Gila Valley",
        "Arizona State University at Tucson",
        "Arizona State University at Yuma",
        "Arizona State University-Downtown Phoenix",
        "Arizona State University-Polytechnic",
        "Arizona State University-Skysong",
        "Arizona State University-Tempe",
        "Arizona State University-West",
        "Arizona Western College",
        "Arkansas Baptist College",
        "Arkansas Beauty College",
        "Arkansas Beauty School-Little Rock",
        "Arkansas College of Barbering and Hair Design",
        "Arkansas Colleges of Health Education",
        "Arkansas Northeastern College",
        "Arkansas State University Mid-South",
        "Arkansas State University Three Rivers",
        "Arkansas State University-Beebe",
        "Arkansas State University-Main Campus",
        "Arkansas State University-Mountain Home",
        "Arkansas State University-Newport",
        "Arkansas Tech University",
        "Arlington Baptist University",
        "Arnolds Beauty School",
        "Arnot Ogden Medical Center",
        "Arrojo Cosmetology School",
        "Art Academy of Cincinnati",
        "Art Center College of Design",
        "Arthur's Beauty College Inc-Fort Smith",
        "Arthur's Beauty College Inc-Jacksonville",
        "Arthur's Beauty College-Jonesboro",
        "Arthur's Beauty School Inc-Conway",
        "Artistic Academy of Hair Design",
        "Artistic Nails and Beauty Academy-Lakeland",
        "Artistic Nails and Beauty Academy-Tampa",
        "ASA College",
        "Asbury Theological Seminary",
        "Asbury University",
        "Asher College",
        "Asheville-Buncombe Technical Community College",
        "Ashford University",
        "Ashland Community and Technical College",
        "Ashland County-West Holmes Career Center",
        "Ashland University",
        "Ashtabula County Technical and Career Campus",
        "Asian-American International Beauty College",
        "ASM Beauty World Academy",
        "Asnuntuck Community College",
        "Aspen Beauty Academy of Laurel",
        "Aspen University",
        "Assabet Valley Regional Technical School",
        "Associated Barber College of San Diego",
        "Associated Beth Rivkah Schools",
        "Associated Technical College-Los Angeles",
        "Associated Technical College-San Diego",
        "Assumption College",
        "Assumption College for Sisters",
        "Astrodome Career Centers",
        "ATA Career Education",
        "ATA College",
        "ATA College - Cincinnati",
        "Atenas College",
        "ATEP at IVC",
        "Athena Career Academy",
        "Athenaeum of Ohio",
        "Athens State University",
        "Athens Technical College",
        "ATI College-Norwalk",
        "Atlanta Metropolitan State College",
        "Atlanta School of Massage",
        "Atlanta Technical College",
        "Atlanta's John Marshall Law School",
        "Atlantic Beauty & Spa Academy LLC",
        "Atlantic Cape Community College",
        "Atlantic Technical College",
        "Atlantic University College",
        "Atlantis University",
        "Auburn Career Center",
        "Auburn University",
        "Auburn University at Montgomery",
        "Augsburg University",
        "Augusta School of Massage",
        "Augusta Technical College",
        "Augusta University",
        "Augustana College",
        "Augustana University",
        "Auguste Escoffier School of Culinary Arts-Austin",
        "Auguste Escoffier School of Culinary Arts-Boulder",
        "Aultman College of Nursing and Health Sciences",
        "Aurora University",
        "Austin College",
        "Austin Community College District",
        "Austin Graduate School of Theology",
        "Austin Kade Academy",
        "Austin Peay State University",
        "Austin Presbyterian Theological Seminary",
        "Austin's Beauty College Inc",
        "Automeca Technical College-Aguadilla",
        "Automeca Technical College-Bayamon",
        "Automeca Technical College-Caguas",
        "Automeca Technical College-Ponce",
        "Automotive Training Center-Exton",
        "Automotive Training Center-Warminster",
        "Autry Technology Center",
        "Avalon School of Cosmetology",
        "Avalon School of Cosmetology-Alameda",
        "Avalon School of Cosmetology-Aurora",
        "Avalon School of Cosmetology-Layton",
        "Avalon School of Cosmetology-Mesa",
        "Avalon School of Cosmetology-Phoenix",
        "Ave Maria School of Law",
        "Ave Maria University",
        "Avera McKennan Hospital School of Radiologic Technology",
        "Avera Sacred Heart Hospital",
        "Averett University",
        "Averett University-Non-Traditional Programs",
        "Avery James School of Cosmetology",
        "Avi Career Training",
        "Aviator College of Aeronautical Science and Technology",
        "Avila University",
        "Award Beauty School",
        "Ayers Career College",
        "Azusa Pacific University",
        "B M Spurr School of Practical Nursing",
        "Babson College",
        "Bacone College",
        "Bais Binyomin Academy",
        "Bais HaMedrash and Mesivta of Baltimore",
        "Bais Medrash Elyon",
        "Bais Medrash Mayan Hatorah",
        "Bais Medrash Toras Chesed",
        "Baker College",
        "Baker College Center for Graduate Studies",
        "Baker College of Allen Park",
        "Baker College of Auburn Hills",
        "Baker College of Cadillac",
        "Baker College of Clinton Township",
        "Baker College of Jackson",
        "Baker College of Muskegon",
        "Baker College of Owosso",
        "Baker College of Port Huron",
        "Baker University",
        "Baker University School of Nursing",
        "Baker University School of Professional and Graduate Studies and Graduate School of Education",
        "Bakersfield College",
        "Bakke Graduate University",
        "Baldwin Beauty School-North Austin",
        "Baldwin Beauty School-South Austin",
        "Baldwin Park Adult & Community Education",
        "Baldwin Wallace University",
        "Baldy View Regional Occupational Program",
        "Ball State University",
        "Baltimore City Community College",
        "Baltimore Studio of Hair Design",
        "Bancroft School of Massage Therapy",
        "Bank Street College of Education",
        "Baptist Bible College",
        "Baptist Health College Little Rock",
        "Baptist Health System School of Health Professions",
        "Baptist Memorial College of Health Sciences",
        "Baptist Missionary Association Theological Seminary",
        "Baptist Theological Seminary at Richmond",
        "Baptist University of the Americas",
        "Barber School of Pittsburgh",
        "Barber School of Pittsburgh - Ambridge",
        "Barber Tech Academy",
        "Barclay College",
        "Bard College",
        "Bard College - BHSEC Newark",
        "Bard College - Brooklyn Public Library",
        "Bard College - Coxsackie Correctional Facility",
        "Bard College - Eastern NY Correctional Facility",
        "Bard College - Fishkill Correctional Facility",
        "Bard College - Green Haven Correctional Facility",
        "Bard College - Holyoke Microcollege",
        "Bard College - MAT Program CA",
        "Bard College - Taconic Correctional Facility",
        "Bard College - Woodbourne Correctional Facility",
        "Bard College at Simon's Rock",
        "Barnard College",
        "Barnes-Jewish College Goldfarb School of Nursing",
        "Barrett and Company School of Hair Design",
        "Barry University",
        "Barry University Law School",
        "Barstow Community College",
        "Barton College",
        "Barton County Community College",
        "Bastyr University",
        "Bates College",
        "Bates Technical College",
        "Baton Rouge Community College",
        "Baton Rouge General Medical Center-School of Nursing",
        "Baton Rouge School of Computers",
        "Bay Area Medical Academy",
        "Bay Area Medical Academy - San Jose Satellite Location",
        "Bay de Noc Community College",
        "Bay Mills Community College",
        "Bay Path University",
        "Bay State College",
        "Bay State School of Technology",
        "Bayamon Community College",
        "Baylor College of Medicine",
        "Baylor University",
        "Bayshire Academy of Beauty Craft Inc",
        "Beacon College",
        "Beal College",
        "Beau Monde Academy of Cosmetology",
        "Beaufort County Community College",
        "Beaumont Adult School",
        "Beauty Academy of South Florida",
        "Beauty School of Middletown",
        "Beauty Schools of America-Hialeah",
        "Beauty Schools of America-Homestead",
        "Beauty Schools of America-Miami",
        "Beauty Schools of America-North Miami Beach",
        "Beauty Technical College Inc",
        "Beaver Falls Beauty Academy",
        "Becker College",
        "Beckfield College-Florence",
        "Be'er Yaakov Talmudic Seminary",
        "Beis Medrash Heichal Dovid",
        "Belanger School of Nursing",
        "Belhaven University",
        "Bell Mar Beauty College",
        "Bella Academy of Cosmetology",
        "Bella Capelli Academy",
        "Bella Cosmetology College",
        "Bellarmine University",
        "Bellarmine University - Bullitt County",
        "Bellarmine University - Oldham County",
        "Belle Academy of Cosmetology LLC",
        "Bellevue College",
        "Bellevue University",
        "Bellin College",
        "Bellingham Technical College",
        "Bellus Academy",
        "Bellus Academy-Chula Vista",
        "Bellus Academy-El Cajon",
        "Bellus Academy-Poway",
        "Belmont Abbey College",
        "Belmont College",
        "Belmont University",
        "Beloit College",
        "Bemidji State University",
        "Ben Franklin Career Center",
        "Benedict College",
        "Benedictine College",
        "Benedictine University",
        "Bene's Career Academy",
        "Bennett College",
        "Bennington College",
        "Bentley University",
        "Berea College",
        "Bergen Community College",
        "Bergin University of Canine Studies",
        "Berk Trade and Business School",
        "Berkeley City College",
        "Berkeley College - Westchester Campus",
        "Berkeley College-New York",
        "Berkeley College-Woodland Park",
        "Berklee College of Music",
        "Berkowits School of Electrolysis",
        "Berks Career & Technology Center",
        "Berkshire Community College",
        "Berry College",
        "Best Care College",
        "Bet Medrash Gadol Ateret Torah",
        "Beth Hatalmud Rabbinical College",
        "Beth Medrash Govoha",
        "Beth Medrash Meor Yitzchok",
        "Beth Medrash of Asbury Park",
        "Bethany College",
        "Bethany Global University",
        "Bethany Lutheran College",
        "Bethany Theological Seminary",
        "Bethel College",
        "Bethel College-North Newton",
        "Bethel Seminary-San Diego",
        "Bethel Seminary-St Paul",
        "Bethel University",
        "Bethesda University",
        "Bethune-Cookman University",
        "Beulah Heights University",
        "Bevill State Community College",
        "Bexley Hall Seabury Western Theological Seminary Federation Inc.",
        "Beyond 21st Century Beauty Academy",
        "Beyond The Basics School of Cosmetology",
        "Bidwell Training Center Inc",
        "Big Bend Community College",
        "Big Bend Technical College",
        "Big Sandy Community and Technical College",
        "Binghamton University",
        "Biola University",
        "Birmingham-Southern College",
        "Birthingway College of Midwifery",
        "Birthwise Midwifery School",
        "Bishop State Community College",
        "Bismarck State College",
        "Bitterroot School of Cosmetology",
        "BJ's Beauty & Barber College",
        "BK Cosmo College of Cosmetology",
        "Black Hawk College",
        "Black Hills State University",
        "Black River Technical College",
        "Blackburn College",
        "Blackfeet Community College",
        "Blackhawk Technical College",
        "Blackstone Valley Vocational Regional School District",
        "Bladen Community College",
        "Blades School of Hair Design",
        "Blake Austin College",
        "Blake Austin College Beauty Academy",
        "Blalock's Professional Beauty College",
        "Blessing Hospital School of Medical Laboratory Technology",
        "Blessing Hospital School of Radiologic Technology",
        "Blessing Rieman College of Nursing and Health Sciences",
        "Blinn College",
        "Bloomfield College",
        "Bloomsburg University of Pennsylvania",
        "Blue Cliff Career College",
        "Blue Cliff College",
        "Blue Hills Regional Technical School",
        "Blue Mountain Community College",
        "Blue Ridge Community College",
        "Bluefield College",
        "Bluefield College - Edward Via College of Osteopathic Medicine",
        "Bluefield State College",
        "Bluegrass Community and Technical College",
        "Bluffton University",
        "Blush School of Makeup",
        "Bnos Zion Of Bobov Seminary",
        "Bob Jones University",
        "Boca Beauty Academy",
        "Boca Beauty Academy-Parkland",
        "Boise Barber College",
        "Boise Bible College",
        "Boise State University",
        "Bold Beauty Academy",
        "Bolivar Technical College",
        "Bon Secours Memorial College of Nursing",
        "Bon Secours St Mary's Hospital School of Medical Imaging",
        "Bonnie Joseph Academy of Cosmetology & Barbering",
        "Boricua College",
        "Borner's Barber College",
        "Bos-Man's Barber College",
        "Bossier Parish Community College",
        "Boston Architectural College",
        "Boston Baptist College",
        "Boston College",
        "Boston Graduate School of Psychoanalysis Inc",
        "Boston University",
        "Bowdoin College",
        "Bowie State University",
        "Bowling Green State University-Firelands",
        "Bowling Green State University-Main Campus",
        "Bradford School",
        "Bradley University",
        "Brand College",
        "Brandeis University",
        "Brandman University",
        "Branford Academy of Hair and Cosmetology",
        "Brazosport College",
        "Brenau University",
        "Brescia University",
        "Brevard College",
        "Brewster Technical College",
        "Brewton-Parker College",
        "Briar Cliff University",
        "Bridgerland Technical College",
        "BridgeValley Community & Technical College",
        "Bridgewater College",
        "Bridgewater State University",
        "Brigham Young University-Hawaii",
        "Brigham Young University-Idaho",
        "Brigham Young University-Provo",
        "Brighton Center's Center for Employment Training",
        "Bristol Community College",
        "Bristol Technical Education Center",
        "Brite Divinity School",
        "Brittany Beauty Academy",
        "Broadview University-West Jordan",
        "Broken Arrow Beauty College",
        "Broken Arrow Beauty College-Broken Arrow",
        "Brookdale Community College",
        "Brookhaven College",
        "Brookline College-Albuquerque",
        "Brookline College-Phoenix",
        "Brookline College-Tempe",
        "Brookline College-Tucson",
        "Brooklyn Law School",
        "Broome Delaware Tioga BOCES-Practical Nursing Program",
        "Broward College",
        "Brown Beauty Barber School",
        "Brown College of Court Reporting",
        "Brown University",
        "Brownson Technical School",
        "Brunswick Community College",
        "Bryan College of Health Sciences",
        "Bryan College-Dayton",
        "Bryan University",
        "Bryant & Stratton College-Akron",
        "Bryant & Stratton College-Albany",
        "Bryant & Stratton College-Amherst",
        "Bryant & Stratton College-Bayshore",
        "Bryant & Stratton College-Buffalo",
        "Bryant & Stratton College-Cleveland",
        "Bryant & Stratton College-Greece",
        "Bryant & Stratton College-Hampton",
        "Bryant & Stratton College-Henrietta",
        "Bryant & Stratton College-Online",
        "Bryant & Stratton College-Parma",
        "Bryant & Stratton College-Racine",
        "Bryant & Stratton College-Richmond",
        "Bryant & Stratton College-Solon",
        "Bryant & Stratton College-Southtowns",
        "Bryant & Stratton College-Syracuse",
        "Bryant & Stratton College-Syracuse North",
        "Bryant & Stratton College-Virginia Beach",
        "Bryant & Stratton College-Wauwatosa",
        "Bryant University",
        "Bryn Athyn College of the New Church",
        "Bryn Mawr College",
        "Buckeye Hills Career Center",
        "Buckeye Joint Vocational School",
        "Bucknell University",
        "Buckner Barber School",
        "Bucks County Community College",
        "Bucks County Community College-Lower Bucks Campus",
        "Bucks County Community College-Upper Bucks Campus",
        "Bucks County School of Beauty Culture Inc",
        "Buena Vista University",
        "Bull City Durham Beauty and Barber College",
        "B-Unique Beauty and Barber Academy",
        "Bunker Hill Community College",
        "Burrell College of Osteopathic Medicine",
        "Butler Beauty Academy",
        "Butler Beauty Academy-Kittanning Beauty Academy",
        "Butler Beauty Academy-New Castle Beauty Academy",
        "Butler Community College",
        "Butler County Community College",
        "Butler Technology and Career Development Schools",
        "Butler University",
        "Butte Academy of Beauty Culture",
        "Butte College",
        "Butte County Regional Occupational Program",
        "Byzantine Catholic Seminary of Saints Cyril and Methodius",
        "C. Alexander School of Cosmetology",
        "CAAN Academy of Nursing",
        "Cabarrus College of Health Sciences",
        "Cabell County Career Technology Center",
        "Cabrillo College",
        "Cabrini University",
        "Caddo Kiowa Technology Center",
        "Cairn University-Langhorne",
        "Caldwell University",
        "California Aeronautical University",
        "California Baptist University",
        "California Barber and Beauty College",
        "California Beauty School",
        "California Career College",
        "California Career School",
        "California Christian College",
        "California Coast University",
        "California College of Barbering and Cosmetology",
        "California College of the Arts",
        "California College San Diego",
        "California Hair Design Academy",
        "California Healing Arts College",
        "California Intercontinental University",
        "California Jazz Conservatory",
        "California Lutheran University",
        "California Miramar University",
        "California Polytechnic State University-San Luis Obispo",
        "California State Polytechnic University-Pomona",
        "California State University Maritime Academy",
        "California State University-Bakersfield",
        "California State University-Channel Islands",
        "California State University-Chico",
        "California State University-Dominguez Hills",
        "California State University-East Bay",
        "California State University-Fresno",
        "California State University-Fullerton",
        "California State University-Long Beach",
        "California State University-Los Angeles",
        "California State University-Monterey Bay",
        "California State University-Northridge",
        "California State University-Sacramento",
        "California State University-San Bernardino",
        "California State University-San Marcos",
        "California State University-Stanislaus",
        "California Technical Academy",
        "California University of Management and Sciences",
        "California University of Pennsylvania",
        "California Western School of Law",
        "Calumet College of Saint Joseph",
        "Calvary University",
        "Calvin Theological Seminary",
        "Calvin University",
        "Cambridge College",
        "Cambridge College of Healthcare & Technology",
        "Cambridge Junior College-Yuba City",
        "Camden County College",
        "Camelot College",
        "Cameo Beauty Academy",
        "Cameo College of Essential Beauty",
        "Cameron University",
        "Campbell University",
        "Campbellsville University",
        "Canada College",
        "Canadian Valley Technology Center",
        "Canisius College",
        "Cankdeska Cikana Community College",
        "Cannella School of Hair Design",
        "Canton City Schools Adult Career and Technical Education",
        "Cape Cod Community College",
        "Cape Coral Technical College",
        "Cape Fear Community College",
        "Cape Girardeau Career and Technology Center",
        "Capella University",
        "Capilo School of Hair Design",
        "Capital Area School of Practical Nursing",
        "Capital Community College",
        "Capital University",
        "Capitol School of Hairstyling and Esthetics",
        "Capitol Technology University",
        "Capri Beauty College",
        "Capri College-Cedar Rapids",
        "Capri College-Davenport",
        "Capri College-Dubuque",
        "Capri Cosmetology Learning Centers",
        "Capstone College",
        "Cardinal Stritch University",
        "Career Academy of Beauty",
        "Career Academy of Hair Design",
        "Career Academy of Hair Design - Fort Smith",
        "Career Academy of Hair Design-Fayetteville",
        "Career Academy of Hair Design-Rogers",
        "Career Academy of Hair Design-Siloam Springs",
        "Career and Technology Education Centers of Licking County",
        "Career Beauty College",
        "Career Center of Southern Illinois",
        "Career College of Northern Nevada",
        "Career Quest Learning Center-Bay City Branch",
        "Career Quest Learning Center-Mt. Pleasant",
        "Career Quest Learning Centers-Jackson",
        "Career Quest Learning Centers-Lansing",
        "Career School of NY",
        "Career Technology Center of Lackawanna County",
        "Careers Unlimited",
        "Caribbean Forensic and Technical College",
        "Caribbean University-Bayamon",
        "Caribbean University-Carolina",
        "Caribbean University-Ponce",
        "Caribbean University-Vega Baja",
        "Caris College",
        "Carl Albert State College",
        "Carl Sandburg College",
        "Carleton College",
        "Carlos Albizu University-Miami",
        "Carlos Albizu University-San Juan",
        "Carlow University",
        "Carlson College of Massage Therapy",
        "Carnegie Mellon University",
        "Carolina Career College",
        "Carolina Christian College",
        "Carolina College of Biblical Studies",
        "Carolina College of Hair Design",
        "Carolina School of Broadcasting",
        "Carolinas College of Health Sciences",
        "Carrington College-Albuquerque",
        "Carrington College-Boise",
        "Carrington College-Citrus Heights",
        "Carrington College-Las Vegas",
        "Carrington College-Mesa",
        "Carrington College-Mesquite",
        "Carrington College-Phoenix East",
        "Carrington College-Phoenix North",
        "Carrington College-Pleasant Hill",
        "Carrington College-Pomona",
        "Carrington College-Portland",
        "Carrington College-Reno",
        "Carrington College-Sacramento",
        "Carrington College-San Jose",
        "Carrington College-San Leandro",
        "Carrington College-Spokane",
        "Carrington College-Stockton",
        "Carrington College-Tucson",
        "Carroll College",
        "Carroll Community College",
        "Carroll University",
        "Carson-Newman University",
        "Carteret Community College",
        "Carthage College",
        "Carthage R9 School District-Carthage Technical Center",
        "Carver Career Center",
        "Casa Loma College-Van Nuys",
        "Cascadia College",
        "Case Western Reserve University",
        "Casper College",
        "Cass Career Center",
        "Castleton University",
        "Catawba College",
        "Catawba Valley Community College",
        "Catholic Distance University",
        "Catholic Theological Union at Chicago",
        "Cattaraugus Allegany BOCES-Practical Nursing Program",
        "Cayce/Reilly School of Massage",
        "Cayuga County Community College",
        "Cayuga Onondaga BOCES-Practical Nursing Program",
        "Cazenovia College",
        "CBD College",
        "CCI Training Center",
        "CCI Training Center-Arlington",
        "Cecil College",
        "Cecil College - Elkton Station",
        "Cedar Crest College",
        "Cedar Valley College",
        "Cedarville University",
        "Celebrity Barber School",
        "Celebrity School of Beauty",
        "Celebrity School of Beauty - Hialeah",
        "Celebrity Stylist Beauty School",
        "CEM College-Bayamon",
        "CEM College-Humacao",
        "CEM College-Mayaguez",
        "CEM College-San Juan",
        "Centenary College of Louisiana",
        "Centenary University",
        "Center for Advanced Legal Studies",
        "Center for Advanced Studies On Puerto Rico and the Caribbean",
        "Center for Allied Health Education",
        "Center for Massage",
        "Center for Natural Wellness School of Massage Therapy",
        "Center for Neurosomatic Studies",
        "Center for Ultrasound Research & Education",
        "CenterPoint Massage & Shiatsu Therapy School & Clinic",
        "Centra College of Nursing",
        "Central Alabama Community College",
        "Central Arizona College",
        "Central Baptist College",
        "Central California School of Continuing Education",
        "Central California School of Continuing Education -",
        "Central Carolina Community College",
        "Central Carolina Technical College",
        "Central Christian College of Kansas",
        "Central Christian College of the Bible",
        "Central Coast College",
        "Central College",
        "Central College of Cosmetology",
        "Central Community College",
        "Central Connecticut State University",
        "Central Georgia Technical College",
        "Central Lakes College-Brainerd",
        "Central Lakes College-Staples Campus",
        "Central Louisiana Technical Community College",
        "Central Maine Community College",
        "Central Methodist University-College of Graduate and Extended Studies",
        "Central Methodist University-College of Liberal Arts and Sciences",
        "Central Michigan University",
        "Central New Mexico Community College",
        "Central Ohio Technical College",
        "Central Oklahoma College",
        "Central Oregon Community College",
        "Central Penn College",
        "Central Piedmont Community College",
        "Central School of Practical Nursing",
        "Central State University",
        "Central Susquehanna Intermediate Unit LPN Career",
        "Central Technology Center",
        "Central Texas Beauty College-Round Rock",
        "Central Texas Beauty College-Temple",
        "Central Texas College",
        "Central Virginia Community College",
        "Central Washington University",
        "Central Wyoming College",
        "Central Yeshiva Beth Joseph",
        "Central Yeshiva Tomchei Tmimim Lubavitz",
        "Centralia Beauty College",
        "Centralia College",
        "Centre College",
        "Centura College-Chesapeake",
        "Centura College-Columbia",
        "Centura College-Newport News",
        "Centura College-Norfolk",
        "Centura College-Richmond Main",
        "Centura College-Virginia Beach",
        "Century College",
        "Cerritos College",
        "Cerro Coso Community College",
        "CES College",
        "CET-Alexandria",
        "CET-Coachella",
        "CET-Colton",
        "CET-El Centro",
        "CET-El Paso",
        "CET-Oxnard",
        "CET-Salinas",
        "CET-San Diego",
        "CET-San Jose",
        "CET-Santa Maria",
        "CET-Soledad",
        "CET-Watsonville",
        "Chabot College",
        "Chadron State College",
        "Chaffey College",
        "Chamberlain University-Arizona",
        "Chamberlain University-California",
        "Chamberlain University-Florida",
        "Chamberlain University-Georgia",
        "Chamberlain University-Illinois",
        "Chamberlain University-Indiana",
        "Chamberlain University-Louisiana",
        "Chamberlain University-Michigan",
        "Chamberlain University-Missouri",
        "Chamberlain University-Nevada",
        "Chamberlain University-New Jersey",
        "Chamberlain University-North Carolina",
        "Chamberlain University-Ohio",
        "Chamberlain University-Texas",
        "Chamberlain University-Virginia",
        "Chambersburg Beauty School",
        "Chaminade University of Honolulu",
        "Champion Beauty College",
        "Champion Christian College",
        "Champlain College",
        "Champ's Barber School",
        "Chandler-Gilbert Community College",
        "Chapman University",
        "Charles A Jones Career and Education Center",
        "Charles and Sues School of Hair Design",
        "Charles H McCann Technical School",
        "Charles of Italy Beauty College",
        "Charles R Drew University of Medicine and Science",
        "Charles Stuart School of Diamond Setting",
        "Charleston School of Beauty Culture",
        "Charleston School of Law",
        "Charleston Southern University",
        "Charlie's Guard-Detective Bureau and Academy Inc",
        "Charlotte Christian College and Theological Seminary",
        "Charlotte Technical College",
        "Charter College",
        "Charter Oak State College",
        "Charzanne Beauty College",
        "Chatfield College",
        "Chatham University",
        "Chattahoochee Technical College",
        "Chattahoochee Valley Community College",
        "Chattanooga College Medical Dental and Technical Careers",
        "Chattanooga State Community College",
        "Cheeks Beauty Academy",
        "Chemeketa Community College",
        "Cheryl Fells School of Business",
        "Chesapeake College",
        "Chester Career College",
        "Chester County Intermediate Unit",
        "Chestnut Hill College",
        "Cheyney University of Pennsylvania",
        "CHI Health School of Radiologic Technology",
        "Chicago School of Professional Psychology at Dallas",
        "Chicago State University",
        "Chicago Theological Seminary",
        "Chief Dull Knife College",
        "Chipola College",
        "Chippewa Valley Technical College",
        "Chisholm Trail Technology Center",
        "Choffin Career  and Technical Center",
        "Chowan University",
        "Chris Beauty College",
        "Christ the King Seminary",
        "Christian Brothers University",
        "Christian Life College",
        "Christian Theological Seminary",
        "Christie's Education",
        "Christina and Company Education Center",
        "Christine Valmy International School for Esthetics Skin Care & Makeup",
        "Christine Valmy International School of Esthetics & Cosmetology",
        "Christopher Newport University",
        "Church Divinity School of the Pacific",
        "Cincinnati Christian University",
        "Cincinnati College of Mortuary Science",
        "Cincinnati School of Barbering & Hair Design Inc",
        "Cincinnati School of Medical Massage",
        "Cincinnati State Technical and Community College",
        "Circle in the Square Theatre School",
        "Cisco College",
        "Citadel Military College of South Carolina",
        "Citizens School of Nursing",
        "Citrus College",
        "Citrus Heights Beauty College",
        "City College Montana State University Billings",
        "City College of San Francisco",
        "City College-Altamonte Springs",
        "City College-Fort Lauderdale",
        "City College-Gainesville",
        "City College-Hollywood",
        "City College-Miami",
        "City Colleges of Chicago-Harold Washington College",
        "City Colleges of Chicago-Harry S Truman College",
        "City Colleges of Chicago-Kennedy-King College",
        "City Colleges of Chicago-Malcolm X College",
        "City Colleges of Chicago-Olive-Harvey College",
        "City Colleges of Chicago-Richard J Daley College",
        "City Colleges of Chicago-Wilbur Wright College",
        "City Pointe Beauty Academy",
        "City University of Seattle",
        "City Vision University",
        "Clackamas Community College",
        "Claflin University",
        "Claremont Graduate University",
        "Claremont Lincoln University",
        "Claremont McKenna College",
        "Claremont School of Theology",
        "Clarendon College",
        "Clarion County Career Center Practical Nursing Program",
        "Clarion University of Pennsylvania",
        "Clark Atlanta University",
        "Clark College",
        "Clark State Community College",
        "Clark University",
        "Clarke University",
        "Clarks Summit University",
        "Clarksburg Beauty Academy and School of Massage Therapy",
        "Clarkson College",
        "Clarkson University",
        "Clary Sage College",
        "Clatsop Community College",
        "Clayton  State University",
        "Clear Creek Baptist Bible College",
        "Clearfield County Career and Technology Center",
        "Cleary University",
        "Clemson University",
        "Cleveland Clinic Health System-School of Diagnostic Imaging",
        "Cleveland Community College",
        "Cleveland State Community College",
        "Cleveland State University",
        "Cleveland University-Kansas City",
        "Clinton College",
        "Clinton Community College",
        "Clinton Essex Warren Washington BOCES",
        "Clinton Technical School",
        "Cloud County Community College",
        "Clover Park Technical College",
        "Clovis Adult Education",
        "Clovis Community College",
        "Cloyd's Barber School 2 Inc",
        "Cloyd's Beauty School 1 Inc",
        "Cloyd's Beauty School 3 Inc",
        "Coachella Valley Beauty College",
        "Coachella Valley Beauty College-Hemet",
        "Coahoma Community College",
        "Coastal Alabama Community College",
        "Coastal Bend College",
        "Coastal Carolina Community College",
        "Coastal Carolina University",
        "Coastal Pines Technical College",
        "Coastline Beauty College",
        "Coastline Community College",
        "Coba Academy",
        "Cobb Beauty College Inc",
        "Cochise County Community College District",
        "Cochran School of Nursing",
        "Coconino Community College",
        "Coe College",
        "Coffeyville Community College",
        "Coffeyville Technical Campus",
        "Cogswell College",
        "Coker University",
        "Colby College",
        "Colby Community College",
        "Colby-Sawyer College",
        "Colegio de Cinematografia Artes y Television",
        "Colegio Educativo Tecnologico Industrial Inc",
        "Colegio Mayor de Tecnologia Inc",
        "Colegio Tecnico de Electricidad Galloza",
        "Colegio Tecnologico y Comercial de Puerto Rico",
        "Colegio Universitario de San Juan",
        "Colgate Rochester Crozer Divinity School",
        "Colgate University",
        "Collectiv Academy",
        "Colleen O'Haras Beauty Academy",
        "College for Creative Studies",
        "College of Alameda",
        "College of Biblical Studies",
        "College of Biblical Studies-Houston",
        "College of Business and Technology-Cutler Bay",
        "College of Business and Technology-Hialeah",
        "College of Business and Technology-Main Campus",
        "College of Business and Technology-Miami Campus",
        "College of Central Florida",
        "College of Charleston",
        "College of Coastal Georgia",
        "College of Cosmetology",
        "College of Court Reporting Inc",
        "College of DuPage",
        "College of Eastern Idaho",
        "College of Hair Design Careers",
        "College of Hair Design-Downtown",
        "College of Hair Design-East Campus",
        "College of International Esthetics Inc",
        "College of Lake County",
        "College of Marin",
        "College of Massage Therapy",
        "College of Menominee Nation",
        "College of Micronesia-FSM",
        "College of Mount Saint Vincent",
        "College of Our Lady of the Elms",
        "College of Saint Benedict",
        "College of Saint Elizabeth",
        "College of Saint Mary",
        "College of San Mateo",
        "College of Southern Idaho",
        "College of Southern Maryland",
        "College of Southern Nevada",
        "College of St Joseph",
        "College of Staten Island CUNY",
        "College of the Albemarle",
        "College of the Atlantic",
        "College of the Canyons",
        "College of the Desert",
        "College of the Holy Cross",
        "College of the Mainland",
        "College of the Marshall Islands",
        "College of the Muscogee Nation",
        "College of the Ozarks",
        "College of the Redwoods",
        "College of the Sequoias",
        "College of the Siskiyous",
        "College of Western Idaho",
        "College of Wilmington",
        "CollegeAmerica-Colorado Springs",
        "CollegeAmerica-Denver",
        "CollegeAmerica-Flagstaff",
        "CollegeAmerica-Fort Collins",
        "CollegeAmerica-Phoenix",
        "Collin County Community College District",
        "Colorado Academy of Veterinary Technology",
        "Colorado Christian University",
        "Colorado College",
        "Colorado Media School",
        "Colorado Mesa University",
        "Colorado Mountain College",
        "Colorado Northwestern Community College",
        "Colorado School of Healing Arts",
        "Colorado School of Mines",
        "Colorado School of Trades",
        "Colorado School of Traditional Chinese Medicine",
        "Colorado State University-Fort Collins",
        "Colorado State University-Global Campus",
        "Colorado State University-Pueblo",
        "Colorado Technical University-Colorado Springs",
        "Colorado Technical University-Denver",
        "Columbia Area Career Center",
        "Columbia Basin College",
        "Columbia Central University-Caguas",
        "Columbia Central University-Yauco",
        "Columbia College",
        "Columbia College -  Jacksonville",
        "Columbia College - Coast Guard Island",
        "Columbia College - Crystal Lake Campus",
        "Columbia College - Denver",
        "Columbia College - Elgin",
        "Columbia College - Fort Drum",
        "Columbia College - Fort Leonard Wood",
        "Columbia College - Fort Sill",
        "Columbia College - Fort Stewart",
        "Columbia College - Fort Worth",
        "Columbia College - Freeport",
        "Columbia College - Hancock Field",
        "Columbia College - Hunter Army Airfield",
        "Columbia College - Jefferson City",
        "Columbia College - Kansas City",
        "Columbia College - Lake County",
        "Columbia College - Lake Ozark",
        "Columbia College - Lemoore",
        "Columbia College - Los Alamitos",
        "Columbia College - Mesquite",
        "Columbia College - NAS Jacksonville",
        "Columbia College - Naval Station Everett/Marysville",
        "Columbia College - Orlando",
        "Columbia College - Patrick AFB",
        "Columbia College - Redstone Arsenal",
        "Columbia College - Rolla",
        "Columbia College - Saint Louis",
        "Columbia College - Salt Lake",
        "Columbia College - San Diego",
        "Columbia College - San Luis Obispo",
        "Columbia College - Springfield",
        "Columbia College - Whidbey Island",
        "Columbia College Chicago",
        "Columbia College Hollywood",
        "Columbia College of Nursing",
        "Columbia Gorge Community College",
        "Columbia International University",
        "Columbia Southern University",
        "Columbia State Community College",
        "Columbia Theological Seminary",
        "Columbia University in the City of New York",
        "Columbia-Greene Community College",
        "Columbiana County Career and Technical Center",
        "Columbus College of Art and Design",
        "Columbus State Community College",
        "Columbus State University",
        "Columbus Technical Campus",
        "Columbus Technical College",
        "Community Care College",
        "Community Christian College",
        "Community College of Allegheny County",
        "Community College of Aurora",
        "Community College of Baltimore County",
        "Community College of Beaver County",
        "Community College of Denver",
        "Community College of Philadelphia",
        "Community College of Rhode Island",
        "Community College of Vermont",
        "Compass Career College",
        "Compass College of Cinematic Arts",
        "Compton College",
        "Compu-Med Vocational Careers Corp",
        "Computer Career Center-Las Cruces",
        "Conception Seminary College",
        "Concord University",
        "Concorde Career College-Aurora",
        "Concorde Career College-Dallas",
        "Concorde Career College-Garden Grove",
        "Concorde Career College-Grand Prairie",
        "Concorde Career College-Kansas City",
        "Concorde Career College-Memphis",
        "Concorde Career College-North Hollywood",
        "Concorde Career College-Portland",
        "Concorde Career College-San Antonio",
        "Concorde Career College-San Bernardino",
        "Concorde Career College-San Diego",
        "Concorde Career College-Southaven",
        "Concordia College at Moorhead",
        "Concordia College-New York",
        "Concordia Seminary",
        "Concordia Theological Seminary",
        "Concordia University Texas",
        "Concordia University-Ann Arbor",
        "Concordia University-Chicago",
        "Concordia University-Irvine",
        "Concordia University-Nebraska",
        "Concordia University-Portland",
        "Concordia University-Saint Paul",
        "Concordia University-Wisconsin",
        "Connecticut Aero Tech  School",
        "Connecticut College",
        "Connors State College",
        "Conservatory of Music of Puerto Rico",
        "Conservatory of Recording Arts and Sciences",
        "Construction Training Center",
        "Continental Academie of Hair Design-Hudson",
        "Continental School of Beauty Culture-Mattydale",
        "Continental School of Beauty Culture-Olean",
        "Continental School of Beauty Culture-Rochester",
        "Continental School of Beauty Culture-West Seneca",
        "Contra Costa College",
        "Contra Costa Medical Career College",
        "Converse College",
        "Conway School of Landscape Design",
        "Cooper Union for the Advancement of Science and Art",
        "Copiah-Lincoln Community College",
        "Copiah-Lincoln Community College Simpson County Center",
        "Copiah-Lincoln Community College-Natchez Campus",
        "Copper Mountain Community College",
        "Coppin State University",
        "Corban University",
        "Corinth Academy of Cosmetology",
        "Cornell College",
        "Cornell University",
        "Cornerstone University",
        "Cornish College of the Arts",
        "Cosmetology & Spa Academy",
        "Cosmetology Academy of Texarkana",
        "Cosmetology Career Center LLC",
        "Cosmetology Careers Unlimited College of Hair Skin and Nails",
        "Cosmetology School of Arts and Science LLC",
        "Cosmetology Training Center",
        "Cosmo Beauty Academy",
        "Cosmo Factory Cosmetology Academy",
        "Cossatot Community College of the University of Arkansas",
        "Cosumnes River College",
        "Cottey College",
        "County College of Morris",
        "Covenant College",
        "Covenant School of Nursing and Allied Health",
        "Covenant Theological Seminary",
        "Cowley County Community College",
        "Cox College",
        "Coyne College",
        "Cozmo Beauty School",
        "Crafton Hills College",
        "Cranbrook Academy of Art",
        "Crave Beauty Academy",
        "Craven Community College",
        "Crawford County Career and Technical Center Practical Nursing Program",
        "Creative Hair School of Cosmetology",
        "Creative Touch Cosmetology School",
        "Creighton University",
        "Crescent City Bartending School",
        "Crevier's Academy of Cosmetology Arts",
        "Criswell College",
        "Crowder College",
        "Crowley's Ridge College",
        "Crown College",
        "CT Aero Tech School",
        "Cuesta College",
        "Culinary Tech Center",
        "Culpeper Cosmetology Training Center",
        "Culver-Stockton College",
        "Cumberland County College",
        "Cumberland University",
        "CUNY Bernard M Baruch College",
        "CUNY Borough of Manhattan Community College",
        "CUNY Bronx Community College",
        "CUNY Brooklyn College",
        "CUNY City College",
        "CUNY Graduate School and University Center",
        "CUNY Hostos Community College",
        "CUNY Hunter College",
        "CUNY John Jay College of Criminal Justice",
        "CUNY Kingsborough Community College",
        "CUNY LaGuardia Community College",
        "CUNY Lehman College",
        "CUNY Medgar Evers College",
        "CUNY New York City College of Technology",
        "CUNY Queens College",
        "CUNY Queensborough Community College",
        "CUNY School of Law",
        "CUNY Stella and Charles Guttman Community College",
        "CUNY York College",
        "Curry College",
        "Curve Metric School of Hair Design",
        "Cutting Edge Academy",
        "Cuyahoga Community College District",
        "Cuyahoga Valley Career Center",
        "Cuyamaca College",
        "CVPH Medical Center School of Radiologic Technology",
        "Cypress College",
        "D A Dorsey Technical College",
        "Dabney S Lancaster Community College",
        "Daemen College",
        "Dakota College at Bottineau",
        "Dakota County Technical College",
        "Dakota State University",
        "Dakota Wesleyan University",
        "Dallas Baptist University",
        "Dallas Barber & Stylist College",
        "Dallas Christian College",
        "Dallas Theological Seminary",
        "Dalton State College",
        "Danville Area Community College",
        "Danville Community College",
        "Daoist Traditions College of Chinese Medical Arts",
        "Dartmouth College",
        "Davenport University",
        "Davenport University - Macomb CC",
        "Davenport University - Schoolcraft College",
        "Davenport University - Treasury Site Dimondale",
        "Davenport University - Wayne CCCD",
        "Davenport University-Flint Location",
        "Davenport University-Grand Rapids Downtown",
        "Davenport University-Holland Location",
        "Davenport University-Kalamazoo Location",
        "Davenport University-Lansing Location",
        "Davenport University-Livonia Location",
        "Davenport University-Midland Location",
        "Davenport University-Traverse City Location",
        "Davenport University-Warren Location",
        "David Pressley School of Cosmetology",
        "Davidson College",
        "Davidson County Community College",
        "Davis & Elkins College",
        "Davis College",
        "Davis Technical College",
        "Dawson Community College",
        "Dayton Barber College",
        "Dayton School of Medical Massage",
        "Dayton School of Medical Massage-Lima",
        "Daytona College",
        "Daytona State College",
        "De Anza College",
        "Dean College",
        "Debutantes School of Cosmetology and Nail Technology",
        "Defiance College",
        "DeHart Technical School",
        "Del Mar College",
        "Delaware Chenango Madison Otsego BOCES-Practical Nursing Program",
        "Delaware College of Art and Design",
        "Delaware County Community College",
        "Delaware County Technical School-Practical Nursing Program",
        "Delaware State University",
        "Delaware Technical Community College-Owens",
        "Delaware Technical Community College-Stanton/Wilmington",
        "Delaware Technical Community College-Terry",
        "Delaware Valley University",
        "Delgado Community College",
        "Dell'Arte International School of Physical Theatre",
        "Delmarva Beauty Academy",
        "Delta Beauty College",
        "Delta College",
        "Delta College Inc",
        "Delta College of Arts & Technology",
        "Delta College of Arts & Technology-Lafayette Campus",
        "Delta College-Slidell Campus",
        "Delta Designs Cosmetology School",
        "Delta State University",
        "Delta Technical College",
        "Denham Springs Beauty School",
        "Denison University",
        "Denmark College",
        "Denmark Technical College",
        "Denver College of Nursing",
        "Denver Seminary",
        "Denver Seminary - Washington DC",
        "Denver Seminary - West Texas",
        "DePaul University",
        "DePauw University",
        "Dermal Science International Aesthetics and Nail Academy",
        "Des Moines Area Community College",
        "Des Moines University-Osteopathic Medical Center",
        "DeSales University",
        "Designer Barber & Stylist School",
        "Designer Barber & Stylist School -",
        "Design's School of Cosmetology",
        "DeVry College of New York",
        "DeVry University-Arizona",
        "DeVry University-California",
        "DeVry University-Colorado",
        "DeVry University-Florida",
        "DeVry University-Georgia",
        "DeVry University-Illinois",
        "DeVry University-Indiana",
        "DeVry University-Missouri",
        "DeVry University-Nevada",
        "DeVry University-New Jersey",
        "DeVry University-North Carolina",
        "DeVry University-Ohio",
        "DeVry University-Pennsylvania",
        "DeVry University-Tennessee",
        "DeVry University-Texas",
        "DeVry University-Virginia",
        "Dewey University-Carolina",
        "Dewey University-Hato Rey",
        "Dewey University-Juana Diaz",
        "Dewey University-Manati",
        "Diablo Valley College",
        "Diamond Beauty College",
        "Diamonds Cosmetology College",
        "Dickinson College",
        "Dickinson State University",
        "Diesel Driving Academy-Baton Rouge",
        "Diesel Driving Academy-Shreveport",
        "Digital Film Academy",
        "DiGrigoli School of Cosmetology",
        "Dillard University",
        "Dine College",
        "Divers Academy International",
        "Diversified Vocational College",
        "Divine Crown Barber & Beauty Academy",
        "Divine Mercy University",
        "Divine Word College",
        "Dixie State University",
        "Dixie Technical College",
        "DLP Conemaugh Memorial Medical Center",
        "Doane University",
        "Doane University-Graduate and Professional Studies",
        "Dodge City Community College",
        "Dolce LLC The Academy",
        "Dominican College of Blauvelt",
        "Dominican School of Philosophy & Theology",
        "Dominican University",
        "Dominican University of California",
        "Don Roberts Beauty School",
        "Don Roberts School of Hair Design",
        "Dongguk University-Los Angeles",
        "Donnelly College",
        "Dordt University",
        "Dorsey School of Business-Dearborn",
        "Dorsey School of Business-Madison Heights",
        "Dorsey School of Business-Roseville",
        "Dorsey School of Business-Roseville Culinary Academy",
        "Dorsey School of Business-Saginaw",
        "Dorsey School of Business-Waterford Pontiac",
        "Dorsey School of Business-Wayne",
        "Dorsey School of Business-Woodhaven",
        "Douglas Education Center",
        "Downey Adult School",
        "Dragon Rises College of Oriental Medicine",
        "Drake University",
        "Drew University",
        "Drexel University",
        "Drury University",
        "Drury University-College of Continuing Professional Studies",
        "DSDT",
        "Duke University",
        "Dunwoody College of Technology",
        "Duquesne University",
        "Durham Beauty Academy",
        "Durham Technical Community College",
        "Dutchess BOCES-Practical Nursing Program",
        "Dutchess Community College",
        "DuVall's School of Cosmetology",
        "Dyersburg State Community College",
        "D'Youville College",
        "E Q School of Hair Design",
        "Ea La Mar's Cosmetology & Barber College",
        "Eagle Gate College-Layton",
        "Eagle Gate College-Murray",
        "Eagle Rock College",
        "Earlham College",
        "East Arkansas Community College",
        "East Carolina University",
        "East Central College",
        "East Central Community College",
        "East Central University",
        "East Georgia State College",
        "East Georgia State College - Augusta",
        "East Georgia State College - Statesboro",
        "East Los Angeles College",
        "East Mississippi Community College",
        "East San Gabriel Valley Regional Occupational Program",
        "East Stroudsburg University of Pennsylvania",
        "East Tennessee State University",
        "East Texas Baptist University",
        "East West College of Natural Medicine",
        "East West College of the Healing Arts",
        "Eastern Arizona College",
        "Eastern Center for Arts and Technology",
        "Eastern College of Health Vocations-Little Rock",
        "Eastern College of Health Vocations-New Orleans",
        "Eastern Connecticut State University",
        "Eastern Florida State College",
        "Eastern Gateway Community College",
        "Eastern Illinois University",
        "Eastern International College-Belleville",
        "Eastern International College-Jersey City",
        "Eastern Iowa Community College District",
        "Eastern Kentucky University",
        "Eastern Maine Community College",
        "Eastern Mennonite University",
        "Eastern Michigan University",
        "Eastern Nazarene College",
        "Eastern New Mexico University Ruidoso Branch Community College",
        "Eastern New Mexico University-Main Campus",
        "Eastern New Mexico University-Roswell Campus",
        "Eastern Oklahoma County Technology Center",
        "Eastern Oklahoma State College",
        "Eastern Oregon University",
        "Eastern School of Acupuncture and Traditional Medicine",
        "Eastern Shore Community College",
        "Eastern Suffolk BOCES",
        "Eastern University",
        "Eastern Virginia Career College",
        "Eastern Virginia Medical School",
        "Eastern Washington University",
        "Eastern West Virginia Community and Technical College",
        "Eastern Wyoming College",
        "Eastfield College",
        "Eastland-Fairfield Career and Technical Schools",
        "East-West University",
        "Eastwick College-Hackensack",
        "Eastwick College-Nutley",
        "Eastwick College-Ramsey",
        "Ecclesia College",
        "Eckerd College",
        "eClips School of Cosmetology and Barbering",
        "ECPI University",
        "ECPI University - College of Nursing - Orlando",
        "ECPI University - Roanoke",
        "ECPI University - San Antonio",
        "ECPI University-Charleston",
        "ECPI University-Charlotte",
        "ECPI University-Columbia",
        "ECPI University-Concord",
        "ECPI University-Greensboro",
        "ECPI University-Greenville",
        "ECPI University-Innsbrook",
        "ECPI University-Manassas",
        "ECPI University-Newport News",
        "ECPI University-Raleigh",
        "ECPI University-Richmond South",
        "ECPI University-Richmond West",
        "ECPI University-Virginia Beach Health Sciences",
        "Ecumenical Theological Seminary",
        "Eden Theological Seminary",
        "Edgecombe Community College",
        "Edgewood College",
        "EDIC College",
        "Edinboro University of Pennsylvania",
        "Edison State Community College",
        "Edmonds Community College",
        "EDP School",
        "EDP University of Puerto Rico Inc-San Juan",
        "EDP University of Puerto Rico Inc-San Sebastian",
        "EDP University of Puerto Rico-Villalba",
        "Educational Technical College-Recinto de Bayamon",
        "Educational Technical College-Recinto de Coamo",
        "Educators of Beauty College of Cosmetology-Peru",
        "Educators of Beauty College of Cosmetology-Rockford",
        "Educators of Beauty College of Cosmetology-Sterling",
        "EduMed Partners",
        "Edward Via College of Osteopathic Medicine",
        "Edward Waters College",
        "EHOVE Career Center",
        "EINE Inc",
        "El Camino Community College District",
        "El Centro College",
        "El Paso Community College",
        "Elaine Steven Beauty College",
        "Electrical Training Center",
        "Elegance International",
        "Elgin Community College",
        "Elite Academy of Hair Design",
        "Elite College of Cosmetology",
        "Elite Cosmetology Barber & Spa Academy",
        "Elite Cosmetology School",
        "Elite School of Cosmetology",
        "Elite Welding Academy LLC",
        "Elizabeth City State University",
        "Elizabeth Grady School of Esthetics and Massage Therapy",
        "Elizabethtown College",
        "Elizabethtown College School of Continuing and Professional Studies",
        "Elizabethtown Community and Technical College",
        "Ellsworth Community College",
        "Elmezzi Graduate School of Molecular Medicine",
        "Elmhurst College",
        "Elmira College",
        "Elon University",
        "Elyon College",
        "Embry-Riddle Aeronautical University-Daytona Beach",
        "Embry-Riddle Aeronautical University-Prescott",
        "Embry-Riddle Aeronautical University-Worldwide",
        "Emerald Coast Technical College",
        "Emerson College",
        "Emily Griffith Technical College",
        "Emmanuel College",
        "Emma's Beauty Academy-Juana Diaz",
        "Emma's Beauty Academy-Mayaguez",
        "Emmaus Bible College",
        "Emory & Henry College",
        "Emory University",
        "Emory University-Oxford College",
        "Emperor's College of Traditional Oriental Medicine",
        "Empire Beauty  School-Lehigh Valley",
        "Empire Beauty School-Augusta",
        "Empire Beauty School-Aurora",
        "Empire Beauty School-Avondale",
        "Empire Beauty School-Bangor",
        "Empire Beauty School-Bloomfield",
        "Empire Beauty School-Bloomington",
        "Empire Beauty School-Bordentown",
        "Empire Beauty School-Boston",
        "Empire Beauty School-Brooklyn",
        "Empire Beauty School-Buffalo",
        "Empire Beauty School-Center City Philadelphia",
        "Empire Beauty School-Chandler",
        "Empire Beauty School-Charlotte",
        "Empire Beauty School-Cheltenham",
        "Empire Beauty School-Chenoweth",
        "Empire Beauty School-Cherry Hill",
        "Empire Beauty School-Cincinnati",
        "Empire Beauty School-Concord",
        "Empire Beauty School-Dixie",
        "Empire Beauty School-Dunwoody",
        "Empire Beauty School-E Greensboro",
        "Empire Beauty School-E Memphis",
        "Empire Beauty School-Elizabethtown",
        "Empire Beauty School-Flagstaff",
        "Empire Beauty School-Florence",
        "Empire Beauty School-Framingham",
        "Empire Beauty School-Glen Burnie",
        "Empire Beauty School-Green Bay",
        "Empire Beauty School-Gwinnett",
        "Empire Beauty School-Hanover",
        "Empire Beauty School-Hanover Park",
        "Empire Beauty School-Harrisburg",
        "Empire Beauty School-Hooksett",
        "Empire Beauty School-Hurstborne",
        "Empire Beauty School-Indianapolis",
        "Empire Beauty School-Jackson",
        "Empire Beauty School-Kennesaw",
        "Empire Beauty School-Laconia",
        "Empire Beauty School-Lakeland",
        "Empire Beauty School-Lancaster",
        "Empire Beauty School-Lauderhill",
        "Empire Beauty School-Lebanon",
        "Empire Beauty School-Littleton",
        "Empire Beauty School-Maine",
        "Empire Beauty School-Malden",
        "Empire Beauty School-Manhattan",
        "Empire Beauty School-Michigan",
        "Empire Beauty School-Midlothian",
        "Empire Beauty School-Milwaukee",
        "Empire Beauty School-Monroeville",
        "Empire Beauty School-Morrow",
        "Empire Beauty School-Nashville",
        "Empire Beauty School-NE Philadelphia",
        "Empire Beauty School-North Hills",
        "Empire Beauty School-Northlake",
        "Empire Beauty School-NW Phoenix",
        "Empire Beauty School-Owings Mills",
        "Empire Beauty School-Paramus",
        "Empire Beauty School-Peekskill",
        "Empire Beauty School-Pineville",
        "Empire Beauty School-Pottsville",
        "Empire Beauty School-Queens",
        "Empire Beauty School-Reading",
        "Empire Beauty School-Richmond",
        "Empire Beauty School-Rochester",
        "Empire Beauty School-S Memphis",
        "Empire Beauty School-Savannah",
        "Empire Beauty School-Shamokin Dam",
        "Empire Beauty School-Somersworth",
        "Empire Beauty School-Speedway",
        "Empire Beauty School-Spring Lake Park",
        "Empire Beauty School-Springfield",
        "Empire Beauty School-Stone Park",
        "Empire Beauty School-Tampa",
        "Empire Beauty School-Thornton",
        "Empire Beauty School-Tucson",
        "Empire Beauty School-Union",
        "Empire Beauty School-Vernon Hills",
        "Empire Beauty School-Virginia Beach",
        "Empire Beauty School-Warminster",
        "Empire Beauty School-Warwick",
        "Empire Beauty School-West Greensboro",
        "Empire Beauty School-West Mifflin",
        "Empire Beauty School-West Palm",
        "Empire Beauty School-Winston-Salem",
        "Empire Beauty School-Wyoming Valley",
        "Empire Beauty School-York",
        "Empire College",
        "Employment Solutions-College for Technical Education",
        "Emporia State University",
        "Endicott College",
        "Enid Beauty College",
        "Enterprise State Community College",
        "Epic Bible College",
        "Episcopal Theological Seminary of the Southwest",
        "Eric Fisher Academy",
        "Erie 1 BOCES",
        "Erie 2 Chautauqua Cattaraugus BOCES-Practical Nursing Program",
        "Erie Community College",
        "Erskine College",
        "Erwin Technical College",
        "Escuela de Artes Plasticas y Diseno de Puerto Rico",
        "Escuela de Peritos Electricistas de Isabela Inc",
        "Escuela De Troqueleria Y Herramentaje",
        "Escuela Hotelera de San Juan",
        "Escuela Tecnica de Electricidad",
        "Essex County College",
        "Estelle Medical Academy",
        "Estrella Mountain Community College",
        "ETI School of Skilled Trades",
        "ETI Technical College",
        "Eureka College",
        "European Academy of Cosmetology and Hairdressing",
        "European Massage Therapy School-Las Vegas",
        "European Medical School of Massage",
        "Evangel University",
        "Evangel University - Assemblies of God Theological Seminary",
        "Evangelical Theological Seminary",
        "Evans Hairstyling College-Cedar City",
        "Evans Hairstyling College-Rexburg",
        "Evans Hairstyling College-St George",
        "Everett Community College",
        "Everglades University",
        "Everglades University - Miami",
        "Everglades University - Tampa",
        "Everglades University-Orlando",
        "Everglades University-Sarasota",
        "Evergreen Beauty and Barber College-Everett",
        "Evergreen Valley College",
        "Eves College of Hairstyling",
        "Evolve Beauty Academy",
        "Excelsior College",
        "Exposito School of Hair Design",
        "Fairfax University of America",
        "Fairfield University",
        "Fairleigh Dickinson University-Florham Campus",
        "Fairleigh Dickinson University-Metropolitan Campus",
        "Fairmont State University",
        "Faith Baptist Bible College and Theological Seminary",
        "Faith International University",
        "Faith Theological Seminary",
        "Family of Faith Christian University",
        "Farmingdale State College",
        "Fashion Focus Hair Academy",
        "Faulkner University",
        "Fayetteville State University",
        "Fayetteville Technical Community College",
        "Feather River Community College District",
        "Felbry College School of Nursing",
        "Felician University",
        "Ferris State University",
        "Ferrum College",
        "Fielding Graduate University",
        "FINE Mortuary College",
        "Finger Lakes Community College",
        "Finger Lakes Health College of Nursing & Health Sciences",
        "Finger Lakes School of Massage",
        "Finlandia University",
        "Firelands Regional Medical Center School of Nursing",
        "First Class Cosmetology School",
        "First Coast Barber Academy",
        "First Coast Technical College",
        "Fisher College",
        "Fisk University",
        "Fitchburg State University",
        "Five Branches University",
        "Five Towns College",
        "Flagler College-St Augustine",
        "Flagler College-Tallahassee",
        "Flair Beauty College",
        "Flathead Valley Community College",
        "Fletcher Technical Community College",
        "Flint Hills Technical College",
        "Florence-Darlington Technical College",
        "Florida Academy",
        "Florida Academy of Health & Beauty",
        "Florida Agricultural and Mechanical University",
        "Florida Atlantic University",
        "Florida Barber Academy",
        "Florida Career College",
        "Florida Career College-Boynton Beach",
        "Florida Career College-Hialeah",
        "Florida Career College-Houston Campus",
        "Florida Career College-Jacksonville",
        "Florida Career College-Lauderdale Lakes",
        "Florida Career College-Margate",
        "Florida Career College-Miami",
        "Florida Career College-Orlando",
        "Florida Career College-Tampa",
        "Florida Career College-West Palm Beach",
        "Florida Center",
        "Florida Coastal School of Law",
        "Florida College",
        "Florida College of Integrative Medicine",
        "Florida Gateway College",
        "Florida Gulf Coast University",
        "Florida International University",
        "Florida Memorial University",
        "Florida National University Training Center",
        "Florida National University-Main Campus",
        "Florida National University-South Campus",
        "Florida Panhandle Technical College",
        "Florida Polytechnic University",
        "Florida School of Massage",
        "Florida School of Traditional Midwifery",
        "Florida Southern College",
        "Florida SouthWestern State College",
        "Florida State College at Jacksonville",
        "Florida State University",
        "Florida Technical College",
        "Folsom Lake College",
        "Fond du Lac Tribal and Community College",
        "Fontbonne University",
        "Foothill College",
        "Forbes Road Career and Technology Center",
        "Fordham University",
        "Forrest College",
        "Forsyth Technical Community College",
        "Fort Hays State University",
        "Fort Lewis College",
        "Fort Myers Technical College",
        "Fort Peck Community College",
        "Fort Pierce Beauty Academy",
        "Fort Scott Community College",
        "Fort Valley State University",
        "Fort Worth Beauty School",
        "Fortis College",
        "Fosbre Academy of Hair Design",
        "Fosters Cosmetology College",
        "Fountain of Youth Academy of Cosmetology",
        "Four County Career Center",
        "Four Rivers Career Center",
        "Fox College",
        "Fox Valley Technical College",
        "Framingham State University",
        "Francis Marion University",
        "Francis Tuttle Technology Center",
        "Franciscan Missionaries of Our Lady University",
        "Franciscan School of Theology",
        "Franciscan University of Steubenville",
        "Frank Phillips College",
        "Franklin Academy",
        "Franklin and Marshall College",
        "Franklin College",
        "Franklin County Career and Technology Center",
        "Franklin Hair Academy School of Cosmetology",
        "Franklin Pierce University",
        "Franklin Technology Center Adult Education",
        "Franklin University",
        "Franklin W Olin College of Engineering",
        "Fred K Marchman Technical College",
        "Fred W Eberle Technical Center",
        "Frederick Community College",
        "Frederick School of Cosmetology",
        "Fredrick and Charles Beauty College",
        "Freed-Hardeman University",
        "Fremont College",
        "French Academy of Cosmetology",
        "Fresno City College",
        "Fresno Pacific University",
        "Friends University",
        "Front Range Community College",
        "Frontier Community College",
        "Frontier Nursing University",
        "Frostburg State University",
        "Full Sail University",
        "Fuller Theological Seminary",
        "Fullerton College",
        "Fulton-Montgomery Community College",
        "Furman University",
        "Future Generations University",
        "GA Beauty & Barber School",
        "Gadsden State Community College",
        "Galaxy Medical College",
        "Galen College of Nursing-ARH",
        "Galen College of Nursing-Cincinnati",
        "Galen College of Nursing-Louisville",
        "Galen College of Nursing-San Antonio",
        "Galen College of Nursing-Tampa Bay",
        "Gallaudet University",
        "Galveston College",
        "Galveston College - Charlie Thomas Family Applied Technology Center",
        "Gannon University",
        "Garden City Community College",
        "Gardner-Webb University",
        "Garnet Career Center",
        "Garrett College",
        "Garrett-Evangelical Theological Seminary",
        "Gaston College",
        "Gateway Community and Technical College",
        "GateWay Community College",
        "Gateway Community College",
        "GateWay Community College-Central City",
        "Gateway Technical College",
        "Gavilan College",
        "Geisinger Commonwealth School of Medicine",
        "Geisinger-Lewistown Hospital School of Nursing",
        "Gem City College",
        "Gemini School of Visual Arts & Communication",
        "Gene Juarez Academy",
        "Genesee Community College",
        "Genesee Valley BOCES-Practical Nursing Program",
        "Genesis Career College-Cookeville",
        "Genesis Career College-Lebanon",
        "Geneva College",
        "George C Wallace Community College-Dothan",
        "George C Wallace State Community College-Hanceville",
        "George C Wallace State Community College-Selma",
        "George Fox University",
        "George Mason University",
        "George Stone Technical College",
        "George T Baker Aviation Technical College",
        "George Washington University",
        "Georgetown College",
        "Georgetown University",
        "Georgia College & State University",
        "Georgia Gwinnett College",
        "Georgia Highlands College",
        "Georgia Military College",
        "Georgia Military College - Albany",
        "Georgia Military College - Augusta",
        "Georgia Military College - Columbus",
        "Georgia Military College - Dublin",
        "Georgia Military College - Eastman",
        "Georgia Military College - Fairburn",
        "Georgia Military College - Fayetteville",
        "Georgia Military College - Madison",
        "Georgia Military College - Robins",
        "Georgia Military College - Sandersville",
        "Georgia Military College - Stone Mountain",
        "Georgia Military College - Valdosta",
        "Georgia Military College - Zebulon",
        "Georgia Northwestern Technical College",
        "Georgia Piedmont Technical College",
        "Georgia Southern University",
        "Georgia Southwestern State University",
        "Georgia State University",
        "Georgia State University-Perimeter College",
        "Georgian Court University",
        "Gerbers Akron Beauty School",
        "Germanna Community College",
        "Gettysburg College",
        "Glen Dow Academy of Hair Design",
        "Glen Oaks Community College",
        "Glendale Career College",
        "Glendale Community College",
        "Glenville State College",
        "Glitz School of Cosmetology",
        "Gnomon",
        "Goddard College",
        "Gods Bible School and College",
        "Gogebic Community College",
        "Golden Gate University-Los Angeles",
        "Golden Gate University-San Francisco",
        "Golden Gate University-Seattle",
        "Golden Gate University-Silicon Valley",
        "Golden West College",
        "Goldey-Beacom College",
        "Gonzaga University",
        "Good Samaritan College of Nursing and Health Science",
        "Goodwin College",
        "Gordon College",
        "Gordon Cooper Technology Center",
        "Gordon State College",
        "Gordon-Conwell Theological Seminary",
        "Goshen College",
        "Goshen School of Cosmetology",
        "Goucher College",
        "Gould's Academy",
        "Governors State University",
        "Grabber School of Hair Design",
        "Grace Christian University",
        "Grace College and Theological Seminary",
        "Grace College of Barbering - Greensboro",
        "Grace College of Divinity",
        "Grace International Beauty School",
        "Grace Mission University",
        "Grace School of Theology",
        "Graceland University - Independence",
        "Graceland University-Lamoni",
        "Graduate Theological Union",
        "Grady Health System Professional Schools",
        "Graham Hospital School of Nursing",
        "Grambling State University",
        "Grand Canyon University",
        "Grand Rapids Community College",
        "Grand River Technical School",
        "Grand Valley State University",
        "Grand View University",
        "Granite State College",
        "Grantham University",
        "Gratz College",
        "Grays Harbor College",
        "Grayson College",
        "Great Basin College",
        "Great Bay Community College",
        "Great Falls College Montana State University",
        "Great Lakes Christian College",
        "Great Lakes Truck Driving School",
        "Great Oaks Career Campuses",
        "Great Plains Technology Center",
        "Greater Altoona Career & Technology Center",
        "Greater Johnstown Career and Technology Center",
        "Greater Lowell Technical School",
        "Green Country Technology Center",
        "Green Mountain College",
        "Green River College",
        "Greene County Career and Technology Center",
        "Greene County Vocational School District",
        "Greenfield Community College",
        "Greensboro College",
        "Greenville Technical College",
        "Greenville University",
        "Grinnell College",
        "Grossmont College",
        "Grove City College",
        "Guam Community College",
        "Guilford College",
        "Guilford Technical Community College",
        "Gulf Coast State College",
        "Gupton Jones College of Funeral Service",
        "Gurnick Academy of Medical Arts",
        "Gustavus Adolphus College",
        "Guy's Shreveport Academy of Cosmetology Inc",
        "Gwinnett College",
        "Gwinnett College-Lilburn",
        "Gwinnett College-Marietta Campus",
        "Gwinnett College-Sandy Springs",
        "Gwinnett Technical College",
        "Gwynedd Mercy University",
        "H Councill Trenholm State Community College",
        "Hacienda La Puente Adult Education",
        "Hagerstown Community College",
        "Hair Academy II",
        "Hair Academy Inc-New Carrollton",
        "Hair Academy School of Barbering & Beauty",
        "Hair Expressions Academy",
        "Hair Professionals Career College",
        "Hair Professionals School of Cosmetology",
        "Halifax Community College",
        "Hallmark University",
        "Hamilton College",
        "Hamilton Technical College",
        "Hamline University",
        "Hampden-Sydney College",
        "Hampshire College",
        "Hampton University",
        "Hamrick School",
        "Hands on Therapy",
        "Hannah E Mullins School of Practical Nursing",
        "Hannibal-LaGrange University",
        "Hanover College",
        "Harcum College",
        "Harding School of Theology",
        "Harding University",
        "Hardin-Simmons University",
        "Harford Community College",
        "Harris School of Business-Cherry Hill Campus",
        "Harris School of Business-Danbury",
        "Harris School of Business-Dover Campus",
        "Harris School of Business-Linwood Campus",
        "Harris School of Business-Sanford Campus",
        "Harris School of Business-Upper Darby Campus",
        "Harris School of Business-Voorhees Campus",
        "Harris School of Business-Wilmington Campus",
        "Harrisburg Area Community College",
        "Harrisburg Area Community College-Gettysburg",
        "Harrisburg Area Community College-Lancaster",
        "Harrisburg Area Community College-Lebanon",
        "Harrisburg Area Community College-York",
        "Harrisburg University of Science and Technology",
        "Harris-Stowe State University",
        "Hartford Seminary",
        "Hartnell College",
        "Hartwick College",
        "Harvard University",
        "Harvey Mudd College",
        "Haskell Indian Nations University",
        "Hastings Beauty School",
        "Hastings College",
        "Haverford College",
        "Hawaii Community College",
        "Hawaii Medical College",
        "Hawaii Pacific University",
        "Hawkeye Community College",
        "Hays Academy of Hair Design",
        "Haywood Community College",
        "Hazard Community and Technical College",
        "Hazelden Betty Ford Graduate School of Addiction Studies",
        "Hazleton Area Career Center",
        "HCI College",
        "HCI College - Fort Lauderdale Campus",
        "Headlines Academy Inc",
        "Headmasters School of Hair Design",
        "Healing Arts Center",
        "Healing Hands School of Holistic Health",
        "Healing Mountain Massage School",
        "Healthcare Career College",
        "Hebrew College",
        "Hebrew Theological College",
        "Heidelberg University",
        "Helena College University of Montana",
        "Helene Fuld College of Nursing",
        "Hellenic College-Holy Cross Greek Orthodox School of Theology",
        "Helms College",
        "Henderson Community College",
        "Henderson State University",
        "Hendrix College",
        "Hennepin Technical College",
        "Henrico County-Saint Marys Hospital School of Practical Nursing",
        "Henry Ford College",
        "Heritage Bible College",
        "Heritage Christian University",
        "Heritage University",
        "Herkimer County BOCES-Practical Nursing Program",
        "Herkimer County Community College",
        "Herzing University-Akron",
        "Herzing University-Atlanta",
        "Herzing University-Birmingham",
        "Herzing University-Brookfield",
        "Herzing University-Kenner",
        "Herzing University-Kenosha",
        "Herzing University-Madison",
        "Herzing University-Minneapolis",
        "Herzing University-Toledo",
        "Herzing University-Winter Park",
        "Hesston College",
        "Hibbing Community College",
        "High Desert Medical College",
        "High Desert Medical College - Bakerfield",
        "High Plains Technology Center",
        "High Point University",
        "High Tech High Graduate School of Education",
        "Highland Community College",
        "Highlands College of Montana Tech",
        "Highline College",
        "Hilbert College",
        "Hill College",
        "Hillsborough Community College",
        "Hillsdale Beauty College",
        "Hillsdale College",
        "Hilltop Beauty School",
        "Hillyard Technical Center",
        "Hinds Community College",
        "Hinton Barber and Beauty College",
        "Hiram College",
        "Hiwassee College",
        "Hobart William Smith Colleges",
        "Hobe Sound Bible College",
        "Hocking College",
        "Hodges University",
        "Hofstra University",
        "Hohokus School of Trade and Technical Sciences",
        "Hollins University",
        "Holmes Community College",
        "Holy Apostles College and Seminary",
        "Holy Cross College",
        "Holy Family College",
        "Holy Family University",
        "Holy Name Medical Center-Sister Claire Tynan School of Nursing",
        "Holy Names University",
        "Holyoke Community College",
        "Homestead Schools",
        "Hondros College of Nursing",
        "Honolulu Community College",
        "Hood College",
        "Hood Theological Seminary",
        "Hope College",
        "Hope College of Arts and Sciences",
        "Hope International University",
        "Hopkinsville Community College",
        "Horizon University",
        "Horry-Georgetown Technical College",
        "Hoss Lee Academy",
        "Hot Springs Beauty College",
        "Houghton College",
        "Housatonic Community College",
        "House of Heavilin Beauty College-Academy of Beauty Professionals",
        "House of Heavilin Beauty College-Blue Springs",
        "House of Heavilin Beauty College-Kansas City",
        "House of Heavilin Beauty College-Raymore",
        "Houston Baptist University",
        "Houston Community College",
        "Houston Graduate School of Theology",
        "Houston International College Cardiotech Ultrasound School",
        "Houston Training School-Gulfgate",
        "Houston Training School-Main Campus",
        "Houston Training Schools-Gessner",
        "Howard College",
        "Howard Community College",
        "Howard Payne University",
        "Howard University",
        "HSHS St. John's Hospital School of Clinical Laboratory Science",
        "Hudson County Community College",
        "Hudson Valley Community College",
        "Huertas College",
        "Hult International Business School",
        "Humacao Community College",
        "Humboldt State University",
        "Humphreys College-Modesto",
        "Humphreys University-Stockton and Modesto Campuses",
        "Hunter Business School",
        "Huntingdon College",
        "Huntingdon County Career and Technology Center",
        "Huntington Junior College",
        "Huntington School of Beauty Culture",
        "Huntington University",
        "Huntington University of Health Sciences",
        "Huntsville Bible College",
        "Hussian College-Daymar College Bowling Green",
        "Hussian College-Daymar College Clarksville",
        "Hussian College-Daymar College Columbus",
        "Hussian College-Daymar College Murfreesboro",
        "Hussian College-Daymar College Nashville",
        "Hussian College-Los Angeles",
        "Hussian College-Philadelphia",
        "Husson University",
        "Huston-Tillotson University",
        "Hutchinson Community College",
        "IBMC College",
        "IBS School of Cosmetology and Massage",
        "Icahn School of Medicine at Mount Sinai",
        "ICOHS College",
        "ICPR Junior College-Arecibo",
        "ICPR Junior College-General Institutional",
        "ICPR Junior College-Manati",
        "ICPR Junior College-Mayaguez",
        "Idaho State University",
        "Ideal Beauty Academy",
        "IGlobal University",
        "Iliff School of Theology",
        "Ilisagvik College",
        "Illinois Central College",
        "Illinois College",
        "Illinois College of Optometry",
        "Illinois Media School",
        "Illinois Media School-Chicago Campus",
        "Illinois State University",
        "Illinois Valley Community College",
        "Illinois Wesleyan University",
        "Imagine-Paul Mitchell Partner School",
        "Immaculata University",
        "Immokalee Technical College",
        "Imperial Valley College",
        "Independence Community College",
        "Independence University",
        "Independent Training & Apprenticeship Program",
        "Indian Capital Technology Center-Muskogee",
        "Indian Capital Technology Center-Sallisaw",
        "Indian Capital Technology Center-Stilwell",
        "Indian Capital Technology Center-Tahlequah",
        "Indian Hills Community College",
        "Indian River State College",
        "Indiana County Technology Center",
        "Indiana State University",
        "Indiana University of Pennsylvania-Main Campus",
        "Indiana University-Bloomington",
        "Indiana University-East",
        "Indiana University-Kokomo",
        "Indiana University-Northwest",
        "Indiana University-Purdue University-Indianapolis",
        "Indiana University-South Bend",
        "Indiana University-Southeast",
        "Indiana Wellness College",
        "Indiana Wesleyan University-Marion",
        "Indiana Wesleyan University-National & Global",
        "Industrial Technical College",
        "Industrial Technical College - Naguabo",
        "Infinity College",
        "Inner State Beauty School",
        "Innovate Salon Academy",
        "Innovations Design Academy",
        "Institucion Chaviano de Mayaguez",
        "Instituto de Banca y Comercio Inc",
        "Instituto Educativo Premier",
        "Instituto Tecnologico de Puerto Rico-Recinto de Guayama",
        "Instituto Tecnologico de Puerto Rico-Recinto de Manati",
        "Instituto Tecnologico de Puerto Rico-Recinto de Ponce",
        "Instituto Tecnologico de Puerto Rico-Recinto de San Juan",
        "Integrity College of Health",
        "Intellitec College-Colorado Springs",
        "Intellitec College-Grand Junction",
        "Inter American University of Puerto Rico",
        "Interactive College of Technology",
        "InterCoast Colleges-Fairfield",
        "InterCoast Colleges-Rancho Cordova",
        "InterCoast Colleges-Riverside",
        "InterCoast Colleges-Santa Ana",
        "InterCoast Colleges-West Covina",
        "Interdenominational Theological Center",
        "International Academy",
        "International Academy of Style",
        "International Air and Hospitality Academy",
        "International Baptist College and Seminary",
        "International Barber College",
        "International Beauty College",
        "International Beauty Education Center",
        "International Beauty School 4",
        "International Business College",
        "International College of Beauty Arts & Sciences",
        "International College of Broadcasting",
        "International College of Cosmetology",
        "International Cosmetology Academy",
        "International Culinary Center-California",
        "International Hair and Barber Academy",
        "International Salon and Spa Academy",
        "International School of Beauty Inc",
        "International School of Cosmetology",
        "International School of Cosmetology-Toni & Guy Hairdressing Academy",
        "International School of Skin Nailcare & Massage Therapy",
        "International Sports Sciences Association",
        "International Technical College",
        "International Technological University",
        "International Training Careers",
        "Inver Hills Community College",
        "Iona College",
        "Iowa Central Community College",
        "Iowa Lakes Community College",
        "Iowa School of Beauty-Des Moines",
        "Iowa School of Beauty-Marshalltown",
        "Iowa School of Beauty-Ottumwa",
        "Iowa School of Beauty-Sioux City",
        "Iowa State University",
        "Iowa Wesleyan University",
        "Iowa Western Community College",
        "Irell & Manella Graduate School of Biological Sciences at City of Hope",
        "Irvine Valley College",
        "Isabella Graham Hart School of Practical Nursing",
        "Isothermal Community College",
        "Itasca Community College",
        "Itawamba Community College",
        "Ithaca College",
        "ITI Technical College",
        "IVAEM College",
        "Ivy Tech Community College",
        "IYRS School of Technology & Trades",
        "J D Academy of Salon and Spa",
        "J F Ingram State Technical College",
        "J Michael Harrold Beauty Academy",
        "J Renee College",
        "J Sargeant Reynolds Community College",
        "J. F. Drake State Community and Technical College",
        "Jackson College",
        "Jackson State Community College",
        "Jackson State University",
        "Jacksonville College-Main Campus",
        "Jacksonville State University",
        "Jacksonville University",
        "James A Rhodes State College",
        "James Madison University",
        "James Sprunt Community College",
        "Jamestown Business College",
        "Jamestown Business College -",
        "Jamestown Community College",
        "Jarvis Christian College",
        "JB's Hair Design and Barber College",
        "Jefferson College",
        "Jefferson Community and Technical College",
        "Jefferson Community College",
        "Jefferson County Dubois Area Vocational Technical Practical Nursing Program",
        "Jefferson Lewis BOCES-Practical Nursing Program",
        "Jefferson Regional Medical Center School of Nursing",
        "Jefferson State Community College",
        "Jenks Beauty College",
        "Jenny Lea Academy of Cosmetology",
        "Jersey College",
        "Jesuit School of Theology of Santa Clara University",
        "Jewish Theological Seminary of America",
        "JFK Muhlenberg Harold B. and Dorothy A. Snyder Schools-School of Imaging",
        "JFK Muhlenberg Harold B. and Dorothy A. Snyder Schools-School of Nursing",
        "Joe Kubert School of Cartoon and Graphic Art",
        "Joffrey Ballet School",
        "John A Gupton College",
        "John A Logan College",
        "John Amico School of Hair Design",
        "John Brown University",
        "John C Calhoun State Community College",
        "John Carroll University",
        "John D Rockefeller IV Career Center",
        "John F. Kennedy University",
        "John Jay Beauty College",
        "John Paul the Great Catholic University",
        "John Tyler Community College",
        "John Wesley International Barber and Beauty College",
        "John Wood Community College",
        "Johnny Matthew's Hairdressing Training School",
        "Johns Hopkins University",
        "Johnson & Wales University-Charlotte",
        "Johnson & Wales University-Denver",
        "Johnson & Wales University-North Miami",
        "Johnson & Wales University-Online",
        "Johnson & Wales University-Providence",
        "Johnson C Smith University",
        "Johnson College",
        "Johnson County Community College",
        "Johnson University",
        "Johnson University Florida",
        "Johnston Community College",
        "Jolie Hair and Beauty Academy-Hazleton",
        "Jolie Hair and Beauty Academy-Ludlow",
        "Jolie Hair and Beauty Academy-Northfield",
        "Jolie Hair and Beauty Academy-Wilkes-Barre",
        "Jolie Health & Beauty Academy-Cherry Hill",
        "Jolie Health and Beauty Academy-Turnersville",
        "Joliet Junior College",
        "Jones County Junior College",
        "Jose Maria Vargas University",
        "Josef's School of Hair Skin & Body-Fargo",
        "Josef's School of Hair Skin & Body-Grand Forks",
        "Joseph F McCloskey School of Nursing",
        "Joseph's College Cosmetology",
        "J's Barber College",
        "Judson College",
        "Judson University",
        "Jung Tao School of Classical Chinese Medicine",
        "Juniata College",
        "Jupiter Beauty Academy",
        "JZ Trend Academy Paul Mitchell Partner School",
        "K & G 5 Star Barber College",
        "Kaizen Beauty Academy",
        "Kalamazoo College",
        "Kalamazoo Valley Community College",
        "Kankakee Community College",
        "Kansas Christian College",
        "Kansas City Kansas Community College",
        "Kansas City University of Medicine and Biosciences",
        "Kansas City University of Medicine and Biosciences-Joplin",
        "Kansas State University",
        "Kansas Wesleyan University",
        "Kapiolani Community College",
        "Kaskaskia College",
        "Kauai Community College",
        "KCK Beauty & Barber Academy",
        "KC's School of Hair Design",
        "KD Conservatory College of Film and Dramatic Arts",
        "Kean University",
        "Keene Beauty Academy",
        "Keene State College",
        "Kehilath Yakov Rabbinical Seminary",
        "Keiser University -  Clearwater",
        "Keiser University - NPR",
        "Keiser University - Residential",
        "Keiser University- Miami",
        "Keiser University-Daytona",
        "Keiser University-Ft Lauderdale",
        "Keiser University-Ft Myers",
        "Keiser University-Jacksonville",
        "Keiser University-Lakeland",
        "Keiser University-Melbourne",
        "Keiser University-Orlando",
        "Keiser University-Pembroke Pines",
        "Keiser University-Port St Lucie",
        "Keiser University-Sarasota",
        "Keiser University-Tallahassee",
        "Keiser University-Tampa",
        "Keiser University-West Palm Beach",
        "Kellogg Community College",
        "Kennebec Valley Community College",
        "Kennesaw State University",
        "Kenneth Shuler School of Cosmetology and Nails-Columbia",
        "Kenneth Shuler School of Cosmetology-Columbia",
        "Kenneth Shuler School of Cosmetology-Florence",
        "Kenneth Shuler School of Cosmetology-Goose Creek",
        "Kenneth Shuler School of Cosmetology-Greenville",
        "Kenneth Shuler School of Cosmetology-North Augusta",
        "Kenneth Shuler School of Cosmetology-Rock Hill",
        "Kenneth Shuler School of Cosmetology-Spartanburg",
        "Kenny's Academy of Barbering",
        "Kenrick Glennon Seminary",
        "Kent State University at Ashtabula",
        "Kent State University at East Liverpool",
        "Kent State University at Geauga",
        "Kent State University at Kent",
        "Kent State University at Salem",
        "Kent State University at Stark",
        "Kent State University at Trumbull",
        "Kent State University at Tuscarawas",
        "Kentucky Christian University",
        "Kentucky Horseshoeing School",
        "Kentucky Mountain Bible College",
        "Kentucky State University",
        "Kentucky Wesleyan College",
        "Kenyon College",
        "Kettering College",
        "Kettering College - Ollie Davis Medical Arts and Education Center",
        "Kettering University",
        "Keuka College",
        "Keune Academy by 124",
        "Keweenaw Bay Ojibwa Community College",
        "Keweenaw Bay Ojibwa Community College - Wabanung Campus",
        "Keystone College",
        "Kiamichi Technology Center-McAlester",
        "Kilgore College",
        "King University",
        "King's College",
        "Kirksville Area Technical Center",
        "Kirkwood Community College",
        "Kirtland Community College",
        "Kishwaukee College",
        "Klamath Community College",
        "Knox College",
        "Knox County Career Center",
        "Kor Beauty Academy",
        "Kutztown University of Pennsylvania",
        "Kuyper College",
        "L3 Commercial Training Solutions Airline Academy",
        "La Belle Beauty Academy",
        "La Belle Beauty School",
        "La James College of Hairstyling and Cosmetology",
        "La James International College-Cedar Falls",
        "La James International College-Davenport",
        "La James International College-East Moline",
        "La James International College-Ft Dodge",
        "La James International College-Iowa City",
        "La James International College-Johnston",
        "La Roche University",
        "La Salle University",
        "La Sierra University",
        "Labette Community College",
        "Laboure College",
        "Lac Courte Oreilles Ojibwe College",
        "Lackawanna College",
        "Lafayette College",
        "LaGrange College",
        "Laguna College of Art and Design",
        "La'James International College",
        "Lake Area Technical College",
        "Lake Career and Technical Center",
        "Lake Erie College",
        "Lake Erie College of Osteopathic Medicine",
        "Lake Forest College",
        "Lake Forest Graduate School of Management",
        "Lake Land College",
        "Lake Michigan College",
        "Lake Region State College",
        "Lake Superior College",
        "Lake Superior State University",
        "Lake Tahoe Community College",
        "Lake Technical College",
        "Lakeland Community College",
        "Lakeland University",
        "Lakes Region Community College",
        "Lakeshore Technical College",
        "Lake-Sumter State College",
        "Lakeview College of Nursing",
        "Lakewood School of Therapeutic Massage",
        "Lamar Community College",
        "Lamar State College-Orange",
        "Lamar State College-Port Arthur",
        "Lamar University",
        "Lancaster Beauty School",
        "Lancaster Bible College",
        "Lancaster County Career and Technology Center",
        "Lancaster School of Cosmetology & Therapeutic Bodywork",
        "Lancaster Theological Seminary",
        "Lander University",
        "Landmark College",
        "Lane College",
        "Lane Community College",
        "Laney College",
        "Langston University",
        "Lanier Technical College",
        "Lansdale School of Business",
        "Lansdale School of Cosmetology Inc",
        "Lansing Community College",
        "Laramie County Community College",
        "Laredo Beauty College Inc",
        "Laredo CHI Academy Beauty School",
        "Laredo College",
        "Larry's Barber College",
        "Las Positas College",
        "Las Vegas College",
        "LaSalle Tech",
        "Lasell University",
        "Lassen Community College",
        "Latin Beauty Academy",
        "Laurus College",
        "Lawrence & Company College of Cosmetology",
        "Lawrence Memorial Hospital School of Nursing",
        "Lawrence Technological University",
        "Lawrence University",
        "Lawson State Community College",
        "LDS Business College",
        "Le Moyne College",
        "Le Moyne-Owen College",
        "Lebanon College of Cosmetology",
        "Lebanon County Area Vocational Technical School",
        "Lebanon Valley College",
        "Lee College",
        "Lee University",
        "Leech Lake Tribal College",
        "Lees-McRae College",
        "Leeward Community College",
        "Lehigh Carbon Community College",
        "Lehigh University",
        "Lehigh Valley Barber School",
        "Lenape Technical School Practical Nursing Program",
        "Lenoir Community College",
        "Lenoir-Rhyne University",
        "Leon Studio One School of Beauty Knowledge",
        "Leons Beauty School Inc",
        "Lesley University",
        "L'esprit Academy",
        "L'esprit Academy - Royal Oak",
        "Leston College",
        "LeTourneau University",
        "Levittown Beauty Academy",
        "Lewis & Clark College",
        "Lewis and Clark Community College",
        "Lewis University",
        "Lewis-Clark State College",
        "Lex La-Ray Technical Center",
        "Lexington Healing Arts Academy",
        "Lexington Theological Seminary",
        "Liberty Junior College",
        "Liberty University",
        "Liceo de Arte y Tecnologia",
        "Liceo de Arte-Dise-O y Comercio",
        "Life Chiropractic College West",
        "Life Pacific University",
        "Life Pacific University-Virginia",
        "Life University",
        "Lil Lou's Beauty and Barber College LLC",
        "LIM College",
        "Limestone College",
        "Lincoln Christian University",
        "Lincoln College",
        "Lincoln College of Technology-Columbia",
        "Lincoln College of Technology-Denver",
        "Lincoln College of Technology-Grand Prairie",
        "Lincoln College of Technology-Indianapolis",
        "Lincoln College of Technology-Marietta",
        "Lincoln College of Technology-Melrose Park",
        "Lincoln College of Technology-Nashville",
        "Lincoln Land Community College",
        "Lincoln Memorial University",
        "Lincoln Trail College",
        "Lincoln University",
        "Lindenwood University",
        "Lindenwood University-Belleville",
        "Lindsey Hopkins Technical College",
        "Lindsey Wilson College",
        "Linfield College-McMinnville Campus",
        "Linfield College-Online and Continuing Education",
        "Linfield College-School of Nursing",
        "Linn-Benton Community College",
        "Lipscomb University",
        "Little Big Horn College",
        "Little Priest Tribal College",
        "Little Priest Tribal College - Ho Chunk Centre",
        "Lively Technical College",
        "Living Arts College",
        "Livingstone College",
        "Lock Haven University",
        "Logan University",
        "Loma Linda University",
        "Lone Star College System",
        "Long Beach City College",
        "Long Island Beauty School-Hauppauge",
        "Long Island Beauty School-Hempstead",
        "Long Island University",
        "Longwood University",
        "Longy School of Music of Bard College",
        "Lorain County Community College",
        "Lorain County Joint Vocational School District",
        "Loraines Academy & Spa",
        "Loras College",
        "Lord Fairfax Community College",
        "Lorenzo Walker Technical College",
        "Los Angeles Academy of Figurative Art",
        "Los Angeles Center",
        "Los Angeles City College",
        "Los Angeles College of Music",
        "Los Angeles County College of Nursing and Allied Health",
        "Los Angeles Film School",
        "Los Angeles Harbor College",
        "Los Angeles Mission College",
        "Los Angeles ORT College-Los Angeles Campus",
        "Los Angeles Pacific University",
        "Los Angeles Pierce College",
        "Los Angeles Southwest College",
        "Los Angeles Trade Technical College",
        "Los Angeles Valley College",
        "Los Medanos College",
        "Louisburg College",
        "Louisiana Academy of Beauty",
        "Louisiana College",
        "Louisiana Delta Community College",
        "Louisiana State University and Agricultural & Mechanical College",
        "Louisiana State University Health Sciences Center-New Orleans",
        "Louisiana State University Health Sciences Center-Shreveport",
        "Louisiana State University-Alexandria",
        "Louisiana State University-Eunice",
        "Louisiana State University-Shreveport",
        "Louisiana Tech University",
        "Louisville Presbyterian Theological Seminary",
        "Lourdes University",
        "Love Beauty School Inc",
        "Lower Columbia College",
        "Loyola Marymount University",
        "Loyola University Chicago",
        "Loyola University Maryland",
        "Loyola University New Orleans",
        "Lu Ross Academy",
        "Lubbock Christian University",
        "Luckes Beauty Academy LLC",
        "Luna Community College",
        "Lurleen B Wallace Community College",
        "Luther College",
        "Luther Rice College & Seminary",
        "Luther Seminary",
        "Lutheran School of Nursing",
        "Lutheran School of Theology at Chicago",
        "Luzerne County Community College",
        "Lycoming College",
        "Lyle's College of  Beauty",
        "Lyle's College of Beauty",
        "Lynn University",
        "Lynnes Welding Training",
        "Lynnes Welding Training-Bismarck",
        "Lyon College",
        "Lytles Redwood Empire Beauty College Inc",
        "M J Murphy Beauty College of Mount Pleasant",
        "M State - Detroit Lakes Campus",
        "M State - Moorhead Campus",
        "M State - Wadena Campus",
        "M T Training Center",
        "Macalester College",
        "MacCormac College",
        "Machzikei Hadath Rabbinical College",
        "MacMurray College",
        "Macomb Community College",
        "Madison Adult Career Center",
        "Madison Area Technical College",
        "Madison Oneida BOCES-Practical Nursing Program",
        "Madisonville Community College",
        "Madonna University",
        "Magdalen College",
        "Magnolia College of Cosmetology",
        "Maharishi University of Management",
        "Mahoning County Career and Technical Center",
        "Maine College of Art",
        "Maine College of Health Professions",
        "Maine Maritime Academy",
        "Make-up Designory",
        "Malone University",
        "Manatee Technical College",
        "Manchester Community College",
        "Manchester University",
        "Mandalyn Academy",
        "Mandl School-The College of Allied Health",
        "Manhattan Area Technical College",
        "Manhattan Christian College",
        "Manhattan College",
        "Manhattan School of Computer Technology",
        "Manhattan School of Music",
        "Manhattanville College",
        "Manor College",
        "Mansfield University of Pennsylvania",
        "Manuel and Theresa's School of Hair Design",
        "Manuel and Theresa's School of Hair Design-Bryan",
        "Manuel and Theresa's School of Hair Design-Victoria",
        "Maple Springs Baptist Bible College and Seminary",
        "Maranatha Baptist University",
        "Margaret H Rollins School of Nursing at Beebe Medical Center",
        "Maria College of Albany",
        "Marian Health Careers Center-Los Angeles Campus",
        "Marian Health Careers Center-Van Nuys Campus",
        "Marian University",
        "Marietta College",
        "Marion S Whelan School of Nursing of Geneva General Hospital",
        "Marion Technical College",
        "Marist College",
        "Marketti Academy of Cosmetology",
        "Marlboro College",
        "Marlboro College Graduate & Professional Studies",
        "Marquette University",
        "Mars Hill University",
        "Marshall B Ketchum University",
        "Marshall University",
        "Marshalltown Community College",
        "Martin Community College",
        "Martin Luther College",
        "Martin Methodist College",
        "Martin University",
        "Martinsburg College",
        "Mary Baldwin University",
        "Marygrove College",
        "Maryland Beauty Academy of Essex",
        "Maryland University of Integrative Health",
        "Marymount California University",
        "Marymount California University - Lakeside Campus",
        "Marymount Manhattan College",
        "Marymount University",
        "Marymount University - Waterfront Campus",
        "Maryville College",
        "Maryville University of Saint Louis",
        "Marywood University",
        "Massachusetts Bay Community College",
        "Massachusetts College of Art and Design",
        "Massachusetts College of Liberal Arts",
        "Massachusetts Maritime Academy",
        "Massachusetts School of Barbering",
        "Massachusetts School of Law",
        "Massasoit Community College",
        "Master's Barber & Styling College",
        "Mauna Loa Helicopters",
        "Mayfield College",
        "Mayland Community College",
        "Maynard A. Traviss Technical College - Central Florida Aerospace Academy",
        "Mayo Clinic College of Medicine and Science",
        "Maysville Community and Technical College",
        "Mayville State University",
        "McCormick Theological Seminary",
        "McDaniel College",
        "McDowell Technical Community College",
        "McHenry County College",
        "McKendree University",
        "McLennan Community College",
        "McMurry University",
        "McNeese State University",
        "McPherson College",
        "MCPHS University",
        "M-DCPS The English Center",
        "MDT College of Health Sciences",
        "Meadville Lombard Theological School",
        "Mechon L'hoyroa",
        "Mech-Tech College",
        "Med Academy",
        "Medaille College",
        "Medical Allied Career Center",
        "Medical Career & Technical College",
        "Medical College of Wisconsin",
        "Medical Training College",
        "Medical University of South Carolina",
        "Medina County Career Center",
        "MedQuest College",
        "Medspa Academies",
        "Meharry Medical College",
        "Memorial Hospital School of Radiation Therapy Technology",
        "Memphis College of Art",
        "Memphis Theological Seminary",
        "Mendocino College",
        "Menlo College",
        "Merced College",
        "Mercer County Career Center",
        "Mercer County Community College",
        "Mercer County Technical Education Center",
        "Mercer University",
        "Mercy College",
        "Mercy College of Health Sciences",
        "Mercy College of Ohio",
        "Mercy Hospital School of Practical Nursing-Plantation General Hospital",
        "Mercyhurst University",
        "Mercyhurst University-North East Campus",
        "Mercy-St Luke's School of Radiologic Technology",
        "Meredith College",
        "Meredith Manor International Equestrian Center",
        "Meridian College",
        "Meridian Community College",
        "Meridian Technology Center",
        "Meridian University",
        "Merkaz Bnos-Business School",
        "Merrell University of Beauty Arts and Science",
        "Merrimack College",
        "Merritt College",
        "Merryfield School of Pet Grooming",
        "Mesa Community College",
        "Mesabi Range College",
        "Mesalands Community College",
        "Mesivta of Eastern Parkway-Yeshiva Zichron Meilech",
        "Mesivta Torah Vodaath Rabbinical Seminary",
        "Mesivtha Tifereth Jerusalem of America",
        "Messenger College",
        "Messiah University",
        "Methodist College",
        "Methodist Theological School in Ohio",
        "Methodist University",
        "Metro Beauty Academy",
        "Metro Business College-Cape Girardeau",
        "Metro Business College-Jefferson City",
        "Metro Business College-Rolla",
        "Metro Technology Centers",
        "Metropolitan College of New York",
        "Metropolitan Community College Area",
        "Metropolitan Community College-Kansas City",
        "Metropolitan State University",
        "Metropolitan State University of Denver",
        "Miami Ad School-Atlanta",
        "Miami Ad School-New York",
        "Miami Ad School-San Francisco",
        "Miami Ad School-Wynwood",
        "Miami Dade College",
        "Miami Lakes Educational Center and Technical College",
        "Miami Media School",
        "Miami Regional University",
        "Miami University-Hamilton",
        "Miami University-Middletown",
        "Miami University-Oxford",
        "Miami Valley Career Technology Center",
        "MIAT College of Technology",
        "Michaels School of Hair Design and Esthetics-Paul Mitchell Partner School",
        "Michigan Barber School Inc",
        "Michigan College of Beauty-Monroe",
        "Michigan College of Beauty-Troy",
        "Michigan School of Psychology",
        "Michigan State University",
        "Michigan State University-College of Law",
        "Michigan Technological University",
        "Mid Cities Barber College",
        "Mid Michigan College",
        "Mid-America Christian University",
        "Mid-America College of Funeral Service",
        "MidAmerica Nazarene University",
        "Mid-America Technology Center",
        "Mid-Atlantic Christian University",
        "Mid-Del Technology Center",
        "Middle Georgia State University",
        "Middle Tennessee School of Anesthesia Inc",
        "Middle Tennessee State University",
        "Middlebury College",
        "Middlesex Community College",
        "Middlesex County College",
        "Mid-EastCTC-Adult Education",
        "Midland College",
        "Midland University",
        "Midlands Technical College",
        "Mid-Plains Community College",
        "Mid-South Christian College",
        "Midstate College",
        "Mid-State Technical College",
        "Midway Paris Beauty School",
        "Midway University",
        "Midwest College of Oriental Medicine-Evanston",
        "Midwest College of Oriental Medicine-Racine",
        "Midwestern Baptist Theological Seminary",
        "Midwestern Career College",
        "Midwestern State University",
        "Midwestern University-Downers Grove",
        "Midwestern University-Glendale",
        "Midwives College of Utah",
        "Mifflin County Academy of Science and Technology",
        "Mildred Elley School-Albany Campus",
        "Mildred Elley-New York Campus",
        "Mildred Elley-Pittsfield Campus",
        "Miles College",
        "Miles Community College",
        "Millennia Atlantic University",
        "Millersville University of Pennsylvania",
        "Milligan University",
        "Millikin University",
        "Mills College",
        "Millsaps College",
        "Milwaukee Area Technical College",
        "Milwaukee Career College",
        "Milwaukee School of Engineering",
        "Mineral Area College",
        "Mineral County Vocational Technical Center",
        "Mingo Extended Learning Center",
        "Minneapolis Business College",
        "Minneapolis College of Art and Design",
        "Minneapolis Community and Technical College",
        "Minnesota School of Cosmetology-Plymouth Campus",
        "Minnesota School of Cosmetology-Woodbury Campus",
        "Minnesota State College Southeast",
        "Minnesota State CollegeSoutheast-Red Wing Campus",
        "Minnesota State Community and Technical College",
        "Minnesota State University Moorhead",
        "Minnesota State University-Mankato",
        "Minnesota West Community and Technical College",
        "Minot State University",
        "MiraCosta College",
        "MiraCosta College - San Elijo Campus",
        "Misericordia University",
        "Missio Theological Seminary",
        "Mission College",
        "Mississippi College",
        "Mississippi College of Beauty Culture",
        "Mississippi Delta Community College",
        "Mississippi Gulf Coast Community College",
        "Mississippi State University",
        "Mississippi University for Women",
        "Mississippi Valley State University",
        "Missouri Baptist University",
        "Missouri College of Cosmetology North",
        "Missouri Southern State University",
        "Missouri State University-Springfield",
        "Missouri State University-West Plains",
        "Missouri University of Science and Technology",
        "Missouri Valley College",
        "Missouri Western State University",
        "Mitchell College",
        "Mitchell Community College",
        "Mitchell Hamline School of Law",
        "Mitchells Academy",
        "Mitsu Sato Hair Academy",
        "MKG Beauty & Business",
        "Moberly Area Community College",
        "Mobile Technical Training",
        "Model College of Hair Design",
        "Modern Beauty Academy",
        "Modern Technology School",
        "Modern Welding School",
        "Modesto Junior College",
        "Mohave Community College",
        "Mohawk Valley Community College",
        "Moler Barber College",
        "Moler Hollywood Beauty Academy",
        "Moler-Pickens Beauty Academy",
        "Molloy College",
        "Monmouth College",
        "Monmouth County Vocational School District",
        "Monmouth University",
        "Monmouth University -  Monmouth Park Corporate Center",
        "Monongalia County Technical Education Center",
        "Monroe 2 Orleans BOCES-Center for Workforce Development",
        "Monroe College",
        "Monroe Community College",
        "Monroe County Community College",
        "Montana Academy of Salons",
        "Montana Bible College",
        "Montana State University",
        "Montana State University Billings",
        "Montana State University-Northern",
        "Montana Technological University",
        "Montcalm Community College",
        "Montclair State University",
        "Monteclaro Escuela de Hoteleria y Artes Culinarias",
        "Montefiore School of Nursing",
        "Monterey Peninsula College",
        "Montessori Casa International",
        "Montessori Education Center of the Rockies",
        "Montgomery Beauty School",
        "Montgomery College",
        "Montgomery Community College",
        "Montgomery County Community College",
        "Montreat College",
        "Montserrat College of Art",
        "Monty Tech",
        "Moody Theological Seminary and Graduate School--Michigan",
        "Moore Career College",
        "Moore College of Art and Design",
        "Moore Norman Technology Center",
        "Moorpark College",
        "Moraine Park Technical College",
        "Moraine Valley Community College",
        "Moravian College",
        "Morehead State University",
        "Morehouse College",
        "Morehouse School of Medicine",
        "Moreno Valley College",
        "Morgan Community College",
        "Morgan State University",
        "Morgantown Beauty College Inc",
        "Morningside College",
        "Morris College",
        "Morris County Vocational School District",
        "Morton College",
        "Motlow State Community College",
        "Mott Community College",
        "Mount Aloysius College",
        "Mount Angel Seminary",
        "Mount Carmel College of Nursing",
        "Mount Carmel College of Nursing - Fairfield",
        "Mount Holyoke College",
        "Mount Marty College",
        "Mount Mary University",
        "Mount Mercy University",
        "Mount Saint Joseph University",
        "Mount Saint Mary College",
        "Mount Saint Mary's University",
        "Mount Saint Mary's University - Doheny Campus",
        "Mount St. Mary's University",
        "Mount Vernon Nazarene University",
        "Mount Wachusett Community College",
        "Mountain Empire Community College",
        "Mountain State College",
        "Mountain State School of Massage",
        "Mountain View College",
        "Mountainland Technical College",
        "Mountwest Community and Technical College",
        "Mr John's School of Cosmetology & Nails-Jacksonville",
        "Mr John's School of Cosmetology Esthetics & Nails-Decatur",
        "Mr Leon's School of Hair Design-Lewiston",
        "Mr Leon's School of Hair Design-Moscow",
        "Mr Wayne's School of Unisex Hair Design",
        "Mt Hood Community College",
        "Mt San Antonio College",
        "Mt San Jacinto Community College District",
        "Mt Sierra College",
        "Mt. Diablo Adult Education-Mt. Diablo USD",
        "MTI Business College Inc",
        "MTI College",
        "Muhlenberg College",
        "Multnomah University",
        "Murray State College",
        "Murray State University",
        "Muskegon Community College",
        "Muskingum University",
        "My Beauty & Barber College",
        "My Le's Beauty College",
        "MyComputerCareer.com",
        "MyComputerCareer.edu-Columbus",
        "MyComputerCareer.edu-Raleigh",
        "Myotherapy College of Utah",
        "Mystros Barber Academy",
        "Napa Valley College",
        "Naropa University",
        "Nash Community College",
        "Nashotah House",
        "Nashua Community College",
        "Nashville College of Medical Careers",
        "Nashville State Community College",
        "Nassau Community College",
        "National American University-Albuquerque West",
        "National American University-Austin",
        "National American University-Bellevue",
        "National American University-Career Point College Teachout Site",
        "National American University-Centennial",
        "National American University-Colorado Springs South",
        "National American University-Ellsworth AFB Extension",
        "National American University-Garden City",
        "National American University-Georgetown",
        "National American University-Harold D. Buckingham Graduate School",
        "National American University-Independence",
        "National American University-Indianapolis",
        "National American University-Killeen",
        "National American University-Mesquite",
        "National American University-Overland Park",
        "National American University-Rapid City",
        "National American University-Richardson",
        "National American University-Rochester",
        "National American University-Roseville",
        "National American University-Sioux Falls",
        "National American University-Tulsa",
        "National American University-Wichita",
        "National Aviation Academy of New England",
        "National Aviation Academy of Tampa Bay",
        "National Beauty College",
        "National Career College",
        "National Career Education",
        "National College-Nashville",
        "National Conservatory of Dramatic Arts",
        "National Louis University",
        "National Paralegal College",
        "National Park College",
        "National Polytechnic College",
        "National Tractor Trailer School Inc-Buffalo",
        "National Tractor Trailer School Inc-Liverpool",
        "National University",
        "National University College",
        "National University College - Mayaguez Campus",
        "National University College-Arecibo",
        "National University College-Caguas",
        "National University College-Ponce",
        "National University College-Rio Grande",
        "National University of Health Sciences",
        "National University of Natural Medicine",
        "Natural Images Beauty College",
        "Naugatuck Valley Community College",
        "Navajo Technical University",
        "Naval Postgraduate School",
        "Navarro College",
        "Nazarene Bible College",
        "Nazarene Theological Seminary",
        "Nazareth College",
        "Nebraska Christian College of Hope International University",
        "Nebraska College of Technical Agriculture",
        "Nebraska Indian Community College",
        "Nebraska Methodist College of Nursing & Allied Health",
        "Nebraska Wesleyan University",
        "Neecee's Barber College",
        "Neighborhood Playhouse School of the Theater",
        "Neilson Beauty College",
        "Neosho Beauty College",
        "Neosho County Community College",
        "Ner Israel Rabbinical College",
        "Networks Barber College",
        "Neumann University",
        "Neumont College of Computer Science",
        "Nevada Regional Technical Center",
        "Nevada State College",
        "New Age Training",
        "New Beginning College of Cosmetology",
        "New Beginnings Beauty Academy",
        "New Brunswick Theological Seminary",
        "New Castle School of Trades",
        "New Castle School of Trades - East Liverpool Campus",
        "New College of Florida",
        "New Concept Massage and Beauty School",
        "New Dimensions Beauty Academy Inc",
        "New Dimensions School of Hair Design",
        "New England College",
        "New England College of Business and Finance",
        "New England College of Optometry",
        "New England Hair Academy",
        "New England Law-Boston",
        "New England School of Hair Design",
        "New England School of Photography",
        "New England Tractor Trailer Training School of Connecticut",
        "New England Tractor Trailer Training School of CT-Bridgeport",
        "New England Tractor Trailer Training School of Massachusetts",
        "New England Tractor Trailer Training School of Rhode Island",
        "New Hope Christian College-Eugene",
        "New Jersey City University",
        "New Mexico Highlands University",
        "New Mexico Junior College",
        "New Mexico State University-Alamogordo",
        "New Mexico State University-Carlsbad",
        "New Mexico State University-Dona Ana",
        "New Mexico State University-Grants",
        "New Mexico State University-Main Campus",
        "New Orleans Baptist Theological Seminary",
        "New River Community and Technical College",
        "New River Community College",
        "New Saint Andrews College",
        "New Tyler Barber College Inc",
        "New York Academy of Art",
        "New York Chiropractic College",
        "New York College of Health Professions",
        "New York College of Podiatric Medicine",
        "New York College of Traditional Chinese Medicine",
        "New York Conservatory for Dramatic Arts",
        "New York Film Academy",
        "New York Law School",
        "New York Medical Career Training Center",
        "New York Medical College",
        "New York School for Medical and Dental Assistants",
        "New York School of Esthetics & Day Spa",
        "New York School of Interior Design",
        "New York Theological Seminary",
        "New York University",
        "Newberry College",
        "Newberry School of Beauty",
        "Newbury College",
        "Newman University",
        "Newschool of Architecture and Design",
        "NHTI-Concord's Community College",
        "Niagara County Community College",
        "Niagara University",
        "Nicholls State University",
        "Nichols College",
        "Nicolet Area Technical College",
        "Nightingale College",
        "Niles School of Cosmetology",
        "No Grease Barber School",
        "Norco College",
        "Norfolk State University",
        "Normandale Community College",
        "North Adrian's College of Beauty Inc",
        "North American Trade Schools",
        "North American University",
        "North Arkansas College",
        "North Bennet Street School",
        "North Carolina A & T State University",
        "North Carolina Central University",
        "North Carolina State University at Raleigh",
        "North Carolina Wesleyan College",
        "North Central College",
        "North Central Kansas Technical College",
        "North Central Michigan College",
        "North Central Missouri College",
        "North Central State College",
        "North Central Texas College",
        "North Central University",
        "North Country Community College",
        "North Dakota State College of Science",
        "North Dakota State University-Main Campus",
        "North Florida College",
        "North Florida Technical College",
        "North Georgia Technical College",
        "North Greenville University",
        "North Hennepin Community College",
        "North Idaho College",
        "North Iowa Area Community College",
        "North Lake College",
        "North Park University",
        "North Seattle College",
        "North Shore Community College",
        "Northampton County Area Community College",
        "Northampton County Area Community College-Monroe",
        "Northcentral Technical College",
        "Northcentral University",
        "Northcoast Medical Training Academy",
        "Northeast Alabama Community College",
        "Northeast Community College",
        "Northeast Iowa Community College",
        "Northeast Lakeview College",
        "Northeast Mississippi Community College",
        "Northeast Ohio Medical University",
        "Northeast State Community College",
        "Northeast Technology Center-Afton",
        "Northeast Technology Center-Claremore",
        "Northeast Technology Center-Kansas",
        "Northeast Technology Center-Pryor",
        "Northeast Texas Community College",
        "Northeast Wisconsin Technical College",
        "Northeastern Illinois University",
        "Northeastern Junior College",
        "Northeastern Oklahoma A&M College",
        "Northeastern Seminary",
        "Northeastern State University",
        "Northeastern Technical College",
        "Northeastern University",
        "Northeastern University Lifelong Learning Network",
        "Northern Arizona University",
        "Northern Baptist Theological Seminary",
        "Northern Essex Community College",
        "Northern Illinois University",
        "Northern Kentucky University",
        "Northern Maine Community College",
        "Northern Marianas College",
        "Northern Michigan University",
        "Northern New Mexico College",
        "Northern Oklahoma College",
        "Northern State University",
        "Northern Technical College",
        "Northern Tier Career Center",
        "Northern Vermont University",
        "Northern Vermont University - Lyndon",
        "Northern Vermont University - Northern Essex Community College",
        "Northern Virginia Community College",
        "Northern Virginia School of Therapeutic Massage",
        "Northern Wyoming Community College District",
        "Northland College",
        "Northland Community and Technical College",
        "Northland Community and Technical College - East Grand Forks",
        "Northland Pioneer College",
        "Northpoint Bible College",
        "Northshore Technical Community College",
        "NorthShore University HealthSystem School of Nurse Anesthesia",
        "NorthWest Arkansas Community College",
        "Northwest Career College",
        "Northwest Christian University",
        "Northwest College",
        "Northwest College of Art & Design",
        "North-West College-Anaheim",
        "Northwest College-Beaverton",
        "Northwest College-Clackamas",
        "Northwest College-Eugene",
        "North-West College-Glendale",
        "Northwest College-Hillsboro",
        "North-West College-Long Beach",
        "Northwest College-Medford",
        "North-West College-Pomona",
        "North-West College-Riverside",
        "Northwest College-Tualatin",
        "North-West College-Van Nuys",
        "North-West College-West Covina",
        "Northwest Educational Center",
        "Northwest Florida State College",
        "Northwest HVAC/R Training Center",
        "Northwest Indian College",
        "Northwest Iowa Community College",
        "Northwest Kansas Technical College",
        "Northwest Louisiana Technical College",
        "Northwest Mississippi Community College",
        "Northwest Missouri State University",
        "Northwest Nazarene University",
        "Northwest School of Wooden Boat Building",
        "Northwest State Community College",
        "Northwest Suburban College",
        "Northwest Technical College",
        "Northwest Technology Center-Alva",
        "Northwest Technology Center-Fairview",
        "Northwest University",
        "Northwest University-College of Adult and Professional Studies",
        "Northwest Vista College",
        "Northwestern College",
        "Northwestern Connecticut Community College",
        "Northwestern Health Sciences University",
        "Northwestern Michigan College",
        "Northwestern Oklahoma State University",
        "Northwestern State University of Louisiana",
        "Northwestern University",
        "Northwest-Shoals Community College",
        "Northwood University",
        "Norwalk Community College",
        "Norwich University",
        "Nossi College of Art",
        "Notre Dame College",
        "Notre Dame de Namur University",
        "Notre Dame of Maryland University",
        "Nova Academy of Cosmetology",
        "Nova College de Puerto Rico",
        "Nova Southeastern University",
        "NTMA Training Centers of Southern California",
        "Nueta Hidatsa Sahnish College",
        "Nunez Community College",
        "Nuvo College of Cosmetology",
        "Nyack College",
        "O C Collins Career Center",
        "Oak Hills Christian College",
        "Oakland City University",
        "Oakland Community College",
        "Oakland University",
        "Oakton Community College",
        "Oakwood University",
        "Oberlin College",
        "Oblate School of Theology",
        "Occidental College",
        "Ocean Corporation",
        "Ocean County College",
        "Ocean County Vocational-Technical School",
        "Oconee Fall Line Technical College",
        "Odessa College",
        "Oehrlein School of Cosmetology",
        "Ogden-Weber Technical College",
        "Ogeechee Technical College",
        "Oglala Lakota College",
        "Ogle School Hair Skin Nails-Arlington",
        "Ogle School Hair Skin Nails-Dallas",
        "Ogle School Hair Skin Nails-Denton",
        "Ogle School Hair Skin Nails-Ft Worth",
        "Ogle School Hair Skin Nails-Hurst",
        "Ogle School Hair Skin Nails-North Dallas",
        "Ogle School Hair Skin Nails-San Antonio",
        "Ogle School Hair Skin Nails-Stafford",
        "Oglethorpe University",
        "Ohio Business College-Sandusky",
        "Ohio Business College-Sheffield",
        "Ohio Christian University",
        "Ohio Dominican University",
        "Ohio Media School-Cincinnati",
        "Ohio Media School-Valley View",
        "Ohio Medical Career College",
        "Ohio Northern University",
        "Ohio State Beauty Academy",
        "Ohio State College of Barber Styling",
        "Ohio State School of Cosmetology",
        "Ohio State School of Cosmetology-Canal Winchester",
        "Ohio State School of Cosmetology-Heath",
        "Ohio State University-Lima Campus",
        "Ohio State University-Main Campus",
        "Ohio State University-Mansfield Campus",
        "Ohio State University-Marion Campus",
        "Ohio State University-Newark Campus",
        "Ohio Technical Center at Vantage Career Center",
        "Ohio Technical College",
        "Ohio University-Chillicothe Campus",
        "Ohio University-Eastern Campus",
        "Ohio University-Lancaster Campus",
        "Ohio University-Main Campus",
        "Ohio University-Southern Campus",
        "Ohio University-Zanesville Campus",
        "Ohio Valley College of Technology",
        "Ohio Valley Hospital School of Nursing",
        "Ohio Valley University",
        "Ohio Wesleyan University",
        "Ohlone College",
        "Ohr Hameir Theological Seminary",
        "Oikos University",
        "Okaloosa Technical College",
        "Oklahoma Baptist University",
        "Oklahoma Christian University",
        "Oklahoma City Community College",
        "Oklahoma City University",
        "Oklahoma Panhandle State University",
        "Oklahoma State University Center for Health Sciences",
        "Oklahoma State University-Main Campus",
        "Oklahoma State University-Oklahoma City",
        "Oklahoma Technical College",
        "Oklahoma Wesleyan University",
        "Old Dominion University",
        "Old Town Barber College-Wichita",
        "Oliver Finley Academy of Cosmetology",
        "Olivet College",
        "Olivet Nazarene University",
        "Olney Central College",
        "Olympian Academy of Cosmetology",
        "Olympic College",
        "Omega Graduate School",
        "Omega Studios' School of Applied Recording Arts & Sciences",
        "Onondaga Community College",
        "Onondaga Cortland Madison BOCES",
        "Onondaga School of Therapeutic Massage-Rochester",
        "Onondaga School of Therapeutic Massage-Syracuse",
        "Opelousas School of Cosmetology",
        "Oral Roberts University",
        "Orange Coast College",
        "Orange County Community College",
        "Orange Technical College-Mid Florida Campus",
        "Orange Technical College-Orlando Campus",
        "Orange Technical College-Westside Campus",
        "Orange Technical College-Winter Park Campus",
        "Orange Ulster BOCES-Practical Nursing Program",
        "Orangeburg Calhoun Technical College",
        "Oregon Coast Community College",
        "Oregon College of Art and Craft",
        "Oregon College of Oriental Medicine",
        "Oregon Health & Science University",
        "Oregon State University",
        "Oregon State University-Cascades Campus",
        "Orleans Niagara BOCES-Practical Nursing Program",
        "Orleans Technical College",
        "Orlo School of Hair Design and Cosmetology",
        "Osceola Technical College",
        "Osceola Technical College - Poinciana Campus",
        "Osceola Technical College - St. Cloud Campus",
        "Oswego County BOCES",
        "Otero Junior College",
        "Otero Junior College - East School",
        "Otero Junior College - Fort Lyon Colorado Coalition for the Homeless",
        "Otero Junior College - La Junta High School",
        "Otis College of Art and Design",
        "Otsego Area BOCES-Practical Nursing Program",
        "Ottawa University-Kansas City",
        "Ottawa University-Milwaukee",
        "Ottawa University-Online",
        "Ottawa University-Ottawa",
        "Ottawa University-Phoenix",
        "Ottawa University-Surprise",
        "Otterbein University",
        "Ouachita Baptist University",
        "Our Lady of the Lake University",
        "Owens Community College",
        "Owensboro Community and Technical College",
        "Oxford Academy of Hair Design Inc",
        "Oxnard College",
        "Ozark Christian College",
        "Ozarka College",
        "Ozarks Technical Community College",
        "P B Cosmetology Education Center",
        "P C Age-Jersey City",
        "P&A Scholars Beauty School",
        "Pace University",
        "Pacific Bible College",
        "Pacific College",
        "Pacific College of Health Science-Chicago",
        "Pacific College of Oriental Medicine-New York",
        "Pacific College of Oriental Medicine-San Diego",
        "Pacific Islands University",
        "Pacific Lutheran University",
        "Pacific Northwest College of Art",
        "Pacific Northwest University of Health Sciences",
        "Pacific Oaks College",
        "Pacific Rim Christian University",
        "Pacific School of Religion",
        "Pacific States University",
        "Pacific Union College",
        "Pacific University",
        "Paier College of Art Inc",
        "Paine College",
        "Palace Beauty College",
        "Palau Community College",
        "Palladium Technical Academy Inc",
        "Palm Beach Academy of Health & Beauty",
        "Palm Beach Atlantic University",
        "Palm Beach State College",
        "Palmer College of Chiropractic",
        "Palmetto Beauty School",
        "Palo Alto College",
        "Palo Alto University",
        "Palo Verde College",
        "Palomar College",
        "Pamlico Community College",
        "Panache Academy of Beauty",
        "Panola College",
        "Paradise Valley Community College",
        "Paramount Beauty Academy",
        "Pardee RAND Graduate School",
        "Paris Junior College",
        "Parisian Beauty School",
        "Park Place Premier Barber School",
        "Park University",
        "Parker University",
        "Parkland College",
        "Paroba College of Cosmetology",
        "Pasadena City College",
        "Pasco-Hernando State College",
        "Passaic County Community College",
        "Pat Goins Benton Road Beauty School",
        "Patrick Henry Community College",
        "Paul D Camp Community College",
        "Paul Mitchell the School",
        "Paul Quinn College",
        "Paul Smiths College of Arts and Science",
        "Payne Theological Seminary",
        "PC AGE-Metropark",
        "PCCenter",
        "PCI Academy-Ames",
        "PCI Academy-Plymouth",
        "PCI College",
        "PCI Health Training Center",
        "Pearl River Community College",
        "Pearl River Community College-Forrest County Center",
        "Peirce College",
        "Pellissippi State Community College",
        "Peloton College",
        "Peninsula College",
        "Penn Commercial Business/Technical School",
        "Pennco Tech-Blackwood",
        "Pennco Tech-Bristol",
        "Pennsylvania Academy of the Fine Arts",
        "Pennsylvania College of Art and Design",
        "Pennsylvania College of Health Sciences",
        "Pennsylvania College of Technology",
        "Pennsylvania Gunsmith School",
        "Pennsylvania Highlands Community College",
        "Pennsylvania State University",
        "Penrose Academy",
        "Pensacola School of Massage Therapy & Health Careers",
        "Pensacola State College",
        "Penta County Joint Vocational School",
        "Pentecostal Theological Seminary",
        "Pepperdine University",
        "Peru State College",
        "Pets Playground Grooming School",
        "Pfeiffer University",
        "Phagans Beauty College",
        "Phagans Central Oregon Beauty College",
        "Phagans Grants Pass College of Beauty",
        "Phagans Medford Beauty School",
        "Phagans School of Beauty",
        "Phagans School of Hair Design",
        "Philadelphia College of Osteopathic Medicine",
        "Philadelphia Technician Training",
        "Philander Smith College",
        "Phillips Community College of the University of Arkansas",
        "Phillips School of Nursing at Mount Sinai Beth Israel",
        "Phillips Theological Seminary",
        "Phipps Academy of Barbering",
        "Phoenix College",
        "Phoenix Seminary",
        "Pickaway Ross Joint Vocational School District",
        "Pickens Technical College",
        "Piedmont College",
        "Piedmont Community College",
        "Piedmont International University",
        "Piedmont Technical College",
        "Piedmont Virginia Community College",
        "Pierce College-Fort Steilacoom",
        "Pierce College-Puyallup",
        "Pierpont Community and Technical College",
        "Pike County Joint Vocational School District",
        "Pike-Lincoln Technical Center",
        "Pikes Peak Community College",
        "Pillar College",
        "Pima Community College",
        "Pine Manor College",
        "Pine Technical & Community College",
        "Pinellas Technical College-Clearwater",
        "Pinellas Technical College-St. Petersburg",
        "Pineville Beauty School",
        "Pioneer Career and Technology Center",
        "Pioneer Pacific College",
        "Pioneer Technology Center",
        "Pipo Academy of Hair Design",
        "Pitt Community College",
        "Pittsburg State University",
        "Pittsburgh Technical College",
        "Pittsburgh Theological Seminary",
        "Pitzer College",
        "Pivot Point Academy",
        "PJ's College of Cosmetology- Brownsburg",
        "PJ's College of Cosmetology- Greenfield",
        "PJ's College of Cosmetology- Indianapolis",
        "PJ's College of Cosmetology- Jeffersonville",
        "PJ's College of Cosmetology- Louisville",
        "PJ's College of Cosmetology- Muncie",
        "PJ's College of Cosmetology- Plainfield",
        "PJ's College of Cosmetology-Bowling Green",
        "PJ's College of Cosmetology-Clarksville",
        "PJ's College of Cosmetology-Crestwood",
        "PJ's College of Cosmetology-Glasgow",
        "PJ's College of Cosmetology-Richmond",
        "Platt College",
        "Platt College-Anaheim",
        "Platt College-Aurora",
        "Platt College-Edge Tech Academy",
        "Platt College-Lawton",
        "Platt College-Los Angeles",
        "Platt College-McCann-Allentown",
        "Platt College-McCann-Lewisburg",
        "Platt College-McCann-Monroe",
        "Platt College-Miller-Motte Technical-Augusta",
        "Platt College-Miller-Motte Technical-Charleston",
        "Platt College-Miller-Motte Technical-Chattanooga",
        "Platt College-Miller-Motte Technical-Columbus",
        "Platt College-Miller-Motte Technical-Conway",
        "Platt College-Miller-Motte Technical-Macon",
        "Platt College-Miller-Motte-Cary",
        "Platt College-Miller-Motte-Fayetteville",
        "Platt College-Miller-Motte-Jacksonville",
        "Platt College-Miller-Motte-Raleigh",
        "Platt College-Miller-Motte-Wilmington",
        "Platt College-Moore",
        "Platt College-OKC-Memorial",
        "Platt College-Ontario",
        "Platt College-Riverside",
        "Platt College-San Diego",
        "Platt College-STVT-Corpus Christi",
        "Platt College-STVT-McAllen",
        "Platt College-STVT-San Antonio",
        "Plaza College",
        "Plymouth State University",
        "Point Loma Nazarene University",
        "Point Park University",
        "Point University",
        "Polaris Career Center",
        "Polk State College",
        "Polytech Adult Education",
        "Polytechnic University of Puerto Rico-Miami",
        "Polytechnic University of Puerto Rico-Orlando",
        "Pomeroy College of Nursing at Crouse Hospital",
        "Pomona College",
        "Pomona Unified School District Adult and Career Education",
        "Ponca City Beauty College",
        "Ponce Health Sciences University",
        "Ponce Health Sciences University-Centro Universitario de San Juan",
        "Ponce Paramedical College Inc",
        "Pontifical Catholic University of Puerto Rico-Arecibo",
        "Pontifical Catholic University of Puerto Rico-Mayaguez",
        "Pontifical Catholic University of Puerto Rico-Ponce",
        "Pontifical College Josephinum",
        "Pontifical Faculty of the Immaculate Conception at the Dominican House of Studies",
        "Pontotoc Technology Center",
        "Pope St John XXIII National Seminary",
        "Poplar Bluff Technical Career Center",
        "Port Huron Cosmetology College",
        "Portage Lakes Career Center",
        "Porterville College",
        "Portland Actors Conservatory",
        "Portland Community College",
        "Portland State University",
        "Post University",
        "Potomac State College of West Virginia University",
        "PPG Technical College",
        "Prairie State College",
        "Prairie View A & M University",
        "Pratt Community College",
        "Premiere Career College",
        "Preparing People Barber Styling College",
        "Presbyterian College",
        "Presbyterian Theological Seminary in America",
        "Prescott College",
        "Presentation College",
        "Presidio Graduate School",
        "Prestige Health & Beauty Sciences Academy",
        "Prince George's Community College",
        "Princess Beauty School",
        "Princeton Theological Seminary",
        "Princeton University",
        "Principia College",
        "Pro Way Hair School",
        "Professional Academy of Cosmetology",
        "Professional Cosmetology Education Center",
        "Professional Golfers Career College",
        "Professional Technical Institution",
        "Professional's Choice Hair Design Academy",
        "Protege Academy",
        "Providence Christian College",
        "Providence College",
        "Provo College",
        "Pueblo Community College",
        "Purdue University - Purdue Polytechnic Anderson",
        "Purdue University - Purdue Polytechnic Columbus",
        "Purdue University - Purdue Polytechnic Indianapolis",
        "Purdue University - Purdue Polytechnic Kokomo",
        "Purdue University - Purdue Polytechnic Lafayette",
        "Purdue University - Purdue Polytechnic New Albany",
        "Purdue University - Purdue Polytechnic Richmond",
        "Purdue University - Purdue Polytechnic South Bend",
        "Purdue University - Purdue Polytechnic Vincennes",
        "Purdue University Fort Wayne",
        "Purdue University Global",
        "Purdue University Global-Augusta",
        "Purdue University Global-Cedar Falls",
        "Purdue University Global-Cedar Rapids",
        "Purdue University Global-Davenport",
        "Purdue University Global-Des Moines",
        "Purdue University Global-Hagerstown",
        "Purdue University Global-Lewiston",
        "Purdue University Global-Lincoln",
        "Purdue University Global-Mason City",
        "Purdue University Global-Milwaukee",
        "Purdue University Global-Omaha",
        "Purdue University Global-St. Louis",
        "Purdue University Northwest",
        "Purdue University-Main Campus",
        "Pure Aesthetics Natural Skincare School",
        "Putnam Career and Technical Center",
        "Quality Technical and Beauty College",
        "Queen City College",
        "Queens University of Charlotte",
        "Quest College",
        "Quincy College",
        "Quincy University",
        "Quinebaug Valley Community College",
        "Quinnipiac University",
        "Quinsigamond Community College",
        "Rabbi Jacob Joseph School",
        "Rabbinical Academy Mesivta Rabbi Chaim Berlin",
        "Rabbinical College Beth Shraga",
        "Rabbinical College Bobover Yeshiva Bnei Zion",
        "Rabbinical College of America",
        "Rabbinical College of Long Island",
        "Rabbinical College of Ohr Shimon Yisroel",
        "Rabbinical College Ohr Yisroel",
        "Rabbinical College Telshe",
        "Rabbinical Seminary of America",
        "Radford M Locklin Technical College",
        "Radford University",
        "Radford University-Carilion",
        "Radiological Technologies University VT",
        "Rainy River Community College",
        "Ralph R Willis Career and Technical Center",
        "Ramapo College of New Jersey",
        "Randall University",
        "Randolph College",
        "Randolph Community College",
        "Randolph Technical Center",
        "Randolph-Macon College",
        "Ranger College",
        "Ranken Technical College",
        "Raphael's School of Beauty Culture Inc-Alliance",
        "Raphael's School of Beauty Culture Inc-Boardman",
        "Raphael's School of Beauty Culture Inc-Brunswick",
        "Raphael's School of Beauty Culture Inc-Niles",
        "Rappahannock Community College",
        "Raritan Valley Community College",
        "Rasmussen College - Overland Park",
        "Rasmussen College-Aurora",
        "Rasmussen College-Blaine",
        "Rasmussen College-Bloomington",
        "Rasmussen College-Brooklyn Park",
        "Rasmussen College-Eagan",
        "Rasmussen College-Florida",
        "Rasmussen College-Fort Myers",
        "Rasmussen College-Illinois",
        "Rasmussen College-Kansas",
        "Rasmussen College-Lake Elmo/Woodbury",
        "Rasmussen College-Land O' Lakes",
        "Rasmussen College-Mankato",
        "Rasmussen College-Minnesota",
        "Rasmussen College-Mokena/Tinley Park",
        "Rasmussen College-New Port Richey",
        "Rasmussen College-North Dakota",
        "Rasmussen College-Romeoville/Joliet",
        "Rasmussen College-Tampa/Brandon",
        "Rasmussen College-Wausau",
        "Rasmussen College-Wisconsin",
        "Ravenscroft Beauty College",
        "Ray J's College of Hair",
        "Reading Area Community College",
        "Reading Hospital School of Health Sciences",
        "Reconstructionist Rabbinical College",
        "Red Lake Nation College",
        "Red River Technology Center",
        "Red Rocks Community College",
        "Redlands Community College",
        "Redondo Beach Beauty College",
        "Reed College",
        "Reedley College",
        "Reflections Academy of Beauty",
        "Reformed Presbyterian Theological Seminary",
        "Reformed University",
        "Refrigeration School Inc",
        "Regent University",
        "Regina Webb Academy",
        "Regional Center for Border Health",
        "Regis College",
        "Regis University",
        "Reid State Technical College",
        "Reinhardt University",
        "Relay Graduate School of Education",
        "Relay Graduate School of Education - Atlanta",
        "Relay Graduate School of Education - Baton Rouge",
        "Relay Graduate School of Education - California",
        "Relay Graduate School of Education - Chicago",
        "Relay Graduate School of Education - Dallas-Fort Worth",
        "Relay Graduate School of Education - Delaware",
        "Relay Graduate School of Education - Denver",
        "Relay Graduate School of Education - Houston",
        "Relay Graduate School of Education - Memphis",
        "Relay Graduate School of Education - Nashville",
        "Relay Graduate School of Education - New Orleans",
        "Relay Graduate School of Education - Newark",
        "Relay Graduate School of Education - Philadelphia & Camden",
        "Relay Graduate School of Education - San Antonio",
        "Relay Graduate School of Education - Washington D.C",
        "Remington College-Baton Rouge Campus",
        "Remington College-Cleveland Campus",
        "Remington College-Columbia Campus",
        "Remington College-Dallas Campus",
        "Remington College-Fort Worth Campus",
        "Remington College-Heathrow Campus",
        "Remington College-Honolulu Campus",
        "Remington College-Houston Southeast Campus",
        "Remington College-Lafayette Campus",
        "Remington College-Little Rock Campus",
        "Remington College-Memphis Campus",
        "Remington College-Mobile Campus",
        "Remington College-Nashville Campus",
        "Remington College-North Houston Campus",
        "Remington College-Shreveport Campus",
        "Renaissance Academie",
        "Rend Lake College",
        "Rensselaer at Hartford",
        "Renton Technical College",
        "Research College of Nursing",
        "Resurrection University",
        "Rexburg College of Massage Therapy",
        "Rhode Island College",
        "Rhode Island School of Design",
        "Rhodes College",
        "Ricci's Academy of Cosmetology",
        "Rice University",
        "Richard Bland College",
        "Richland College",
        "Richland Community College",
        "Richmond Community College",
        "Richmont Graduate University",
        "Richport Technical College",
        "Rider University",
        "Ridge Technical College",
        "Ridgewater College",
        "Ringling College of Art and Design",
        "Rio Grande Valley College",
        "Rio Hondo College",
        "Rio Salado College",
        "Ripon College",
        "River Parishes Community College",
        "River Valley Community College",
        "River Valley School of Massage",
        "Riverland Community College",
        "Riveroak Technical College",
        "Riverside City College",
        "Riverside College of Health Careers",
        "Riverside County Office of Education-School of Career Education",
        "Rivertown School of Beauty Barber Skin Care and Nails",
        "Rivier University",
        "Rizzieri Aveda School for Beauty and Wellness",
        "Roane State Community College",
        "Roane-Jackson Technical Center",
        "Roanoke College",
        "Roanoke-Chowan Community College",
        "Rob Roy Academy - Woonsocket",
        "Rob Roy Academy-Fall River",
        "Rob Roy Academy-New Bedford",
        "Rob Roy Academy-Taunton",
        "Rob Roy Academy-Worcester",
        "Robert Fiance Beauty Schools",
        "Robert Fiance Beauty Schools-North Plainfield",
        "Robert Fiance Beauty Schools-West New York",
        "Robert Morgan Educational Center and Technical College",
        "Robert Morris University",
        "Robert Morris University Illinois",
        "Robert Paul Academy of Cosmetology Arts & Sciences",
        "Roberto-Venn School of Luthiery",
        "Roberts Wesleyan College",
        "Robeson Community College",
        "Rochester Community and Technical College",
        "Rochester University",
        "Rock Valley College",
        "Rockefeller University",
        "Rockford University",
        "Rockhurst University",
        "Rockingham Community College",
        "Rockland Community College",
        "Rockland County BOCES-Practical Nursing Program",
        "Rocky Mountain College",
        "Rocky Mountain College of Art and Design",
        "Rocky Mountain University of Health Professions",
        "Rocky Vista University",
        "Rocky Vista University - Southern Utah",
        "Roger Williams University",
        "Roger Williams University School of Law",
        "Rogers Academy of Hair Design",
        "Rogers State University",
        "Rogue Community College",
        "Rollins College",
        "Roosevelt University",
        "Rosalind Franklin University of Medicine and Science",
        "Rose State College",
        "Rosedale Bible College",
        "Rosedale Technical College",
        "Rosel School of Cosmetology",
        "Roseman University of Health Sciences",
        "Rosemead Beauty School",
        "Rosemont College",
        "Ross College-Canton",
        "Ross College-Grand Rapids North",
        "Ross College-Hopkinsville",
        "Ross College-Quad Cities",
        "Ross College-Sylvania",
        "Ross Medical Education Center - Kalamazoo",
        "Ross Medical Education Center-Ann Arbor",
        "Ross Medical Education Center-Battle Creek",
        "Ross Medical Education Center-Bowling Green",
        "Ross Medical Education Center-Brighton",
        "Ross Medical Education Center-Canton",
        "Ross Medical Education Center-Charleston",
        "Ross Medical Education Center-Cincinnati",
        "Ross Medical Education Center-Davison",
        "Ross Medical Education Center-Dayton",
        "Ross Medical Education Center-Elyria",
        "Ross Medical Education Center-Erlanger",
        "Ross Medical Education Center-Evansville",
        "Ross Medical Education Center-Flint",
        "Ross Medical Education Center-Fort Wayne",
        "Ross Medical Education Center-Granger",
        "Ross Medical Education Center-Huntsville",
        "Ross Medical Education Center-Johnson City",
        "Ross Medical Education Center-Kentwood",
        "Ross Medical Education Center-Knoxville",
        "Ross Medical Education Center-Kokomo",
        "Ross Medical Education Center-Lafayette",
        "Ross Medical Education Center-Lansing",
        "Ross Medical Education Center-Madison Heights",
        "Ross Medical Education Center-Midland",
        "Ross Medical Education Center-Morgantown",
        "Ross Medical Education Center-Muncie",
        "Ross Medical Education Center-New Baltimore",
        "Ross Medical Education Center-Niles",
        "Ross Medical Education Center-Ontario",
        "Ross Medical Education Center-Owensboro",
        "Ross Medical Education Center-Port Huron",
        "Ross Medical Education Center-Roosevelt Park",
        "Ross Medical Education Center-Saginaw",
        "Ross Medical Education Center-Taylor",
        "Rosslyn Training Academy of Cosmetology",
        "Rowan College at Burlington County",
        "Rowan College of South Jersey Gloucester Campus",
        "Rowan University",
        "Rowan-Cabarrus Community College",
        "Roxborough Memorial Hospital School of Nursing",
        "Roxbury Community College",
        "Royale College of Beauty and Barbering",
        "Rudae's School of Beauty Culture-Ft Wayne",
        "Rudy & Kelly Academy-A Paul Mitchell Partner School",
        "Rush University",
        "Russell Sage College",
        "Rust College",
        "Rutgers University-Camden",
        "Rutgers University-New Brunswick",
        "Rutgers University-Newark",
        "SABER College",
        "Sacramento Center",
        "Sacramento City College",
        "Sacred Heart Major Seminary",
        "Sacred Heart Seminary and School of Theology",
        "Sacred Heart University",
        "Saddleback College",
        "SAE Expression College",
        "Sage School of Massage & Esthetics",
        "Saginaw Chippewa Tribal College",
        "Saginaw Valley State University",
        "Saint Ambrose University",
        "Saint Anselm College",
        "Saint Anthony College of Nursing",
        "Saint Anthony College of Nursing - Highland Community College Campus",
        "Saint Anthony College of Nursing - Sauk Valley Community College Campus",
        "Saint Augustine College",
        "Saint Augustine's University",
        "Saint Charles Borromeo Seminary-Overbrook",
        "Saint Cloud State University",
        "Saint Edward's University",
        "Saint Elizabeth College of Nursing",
        "Saint Elizabeth School of Nursing",
        "Saint Francis Medical Center College of Nursing",
        "Saint Francis Medical Center School of Nursing",
        "Saint Francis University",
        "Saint John Fisher College",
        "Saint Johns River State College",
        "Saint John's Seminary",
        "Saint Johns University",
        "Saint John's University - Staten Island Campus",
        "Saint Joseph Seminary College",
        "Saint Joseph's College of Maine",
        "Saint Joseph's University",
        "Saint Leo University",
        "Saint Louis Christian College",
        "Saint Louis Community College",
        "Saint Louis University",
        "Saint Luke's College of Health Sciences",
        "Saint Martin's University",
        "Saint Mary-of-the-Woods College",
        "Saint Mary's College",
        "Saint Mary's College of California",
        "Saint Mary's University of Minnesota",
        "Saint Meinrad School of Theology",
        "Saint Michael College of Allied Health",
        "Saint Michael's College",
        "Saint Norbert College",
        "Saint Paul College",
        "Saint Paul School of Theology",
        "Saint Peter's University",
        "Saint Vincent College",
        "Saint Vincent de Paul Regional Seminary",
        "Saint Vincent Seminary",
        "Saint Vladimirs Orthodox Theological Seminary",
        "Saint Xavier University",
        "Salem College",
        "Salem College of Hairstyling",
        "Salem Community College",
        "Salem State University",
        "Salem University",
        "Salina Area Technical College",
        "Saline County Career Center",
        "Salisbury University",
        "Salish Kootenai College",
        "Salon Boutique Academy",
        "Salon Professional Academy of San Antonio",
        "Salon Success Academy-Corona",
        "Salon Success Academy-Fontana",
        "Salon Success Academy-Redlands",
        "Salon Success Academy-Riverside",
        "Salon Success Academy-Upland",
        "Salon Success Academy-West Covina",
        "Salt Lake Community College",
        "Salter College-West Boylston",
        "Salus University",
        "Salve Regina University",
        "Sam Houston State University",
        "Samaritan Hospital School of Nursing",
        "Samford University",
        "Sampson Community College",
        "Samuel Merritt University",
        "San Antonio College",
        "San Bernardino Beauty College",
        "San Bernardino Valley College",
        "San Diego Christian College",
        "San Diego City College",
        "San Diego Mesa College",
        "San Diego Miramar College",
        "San Diego State University",
        "San Diego State University-Imperial Valley Campus",
        "San Francisco Conservatory of Music",
        "San Francisco Film School",
        "San Francisco State University",
        "San Francisco Theological Seminary",
        "San Ignacio University",
        "San Jacinto College-North Campus",
        "San Jacinto College-South Campus",
        "San Jacinto Community College",
        "San Joaquin College of Law",
        "San Joaquin Delta College",
        "San Joaquin General Hospital School of Radiation Technology",
        "San Joaquin Valley College - Atascadero",
        "San Joaquin Valley College - Bakersfield",
        "San Joaquin Valley College - Delano",
        "San Joaquin Valley College - Fresno",
        "San Joaquin Valley College - Fresno Trades Education Center",
        "San Joaquin Valley College - Hanford",
        "San Joaquin Valley College - Hesperia",
        "San Joaquin Valley College - Lancaster",
        "San Joaquin Valley College - Madera",
        "San Joaquin Valley College - Modesto",
        "San Joaquin Valley College - Online",
        "San Joaquin Valley College - Ontario",
        "San Joaquin Valley College - Rancho Cordova",
        "San Joaquin Valley College - San Diego",
        "San Joaquin Valley College - Temecula",
        "San Joaquin Valley College-Visalia",
        "San Jose Campus",
        "San Jose City College",
        "San Jose State University",
        "San Juan Bautista School of Medicine",
        "San Juan College",
        "Sandhills Community College",
        "Sandra Academy of Salon Services Inc",
        "Sandusky Career Center",
        "Sanford Medical Center",
        "Santa Ana Beauty Academy",
        "Santa Ana Beauty College",
        "Santa Ana College",
        "Santa Barbara Business College - Ventura",
        "Santa Barbara Business College-Bakersfield",
        "Santa Barbara Business College-Santa Maria",
        "Santa Barbara City College",
        "Santa Clara University",
        "Santa Fe College",
        "Santa Fe Community College",
        "Santa Monica College",
        "Santa Rosa Junior College",
        "Santiago Canyon College",
        "Sarah Lawrence College",
        "Sarasota School of Massage Therapy",
        "Sauk Valley Community College",
        "Savannah College of Art and Design",
        "Savannah State University",
        "Savannah Technical College",
        "Saybrook University",
        "Schenectady County Community College",
        "Schiller International University",
        "Schilling-Douglas School of Hair Design LLC",
        "School of Architecture at Taliesin",
        "School of Automotive Machinists & Technology",
        "School of Missionary Aviation Technology",
        "School of Professional Horticulture New York Botanical Garden",
        "School of Visual Arts",
        "Schoolcraft College",
        "Schreiner University",
        "Schuyler Steuben Chemung Tioga Allegany BOCES",
        "Schuylkill Technology Center",
        "Scioto County Career Technical Center",
        "Scott College of Cosmetology",
        "Scottsdale Community College",
        "Scripps College",
        "Searcy Beauty College",
        "Seattle Central College",
        "Seattle Pacific University",
        "Seattle University",
        "Sebring Career Schools-Houston",
        "Sebring Career Schools-Huntsville",
        "Seguin Beauty School-New Braunfels",
        "Seguin Beauty School-Seguin",
        "Selma University",
        "Seminar L'moros Bais Yaakov",
        "Seminario Evangelico de Puerto Rico",
        "Seminary Bnos Chaim",
        "Seminole State College",
        "Seminole State College of Florida",
        "Sentara College of Health Sciences",
        "Sessions College for Professional Design",
        "Seton Hall University",
        "Seton Hill University",
        "Setting the Standard Barbering and Natural Hair Academy",
        "Seward County Community College",
        "Sharon Regional School of Nursing",
        "Sharps Academy of Hair Styling",
        "Shasta Bible College and Graduate School",
        "Shasta College",
        "Shasta School of Cosmetology",
        "Shaw University",
        "Shawnee Beauty College",
        "Shawnee Community College",
        "Shawnee State University",
        "Shawsheen Valley School of Practical Nursing",
        "Shear Ego International School of Hair Design",
        "Shear Excellence Hair Academy",
        "Shear Finesse Beauty Academy",
        "Shelton State Community College",
        "Shenandoah University",
        "Shepherd University",
        "Shepherds College",
        "Shepherds Theological Seminary",
        "Sheridan Technical College",
        "Sherman College of Chiropractic",
        "Sherrill's University of Barber & Cosmetology",
        "Shiloh University",
        "Shippensburg University of Pennsylvania",
        "Sh'or Yoshuv Rabbinical College",
        "Shoreline Community College",
        "Shorter College",
        "Shorter University",
        "Siena College",
        "Siena Heights University",
        "Sierra College",
        "Sierra College of Beauty",
        "Sierra Nevada College",
        "Signature Healthcare Brockton Hospital School of Nursing",
        "Sikeston Career and Technology Center",
        "Simmons College of Kentucky",
        "Simmons University",
        "Simpson College",
        "Simpson University",
        "Sinclair Community College",
        "Sinte Gleska University",
        "Sioux Falls Seminary",
        "Sisseton Wahpeton College",
        "Sitting Bull College",
        "Skagit Valley College",
        "Skidmore College",
        "Skinworks School of Advanced Skincare",
        "Skyline College",
        "Slippery Rock University of Pennsylvania",
        "Smith College",
        "Snead State Community College",
        "Snow College",
        "Snow College-Richfield Campus",
        "Sofia University",
        "Soka University of America",
        "Solano Community College",
        "Somerset Community College",
        "Somerset County Technology Center",
        "Somerset County Technology Center - Bedford Site",
        "Sonoma State University",
        "South Arkansas Community College",
        "South Baylo University",
        "South Carolina State University",
        "South Central Career Center",
        "South Central College",
        "South Central College-Faribault",
        "South Central Louisiana Technical College",
        "South Coast College",
        "South College",
        "South Dade Technical College",
        "South Dakota School of Mines and Technology",
        "South Dakota State University",
        "South Florida Bible College and Theological Seminary",
        "South Florida State College",
        "South Georgia State College",
        "South Georgia Technical College",
        "South Hills Beauty Academy",
        "South Hills School of Business & Technology",
        "South Hills School of Business and Technology-Altoona",
        "South Louisiana Community College",
        "South Mountain Community College",
        "South Piedmont Community College",
        "South Plains College",
        "South Puget Sound Community College",
        "South Seattle College",
        "South Suburban College",
        "South Texas Barber College Inc",
        "South Texas College",
        "South Texas College of Law Houston",
        "South Texas Training Center",
        "South University-Austin",
        "South University-Cleveland",
        "South University-Columbia",
        "South University-High Point",
        "South University-Montgomery",
        "South University-Novi",
        "South University-Richmond",
        "South University-Savannah",
        "South University-Savannah Online",
        "South University-Tampa",
        "South University-Virginia Beach",
        "South University-West Palm Beach",
        "Southcentral Kentucky Community and Technical College",
        "Southeast Arkansas College",
        "Southeast Community College Area",
        "Southeast Kentucky Community and Technical College",
        "Southeast Missouri Hospital College of Nursing and Health Sciences",
        "Southeast Missouri State University",
        "Southeastern Baptist College",
        "Southeastern Baptist Theological Seminary",
        "Southeastern College-Miami Lakes",
        "Southeastern College-West Palm Beach",
        "Southeastern Community College",
        "Southeastern Illinois College",
        "Southeastern Louisiana University",
        "Southeastern Louisiana University - Albert Cammon Middle School",
        "Southeastern Louisiana University - Destrahan High School",
        "Southeastern Louisiana University - EBR Parish School System Prof Dev",
        "Southeastern Louisiana University - St Amant High School",
        "Southeastern Oklahoma State University",
        "Southeastern Technical College",
        "Southeastern University",
        "Southern Adventist University",
        "Southern Arkansas University Main Campus",
        "Southern Arkansas University Tech",
        "Southern California Seminary",
        "Southern California University of Health Sciences",
        "Southern College of Optometry",
        "Southern Connecticut State University",
        "Southern Crescent Technical College",
        "Southern Illinois University-Carbondale",
        "Southern Illinois University-Edwardsville",
        "Southern Maine Community College",
        "Southern Methodist University",
        "Southern Nazarene University",
        "Southern New Hampshire University",
        "Southern Oklahoma Technology Center",
        "Southern Oregon University",
        "Southern Regional Technical College",
        "Southern School of Beauty Inc",
        "Southern State Community College",
        "Southern States University",
        "Southern Technical College",
        "Southern Union State Community College",
        "Southern University and A & M College",
        "Southern University at New Orleans",
        "Southern University at Shreveport",
        "Southern University Law Center",
        "Southern Utah University",
        "Southern Vermont College",
        "Southern Virginia University",
        "Southern Wesleyan University",
        "Southern West Virginia Community and Technical College",
        "Southern Westchester BOCES-Practical Nursing Program",
        "Southern Worcester County Regional Vocational School District",
        "Southside College of Health Sciences",
        "Southside Virginia Community College",
        "Southwest Acupuncture College-Boulder",
        "Southwest Acupuncture College-Santa Fe",
        "Southwest Baptist University",
        "Southwest College of Naturopathic Medicine & Health Sciences",
        "Southwest Minnesota State University",
        "Southwest Mississippi Community College",
        "Southwest School of Business and Technical Careers-San Antonio",
        "Southwest School of Business and Technical Careers-South Texas",
        "Southwest Technical College",
        "Southwest Technology Center",
        "Southwest Tennessee Community College",
        "Southwest Texas Junior College",
        "Southwest University at El Paso",
        "Southwest University of Visual Arts-Albuquerque",
        "Southwest University of Visual Arts-Tucson",
        "Southwest Virginia Community College",
        "Southwest Wisconsin Technical College",
        "Southwestern Adventist University",
        "Southwestern Assemblies of God University",
        "Southwestern Christian College",
        "Southwestern Christian University",
        "Southwestern College",
        "Southwestern Community College",
        "Southwestern Illinois College",
        "Southwestern Law School",
        "Southwestern Michigan College",
        "Southwestern Oklahoma State University",
        "Southwestern Oregon Community College",
        "Southwestern University",
        "Sovah School of Health Professions",
        "SOWELA Technical Community College",
        "Spalding University",
        "Spartan College of Aeronautics & Technology",
        "Spartanburg Community College",
        "Spartanburg Methodist College",
        "Specs Howard School of Media Arts",
        "Spelman College",
        "Spertus College",
        "Spokane Community College",
        "Spokane Falls Community College",
        "Spoon River College",
        "Spring Arbor University",
        "Spring Hill College",
        "Springfield College",
        "Springfield College-Regional Online and Continuing Education",
        "Springfield Technical Community College",
        "St Bernard's School of Theology and Ministry",
        "St Bonaventure University",
        "St Catherine University",
        "St Charles Community College",
        "St Clair County Community College",
        "St Cloud Technical and Community College",
        "St Francis College",
        "St Francis Medical Center-School of Radiologic Technology",
        "St John's Seminary",
        "St John's University-New York",
        "St Joseph School of Nursing",
        "St Lawrence University",
        "St Louis College of Health Careers-Fenton",
        "St Louis College of Health Careers-St Louis",
        "St Luke's College",
        "St Lukes Hospital School of Nursing",
        "St Olaf College",
        "St Paul's School of Nursing-Queens",
        "St Paul's School of Nursing-Staten Island",
        "St Petersburg College",
        "St Philip's College",
        "St. Andrews University",
        "St. John Vianney College Seminary",
        "St. John's College",
        "St. Joseph's College of Nursing",
        "St. Joseph's College-Long Island",
        "St. Joseph's College-New York",
        "St. Louis College of Pharmacy",
        "St. Mary's College of Maryland",
        "St. Mary's University",
        "St. Peter's Hospital College of Nursing",
        "St. Thomas Aquinas College",
        "St. Thomas University",
        "Stage One-The Hair School",
        "Stanbridge University",
        "Stanbridge University-Los Angeles",
        "Standard Healthcare Services-College of Nursing",
        "Stanford University",
        "Stanly Community College",
        "Star College of Cosmetology 2",
        "Stark State College",
        "Starr King School for the Ministry",
        "State Career College",
        "State College of Beauty Culture Inc",
        "State College of Florida-Manatee-Sarasota",
        "State Fair Community College",
        "State Technical College of Missouri",
        "State University of New York at New Paltz",
        "Staunton School of Cosmetology",
        "Stautzenberger College-Brecksville",
        "Stautzenberger College-Maumee",
        "Stellar Career College",
        "Stephen F Austin State University",
        "Stephens College",
        "Sterling College",
        "Stetson University",
        "Steven Papageorge Hair Academy",
        "Stevens-Henager College",
        "Stevenson University",
        "Stevensons Academy of Hair Design",
        "Stewart School",
        "Stillman College",
        "Stockton University",
        "Stone Academy-East Hartford",
        "Stone Academy-Waterbury",
        "Stone Academy-West Haven",
        "Stone Child College",
        "Stonehill College",
        "Stony Brook University",
        "Strand College of Hair Design",
        "Stratford School for Aviation Maintenance Technicians",
        "Stratford University",
        "Strayer University - Macon Campus",
        "Strayer University - Northwest Houston Campus",
        "Strayer University-Alabama",
        "Strayer University-Alexandria Campus",
        "Strayer University-Allentown Campus",
        "Strayer University-Anne Arundel Campus",
        "Strayer University-Arkansas",
        "Strayer University-Augusta Campus",
        "Strayer University-Baymeadows Campus",
        "Strayer University-Cedar Hill",
        "Strayer University-Center City Campus",
        "Strayer University-Charleston Campus",
        "Strayer University-Chesapeake Campus",
        "Strayer University-Chesterfield Campus",
        "Strayer University-Cobb Campus",
        "Strayer University-Columbia Campus",
        "Strayer University-Columbus",
        "Strayer University-Delaware",
        "Strayer University-Delaware County Campus",
        "Strayer University-District of Columbia",
        "Strayer University-Douglasville Campus",
        "Strayer University-Florida",
        "Strayer University-Fort Lauderdale Campus",
        "Strayer University-Fredericksburg Campus",
        "Strayer University-Georgia",
        "Strayer University-Global Region",
        "Strayer University-Greensboro Campus",
        "Strayer University-Henrico Campus",
        "Strayer University-Huntersville Campus",
        "Strayer University-Huntsville Campus",
        "Strayer University-Irving",
        "Strayer University-Knoxville Campus",
        "Strayer University-Lithonia Campus",
        "Strayer University-Loudoun Campus",
        "Strayer University-Lower Bucks County Campus",
        "Strayer University-Maitland Campus",
        "Strayer University-Manassas Campus",
        "Strayer University-Maryland",
        "Strayer University-Miramar Campus",
        "Strayer University-Mississippi",
        "Strayer University-Morrow Campus",
        "Strayer University-Nashville Campus",
        "Strayer University-New Jersey",
        "Strayer University-Newport News Campus",
        "Strayer University-North Carolina",
        "Strayer University-North Charlotte",
        "Strayer University-North Dallas",
        "Strayer University-North Raleigh Campus",
        "Strayer University-Northwest Houston",
        "Strayer University-Orlando East Campus",
        "Strayer University-Owings Mills Campus",
        "Strayer University-Palm Beach Gardens Campus",
        "Strayer University-Pennsylvania",
        "Strayer University-Piscataway",
        "Strayer University-Plano",
        "Strayer University-Rockville Campus",
        "Strayer University-Roswell Campus",
        "Strayer University-San Antonio",
        "Strayer University-Sand Lake Campus",
        "Strayer University-Savannah Campus",
        "Strayer University-Shelby Oaks Campus",
        "Strayer University-South Carolina",
        "Strayer University-South Charlotte",
        "Strayer University-South Raleigh Campus",
        "Strayer University-Stafford",
        "Strayer University-Takoma Park Campus",
        "Strayer University-Tampa East Campus",
        "Strayer University-Tennessee",
        "Strayer University-Texas",
        "Strayer University-Virginia",
        "Strayer University-Virginia Beach Campus",
        "Strayer University-Warrendale Campus",
        "Strayer University-West Virginia",
        "Strayer University-White Marsh Campus",
        "Strayer University-Willingboro Campus",
        "Strayer University-Woodbridge Campus",
        "Studio Academy of Beauty",
        "Studio Beauty School",
        "Studio Incamminati",
        "Studio Jewelers",
        "Stylemaster College of Hair Design",
        "Suffolk Beauty Academy",
        "Suffolk County Community College",
        "Suffolk University",
        "Sul Ross State University",
        "Sullivan County Community College",
        "Sullivan University",
        "SUM Bible College and Theological Seminary",
        "Summit Academy Opportunities Industrialization Center",
        "Summit Christian College",
        "Summit College",
        "Summit Salon Academy",
        "Sumner College",
        "Sumter Beauty College",
        "Suncoast Technical College",
        "Suncoast Technical Education Center",
        "Sunstate Academy",
        "SUNY at Albany",
        "SUNY at Fredonia",
        "SUNY at Purchase College",
        "SUNY Broome Community College",
        "SUNY Buffalo State",
        "SUNY College at Brockport",
        "SUNY College at Geneseo",
        "SUNY College at Old Westbury",
        "SUNY College at Oswego",
        "SUNY College at Plattsburgh",
        "SUNY College at Potsdam",
        "SUNY College of Agriculture and Technology at Cobleskill",
        "SUNY College of Environmental Science and Forestry",
        "SUNY College of Optometry",
        "SUNY College of Technology at Alfred",
        "SUNY College of Technology at Canton",
        "SUNY College of Technology at Delhi",
        "SUNY Corning Community College",
        "SUNY Cortland",
        "SUNY Downstate Health Sciences University",
        "SUNY Empire State College",
        "SUNY Maritime College",
        "SUNY Morrisville",
        "SUNY Oneonta",
        "SUNY Westchester Community College",
        "Surry Community College",
        "Susquehanna County Career and Technology Center",
        "Susquehanna University",
        "Sussex County Community College",
        "Sutter Beauty College",
        "Swarthmore College",
        "Sweet Briar College",
        "Sylvain Melloul International Hair Academy",
        "Syracuse University",
        "Tabor College",
        "Tacoma Community College",
        "Taft College",
        "Taft University System",
        "Talladega College",
        "Tallahassee Community College",
        "Talmudic College of Florida",
        "Talmudical Academy-New Jersey",
        "Talmudical Seminary of Bobov",
        "Talmudical Seminary Oholei Torah",
        "Talmudical Yeshiva of Philadelphia",
        "Tarleton State University",
        "Tarrant County College District",
        "Taylor Andrews Academy of Hair Design-Provo",
        "Taylor Andrews Academy of Hair Design-West Jordan",
        "Taylor Andrews Academy-St George",
        "Taylor College",
        "Taylor University",
        "Taylortown School of Beauty Inc",
        "Teachers College at Columbia University",
        "Teachers College of San Joaquin",
        "Technical College of the Lowcountry",
        "Technical College of the Rockies",
        "Technical Learning Centers Inc",
        "Telshe Yeshiva-Chicago",
        "Temple College",
        "Temple University",
        "Tennessee College of Applied Technology Nashville",
        "Tennessee College of Applied Technology-Athens",
        "Tennessee College of Applied Technology-Covington",
        "Tennessee College of Applied Technology-Crossville",
        "Tennessee College of Applied Technology-Crump",
        "Tennessee College of Applied Technology-Dickson",
        "Tennessee College of Applied Technology-Elizabethton",
        "Tennessee College of Applied Technology-Harriman",
        "Tennessee College of Applied Technology-Hartsville",
        "Tennessee College of Applied Technology-Hohenwald",
        "Tennessee College of Applied Technology-Jacksboro",
        "Tennessee College of Applied Technology-Jacksboro -",
        "Tennessee College of Applied Technology-Jackson",
        "Tennessee College of Applied Technology-Knoxville",
        "Tennessee College of Applied Technology-Livingston",
        "Tennessee College of Applied Technology-McKenzie",
        "Tennessee College of Applied Technology-McMinnville",
        "Tennessee College of Applied Technology-Memphis",
        "Tennessee College of Applied Technology-Morristown",
        "Tennessee College of Applied Technology-Murfreesboro",
        "Tennessee College of Applied Technology-Newbern",
        "Tennessee College of Applied Technology-Oneida-Huntsville",
        "Tennessee College of Applied Technology-Paris",
        "Tennessee College of Applied Technology-Pulaski",
        "Tennessee College of Applied Technology-Ripley",
        "Tennessee College of Applied Technology-Shelbyville",
        "Tennessee College of Applied Technology-Whiteville",
        "Tennessee School of Beauty of Knoxville Inc",
        "Tennessee State University",
        "Tennessee Technological University",
        "Tennessee Wesleyan University",
        "Terra State Community College",
        "Teterboro School of Aeronautics",
        "Texarkana College",
        "Texas A & M International University",
        "Texas A & M University Health Science Center",
        "Texas A & M University-College Station",
        "Texas A & M University-Commerce",
        "Texas A & M University-Corpus Christi",
        "Texas A & M University-Kingsville",
        "Texas A&M University-Central Texas",
        "Texas A&M University-San Antonio",
        "Texas A&M University-Texarkana",
        "Texas Barber College",
        "Texas Barber College - Branch Campus #1",
        "Texas Barber College - Branch Campus #2",
        "Texas Barber College - Branch Campus #5",
        "Texas Beauty College",
        "Texas Chiropractic College Foundation Inc",
        "Texas Christian University",
        "Texas College",
        "Texas College of Cosmetology-Abilene",
        "Texas College of Cosmetology-Lubbock",
        "Texas College of Cosmetology-San Angelo",
        "Texas County Technical College",
        "Texas Health and Science University",
        "Texas Health School",
        "Texas Lutheran University",
        "Texas Southern University",
        "Texas Southmost College",
        "Texas State Technical College",
        "Texas State University",
        "Texas Tech University",
        "Texas Tech University Health Sciences Center",
        "Texas Tech University Health Sciences Center-El Paso",
        "Texas Wesleyan University",
        "Texas Woman's University",
        "Thaddeus Stevens College of Technology",
        "Thanh Le College School of Cosmetology",
        "The Academy of Hair Design Six",
        "The Academy of Radio and TV Broadcasting",
        "The Ailey School",
        "The Baptist College of Florida",
        "The Barber School",
        "The Beauty School",
        "The Catholic University of America",
        "The Chicago School of Professional Psychology at Chicago",
        "The Chicago School of Professional Psychology at Irvine",
        "The Chicago School of Professional Psychology at Los Angeles",
        "The Chicago School of Professional Psychology at San Diego",
        "The Chicago School of Professional Psychology at Washington DC",
        "The Chicago School of Professional Psychology at Xavier University of Louisiana",
        "The Christ College of Nursing and Health Sciences",
        "The Chrysm Insitute of Esthetics",
        "The College of Health Care Professions-Austin",
        "The College of Health Care Professions-Dallas",
        "The College of Health Care Professions-Fort Worth",
        "The College of Health Care Professions-McAllen Campus",
        "The College of Health Care Professions-Northwest",
        "The College of Health Care Professions-San Antonio",
        "The College of Health Care Professions-South San Antonio",
        "The College of Health Care Professions-Southwest Houston",
        "The College of Idaho",
        "The College of New Jersey",
        "The College of New Rochelle",
        "The College of Saint Rose",
        "The College of Saint Scholastica",
        "The College of the Florida Keys",
        "The College of Westchester",
        "The College of Wooster",
        "The Creative Center",
        "The Creative Circus",
        "The Culinary School of Fort Worth",
        "The Evergreen State College",
        "The Fab School",
        "The General Theological Seminary",
        "The Hair Academy",
        "The Hair Academy LLC",
        "The International Culinary Center",
        "The John Marshall Law School",
        "The Juilliard School",
        "The King's College",
        "The King's University",
        "The Landing School",
        "The Master's University and Seminary",
        "The Modern College of Design",
        "The New England Conservatory of Music",
        "The New School",
        "The New School Center for Media",
        "The North Coast College",
        "The Ohio Media School-Columbus",
        "The Professional Hair Design Academy",
        "The Recording Conservatory of Austin",
        "The Restaurant School at Walnut Hill College",
        "The Salon Professional Academy",
        "The Salon Professional Academy-Altoona",
        "The Salon Professional Academy-Appleton",
        "The Salon Professional Academy-Battle Creek",
        "The Salon Professional Academy-Cedar Falls",
        "The Salon Professional Academy-Colorado Springs",
        "The Salon Professional Academy-Delray Beach",
        "The Salon Professional Academy-Evansville",
        "The Salon Professional Academy-Fargo",
        "The Salon Professional Academy-Ft Myers",
        "The Salon Professional Academy-Georgetown",
        "The Salon Professional Academy-Grand Junction",
        "The Salon Professional Academy-Huntsville",
        "The Salon Professional Academy-Kenosha",
        "The Salon Professional Academy-Lewisville",
        "The Salon Professional Academy-Melbourne",
        "The Salon Professional Academy-Nampa",
        "The Salon Professional Academy-Nashville",
        "The Salon Professional Academy-North Little Rock",
        "The Salon Professional Academy-Onalaska",
        "The Salon Professional Academy-Rochester",
        "The Salon Professional Academy-San Jose",
        "The Salon Professional Academy-Shorewood",
        "The Salon Professional Academy-St Charles",
        "The Salon Professional Academy-Tonawanda",
        "The Salter School-Malden Campus",
        "The Santa Barbara and Ventura Colleges of Law at Santa Barbara",
        "The Santa Barbara and Ventura Colleges of Law at Ventura",
        "The Seattle School of Theology & Psychology",
        "The Southern Baptist Theological Seminary",
        "The Spa School",
        "The Temple Annapolis-A Paul Mitchell Partner School",
        "The Temple-A Paul Mitchell Partner School",
        "The Training Domain",
        "The University of Aesthetics & Cosmetology",
        "The University of Alabama",
        "The University of Findlay",
        "The University of Montana",
        "The University of Montana - Missoula College",
        "The University of Montana-Western",
        "The University of Tampa",
        "The University of Tennessee Health Science Center",
        "The University of Tennessee-Chattanooga",
        "The University of Tennessee-Knoxville",
        "The University of Tennessee-Martin",
        "The University of Texas at Arlington",
        "The University of Texas at Austin",
        "The University of Texas at Dallas",
        "The University of Texas at El Paso",
        "The University of Texas at San Antonio",
        "The University of Texas at Tyler",
        "The University of Texas Health Science Center at Houston",
        "The University of Texas Health Science Center at San Antonio",
        "The University of Texas MD Anderson Cancer Center",
        "The University of Texas Medical Branch at Galveston",
        "The University of Texas of the Permian Basin",
        "The University of Texas Rio Grande Valley",
        "The University of the Arts",
        "The University of the South",
        "The University of Virginia's College at Wise",
        "The University of West Florida",
        "Theatre of Arts",
        "Theological Seminary of the Reformed Episcopal Church",
        "Thiel College",
        "Thomas Aquinas College",
        "Thomas College",
        "Thomas Edison State University",
        "Thomas Jefferson School of Law",
        "Thomas Jefferson University",
        "Thomas More College of Liberal Arts",
        "Thomas More University",
        "Thomas Nelson Community College",
        "Thomas University",
        "Three Rivers College",
        "Three Rivers Community College",
        "Three Rivers Community College - Corrigan-Radgowski Correctional Cente",
        "Three Rivers Community College - York Correctional Institution",
        "Tidewater Community College",
        "Tidewater Tech-Trades",
        "Tiffin Academy of Hair Design",
        "Tiffin University",
        "Tillamook Bay Community College",
        "Tint School of Makeup & Cosmetology-Seattle",
        "Tint School of Makeup and Cosmetology-Dallas",
        "Tint School of Makeup and Cosmetology-Irving",
        "Toccoa Falls College",
        "Tohono O'odham Community College",
        "Toledo Academy of Beauty Culture-East",
        "Toledo Public Schools Adult and Continuing Education",
        "Tom P Haney Technical Center",
        "Tomorrow's Image Barber And Beauty Academy of Virginia",
        "Tompkins Cortland Community College",
        "Toni & Guy Hairdressing Academy-Albuquerque",
        "Toni & Guy Hairdressing Academy-Atlanta",
        "Toni & Guy Hairdressing Academy-Colorado Springs",
        "Toni & Guy Hairdressing Academy-Cranston",
        "Toni & Guy Hairdressing Academy-Idaho",
        "Toni & Guy Hairdressing Academy-Plano",
        "Toni & Guy Hairdressing Academy-Rio Rancho",
        "Toni & Guy Hairdressing Academy-Santa Monica",
        "Toni & Guy Hairdressing Academy-Shoreline",
        "Toni & Guy Hairdressing Academy-Worcester",
        "Tooele Technical College",
        "Top Nails & Hair Beauty School",
        "Top of the Line Barber College",
        "Torah Temimah Talmudical Seminary",
        "Total Image Beauty Academy",
        "Totally Cosmo School of Modern Cosmetology",
        "Tougaloo College",
        "Touro College",
        "Touro University California",
        "Touro University Nevada",
        "Touro University Worldwide",
        "Towson University",
        "Transformed Barber and Cosmetology Academy",
        "Transylvania University",
        "Traviss Technical College",
        "Traxlers School of Hair",
        "Treasure Coast Technical College",
        "Treasure Valley Community College",
        "Trend Barber College",
        "Trend Setters' Academy of Beauty Culture-Elizabethtown",
        "Trend Setters' Academy of Beauty Culture-Louisville",
        "Trendsetters School of Beauty & Barbering",
        "Trenz Beauty Academy",
        "Trevecca Nazarene University",
        "Tri County Regional Vocational Technical High School",
        "Triangle Tech Inc-Bethlehem",
        "Triangle Tech Inc-Dubois",
        "Triangle Tech Inc-Erie",
        "Triangle Tech Inc-Greensburg",
        "Triangle Tech Inc-Pittsburgh",
        "Triangle Tech Inc-Sunbury",
        "Tricoci University of Beauty Culture-Bridgeview",
        "Tricoci University of Beauty Culture-Chicago NE",
        "Tricoci University of Beauty Culture-Chicago NW",
        "Tricoci University of Beauty Culture-Danville",
        "Tricoci University of Beauty Culture-Elgin",
        "Tricoci University of Beauty Culture-Glendale Heights",
        "Tricoci University of Beauty Culture-Highland",
        "Tricoci University of Beauty Culture-Indianapolis",
        "Tricoci University of Beauty Culture-Janesville",
        "Tricoci University of Beauty Culture-Lafayette",
        "Tricoci University of Beauty Culture-Libertyville",
        "Tricoci University of Beauty Culture-Peoria",
        "Tricoci University of Beauty Culture-Rockford",
        "Tricoci University of Beauty Culture-Urbana",
        "Tricoci University of Beauty Cuture LLC-Bloomington",
        "Tri-Community Adult Education",
        "Tri-County Adult Career Center",
        "Tri-County Beauty Academy",
        "Tri-County Community College",
        "Tri-County Technical College",
        "Trident Technical College",
        "Trident University International",
        "Trine University",
        "Trine University-Regional/Non-Traditional Campuses",
        "Trinidad State Junior College",
        "Trinity Baptist College",
        "Trinity Bible College and Graduate School",
        "Trinity Christian College",
        "Trinity College",
        "Trinity College of Florida",
        "Trinity College of Nursing & Health Sciences",
        "Trinity College of Puerto Rico",
        "Trinity Episcopal School for Ministry",
        "Trinity Health System School of Nursing",
        "Trinity International University-Florida",
        "Trinity International University-Illinois",
        "Trinity Law School",
        "Trinity School of Health and Allied Sciences",
        "Trinity University",
        "Trinity Valley Community College",
        "Trinity Washington University",
        "Tri-Rivers Career Center",
        "Tri-State Barber College",
        "Tri-State Bible College",
        "Triton College",
        "Triton College - Intl Union of Operating Engr Local 399 Trning Fac.",
        "Trocaire College",
        "Troy University",
        "Troy University-Dothan Campus",
        "Troy University-Montgomery Campus",
        "Troy University-Online",
        "Troy University-Phenix City Campus",
        "Troy University-Support Sites",
        "Truckee Meadows Community College",
        "Truett McConnell University",
        "Truman State University",
        "Trumbull Career & Technical Center",
        "Tucson College of Beauty",
        "Tufts University",
        "Tulane University of Louisiana",
        "Tulsa Community College",
        "Tulsa Technology Center",
        "Tulsa Welding School-Houston",
        "Tulsa Welding School-Jacksonville",
        "Tulsa Welding School-Tulsa",
        "Tunxis Community College",
        "Turning Point Beauty College",
        "Turtle Mountain Community College",
        "Tusculum University",
        "Tuskegee University",
        "Twin City Beauty College",
        "Tyler Junior College",
        "U S Grant Joint Vocational School",
        "U.S. Truck Driver Training School",
        "UCAS Univeristy of Cosmetology Arts & Sciences-La Joya",
        "UCAS University of Cosemtology Arts & Sciences-McAllen",
        "UCAS University of Cosmetology Arts & Sciences",
        "UCAS University of Cosmetology Arts & Sciences-Harlingen",
        "UCAS University of Cosmetology Arts & Sciences-San Antonio 410",
        "UEI College-Bakersfield",
        "UEI College-Fresno",
        "UEI College-Gardena",
        "UEI College-Riverside",
        "Uintah Basin Technical College",
        "Ukiah Adult School",
        "Ulster BOCES-School of Practical Nursing",
        "Ulster County Community College",
        "Ultimate Medical Academy-Clearwater",
        "Umpqua Community College",
        "Unification Theological Seminary",
        "Unilatina International College",
        "Union Bible College",
        "Union College",
        "Union County College",
        "Union County Vocational Technical School",
        "Union Presbyterian Seminary",
        "Union Presbyterian Seminary-Charlotte",
        "Union Theological Seminary in the City of New York",
        "Union University",
        "Unitech Training Academy-Alexandria",
        "Unitech Training Academy-Baton Rouge",
        "Unitech Training Academy-Houma",
        "Unitech Training Academy-Lafayette",
        "Unitech Training Academy-Lake Charles",
        "Unitech Training Academy-New Orleans",
        "Unitech Training Academy-West Monroe",
        "United Beauty College",
        "United Lutheran Seminary",
        "United States Air Force Academy",
        "United States Coast Guard Academy",
        "United States Merchant Marine Academy",
        "United States Military Academy",
        "United States Naval Academy",
        "United States Sports Academy",
        "United States University",
        "United Talmudical Seminary",
        "United Technical Center",
        "United Theological Seminary",
        "United Theological Seminary of the Twin Cities",
        "United Tribes Technical College",
        "Unitek College",
        "Unity College",
        "UnityPoint Health-Des Moines School of Radiologic Technology",
        "Universal Career School",
        "Universal College of Beauty Inc-Los Angeles 2",
        "Universal College of Healing Arts",
        "Universal Healthcare Careers College",
        "Universal Spa Training Academy",
        "Universal Technology College of Puerto Rico",
        "Universidad Adventista de las Antillas",
        "Universidad Ana G. Mendez-Carolina Campus",
        "Universidad Ana G. Mendez-Cupey Campus",
        "Universidad Ana G. Mendez-Gurabo Campus",
        "Universidad Ana G. Mendez-Online Campus",
        "Universidad Central de Bayamon",
        "Universidad Central Del Caribe",
        "Universidad del Sagrado Corazon",
        "Universidad Pentecostal Mizpa",
        "Universidad Politecnica de Puerto Rico",
        "Universidad Teologica del Caribe",
        "University Academy of Hair Design",
        "University at Buffalo",
        "University of Advancing Technology",
        "University of Aesthetics & Cosmetology",
        "University of Akron Main Campus",
        "University of Akron Wayne College",
        "University of Alabama at Birmingham",
        "University of Alabama in Huntsville",
        "University of Alaska Anchorage",
        "University of Alaska Fairbanks",
        "University of Alaska Southeast",
        "University of Antelope Valley",
        "University of Arizona",
        "University of Arizona-Sierra Vista",
        "University of Arkansas",
        "University of Arkansas at Little Rock",
        "University of Arkansas at Monticello",
        "University of Arkansas at Pine Bluff",
        "University of Arkansas Community College at Hope - Texarkana",
        "University of Arkansas Community College Rich Mountain",
        "University of Arkansas Community College-Batesville",
        "University of Arkansas Community College-Morrilton",
        "University of Arkansas for Medical Sciences",
        "University of Arkansas Hope-Texarkana",
        "University of Arkansas-Fort Smith",
        "University of Arkansas-Pulaski Technical College",
        "University of Baltimore",
        "University of Bridgeport",
        "University of California-Berkeley",
        "University of California-Davis",
        "University of California-Hastings College of Law",
        "University of California-Irvine",
        "University of California-Los Angeles",
        "University of California-Merced",
        "University of California-Riverside",
        "University of California-San Diego",
        "University of California-San Francisco",
        "University of California-Santa Barbara",
        "University of California-Santa Cruz",
        "University of Central Arkansas",
        "University of Central Florida",
        "University of Central Missouri",
        "University of Central Oklahoma",
        "University of Charleston",
        "University of Chicago",
        "University of Cincinnati-Blue Ash College",
        "University of Cincinnati-Clermont College",
        "University of Cincinnati-Main Campus",
        "University of Colorado Boulder",
        "University of Colorado Colorado Springs",
        "University of Colorado Denver/Anschutz Medical Campus",
        "University of Connecticut",
        "University of Connecticut-Avery Point",
        "University of Connecticut-Hartford Campus",
        "University of Connecticut-Stamford",
        "University of Connecticut-Waterbury Campus",
        "University of Dallas",
        "University of Dayton",
        "University of Delaware",
        "University of Denver",
        "University of Detroit Mercy",
        "University of Dubuque",
        "University of East-West Medicine",
        "University of Evansville",
        "University of Florida",
        "University of Florida-Online",
        "University of Fort Lauderdale",
        "University of Georgia",
        "University of Guam",
        "University of Hartford",
        "University of Hawaii at Hilo",
        "University of Hawaii at Manoa",
        "University of Hawaii Maui College",
        "University of Hawaii-West Oahu",
        "University of Holy Cross",
        "University of Houston",
        "University of Houston-Clear Lake",
        "University of Houston-Downtown",
        "University of Houston-Victoria",
        "University of Idaho",
        "University of Illinois at Chicago",
        "University of Illinois at Springfield",
        "University of Illinois at Urbana-Champaign",
        "University of Indianapolis",
        "University of Iowa",
        "University of Jamestown",
        "University of Kansas",
        "University of Kentucky",
        "University of La Verne",
        "University of Louisiana at Lafayette",
        "University of Louisiana at Monroe",
        "University of Louisville",
        "University of Lynchburg",
        "University of Maine",
        "University of Maine at Augusta",
        "University of Maine at Farmington",
        "University of Maine at Fort Kent",
        "University of Maine at Machias",
        "University of Maine at Presque Isle",
        "University of Management and Technology",
        "University of Mary",
        "University of Mary Hardin-Baylor",
        "University of Mary Washington",
        "University of Maryland Baltimore",
        "University of Maryland Eastern Shore",
        "University of Maryland Global Campus",
        "University of Maryland-Baltimore County",
        "University of Maryland-College Park",
        "University of Massachusetts Medical School Worcester",
        "University of Massachusetts-Amherst",
        "University of Massachusetts-Boston",
        "University of Massachusetts-Dartmouth",
        "University of Massachusetts-Lowell",
        "University of Memphis",
        "University of Miami",
        "University of Michigan-Ann Arbor",
        "University of Michigan-Dearborn",
        "University of Michigan-Flint",
        "University of Minnesota-Crookston",
        "University of Minnesota-Duluth",
        "University of Minnesota-Morris",
        "University of Minnesota-Rochester",
        "University of Minnesota-Twin Cities",
        "University of Mississippi",
        "University of Mississippi Medical Center",
        "University of Missouri-Columbia",
        "University of Missouri-Kansas City",
        "University of Missouri-St Louis",
        "University of Mobile",
        "University of Montevallo",
        "University of Mount Olive",
        "University of Mount Union",
        "University of Nebraska at Kearney",
        "University of Nebraska at Omaha",
        "University of Nebraska Medical Center",
        "University of Nebraska-Lincoln",
        "University of Nevada-Las Vegas",
        "University of Nevada-Reno",
        "University of New England",
        "University of New Hampshire at Manchester",
        "University of New Hampshire-Franklin Pierce School of Law",
        "University of New Hampshire-Main Campus",
        "University of New Haven",
        "University of New Mexico-Gallup Campus",
        "University of New Mexico-Los Alamos Campus",
        "University of New Mexico-Main Campus",
        "University of New Mexico-Taos Campus",
        "University of New Mexico-Valencia County Campus",
        "University of New Orleans",
        "University of North Alabama",
        "University of North Carolina at Asheville",
        "University of North Carolina at Chapel Hill",
        "University of North Carolina at Charlotte",
        "University of North Carolina at Greensboro",
        "University of North Carolina at Pembroke",
        "University of North Carolina School of the Arts",
        "University of North Carolina Wilmington",
        "University of North Dakota",
        "University of North Florida",
        "University of North Georgia",
        "University of North Texas",
        "University of North Texas at Dallas",
        "University of North Texas Health Science Center",
        "University of Northern Colorado",
        "University of Northern Iowa",
        "University of Northwestern Ohio",
        "University of Northwestern-St Paul",
        "University of Notre Dame",
        "University of Notre Dame -",
        "University of Oklahoma - Douglass Learning Center",
        "University of Oklahoma - Ft. Benning GA - AP",
        "University of Oklahoma-Health Sciences Center",
        "University of Oklahoma-Norman Campus",
        "University of Oregon",
        "University of Pennsylvania",
        "University of Phoenix-Arizona",
        "University of Phoenix-California",
        "University of Phoenix-Colorado",
        "University of Phoenix-Florida",
        "University of Phoenix-Georgia",
        "University of Phoenix-Hawaii",
        "University of Phoenix-Illinois",
        "University of Phoenix-Louisiana",
        "University of Phoenix-Michigan",
        "University of Phoenix-Nevada",
        "University of Phoenix-New Jersey",
        "University of Phoenix-New Mexico",
        "University of Phoenix-North Carolina",
        "University of Phoenix-Pennsylvania",
        "University of Phoenix-Tennessee",
        "University of Phoenix-Texas",
        "University of Phoenix-Utah",
        "University of Phoenix-Virginia",
        "University of Phoenix-Washington",
        "University of Pikeville",
        "University of Pittsburgh-Bradford",
        "University of Pittsburgh-Greensburg",
        "University of Pittsburgh-Johnstown",
        "University of Pittsburgh-Pittsburgh Campus",
        "University of Pittsburgh-Titusville",
        "University of Portland",
        "University of Providence",
        "University of Puerto Rico-Aguadilla",
        "University of Puerto Rico-Arecibo",
        "University of Puerto Rico-Bayamon",
        "University of Puerto Rico-Carolina",
        "University of Puerto Rico-Cayey",
        "University of Puerto Rico-Humacao",
        "University of Puerto Rico-Mayaguez",
        "University of Puerto Rico-Medical Sciences",
        "University of Puerto Rico-Ponce",
        "University of Puerto Rico-Rio Piedras",
        "University of Puerto Rico-Utuado",
        "University of Puget Sound",
        "University of Redlands",
        "University of Rhode Island",
        "University of Richmond",
        "University of Rio Grande",
        "University of Rochester",
        "University of Saint Francis-Fort Wayne",
        "University of Saint Joseph",
        "University of Saint Katherine",
        "University of Saint Mary",
        "University of Saint Mary of the Lake",
        "University of San Diego",
        "University of San Francisco",
        "University of Science and Arts of Oklahoma",
        "University of Scranton",
        "University of Sioux Falls",
        "University of South Alabama",
        "University of South Carolina Aiken",
        "University of South Carolina Beaufort",
        "University of South Carolina-Columbia",
        "University of South Carolina-Lancaster",
        "University of South Carolina-Salkehatchie",
        "University of South Carolina-Sumter",
        "University of South Carolina-Union",
        "University of South Carolina-Upstate",
        "University of South Dakota",
        "University of South Florida-Main Campus",
        "University of South Florida-Sarasota-Manatee",
        "University of South Florida-St Petersburg",
        "University of Southern California",
        "University of Southern Indiana",
        "University of Southern Maine",
        "University of Southern Mississippi",
        "University of Spa & Cosmetology Arts",
        "University of St Francis",
        "University of St Thomas",
        "University of St. Augustine for Health Sciences",
        "University of Texas Health Science Center at Tyler",
        "University of Texas Southwestern Medical Center",
        "University of the Cumberlands",
        "University of the District of Columbia",
        "University of the District of Columbia-David A Clarke School of Law",
        "University of the Incarnate Word",
        "University of the Ozarks",
        "University of the Pacific",
        "University of the Potomac-VA Campus",
        "University of the Potomac-Washington DC Campus",
        "University of the Sciences",
        "University of the Southwest",
        "University of the Virgin Islands",
        "University of the Virgin Islands-Albert A. Sheen",
        "University of the West",
        "University of Toledo",
        "University of Tulsa",
        "University of Utah",
        "University of Valley Forge",
        "University of Vermont",
        "University of Virginia-Main Campus",
        "University of Washington-Bothell Campus",
        "University of Washington-Seattle Campus",
        "University of Washington-Tacoma Campus",
        "University of West Alabama",
        "University of West Georgia",
        "University of West Los Angeles",
        "University of Western States",
        "University of Wisconsin Colleges Flex",
        "University of Wisconsin-Eau Claire",
        "University of Wisconsin-Green Bay",
        "University of Wisconsin-La Crosse",
        "University of Wisconsin-Madison",
        "University of Wisconsin-Milwaukee",
        "University of Wisconsin-Milwaukee Flex",
        "University of Wisconsin-Oshkosh",
        "University of Wisconsin-Parkside",
        "University of Wisconsin-Parkside Flex",
        "University of Wisconsin-Platteville",
        "University of Wisconsin-River Falls",
        "University of Wisconsin-Stevens Point",
        "University of Wisconsin-Stout",
        "University of Wisconsin-Superior",
        "University of Wisconsin-Whitewater",
        "University of Wyoming",
        "Unlimited Cosmetology School",
        "UPMC Jameson School of Nursing",
        "UPMC Mercy School of Nursing",
        "UPMC Shadyside School of Nursing",
        "UPMC St. Margaret School of Nursing",
        "Upper Cape Cod Regional Technical School",
        "Upper Iowa University",
        "Upper Valley Career Center",
        "Upstate Medical University",
        "UR Beauty & Barber Academy",
        "Urban 113 School of Cosmetology",
        "Urban Barber College",
        "Urban College of Boston",
        "Urbana University",
        "Urshan Graduate School of Theology",
        "Ursinus College",
        "Ursuline College",
        "Uta Mesivta of Kiryas Joel",
        "Utah County Campus",
        "Utah State University",
        "Utah Valley University",
        "Utica College",
        "Valdosta State University",
        "Valencia College",
        "Valley City State University",
        "Valley College of Medical Careers",
        "Valley College-Beckley",
        "Valley College-Martinsburg",
        "Valley College-Princeton",
        "Valley Forge Military College",
        "Valor Christian College",
        "Valparaiso University",
        "Vance-Granville Community College",
        "Vanderbilt University",
        "VanderCook College of Music",
        "Vanguard College of Cosmetology-Baton Rouge",
        "Vanguard College of Cosmetology-Metairie",
        "Vanguard College of Cosmetology-Slidell",
        "Vanguard University of Southern California",
        "Vanguard-Sentinel Adult Career and Technology Center",
        "Vassar College",
        "Vaughn College of Aeronautics and Technology",
        "Veeb Nassau County School of Practical Nursing",
        "Velvatex College of Beauty Culture",
        "Venango County Area Vocational Technical School",
        "Ventura Adult and Continuing Education",
        "Ventura College",
        "Venus Beauty Academy",
        "Veritas Baptist College",
        "Vermilion Community College",
        "Vermont College of Fine Arts",
        "Vermont Law School",
        "Vermont Technical College",
        "Vernon College",
        "Verve College",
        "VH Barber & Styling Academy",
        "Vibe Barber College",
        "VICI Beauty School",
        "Victor Valley College",
        "Victoria Beauty College Inc",
        "Victoria College",
        "Victoria's Academy of Cosmetology",
        "Victory Career College",
        "Villa Maria College",
        "Villanova University",
        "Villanova University - Summer Music Studies Program",
        "Vincennes Beauty College",
        "Vincennes University",
        "Virgil's Beauty College",
        "Virginia Beach City Public Schools School of Practical Nursing",
        "Virginia Beach Theological Seminary",
        "Virginia Commonwealth University",
        "Virginia Highlands Community College",
        "Virginia School of Hair Design",
        "Virginia Sewing Machines and School Center",
        "Virginia State University",
        "Virginia Union University",
        "Virginia University of Integrative Medicine",
        "Virginia University of Lynchburg",
        "Virginia Wesleyan University",
        "Virginia Western Community College",
        "Visible Music College",
        "Vista College",
        "Vista College - College Station",
        "Vista College - Ft. Smith",
        "Vista College - Lake Charles",
        "Vista College Amarillo",
        "Vista College Lubbock",
        "Vista College-Beaumont",
        "Vista College-Killeen",
        "Vista College-Longview",
        "Vista College-Online",
        "Viterbo University",
        "Vogue Beauty and Barber School",
        "Vogue College of Cosmetology",
        "Vogue College of Cosmetology-McAllen",
        "Vogue College of Cosmetology-San Antonio Fredericksburg",
        "Vogue College of Cosmetology-Santa Fe",
        "Vogue International Academy",
        "Volunteer State Community College",
        "Voorhees College",
        "Wabash College",
        "Wabash Valley College",
        "Wade College",
        "Wade Gordon Hairdressing Academy",
        "Wagner College",
        "Wake Forest University",
        "Wake Technical Community College",
        "Walden University",
        "Waldorf University",
        "Walla Walla Community College",
        "Walla Walla University",
        "Walsh College",
        "Walsh University",
        "Walters State Community College",
        "Warner Pacific University",
        "Warner Pacific University Professional and Graduate Studies",
        "Warner University",
        "Warren County Career Center",
        "Warren County Community College",
        "Warren Wilson College",
        "Warrensburg Area Career Center",
        "Wartburg College",
        "Wartburg Theological Seminary",
        "Washburn University",
        "Washington & Jefferson College",
        "Washington Adventist University",
        "Washington and Lee University",
        "Washington Barber College Inc",
        "Washington College",
        "Washington County Career Center-Adult Technical Training",
        "Washington County Community College",
        "Washington Hospital School of Nursing",
        "Washington Saratoga Warren Hamilton Essex BOCES-Practical Nursing Program",
        "Washington State Community College",
        "Washington State University",
        "Washington State University-Spokane",
        "Washington State University-Tri Cities",
        "Washington State University-Vancouver",
        "Washington University in St Louis",
        "Washtenaw Community College",
        "Watkins College of Art Design & Film",
        "Watts School of Nursing",
        "Waubonsee Community College",
        "Waukesha County Technical College",
        "Wave Leadership College",
        "Wayland Baptist University",
        "Wayne Community College",
        "Wayne County Community College District",
        "Wayne County Schools Career Center",
        "Wayne Finger Lakes BOCES-Practical Nursing Program",
        "Wayne State College",
        "Wayne State University",
        "Waynes College of Beauty",
        "Waynesburg University",
        "Waynesville Career Center",
        "Weatherford College",
        "Webber International University",
        "Weber State University",
        "Webster University",
        "Weill Cornell Medical College",
        "Welch College",
        "Wellesley College",
        "Wells College",
        "WellSpring School of Allied Health-Kansas City",
        "WellSpring School of Allied Health-Lawrence",
        "WellSpring School of Allied Health-Springfield",
        "Wenatchee Valley College",
        "Wes Watkins Technology Center",
        "Wesley Biblical Seminary",
        "Wesley College",
        "Wesley Theological Seminary",
        "Wesleyan College",
        "Wesleyan University",
        "West Chester University of Pennsylvania",
        "West Coast University-Center for Graduate Studies",
        "West Coast University-Dallas",
        "West Coast University-Los Angeles",
        "West Coast University-Miami",
        "West Coast University-Ontario",
        "West Coast University-Orange County",
        "West Georgia Technical College",
        "West Hills College-Coalinga",
        "West Hills College-Lemoore",
        "West Kentucky Community and Technical College",
        "West Liberty University",
        "West Los Angeles College",
        "West Michigan College of Barbering and Beauty",
        "West Shore Community College",
        "West Texas A & M University",
        "West Valley College",
        "West Virginia Junior College-Bridgeport",
        "West Virginia Junior College-Charleston",
        "West Virginia Junior College-Morgantown",
        "West Virginia Northern Community College",
        "West Virginia School of Osteopathic Medicine",
        "West Virginia State University",
        "West Virginia University",
        "West Virginia University at Parkersburg",
        "West Virginia University Hospital Departments of Rad Tech and Nutrition",
        "West Virginia Wesleyan College",
        "Westchester College of Nursing & Allied Health",
        "Westchester School for Dental Assistant",
        "Westchester School of Beauty Culture",
        "Westcliff University",
        "Western Area Career & Technology Center",
        "Western Carolina University",
        "Western Colorado University",
        "Western Connecticut State University",
        "Western Governors University",
        "Western Illinois University",
        "Western Iowa Tech Community College",
        "Western Kentucky University",
        "Western Michigan University",
        "Western Michigan University Homer Stryker M.D. School of Medicine",
        "Western Michigan University-Thomas M. Cooley Law School",
        "Western Nebraska Community College",
        "Western Nevada College",
        "Western New England University",
        "Western New Mexico University",
        "Western Oklahoma State College",
        "Western Oregon University",
        "Western Pennsylvania Hospital School of Nursing",
        "Western Piedmont Community College",
        "Western Seminary",
        "Western Seminary-Sacramento",
        "Western Seminary-San Jose",
        "Western Suffolk BOCES",
        "Western Technical College",
        "Western Technology Center",
        "Western Texas College",
        "Western Theological Seminary",
        "Western University of Health Sciences",
        "Western Washington University",
        "Western Wyoming Community College",
        "Westfield State University",
        "Westminster College",
        "Westminster Theological Seminary",
        "Westminster Theological Seminary in California",
        "Westmont College",
        "Westmoreland County Community College",
        "Wharton County Junior College",
        "Whatcom Community College",
        "Wheaton College",
        "Wheeling University",
        "White Earth Tribal and Community College",
        "White Mountains Community College",
        "Whitman College",
        "Whittier College",
        "Whitworth University",
        "Whitworth University-Adult Degree Programs",
        "Wichita State University",
        "Wichita State University-Campus of Applied Sciences and Technology",
        "Widener University",
        "Wilberforce University",
        "Wiley College",
        "Wilkes Community College",
        "Wilkes University",
        "Wilkes-Barre Area Career and Technical Center Practical Nursing",
        "Willamette University",
        "William & Mary",
        "William Carey University",
        "William James College",
        "William Jessup University",
        "William Jewell College",
        "William Moore College of Technology",
        "William Paterson University of New Jersey",
        "William Peace University",
        "William Penn University",
        "William Rainey Harper College",
        "William T McFatter Technical College",
        "William Woods University",
        "Williams Baptist University",
        "Williams College",
        "Williamsburg Technical College",
        "Williamson Christian College",
        "Williamson College of the Trades",
        "Williston State College",
        "Wilmington College",
        "Wilmington University",
        "Wilson College",
        "Wilson Community College",
        "Windward Community College",
        "Winebrenner Theological Seminary",
        "Winebrenner Theological Seminary -",
        "Wingate University",
        "Winona State University",
        "Winonah's International School of Cosmetology",
        "Winston - Salem Barber School - Satellite",
        "Winston Salem Barber School",
        "Winston-Salem State University",
        "Winthrop University",
        "Wiregrass Georgia Technical College",
        "Wisconsin Academy a Paul Mitchell Partner Group-PMTS Lincoln",
        "Wisconsin Indianhead Technical College",
        "Wisconsin Lutheran College",
        "Wisconsin School of Professional Psychology",
        "Withlacoochee Technical College",
        "Wittenberg University",
        "Wofford College",
        "Wongu University of Oriental Medicine",
        "Wood County School of Practical Nursing",
        "Woodbury University",
        "Woodland Community College",
        "Woodruff Medical Training and Testing",
        "Worcester State University",
        "World Class Academy of Beauty Careers",
        "World Mission University",
        "Worsham College of Mortuary Science",
        "Wor-Wic Community College",
        "Wright Beauty Academy",
        "Wright Graduate University for the Realization of Human Potential",
        "Wright State University-Lake Campus",
        "Wright State University-Main Campus",
        "WyoTech",
        "Wytheville Community College",
        "Xavier College School of Nursing",
        "Xavier University",
        "Xavier University of Louisiana",
        "Xenon International Academy-Omaha",
        "Yahweh Beauty Academy",
        "Yakima Valley College",
        "Yale University",
        "Yavapai College",
        "Yeshiva Bais Aharon",
        "Yeshiva Chemdas Hatorah",
        "Yeshiva College of the Nations Capital",
        "Yeshiva Derech Chaim",
        "Yeshiva D'monsey Rabbinical College",
        "Yeshiva Gedola Ohr Yisrael",
        "Yeshiva Gedola Tiferes Yerachmiel",
        "Yeshiva Gedolah Imrei Yosef D'spinka",
        "Yeshiva Gedolah Keren Hatorah",
        "Yeshiva Gedolah Kesser Torah",
        "Yeshiva Gedolah of Cliffwood",
        "Yeshiva Gedolah of Greater Detroit",
        "Yeshiva Gedolah Shaarei Shmuel",
        "Yeshiva Gedolah Tiferes Boruch",
        "Yeshiva Gedolah Zichron Leyma",
        "Yeshiva Karlin Stolin",
        "Yeshiva Kollel Tifereth Elizer",
        "Yeshiva of Far Rockaway Derech Ayson Rabbinical Seminary",
        "Yeshiva of Machzikai Hadas",
        "Yeshiva of Nitra Rabbinical College",
        "Yeshiva of the Telshe Alumni",
        "Yeshiva Ohr Elchonon Chabad West Coast Talmudical Seminary",
        "Yeshiva Ohr Naftoli",
        "Yeshiva Shaar Ephraim",
        "Yeshiva Shaar Hatorah",
        "Yeshiva Shaarei Torah of Rockland",
        "Yeshiva Sholom Shachna",
        "Yeshiva Toras Chaim",
        "Yeshiva University",
        "Yeshiva Yesodei Hatorah",
        "Yeshiva Zichron Aryeh",
        "Yeshivah Gedolah Rabbinical College",
        "Yeshivas Be'er Yitzchok",
        "Yeshivas Emek Hatorah",
        "Yeshivas Maharit D'Satmar",
        "Yeshivas Novominsk",
        "Yeshivath Beth Moshe",
        "Yeshivath Viznitz",
        "Yeshivath Zichron Moshe",
        "Yo San University of Traditional Chinese Medicine",
        "York College",
        "York College of Pennsylvania",
        "York County Community College",
        "York County Community College - Sanford Instructional Facility",
        "York County School of Technology-Adult & Continuing Education",
        "York Technical College",
        "Young Harris College",
        "Youngstown State University",
        "Yuba College",
        "Yukon Beauty College Inc",
        "Z Hair Academy",
        "Zane State College",
        "ZMS",
    ]

    const originalSchoolNames = originals.map((o) => o.label)

    const display = getRandom(schools, 500).filter((newSchool) => {
        return !(newSchool in originalSchoolNames)
    })

    const newSchools = display.map((s, idx) => {
        return {
            label: s,
            value: s.replace(/\s+/g, "-").toLowerCase(),
            trade: "none",
            is_live: false,
        }
    })

    return originals
        .concat(newSchools)
        .sort((a, b) => a.label.localeCompare(b.label))
}

function getRandom(arr: string[], n: number) {
    var result = new Array(n),
        len = arr.length,
        taken = new Array(len)
    if (n > len)
        throw new RangeError("getRandom: more elements taken than available")
    while (n--) {
        var x = Math.floor(Math.random() * len)
        result[n] = arr[x in taken ? taken[x] : x]
        taken[x] = --len in taken ? taken[len] : len
    }
    return result
}
