import { useState, useEffect, useCallback } from "react"
import { useSendPhoneVerificationApp } from "@wetradeup/student-apps-shared"
import { TouchableContainer, Caption } from "@wetradeup/ui-kit"
import { ClickableCaption } from "../atoms/ClickableCaption"
import { UpdatePhoneModal } from "./modals/UpdatePhoneModal"

export const ChangePhone = ({ schoolId }: { schoolId: number }) => {
    const [textSent, setTextSent] = useState(false)
    const sendPhoneVerificationMutation = useSendPhoneVerificationApp()
    const [showUpdatePhoneModal, setShowUpdatePhoneModal] = useState(false)

    const handleUpdatePhonePress = (e) => {
        e.preventDefault()
        setShowUpdatePhoneModal(true)
    }

    useEffect(() => {
        if (textSent) {
            setTimeout(() => {
                setTextSent(false)
            }, 5000)
        }
    }, [textSent])

    const sendPhoneVerification = useCallback(async () => {
        await sendPhoneVerificationMutation.mutate(schoolId)
        setTextSent(true)
    }, [schoolId])

    return (
        <>
            {textSent ? (
                <Caption weight={600} textAlign="center">
                    {" "}
                    We sent a verification message to your updated phone number!{" "}
                </Caption>
            ) : (
                <TouchableContainer onPress={handleUpdatePhonePress}>
                    <ClickableCaption textAlign="center">
                        {" "}
                        Change phone number{" "}
                    </ClickableCaption>
                </TouchableContainer>
            )}
            <UpdatePhoneModal
                visible={showUpdatePhoneModal}
                closeModal={() => setShowUpdatePhoneModal(false)}
                sendPhoneVerification={sendPhoneVerification}
            />
        </>
    )
}
