import {
    CancelIcon,
    Card,
    Container,
    H3,
    Modal,
    P,
    Spacer,
} from "@wetradeup/ui-kit"
import { ContentContainer } from "../ModalComponents"
import { CancelIconContainer } from "../application/molecules/modals/DecisionModal"
import { UnderlineText } from "./UnderlineText"
import { CallUs } from "../icons/CallUs"
import { useTheme } from "@emotion/react"
import { Email } from "../icons/Email"
import { isWeekend } from "date-fns"

interface ContactSupportModalProps {
    invalidPersonalInfo: string
}

export function ContactSupportModal({
    invalidPersonalInfo,
}: ContactSupportModalProps) {
    const declineMessage = `You’ve previously been marked as ineligible based on your ${invalidPersonalInfo}. In order to move forward, you’ll need to contact customer service.`
    const theme = useTheme()
    const isCallClosed = isWeekend(new Date())
    return (
        <Modal visible={!!invalidPersonalInfo} width="100%" onClick={() => {}}>
            <Container
                width={[
                    [700, "md"],
                    [860, "lg"],
                ]}
            >
                <Card paddingMd>
                    <ContentContainer
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Spacer lg />
                        <CancelIconContainer>
                            <CancelIcon />
                        </CancelIconContainer>
                        <Spacer />
                        <Container padding={["sm", "lg"]}>
                            <H3 bold="light">{declineMessage}</H3>
                        </Container>
                        <Spacer lg />
                        <Container flex wrap row gap="16px">
                            <Card alignItems="center" flex={1} minWidth={300}>
                                <CallUs />
                                <Spacer lg />
                                <P
                                    textColor="black"
                                    bold
                                    textSize={theme.sizings.fontSizeInc1}
                                >
                                    Call us
                                </P>
                                <Spacer lg />
                                <P textColor="black" textAlign="center">
                                    The quickest way to reach us.
                                </P>
                                <Spacer sm />
                                <P textColor="black" textAlign="center">
                                    {`Hours today: ${
                                        isCallClosed
                                            ? "closed"
                                            : "9:00 AM - 5:00 PM"
                                    }`}
                                </P>
                                <Spacer lg />
                                <P textColor="black" textAlign="center">
                                    Call us at{" "}
                                    <UnderlineText bold textColor="black">
                                        505-806-2054
                                    </UnderlineText>
                                </P>
                            </Card>
                            <Card alignItems="center" flex={1} minWidth={300}>
                                <Email />
                                <Spacer lg />
                                <P
                                    textColor="black"
                                    bold
                                    textSize={theme.sizings.fontSizeInc1}
                                >
                                    Email us
                                </P>
                                <Spacer lg />
                                <P textColor="black" textAlign="center">
                                    Always available and great if you need to
                                    include attachments
                                </P>
                                <Spacer sm />
                                <P textColor="black" textAlign="center">
                                    Our inbox is open 24/7
                                </P>
                                <Spacer lg />
                                <P textColor="black" bold textAlign="center">
                                    help@fynncredit.com
                                </P>
                            </Card>
                        </Container>
                        <Spacer lg />
                    </ContentContainer>
                </Card>
            </Container>
        </Modal>
    )
}
